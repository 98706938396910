import Tippy from '@tippyjs/react';
import { useModal } from 'react-simple-modal-provider';

import { useTranslation } from 'react-i18next';
import { Button, TeamRoleSelect, Typography } from '../../../../components/index';
import { WorkspaceMember } from '../../imports/types';
import { copyToClipboard } from '../../../../imports/utils';
import { useLoadingStatusContext } from '../../../../context';
import { useAppSelector } from '../../../../redux/hooks';
import { grantPermission, handleBackendError } from '../../api/api';
import { CHAIN_MODULES } from '../../../../imports/constants';

type ManageTeamsTableBodyProps = {
  member: WorkspaceMember;
  role: any;
  workspaceIsOwner: boolean;
  changeSelection: (
    selection: string,
    index: number | string,
    module: 'notarization' | 'audit' | 'token_creator'
  ) => void;
  index: number;
};

const ManageTeamsTableBody = ({
  member,
  role,
  workspaceIsOwner,
  changeSelection,
  index,
}: ManageTeamsTableBodyProps) => {
  const { t } = useTranslation(['team']);

  const {open: openLoadingModal} = useModal('LoadingModal');
  const {dispatch: loadingStatusDispatch} = useLoadingStatusContext();
  const {uid, wallet: {privateKey}} = useAppSelector((state) => state.user);
  const workspaceId = useAppSelector((state) => state.team.workspace?.id);
  
  const needsToBeVerified =
  member.permissions.notarization.write ||
  member.permissions.audit.write ||
  member.permissions.token_creator.write;

const isPending = member.status === 'pending';

const isUnverified = !isPending && !member.verifiedOnChain && needsToBeVerified;

  /**
   * @description Function called after user accept invitation to the team
   * handle the blockchain function call
   * @param address
   * @param userId
   * @returns
   */
  const handleVerify = async (address: string, userId: string) => {
    try {
      openLoadingModal({});
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.detail.pending'),
        },
      });
      if (workspaceId == null) throw new Error('Empty workspaceId');
      // First value needs to be setted, doesn't mind if is true or false beacuse index 0 is not releated to any modules
      await grantPermission(privateKey, workspaceId, address, userId, [
        false,
        ...CHAIN_MODULES.map(
          (mod: string) => member?.permissions[mod.toLowerCase()].write || false
        ),
      ]);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.detail.verify.success'),
        },
      });
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.detail.error'),
        },
      });
      handleBackendError(error);
    }
  };

  return (
    <div
      key={member?.user}
      className={`grid h-[65px] w-full grid-cols-6 items-center justify-center border-t border-grey-200 text-center align-middle text-grey-600 first:border-0 ${
        member?.status === 'pending' && 'pointer-events-none opacity-50'
      }`}
    >
      <Tippy interactive content={<div onClick={()=>copyToClipboard(member.email)} className='cursor-pointer'>{member.email}</div>}>
        <div>
          <Typography className="cursor-pointer" size="body-medium-18" weight="medium">
            {member?.name}
          </Typography>
        </div>
      </Tippy>

      <div className="flex justify-center">
        <TeamRoleSelect
          // selected={handleSelected(member.permissions.token_creator)}
          selected={
            role[member.user]?.token_creator.write
              ? 'editor'
              : role[member.user]?.token_creator.read
              ? 'viewer'
              : 'no_access'
          }
          onChange={(selection: string) => changeSelection(selection, member.user, 'token_creator')}
          disabled={!workspaceIsOwner}
        />
      </div>
      <div className="flex justify-center">
        <TeamRoleSelect
          selected={
            role[member.user]?.notarization.write
              ? 'editor'
              : role[member.user]?.notarization.read
              ? 'viewer'
              : 'no_access'
          }
          onChange={(selection: string) => changeSelection(selection, member.user, 'notarization')}
          disabled={!workspaceIsOwner}
        />
      </div>
      <div className="flex justify-center">
        <TeamRoleSelect
          selected={
            role[member.user]?.audit.write
              ? 'editor'
              : role[member.user]?.audit.read
              ? 'viewer'
              : 'no_access'
          }
          onChange={(selection: string) => changeSelection(selection, member.user, 'audit')}
          disabled={!workspaceIsOwner}
        />
      </div>
      <div className= 'flex justify-center'>
        {member.isCompanyAccount ? t('manage.company_account') : t('manage.individual_account')}
      </div>
      <div className= 'flex justify-center'>
        <Button disabled={member.verifiedOnChain} action={()=>{handleVerify(member.address, member.user)}}>
          {t('verify')}
        </Button>
      </div>
    </div>
  );
};

export default ManageTeamsTableBody;
