import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { InnerPage } from '../../components';
import { useAppSelector } from '../../redux/hooks';

const Home = () => {
  const navigate = useNavigate();
  const { workspace } = useAppSelector((state) => state.team);

  useEffect(() => {
    if (!isEmpty(workspace)) {
      if (workspace.permissions.notarization.read === false) {
        if (workspace.permissions.token_creator.read === false) {
          navigate('/profile');
          return;
        }
        navigate('/nft/');
        return;
      }
    }

    navigate('/notarizations');
  }, [workspace]);

  return <InnerPage>BCode Home</InnerPage>;
};

export default Home;
