import { cloneDeep } from 'lodash';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { IconCopy } from '../../icons';
import { BLURRED_EMAIL } from '../../imports/constants';
import { updateSubCollection } from '../../imports/firestore.utils';
import { copyToClipboard, truncateText } from '../../imports/utils';
import { Customer } from '../../modules/team/imports/types';
import { ProjectToList } from '../../modules/tokenCreator/imports/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateCustomerCrm } from '../../redux/team/team.slice';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Tooltip from '../tooltips/Tooltip';
import Typography from '../typography/Typography';

type CrmUserModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { customer, defaultModalType } = useModalProps('CrmUserModal');
  const [modalType, setModalType] = useState<'full' | 'collections'>(defaultModalType);
  const { t } = useTranslation(['tokenCreator']);
  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const [crmCustomer, setCrmCustomer] = useState<Customer>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    /* set the crmCustomer to mantain it updated with hide/show updates and to always have the correct hide value */
    const crmCustomer = workspace?.customers.find(
      (cust: Customer) => cust.customerId === customer.customerId
    );
    setCrmCustomer(crmCustomer);
  }, [workspace?.customers]);

  const handleHide = async () => {
    try {
      await updateSubCollection(`/CRM/${uid}/customers`, customer.customerId, {
        hide: !crmCustomer?.hide,
      });
      const updatedCustomer = cloneDeep(customer);
      updatedCustomer.hide = !crmCustomer?.hide;
      dispatch(updateCustomerCrm(updatedCustomer));
    } catch (e) {
      console.error(e);
      return { error: JSON.stringify(e) };
    }
  };

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        //if the modal was opened from the user section and then entered the Collections section, you should return to user and not close
        if (defaultModalType === 'full' && modalType === 'collections') {
          setModalType('full');
        } else {
          setOpen(false);
        }
      }}
      classNameLayout=" overflow-auto max-h-[85%] min-h-[300px]"
      width="485px"
    >
      {/* full modal, from grid user */}
      {modalType === 'full' && (
        <div className="flex w-full flex-col items-center justify-center space-y-4 px-[40px] pt-[20px]">
          <div className="flex size-full flex-col items-start justify-between ">
            <div className="flex flex-col space-y-[20px]">
              <Typography size="body-medium-30" color="primary-500">
                {t('crm.user_info')}
              </Typography>
              <div>
                <Typography size="body-regular-18" color="grey-600">
                  {t('crm.user_name')}
                </Typography>
                <Typography size="body-medium-16" weight="medium">
                  {customer.name || ''}
                </Typography>
              </div>
              <div>
                <Typography size="body-regular-18" color="grey-600">
                  {t('crm.collections')}
                </Typography>
                <Typography size="body-medium-16" weight="medium">
                  {customer.projects.length === 0 ? (
                    '-'
                  ) : (
                    <Button
                      type="secondary"
                      action={() => setModalType('collections')}
                      className="!h-[30px] rounded-[4px]"
                    >
                      {t('crm.collections')}{' '}
                    </Button>
                  )}
                </Typography>
              </div>
              <div className="flex flex-col ">
                <Typography size="body-regular-18" color="grey-600">
                  {t('crm.email')}
                </Typography>

                {customer.email === null ? (
                  <div
                    className="select-none"
                    style={{ color: 'grey-50', textShadow: '0 0 5px rgba(0,0,0,0.5)' }}
                  >
                    <Typography size="body-medium-16" weight="medium">
                      {BLURRED_EMAIL}
                    </Typography>
                  </div>
                ) : (
                  <Typography size="body-medium-16" weight="medium">
                    {customer.email}
                  </Typography>
                )}
              </div>
              <div className="relative flex w-full flex-col ">
                <Typography size="body-regular-18" color="grey-600">
                  {t('crm.wallet_address')}
                </Typography>
                <div className="flex gap-2">
                  <Typography size="body-medium-16" weight="medium">
                    {truncateText(customer.address, { maxLength: 20, position: 'middle' })}
                  </Typography>
                  <Tooltip content={t('copy')}>
                    <Button
                      type="ghost"
                      action={() => copyToClipboard(customer.address)}
                      icon={IconCopy}
                    />
                  </Tooltip>
                </div>
                <Button
                  width="200px"
                  className="mt-7"
                  type={`${crmCustomer?.hide ? 'secondary' : 'warning'}`}
                  action={() => handleHide()}
                >
                  {crmCustomer?.hide ? t('show_user') : t('hide_user')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* only collections, from table visualization */}
      {modalType === 'collections' && (
        <div className="flex w-full flex-col items-start justify-start space-y-4 px-[50px] pt-[20px]">
          <Typography size="body-medium-30" color="primary-500">
            {t('crm.collections')}
          </Typography>
          <div className="mt-4">
            {customer.projects.length === 0 ? (
              <Typography size="body-medium-16">{t('crm.no_collections')}</Typography>
            ) : (
              <div className="grid grid-cols-4 gap-x-5">
                <div className="col-span-3 mb-2">
                  <Typography weight="medium" size="body-regular-16">
                    {t('crm.headers.name')}
                  </Typography>
                </div>
                <div className="mb-2">
                  <Typography weight="medium" size="body-regular-16">
                    {t('crm.headers.quantity')}
                  </Typography>
                </div>
                {customer.projects.map(
                  (project: ProjectToList) =>
                    project.quantity > 0 && (
                      <Fragment key={project.projectId}>
                        <div className="col-span-3">
                          <Typography size="body-regular-16">{project.projectName}</Typography>
                        </div>
                        <div>
                          <Typography size="body-regular-16">{project.quantity}</Typography>
                        </div>
                      </Fragment>
                    )
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </ModalLayout>
  );
};

const CrmUserModal = ({ children }: CrmUserModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="CrmUserModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default CrmUserModal;
