import { SVGProps } from 'react';

const IconStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2477_75482)">
      <path
        d="M99.3019 143.025L137.102 167.025C141.977 170.1 147.977 165.525 146.552 159.9L135.602 116.85C135.306 115.657 135.353 114.404 135.738 113.237C136.123 112.069 136.829 111.034 137.777 110.25L171.677 81.9752C176.102 78.3002 173.852 70.8752 168.077 70.5002L123.827 67.6502C122.62 67.5799 121.459 67.1595 120.486 66.4403C119.514 65.721 118.772 64.7341 118.352 63.6002L101.852 22.0502C101.415 20.8495 100.619 19.8122 99.5729 19.0793C98.5264 18.3463 97.2796 17.9531 96.0019 17.9531C94.7242 17.9531 93.4775 18.3463 92.4309 19.0793C91.3844 19.8122 90.5887 20.8495 90.1519 22.0502L73.6519 63.6002C73.2314 64.7341 72.4897 65.721 71.5174 66.4403C70.5451 67.1595 69.3843 67.5799 68.1769 67.6502L23.9269 70.5002C18.1519 70.8752 15.9019 78.3002 20.3269 81.9752L54.2269 110.25C55.1744 111.034 55.8813 112.069 56.2661 113.237C56.6509 114.404 56.698 115.657 56.4019 116.85L46.2769 156.75C44.5519 163.5 51.7519 168.975 57.5269 165.3L92.7019 143.025C93.6883 142.398 94.833 142.065 96.0019 142.065C97.1708 142.065 98.3155 142.398 99.3019 143.025Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2477_75482">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconStar;
