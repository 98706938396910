import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { PropsWithChildren, useEffect } from 'react';
import { FIREBASECHANGEEVENTS } from '../imports/constants';
import { db } from '../imports/firebase';
import { Subscription } from '../imports/types';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logOutSubscription, setSubscription } from '../redux/subscription/subscription.slice';

const SubscriptionWrapper = (props: PropsWithChildren) => {
  const { children } = props;
  const { workspace } = useAppSelector((state) => state.team);
  const { uid } = useAppSelector((state) => state.user);
  const { list } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const targetOwnerUid = workspace?.owner ?? uid;

  useEffect(() => {
    dispatch(logOutSubscription());
    if (!workspace?.id) return;

    const subscriptionQuery = query(
      collection(db, 'subscriptions'),
      where('ownerUid', '==', targetOwnerUid)
    );

    onSnapshot(subscriptionQuery, (snapshot) => {
      const change = snapshot.docChanges()[0];
      if (change) {
        const subscription: Subscription = change.doc.data() as Subscription;
        if (change.type === FIREBASECHANGEEVENTS.ADDED) {
          dispatch(setSubscription(subscription));
        }

        if (change.type === FIREBASECHANGEEVENTS.MODIFIED) {
          if (list.length > 0) {
            dispatch(setSubscription(subscription));
          }
        }
      }
    });
  }, [workspace?.id]);

  return <>{children}</>;
};

export default SubscriptionWrapper;
