import { ReactNode, createContext, useContext, useMemo, useReducer } from 'react';

type StatusType = 'pending' | 'success' | 'error' | null;

type Action =
  | { type: 'RESET_STATE' }
  | { type: 'SET_HIDE' }
  | { type: 'SET_SHOW' }
  | {
      type: 'SET_PENDING';
      payload: {
        title?: string;
        message?: string;
      };
    }
  | {
      type: 'SET_SUCCESS';
      payload: {
        title?: string;
        message?: string;
        action?: { label: string; onClick: () => void };
      };
    }
  | {
      type: 'SET_ERROR';
      payload: {
        title?: string;
        message?: string;
        action?: { label: string; onClick: () => void };
      };
    };

type State = {
  isVisible: boolean;
  status: StatusType;
  title?: string;
  message?: string;
  action?: { label: string; onClick: () => void };
};

type LoadingDispatch = (action: Action) => void;

type LoadingProviderProps = { children: ReactNode };

const LoadingStateContext = createContext<{ state: State; dispatch: LoadingDispatch } | undefined>(
  undefined
);

const initialState: State = {
  isVisible: false,
  status: null,
  title: '',
  message: '',
  action: {
    label: '',
    onClick: () => {},
  },
};

const loadingReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_HIDE': {
      return { ...state, isVisible: false };
    }
    case 'SET_SHOW': {
      return { ...state, isVisible: true };
    }
    case 'SET_PENDING': {
      const { title, message } = action.payload;

      return { ...state, isVisible: true, status: 'pending', title, message };
    }
    case 'SET_SUCCESS': {
      const { title, message, action: splashScreenAction } = action.payload;

      return {
        ...state,
        isVisible: true,
        status: 'success',
        title,
        message,
        action: splashScreenAction,
      };
    }
    case 'SET_ERROR': {
      const { title, message, action: loaderAction } = action.payload;

      return {
        ...state,
        isVisible: true,
        status: 'error',
        title,
        message,
        action: loaderAction,
      };
    }
    case 'RESET_STATE': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const LoadingStatusProvider = ({ children }: LoadingProviderProps) => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);
  const memoizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <LoadingStateContext.Provider value={memoizedValue}>{children}</LoadingStateContext.Provider>
  );
};

const useLoadingStatusContext = () => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw new Error('useSplashscreen must be used within a LoadingStatusProvider');
  }
  return context;
};

export { LoadingStatusProvider, useLoadingStatusContext };
