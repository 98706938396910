import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconArrow } from '../../icons';
import { decorationColors, hoverColors, textColors } from '../../imports/constants';
import { Colors } from '../../imports/types';
import Icon from '../icon/Icon';

type LinkTextProps = {
  // icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconSize?: string;
  textSize?: string;
  textColor?: Colors;
  textColorHover?: Colors;
  decorationColor?: Colors;
  color?: Colors;
  noUnderline?: Boolean;
  className?: string;
  direction?: 'left' | 'right';
  newPage?: boolean;
  to: string;
  children?: ReactNode;
};

const LinkText = ({
  iconSize = '14',
  textColor = 'primary-500',
  textSize = 'text-body-medium-14',
  textColorHover = 'primary-500',
  decorationColor = 'primary-500',
  color = 'primary-500',
  noUnderline = false,
  newPage = false,
  className,
  direction,
  children,
  to,
}: LinkTextProps) => {
  const getLinkTextColor = () => textColors[textColor];
  const getLinkTextHoverColor = () => hoverColors[textColorHover];
  const getLinkTextDecorationColor = () => decorationColors[decorationColor];
  const underlineClass = noUnderline ? '' : 'hover:underline';
  const target = newPage ? '_blank' : '';

  return (
    <Link
      to={to}
      target={target}
      className={`flex items-center justify-start gap-1 hover:cursor-pointer ${underlineClass}  ${textSize} ${getLinkTextColor()} ${getLinkTextDecorationColor()} ${getLinkTextHoverColor()} ${className}`}
    >
      {
        direction === 'left' && <Icon icon={IconArrow} color={color} size={iconSize} />
        // <IconArrow stroke={color} width={iconSize} height={iconSize} />
      }
      {children}
      {direction === 'right' && (
        <Icon icon={IconArrow} stroke={color} size={iconSize} className={`rotate-180 `} />
        // <IconArrow stroke={color} width={iconSize} height={iconSize} className={`rotate-180 `} />
      )}
    </Link>
  );
};
export default LinkText;
