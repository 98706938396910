import { onValue, ref } from 'firebase/database';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode as QRCodeLogo } from 'react-qrcode-logo';
import { useModalProps } from 'react-simple-modal-provider';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconLoading } from '../../icons';
import { redeemer } from '../../imports/constants';
import { database } from '../../imports/firebase';
import { TQrCodeFormatted } from '../../imports/types';
import { copyToClipboard, downloadQrCodes, formatDate } from '../../imports/utils';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

const ModalBody = ({ setOpen }: any) => {
  const { contractId, dropDate, qrCodes, nft, contractImage } = useModalProps('QrModal');

  const { contracts } = useAppSelector((state) => ({ contracts: state.contracts.list }));
  const { t } = useTranslation(['tokenCreator']);
  const [qrCodesFormatted, setQrCodesFormatted] = useState<TQrCodeFormatted[] | null>(null);
  const [contract, setContract] = useState(contracts.find(({ id }) => id === contractId));
  const [loading, setLoading] = useState(false);

  const downloadCsv = () => {
    const customFields = nft?.attributes?.map(({ trait_type }: any) => trait_type);
    const customValues = nft?.attributes?.map(({ value }: any) => value);

    let headers = ['Name', 'description', 'Quantity', 'external url', 'Image'];
    let values = [nft?.name, nft?.description, nft?.quantity, nft?.external_url, nft?.image];
    if (customFields && customValues) {
      headers = [...headers, ...customFields];
      values = [...values, ...customValues];
    }

    const rows = [
      [...headers, 'TokenID', 'URL', 'address'],
      ...(qrCodesFormatted?.map(({ id, url, key }) => [...values, id, url, key]) ?? []),
    ];

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  useEffect(() => {
    const fetchqQrCodes = async () => {
      if (contract && nft) {
        /**
         * This condition covers the unique QRCode for all NFTs
         */
        if (contract.qrCodeDrop === 2) {
          const qrMockKey = '0x0000000000000000000000000000000000000000000000000000000000000000';
          setQrCodesFormatted(
            await Promise.all(
              [...Array(1)].map(async (value, index) => ({
                id: contract.qrCodes?.[0]?.id || index + 1,
                key: qrMockKey,
                image: await QRCode.toDataURL(
                  `${redeemer}/redeem?id=${contract.id}&key=${qrMockKey}`
                ),
                url: `${redeemer}/redeem?id=${contract.id}&key=${qrMockKey}`,
              }))
            )
          );
          return;
        }
        /**
         * This condition covers the case where every NFT has it's own QRCode
         */
        if (contract.qrCodeDrop) {
          const unsubscribeQrCodes = await onValue(
            ref(database, `${contract.id}/s/${parseInt(nft?.id, 10) - 1}`),
            async (snapshot) => {
              const values: TQrCodeFormatted[] = [];
              if (!snapshot.exists()) return null;
              await Promise.all(
                snapshot.val().map(async (snap: any) => {
                  if (!snap?.s && !snap?.e) {
                    const { p } = snap;
                    const qrCodeFormatted: TQrCodeFormatted = {
                      id: nft.id + 1,
                      key: p,
                      image: await QRCode.toDataURL(
                        `${redeemer}/redeem?id=${contract.id}&key=${p}`
                      ),
                      url: `${redeemer}/redeem?id=${contract.id}&key=${p}`,
                    };
                    values.push(qrCodeFormatted);
                  }
                })
              );
              setQrCodesFormatted(values);
            }
          );

          return unsubscribeQrCodes;
        }
      }
    };

    setLoading(true);
    fetchqQrCodes();
    setLoading(false);
  }, [contract, nft]);

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      width="540px"
      // height="336px"
      classNameLayout="min-h-[336px]"
    >
      {loading ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : !qrCodesFormatted?.length ? (
        <div className="flex h-full flex-col items-center justify-center">
          <Typography as="h3" size="lg" className="text-center ">
            {t('collection_item.qr_code_overlay.no')}
          </Typography>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-10">
          <Typography as="h2" size="2xl" weight="semibold" className="text-center">
            {t('collection_item.qr_code_overlay.title', { nftName: nft?.name })}
          </Typography>
          {dropDate && (
            <Typography as="h3" size="lg" className="mt-4 text-center">
              {t('collection_item.qr_code_overlay.drop_date', {
                date: formatDate(dropDate),
              })}
            </Typography>
          )}
          <div className="mt-4 flex w-full items-center justify-center">
            <Swiper navigation modules={[Navigation]} className="w-full">
              {qrCodesFormatted.map(({ key, url }, index) => {
                return (
                  <SwiperSlide key={key} className="flex flex-col items-center justify-center">
                    <QRCodeLogo
                      value={url}
                      logoImage={contractImage}
                      enableCORS
                      id={key}
                      logoWidth={50}
                      size={200}
                      eyeRadius={1}
                      removeQrCodeBehindLogo
                    />

                    <Button action={() => copyToClipboard(url)} type="ghost">
                      {t('collection_item.qr_code_overlay.go_to_redeem_page')}
                    </Button>
                    <Typography className="mt-8 text-center">
                      {`${index + 1} / ${qrCodesFormatted.length}`}
                    </Typography>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="mt-16 flex w-full items-center justify-between space-x-[24px]">
            <Button action={() => downloadQrCodes(qrCodesFormatted, nft)}>
              {t('collection_item.qr_code_overlay.action')}
            </Button>
            <Button type="secondary" action={downloadCsv}>
              {t('collection_item.qr_code_overlay.action2')}
            </Button>
          </div>
        </div>
      )}
    </ModalLayout>
  );
};

export { ModalBody };
