import { SVGProps } from 'react';

const IconUserPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M150 102H186"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M168 84V120"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81 120C105.853 120 126 99.8528 126 75C126 50.1472 105.853 30 81 30C56.1472 30 36 50.1472 36 75C36 99.8528 56.1472 120 81 120Z"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeMiterlimit={10}
    />
    <path
      d="M16.6484 150C24.5329 140.603 34.3797 133.046 45.4969 127.862C56.6141 122.678 68.7319 119.991 80.9984 119.991C93.265 119.991 105.383 122.678 116.5 127.862C127.617 133.046 137.464 140.603 145.348 150"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconUserPlus;
