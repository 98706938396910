import { SVGProps } from 'react';

const IconFileNft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38799)">
      <path
        d="M68.7136 119.98V151H56.8696L49.4436 136.9L47.7516 133.093H47.6576L47.8456 139.485V151H38.3516V119.98H50.1956L57.6216 134.127L59.3136 137.887H59.4076L59.2196 131.495V119.98H68.7136Z"
        fill="#4F4F4F"
      />
      <path
        d="M101.477 128.017H86.719V132.341H98.657V140.378H86.719V151H75.768V119.98H101.477V128.017Z"
        fill="#4F4F4F"
      />
      <path
        d="M135.94 119.98V128.017H126.258V151H115.307V128.017H105.578V119.98H135.94Z"
        fill="#4F4F4F"
      />
      <path
        d="M114 19V61H156"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144 163H150C151.591 163 153.117 162.368 154.243 161.243C155.368 160.117 156 158.591 156 157V61L114 19H42C40.4087 19 38.8826 19.6321 37.7574 20.7574C36.6321 21.8826 36 23.4087 36 25V97"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38799">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFileNft;
