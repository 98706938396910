/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38765)">
      <path
        d="M156 66H36C32.6863 66 30 68.6863 30 72V156C30 159.314 32.6863 162 36 162H156C159.314 162 162 159.314 162 156V72C162 68.6863 159.314 66 156 66Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69 66V39C69 31.8392 71.8446 24.9716 76.9081 19.9081C81.9716 14.8446 88.8392 12 96 12C103.161 12 110.028 14.8446 115.092 19.9081C120.155 24.9716 123 31.8392 123 39V66"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38765">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconLock;
