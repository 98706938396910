import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Form } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import i18n from '../../i18n';
import { getRemoteConfigValue } from '../../imports/remoteConfig';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Field from '../field/Field';
import Form from '../form/Form';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type TermsModalProps = {
  children: ReactNode;
};

const TermsAndCondition1 = () => {
  const { t } = useTranslation();
  return (
    <Typography size="body-regular-10">
      {t('termAndCondition.tec1.tecPt1')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorTermsAndConditions[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec1.link1')}
      </a>
      {t('termAndCondition.tec1.tecPt2')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorPrivacyPolicy[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec1.link2')}
      </a>
    </Typography>
  );
};
const TermsAndCondition2 = () => {
  const { t } = useTranslation();
  return (
    <Typography size="body-regular-10">
      {t('termAndCondition.tec2.tecPt1')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorTermsAndConditions[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec2.link1')}
      </a>
      {t('termAndCondition.tec2.tecPt2')}
    </Typography>
  );
};
const TermsAndCondition3 = () => {
  const { t } = useTranslation();
  return (
    <Typography size="body-regular-10">
      {t('termAndCondition.tec3.tecPt1')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorPrivacyPolicy[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec3.link1')}
      </a>
      {t('termAndCondition.tec3.tecPt2')}
    </Typography>
  );
};
const TermsAndCondition4 = () => {
  const { t } = useTranslation();
  return (
    <Typography size="body-regular-10">
      {t('termAndCondition.tec4.tecPt1')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorPrivacyPolicy[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec4.link1')}
      </a>
    </Typography>
  );
};
const TermsAndCondition5 = () => {
  const { t } = useTranslation();
  return (
    <Typography size="body-regular-10">
      {t('termAndCondition.tec5.tecPt1')}
      <a
        href={getRemoteConfigValue('legals', 'json').tokenCreatorPrivacyPolicy[i18n.language]}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {t('termAndCondition.tec5.link1')}
      </a>
    </Typography>
  );
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { t } = useTranslation();

  const { handleSignUp /*  isLoading, setIsLoading */ } = useModalProps('TermsModal');
  const [isLoading, setIsLoading] = useState(false);
  const termsForm = {
    initialValues: {
      tec1: false,
      tec2: false,
      tec3: false,
      tec4: false,
      tec5: false,
    },
    validationSchema: yup.object({
      tec1: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      tec2: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      tec3: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      tec4: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
      tec5: yup.boolean().oneOf([true], t('form_validation.terms_and_conditions_must_be_accepted')),
    }),
  };
  const { initialValues, validationSchema } = termsForm;

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      // className="flex flex-col w-full "
      // className={`absolute mx-auto flex ${
      //   step === Steps.WALLET ? 'w-[600px]' : 'w-[450px]'
      // } flex-col items-start justify-between pb-[10vh]`}
      mode="all"
    >
      {({ fields, errors, handleSubmit, resetField, watch, setValue, dirtyFields, isValid }) => {
        const terms = watch();

        return (
          <ModalLayout
            open={isOpen}
            onClose={() => {
              setOpen(false);
            }}
            // classNameBg="pl-[17%]"
            classNameLayout="w-[540px] h-[611px] overflow-auto flex justify-center"
          >
            <div className="my-10 flex h-[495px] w-[460px] flex-col items-center justify-center gap-7">
              <Typography color="primary-500" size="body-medium-30">
                {t('authentication.sign_up_labels.terms_privacy')}
              </Typography>
              <div>
                <Field
                  error={errors.tec1?.message}
                  label={t('termAndCondition.label')}
                  className=" mb-[7px]"
                >
                  <div className="flex flex-row justify-between space-x-[8px]  ">
                    <Checkbox
                      size="9"
                      id={fields.tec1.name}
                      name={fields.tec1.name}
                      onBlur={fields.tec1.onBlur}
                      onChange={fields.tec1.onChange}
                      inputRef={fields.tec1.ref}
                      error={errors.tec1?.message}
                      // disabled={isCurrentEnvDemo}
                    />
                    <TermsAndCondition1 />
                  </div>
                </Field>

                <Field
                  error={errors.tec2?.message}
                  label={t('termAndCondition.label')}
                  className=" mb-[7px]"
                >
                  <div className=" flex flex-row  justify-between space-x-[8px]">
                    <Checkbox
                      size="9"
                      id={fields.tec2.name}
                      name={fields.tec2.name}
                      onBlur={fields.tec2.onBlur}
                      onChange={fields.tec2.onChange}
                      inputRef={fields.tec2.ref}
                      error={errors.tec2?.message}
                      // disabled={isCurrentEnvDemo}
                    />
                    <TermsAndCondition2 />
                  </div>
                </Field>

                <Field
                  error={errors.tec3?.message}
                  label={t('termAndCondition.label')}
                  className=" mb-[7px]"
                >
                  <div className="flex flex-row justify-between space-x-[8px]">
                    <Checkbox
                      size="9"
                      id={fields.tec3.name}
                      name={fields.tec3.name}
                      onBlur={fields.tec3.onBlur}
                      onChange={fields.tec3.onChange}
                      inputRef={fields.tec3.ref}
                      error={errors.tec3?.message}
                      // disabled={isCurrentEnvDemo}
                    />
                    <TermsAndCondition3 />
                  </div>
                </Field>

                <Field
                  error={errors.tec4?.message}
                  label={t('termAndCondition.label')}
                  className="mb-[7px]  "
                >
                  <div className="flex flex-row justify-between space-x-[8px]">
                    <Checkbox
                      size="9"
                      id={fields.tec4.name}
                      name={fields.tec4.name}
                      onBlur={fields.tec4.onBlur}
                      onChange={fields.tec4.onChange}
                      inputRef={fields.tec4.ref}
                      error={errors.tec4?.message}
                      // disabled={isCurrentEnvDemo}
                    />
                    <TermsAndCondition4 />
                  </div>
                </Field>

                <Field
                  error={errors.tec5?.message}
                  label={t('termAndCondition.label')}
                  textSize="body-regular-10"
                >
                  <div className="flex flex-row justify-between space-x-[8px]">
                    <Checkbox
                      size="9"
                      id={fields.tec5.name}
                      name={fields.tec5.name}
                      onBlur={fields.tec5.onBlur}
                      onChange={fields.tec5.onChange}
                      inputRef={fields.tec5.ref}
                      error={errors.tec5?.message}
                      // disabled={isCurrentEnvDemo}
                    />
                    <TermsAndCondition5 />
                  </div>
                </Field>
              </div>
              <div className="ml-0 ">
                <Button
                  disabled={!isValid || isLoading}
                  className="ml-0 "
                  action={() => {
                    setIsLoading(true);
                    handleSubmit(handleSignUp(terms, setOpen));
                  }}
                >
                  {t('authentication.accept_and_signup')}
                </Button>
              </div>
            </div>
          </ModalLayout>
        );
      }}
    </Form>
  );
};

const TermsModal = ({ children }: TermsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="TermsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};
export default TermsModal;
