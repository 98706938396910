import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactElement, ReactNode } from 'react';
import { MainLogo } from '../../assets/images';
import Image from '../image/Image';
import Stepper from '../stepper/Stepper';

type SidebarProps = {
  topElement?: ReactNode | string | ReactElement;
  middleElement?: ReactNode | string | ReactElement;
  bottomElement?: ReactNode | string | ReactElement;
  stepperLabels?: string[];
  stepperCurrent?: number;
  title?: string;
  subtitle?: string;
};

const Sidebar = ({
  topElement,
  middleElement,
  bottomElement,
  stepperLabels,
  stepperCurrent,
  title,
  subtitle,
}: SidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <aside className="fixed left-0 top-0  flex h-full w-[30%] min-w-[360px] max-w-[420px] shrink-0 flex-col bg-grey-100 text-white">
      <div className="absolute flex size-full flex-col justify-between pl-[16.6%] ">
        {topElement === 'logo' && (
          <header className="absolute top-[9%]  flex w-[225px] flex-col justify-center gap-[12px]">
            <Image src={MainLogo} alt={t('bcode_logo')} className="w-[218px]" />
            <p className="w-[217px] text-body-medium-16 text-black">{t('sidebar.logo_subtitle')}</p>
          </header>
        )}
        <div className="absolute top-[15%]">
          {title && <h3 className="text-body-medium-30 text-black">{title}</h3>}
          {subtitle && <p className="mt-[5px]  text-body-medium-20 text-black">{subtitle}</p>}
          {topElement !== 'logo' && topElement}
        </div>
        <div
          id="middle"
          className="fixed top-[30%] h-[20%] w-[180px]  text-primary-500"
          style={{
            // eslint-disable-next-line no-unsafe-optional-chaining
            height: `${stepperLabels ? `${Math.floor(stepperLabels?.length * 7)}%` : '30%'}`,
            maxHeight: '35%',
          }}
        >
          {stepperLabels ? (
            <Stepper currentStep={stepperCurrent || 0} stepLabels={stepperLabels || ['']} />
          ) : (
            middleElement
          )}
        </div>
        <div id="bottom" className="absolute bottom-[9%] items-start text-primary-500">
          {bottomElement === 'text' ? (
            <div className="flex  w-[270px] flex-col items-start ">
              <h4 className=" mb-[10px] text-h4 text-primary-500">{t('sidebar.know')}</h4>
              <p className=" text-body-regular-14 leading-[16px] text-black">
                {t('sidebar.description')}
              </p>
            </div>
          ) : (
            bottomElement
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
