import { sendEmailVerification, User } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Footer, Topbar } from '../components';

import { roles } from '../imports/constants';
import { useAppSelector } from '../redux/hooks';

import type { Routes } from '../imports/types';

import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../imports/firebase';
import { verifyPermission } from '../modules/team/common/utils';
import NoInternetConnection from './noInternetConnection/NoInternetConnection';

type LayoutProps = {
  route: Routes;
};
const Layout = ({ route }: LayoutProps) => {
  const {
    component,
    // hasSidebar,
    hasTopbar,
    hasntFooter,
    restrictedTo,
    redirect,
    redirectByRole,
    requiresPermission,
    hideFromTeam,
  } = route;

  const { role } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);

  const navigate = useNavigate();

  const Page = component;
  const resendEmail = (authUser: User) => {
    const lastEmailSent = localStorage.getItem('LastEmailSent');
    if (!lastEmailSent && !authUser.emailVerified) {
      sendEmailVerification(authUser);
      localStorage.setItem('LastEmailSent', Date.now().valueOf().toString());
      return;
    }
    const timePassedSinceLastTime = Date.now().valueOf() - parseInt(lastEmailSent!, 10);
    if (timePassedSinceLastTime > 3600000 && !authUser.emailVerified) {
      sendEmailVerification(authUser);
      localStorage.setItem('LastEmailSent', Date.now().valueOf().toString());
    }
  };

  useEffect(() => {
    if (restrictedTo) {
      if (!restrictedTo.includes(role)) {
        if (role === roles.unverified) {
          if (auth.currentUser) {
            resendEmail(auth.currentUser);
          }
          navigate('/verify-email');
          return;
        }

        if (redirect) {
          navigate(redirect);
          return;
        }

        if (redirectByRole && redirectByRole[role]) {
          navigate(redirectByRole[role]!);
        }
      }
    }

    if (workspace != null && !workspace.isOwner && hideFromTeam === true) {
      navigate('/', { replace: true });
      return;
    }

    if (
      workspace != null &&
      !workspace.isOwner &&
      !!workspace?.permissions &&
      !!requiresPermission
    ) {
      const permission = verifyPermission(workspace.permissions, requiresPermission);
      if (!permission) {
        navigate('/', { replace: true });
      }
    }
  }, [role]);

  return (
    <NoInternetConnection>
      <>
        <div id="app" className={`min-h-screen w-full ${hasTopbar && 'pt-[70px]'} font-sans`}>
          {hasTopbar && <Topbar />}
          <Page route={route} />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ zIndex: 10000 }}
          />
        </div>
        {!hasntFooter && <Footer />}
      </>
    </NoInternetConnection>
  );
};

export default Layout;
