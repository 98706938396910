/* eslint-disable tailwindcss/no-custom-classname */
import './flipCard.css';

interface FlipCardProps {
  front: JSX.Element;
  back: JSX.Element;
  always: JSX.Element;
  flip: boolean;
}
const FlipCard = ({ front, back, always, flip }: FlipCardProps) => {
  return (
    <div className="relative mb-5 aspect-square items-center">
      {flip ? back : front}
      {always}
    </div>
  );
};
export default FlipCard;
