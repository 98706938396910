import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../imports/firebase';
import { Group } from '../imports/types';

export const getGroups = async (workspaceId: string | undefined): Promise<Group[]> => {
  try {
    if (!workspaceId) throw new Error('No workspace Id found!');
    // retrieve the groups from the database in the subcollection groups of the  collection 'workspaces' and return an array of objects
    const groups = (await getDocs(collection(db, `workspaces/${workspaceId}/groups`))).docs.map(
      (doc) => {
        return { ...doc.data(), id: doc.id } as Group;
      }
    );

    return groups;
  } catch (e: any) {
    console.log(e, 'error while fetching groups');
    return e;
  }
};

export const getWorkspaceMembers = async (workspaceId: string) => {
  try {
    const members = await getDocs(collection(db, 'workspaces', workspaceId, 'members'));
    return members;
  } catch (e) {
    console.log(e);
  }
};
