import { PDFDownloadLink } from '@react-pdf/renderer';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { validatePec } from '../../api/axios';

import {
  changeEmail,
  changePassword,
  getSubscription,
  getUserAuthToken,
  reauthenticateUser,
} from '../../api/firebase';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateProfile } from '../../redux/user/user.slice';

import { useCopyToClipboard, useValidation } from '../../hooks';

import { delayed, truncateText } from '../../imports/utils';

import { ImageEmailVerified, ImageFileUpload } from '../../assets/images';

import {
  Button,
  Field,
  Form,
  Icon,
  Image,
  Input,
  PecValidationDocument,
  ProgressBar,
  Tooltip,
  Typography,
} from '../../components';
import {
  IconClose,
  IconCopy,
  IconDownload,
  IconEdit,
  IconExport,
  IconInvisible,
  IconLink,
  IconVisible,
} from '../../icons';
import { currentEnv, explorerPolygon } from '../../imports/constants';
import { auth, db } from '../../imports/firebase';
import { SubscriptionPlan } from '../../imports/types';
import { ManageTeams } from '../../modules/team/pages';
import Plans from './Plans';

type VerifyPecProps = {
  user: any;
  handleCloseSidebar: () => void;
};
enum Steps {
  PROFILE,
  PLAN,
  DELEGATIONS,
}

/* pec step? */
const VerifyPec = ({ user, handleCloseSidebar }: VerifyPecProps) => {
  const { t } = useTranslation();
  const { required, validateEmail } = useValidation();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [pec, setPec] = useState('');

  const form = {
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: required(validateEmail()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleSetStep = (step: string) => {
    if (step === 'next') {
      setStep((prev) => prev + 1);
    }
    if (step === 'back') {
      setStep((prev) => prev - 1);
    }
  };

  const handleVerifyPec = async (values: any) => {
    setIsLoading(true);

    const { value: authTokenId, error: authTokenError } = await getUserAuthToken();
    const { error } = await validatePec(values.email, authTokenId);

    if (error && error.response.status === 511) {
      setIsLoading(false);
      toast.error(t('verify_pec.error.already_sent_validation_request') as string);
      return;
    }

    setIsLoading(false);
    setPec(values.email);
    // handleSetStep('next');
  };

  return (
    <div className="flex w-full flex-col items-center space-y-4">
      <Image src={ImageEmailVerified} alt={t('image.filehash')} className="w-[50px]" />

      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        className="flex w-full flex-col space-y-4"
      >
        {({ fields, handleSubmit, errors, resetField }) => (
          <>
            <div className="flex flex-col items-center space-y-2 text-center">
              <Typography weight="medium" size="lg">
                {step === 0 ? t('verify_pec.form_title') : t('verify_pec.file_title')}
              </Typography>

              {step === 0 ? (
                <Field error={errors.email?.message}>
                  <Input
                    type="text"
                    placeholder={t('verify_pec.form_placeholders.email')}
                    id={fields.email.name}
                    name={fields.email.name}
                    onBlur={fields.email.onBlur}
                    onChange={fields.email.onChange}
                    inputRef={fields.email.ref}
                    error={errors.email?.message}
                    elementRight={
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.email.name)}
                      />
                    }
                  />
                </Field>
              ) : (
                <div className="!my-8 flex w-full items-center space-x-4">
                  <div className="shrink-0">
                    <Image src={ImageFileUpload} alt={t('download')} className="size-8" />
                  </div>

                  <div className="flex grow items-center text-left">
                    <div className="w-20">
                      <Typography className="truncate">{user.company}</Typography>
                    </div>
                    <Typography>_PEC.pdf</Typography>
                  </div>

                  <div className="shrink-0">
                    <PDFDownloadLink
                      fileName={`${user.company}_PEC.pdf`}
                      document={<PecValidationDocument user={user} pec={pec} />}
                    >
                      <Tooltip content={t('download')}>
                        <Button type="ghost" action={handleCloseSidebar}>
                          <Icon icon={IconDownload} className="!h-4 !w-4" color="secondary-500" />
                        </Button>
                      </Tooltip>
                    </PDFDownloadLink>
                  </div>
                </div>
              )}
            </div>

            {step === 0 && (
              <Button
                type="primary"
                action={() => handleSubmit(handleVerifyPec)()}
                loading={isLoading}
              >
                {t('verify_pec.next')}
              </Button>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

type EditFieldProps = {
  type: 'email' | 'password' | 'language';
  handleCloseSidebar: () => void;
};

const EditField = ({ type, handleCloseSidebar }: EditFieldProps) => {
  const { t } = useTranslation();
  const { required, validateEmail } = useValidation();
  const profile = useAppSelector((state) => state.user);
  const { uid, wallet } = profile;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const isStepForm = step === 0;
  const isStepSuccess = step === 1;
  const isStepError = step === 2;

  const form = {
    initialValues: {
      ...(type === 'email' && {
        email: '',
      }),
      ...(type === 'password' && {
        password: '',
      }),
      currentPassword: '',
    },
    validationSchema: yup.object({
      ...(type === 'email' && {
        email: required(validateEmail()),
      }),
      ...(type === 'password' && {
        password: required(yup.string()),
      }),
      currentPassword: required(yup.string()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleEditField = async (values: any) => {
    const { email, password, currentPassword } = values;
    setIsLoading(true);

    const auth = getAuth();
    const { currentUser } = auth;

    if (currentUser && currentUser.email) {
      const { error: reauthenticationError } = await reauthenticateUser(
        currentUser,
        currentPassword
      );

      if (reauthenticationError) {
        delayed(() => {
          setIsLoading(false);
          setStep(2);
        }, 2500);
        return;
      }

      if (type === 'email') {
        const { error: changeEmailError } = await changeEmail(currentUser, email);

        if (changeEmailError) {
          delayed(() => {
            setIsLoading(false);
            setStep(2);
          }, 2500);
          return;
        }

        dispatch(updateProfile({ profile: { email } }));
      }

      if (type === 'password') {
        const { error: changePasswordError } = await changePassword(uid, currentPassword, password);

        if (changePasswordError) {
          delayed(() => {
            setIsLoading(false);
            setStep(2);
          }, 2500);
          return;
        }
      }

      delayed(() => {
        setIsLoading(false);
        setStep(1);
      }, 2500);
    }
  };
  const handleLanguage = (lng: 'it' | 'en') => {
    localStorage.setItem('lng', lng);
    i18next.changeLanguage(lng);
    handleCloseSidebar();
  };
  const lng = localStorage.getItem('lng') || navigator.language.slice(0, 2);

  return (
    <div className="flex w-full flex-col items-center space-y-4">
      {isStepForm && (
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          className="flex w-full flex-col"
        >
          {({ fields, handleSubmit, errors, resetField }) => (
            <div className="flex flex-col items-center space-y-4 text-center">
              <Typography weight="medium" size="lg">
                {type === 'email' && t('account.edit_profile_field.title', { field: 'email' })}
                {type === 'password' &&
                  t('account.edit_profile_field.title', { field: 'password' })}
                {type === 'language' &&
                  t('account.edit_profile_field.title', {
                    field: t('account.profile_fields.language'),
                  })}
              </Typography>

              <div className="w-full text-left">
                {type === 'language' && (
                  <>
                    <Button
                      className={`!mt-2 ${lng === 'it' ? 'primary' : 'secondary'}`}
                      action={() => handleLanguage('it')}
                    >
                      {t('account.edit_profile_field.it')}
                    </Button>
                    <Button
                      className={`!mt-2 ${lng === 'en' ? 'primary' : 'secondary'}`}
                      action={() => handleLanguage('en')}
                    >
                      {t('account.edit_profile_field.en')}
                    </Button>
                  </>
                )}

                {type === 'email' && (
                  <>
                    <Field error={errors.email?.message}>
                      <Input
                        type="text"
                        placeholder={t('account.edit_profile_field.form_placeholders.email')}
                        id={fields.email.name}
                        name={fields.email.name}
                        onBlur={fields.email.onBlur}
                        onChange={fields.email.onChange}
                        inputRef={fields.email.ref}
                        error={errors.email?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.email.name)}
                          />
                        }
                      />
                    </Field>

                    <Field error={errors.currentPassword?.message}>
                      <Input
                        type={showCurrentPassword ? 'text' : 'password'}
                        placeholder={t(
                          'account.edit_profile_field.form_placeholders.current_password'
                        )}
                        id={fields.currentPassword.name}
                        name={fields.currentPassword.name}
                        onBlur={fields.currentPassword.onBlur}
                        onChange={fields.currentPassword.onChange}
                        inputRef={fields.currentPassword.ref}
                        error={errors.currentPassword?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={showCurrentPassword ? IconInvisible : IconVisible}
                            action={() => setShowCurrentPassword((prev) => !prev)}
                          />
                        }
                      />
                    </Field>
                  </>
                )}

                {type === 'password' && (
                  <>
                    <Field error={errors.currentPassword?.message}>
                      <Input
                        type={showCurrentPassword ? 'text' : 'password'}
                        placeholder={t(
                          'account.edit_profile_field.form_placeholders.current_password'
                        )}
                        id={fields.currentPassword.name}
                        name={fields.currentPassword.name}
                        onBlur={fields.currentPassword.onBlur}
                        onChange={fields.currentPassword.onChange}
                        inputRef={fields.currentPassword.ref}
                        error={errors.currentPassword?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={showCurrentPassword ? IconInvisible : IconVisible}
                            action={() => setShowCurrentPassword((prev) => !prev)}
                          />
                        }
                      />
                    </Field>

                    <Field error={errors.password?.message}>
                      <Input
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder={t('account.edit_profile_field.form_placeholders.password')}
                        id={fields.password.name}
                        name={fields.password.name}
                        onBlur={fields.password.onBlur}
                        onChange={fields.password.onChange}
                        inputRef={fields.password.ref}
                        error={errors.password?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={showNewPassword ? IconInvisible : IconVisible}
                            action={() => setShowNewPassword((prev) => !prev)}
                          />
                        }
                      />
                    </Field>
                  </>
                )}
                {type !== 'language' && (
                  <div className="!mt-2">
                    <Button action={() => handleSubmit(handleEditField)()} loading={isLoading}>
                      {t('account.edit_profile_field.save')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Form>
      )}

      {(isStepSuccess || isStepError) && (
        <div className="flex flex-col items-center space-y-4 text-center">
          {isStepSuccess && (
            <>
              <Typography weight="medium" size="lg">
                {type === 'email' && t('account.success.field_changed', { field: 'email' })}
                {type === 'password' && t('account.success.field_changed', { field: 'password' })}
              </Typography>

              <Button action={handleCloseSidebar}>{t('account.edit_profile_field.close')}</Button>
            </>
          )}

          {isStepError && (
            <>
              <Typography weight="medium" size="lg">
                {t('account.error.generic')}
              </Typography>

              <Button action={() => setStep(0)}>{t('account.edit_profile_field.go_back')}</Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

type ProfileFieldProps = {
  label: string;
  value: string;
  children?: React.ReactNode;
};

const ProfileField = ({ label, value, children }: ProfileFieldProps) => (
  <div className="flex flex-col space-y-1">
    <div className="flex items-center justify-between">
      <Typography size="body-regular-18">{label}</Typography>
    </div>
    <div className="flex items-center">
      <Typography className="truncate">{value}</Typography>
      {children && children}
    </div>
  </div>
);

const Profile = () => {
  const { t } = useTranslation();

  const { search, key } = useLocation();
  const [searchParams] = useSearchParams();
  const verifyPec = searchParams.get('verify-pec');

  const profile = useAppSelector((state) => state.user);
  const { wallet, uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);

  const lng = localStorage.getItem('lng') || navigator.language.slice(0, 2);
  const [subscription, setSubscription] = useState<any>(null);
  const [stateCopyToClipboard, copyToClipboard] = useCopyToClipboard();
  const showSidebarInitialState = { type: '', optionalProps: {} };
  const [showSidebar, setShowSidebar] = useState<{ type: string; optionalProps?: any }>(
    showSidebarInitialState
  );
  const [pec, setPec] = useState('');
  const { open: openPec } = useModal('PecModal');
  const { open: openFields } = useModal('ProfileFieldsModal');
  const { open: openModalPlans } = useModal('PlansModal');
  const handleOpenEditPlan = () => {
    const plan: SubscriptionPlan = {
      name: subscription.value.name,
      maxTokenNumber: 7000,
      maxNotarizations: 10000,
      maxContractsNumber: 5,
      storageLimit: 7,
      maxTeamMembers: 3,
      fees: 12,
      price: 16303,
    };
    openModalPlans({ plan });
  };

  const [step, setStep] = useState(workspace?.owner === uid ? Steps.PROFILE : Steps.DELEGATIONS);
  const [numberOfTeamMembers, setNumberOfTeamMembers] = useState(0);

  const walletJson = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(wallet))}`;

  const getOwnWorkspace = async (uid: string) => {
    const workspaceRef = collection(db, 'users', uid, 'workspaces');
    const ownerWorkspace = query(workspaceRef, where('is_owner', '!=', true));
    if (ownerWorkspace) {
      const querySnapshot = await getDocs(ownerWorkspace);
      const ownedTeamId = querySnapshot.docs[0]?.id;

      const members = query(collection(db, 'workspaces', ownedTeamId, 'members'));
      const membersSnapshot = await getDocs(members);
      const number = membersSnapshot.docs.length;
      setNumberOfTeamMembers(number);
    }
    return 0;
    // const workspace_id = collection('users', uid, 'workspaces', where('is_owner', '==', true) )
  };

  // const isMyTeam = workspace?.owner === profile.uid;

  // const handleShowSidebar = (type: SidebarType, optionalProps: any = {}) => {
  //   setShowSidebar({ type, optionalProps });
  // };

  // const handleCloseSidebar = () => {
  //   setShowSidebar(showSidebarInitialState);
  // };

  const handleCopyToClipboard = (value: any, message: string) => {
    copyToClipboard(value);
    toast.success(message);
  };

  useEffect(() => {
    const sub = onAuthStateChanged(auth, () => {
      if (profile?.uid) {
        getSubscription(profile.uid).then((res: { value?: any; error?: any }) => {
          setSubscription(res);
        });
      }
    });
    getOwnWorkspace(profile.uid);
    return sub;
  }, []);

  // useEffect(() => {
  //   if (search) {
  //     if (verifyPec) {
  //       setShowSidebar({ type: 'pec' });
  //     }
  //   }
  // }, [key]);

  return (
    <>
      <div className=" flex flex-col px-[70px] py-[66px] pb-[100px]">
        <Typography size="body-medium-30" color="black">
          {t('account.profile_fields.company_profile')}
        </Typography>
        <div className="mb-16 mt-[45px] flex justify-start gap-[25px]">
          {workspace?.owner === uid && (
            <>
              <Button
                type={step === Steps.PROFILE ? 'primary' : 'tertiary'}
                action={() => setStep(Steps.PROFILE)}
                className="mx-0"
              >
                {t('account.profile')}
              </Button>
              <Button
                type={step === Steps.PLAN ? 'primary' : 'tertiary'}
                action={() => setStep(Steps.PLAN)}
                className="mx-0"
              >
                {t('account.plan')}
              </Button>
            </>
          )}
          <Button
            type={step === Steps.DELEGATIONS ? 'primary' : 'tertiary'}
            action={() => setStep(Steps.DELEGATIONS)}
            className="mx-0"
          >
            {t('account.delegations')}
          </Button>
        </div>

        {/* steps */}
        {step === Steps.PROFILE && (
          <>
            <div className="grid grid-cols-4 gap-[30px]">
              <ProfileField
                label={t('account.profile_fields.fields.company_name')}
                value={profile.company || '-'}
              />

              <ProfileField label={t('account.profile_fields.fields.email')} value={profile.email}>
                <Tooltip content={t('modify')}>
                  <Button
                    type="ghost"
                    action={() => openFields({ type: 'email' })}
                    icon={IconEdit}
                    iconStroke="primary-500"
                  ></Button>
                </Tooltip>
              </ProfileField>

              <ProfileField label={t('account.profile_fields.fields.password')} value="********">
                <Tooltip content={t('modify')}>
                  <Button
                    type="ghost"
                    // action={() => handleShowSidebar('editField', { type: 'password' })}s
                    action={() => openFields({ type: 'password' })}
                    icon={IconEdit}
                    iconStroke="primary-500"
                  />
                </Tooltip>
              </ProfileField>

              {pec ? (
                <ProfileField label={t('PEC')} value="">
                  {pec === 'pending' && (
                    <Button
                      type="secondary"
                      className="ml-0 !h-[30px] rounded-[6px] "
                      action={() => {}}
                      classNameText="px-[8px]"
                    >
                      In fase di verifica
                    </Button>
                  )}
                  {pec === 'verified' && (
                    <Button
                      type="secondary"
                      className="ml-0 !h-[30px] rounded-[6px] "
                      action={() => {}}
                      classNameText="px-[8px]"
                    >
                      Verificata
                    </Button>
                  )}
                </ProfileField>
              ) : (
                <ProfileField label={t('PEC')} value="-">
                  <Tooltip content={t('modify')}>
                    <Button
                      action={() => {
                        openPec({ user: profile });
                      }}
                      type="ghost"
                      icon={IconEdit}
                      iconStroke="primary-500"
                    />
                  </Tooltip>
                </ProfileField>
              )}
              {/* <ProfileField label={t('PEC')} value={'-'}>
                <Tooltip content={t('modify')}>
                  <Button
                    action={() => {
                      openPec({ user: profile });
                    }}
                    type="ghost"
                    icon={IconEdit}
                    iconStroke="primary-500"
                  />
                </Tooltip>
              </ProfileField> */}
              <ProfileField
                label={t('account.profile_fields.fields.vat_number')}
                value={profile.vat || '-'}
              />

              <ProfileField
                label={t('account.profile_fields.fields.sdi')}
                value={profile.sdi || '-'}
              />
              <ProfileField
                label={t('account.profile_fields.fields.registered_office')}
                value={profile.office || '-'}
              />
            </div>

            <div className="mt-[60px] flex flex-col gap-[30px]">
              <div className="flex items-center gap-[20px] ">
                <Typography size="body-medium-30" color="black">
                  {t('account.profile_fields.company_wallet')}
                </Typography>

                <a href={walletJson} download="wallet.json">
                  <Button action={() => {}} className=" !h-9 !w-9">
                    <Icon icon={IconExport} stroke="white" />
                  </Button>
                </a>
              </div>
              <div className="grid grid-cols-4 gap-8">
                <ProfileField
                  label={t('account.profile_fields.fields.wallet_address')}
                  value={truncateText(profile.wallet.address, {
                    maxLength: 18,
                    position: 'middle',
                  })}
                >
                  <Tooltip content={t('copy')}>
                    <Button
                      type="ghost"
                      action={() =>
                        handleCopyToClipboard(
                          profile.wallet.address,
                          t('account.success.copied_succesfully', {
                            element: t('account.profile_fields.fields.wallet_address'),
                          })
                        )
                      }
                      icon={IconCopy}
                      iconStroke="primary-500"
                    />
                  </Tooltip>
                </ProfileField>

                <ProfileField
                  label={t('account.profile_fields.fields.private_key')}
                  value={truncateText(profile.wallet.privateKey, {
                    maxLength: 18,
                    position: 'middle',
                  })}
                >
                  <Tooltip content={t('copy')}>
                    <Button
                      type="ghost"
                      action={() =>
                        handleCopyToClipboard(
                          profile.wallet.privateKey,
                          t('account.success.copied_succesfully', {
                            element: t('account.profile_fields.fields.private_key'),
                          })
                        )
                      }
                      icon={IconCopy}
                      iconStroke="primary-500"
                    />
                  </Tooltip>
                </ProfileField>

                <ProfileField
                  label={t('account.profile_fields.fields.blockchain_explorer')}
                  value="Polygonscan"
                >
                  <Tooltip content={t('link')}>
                    <a href={explorerPolygon} target="_blank" rel="noreferrer">
                      <Button
                        type="ghost"
                        action={() => {}}
                        icon={IconLink}
                        iconStroke="primary-500"
                      />
                    </a>
                  </Tooltip>
                </ProfileField>
              </div>
            </div>
          </>
        )}

        {step === Steps.PLAN && (
          <>
            <div className="flex flex-row items-center gap-7">
              <Typography size="body-medium-30" color="black">
                {t('account.profile_fields.subscription', {
                  name: subscription?.value?.name
                    ? subscription?.value?.name
                    : t('account.profile_fields.free'),
                })}
              </Typography>
              {subscription?.value?.name && ['DEV', 'PROD'].includes(currentEnv) && (
                <Button type="ghost" action={() => handleOpenEditPlan()}>
                  <Icon icon={IconEdit} className="!h-4 !w-4" stroke="secondary-500" />
                </Button>
              )}
            </div>
            <div className="mt-[30px] rounded-[17px] border">
              <div className=" gap-14 p-[55px] ">
                {subscription && subscription.value?.createdAt && (
                  <>
                    <div className="grid grid-cols-3">
                      <ProfileField
                        label={t('account.profile_fields.fields.activation')}
                        value={new Date(
                          subscription.value.createdAt._seconds * 1000 +
                            subscription.value.createdAt._nanoseconds / 1000000
                        ).toLocaleDateString()}
                      />
                      <div></div>
                      <div className="text-right">
                        <Typography size="h4" weight="bold">
                          {t('account.profile_fields.fields.renewal')} -{' '}
                          {new Date(
                            subscription.value.expDate._seconds * 1000 +
                              subscription.value.expDate._nanoseconds / 1000000
                          ).toLocaleDateString()}{' '}
                        </Typography>
                      </div>
                    </div>
                    {subscription?.value && (
                      <div className="mt-[30px] grid grid-cols-3 ">
                        <div className="flex max-w-[320px] flex-col gap-[10px] pr-3">
                          <Typography size="body-medium-18">
                            {`${subscription.value.currentNotarizations} / ${subscription.value.maxNotarizations}`}{' '}
                            {t('account.profile_fields.fields.notarizations')}
                          </Typography>
                          <ProgressBar
                            partial={subscription.value.currentNotarizations}
                            total={subscription.value.maxNotarizations}
                          />
                        </div>
                        <div className="flex max-w-[320px] flex-col gap-[10px] pr-3">
                          <Typography size="body-medium-18">
                            {`${subscription.value.storageUsed.toFixed(
                              1
                            )}MB / ${subscription.value.storageLimit.toFixed(1)}MB`}{' '}
                          </Typography>
                          <ProgressBar
                            partial={subscription.value.storageUsed}
                            total={subscription.value.storageLimit}
                          />
                        </div>
                        {/* {!Number.isNaN(subscription.value?.eurWallet) && (
                          <ProfileField
                            label={t('account.profile_fields.fields.credits')}
                            value={`${subscription.value.eurWallet?.toFixed(2)}€`}
                          />
                        )} */}
                      </div>
                    )}
                    {subscription?.value && (
                      <div className="mt-[30px] grid grid-cols-3">
                        {!Number.isNaN(subscription.value?.currentContractsNumber) &&
                          !Number.isNaN(subscription.value?.maxContractsNumber) && (
                            <div className="flex max-w-[320px] flex-col gap-[10px] pr-3">
                              <Typography size="body-medium-18">
                                {`${subscription.value.currentContractsNumber} / ${subscription.value.maxContractsNumber}`}{' '}
                                {t('account.profile_fields.fields.projects')}
                              </Typography>
                              <ProgressBar
                                partial={subscription.value.currentContractsNumber}
                                total={subscription.value.maxContractsNumber}
                              />
                            </div>
                          )}

                        {!Number.isNaN(subscription.value?.currentTokenNumber) &&
                          !Number.isNaN(subscription.value?.maxTokenNumber) && (
                            <div className="flex max-w-[320px] flex-col gap-[10px] pr-3">
                              <Typography size="body-medium-18">
                                {`${subscription.value.currentTokenNumber} / ${subscription.value.maxTokenNumber}`}{' '}
                                {t('account.profile_fields.fields.nft_created')}
                              </Typography>
                              <ProgressBar
                                partial={subscription.value.currentTokenNumber}
                                total={subscription.value.maxTokenNumber}
                              />
                            </div>
                          )}

                        {!Number.isNaN(subscription.value?.maxTeamMembers) && (
                          <div className="flex max-w-[320px] flex-col gap-[10px] pr-3">
                            <Typography size="body-medium-18">
                              {`${numberOfTeamMembers}  /
                                ${
                                  subscription.value.maxTeamMembers
                                    ? ` ${subscription.value?.maxTeamMembers}`
                                    : '0'
                                }`}{' '}
                              {t('account.profile_fields.fields.available_account')}
                            </Typography>
                            <ProgressBar
                              partial={numberOfTeamMembers}
                              total={subscription.value.maxTeamMembers}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* {subscription?.value && <Plans subscription={subscription} />} */}
          </>
        )}
        {step === Steps.DELEGATIONS && <ManageTeams />}
      </div>
    </>
  );
};

export default Profile;
