import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { getUserAuthToken } from '../../api/firebase';
import { IconCopy, IconLoading } from '../../icons';
import { compare, copyToClipboard, formatDate } from '../../imports/utils';
import { Audit } from '../../modules/audit/imports/types';
import { getFileFromStorage } from '../../modules/notarizations/api';
import { ReportNotarization } from '../../modules/notarizations/components';
import { Notarization, Receipt } from '../../modules/notarizations/imports/types';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Tag from '../tag/Tag';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};
const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography size="body-regular-18">{label.toUpperCase()}</Typography>
      <div className="flex flex-row items-center justify-start gap-4 ">{children}</div>
    </div>
  );
};

type AuditDetailsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { t } = useTranslation(['audit']);
  const { workspace } = useAppSelector((state) => state.team);
  const { audit, notarizations, receipts, hasWritePermission, goTo } =
    useModalProps('AuditDetailsModal');
  const [storageLink, setStorageLink] = useState(null);
  const [auditWithReceipt, setAuditWithReceipt] = useState<Audit>(audit);
  const [auditNotarizations, setAuditNotarizations] = useState<Array<Notarization & Receipt>>([]);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  const isReportDownloadable = () => {
    if (auditWithReceipt.tx && auditWithReceipt.merkleTreeURI) {
      return true;
    }
    return false;
  };

  const handleGetAuditExternalUrl = async () => {
    let url;
    if (audit.externalUrl) {
      const { value: authTokenId } = await getUserAuthToken();
      const res = await getFileFromStorage(audit.externalUrl, authTokenId);
      url = res.value.url;
      setStorageLink(url);
    }
    return url;
  };

  const handleDownloadFileFromExternalUrl = async () => {
    const url = await handleGetAuditExternalUrl();
    if (url) {
      const link = document.createElement('a');
      link.download = audit.name;
      link.href = url;
      link.click();
    }
  };
  const handleGetExternalUrl = async () => {
    const { value: authTokenId } = await getUserAuthToken();
    const externalUrls = await Promise.all(
      auditNotarizations.map((notarization) => {
        if (notarization.externalUrl) {
          return getFileFromStorage(notarization.externalUrl, authTokenId).then((res) => {
            return { hash: notarization.hash, externalUrl: res.value.url };
          });
        }
        return { hash: notarization.hash, externalUrl: '' };
      })
    );
    return externalUrls;
  };
  const { data: externalUrl, status: externalUrlStatus } = useQuery(
    ['externalUrl', auditNotarizations],
    () => handleGetExternalUrl()
  );

  useEffect(() => {
    if (audit.notarizationRef && notarizations) {
      // notarization that identifies the audit on chain
      const auditNotarization: Notarization = notarizations.find(
        (notarization: { id: string }) => notarization.id === audit.notarizationRef.id
      );
      const notarizationWithReceipt = receipts.find(
        (receipt: Receipt) => receipt.receiptId === auditNotarization?.receiptId
      );
      const auditFormatted: Audit = {
        ...audit,
        tx: notarizationWithReceipt?.tx,
        merkleTreeURI: notarizationWithReceipt?.merkleTreeURI,
      };
      setAuditWithReceipt(auditFormatted);
    }

    const verifiedNotarizationsIds: string[] = audit.verifiedNotarizations.map(
      // array di ref relative alle notarizzazioni verificate
      (notarization: any) => notarization.id
    );

    const verifiedNotarizations: any[] = // (Notarization & Receipt) []
      verifiedNotarizationsIds
        .map((notarizationId: string) => {
          if (notarizations) {
            const notarizationsWithReceipt: (Notarization & Receipt)[] = (
              notarizations as Notarization[]
            )
              .filter((notarization) => notarization.id === notarizationId)
              .map((notarization) => {
                const receipt = receipts.find(
                  (receipt: Receipt) => receipt.receiptId === notarization.receiptId
                );
                return { ...receipt, ...notarization };
              });

            if (notarizationsWithReceipt.length) {
              return notarizationsWithReceipt;
            }
            return null;
          }
          return null;
        })
        .filter((n) => n) || [];

    const newFiltered: any[] = [];
    verifiedNotarizations.forEach((arrayItem) => {
      const x = arrayItem[0];
      newFiltered.push(x);
    });
    if (newFiltered?.length && newFiltered) {
      const filteredNotarizations = newFiltered.sort((a: any, b: any) =>
        compare(a, b, 'name', 'asc')
      );
      setAuditNotarizations(filteredNotarizations.filter((f: Notarization & Receipt) => f) || []);
    }
  }, [audit, notarizations, receipts]);

  return (
    <ModalLayout
      classNameLayout="w-auto"
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mt-7 flex flex-col gap-7 px-5" key={audit.auditId}>
        <Typography className="" color="primary-500" size="body-medium-30">
          {audit.name}
        </Typography>
        <div className="flex flex-row gap-7">
          <div className="flex w-1/2 flex-col gap-4">
            <InfoSection label={t('view_audit.status') as string}>
              <Typography size="body-medium-16" color="primary-500">
                {t(`view_audit.state.${audit.status}`)}
              </Typography>
            </InfoSection>

            {audit.description && (
              <InfoSection label={t('view_audit.description') as string}>
                <Typography size="body-medium-16">{audit.description}</Typography>
              </InfoSection>
            )}

            {auditWithReceipt.tx && (
              <InfoSection label={t('view_audit.hash') as string}>
                {t('view_audit.hash')}
                <div className="w-min">
                  <Button
                    icon={IconCopy}
                    type="ghost"
                    action={() => handleCopyToClipboard(auditWithReceipt.tx?.transactionHash)}
                  />
                </div>
              </InfoSection>
            )}
            {/* {auditNotarizations[0].explorer && ( 
            <InfoSection label={t('view_audit.blockchain_explorer_api') as string}>
              <Typography>{auditNotarizations[0].explorer}</Typography>
              <div className="w-min">
                <Button
                  icon={IconLink}
                  type="ghost"
                  action={() => handleCopyToClipboard(audit.notes)}
                />
              </div>
            </InfoSection>
          )} */}
          </div>

          <div className="flex w-1/2 flex-col gap-4">
            <InfoSection label={t('view_audit.date') as string}>
              <Typography size="body-medium-16">{formatDate(audit?.date)}</Typography>
            </InfoSection>
            {audit.tags && audit.tags.length > 0 && (
              <InfoSection label={t('view_audit.tags') as string}>
                <div className="grid grid-cols-3 gap-3 ">
                  {audit.tags.map((tag: string) => {
                    return <Tag key={tag} title={tag} />;
                  })}
                </div>
              </InfoSection>
            )}
            {audit.externalUrl && (
              <InfoSection label={t('view_audit.storage') as string}>
                <Typography> {t('view_audit.link')}</Typography>
                <div className="w-min">
                  <Button
                    icon={IconCopy}
                    type="ghost"
                    action={async () =>
                      handleCopyToClipboard(storageLink ?? (await handleGetAuditExternalUrl()))
                    }
                  />
                </div>
                {/* {workspace?.permissions.audit.write && (
                  <div className="w-min">
                    <Button
                      icon={IconClose}
                      iconStroke="error"
                      type="ghost"
                      action={() => handleCopyToClipboard(auditNotarizations[0].notes)}
                    />
                  </div>
                )} */}
              </InfoSection>
            )}
          </div>
        </div>

        <div className="flex w-full flex-row justify-center gap-4 pb-6">
          {audit.externalUrl && (
            <div className="w-inherit flex">
              <Button
                type="secondary"
                id="createFolder"
                className="w-[220px]"
                action={handleDownloadFileFromExternalUrl}
              >
                {t('view_audit.buttons.download_file')}
              </Button>
            </div>
          )}
          {isReportDownloadable() && (
            <div className="w-inherit flex">
              <PDFDownloadLink
                className="w-full"
                fileName={audit.name}
                document={
                  <ReportNotarization
                    notarizations={auditNotarizations}
                    audit={auditWithReceipt}
                    externalUrls={externalUrl}
                  />
                }
              >
                <Button
                  type="primary"
                  action={() => {}}
                  className="w-[220px]"
                  disabled={externalUrlStatus !== 'success'}
                >
                  {externalUrlStatus !== 'success' ? (
                    <IconLoading className="size-12 animate-spin text-primary-500" />
                  ) : (
                    t('view_audit.buttons.download_report_notarizations')
                  )}
                </Button>
              </PDFDownloadLink>
            </div>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

const AuditDetailsModal = ({ children }: AuditDetailsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="AuditDetailsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AuditDetailsModal;
