import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParametersFromUrl } from '../../imports/utils';

interface AuthTemplateParams {
  mode: string;
  oobCode: string;
  email?: string;
}

const paramIsValid = (params: unknown): params is AuthTemplateParams => {
  const paramsAsAny = params as any;
  return 'mode' in paramsAsAny && 'oobCode' in paramsAsAny;
};

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = getParametersFromUrl(location.search);

    if (!paramIsValid(params)) {
      navigate('/', { replace: true });
      return;
    }

    const { mode, oobCode } = params;
    navigate(`/redirect/${mode}`, { state: { oobCode, email: params?.email }, replace: true });
  }, []);

  return <>Redirecting....</>;
};

export default Redirect;
