import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImageContract } from '../../../../assets/images';
import { Button, Image as ImageComponent, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { delayed } from '../../../../imports/utils';
import { updateContract } from '../../api';

type DeleteContractProps = {
  handleCloseSidebar: () => void;
  contractId: string;
  contractName: string;
};
const DeleteContract = ({ handleCloseSidebar, contractId, contractName }: DeleteContractProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteContract = async () => {
    setIsLoading(true);
    handleCloseSidebar();

    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('collection_item.pending.deleting_contract'),
      },
    });

    //const { value, error } = await deleteContract(contractId);
    const { value, error } = await updateContract(contractId, {
      costs: {
        deployCost: 0,
        mints: 0,
        transfers: 0,
        whitelistCost: 0,
      },
      userId: 'UNKNOWN',
      workspace_id: 'UNKNOWN',
      visibility: { isDisabled: true },
    });
    if (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('collection_item.error.something_went_wrong'),
        },
      });
    }

    if (value) {
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('collection_item.success.contract_deleted'),
        },
      });
    }

    delayed(() => {
      loadingStatusDispatch({ type: 'SET_HIDE' });

      if (value) {
        navigate('/nft');
      }
    }, 2500);
  };

  return (
    <>
      <ImageComponent src={ImageContract} alt={t('image.folder')} className="w-[50px]" />

      <Typography weight="medium" size="lg">
        {t('collection.delete_contract.title', { name: contractName })}
      </Typography>

      <Button type="primary" action={handleDeleteContract} loading={isLoading}>
        {t('collection.delete_contract.submit_data')}
      </Button>
    </>
  );
};

export default DeleteContract;
