import axios from 'axios';
import { ethers } from 'ethers';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import i18next, { t } from 'i18next';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import {
  apiEndpoint,
  backendEndpoint,
  pablockConfig,
  rpcProvider,
} from '../../../imports/constants';
import { auth, db } from '../../../imports/firebase';
import { setWorkspace } from '../../../redux/team/team.slice';

import teamsStorageAbi from '../../../abi/teamsStorage';
import { bcodeSDK } from '../../../imports/bcodeSDK';
import {
  Customer,
  GroupCreationData,
  Permissions,
  Workspace,
  WorkspaceInvitation,
  WorkspaceMember,
} from '../imports/types';

const ONLY_TOKEN_CREATOR = {
  token_creator: {
    read: true,
    write: true,
    update: true,
    delete: true,
  },
  notarization: {
    read: false,
    write: false,
    update: false,
    delete: false,
  },
  audit: {
    read: false,
    write: false,
    update: false,
    delete: false,
  },
  team: {
    read: false,
    write: false,
    update: false,
    delete: false,
  },
};

export const createTeam = async (name: string) => {
  try {
    const response = await axios.post(
      `${backendEndpoint}/team/create-workspace`,
      { name },
      { headers: { authorization: await auth.currentUser?.getIdToken()! } }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export const addGroup = async (group: GroupCreationData) => {
  try {
    await axios.post(
      `${backendEndpoint}/team/create-group`,
      { ...group },
      { headers: { authorization: await auth.currentUser?.getIdToken()! } }
    );
  } catch (e) {
    console.error(e);
    return { error: e };
  }
};

export const addMember = async (invitation: WorkspaceInvitation) => {
  await axios.post(
    `${backendEndpoint}/team/create-invitation`,
    { ...invitation },
    { headers: { authorization: await auth.currentUser?.getIdToken()! } }
  );
};

export const acceptInvitation = async (workspaceId: string, invitationId: string) => {

  await axios.post(
    `${backendEndpoint}/team/accept-invitation/${workspaceId}/${invitationId}`,
    {},
    { headers: { authorization: await auth.currentUser?.getIdToken()! } }
  );
};

export const declineInvitation = async (workspaceId: string, invitationId: string) => {
  await axios.post(
    `${backendEndpoint}/team/decline-invitation/${workspaceId}/${invitationId}`,
    {},
    { headers: { authorization: await auth.currentUser?.getIdToken()! } }
  );
};

export const updatePermissions = async (workspaceId: string, email: string) => {
  await axios.post(
    `${backendEndpoint}/team/update-permissions`,
    {
      workspace_id: workspaceId,
      users: [
        {
          email,
          permissions: ONLY_TOKEN_CREATOR,
        },
      ],
    },
    { headers: { authorization: await auth.currentUser?.getIdToken()! } }
  );
};

export const deleteUser = async (workspaceId: string, email: string) => {
  await axios.post(
    `${backendEndpoint}/team/delete-user`,
    {
      workspace_id: workspaceId,
      users: [email],
    },
    { headers: { authorization: await auth.currentUser?.getIdToken()! } }
  );
};

export const getWorkspace = async (workspaceId: string) => {
  const workspace = (await getDoc(doc(db, 'workspaces', workspaceId))).data() as Workspace;
  return workspace;
};

export const getWorkspaceMember = async (workspaceId: string, memeberUid: string) => {
  const workspaceMember = (
    await getDoc(doc(db, 'workspaces', workspaceId, 'members', memeberUid))
  ).data() as WorkspaceMember;

  return workspaceMember;
};
export const getWorkspaceCustomers = async (workspaceOwner: string) => {
  const querySnapshot = await getDocs(collection(db, 'CRM', workspaceOwner, 'customers'));
  const customers: Customer[] = [];
  querySnapshot.forEach(async (doc) => {
    customers.push({ ...doc.data(), customerId: doc.id } as Customer);
  });

  return customers;
};
export const saveWorkspaceToStore = async (
  workspaceId: string,
  workspaceOwner: string,
  uid: string,
  dispatch: Dispatch<AnyAction>,
  groupId?: string
) => {
  const { name, owner, owner_address } = await getWorkspace(workspaceId);
  const { permissions } = await getWorkspaceMember(workspaceId, uid);
  const customers = await getWorkspaceCustomers(workspaceOwner || '');
  dispatch(
    setWorkspace({
      id: workspaceId,
      owner,
      name,
      permissions,
      ownerAddress: owner_address,
      isOwner: uid === owner,
      customers,
      ...(groupId ? { inGroup: true, groupId } : {}),
    })
  );

  if (owner !== uid) {
    toast.info(t('workspace.other-team', { team: name }) as string, {
      autoClose: false,
    });
  }
};

export const fetchWorkspaces = async (userId: string) => {
  const userWorkspaces = await getDocs(collection(db, 'users', userId, 'workspaces'));

  const workspaces = await Promise.all(
    userWorkspaces.docs.map(async (workspace) => {
      const workspaceDoc = await getDoc(doc(db, 'workspaces', workspace.id));

      return workspaceDoc.data() as Workspace;
    })
  );

  workspaces.sort((a, b) => (a.owner === userId ? -1 : 1));

  return workspaces;
};

export const grantPermission = async (
  privateKey: string,
  workspaceId: string,
  addressToVerify: string,
  userId: string,
  permission: boolean[]
) => {
  const bytes32WorkspaceId = ethers.utils.formatBytes32String(workspaceId);

  if (!bcodeSDK.isInitialized()) {
    await bcodeSDK.init();
  }

  bcodeSDK.setPrivateKey(privateKey);

  if (pablockConfig.pablockContracts.PABLOCK_TEAM_STORAGE) {
    const nonce = await bcodeSDK.getNonce(bcodeSDK.getWalletAddress());

    const metaTx = await bcodeSDK.prepareTransaction(
      {
        address: pablockConfig.pablockContracts.PABLOCK_TEAM_STORAGE,
        abi: teamsStorageAbi,
        name: 'TeamsManager',
        version: '0.0.1',
      },
      'createAndSetPermission',
      [bytes32WorkspaceId, addressToVerify, permission],
      { nonce: nonce.toNumber() }
    );

    // const requestId: string = await bcodeSDK.executeAsyncTransaction(metaTx, {
    //   webhookUrl: `${backendEndpoint}/team/verify/${workspaceId}/${userId}`,
    //   verbose: true,
    // });

    await axios.post(
      `${apiEndpoint}/executeGrantPermission/`,
      { metaTx, workspaceId, userId },
      { headers: { 'x-access-token': await auth.currentUser?.getIdToken()! } }
    );
  } else {
    throw Error('MIssing Team Storage contract address');
  }
};

/**
 * @description Function that return a boolean value correspongin with user permission over
 * module/operationType/rule
 * It's used into completePermissionCheck of alone in modules that need only DB permissions check
 * @param workspaceId
 * @param userId
 * @param type
 * @param rule
 * @returns boolean
 */
export async function grantPermissionsOnDb(
  workspaceId: string,
  userId: string,
  permissions: Permissions,
  writePermissionChanged: boolean
): Promise<boolean | { error: string }> {
  try {
    const userDoc = doc(collection(db, `workspaces/${workspaceId}/members/`), userId);
    const user = await getDoc(userDoc);

    if (!user.exists()) return { error: 'user.not.exists' };

    const permissionChanged: { permissions: Permissions; status?: string } = {
      permissions,
    };

    if (writePermissionChanged) {
      permissionChanged.status = 'pending';
    }
    setDoc(
      userDoc,
      {
        ...permissionChanged,
      },
      { merge: true }
    );

    return true;
  } catch (e) {
    console.error(e);
    return { error: JSON.stringify(e) };
  }
}

export const isVerifiedOnChain = async (address: string, workspaceId: string) => {
  const provider = new ethers.providers.JsonRpcProvider(rpcProvider);
  const contract = new ethers.Contract(
    process.env.REACT_APP_BCODE_TEAM_STORAGE!, 
    teamsStorageAbi,
    provider
  );
  const bytes32WorkspaceId = ethers.utils.formatBytes32String(workspaceId);

  const verified = await contract.getPermissions(bytes32WorkspaceId, address);
  return verified.reduce((acc: boolean, cur: boolean) => cur || acc, false) as boolean;
};

export const isOnlyReader = (member: WorkspaceMember) => {
  const modules = Object.keys(member.permissions);

  if (modules.length) {
    const canWrite = modules.map((module) => member.permissions[module].write);
    if (canWrite.includes(true)) {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * Gets all the workspace that the caller uid is member of
 */
export async function getTeam() {
  try {
    const token = await auth.currentUser?.getIdToken()!;
    const workspacesRequest = await axios.get(`${backendEndpoint}/team/get-teams`, {
      headers: {
        authorization: token,
      },
    });

    const workspaces = workspacesRequest.data;

    return workspaces.filter((ws: Workspace) => ws) as Workspace[];
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function handleBackendError(error: unknown) {
  console.error(error);
  const errorMessage = (error as any)?.response?.data?.error;
  const key = `errors.${errorMessage}`;
  if (i18next.exists(key, { ns: 'team' })) {
    toast.error(t(key, { ns: 'team' }) as string);
  } else {
    toast.error(t('errors.generic', { ns: 'team' }) as string);
  }
}
