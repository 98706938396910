import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '../../components';
import { IconOffline } from '../../icons';

type NoInternetConnectionProps = {
  children: ReactElement;
};

const NoInternetConnection = ({ children }: NoInternetConnectionProps) => {
  const { t } = useTranslation();

  const [isOnline, setOnline] = useState(true);
  useEffect(() => {
    window.addEventListener('online', () => {
      setOnline(true);
    });
    window.addEventListener('offline', () => {
      setOnline(false);
    });
  }, []);

  return isOnline ? (
    <>{children}</>
  ) : (
    <div className="flex h-screen flex-col items-center justify-center gap-4 ">
      <Icon icon={IconOffline} size="xxxl" stroke="primary-500" />
      <Typography size="body-semibold-30" className="">
        {t(`network.ops`)}
      </Typography>
      <Typography size="body-semibold-20" className="">
        {t(`network.offline`)}
      </Typography>
      <Typography size="body-semibold-20" className="">
        {t(`network.connect`)}
      </Typography>
    </div>
  );
};

export default NoInternetConnection;
