import { IconApi, IconFileNotarized, IconFolderNotch } from '../../../icons';
import type { Routes } from '../../../imports/types';

import { Collection, Folder } from '../pages';

const routes: Routes[] = [
  {
    name: 'notarizations',
    path: '/',
    component: Collection,
    // hasSidebar: true,
    isHome: true,
    topbarIcon: IconFileNotarized,
    requiresPermission: {
      moduleName: 'notarization',
      permissions: ['read'],
    },
  },
  {
    name: 'new_notarization',
    path: '/?new=1',
    component: Collection,
    topbarIcon: IconFileNotarized,
    // hasSidebar: true,
    requiresPermission: {
      moduleName: 'notarization',
      permissions: ['write'],
    },
  },
  {
    name: 'folder',
    path: '/folder/:id',
    topbarIcon: IconFolderNotch,
    component: Folder,
  },
  {
    name: 'api_settings',
    path: '/?settings=1',
    component: Collection,
    topbarIcon: IconApi,
    // hasSidebar: true,
    requiresPermission: {
      moduleName: 'notarization',
      permissions: ['write'],
    },
  },
];

export default routes;
