import type { ReactNode } from 'react';
import { createElement } from 'react';

import { textColors } from '../../imports/constants';
import { Colors } from '../../imports/types';

type TypographyProps = {
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'; //questo andrà rimosso
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | 'h3'
    | 'h4'
    | 'body-regular-10'
    | 'body-regular-12'
    | 'body-regular-14'
    | 'body-regular-16'
    | 'body-regular-18'
    | 'body-regular-20'
    | 'body-medium-12'
    | 'body-medium-14'
    | 'body-medium-16'
    | 'body-medium-18'
    | 'body-medium-20'
    | 'body-medium-30'
    | 'body-semibold-18'
    | 'body-semibold-20'
    | 'body-semibold-30'
    | 'body-bold-16';
  weight?: 'regular' | 'medium' | 'semibold' | 'bold' | 'light'; //questo andrà rimosso
  family?: 'regular' | 'italic';
  color?: Colors;
  isLink?: boolean;
  id?: string;
};

const Typography = ({
  children,
  className = '',
  as = 'p',
  size = 'md',
  weight = 'regular',
  family = 'regular',
  color = 'inherit',
  isLink,
  id,
}: TypographyProps) => {
  const getTypographySize = () => {
    const typographySize: { [key: string]: string } = {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      h3: 'text-h3',
      h4: 'text-h4',
      'body-regular-10': 'text-body-regular-10',
      'body-regular-12': 'text-body-regular-12',
      'body-regular-14': 'text-body-regular-14',
      'body-regular-16': 'text-body-regular-16',
      'body-regular-18': 'text-body-regular-18',
      'body-regular-20': 'text-body-regular-20',
      'body-medium-12': 'text-body-medium-12',
      'body-medium-14': 'text-body-medium-14',
      'body-medium-16': 'text-body-medium-16',
      'body-medium-18': 'text-body-medium-18',
      'body-medium-20': 'text-body-medium-20',
      'body-medium-30': 'text-body-medium-30',
      'body-semibold-18': 'text-body-semibold-18',
      'body-semibold-20': 'text-body-semibold-20',
      'body-semibold-30': 'text-body-semibold-30',
      'body-bold-16': 'text-body-bold-16',
    };
    return typographySize[size];
  };

  const getTypographyWeight = () => {
    const typographyWeight: { [key: string]: string } = {
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      light: 'font-light',
    };
    return typographyWeight[weight];
  };

  const getTypographyFamily = () => {
    const typographyFamily: { [key: string]: string } = {
      regular: 'not-italic',
      italic: 'italic',
    };
    return typographyFamily[family];
  };

  const getTypographyLink = () => {
    let style = '';

    if (isLink) {
      style = 'hover:underline hover:text-secondary-500';
    }

    return style;
  };

  const getTypographyColor = () => textColors[color];

  const typographyStyle = `${getTypographyFamily()} ${getTypographySize()} ${getTypographyWeight()} ${getTypographyColor()} ${getTypographyLink()} ${className}`;

  return createElement(as, { className: typographyStyle, id }, children);
};

export default Typography;
