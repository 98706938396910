/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconFastForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38853)">
      <path
        d="M181.125 90.9749L114.225 47.9249C113.315 47.3449 112.266 47.021 111.187 46.9873C110.109 46.9536 109.041 47.2114 108.097 47.7334C107.153 48.2555 106.367 49.0225 105.822 49.9537C105.277 50.8849 104.993 51.9459 105 53.0249V138.975C104.993 140.054 105.277 141.115 105.822 142.046C106.367 142.977 107.153 143.744 108.097 144.266C109.041 144.788 110.109 145.046 111.187 145.012C112.266 144.979 113.315 144.655 114.225 144.075L181.125 101.025C181.978 100.492 182.681 99.7517 183.168 98.8726C183.655 97.9936 183.911 97.005 183.911 95.9999C183.911 94.9948 183.655 94.0062 183.168 93.1271C182.681 92.2481 181.978 91.5074 181.125 90.9749V90.9749Z"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.1251 90.9749L30.2251 47.9249C29.3153 47.3449 28.2659 47.021 27.1874 46.9873C26.1089 46.9536 25.0413 47.2114 24.097 47.7334C23.1527 48.2555 22.3667 49.0225 21.8217 49.9537C21.2767 50.8849 20.9929 51.9459 21.0001 53.0249V138.975C20.9929 140.054 21.2767 141.115 21.8217 142.046C22.3667 142.977 23.1527 143.744 24.097 144.266C25.0413 144.788 26.1089 145.046 27.1874 145.012C28.2659 144.979 29.3153 144.655 30.2251 144.075L97.1251 101.025C97.9776 100.492 98.6805 99.7517 99.1679 98.8726C99.6552 97.9936 99.9109 97.005 99.9109 95.9999C99.9109 94.9948 99.6552 94.0062 99.1679 93.1271C98.6805 92.2481 97.9776 91.5074 97.1251 90.9749V90.9749Z"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38853">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFastForward;
