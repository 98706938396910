type ProgressBarProps = {
  partial: number;
  total: number;
  className?: string;
};

const ProgressBar = ({ partial, total, className }: ProgressBarProps) => {
  const percentage = Math.ceil((partial / total) * 100);

  return (
    <div className={`h-[10px] w-full rounded-[10px] bg-grey-300 ${className}`}>
      <div
        className="h-[10px] rounded-[10px] bg-primary-500"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};
export default ProgressBar;
