import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/* root imports */
import { getUserAuthToken } from '../../../../api/firebase';
import { MainLogoPng } from '../../../../assets/images';
import { explorerPolygon, paidIpfsGateway } from '../../../../imports/constants';
import { formatDate, formatIPFSUri } from '../../../../imports/utils';
/* import audit */
import type { Audit } from '../../../audit/imports/types';

/* import notarization */
import { getFileFromStorage } from '../../api';
import type { Notarization, Receipt } from '../../imports/types';

type ReportPageProps = {
  children: React.ReactNode | React.ReactNode[];
};
const ReportPage = ({ children }: ReportPageProps) => (
  <Page size="A4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <View style={{ width: '90%', height: '90%' }}>
      <View
        style={{
          width: '100%',
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 10,
          borderColor: '#2ca6ff',
          borderBottomWidth: 2,
        }}
      >
        <Image src={MainLogoPng} style={{ width: 'auto', height: '100%' }} />
      </View>

      <View style={{ width: '100%', height: '100%' }}>{children}</View>
    </View>
  </Page>
);

type ReportTableTitleProps = {
  children: React.ReactNode;
};
const ReportTableTitle = ({ children }: ReportTableTitleProps) => (
  <View style={{ margin: 20 }}>
    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{children}</Text>
  </View>
);

type TableRow = { key: string; label: string; linkTo?: any; value: any };

type ReportTableProps = {
  row: TableRow;
  isNotarizationsList?: boolean;
};
const ReportTable = ({ row, isNotarizationsList }: ReportTableProps) => {
  const { t } = useTranslation(['notarization']);
  const { key, label, linkTo, value } = row;

  return (
    <View
      style={{
        width: '100%',
        minHeight: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          width: '30%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderColor: 'black',
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 12, color: '#1e679c' }}>
          {isNotarizationsList ? label : t(`report.table_fields.${label}`)}
        </Text>
      </View>

      <View
        style={{
          width: '70%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 10,
          borderWidth: 1,
          borderColor: 'black',
        }}
      >
        {linkTo && value ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            style={{ fontSize: 10, color: '#2ca6ff', textDecoration: 'underline' }}
            src={linkTo}
          >
            <Text style={{ fontSize: 10 }}>{value.toString()}</Text>
          </Link>
        ) : (
          <Text style={{ fontSize: 10 }}>{value}</Text>
        )}
      </View>
    </View>
  );
};

type RenderReportPageProps = {
  notarization?: Notarization & Receipt;
  notarizationsList?: (Notarization & Receipt) | Array<Notarization & Receipt>;
  audit?: Audit;
  externalUrls?:
    | (
        | {
            hash: string;
            externalUrl: any;
          }
        | {
            hash: string;
            externalUrl: string;
          }
      )[]
    | undefined;
};
const RenderReportPage = ({
  notarization,
  notarizationsList,
  audit,
  externalUrls,
}: RenderReportPageProps) => {
  const { t } = useTranslation(['notarization']);

  const [externalUrl, setExternalUrl] = useState<string>('');
  const [multipleExternalUrls, setMultipleExternalUrls] = useState<
    { hash: string; externalUrl: string }[]
  >([]);

  const bcodelink = paidIpfsGateway;
  const chainLink = explorerPolygon;

  const getPageTitle = () => {
    let title = '';

    if (audit) {
      title = t('report.audit_data');
    }
    if (notarization) {
      title = t('report.notarization_data');
    }
    if (notarizationsList) {
      title = t('report.notarizations_list');
    }

    return title;
  };

  const handleGetExternalUrl = async () => {
    const { value: authTokenId } = await getUserAuthToken();

    if (notarization && notarization.externalUrl) {
      getFileFromStorage(notarization.externalUrl, authTokenId).then((res) => {
        setExternalUrl(res.value.url);
      });
    }
    if (externalUrls) {
      setMultipleExternalUrls(externalUrls);
    } else if (notarizationsList && Array.isArray(notarizationsList)) {
      const externalUrls = await Promise.all(
        notarizationsList.map((notarization) => {
          if (notarization.externalUrl) {
            return getFileFromStorage(notarization.externalUrl, authTokenId).then((res) => {
              return { hash: notarization.hash, externalUrl: res.value.url };
            });
          }
          return { hash: notarization.hash, externalUrl: '' };
        })
      );
      setMultipleExternalUrls(externalUrls);
    }
  };

  const getNotarizationTable = (data: Notarization & Receipt) => {
    const getStorageKey = () => {
      if (data.externalUrl) {
        return 'externalUrl';
      }
      return 'ipfsUri';
    };

    const getStorageLinkTo = () => {
      let linkTo = '';
      if (data.ipfsUri) {
        linkTo = `${bcodelink}/${data.ipfsUri}`;
      }
      if (data.externalUrl) {
        linkTo = externalUrl;
      }
      return linkTo;
    };

    const getStorageValue = () => {
      let value = t('report.file_not_available');
      if (data.ipfsUri) {
        value = t('report.table_fields.ipfs');
      }
      if (data.externalUrl) {
        value = t('report.table_fields.bcode');
      }
      return value;
    };

    return [
      { key: 'name', label: 'name', value: data.name },
      { key: 'description', label: 'description', value: data.description },
      { key: 'date', label: 'date', value: formatDate(data.date!) },
      { key: 'hash', label: 'hash', value: data.hash },
      {
        key: getStorageKey(),
        label: 'storage',
        linkTo: getStorageLinkTo(),
        value: getStorageValue(),
      },
      { key: 'notes', label: 'notes', value: data.notes },
    ];
  };

  const getAuditTable = (data: Audit) => [
    { key: 'name', label: 'name', value: data.name },
    { key: 'description', label: 'description', value: data.description },
    { key: 'date', label: 'date', value: formatDate(data.date!) },
    { key: 'hash', label: 'hash', value: data.transactionHash },
    // { key: 'from', label: 'from', value: data.tx?.from },
    // { key: 'to', label: 'to', value: data.tx?.to },
  ];

  const getMaticTransactionTable = (data: (Notarization & Receipt) | Audit) => {
    const formattedMerkleTreeURI = formatIPFSUri(data.merkleTreeURI!);

    return [
      { key: 'from', label: 'from', value: data.tx?.from },
      { key: 'to', label: 'to', value: data.tx?.to },
      {
        key: 'transactionHash',
        label: 'transaction_hash',
        linkTo: `${chainLink}/tx/${data.tx?.transactionHash}`,
        value: data.tx?.transactionHash,
      },
      {
        key: 'blockNumber',
        label: 'block_number',
        value: data.tx?.blockNumber,
      },
      {
        key: 'blockHash',
        label: 'block_hash',
        value: data.tx?.blockHash,
      },
      {
        key: 'merkleTreeURI',
        label: 'merkle_tree_uri',
        linkTo: formattedMerkleTreeURI,
        value: formattedMerkleTreeURI,
      },
    ];
  };

  const getNotarizationsListTable = () =>
    (notarizationsList as Array<Notarization & Receipt>).map((notarization) => {
      const url = multipleExternalUrls.find((url) => url.hash === notarization.hash);

      const getLinkTo = () => {
        const linkTo = { linkTo: '', value: t('report.file_not_available') };

        if (url && url.externalUrl) {
          linkTo.linkTo = url.externalUrl;
          linkTo.value = t('report.table_fields.bcode');
        }
        if (notarization.ipfsUri) {
          linkTo.linkTo = `${bcodelink}/${notarization.ipfsUri}`;
          linkTo.value = t('report.table_fields.ipfs');
        }
        return linkTo;
      };

      return {
        key: notarization.hash,
        label: notarization.name,
        ...getLinkTo(),
      };
    });

  useEffect(() => {
    if (externalUrl.length === 0) {
      handleGetExternalUrl();
    }
  }, []);

  return (
    <ReportPage>
      <ReportTableTitle>{getPageTitle()}</ReportTableTitle>
      {audit && getAuditTable(audit).map((row, index) => <ReportTable key={index} row={row} />)}
      {audit && (
        <>
          <ReportTableTitle>{t('report.matic_transaction')}</ReportTableTitle>
          {getMaticTransactionTable(audit).map((row, index) => (
            <ReportTable key={index} row={row} />
          ))}
        </>
      )}
      {notarization &&
        getNotarizationTable(notarization).map((row, index) => (
          <ReportTable key={index} row={row} />
        ))}
      {notarizationsList &&
        getNotarizationsListTable().map((row: any, index) => (
          <ReportTable key={index} row={row} isNotarizationsList />
        ))}
      {notarization && (
        <>
          <ReportTableTitle>{t('report.matic_transaction')}</ReportTableTitle>
          {getMaticTransactionTable(notarization).map((row, index) => (
            <ReportTable key={index} row={row} />
          ))}
        </>
      )}
    </ReportPage>
  );
};

type ReportProps = {
  notarizations: (Notarization & Receipt) | (Notarization & Receipt)[];
  audit?: Audit;
  externalUrls?:
    | (
        | {
            hash: string;
            externalUrl: any;
          }
        | {
            hash: string;
            externalUrl: string;
          }
      )[]
    | undefined;
};
const ReportNotarization = ({ notarizations, audit, externalUrls }: ReportProps) => {
  const { t } = useTranslation(['notarization']);

  const isNotarizationsArray = Array.isArray(notarizations);

  const singleNotarization = isNotarizationsArray ? notarizations[0] : notarizations;

  return (
    <Document>
      {audit && <RenderReportPage audit={audit} />}
      {audit && (notarizations as Array<Notarization & Receipt>).length > 0 && (
        <RenderReportPage notarizationsList={notarizations} />
      )}

      {isNotarizationsArray ? (
        notarizations.map((notarization, index) => (
          <RenderReportPage key={index} notarization={notarization} externalUrls={externalUrls} />
        ))
      ) : (
        <RenderReportPage notarization={notarizations} />
      )}
    </Document>
  );
};

export default ReportNotarization;
