import isArray from 'lodash/isArray';
import { Fragment, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { APP_VERSION } from './imports/constants';

import { logOut } from './api/firebase';
import { logOutContracts } from './redux/contracts/contracts.slice';
import { logOutPolls, logOutSubmitions } from './redux/polls/polls.slice';
import { logOutTeam } from './redux/team/team.slice';
import { logOutUser } from './redux/user/user.slice';

import { enableRemoteConfig } from './imports/remoteConfig';
import routes from './imports/routes';
import Layout from './pages/Layout';
import { logOutFunctionalities } from './redux/functionalities/functionalities.slice';
import { useAppDispatch } from './redux/hooks';
import { logOutSubscription } from './redux/subscription/subscription.slice';
import { logOutTemplate } from './redux/templates/templates.slice';

// REVIEW: can we improve this code? It's a bit long and verbose, it seems that we cannot encapsulate the logic for rendering a route in a component that has multiple paths due to a react router limitation.

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('version') !== APP_VERSION) {
      localStorage.setItem('version', APP_VERSION);

      logOut().then(() => {
        dispatch(logOutUser());
        dispatch(logOutTeam());
        dispatch(logOutContracts());
        dispatch(logOutPolls());
        dispatch(logOutSubmitions());
        dispatch(logOutFunctionalities());
        dispatch(logOutSubscription());
        dispatch(logOutTemplate());
      });
    }

    // Here we have to fetch remoteConfig
    enableRemoteConfig();
  }, []);

  return (
    <Router>
      <Routes>
        {routes.map((route) => {
          const { childRoutes, name, path } = route;

          return childRoutes && childRoutes.length > 0 ? (
            <Fragment key={name}>
              {isArray(path) ? (
                <>
                  {path.map((path) => (
                    <Route key={path} path={path} element={<Layout route={route} />}>
                      {childRoutes?.map((childRoute) => {
                        const {
                          component: ChildComponent,
                          name: childName,
                          path: childPath,
                        } = childRoute;

                        return (
                          <Fragment key={childName}>
                            {isArray(childPath) ? (
                              <>
                                {childPath.map((childPath) => (
                                  <Route
                                    key={`${path}${childPath}`}
                                    path={`${path}${childPath}`}
                                    element={<ChildComponent route={childRoute} />}
                                  />
                                ))}
                              </>
                            ) : (
                              <Route
                                path={`${path}${childPath}`}
                                element={<ChildComponent route={childRoute} />}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </Route>
                  ))}
                </>
              ) : (
                <Route path={path} element={<Layout route={route} />}>
                  {childRoutes?.map((childRoute) => {
                    const {
                      component: ChildComponent,
                      name: childName,
                      path: childPath,
                    } = childRoute;

                    return (
                      <Fragment key={childName}>
                        {isArray(childPath) ? (
                          <>
                            {childPath.map((childPath) => (
                              <Route
                                key={`${path}${childPath}`}
                                path={`${path}${childPath}`}
                                element={<ChildComponent route={childRoute} />}
                              />
                            ))}
                          </>
                        ) : (
                          <Route
                            path={`${path}${childPath}`}
                            element={<ChildComponent route={childRoute} />}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </Route>
              )}
            </Fragment>
          ) : (
            <Fragment key={name}>
              {isArray(path) ? (
                <>
                  {path.map((path) => (
                    <Route key={path} path={path} element={<Layout route={route} />} />
                  ))}
                </>
              ) : (
                <Route path={path} element={<Layout route={route} />} />
              )}
            </Fragment>
          );
        })}
      </Routes>
    </Router>
  );
};
export default App;
