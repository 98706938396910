import type { Routes } from '../../imports/types';

import Layout from './pages/Layout';

type TokenCreatorProps = {
  route: Routes;
};
const TokenCreator = ({ route }: TokenCreatorProps) => <Layout route={route} />;

export default TokenCreator;
