import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CustomizedText, NftCard, Tag, Typography } from '../../../../components';
import { truncateText } from '../../../../imports/utils';
import { typeOfQrCode } from '../../imports/constants';
import { Nft, Template } from '../../imports/types';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

interface ISummaryStep {
  values: any;
  onSubmit: () => void;
  onBack: () => void;
  templates: Template[];
}

const SummaryStep: React.FC<ISummaryStep> = ({ values, onSubmit, onBack, templates }) => {
  const { t } = useTranslation(['tokenCreator']);
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;
  const ref = useRef<HTMLDivElement>(null);
  const prefixKey = (suffix: string) => t(`create_contract.form_labels.${suffix}`);

  const detailsField = (label: string, value: any) => {
    return (
      <div className="flex flex-col gap-1">
        <Typography weight="semibold">{label.toUpperCase()}</Typography>
        <Typography>{value}</Typography>
      </div>
    );
  };

  const totalNftResult = () => {
    let sumQuantity: number = 0;
    let unlimited = false;
    values.nfts.forEach((nft: { quantity: any; isUnlimited: boolean }) => {
      if (nft.isUnlimited) {
        unlimited = true;
      } else {
        sumQuantity += parseInt(nft.quantity, 10);
      }
    });

    if (sumQuantity === 0 && unlimited) {
      return t('create_contract.form_labels.unlimited');
    }
    if (unlimited) {
      return t('create_contract.form_labels.unlimitedPlus', {
        sumQuantity,
      });
    }
    return sumQuantity;
  };
  const templateSelected = templates.find((temp) => temp.name === values.template);

  const calculateNftsTotal = (nfts: Nft[]) => {
    let result: string | number;
    if (nfts.find((nft: Nft) => nft.isUnlimited)) {
      result = t('nft.unlimited') as string;
    } else {
      result = nfts.reduce(
        (total: number, nft: { quantity: any }) => parseInt(nft.quantity, 10) + total,
        0
      );
    }
    return result.toString();
  };

  return (
    <div className="space-y-8">
      <div className="flex flex-col ">
        <div className="flex flex-row gap-x-4 py-4">
          <Typography as="h2" size="xl" className="text-center">
            {values.name}
          </Typography>
          {!template.fields?.tag?.hidden && values.tag && values.tag?.length > 0 && (
            <div className="flex items-center space-x-2">
              {values.tag.map((tag: any) => (
                <Tag key={tag} title={tag} />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <Typography className="col-span-3 py-4">{t('collection_item.summary.general')}</Typography>
        {!template.fields?.name?.hidden &&
          detailsField(
            template.fields?.name?.label?.[language] || t('create_contract.form_labels.name'),
            values.name
          )}
        {!template.fields?.status?.hidden &&
          detailsField(
            template.fields?.status?.label?.[language] || t('collection_item.summary.status'),
            values.status
          )}
        {!template.fields?.chain?.hidden &&
          detailsField(
            template.fields?.status?.label?.[language] || t('collection_item.summary.chain'),
            values.chain
          )}

        <div className="row-span-2">
          {!template.fields?.description?.hidden &&
            detailsField(
              template.fields?.description?.label?.[language] ||
                t('create_contract.form_labels.description'),
              <CustomizedText text={values.description} />
            )}
        </div>

        {!template.fields?.doesDropDate?.hidden &&
          detailsField(
            template.fields?.doesDropDate?.label?.[language] ||
              t('collection_item.summary.drop_date'),
            values.dropDate || 'N/A'
          )}
        {/* {detailsField(t('collection_item.summary.contract') as string, '??')} */}

        {!template.fields?.type?.hidden &&
          detailsField(
            template.fields?.type?.label?.[language] || t('collection_item.summary.type'),
            values.type
          )}
        {!template.fields?.owner?.hidden &&
          detailsField(
            template.fields?.owner?.label?.[language] || t('collection_item.summary.owner'),
            truncateText(values.owner, {
              maxLength: 20,
              rightChars: 5,
            })
          )}
      </div>

      <div className="grid grid-cols-3 gap-2">
        <Typography className="col-span-3 py-4">{t('collection_item.summary.specific')}</Typography>
        {!template.fields?.totalTokens?.hidden &&
          detailsField(
            template.fields?.totalTokens?.label?.[language] ||
              t('collection_item.summary.total_nft'),
            totalNftResult()
          )}
        {!template.fields?.maxTokensPerUser?.hidden &&
          detailsField(
            template.fields?.maxTokensPerUser?.label?.[language] ||
              t('create_contract.form_labels.nft_per_wallet'),
            values.maxTokensPerUser
          )}
        {!template.fields?.update?.hidden &&
          detailsField(
            template.fields?.maxTokensPerUser?.label?.[language] ||
              t('collection_item.summary.doesUpdate'),
            t(`collection_item.nft_overlay.label_fields.${values?.update.toString()}`)
          )}
        {(!template.fields?.qrCodeDrop?.hidden || !template.fields?.qrCodeDropVal?.hidden) &&
          detailsField(
            t('collection_item.summary.redeem') as string,
            template.dropDowns.redeemViaQrCode.find(
              (option) => option.value === values?.qrCodeDropVal
            )?.label[language]
            // t(typeOfQrCode(values?.qrCodeDropVal, templateSelected?.prefilledCustomFields))
          )}
        {!template.fields?.doesExpire?.hidden &&
          detailsField(
            template.fields?.doesExpire?.label?.[language] || t('collection_item.summary.expire'),
            values.expiration
              ? `${values.expiration} ${t('days', {
                  count: values.expiration,
                })}`
              : t('collection_item.no_expiration')
          )}
        {!template.fields?.doesTransfer?.hidden &&
          detailsField(
            template.fields?.doesTransfer?.label?.[language] ||
              t('collection_item.summary.transfer'),
            t(`boolean.${values?.doesTransfer.toString()}`)
          )}
        {detailsField(
          template.fields?.numberOfCategories?.label?.[language] ||
            t('create_contract.form_labels.category'),
          values.numberOfCategories
        )}
        {!template.fields?.tokenBurn?.hidden &&
          detailsField(
            template.fields?.doesExpire?.label?.[language] || t('collection_item.summary.burn'),
            t(`boolean.${values?.tokenBurn.toString()}`)
          )}
      </div>

      <div className="mt-4 flex flex-col" id="nfts">
        <Typography size="body-regular-20">{t('collection_item.summary.nfts')}</Typography>
        <div className="flex flex-wrap items-center justify-start gap-5 ">
          {values.nfts.map((nft: any, index: number) => {
            return <NftCard contract={values} item={nft} key={index} disabled isPreview />;
          })}
        </div>
      </div>
      <div className="flex w-full items-center justify-center  space-x-[24px] pt-[50px] ">
        <Button id="summary-back" type="secondary" action={onBack} className="w-min">
          {t('summary.back')}
        </Button>
        <Button id="summary-upload" type="primary" action={onSubmit} className="w-min">
          {t('summary.upload')}
        </Button>
      </div>
    </div>
  );
};

export default SummaryStep;
