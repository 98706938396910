import { useModal } from 'react-simple-modal-provider';
import { BasicCard } from '..';
import { NotarizationBottomCard, NotarizationTopCard } from './index';

type NotarizationCardProps = {
  item: any;
  hasWritePermission?: boolean;
  action?: () => void;
  folders?: any;
};

const NotarizationCard = ({ item, hasWritePermission, action, folders }: NotarizationCardProps) => {
  const { open: openModalDetail } = useModal('NotarizationDetailsModal');
  const handleOpenModalDetail = () => {
    openModalDetail({ notarization: item, folders });
  };
  return (
    <BasicCard
      top={
        <NotarizationTopCard
          item={item}
          hasWritePermission={hasWritePermission}
          action={action}
          folders={folders}
        />
      }
      bottom={<NotarizationBottomCard item={item} />}
      onClick={action || handleOpenModalDetail}
    />
  );
};
export default NotarizationCard;
