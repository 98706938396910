import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/* root imports */
import { compare, compareByDate, filterCollection } from '../../../../imports/utils';

import { IconLoading } from '../../../../icons';

import { NotarizationCard, Typography } from '../../../../components';

import type { Filters, Folder, SidebarType } from '../../../../imports/types';

/* notarizations imports */
import { useAppSelector } from '../../../../redux/hooks';
import type { Notarization, Receipt } from '../../imports/types';

type NotarizationsListProps = {
  notarizations: Notarization[];
  receipts: Receipt[];
  folders: Folder[];
  isLoading: boolean;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
  handleShowSidebar?: (type: SidebarType, optionalProps?: any) => void;
};
const NotarizationsList = ({
  notarizations,
  receipts,
  folders,
  isLoading,
  filtersState,
  orderBy,
  handleShowSidebar,
}: NotarizationsListProps) => {
  const { t } = useTranslation(['notarization']);

  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;
  const { workspace } = useAppSelector((user) => user.team);

  const filteredNotarizations = useMemo(() => {
    const notarizationsFormatted = notarizations.map((notarization) => {
      const receipt = receipts.find((receipt) => receipt.receiptId === notarization.receiptId);

      const newNotarization = {
        ...notarization,
        ...receipt,
      };

      return newNotarization;
    });

    const filteredCollection = filterCollection({
      filtersInOrder: [
        {
          type: inputFilterByName.filter,
          propertyToFilter: ['name', 'txHash', 'folderName'],
          filterValue: inputFilterByName.value,
        },
        {
          type: inputFilterByTag.filter,
          propertyToFilter: 'tags',
          filterValue: inputFilterByTag.value,
        },
        {
          type: inputFilterByDate.filter,
          propertyToFilter: 'date',
          filterValue: inputFilterByDate.value,
        },
      ],
      collection: notarizationsFormatted,
    });

    if (orderBy) {
      if (orderBy.type === 'date') {
        return filteredCollection.sort((a, b) => compareByDate(a.date, b.date, orderBy.direction));
      }

      if (orderBy.type === 'name') {
        return filteredCollection.sort((a, b) => compare(a, b, 'name', orderBy.direction));
      }
    }

    return filteredCollection;
  }, [notarizations, receipts, inputFilterByName, inputFilterByTag, inputFilterByDate, orderBy]);

  return !isLoading ? (
    <>
      <Typography size="body-medium-20">{t('collection.title_notarizations')}</Typography>
      <div className="flex flex-wrap gap-5">
        {filteredNotarizations.length > 0 ? (
          filteredNotarizations.map((item, index) => {
            return (
              <NotarizationCard
                key={index}
                item={item}
                folders={folders}
                hasWritePermission={workspace?.permissions.notarization.write}
              />
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                notarizations.length === 0
                  ? 'collection.no_notarization_found'
                  : 'collection.no_filtered_notarization_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default NotarizationsList;
