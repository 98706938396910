import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { usePrevious } from '../../hooks';
import type { InputType } from '../../imports/types';
import Tag from '../tag/Tag';
import Typography from '../typography/Typography';

type TagInputProps = InputType & {
  onTagsChange: (tags: string[]) => void;
  elementLeft?: ReactNode;
  elementRight?: ReactNode;
  tags?: string[];
  maxTags?: number;
  className?: string;
};

const TagInput = ({
  id,
  name,
  type,
  placeholder,
  disabled,
  error,
  success,
  onBlur,
  elementLeft,
  elementRight,
  tags = [],
  onTagsChange,
  maxTags = 3,
  className,
}: TagInputProps) => {
  const { t } = useTranslation(['notarization']);
  const [tagsList, setTagsList] = useState<string[]>(tags);
  const emptyTagsList = tagsList.length === 0;

  const prevTags = usePrevious(tags);

  const getInputStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  const getInputPaddingX = () => {
    let padding = '';

    if (elementLeft) {
      padding = `${padding} with-element-left`;
    }
    if (elementRight) {
      padding = `${padding} with-element-right`;
    }

    return padding;
  };

  const handleAddTag = (value: any) => {
    if (tagsList.length < maxTags) {
      if (!tagsList.includes(value.toLowerCase())) {
        setTagsList([...tagsList, value.toLowerCase()]);
      } else {
        toast.warn(t('alert.tag_already_inserted') as string);
      }
    } else {
      toast.error(t('alert.max_tags') as string);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      return;
    }

    if (event.target.value.includes(' ')) {
      const newTags = event.target.value
        .split(' ')
        .filter((tag) => tag.trim() !== '')
        .map((tag) => tag.trim())[0];

      handleAddTag(newTags);
      event.target.value = '';
    }
  };

  const handleOnKeyDown = (e: any) => {
    const val = e.target.value;

    if (e.key !== 'Enter') {
      return;
    }
    if (!val.trim()) {
      return;
    }

    handleAddTag(val);
    e.target.value = '';
  };

  const handleRemoveTag = (item: string | number) => {
    const filteredTags = tagsList.filter((tagItem) => tagItem !== item);
    setTagsList(filteredTags);
  };

  useEffect(() => {
    onTagsChange(tagsList);
  }, [tagsList]);

  useEffect(() => {
    if (!isEmpty(tags)) {
      if (!isEqual(prevTags, tags)) {
        setTagsList(tags);
      }
    }
  }, [tags]);

  return (
    <>
      <div className={`input-container ${className}`}>
        {elementLeft && <div className="input-element left">{elementLeft}</div>}
        {elementRight && <div className="input-element right">{elementRight}</div>}

        <input
          className={`input ${getInputPaddingX()} ${getInputStatus()}`}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>

      {emptyTagsList ? (
        <Typography className="mt-2" size="body-regular-12">
          {t('create_notarization.tags_hint', { maxTags })}
        </Typography>
      ) : (
        <div className="mt-2 flex flex-wrap gap-1">
          {tagsList.map((item) => (
            <Tag key={item} title={item} onClose={(item) => handleRemoveTag(item)} />
          ))}
        </div>
      )}
    </>
  );
};

export default TagInput;
