import { ethers } from 'ethers';
import { get, ref } from 'firebase/database';
import { omit, range } from 'lodash';
import { database } from './firebase';

/**
 * @description Functions to fetch signers from RTDB
 * @param {contractId: string, tokens: number}
 * @returns {a: string; p: string}[][]
 */
export async function getRTDBSigners({
  contractId,
  tokens,
}: {
  contractId: string;
  tokens: number;
}) {
  return Promise.all(
    new Array(tokens).fill(1).map(async (_, id: number) => {
      const catRef = ref(database, `${contractId}/s/${id}`);
      const snapshot = await get(catRef);
      if (!snapshot.exists()) return null;

      return snapshot
        .val()
        .map((signer: { a: string; p: string; s: string }) => omit(signer, ['s']));
    })
  );
}

/**
 *
 * @param num_of_signers
 * @param maxSupplyPerRarity
 * @returns { id: number; s: { p: string; a: string }[] }[]
 */
export function generateSigners(
  maxSupplyPerRarity: number[] // [0, quantity per category]
): { p: string; a: string }[][] {
  const categories = [];

  // eslint-disable-next-line guard-for-in
  for (const categoryIndex in maxSupplyPerRarity) {
    const supply = maxSupplyPerRarity[categoryIndex];

    if (supply) {
      categories.push(
        range(0, supply).map(() => {
          let wallet = ethers.Wallet.createRandom();
          // TODO: try to break it
          while (
            wallet.privateKey ===
            '0x0000000000000000000000000000000000000000000000000000000000000001'
          ) {
            wallet = ethers.Wallet.createRandom();
          }
          return {
            a: wallet.address,
            p: wallet.privateKey,
          };
        })
      );
    } else if (categoryIndex !== '0') {
      // supply = 0 means unlimited quantity, so I need to check the category
      // to be different from '0' to be sure it is not the placeholder
      categories.push([
        {
          a: '0x7E5F4552091A69125d5DfCb7b8C2659029395Bdf',
          p: '0x0000000000000000000000000000000000000000000000000000000000000001',
        },
      ]);
    }
  }
  console.log('Signers generated!');
  return categories;
}
