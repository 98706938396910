import type { FunctionComponent, SVGProps } from 'react';

import { COLORS, fillColors } from '../../imports/constants';
import type { Colors } from '../../imports/types';

import { IconLoading } from '../../icons';

import './icon.css';

type SizesType = 'sm' | 'md' | 'lg' | string;

type IconProps = {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  size?: SizesType;
  color?: Colors;
  loading?: boolean;
  className?: string;
  role?: string;
  stroke?: Colors;
  onClick?: () => void;
};

const Icon = ({
  icon,
  size = 'sm',
  color = 'inherit',
  loading,
  className,
  role,
  stroke = 'grey-300',
  onClick,
}: IconProps) => {
  const IconComponent = icon;

  const SIZES: { [key in SizesType]: number } = { sm: 15, md: 20, lg: 25, xl: 50, xxxl: 100 };

  const getIconColor = () => fillColors[color];
  return loading ? (
    <IconLoading className={`icon loading ${size} ${className}`} />
  ) : (
    <>
      {IconComponent && (
        <IconComponent
          stroke={COLORS[stroke]}
          className={`icon ${getIconColor()}  ${size} ${className} `}
          width={SIZES[size] || parseInt(size, 10)}
          height={SIZES[size] || parseInt(size, 10)}
          role={role}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default Icon;
