import type { Routes } from '../../imports/types';

import Layout from './pages/Layout';

type TeamProps = {
  route: Routes;
};
const Team = ({ route }: TeamProps) => <Layout route={route} />;

export default Team;
