import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrevious } from '../../hooks';
import type { InputType } from '../../imports/types';
import Select from '../select/Select';
import Tag from '../tag/Tag';
import { useAppSelector } from '../../redux/hooks';

type TagInputProps = InputType & {
  onTagsChange: (tags: string[]) => void;
  tagList: any;
  elementLeft?: ReactNode;
  elementRight?: ReactNode;
  tags?: string[];
  labels?: string[];
  maxTags?: number;
};

const TagSelect = ({
  id,
  name,
  type,
  placeholder,
  disabled,
  error,
  success,
  onBlur,
  elementLeft,
  elementRight,
  tagList,
  tags = [],
  labels = [],
  onTagsChange,
  maxTags = 3,
}: TagInputProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const template = useAppSelector((state) => state.template);
  const [tagsList, setTagsList] = useState<string[]>(
    (template?.fields?.tag?.value as string[]) || tags
  );
  const [tagsLabels, setTagsLabels] = useState<string[]>(labels);
  const prevTags = usePrevious(tags);
  const handleAddTag = (value: any) => {
    const selected = tagsList.includes(value.target.value);

    // NOTE: value.target.value is used to avoid push og placeholder "-" in tags
    if (tagsList.length < maxTags && !selected && value.target.value) {
      setTagsList([...tagsList, value.target.value]);
    }
  };
  const handleRemoveTag = (item: string | number) => {
    const filteredTags = tagsList.filter((tagItem) => tagItem !== item);
    setTagsList(filteredTags);
  };

  useEffect(() => {
    onTagsChange(tagsList);
  }, [tagsList]);

  useEffect(() => {
    if (!isEmpty(tags)) {
      if (!isEqual(prevTags, tags)) {
        setTagsList(tags);
      }
    }
  }, [tags]);

  return (
    <>
      <div className="input-container">
        {elementLeft && <div className="input-element left">{elementLeft}</div>}
        {elementRight && <div className="input-element right">{elementRight}</div>}

        <Select
          id={id}
          name={name}
          placeholder={placeholder}
          options={tagList}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleAddTag}
          defaultValue={tagList[0].value}
        />
      </div>

      <div className="flex flex-wrap gap-1 mt-2">
        {tagsList.map((item) => (
          <Tag
            disabled={disabled}
            key={item}
            id={item}
            title={tagList.find((tag: any) => tag.value === item).label}
            onClose={(item) => handleRemoveTag(item)}
          />
        ))}
      </div>
    </>
  );
};

export default TagSelect;
