import { createSlice } from '@reduxjs/toolkit';
import { Workspace } from '../../imports/types';
import { Customer } from '../../modules/team/imports/types';

export interface StateTeam {
  workspace?: Workspace;
}

const initialState: StateTeam = {
  workspace: undefined,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setWorkspace: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        workspace: payload as StateTeam['workspace'],
      };
    },
    updateCustomerCrm: (
      state,
      action: {
        payload: Customer;
        type: string;
      }
    ) => {
      if (state.workspace) {
        const customerIndex = state.workspace?.customers.findIndex(
          (customer) => customer.address === action.payload.address
        );
        if (customerIndex !== -1) {
          state.workspace.customers[customerIndex] = {
            ...state.workspace.customers[customerIndex],
            ...action.payload,
          };
        } else {
          state.workspace.customers.push(action.payload);
        }
      }
      return state;
    },
    logOutTeam: () => {
      return initialState;
    },
  },
});

export const { setWorkspace, updateCustomerCrm, logOutTeam } = teamSlice.actions;
export default teamSlice.reducer;
