import { Icon } from '..';
import { IconLeft } from '../../icons';
import Typography from '../typography/Typography';

type SectionTitleProps = {
  title: string;
  children?: React.ReactNode;
  onBack?: () => void;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
};

const SectionTitle = ({ title, children, onBack, as = 'h2', size = 'lg' }: SectionTitleProps) => (
  <div className="w-full">
    <div className="flex items-end justify-between">
      <div className="flex w-full flex-row justify-between">
        <div className="flex items-center space-x-[12px]">
          {!!onBack && (
            <button
              type="button"
              className="text-[20px] hover:opacity-75 active:opacity-100"
              onClick={onBack}
            >
              <Icon icon={IconLeft} size="md" />
            </button>
          )}
          <Typography as={as} size={size} weight="medium" className=" whitespace-nowrap">
            {title}
          </Typography>
        </div>
      </div>

      {children && children}
    </div>
    <hr className="mt-2 border-primary-400" />
  </div>
);

export default SectionTitle;
