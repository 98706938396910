import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Button, Field, Form, Input, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconClose } from '../../../../icons';
import {
  getAvailableContracts,
  getContractsFromFunctionalities,
} from '../../../../imports/contractsParsers';
import { PollForm } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';
import { getPollRef, setPoll, setPollAvailableOn } from '../../api';
import { Contract } from '../../imports/types';
import ContractsManager from '../contractsManager/ContractsManager';

type PollProps = {
  edit: PollForm;
};

const Poll = ({ edit }: PollProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { contractsList } = useAppSelector((state) => ({
    contractsList: state.contracts.list,
  }));
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>(
    edit ? getContractsFromFunctionalities(edit.availableOn, contractsList) : []
  );

  const formPoll = {
    initialValuesPoll: {
      title: edit?.title || '',
      body: edit?.body || '',
    },
    validationSchemaPoll: yup.object({}),
  };
  const { initialValuesPoll, validationSchemaPoll } = formPoll;
  const handleSubmitPoll = async (fields: { body: string; title: string }) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('collection.functionality.actions.pending'),
      },
    });
    if (!edit) return;
    const newPollRef = getPollRef(edit);
    const poll: PollForm = {
      availableOn: edit.availableOn || {},
      body: fields.body || edit.body,
      endDate: edit.endDate,
      id: edit.id,
      img: edit.img,
      maxTotalAnswers: edit.maxTotalAnswers,
      minTotalAnswers: edit.minTotalAnswers,
      owner: edit.owner,
      questions: edit.questions,
      startDate: edit.startDate,
      submit: edit.submit,
      title: fields.title || edit.title,
      updatedAt: Date.now(),
      createdAt: Date.now(),
      startHours: edit.startHours,
      endHours: edit.endHours,
    };

    const { value } = edit
      ? await setPollAvailableOn(newPollRef, getAvailableContracts(selectedContracts))
      : await setPoll(newPollRef, poll);

    if (value) {
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: edit
            ? t('collection.functionality.actions.updated')
            : t('collection.functionality.actions.success'),
        },
      });
    } else {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('collection.functionality.actions.failed'),
        },
      });
    }
  };

  return (
    <div>
      <Typography color="primary-500" size="body-medium-30" className="p-4">
        {t('collection.functionality.poll')}
      </Typography>

      <Form
        initialValues={initialValuesPoll}
        validationSchema={validationSchemaPoll}
        className="mt-4 flex size-full flex-col gap-4 p-4"
      >
        {({ fields, handleSubmit, errors, resetField, watch, setValue, isValid, register }) => {
          const values = watch();

          return (
            <>
              <Field label={t('collection.functionality.news_title')} error={errors.title?.message}>
                <Input
                  type="text"
                  placeholder={t('collection.functionality.placeholders_title')}
                  name={fields.title.name}
                  onBlur={fields.title.onBlur}
                  onChange={fields.title.onChange}
                  inputRef={fields.title.ref}
                  error={errors.title?.message}
                  elementRight={
                    values.title && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.title.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field label={t('collection.functionality.description')} error={errors.body?.message}>
                <Input
                  type="text"
                  placeholder={t('collection.functionality.placeholders_body')}
                  name={fields.body.name}
                  onBlur={fields.body.onBlur}
                  onChange={fields.body.onChange}
                  inputRef={fields.body.ref}
                  error={errors.body?.message}
                  elementRight={
                    values.body && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.body.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field error={errors.options?.message}>
                <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
                  {t('collection.functionality.select_contract')}
                </Typography>
                <div className="!max-h-[400px] overflow-auto">
                  <ContractsManager
                    availableContracts={edit?.availableOn ? edit.availableOn : {}}
                    setContracts={setSelectedContracts}
                    isPollPage
                  />
                </div>
              </Field>

              <div className="mt-4 flex justify-end">
                <Button
                  id="notarize"
                  type="primary"
                  action={() => handleSubmit(() => handleSubmitPoll(values))()}
                  className="px-6 "
                >
                  {t('collection.functionality.submit')}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default Poll;
