import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type MessagesCollectionsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { message } = useModalProps('MessagesCollectionsModal');
  const { contractsNames = [] } = message;
  const { t } = useTranslation(['tokenCreator']);

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout=" overflow-auto max-h-[85%] min-h-[300px]"
      width="485px"
    >
      <div className="flex w-full flex-col items-start justify-start space-y-4 px-[50px] pt-[20px]">
        <Typography size="body-medium-30" color="primary-500">
          {t('messages.collections')}
        </Typography>
        {contractsNames.length === 0 ? (
          <Typography size="body-medium-18" weight="medium">
            {t('messages.no_collections')}
          </Typography>
        ) : (
          contractsNames.map((contract: { name: string; nfts: string[] }, index: number) => {
            const [expandedContracts, setExpandedContracts] = useState(false);
            const first10 = contract.nfts.slice(0, 10);
            const lasts = contract.nfts.slice(10);
            return (
              <div key={index}>
                <Typography size="body-medium-18" weight="medium">
                  {contract.name}
                </Typography>
                <ul className="pl-6">
                  {first10.map((nft: string, nftIndex: number) => (
                    <li key={nftIndex}>- {nft}</li>
                  ))}
                  {expandedContracts &&
                    lasts.map((nft: string, nftIndex: number) => <li key={nftIndex}>- {nft}</li>)}
                  {lasts.length !== 0 && (
                    <Button
                      type="secondary"
                      className="!h-[30px] rounded-[4px]"
                      action={() => setExpandedContracts(!expandedContracts)}
                    >
                      {expandedContracts
                        ? t('messages.collapse_collection')
                        : t('messages.expand_collection')}
                    </Button>
                  )}
                </ul>
              </div>
            );
          })
        )}
      </div>
    </ModalLayout>
  );
};

const MessagesCollectionsModal = ({ children }: MessagesCollectionsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="MessagesCollectionsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default MessagesCollectionsModal;
