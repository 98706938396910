/**
 * @notice This file is not used anymore, we moved everything in NewCompanyModal
 */

import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  Breadcrumbs,
  Button,
  Field,
  Form,
  Icon,
  InnerPage,
  Input,
  SectionProfile,
} from '../../../../components';
import { IconClose, IconEdit, IconLoading } from '../../../../icons';
import { firebaseConfig } from '../../../../imports/constants';
import { db, storage } from '../../../../imports/firebase';
import { PublicCompany } from '../../../../imports/types';
import { getBase64 } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import ProfileInput from '../../components/profileInput/ProfileInput';
import { isSecureUrl, isUrl } from '../../imports/utils';

type DocumentLinkProps = {
  link: string;
  text: string;
};
const DocumentLink = ({ link, text }: DocumentLinkProps) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};

type PencilButtonProps = {
  action?: () => void;
  disabled?: boolean;
};
const PencilButton = ({ action, disabled = false }: PencilButtonProps) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className="primary btn h-6 w-6 place-self-end rounded-full p-1"
      onClick={action}
    >
      <Icon icon={IconEdit} size="16" className="" />
    </button>
  );
};
type DeleteButtonProps = {
  action?: () => void;
  disabled?: boolean;
};
const DeleteButton = ({ action, disabled = false }: DeleteButtonProps) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className="primary btn h-6 w-6 place-self-end rounded-full p-1"
      onClick={action}
    >
      <Icon icon={IconClose} size="sm" color="white" className="" />
    </button>
  );
};

const CompanyProfile = () => {
  const { t } = useTranslation();
  const { uid } = useAppSelector((state) => state.user);
  const { address } = useAppSelector((state) => state.user.wallet);
  const profile = useAppSelector((state) => state.user);
  const colors = { textColor: '#ffffff', bgColor: '#211b2b' };

  const [isLoading, setIsLoading] = useState(true);
  const [img, setImg] = useState(null);
  const [saveMessage, setSaveMessage] = useState<boolean>(false);
  const [enabledField, setEnabledField] = useState('');
  const acceptingFiles = '.jpg, .jpeg, .png';
  const [newPublicData, setNewPublicData] = useState<PublicCompany>({
    address,
    // bannerPic: '',
    // bgColor: '',
    companyName: profile.company,
    cta: '',
    ctaLink: '',
    description: '',
    profilePic: '',
    website: '',
    // textColor: '',
  });
  const { open: openModalNewCompany } = useModal('NewCompanyModal');
  const handleOpenNewCompany = () => {
    openModalNewCompany({ publicCompany: newPublicData, message: true });
  };

  const getPublicCompany = async () => {
    setIsLoading(true);
    const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
    const snapshot = await getDoc(publicDocRef);

    if (snapshot.exists()) {
      setNewPublicData({
        website: '',
        ...snapshot.data(),
      } as PublicCompany);
    } else {
      const publicCompany: PublicCompany = {
        address,
        companyName: profile?.company,
        cta: '',
        ctaLink: '',
        description: '',
        profilePic: '',
        website: '',
      };
      setNewPublicData(publicCompany);
      handleOpenNewCompany();
      // await setDoc(publicDocRef, publicCompany, { merge: true });
      // await setDoc(doc(db, `publicCompany/${address}/owner`, 'owner'), { owner: uid });
    }
    setIsLoading(false);
  };

  const companyForm = {
    initialValues: newPublicData,
    validationSchema: yup.object({
      cta: yup.string(),
      ctaLink: yup
        .string()
        .test('valid_url', t('companyProfile.errors.invalid_url'), (value) => isUrl(value))
        .test('secure_url', t('companyProfile.errors.url_not_secure'), (value) =>
          isSecureUrl(value)
        ),
      description: yup.string(),
      // textColor: yup.string(),
      // bgColor: yup.string(),
      profilePic: yup.string(),
      website: yup
        .string()
        .test('valid_url', t('companyProfile.errors.invalid_url'), (value) => isUrl(value))
        .test('secure_url', t('companyProfile.errors.url_not_secure'), (value) =>
          isSecureUrl(value)
        )
        .required(),
      // bannerPic: yup.string(),
      // legalTitle: yup.string(),
      // legal: yup.string(),
    }),
  };

  const { initialValues, validationSchema } = companyForm;
  const formFields = [
    {
      field: 'companyName',
      type: 'text',
      editable: false,
      link: false,
    },
    {
      field: 'description',
      type: 'text',
      editable: true,
      link: false,
    },
    {
      field: 'cta',
      type: 'text',
      editable: true,
      link: false,
    },
    {
      field: 'ctaLink',
      type: 'text',
      editable: true,
      link: true,
    },
    {
      field: 'website',
      type: 'text',
      editable: true,
      link: true,
    },
    // {
    //   field: 'textColor',
    //   type: 'color',
    //   editable: true,
    //   link: false,
    // },
    // {
    //   field: 'bgColor',
    //   type: 'color',
    //   editable: true,
    //   link: false,
    // },
    // {
    //   field: 'preview',
    //   type: 'text',
    //   editable: false,
    //   link: false,
    // },
    // {
    //   field: 'legalTitle',
    //   type: 'input',
    //   editable: true,
    //   link: false,
    // },
  ];

  const setCompanyPreference = (key: string, value: string) => {
    setNewPublicData({ ...newPublicData, [key]: value });
  };

  useEffect(() => {
    getPublicCompany();
  }, []);

  // const deleteFile = async (key: string, url: string) => {
  //   setIsLoading(true);
  //   try {
  //     if (key === 'privacyPolicy') {
  //       const fileRef = ref(storage, `images/${uid}/${key}`);
  //       await deleteObject(fileRef);
  //       setCompanyPreference(key, '');
  //       setIsLoading(false);
  //     }

  //     return { status: 200 };
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error(t(`companyProfile.${key}`) as string);
  //     return { status: 500, error };
  //   }
  // };
  const uploadFile = async (key: string, file: File) => {
    if (file) {
      setIsLoading(true);
      try {
        const imgRef = ref(storage, `images/${uid}/${key}`);
        await uploadBytes(imgRef, file);
        const url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2F${uid}%2F${key}?alt=media`;
        setCompanyPreference(key, url);
        if (newPublicData[key as keyof typeof newPublicData] === '') {
          const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
          // if (key === 'legal') {
          //   await updateDoc(publicDocRef, { legal: url });
          // }
          if (key === 'profilePic') {
            await updateDoc(publicDocRef, { profilePic: url });
          }
          // if (key === 'privacyPolicy') {
          //   await updateDoc(publicDocRef, { privacyPolicy: url });
          // }
          toast.success(t(`companyProfile.save`) as string);
        }
        toast.success(t(`companyProfile.${key}`) as string);
        setIsLoading(false);
        return { status: 200, data: url };
      } catch (error) {
        setIsLoading(false);
        toast.error(t(`companyProfile.${key}`) as string);
        return { status: 500, error };
      }
    } else {
      toast.error(t(`companyProfile.noIMG`) as string);
      return { status: 404, error: `companyProfile.noIMG` };
    }
  };

  const handleModify = (key: SetStateAction<string>) => {
    if (key === enabledField) {
      setEnabledField('');
    } else {
      setEnabledField(key);
    }
  };

  const doubleCheckFormField = (
    keyFieldOne: string,
    valFieldOne: string | undefined,
    keyFieldTwo: string,
    valFieldTwo: string | undefined
  ) => {
    if (valFieldOne === undefined && valFieldTwo === undefined) {
      return true;
    }
    if (valFieldOne === '' && valFieldTwo === '') {
      return true;
    }
    if (valFieldOne && valFieldOne !== '' && valFieldTwo && valFieldTwo !== '') {
      return true;
    }
    if (valFieldOne === undefined || valFieldOne === '') {
      toast.error(t(`companyProfile.errors.${keyFieldOne}`) as string);
      return false;
    }
    if (valFieldTwo === undefined || valFieldTwo === '') {
      toast.error(t(`companyProfile.errors.${keyFieldTwo}`) as string);
      return false;
    }
    toast.error(t('companyProfile.errors.something_went_wrong') as string);
    return false;
  };

  const handleConfirmSave: SubmitHandler<PublicCompany> = async (fields: PublicCompany) => {
    const urlCheck = doubleCheckFormField('cta', fields.cta, 'ctaLink', fields.ctaLink);
    if (urlCheck) {
      if (Object.entries(fields).toString() !== Object.entries(newPublicData).toString()) {
        try {
          const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
          await setDoc(publicDocRef, fields, { merge: true });
          setNewPublicData(fields);
          toast.success(t(`companyProfile.save`) as string);
          setEnabledField('');
          setSaveMessage(false);
        } catch (e) {
          toast.error(t('companyProfile.saveError') as string);
          console.log(e);
        }
      }
    }
  };

  const handleEdit = (
    field: UseFormRegisterReturn<string>,
    key?: { field?: string; type: any; editable?: boolean }
  ) => {
    return (
      <PencilButton
        action={() => {
          handleModify(field.name);
        }}
      />
    );
  };

  return (
    <InnerPage>
      {!isLoading ? (
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          className="flex w-full flex-col"
        >
          {({ fields, handleSubmit, reset, watch, errors }) => {
            const values = watch();
            const activateButton = saveMessage
              ? false
              : Object.entries(values).toString() === Object.entries(newPublicData).toString();
            return (
              <>
                <Breadcrumbs />

                <header className="flex flex-col items-center">
                  <div
                    //photo-container
                    className="h-full flex-1"
                  >
                    <ProfileInput
                      id="propicInput"
                      tooltip={t('companyProfile.recommendations')}
                      message={t('companyProfile.modProPic')}
                      className={`border-lightGrey relative box-border h-40 w-40 rounded-3xl border-2 bg-center bg-no-repeat  text-black shadow-none ${
                        newPublicData.profilePic ? '' : 'bg-upload' //px-3 py-4
                      }`}
                      acceptingFiles={acceptingFiles}
                      styleType="avatarUpload"
                      onChange={(imageObj: any) => {
                        if (imageObj) {
                          getBase64(imageObj, setImg);
                          uploadFile('profilePic', imageObj);
                        }
                      }}
                    >
                      <div className="box-border h-40 w-40 overflow-hidden rounded-3xl ">
                        {img ? (
                          <img src={img} alt="propic" className="h-40 w-40 object-fill" />
                        ) : newPublicData.profilePic ? (
                          <img
                            src={newPublicData.profilePic}
                            alt="propic"
                            className="h-40 w-40 object-fill "
                          />
                        ) : (
                          <img
                            src={`https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2Fpropic.png?alt=media&token=cb6dd1d9-1188-48ba-8787-64c97bca0816`}
                            alt="default propic"
                            className="h-40 w-40 object-fill"
                          />
                        )}
                      </div>
                    </ProfileInput>
                  </div>
                </header>

                <div className="mt-5 flex flex-col space-y-4">
                  {formFields.map((key) => {
                    return (
                      <div id={key.field} key={key.field}>
                        <SectionProfile
                          title={t(`companyProfile.${key.field}`)}
                          editData={
                            key.editable &&
                            handleEdit(fields[key.field as keyof typeof fields], key)
                          }
                          id={key.field}
                          textColor={values['textColor' as keyof typeof fields]}
                          bgColor={values['bgColor' as keyof typeof fields]}
                        >
                          <Field error={errors?.[key.field]?.message}>
                            {key.field === enabledField ? (
                              <Input
                                type="text"
                                id={fields[key.field as keyof typeof fields].name}
                                name={fields[key.field as keyof typeof fields].name}
                                onChange={fields[key.field as keyof typeof fields].onChange}
                                inputRef={fields[key.field as keyof typeof fields].ref}
                                onBlur={fields[key.field as keyof typeof fields].onBlur}
                                error={errors?.[key.field]?.message}
                              />
                            ) : // key.field === 'bgColor' || key.field === 'textColor' ? (
                            //   //values[key.field as keyof typeof values] !== '' && (
                            //   <div className="flex flex-row">
                            //     <input
                            //       type="color"
                            //       value={
                            //         values[key.field as keyof typeof values] === ''
                            //           ? colors[key.field as keyof typeof colors]
                            //           : values[key.field as keyof typeof values]
                            //       }
                            //       disabled
                            //       className="mr-4"
                            //     />
                            //     <p>
                            //       {values[key.field as keyof typeof values] === ''
                            //         ? colors[key.field as keyof typeof colors]
                            //         : values[key.field as keyof typeof values]}
                            //     </p>
                            //   </div>
                            // ):
                            key.field === 'preview' ? (
                              //values[key.field as keyof typeof values] !== '' && (
                              <div className="flex flex-row">
                                <p id="previewCustom">{values['cta' as keyof typeof values]}</p>
                              </div>
                            ) : key.link ? (
                              <DocumentLink
                                link={values[key.field as keyof typeof values]}
                                text={values[key.field as keyof typeof values]}
                              />
                            ) : (
                              <p>{values[key.field as keyof typeof values]}</p>
                            )}
                          </Field>
                        </SectionProfile>
                      </div>
                    );
                  })}
                  {/* <SectionProfile title={t('companyProfile.preview')} /> */}
                  {/* <SectionProfile
                    title={t(`companyProfile.legal`)}
                    editData={handleEdit(fields['legal' as keyof typeof fields])}
                  >
                    <Field error={errors?.legal?.message}>
                      {legalDocument?.name ? (
                        <DocumentLink
                          link={values['legal' as keyof typeof values]}
                          text={legalDocument?.name}
                        />
                      ) : values['legal' as keyof typeof values] ? (
                        <DocumentLink
                          link={values['legal' as keyof typeof values]}
                          text={t(`companyProfile.pdf`)}
                        />
                      ) : (
                        <p>{t(`companyProfile.noPdf`)}</p>
                      )}
                    </Field>
                  </SectionProfile> */}

                  {/* privacy */}
                  {/* <SectionProfile title={t('companyProfile.privacy_policy')}>
                    <ProfileInput
                      id="privacyFileInput"
                      tooltip={t('companyProfile.upload_privacy')}
                      message="message"
                      className={`bg-upload  relative left-0 
                       mx-auto ml-0 mt-2 text-black underline shadow-none
                    `}
                      styleType="file"
                      acceptingFiles=".pdf"
                      onChange={(file: any) => {
                        if (file) {
                          getBase64(file, setPrivacyPolicy);
                          uploadFile('privacyPolicy', file);
                        }
                      }}
                    >
                      <div>{t('companyProfile.upload_privacy')}</div>
                    </ProfileInput>
                  </SectionProfile> */}

                  <div className="mt-10 flex w-full flex-row justify-around p-7">
                    {/* TODO: disable button if data didn't change  */}
                    <Button
                      className="w-1/3 "
                      action={() => {
                        reset();
                        setEnabledField('');
                        setSaveMessage(false);
                      }}
                      disabled={activateButton}
                    >
                      {t('companyProfile.delete')}
                    </Button>
                    {/* TODO: disable button if data didn't change  */}
                    <Button
                      className="w-1/3 "
                      action={handleSubmit(() => handleConfirmSave(values))}
                      disabled={activateButton}
                    >
                      {t('companyProfile.save')}
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      ) : (
        <div className="flex h-screen w-full items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      )}
    </InnerPage>
  );
};

export default CompanyProfile;
