/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M78 36H42C38.6863 36 36 38.6863 36 42V78C36 81.3137 38.6863 84 42 84H78C81.3137 84 84 81.3137 84 78V42C84 38.6863 81.3137 36 78 36Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78 108H42C38.6863 108 36 110.686 36 114V150C36 153.314 38.6863 156 42 156H78C81.3137 156 84 153.314 84 150V114C84 110.686 81.3137 108 78 108Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150 36H114C110.686 36 108 38.6863 108 42V78C108 81.3137 110.686 84 114 84H150C153.314 84 156 81.3137 156 78V42C156 38.6863 153.314 36 150 36Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108 108V132"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108 156H132V108"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132 120H156"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M156 144V156"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconQrCode;
