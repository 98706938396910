import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { uid as uniqueId } from 'uid';
import { AnswerFields } from '..';
import { Button, Field, Icon, Select, Textarea, Tooltip, Typography } from '../../../../components';
import { IconArrowDown, IconGraph, IconPlus, IconTrash } from '../../../../icons';

interface QuestionFieldsProps {
  values: any;
  control: any;
  errors: any;
  resetField: any;
  register: any;
  setValue: any;
  onBack: () => void;
  onSubmit: () => void;
  clearErrors: () => void;
}

const QuestionFields: React.FC<QuestionFieldsProps> = ({
  errors,
  resetField,
  control,
  values,
  register,
  setValue,
  onBack,
  onSubmit,
  clearErrors,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const { questions } = values;
  const { append, remove, fields } = useFieldArray({
    control,
    name: `questions`,
  });
  const [changeTypeStatus, setChangeTypeStatus] = useState(true);

  const [questionVisibility, setQuestionVisibility] = useState<Array<string>>([]);
  const { open: openQuestionsFlowsModal } = useModal('QuestionsFlowsModal');
  const handleOpenFlows = (questions: any) => {
    openQuestionsFlowsModal({ questions });
  };
  const handleVQuestionVisibility = (qId: any) => {
    const found = questionVisibility.findIndex((element) => element === qId);
    const newVisibility = [...questionVisibility];
    if (found < 0) {
      newVisibility.push(qId);
      setQuestionVisibility(newVisibility);
    } else {
      newVisibility.splice(found, 1);
      setQuestionVisibility(newVisibility);
    }
  };
  const typeOptions = [
    {
      value: 'check',
      label: t('polls.typeOptions.check'),
    },
    {
      value: 'boolean',
      label: t('polls.typeOptions.boolean'),
    },
    // {
    //   value: 'string',
    //   label: t('polls.typeOptions.string'),
    // },
  ];

  useEffect(() => {
    if (changeTypeStatus === false) {
      setChangeTypeStatus(true);
    }
  }, [changeTypeStatus]);

  return (
    <>
      <Field error={errors.questions?.message}>
        <div className="flex items-center space-x-2">
          <Typography color={errors.questions?.message ? 'error' : 'black'}>
            {t('polls.questions')}
          </Typography>
        </div>
      </Field>
      <div className="space-y-12 ">
        <div className="flex flex-col gap-x-8 gap-y-2">
          {fields &&
            fields.map(({ id }, index: number) => {
              const question = register(`questions[${index}].question`);
              const minAnswers = register(`questions[${index}].minAnswers`);
              const maxAnswers = register(`questions[${index}].maxAnswers`);
              const type = register(`questions[${index}].type`);
              const newMinAnswerOptions: { value: number; label: string }[] = [];
              const newMaxAnswerOptions: { value: number; label: string }[] = [];
              const totAnswer = questions[index].answers?.length || 1;

              for (let i = 0; i <= totAnswer; i += 1) {
                if (i === 0) {
                  if (questions[index].type === 'string') {
                    newMinAnswerOptions.push({ value: i, label: i as unknown as string });
                  }
                } else {
                  newMinAnswerOptions.push({ value: i, label: i as unknown as string });
                  newMaxAnswerOptions.push({ value: i, label: i as unknown as string });
                }
              }

              const changeType = (index: number, event: any) => {
                const eventType = event.target.value;
                setValue(`questions[${index}].type`, eventType);
                if (eventType === 'boolean') {
                  const answers = [
                    {
                      aId: uniqueId(4),
                      answer: t('polls.typeOptions.true'),
                      nextQuestion: '',
                    },
                    {
                      aId: uniqueId(4),
                      answer: t('polls.typeOptions.false'),
                      nextQuestion: '',
                    },
                  ];
                  setValue(`questions[${index}].answers`, answers);
                }
                if (eventType === 'check') {
                  const answers = [
                    {
                      aId: uniqueId(4),
                      answer: '',
                      nextQuestion: '',
                    },
                  ];
                  setValue(`questions[${index}].answers`, answers);
                }
                clearErrors();
                setChangeTypeStatus(false);
              };

              return (
                <div
                  key={index}
                  className="mb-8 rounded-lg border-2 border-grey-200 bg-grey-200 p-2"
                >
                  <div className="grid grid-cols-2 gap-x-8 gap-y-2 ">
                    <div className="col-span-2">
                      <div className="flex flex-row justify-between">
                        <Tooltip content={t('polls.open_flow')}>
                          <Button type="ghost" action={() => handleOpenFlows(questions)}>
                            <Icon icon={IconGraph} className="!h-4 !w-4" color="primary-400" />
                          </Button>
                        </Tooltip>
                        <Button
                          type="ghost"
                          action={() => handleVQuestionVisibility(questions[index].qId)}
                          icon={IconArrowDown}
                          iconColor="primary-400"
                          iconClass={
                            questionVisibility.findIndex(
                              (element) => element === questions[index].qId
                            ) >= 0
                              ? '!h-6 !w-6'
                              : '!h-6 !w-6 rotate-180'
                          }
                        >
                          <div className="flex flex-row">
                            <Typography>{t('polls.question', { number: index + 1 })}</Typography>
                          </div>
                        </Button>
                        <Tooltip content={t('polls.removeQuestion')}>
                          <Button
                            type="ghost"
                            action={() => remove(index)}
                            disabled={questions[index].isFirst}
                          >
                            <IconTrash className="!h-4 !w-4" />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      questionVisibility.findIndex((element) => element === questions[index].qId) >=
                      0
                        ? ' collapse h-0'
                        : ''
                    } col-span-2`}
                  >
                    <div className="grid grid-cols-2 gap-x-8 gap-y-2 ">
                      <Field
                        className="col-span-2"
                        error={errors.questions?.[index]?.question?.message}
                      >
                        <Textarea
                          type="text"
                          rows={2}
                          placeholder={t('polls.question_placeholder')}
                          name={question.name}
                          onBlur={question.onBlur}
                          onChange={question.onChange}
                          inputRef={question.ref}
                          error={errors.questions?.[index]?.question?.message}
                        />
                      </Field>

                      <Field
                        label={t('polls.type')}
                        error={errors.questions?.[index]?.type?.message}
                      >
                        <Select
                          options={typeOptions}
                          id={type.name}
                          placeholder={t('polls.type')}
                          name={type.name}
                          onBlur={type.onBlur}
                          // onChange={type.onChange}
                          value={values.questions[index].type}
                          onChange={(e) => changeType(index, e)}
                          inputRef={type.ref}
                          error={errors.questions?.[index]?.type?.message}
                        />
                      </Field>
                      {values.questions[index].type === 'check' && (
                        <Field
                          className="col-start-1"
                          label={t('polls.minAnswers')}
                          error={errors.questions?.[index]?.minAnswers?.message}
                        >
                          <Select
                            options={newMinAnswerOptions}
                            placeholder={t('polls.minAnswers')}
                            defaultValue={questions[index].minAnswers}
                            name={minAnswers.name}
                            onBlur={minAnswers.onBlur}
                            onChange={minAnswers.onChange}
                            inputRef={minAnswers.ref}
                            error={errors.questions?.[index]?.minAnswers?.message}
                          />
                        </Field>
                      )}
                      {values.questions[index].type === 'check' && (
                        <Field
                          label={t('polls.maxAnswers')}
                          error={errors.questions?.[index]?.maxAnswers?.message}
                          className={`${
                            questions[index].type === 'boolean' ? ' collapse h-0' : ''
                          }`}
                        >
                          <Select
                            defaultValue={questions[index].maxAnswers}
                            options={newMaxAnswerOptions}
                            placeholder={t('polls.maxAnswers')}
                            name={maxAnswers.name}
                            onBlur={maxAnswers.onBlur}
                            onChange={maxAnswers.onChange}
                            inputRef={maxAnswers.ref}
                            error={errors.questions?.[index]?.maxAnswers?.message}
                          />
                        </Field>
                      )}
                    </div>
                    <Field
                      className="col-span-2"
                      error={errors.questions?.[index]?.answers?.message}
                    >
                      <Typography
                        color={errors.questions?.[index]?.answers?.message ? 'error' : 'black'}
                      >
                        {t('polls.answersToQuestion')}
                      </Typography>
                    </Field>
                    <AnswerFields
                      questionId={questions[index].qId}
                      values={values}
                      control={control}
                      errors={errors}
                      resetField={resetField}
                      register={register}
                      setValue={setValue}
                    />
                  </div>
                </div>
              );
            })}
          <div className="mb-10 flex flex-row justify-around">
            <Tooltip content={t('polls.addQuestion')}>
              <Button
                type="ghost"
                action={() => {
                  append({
                    qId: uniqueId(4),
                    question: '',
                    minAnswers: 1,
                    maxAnswers: 1,
                    isFirst: false,
                    type: 'check',
                    answers: [
                      {
                        aId: uniqueId(4),
                        answer: '',
                        nextQuestion: '',
                      },
                    ],
                  });
                }}
                icon={IconPlus}
                iconSize="lg"
                iconColor="grey-50"
                className="mx-auto"
              />
            </Tooltip>
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-2 ">
            <Button action={onBack}>{t('polls.goToGeneral')}</Button>
            <Button action={onSubmit}>{t('polls.send')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionFields;
