import { SVGProps } from 'react';

const IconShare2 = (props: SVGProps<SVGSVGElement>) => {
  const { stroke } = props;
  return (
    <svg
      width={192}
      height={192}
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1653_38780)">
        <path
          d="M132 114L168 78L132 42"
          stroke={stroke || '#4F4F4F'}
          strokeWidth={12}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144 162H30C28.4087 162 26.8826 161.368 25.7574 160.243C24.6321 159.117 24 157.591 24 156V66"
          stroke={stroke || '#4F4F4F'}
          strokeWidth={12}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.25 132C60.2537 116.545 69.2755 102.857 81.8994 93.0836C94.5233 83.3102 110.035 78.0048 126 78H168"
          stroke={stroke || '#4F4F4F'}
          strokeWidth={12}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1653_38780">
          <rect width={192} height={192} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconShare2;
