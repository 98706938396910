import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { sendModifyPushNotification } from '../../../../api/axios';
import { Button, Field, Form, Input, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconClose, IconTrash } from '../../../../icons';
import {
  getAvailableContracts,
  getContractsFromFunctionalities,
  getWorkspaceCreatedContracts,
} from '../../../../imports/contractsParsers';
import { AvailableOn, ProductAttribute, ProductForm } from '../../../../imports/types';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { updateProduct } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getCollectionRef,
  setFunctionality,
  setNftData,
  updateAvailableOn,
  updateNft,
} from '../../api';
import { Contract, FunctionalitiesProduct, Nft, NftAttribute } from '../../imports/types';
import ContractsManager from '../contractsManager/ContractsManager';
import { getContractsToRemove } from './query';

type ProductProps = {
  edit: FunctionalitiesProduct | null;
};

const Product = ({ edit }: ProductProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { workspace, contractsList, uid } = useAppSelector((state) => ({
    workspace: state.team.workspace,
    contractsList: state.contracts.list,
    uid: state.user.uid,
  }));
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>(
    edit ? getContractsFromFunctionalities(edit.availableOn, contractsList) : []
  );
  const dispatch = useAppDispatch();

  // FORM FOR PRODUCT
  const formProduct = {
    initialValuesProduct: {
      title: edit?.title || '',
      attributes: edit?.attributes || [],
    },
    validationSchemaProduct: yup.object({}),
  };
  const { initialValuesProduct, validationSchemaProduct } = formProduct;

  const handleSubmitProduct = async (fields: { attributes: ProductAttribute; title: string }) => {
    if (workspace) {
      const newFunctionalityRef = getCollectionRef('products', edit);

      const functionality: FunctionalitiesProduct = {
        id: newFunctionalityRef.id,
        createdAt: edit?.createdAt || Date.now(),
        title: edit ? (edit.title !== fields.title ? fields.title : edit.title) : fields.title,
        attributes: edit
          ? edit.attributes !== fields.attributes
            ? fields.attributes
            : edit.attributes
          : fields.attributes,
        workspaceId: workspace.id,
        availableOn: edit?.availableOn || {},
        type: 'product',
        updatedAt: Date.now(),
      };

      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      if (
        getWorkspaceCreatedContracts(workspace.id, contractsList) &&
        selectedContracts.length > 0
      ) {
        functionality.availableOn = getAvailableContracts(selectedContracts);
        /* add news on db in functionalities collection*/

        if (edit) {
          /**
           * Code to delete the functionality from NFTs
           */
          await Promise.allSettled(
            getContractsFromFunctionalities(
              getContractsToRemove(edit.availableOn, selectedContracts),
              contractsList
            ).map((contract) => {
              return contract.nfts.map((nft: Nft) => {
                /* update nft.attributes on redux contract */

                return updateNft(
                  {
                    ...nft,
                    attributes: nft.attributes?.filter(({ id }) => !(id === functionality.id)),
                    updatedAt: Date.now(),
                  },
                  contract.id
                );
              });
            })
          );

          const availableOn: AvailableOn = selectedContracts.reduce((acc, contract) => {
            acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
            return acc;
          }, {} as AvailableOn);
          updateAvailableOn(newFunctionalityRef, availableOn);
        }

        const data = await Promise.allSettled(
          selectedContracts
            .map(async (contract) => {
              /**
               * Create a copy of contract
               */
              const updatedContract = cloneDeep(contract);

              const modifiedNftsIds: number[] = [];
              const promises = contract.nfts.map(async (nft) => {
                try {
                  /**
                   * if edit exists we are modifying the attribute, if not we are creating it
                   */
                  const newAttributes: NftAttribute[] = cloneDeep(
                    nft?.attributes
                  ) as NftAttribute[];

                  const attributeIndex = newAttributes?.findIndex(({ id }) => {
                    return id === functionality.id;
                  });
                  if (attributeIndex === -1) {
                    newAttributes.push({
                      trait_type: fields.title,
                      type: functionality.type,
                      value: fields.attributes,
                      id: functionality.id,
                    });
                  } else {
                    newAttributes[attributeIndex] = {
                      ...newAttributes[attributeIndex],
                      value: fields.attributes,
                    };
                  }
                  const data = { attributes: newAttributes };

                  updatedContract.nfts = updatedContract.nfts.map((updatedNft: Nft) => {
                    if (nft.id === updatedNft.id) {
                      return { ...updatedNft, attributes: newAttributes };
                    }
                    return updatedNft;
                  });

                  modifiedNftsIds.push(nft.id);
                  const promise = await setNftData(contract.id, nft, data);

                  /**
                   * create newNfts array and adding it only once. Populated with
                   * cloned and eventuyally modified nfts
                   *  */

                  return promise;
                } catch (error) {
                  console.log(error);
                  loadingStatusDispatch({
                    type: 'SET_ERROR',
                    payload: {
                      title: t('collection.functionality.actions.failed'),
                    },
                  });
                  return error;
                }
              });
              await sendModifyPushNotification(uid, contract.id, modifiedNftsIds);
              dispatch(updateContract(updatedContract));

              return promises;
            })
            .flat()
        );
        const { value, error } = await setFunctionality(newFunctionalityRef, functionality, true);
        let isError = false;

        data.forEach((promise: any) => {
          if (promise.status === 'fulfilled' && promise.value.error) {
            isError = true;
          }
        });

        if (isError || error) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        }
        if (value) {
          dispatch(updateProduct(functionality as unknown as ProductForm));

          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: edit
                ? t('collection.functionality.actions.updated')
                : t('collection.functionality.actions.success'),
            },
          });
        }
      } else {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('collection.functionality.actions.failed'),
          },
        });
      }
    }
  };

  return (
    <div>
      <Typography color="primary-500" size="body-medium-30" className="pl-8 pt-8">
        {t('collection.functionality.product')}
      </Typography>
      <Form
        initialValues={initialValuesProduct}
        validationSchema={validationSchemaProduct}
        className="mt-4 flex size-full flex-col items-center"
      >
        {({ handleSubmit, errors, resetField, watch, register, control, fields: formFields }) => {
          const values = watch();
          const { append, remove, insert, fields } = useFieldArray({
            control,
            name: `attributes`,
          });

          const handleOnDragEnd = (result: any) => {
            if (!result.destination) return;
            const draggedItem = values.attributes[result.source.index];
            remove(result.source.index);
            insert(result.destination.index, draggedItem);
          };

          return (
            <div className="flex flex-col gap-4 p-8">
              <DragDropContext
                onDragEnd={(result: any) => handleOnDragEnd(result /* , category */)}
              >
                <Droppable droppableId="custom-fields">
                  {(provided) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="flex size-full flex-col content-center gap-4 ">
                          <div className="flex max-h-[500px] flex-col gap-4 overflow-x-auto">
                            <Field
                              className="w-1/2"
                              error={errors.attributes?.title?.message}
                              label={t('collection.functionality.product')}
                            >
                              <Input
                                id={formFields.title.name}
                                type="text"
                                placeholder={t('collection.functionality.description_product')}
                                name={formFields.title.name}
                                onBlur={formFields.title.onBlur}
                                onChange={formFields.title.onChange}
                                inputRef={formFields.title.ref}
                                error={errors.attributes?.title?.message}
                                elementRight={
                                  <Button
                                    type="ghost"
                                    icon={IconClose}
                                    action={() => resetField(formFields.title.name)}
                                  />
                                }
                              />
                            </Field>
                            {fields.map(({ id }, index) => {
                              const fieldName = register(`attributes[${index}].name`);
                              const fieldDescription = register(`attributes[${index}].description`);
                              return (
                                <Draggable draggableId={id} key={id} index={index}>
                                  {(provided) => {
                                    return (
                                      <div
                                        className="flex w-full flex-col gap-4"
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        key={id + index}
                                      >
                                        <div className="flex flex-row items-center gap-4 p-1">
                                          <div className="flex w-full flex-row gap-4 rounded-[4px] border border-grey-200  p-2">
                                            <Field
                                              error={errors.attributes?.[index]?.name?.message}
                                              label={t('collection.functionality.name_product')}
                                              className="w-1/2"
                                            >
                                              <Input
                                                id={fieldName.name}
                                                type="text"
                                                placeholder={t(
                                                  'collection.functionality.name_placeholder'
                                                )}
                                                name={fieldName.name}
                                                onBlur={fieldName.onBlur}
                                                onChange={fieldName.onChange}
                                                inputRef={fieldName.ref}
                                                error={errors.attributes?.[index]?.name?.message}
                                                elementRight={
                                                  <Button
                                                    type="ghost"
                                                    icon={IconClose}
                                                    action={() => resetField(fieldName.name)}
                                                  />
                                                }
                                              />
                                            </Field>
                                            <Field
                                              className="w-1/2"
                                              error={errors.attributes?.[index]?.name?.message}
                                              label={t(
                                                'collection.functionality.description_product'
                                              )}
                                            >
                                              <Input
                                                id={fieldDescription.name}
                                                type="text"
                                                placeholder={t(
                                                  'collection.functionality.description_placeholder'
                                                )}
                                                name={fieldDescription.name}
                                                onBlur={fieldDescription.onBlur}
                                                onChange={fieldDescription.onChange}
                                                inputRef={fieldDescription.ref}
                                                error={errors.attributes?.[index]?.name?.message}
                                                elementRight={
                                                  <Button
                                                    type="ghost"
                                                    icon={IconClose}
                                                    action={() => resetField(fieldDescription.name)}
                                                  />
                                                }
                                              />
                                            </Field>
                                          </div>
                                          <button
                                            id={`remove-attributes[${index}]`}
                                            type="button"
                                            onClick={() => remove(index)}
                                          >
                                            <IconTrash
                                              height={20}
                                              width={20}
                                              className="cursor-pointer"
                                              stroke="primary-500"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </div>
                          <div className="flex w-full flex-row justify-start gap-7">
                            <Button
                              id="add-field"
                              action={() =>
                                append({
                                  name: '',
                                  description: '',
                                })
                              }
                            >
                              +
                            </Button>
                          </div>
                          <Field error={errors.options?.message}>
                            <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
                              {t('collection.functionality.select_contract')}
                            </Typography>
                            <div className="!max-h-[400px] overflow-auto">
                              <ContractsManager
                                availableContracts={edit?.availableOn ? edit.availableOn : {}}
                                setContracts={setSelectedContracts}
                              />
                            </div>
                          </Field>
                        </div>
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              <div className="mt-4 flex justify-end">
                <Button
                  id="create-functionality"
                  type="primary"
                  disabled={!values.title || !values.attributes.length || !selectedContracts.length}
                  action={() => handleSubmit(() => handleSubmitProduct(values))()}
                  className="px-6 "
                >
                  {t('collection.functionality.submit')}
                </Button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default Product;
