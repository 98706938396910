import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  SetOptions,
  updateDoc,
} from 'firebase/firestore';
import { useQuery } from 'react-query';

import { ECollections } from './enums';
import { db as firestore } from './firebase';

export const getDocumentsFromSub = async (
  collectionName: ECollections | string,
  documentId: string,
  subCollectionName: string,
  ...queryConstraints: QueryConstraint[]
) => {
  const q = query(
    collection(firestore, collectionName, documentId, subCollectionName),
    ...queryConstraints
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

export const getDocuments = async (
  collectionName: ECollections | string,
  ...queryConstraints: QueryConstraint[]
) => {
  const q = query(collection(firestore, collectionName), ...queryConstraints);
  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

export const findDocument = async (
  collectionName: ECollections | string,
  ...queryConstraints: QueryConstraint[]
) => {
  const documents = await getDocuments(collectionName, ...queryConstraints);
  if (documents.size === 0) return undefined;
  if (documents.size > 1)
    throw new Error(`[error] - Excpected to find only one document, found ${documents.size}`);
  return documents.docs[0];
};

export const getDocument = async (collectionName: ECollections | string, documentId: string) => {
  const docRef = doc(firestore, collectionName, documentId);
  const docSnap = await getDoc(docRef);
  return docSnap;
};

export const addDocument = async (collectionName: ECollections, documentData: any) => {
  const docRef = await addDoc(collection(firestore, collectionName), documentData);
  return docRef;
};

export const setDocument = async (
  collectionName: ECollections | string,
  documentId: string,
  documentData: any,
  options?: SetOptions
) => {
  const ref = doc(firestore, collectionName, documentId);
  if (options) {
    await setDoc(ref, documentData, options);
  } else {
    await setDoc(ref, documentData);
  }
};

export const updateDocument = async (
  collectionName: ECollections,
  documentId: string,
  data: any
) => {
  const ref = doc(firestore, collectionName, documentId);
  await updateDoc(ref, data);
};

export const updateSubCollection = async (
  subCollectionPath: string,
  documentId: string,
  data: any
) => {
  const ref = doc(firestore, subCollectionPath, documentId);
  await updateDoc(ref, data);
};

export const useGetDocuments = (
  collectionName: ECollections | string,
  ...queryConstraints: QueryConstraint[]
) => {
  const query = useQuery([collectionName], () => getDocuments(collectionName, ...queryConstraints));
  return { ...query };
};

export const useGetDocumentsFromSub = (
  collectionName: ECollections | string,
  documentId: string,
  subCollectionName: ECollections | string,
  ...queryConstraints: QueryConstraint[]
) => {
  const query = useQuery([collectionName, documentId, subCollectionName], () =>
    getDocumentsFromSub(collectionName, documentId, subCollectionName, ...queryConstraints)
  );
  return { ...query };
};

export const useGetDocument = (collectionName: ECollections | string, documentId: string) => {
  const query = useQuery([collectionName], () => getDocument(collectionName, documentId));

  return { ...query };
};
