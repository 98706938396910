import { SVGProps } from 'react';

const IconFolderNotch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1829_44529)">
      <path
        d="M96 60H162C163.591 60 165.117 60.6321 166.243 61.7574C167.368 62.8826 168 64.4087 168 66V150C168 151.591 167.368 153.117 166.243 154.243C165.117 155.368 163.591 156 162 156H30C28.4087 156 26.8826 155.368 25.7574 154.243C24.6321 153.117 24 151.591 24 150V78"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.975 78H24V48C24 46.4087 24.6321 44.8826 25.7574 43.7574C26.8826 42.6321 28.4087 42 30 42H69.975C71.2724 42.0053 72.5339 42.4259 73.575 43.2L96 60L73.575 76.8C72.5339 77.5741 71.2724 77.9947 69.975 78V78Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1829_44529">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFolderNotch;
