import { UseFormSetValue } from 'react-hook-form';
import { MODULES } from '../../../../../imports/constants';
import { WorkspaceMember } from '../../../imports/types';

export const changeSelection = (
  selection: string,
  index: number,
  module: 'token_creator' | 'notarization' | 'audit',
  setValue: UseFormSetValue<any>
) => {
  if (selection === 'viewer') {
    setValue(`${index}.${module}.write`, false);
    setValue(`${index}.${module}.read`, true);
  }
  if (selection === 'editor') {
    setValue(`${index}.${module}.write`, true);
    setValue(`${index}.${module}.read`, true);
  }
  if (selection === 'no_access') {
    setValue(`${index}.${module}.write`, false);
    setValue(`${index}.${module}.read`, false);
  }
};

export const getInitialValues = (array: WorkspaceMember[]) => {
  const initialValArray: object[] = [];
  array.forEach((user) => {
    const permissions = MODULES.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.toLowerCase()]: {
          read: user?.permissions[cur.toLowerCase()].read,
          write: user?.permissions[cur.toLowerCase()].write,
        },
      }),
      {}
    );
    initialValArray.push(permissions);
  });
  const objectInitialValues = initialValArray.reduce(
    (acc, cur, i) => ({
      ...acc,
      [array[i].user]: cur,
    }),
    {}
  );
  return objectInitialValues;
};
