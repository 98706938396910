/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38843)">
      <path
        d="M126 126H162V30H66V66"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126 66H30V162H126V66Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38843">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconCopy;
