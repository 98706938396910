/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M91.7258 53.55L106.576 38.7C112.863 32.9694 121.117 29.8818 129.621 30.0787C138.126 30.2756 146.228 33.7419 152.244 39.7573C158.259 45.7727 161.725 53.8745 161.922 62.3793C162.119 70.8841 159.031 79.1377 153.301 85.425L132.076 106.575C129.017 109.645 125.382 112.082 121.379 113.744C117.376 115.406 113.085 116.262 108.751 116.262C104.417 116.262 100.125 115.406 96.1225 113.744C92.1198 112.082 88.4847 109.645 85.4258 106.575"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.276 138.45L85.4255 153.3C79.1382 159.03 70.8846 162.118 62.3798 161.921C53.875 161.724 45.7732 158.258 39.7578 152.243C33.7424 146.227 30.2761 138.125 30.0792 129.621C29.8823 121.116 32.9699 112.862 38.7005 106.575L59.9255 85.4249C62.9845 82.3545 66.6195 79.9182 70.6222 78.2559C74.6249 76.5935 78.9164 75.7378 83.2505 75.7378C87.5846 75.7378 91.8761 76.5935 95.8788 78.2559C99.8815 79.9182 103.517 82.3545 106.576 85.4249"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconLink;
