import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { IconClose, IconCopy, IconEdit, IconLink } from '../../icons';
import { copyToClipboard, formatDate } from '../../imports/utils';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};
const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="m-2 flex h-[51px] flex-col">
      <Typography>{label.toUpperCase()}</Typography>
      <div className="flex flex-row items-center justify-start gap-2">{children}</div>
    </div>
  );
};

type CollectionDetailsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { notarization } = useModalProps('CollectionDetailsModal');
  const { t } = useTranslation(['notarization']);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col ">
        <div className="grid h-full grid-cols-2 content-center p-4">
          <div className="col-span-2 m-2 p-2">
            {notarization.folderName !== '' ? (
              <Typography>
                {notarization.folderName}&nbsp;&nbsp;/&nbsp;&nbsp;{notarization.name}
              </Typography>
            ) : (
              <Typography>{notarization.name}</Typography>
            )}
          </div>
          <InfoSection label={t('view_notarization.description') as string}>
            <div>{notarization.description}</div>
          </InfoSection>

          <InfoSection label={t('view_notarization.date') as string}>
            {formatDate(notarization.date)}
          </InfoSection>

          <InfoSection label={t('view_notarization.tags') as string}>
            {notarization.tags.length > 0 && (
              <div className="grid grid-cols-3">
                {notarization.tags.map((tag: string) => {
                  return <Typography> {tag}</Typography>;
                })}
              </div>
            )}
          </InfoSection>

          {notarization.notes && (
            <InfoSection label={t('view_notarization.notes') as string}>
              <div className="flex items-center justify-center">
                {/* <Image src={ImageCircularExpand} alt="options" className="h-[15px] w-[15px]" /> */}
                {notarization.notes}
                <div className="w-min">
                  <Button
                    icon={IconCopy}
                    type="ghost"
                    action={() => handleCopyToClipboard(notarization.notes)}
                  />
                </div>
                <div className="w-min">
                  <Button
                    icon={IconEdit}
                    type="ghost"
                    action={() => handleCopyToClipboard(notarization.notes)}
                  />
                </div>
              </div>
            </InfoSection>
          )}
          {notarization.hash && (
            <InfoSection label={t('view_notarization.hash') as string}>
              {t('view_notarization.hash')}
              <div className="w-min">
                <Button
                  icon={IconCopy}
                  type="ghost"
                  action={() => handleCopyToClipboard(notarization.hash)}
                />
              </div>
            </InfoSection>
          )}

          {notarization.storageType && (
            <InfoSection label={t('view_notarization.storage') as string}>
              <Typography>{notarization.storageType}</Typography>
              <div className="w-min">
                <Button
                  icon={IconCopy}
                  type="ghost"
                  action={() => handleCopyToClipboard(notarization.notes)}
                />
              </div>
              <div className="w-min">
                <Button
                  icon={IconClose}
                  iconStroke="error"
                  type="ghost"
                  action={() => handleCopyToClipboard(notarization.notes)}
                />
              </div>
            </InfoSection>
          )}

          {notarization.explorer && (
            <InfoSection label={t('view_notarization.blockchain_explorer_api') as string}>
              <Typography>{notarization.explorer}</Typography>
              <div className="w-min">
                <Button
                  icon={IconLink}
                  type="ghost"
                  action={() => handleCopyToClipboard(notarization.notes)}
                />
              </div>
            </InfoSection>
          )}
        </div>

        <div className="absolute bottom-0 right-0 flex flex-row gap-4 px-6 pb-6">
          <Button type="secondary" id="createFolder" action={() => ''}>
            {t('view_notarization.download_file')}
          </Button>
          <Button type="primary" id="createNotarization" action={() => ''}>
            {t('view_notarization.download_report')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const CollectionDetailsModal = ({ children }: CollectionDetailsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="CollectionDetailsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default CollectionDetailsModal;
