import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Field, Textarea, TextareaQuill, Typography } from '../../../../components';
import DateAndTimeSelect from '../../../../components/dateAndTimeSelect/DateAndTimeSelect';
import { ExportedProps } from '../../../../components/form/Form';
import { getAvailableContracts } from '../../../../imports/contractsParsers';
import { getDefaultDateTimeValue, hoursOptionsForSelect } from '../../../../imports/utils';
import { Contract } from '../../imports/types';
import ContractsManager from '../contractsManager/ContractsManager';

interface PollsGeneralProps
  extends Omit<
    ExportedProps,
    | 'trigger'
    | 'register'
    | 'reset'
    | 'getValues'
    | 'isValid'
    | 'clearErrors'
    | 'dirtyFields'
    | 'isLoading'
    | 'resetField'
    | 'handleSubmit'
  > {
  values: any;
  onCompleted: () => void;
}

const PollsGeneral: React.FC<PollsGeneralProps> = ({
  errors,
  values,
  fields,
  watch,
  setValue,
  onCompleted,
}) => {
  const { t } = useTranslation(['tokenCreator']);

  const { defaultDate, defaultTime } = getDefaultDateTimeValue({ extraHours: 2 });

  return (
    <>
      <div className="grid grid-cols-2 gap-x-8 gap-y-2 ">
        <Field label={t('polls.title')} error={errors.title?.message} className="col-span-2">
          <Textarea
            resize
            rows={1}
            name={fields.title.name}
            id={fields.title.name}
            onChange={fields.title.onChange}
            onBlur={fields.title.onBlur}
            inputRef={fields.title.ref}
            placeholder={t('polls.title_placeholder')}
            error={errors.title?.message}
          />
        </Field>
        {/* body */}
        <Field
          label={t('polls.description_poll')}
          error={errors.body?.message}
          className="col-span-2 h-[300px]"
        >
          <TextareaQuill setValue={setValue} field="body" value={values.body} />
        </Field>

        <DateAndTimeSelect
          date={{
            label: t('polls.startDate'),
            error: errors.startDate?.message,
            name: fields.startDate.name,
            onBlur: fields.startDate.onBlur,
            onChange: fields.startDate.onChange,
            inputRef: fields.startDate.ref,
            minValue: defaultDate,
          }}
          time={{
            label: t('polls.startHours'),
            error: errors.startHours?.message,
            options: hoursOptionsForSelect,
            name: fields.startHours.name,
            onBlur: fields.startHours.onBlur,
            onChange: fields.startHours.onChange,
            inputRef: fields.startHours.ref,
          }}
        />

        <DateAndTimeSelect
          date={{
            label: t('polls.expiresAt'),
            name: fields.endDate.name,
            onBlur: fields.endDate.onBlur,
            onChange: fields.endDate.onChange,
            inputRef: fields.endDate.ref,
            error: errors.endDate?.message,
            minValue: values.startDate ?? defaultDate,
          }}
          time={{
            label: t('polls.endHours'),
            options: hoursOptionsForSelect,
            name: fields.endHours.name,
            onBlur: fields.endHours.onBlur,
            onChange: fields.endHours.onChange,
            inputRef: fields.endHours.ref,
            error: errors.endHours?.message,
          }}
        />

        {/* <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <Typography>{t('polls.private') as string}</Typography>
          </div>

          <Checkbox
            name={fields.private.name}
            onBlur={fields.private.onBlur}
            onChange={fields.private.onChange}
            inputRef={fields.private.ref}
            error={errors.private?.message}
          />
        </div> */}
        {/* <div className='flex flex-row'>
          <Field label={t('polls.private')} error={errors.endDate?.message}>
            <Checkbox
              name={fields.private.name}
              onBlur={fields.private.onBlur}
              onChange={fields.private.onChange}
              inputRef={fields.private.ref}
              error={errors.private?.message}
            />
          </Field>
        </div> */}

        {/* <Field label={t('polls.minTotalAnswers')} error={errors.minTotalAnswers?.message}>
          <Input
            type="number"
            minValue={1}
            name={fields.minTotalAnswers.name}
            onBlur={fields.minTotalAnswers.onBlur}
            onChange={fields.minTotalAnswers.onChange}
            inputRef={fields.minTotalAnswers.ref}
            error={errors.minTotalAnswers?.message}
          />
        </Field>
        <Field label={t('polls.maxTotalAnswers')} error={errors.maxTotalAnswers?.message}>
          <Input
            type="number"
            minValue={1}
            name={fields.maxTotalAnswers.name}
            onBlur={fields.maxTotalAnswers.onBlur}
            onChange={fields.maxTotalAnswers.onChange}
            inputRef={fields.maxTotalAnswers.ref}
            error={errors.maxTotalAnswers?.message}
          />
        </Field> */}
      </div>

      <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
        {t('polls.option')}
      </Typography>
      <div className=" max-h-[400px] max-w-full overflow-x-hidden">
        <ContractsManager
          availableContracts={getAvailableContracts(values.options)}
          setContracts={(values: Contract[]) => setValue('options', values)}
          isPollPage
        />
      </div>
      <div>
        <Button id="cc-step-1" action={onCompleted} disabled={!values.options.length}>
          {t('polls.goToQuestions') as string}
        </Button>
      </div>
    </>
  );
};

export default PollsGeneral;
