import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FunctionalityCard, Typography } from '../../../../components';
import { IconLoading } from '../../../../icons';
import type { Filters, ShopForm } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

type ShopListProps = {
  shop: ShopForm[];
  isLoading: boolean;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
};
const ShopList = ({ shop, isLoading, filtersState, orderBy }: ShopListProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace } = useAppSelector((user) => user.team);
  const handleGoToAttributeDetails = (attributesId: String) => {
    navigate(`shop/${attributesId}`);
  };

  return !isLoading ? (
    <>
      <Typography size="body-semibold-20">{t('collection.title_shop')}</Typography>
      <div className="flex flex-wrap gap-5">
        {shop.length > 0 ? (
          shop.map((item, index) => {
            return (
              <div className="cursor-pointer" key={`${item.id}-${index}`}>
                <FunctionalityCard
                  functionality={item}
                  key={item.id}
                  hasWritePermission={workspace?.permissions.token_creator.write}
                  onClick={() => handleGoToAttributeDetails(item.id)}
                />
              </div>
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                shop.length === 0 ? 'collection.no_shop_found' : 'collection.no_filtered_shop_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default ShopList;
