import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FunctionComponent, SVGProps, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
/* root imports */
import { useModal } from 'react-simple-modal-provider';
import {
  Breadcrumbs,
  Button,
  CollectionFolderList,
  CollectionHeader,
  InnerPage,
  Typography,
} from '../../../../components';
import { db } from '../../../../imports/firebase';
import { Filters } from '../../../../imports/types';
import { compare, safeWhere } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';

import type { FiltersActionType, Folder } from '../../../../imports/types';

/* notarizations imports */
import type { FullNotarization, Receipt } from '../../imports/types';

import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import {
  IconCalendar,
  IconFastBack,
  IconFastForward,
  IconInfo,
  IconLoading,
} from '../../../../icons';
import { isVerifiedOnChain } from '../../../team/api/api';
import { NotarizationsList } from '../../components';

const filtersInitialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByDate: { filter: 'NOTARIZATION_DATE_MATCH', value: '' },
};

const filtersReducer = (state: typeof filtersInitialState, action: FiltersActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

const Collection = () => {
  const { t } = useTranslation(['notarization']);
  const navigate = useNavigate();
  const { search, key } = useLocation();
  const [searchParams] = useSearchParams();

  const apiSettings = searchParams.get('settings');
  const newNotarization = searchParams.get('new');

  const {
    uid,
    wallet: { address },
    workspace,
    canNotarize,
  } = useAppSelector(({ user, team, subscription }) => ({
    ...user,
    workspace: team.workspace,
    canNotarize: subscription.canNotarize,
  }));

  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);

  const orderByInitialState = { type: 'date', direction: 'desc' };

  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [notarizations, setNotarizations] = useState<Array<FullNotarization>>([]);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;

  const { open: openModalNewFolder } = useModal('NewFolderModal');
  const handleOpenNewFolder = () => {
    openModalNewFolder({ folderType: 'notarization' });
  };
  const { open: openModalNewNotarization } = useModal('NewNotarizationModal');
  const handleOpenNewNotarization = () => {
    openModalNewNotarization({ folders });
  };

  const { data: verifiedOnChain } = useQuery(
    ['verified_on_chain', address],
    async () => {
      const value = await isVerifiedOnChain(address!, workspace?.id!);
      return value;
    },
    {
      enabled: address !== '' && workspace?.id != null && !workspace?.isOwner,
    }
  );

  // !!! HANDLE MODAL
  //const showSidebarInitialState = { type: '', optionalProps: {} };
  // const [showSidebar, setShowSidebar] = useState<{ type: string; optionalProps?: any }>(
  //   showSidebarInitialState
  // );

  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      icon: IconInfo,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
      icon: IconCalendar,
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
      icon: IconCalendar,
    },
    {
      value: 'name-asc',
      label: 'A-Z',
      icon: IconFastForward,
    },
    {
      value: 'name-desc',
      label: 'Z-A',
      icon: IconFastBack,
    },
  ];
  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  // !!! HANDLE OPEN MODAL
  // const handleShowSidebar = (type: SidebarType, optionalProps: any = {}) => {
  //   setShowSidebar({ type, optionalProps });
  // };

  // !!! HANDLE CLOSE MODAL
  // const handleCloseSidebar = () => {
  //   setShowSidebar(showSidebarInitialState);
  // };

  // !!! HANDLE NEW NOTARIZATION
  const handleNewNotarization = async () => {
    if (!verifiedOnChain && !workspace?.isOwner) {
      toast.warn(t('team.not_verified') as string);
      return;
    }
    handleOpenNewNotarization();
    //handleShowSidebar('addNotarization', { folders });
  };

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup === true ? undefined : activeGroupId),
      where('type', '==', 'notarization')
    );
    const notarizationsQuery = query(
      collection(db, 'notarizations'),
      ...safeWhere(uid, workspace?.id, isSuperGroup === true ? undefined : activeGroupId)
    );
    const receiptsQuery = query(collection(db, 'receipts'));

    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setIsLoading(false);
    });

    const unsubscribeReceipts = onSnapshot(receiptsQuery, async (querySnapshot) => {
      const receipts: any[] = [];
      querySnapshot.forEach((doc) => {
        receipts.push(doc.data());
      });

      setReceipts(receipts);
    });

    const unsubscribeNotarizations = onSnapshot(notarizationsQuery, async (querySnapshot) => {
      const notarizations: any[] = [];
      querySnapshot.forEach((doc) => {
        notarizations.push({ ...doc.data(), id: doc.id });
      });

      const notarizationsFormatted = notarizations.map((notarization) => ({
        ...notarization,
        folderName: '',
      }));

      setNotarizations(notarizationsFormatted.sort((a, b) => compare(a, b, 'date', 'desc')));
      // setIsLoading(false);
    });

    return () => {
      unsubscribeFolders();
      unsubscribeReceipts();
      unsubscribeNotarizations();
    };
  }, [workspace?.id, groupId]);

  useEffect(() => {
    if (search) {
      if (apiSettings) {
        //handleShowSidebar('settings');
      }
      if (newNotarization) {
        //handleNewNotarization();
        handleOpenNewNotarization();
      }
    }
  }, [key]);

  useEffect(() => {
    if (folders.length > 0 && notarizations.length > 0) {
      const notarizationsFormatted = notarizations.map((notarization) => {
        const folder = folders.find((folder) => folder.id === notarization.folderId);

        return {
          ...notarization,
          folderName: folder ? folder.name : '',
        };
      });

      setNotarizations(notarizationsFormatted);
    }
  }, [folders]);

  return (
    <>
      <InnerPage>
        {isLoading ? (
          <div className="flex h-screen grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <>
            <CollectionHeader
              orderByOptions={orderByOptions}
              filtersState={filtersState}
              filtersDispatch={filtersDispatch}
              handleSetOrderBy={handleSetOrderBy}
              resetFilterField={resetFilterField}
            />

            <div className="mt-12 flex h-[67px] w-full flex-row  justify-between ">
              <div className="flex h-full w-2/3 flex-col p-2">
                <Breadcrumbs />
                <Typography size="body-medium-30">
                  {t('collection.title_myNotarizations')}
                </Typography>
              </div>

              {workspace?.permissions.notarization.write && (
                <div className="flex h-full w-fit flex-row gap-2 p-2">
                  <Button
                    type="secondary"
                    id="createFolder"
                    // action={() => handleShowSidebar('addFolder')}
                    action={handleOpenNewFolder}
                    className="px-4"
                  >
                    {t('collection.create_folder')}
                  </Button>
                  <Button
                    type="primary"
                    id="createNotarization"
                    action={handleNewNotarization}
                    className="px-4"
                    disabled={!canNotarize}
                  >
                    {t('collection.create_notarization')}
                  </Button>
                </div>
              )}
            </div>
            <div className="mt-12 space-y-4">
              <CollectionFolderList
                folders={folders}
                isLoading={isLoading}
                type="notarization"
                hasWritePermission={workspace?.permissions.notarization.write}
              />

              <NotarizationsList
                notarizations={notarizations}
                receipts={receipts}
                folders={folders}
                isLoading={isLoading}
                filtersState={filtersState}
                orderBy={orderBy}
              />
            </div>
          </>
        )}
      </InnerPage>
    </>
  );
};

export default Collection;
