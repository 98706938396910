import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';

import api from '../../../imports/axios';
import { db } from '../../../imports/firebase';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const createAudit = async (data: FormData, authTokenId: string) => {
  try {
    const response = await api.post('/audit', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: authTokenId,
      },
    });

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

export const createAuditDoc = async (data: any) => {
  try {
    const docRef = await addDoc(collection(db, 'audits'), data);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return result({ value: { auditId: docRef.id, data: docSnap.data() } });
    }

    return result({ value: 'Audit not created' });
  } catch (error) {
    return result({ error });
  }
};

export const updateAuditDoc = async (auditId: string, data: any) => {
  try {
    const docRef = doc(db, 'audits', auditId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await setDoc(docRef, data, { merge: true });
      const updatedDocSnap = await getDoc(docRef);

      return result({ value: { auditId: docRef.id, data: updatedDocSnap.data() } });
    }

    return result({ error: 'Audit not found' });
  } catch (error) {
    return result({ error });
  }
};

export const deleteAuditDoc = async (auditId: string) => {
  try {
    const docRef = doc(db, 'audits', auditId);
    await deleteDoc(docRef);
    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const getFolderName = async (folderId: string) => {
  const docRef = doc(db, 'folders', folderId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      return result({ value: docSnap.data().name });
    }
    return result({ error: 'No such document!' });
  } catch (error) {
    return result({ error });
  }
};

export const getFileFromStorage = async (fileUrl: string, authTokenId: string) => {
  try {
    const response = await api.get(`content/${fileUrl}`, {
      headers: {
        authorization: authTokenId,
      },
    });

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

export const verifyNotarization = async (notarizationId: string) => {
  const docRef = doc(db, 'notarizations', notarizationId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(docRef, {
        ...data,
        verified: true,
      });

      return result({ value: 'Verified' });
    }
    return result({ error: 'Notarization not found' });
  } catch (error) {
    return result({ error });
  }
};
