import { collection, doc } from '@firebase/firestore';
import { cloneDeep } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import 'reactflow/dist/style.css';
import { useLoadingStatusContext } from '../../context';
import {
  getContractsChanges,
  getContractsFromFunctionalities,
  getSpecificNft,
} from '../../imports/contractsParsers';
import { db } from '../../imports/firebase';
import { AvailableOn, ChangedContracts } from '../../imports/types';
import { removeFieldFromNft, setNftData, updateAvailableOn } from '../../modules/tokenCreator/api';
import { ContractsManager } from '../../modules/tokenCreator/components';
import { Contract, FunctionalityData, Nft } from '../../modules/tokenCreator/imports/types';
import { updateContract } from '../../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';

type ContractsManagerProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { availableContracts: oldAvailableOn, shopId, edit } = useModalProps('ShareShopModal');
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
  const dispatch = useAppDispatch();

  const handleSubmit = async (editedContracts: Contract[]) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('collection.functionality.actions.pending'),
      },
    });
    try {
      const newAttributesRef = doc(collection(db, 'functionalities'), shopId);
      const availableOn: AvailableOn = editedContracts.reduce((acc, contract) => {
        acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
        return acc;
      }, {} as AvailableOn);
      /* add availableOn to functionalities on DB */
      updateAvailableOn(newAttributesRef, availableOn);
      /* add/remove to nfts */
      const newContracts = getContractsFromFunctionalities(availableOn, contractsList);
      const oldContracts = getContractsFromFunctionalities(oldAvailableOn, contractsList);
      const changes = getContractsChanges(oldContracts, newContracts, availableOn, oldAvailableOn);
      const data: FunctionalityData = {
        ['shop' as string]: { value: edit.link, id: edit.id },
      };
      if (changes.addedContracts.length > 0) {
        changes.addedContracts.forEach((contract: Contract) => {
          const updatedContract = cloneDeep(contract);
          contract.nfts.forEach(async (nft: Nft) => {
            setNftData(contract.id, nft, data);
            /* update nft.attributes on redux contract */
            if (updatedContract) {
              const newNfts: Nft[] = [];
              updatedContract.nfts.forEach((updatedNft: Nft) => {
                if (nft.id === updatedNft.id) {
                  const newNft = cloneDeep(updatedNft);
                  newNft.shop = data.shop as { value: string; id: string };
                  newNfts.push(newNft);
                } else {
                  newNfts.push(updatedNft);
                }
              });
              updatedContract.nfts = newNfts;
            }
          });
          /* update redux state */
          dispatch(updateContract(updatedContract));
        });
      }
      if (changes.changedContracts.length > 0) {
        changes.changedContracts.forEach((changedContract: ChangedContracts) => {
          const contract = contractsList.find(
            (contract: Contract) => contract.address === changedContract.contractAddress
          );
          const updatedContract = cloneDeep(contract);
          if (changedContract.addedNfts.length > 0) {
            changedContract.addedNfts.forEach(async (id: number) => {
              const nft = getSpecificNft(contract?.id || '', id, contractsList);
              if (contract && nft) {
                setNftData(contract.id, nft, data);
                if (updatedContract) {
                  const newNfts: Nft[] = [];
                  updatedContract.nfts.forEach((updatedNft: Nft) => {
                    if (id === updatedNft.id) {
                      const newNft = cloneDeep(updatedNft);
                      newNft.shop = data.shop as { value: string; id: string };
                      newNfts.push(newNft);
                    } else {
                      newNfts.push(updatedNft);
                    }
                  });
                  updatedContract.nfts = newNfts;
                }
              }
            });
          }
          if (changedContract.removedNfts.length > 0) {
            changedContract.removedNfts.forEach(async (id: number) => {
              const nft = getSpecificNft(contract?.id || '', id, contractsList);
              if (nft && contract) {
                removeFieldFromNft(contract.id, nft, 'shop');
                if (updatedContract) {
                  const newNfts: Nft[] = [];
                  updatedContract.nfts.forEach((updatedNft: Nft) => {
                    if (id === updatedNft.id) {
                      const newNft = cloneDeep(updatedNft);
                      delete newNft.shop;
                      newNfts.push(newNft);
                    } else {
                      newNfts.push(updatedNft);
                    }
                  });
                  updatedContract.nfts = newNfts;
                }
              }
            });
          }
          /* update redux state */
          if (updatedContract) {
            dispatch(updateContract(updatedContract));
          }
        });
      }
      if (changes.removedContracts.length > 0) {
        changes.removedContracts.forEach((contract: Contract) => {
          const updatedContract = cloneDeep(contract);
          contract.nfts.forEach(async (nft: Nft) => {
            removeFieldFromNft(contract.id, nft, 'shop');
            if (updatedContract) {
              const newNfts: Nft[] = [];
              updatedContract.nfts.forEach((updatedNft: Nft) => {
                if (nft.id === updatedNft.id) {
                  const newNft = cloneDeep(updatedNft);
                  delete newNft.shop;
                  newNfts.push(newNft);
                } else {
                  newNfts.push(updatedNft);
                }
              });
              updatedContract.nfts = newNfts;
            }
          });
          dispatch(updateContract(updatedContract));
        });
      }
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('collection.functionality.actions.success'),
        },
      });
    } catch (error) {
      console.error(error);
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('collection.functionality.actions.failed'),
        },
      });
    } finally {
      setSelectedContracts([]);
    }
  };

  const handle = () => {
    handleSubmit(selectedContracts);
  };
  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="max-h-[95%] w-auto overflow-auto "
    >
      <div className="h-autow-auto mt-7 flex flex-col">
        <div className="flex h-[500px] p-4">
          <ContractsManager
            availableContracts={oldAvailableOn}
            setContracts={setSelectedContracts}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button
            action={handle}
            className="rounded bg-primary-500 px-4 py-2 font-bold text-white hover:bg-primary-400"
          >
            {t('token_manager.save')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const ShareShopModal = ({ children }: ContractsManagerProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ShareShopModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ShareShopModal;
