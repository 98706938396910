import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '../../components';
import LinkText from '../../components/linkText/LinkText';
import { IconError404 } from '../../icons';

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 ">
      <Icon icon={IconError404} size="xxxl" stroke="primary-500" />
      <Typography size="body-semibold-30" className="">
        {t(`404.ops`)}
      </Typography>
      <Typography size="body-semibold-20" className="">
        {t(`404.error_404`)}
      </Typography>
      <Typography size="body-semibold-20" className="">
        {t(`404.page_not_exist`)}
      </Typography>
      <div className="flex flex-row gap-1">
        <Typography size="body-semibold-20" className="">
          {t(`404.go_back`)}
        </Typography>
        <LinkText to="/sign-in" className="" textSize="text-body-semibold-20">
          {t(`404.home`)}
        </LinkText>
      </div>
    </div>
  );
};

export default Error404;
