/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconAsterisk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1707_42354)">
      <path
        d="M96 30V96"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2266 75.6001L96.0016 96.0001"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2266 149.4L96.0016 96"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.775 149.4L96 96"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.775 75.6001L96 96.0001"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1707_42354">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconAsterisk;
