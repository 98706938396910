import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from 'react-portal';
import SimpleBar from 'simplebar-react';

import Icon from '../icon/Icon';
import OutsideAlerter from '../outsideAlerter/OutsideAlerter';

import { IconFastForward } from '../../icons';
import { colors } from '../../imports/constants';

export type OverlaySidebarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
};

const OverlaySidebar = ({ children, isOpen, handleClose }: OverlaySidebarProps) => {
  const { black, overlay } = colors;

  // const handleCloseOnRightTarget = (event: any) => {
  //   if (
  //     event.path.find((el: any) => {
  //       if (el.className && typeof el.className === 'string') {
  //         if (el.className.includes('Toastify__toast-container')) {
  //           return true;
  //         }
  //       }

  //       return false;
  //     })
  //   ) {
  //     return;
  //   }

  //   handleClose();
  // };
  const handleCloseOnRightTarget = (event: any) => {
    try {
      event.path.find((el: any) => {
        if (el.className && typeof el.className === 'string') {
          if (el.className.includes('Toastify__toast-container')) {
            return true;
          }
        }

        return false;
      });
    } catch (error) {
      return;
    }

    handleClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <div className="fixed left-0 top-0 z-[100] h-screen w-screen bg-black/75">
            <OutsideAlerter onOutsideClick={handleCloseOnRightTarget}>
              <motion.aside
                initial={{ right: '-100%' }}
                animate={{ right: 0 }}
                exit={{ right: '-100%' }}
                transition={{ duration: 0.4 }}
                className="absolute right-0 top-0 h-full w-[21.875rem] rounded-l-md bg-white"
              >
                <button
                  type="button"
                  onClick={handleClose}
                  className="absolute left-0 top-1/2 -z-10 flex h-[13.125rem] w-[2.187rem] -translate-x-full -translate-y-1/2 items-center justify-center rounded-l-md bg-white"
                >
                  <Icon icon={IconFastForward} size="md" color="primary-400" />
                </button>
                <SimpleBar className="size-full p-10">{children}</SimpleBar>
              </motion.aside>
            </OutsideAlerter>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default OverlaySidebar;
