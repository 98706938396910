import { ReactNode, useEffect } from 'react';
import Modal, { useModalState } from 'react-simple-modal-provider';
import { useLoadingStatusContext } from '../../context';
import { delayed } from '../../imports/utils';
import ModalLayout from '../layouts/ModalLayout';

type SendNftModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  useEffect(() => {
    if (state.status === 'error') {
      delayed(() => {
        loadingStatusDispatch({
          type: 'RESET_STATE',
        });
        setOpen(false);
      }, 3500);
    }
  }, [state.status]);

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    ></ModalLayout>
  );
};

const SendNftModal = ({ children }: SendNftModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="SendNftModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default SendNftModal;
