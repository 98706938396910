import { useEffect, useRef } from 'react';

const useClickOutsideToClose = (handler: () => void) => {
  const domNode = useRef() as any;

  useEffect(() => {
    const actionHandler = (event: Event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', actionHandler);

    return () => {
      document.removeEventListener('mousedown', actionHandler);
    };
  });

  return domNode;
};

export default useClickOutsideToClose;
