import * as yup from 'yup';
// const tokenCreatorSidebarMenuOrderList = [
//   'collection',
//   'create_contract',
//   'company_profile',
//   'send_messages',
//   'crm',

import { t } from 'i18next';
import { PrefilledCustomFields, PrefilledFields, StandardTemplateNames, Template } from './types';

const EMPTY_CONTRACT_ID = 'FnaE5vyyJle8juMeZ6df';

// ];
const EMPTY_NFT = {
  name: '',
  description: '',
  quantity: 1,
  external_url: '',
  image: [],
  isUnlimited: false,
  attributes: [],
};
const prefilledSubscription: PrefilledFields[] = [
  { fieldName: 'name', fieldValue: 'prova' },
  { fieldName: 'doesTransfer', fieldValue: false },
  { fieldName: 'qrCodeDrop', fieldValue: false },
  { fieldName: 'qrCodeDropVal', fieldValue: 0 },
];
const dipendenti: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Logo Azienda' },
  { fieldName: 'name', newName: 'Nome Azienda' },
  { fieldName: 'description', newName: 'Descrizione Azienda' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', isHidden: true },
  { fieldName: 'contractExternalUrl', newName: 'Link Azienda' },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', isHidden: true, fieldValue: 1 },
  { fieldName: 'doesTransfer', isHidden: true },
  { fieldName: 'doesExpire', isHidden: true, fieldValue: false },
  { fieldName: 'tokenBurn', isHidden: true, fieldValue: false },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true, fieldValue: false },
  { fieldName: 'isPrivate', fieldValue: false, isHidden: true },
  //REDEEM
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'doesDropDateVal', isHidden: true, fieldValue: 1 },

  { fieldName: 'qrCodeDrop', newName: 'Modalità riscatto dei Certificati' },
  //NFT
  { fieldName: 'numberOfCategories', newName: 'Numero di Reparti' },
  { fieldName: 'nft_image_title', newName: 'Immagine Reparto' },
  { fieldName: 'nft_image_label', newName: "Rilascia qui l'immagine del Reparto" },
  { fieldName: 'nft_name', newName: 'Nome Reparto' },
  { fieldName: 'nft_description', newName: 'Descrizione certiRepartoficato' },
  { fieldName: 'unlimited', fieldValue: true },
  { fieldName: 'nft_quantity', newName: 'Numero corsisti', isHidden: true },
  { fieldName: 'nft_external_link', newName: 'Sito Reparto' },
];

const companyWelcomeToken: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Logo Azienda' },
  { fieldName: 'name', newName: 'Nome Azienda' },
  { fieldName: 'description', newName: 'Descrizione Azienda' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', isHidden: true },
  { fieldName: 'contractExternalUrl', newName: 'Sito Web Azienda' },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', isHidden: true, fieldValue: 1 },
  { fieldName: 'doesTransfer', isHidden: true, fieldValue: 'creator' },
  { fieldName: 'doesExpire', isHidden: true, fieldValue: false },
  { fieldName: 'tokenBurn', isHidden: true, fieldValue: false },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true, fieldValue: false },
  { fieldName: 'isPrivate', fieldValue: false, isHidden: true },
  //REDEEM
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'doesDropDateVal', isHidden: true, fieldValue: 2 },
  { fieldName: 'qrCodeDrop', isHidden: true },
  //NFT
  { fieldName: 'numberOfCategories', isHidden: true },
  { fieldName: 'nft_image_title', newName: 'Immagine Azienda' },
  { fieldName: 'nft_image_label', newName: "Rilascia qui l'immagine del Reparto" },
  { fieldName: 'nft_name', newName: 'Nome Token Azienda' },
  { fieldName: 'nft_description', newName: 'Storia Azienda' },
  { fieldName: 'unlimited', fieldValue: true, isHidden: true },
  { fieldName: 'nft_quantity', isHidden: true },
  { fieldName: 'nft_external_link', newName: 'Link Software Azienda' },
];

const companyWelcomeTokenCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'category',
    newName: 'Token di Benvenuto Azienda',
    isHidden: false,
  },
  {
    fieldName: 'text',
    isHidden: false,
  },
  {
    fieldName: 'restricted_area',
    isHidden: false,
  },
  {
    fieldName: 'document',
    isHidden: false,
    nameLabel: 'Normativa Azienda',
    descriptionLabel: 'Link di rimando alla normativa',
    newName: 'Normativa Azienda',
  },
  {
    fieldName: 'warranty_certificate',
    isHidden: false,
    nameLabel: 'Documentazione Legale Azienda',
    descriptionLabel: 'Link di rimando alla documentazione',
    newName: 'Documentazione Legale Azienda',
  },
  {
    fieldName: 'partner',
    nameLabel: 'Nome Archivio Foto',
    descriptionLabel: 'Link Archivio',
    linkLabel: "Link di rimando all'archivio",
    newName: 'Archivio Foto Azienda',
    isHidden: false,
  },

  {
    fieldName: 'authenticity_certificate',
    isHidden: true,
  },
];

const tokenExDipendenti: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Logo Azienda' },
  { fieldName: 'name', newName: 'Nome Azienda' },
  { fieldName: 'description', newName: 'Descrizione Azienda' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', isHidden: true },
  { fieldName: 'contractExternalUrl', newName: 'Sito Web Azienda' },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', isHidden: true, fieldValue: 1 },
  { fieldName: 'doesTransfer', isHidden: true },
  { fieldName: 'doesExpire', isHidden: true, fieldValue: false },
  { fieldName: 'tokenBurn', isHidden: true, fieldValue: false },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true, fieldValue: false },
  { fieldName: 'isPrivate', fieldValue: false, isHidden: true },
  //REDEEM
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'doesDropDateVal', isHidden: true, fieldValue: 2 },
  { fieldName: 'qrCodeDrop', isHidden: true },
  //NFT
  { fieldName: 'numberOfCategories', isHidden: true },
  { fieldName: 'nft_image_title', newName: 'Immagine Azienda' },
  { fieldName: 'nft_image_label', newName: "Rilascia qui l'immagine del Reparto" },
  { fieldName: 'nft_name', newName: 'Nome Token Azienda' },
  { fieldName: 'nft_description', newName: 'Storia Azienda' },
  { fieldName: 'unlimited', fieldValue: true, isHidden: true },
  { fieldName: 'nft_quantity', isHidden: true },
  { fieldName: 'nft_external_link', newName: 'Link network Azienda' },
];

const tokenExDipendentiCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'category',
    newName: 'Token Ex-Dipendenti Azienda',
    descriptionLabel: 'Saluto Azienda',
    isHidden: false,
  },
  {
    fieldName: 'text',
    isHidden: false,
  },
  {
    fieldName: 'restricted_area',
    isHidden: true,
  },
  {
    fieldName: 'document',
    isHidden: false,
    nameLabel: 'Community ex-dipendenti',
    descriptionLabel: 'Link di rimando alla community',
    newName: 'Community ex-dipendenti',
  },
  {
    fieldName: 'warranty_certificate',
    isHidden: true,
    nameLabel: 'Documentazione Legale Azienda',
    descriptionLabel: 'Link di rimando alla documentazione',
    newName: 'Documentazione Legale Azienda',
  },
  {
    fieldName: 'partner',
    nameLabel: 'Nome Archivio Foto',
    descriptionLabel: 'Link Archivio',
    linkLabel: "Link di rimando all'archivio",
    newName: 'Archivio Foto Azienda',
    isHidden: true,
  },

  {
    fieldName: 'authenticity_certificate',
    isHidden: true,
  },
];

const dipendentiCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'category',
    newName: 'Reparto',
    isHidden: false,
  },
  {
    fieldName: 'redeem_via_qr_code1',
    newName: 'Qr Code singolo per ogni Reparto',
    isHidden: false,
  },
  {
    fieldName: 'redeem_via_qr_code2',
    newName: '  Qr Code unico per tutti i Reparto',
    isHidden: false,
  },
  {
    fieldName: 'text',
    isHidden: false,
  },
  {
    fieldName: 'restricted_area',
    isHidden: false,
  },
  {
    fieldName: 'document',
    // isHidden: true,
  },
  {
    fieldName: 'partner',
    isHidden: false,
  },
  {
    fieldName: 'warranty_certificate',
    nameLabel: 'Normativa Aziendale',
    descriptionLabel: 'Link di rimando alla normativa',
    newName: 'Normativa Aziendale',
    isHidden: false,
  },
  {
    fieldName: 'authenticity_certificate',
    isHidden: true,
  },
];

const certificazioneCompetenze: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Logo Certificatore' },
  { fieldName: 'name', newName: 'Nome Certificatore' },
  { fieldName: 'description', newName: 'Descrizione Certificatore' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', isHidden: true },
  { fieldName: 'contractExternalUrl', newName: 'Sito Web Certificatore' },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', isHidden: true, fieldValue: 1 },
  { fieldName: 'doesTransfer', isHidden: true },
  { fieldName: 'tokenBurn', isHidden: true, fieldValue: false },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true, fieldValue: false },
  { fieldName: 'isPrivate', fieldValue: true, isHidden: true },
  //REDEEM
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'doesDropDateVal', isHidden: true, fieldValue: 1 },
  { fieldName: 'qrCodeDrop', isHidden: true },
  //NFT
  { fieldName: 'numberOfCategories', newName: 'Competenze' },
  { fieldName: 'nft_image_title', newName: 'Immagine Competenza' },
  { fieldName: 'nft_image_label', newName: "Rilascia qui l'immagine della Competenza" },
  { fieldName: 'nft_name', newName: 'Nome Competenza' },
  { fieldName: 'nft_description', newName: 'Descrizione Competenza' },
  { fieldName: 'unlimited', fieldValue: false, isHidden: true },
  { fieldName: 'nft_external_link', newName: 'Link Area Competenza' },
];

const certificazioneCompetenzeCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'category',
    newName: 'Token Ex-Dipendenti Azienda',
    descriptionLabel: 'Saluto Azienda',
    isHidden: false,
  },
  {
    fieldName: 'text',
    isHidden: false,
  },
  {
    fieldName: 'restricted_area',
    isHidden: true,
  },
  {
    fieldName: 'document',
    isHidden: false,
    nameLabel: 'Documentazione Competenza',
    descriptionLabel: 'Link di rimando alla Competenza',
    newName: 'Documentazione Competenza',
  },
  {
    fieldName: 'warranty_certificate',
    isHidden: false,
    nameLabel: 'Attestato Competenza',
    descriptionLabel: "Link di rimando all'attestato",
    newName: 'Attestato Competenza',
  },
  {
    fieldName: 'partner',
    nameLabel: 'Nome Archivio Foto',
    descriptionLabel: 'Link Archivio',
    linkLabel: "Link di rimando all'archivio",
    newName: 'Archivio Foto Azienda',
    isHidden: true,
  },

  {
    fieldName: 'authenticity_certificate',
    isHidden: true,
  },
];

const randstad: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Immagine copertina corso' },
  { fieldName: 'name', newName: 'Nome corso' },
  { fieldName: 'description', newName: 'Descrizione corso' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', fieldValue: ['food', 'sport'], isHidden: true },
  { fieldName: 'contractExternalUrl', isHidden: true },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', newName: 'Numero massimo di Certificati per utente' },
  { fieldName: 'doesTransfer', isHidden: true },
  { fieldName: 'doesExpire', isHidden: true },
  { fieldName: 'tokenBurn', isHidden: true },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true },
  { fieldName: 'isPrivate', fieldValue: false, isHidden: true },
  //REDEEM
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'qrCodeDrop', newName: 'Modalità riscatto dei Certificati' },
  //NFT
  { fieldName: 'numberOfCategories', newName: 'Numero di certificati' },
  { fieldName: 'nft_image_title', newName: 'Immagine certificato' },
  { fieldName: 'nft_image_label', newName: "Rilascia qui l'immagine del certificato" },
  { fieldName: 'nft_name', newName: 'Nome certificato' },
  { fieldName: 'nft_description', newName: 'Descrizione certificato' },
  { fieldName: 'unlimited', isHidden: true },
  { fieldName: 'nft_quantity', newName: 'Numero corsisti' },
  { fieldName: 'nft_external_link', isHidden: true },
];
const randstadCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'category',
    newName: 'Certificato',
    isHidden: false,
  },
  {
    fieldName: 'redeem_via_qr_code1',
    newName: 'Qr Code singolo per ogni Certificato',
    isHidden: false,
  },
  {
    fieldName: 'redeem_via_qr_code2',
    newName: '  Qr Code unico per tutti i Certificati',
    isHidden: false,
  },
  {
    fieldName: 'text',
    nameLabel: 'Nome skill',
    descriptionLabel: 'Descrizione skill',
    newName: 'Skills',
    isHidden: false,
  },
  {
    fieldName: 'restricted_area',
    isHidden: true,
  },
  {
    fieldName: 'document',
    isHidden: true,
  },
  {
    fieldName: 'partner',
    nameLabel: 'Nome sponsor',
    descriptionLabel: 'Link immagine',
    linkLabel: 'Link di rimando allo sponsor',
    newName: 'Sponsor',
    isHidden: false,
  },
  {
    fieldName: 'warranty_certificate',
    nameLabel: 'Nome corso',
    descriptionLabel: 'Link di rimando al certificato',
    newName: 'Certificato corso',
    isHidden: false,
  },
  {
    fieldName: 'authenticity_certificate',
    nameLabel: 'Nome documento',
    descriptionLabel: 'Link di rimando al documento',
    newName: 'Documenti aggiuntivi corso',
    isHidden: false,
  },
];
const randstadNfts = [
  {
    //attributes?: { trait_type: string; value: string; type: string; link?: string }[];
    description: 'descrizione1',
    external_url: 'link1',
    image: 'linkImg1',
    name: 'Nome1',
    quantity: 3,
  },
  {
    //attributes?: { trait_type: string; value: string; type: string; link?: string }[];
    description: 'descrizione2',
    external_url: 'link2',
    image: 'linkImg2',
    name: 'Nome2',
    quantity: 5,
  },
];
const prefilledTest: PrefilledFields[] = [
  { fieldName: 'name', fieldValue: 'provaPrefilledTest', newName: 'Nomignolo' },
  { fieldName: 'description', fieldValue: 'Descrizione provaPrefilledTest' },
  { fieldName: 'doesTransfer', fieldValue: false },
  { fieldName: 'tag', fieldValue: ['food', 'sport'], newName: 'Dove operiamo' },
  { fieldName: 'hasGame', fieldValue: true },
  { fieldName: 'isPrivate', fieldValue: false, isHidden: true },
  { fieldName: 'numberOfCategories', fieldValue: 2, isHidden: true },
  { fieldName: 'maxTokensPerUser', fieldValue: 1 },
  { fieldName: 'update', fieldValue: 'none' },
  { fieldName: 'doesDropDate', isHidden: true },
  { fieldName: 'tokenBurn', fieldValue: false, isHidden: true },

  // { fieldName: 'qrCodeDrop', fieldValue: false },
  // { fieldName: 'qrCodeDropVal', fieldValue: 0 },
];

const prefilledCertificate: PrefilledFields[] = [
  { fieldName: 'doesUpdate', fieldValue: false },
  { fieldName: 'hasGame', fieldValue: false },
  { fieldName: 'qrCodeDrop', fieldValue: false },
  { fieldName: 'qrCodeDropVal', fieldValue: 0 },
];

const prefilledEvent: PrefilledFields[] = [
  { fieldName: 'maxTokensPerUser', fieldValue: 1 },
  { fieldName: 'qrCodeDrop', fieldValue: false },
  { fieldName: 'qrCodeDropVal', fieldValue: 0 },
];

const templatePrefilledFields: PrefilledFields[] = [
  //GENERAL
  { fieldName: 'contractImage', newName: 'Logo Azienda' }, //dell'officina
  { fieldName: 'name', newName: 'Nome Azienda' },
  { fieldName: 'description', newName: 'Descrizione Azienda' },
  { fieldName: 'owner', isHidden: true },
  { fieldName: 'chain', isHidden: true },
  { fieldName: 'type', isHidden: true },
  { fieldName: 'tag', isHidden: true },
  { fieldName: 'contractExternalUrl', newName: 'Sito Web Azienda' },
  //SPECIFIC
  { fieldName: 'maxTokensPerUser', isHidden: true, fieldValue: 10000 }, //uguali
  { fieldName: 'doesTransfer', isHidden: true, fieldValue: true },
  { fieldName: 'transfer', isHidden: true, fieldValue: 'creator&owner' },
  { fieldName: 'doesExpire', isHidden: true, fieldValue: false },
  { fieldName: 'tokenBurn', isHidden: true, fieldValue: false },
  { fieldName: 'update', fieldValue: 'creator', isHidden: true },
  { fieldName: 'hasGame', isHidden: true, fieldValue: false },
  { fieldName: 'isPrivate', fieldValue: true, isHidden: true },
  //REDEEM
  { fieldName: 'qrCodeDrop', isHidden: true }, //Modalità riscatto Token    //uguali
  { fieldName: 'doesDropDateVal', isHidden: true, fieldValue: 1 },
  { fieldName: 'doesDropDate', isHidden: true },
  //NFT
  { fieldName: 'numberOfCategories', newName: 'Numero manutenzioni straordinaria' }, //manutenzioni
  { fieldName: 'nft_image_title', newName: 'Immagine della Manutenzione effettuata' },
  {
    fieldName: 'nft_image_label',
    newName: "Rilascia qui l'immagine della Manutenzione effettuata",
  },
  {
    fieldName: 'nft_name',
    newName: 'Nome della Manutenzione effettuata (Incidenti o danni al veicolo)',
  },
  { fieldName: 'nft_description', newName: 'Descrizione della Manutenzione effettuata' },
  { fieldName: 'unlimited', fieldValue: false, isHidden: true },
  { fieldName: 'nft_quantity', isHidden: true, fieldValue: 1 },
  { fieldName: 'nft_external_link', newName: '', isHidden: true },
  { fieldName: 'manualUpload', isHidden: true },
];

const templatePrefilledCustomFields: PrefilledCustomFields[] = [
  {
    fieldName: 'text',
    isHidden: true,
    nameLabel: 'Libretto',
    nameValue: 'Libretto',
    descriptionLabel: 'Numero ID Manutenzione',
    newName: 'Libretto',
  },
  {
    fieldName: 'document',
    isHidden: false,
    nameLabel: 'Libretto',
    descriptionLabel: 'Descrizione documento Manutenzione',
    newName: 'Libretto',
    nameValue: 'Libretto',
  },
  {
    fieldName: 'warranty_certificate',
    isHidden: false,
    nameLabel: 'Assicurazione',
    descriptionLabel: 'Numero Assicurazione',
    newName: 'Assicurazione',
    nameValue: 'Assicurazione',
  },
  {
    fieldName: 'authenticity_certificate',
    isHidden: false,
    nameLabel: 'Contratto di vendita',
    descriptionLabel: 'Numero di contratto',
    newName: 'Contratto di vendita',
    nameValue: 'Contratto di vendita',
  },
  {
    fieldName: 'partner',
    nameLabel: '',
    descriptionLabel: '',
    linkLabel: '',
    newName: '',
    isHidden: true,
  },
  {
    fieldName: 'category',
    newName: '',
    isHidden: true,
  },
  {
    fieldName: 'restricted_area',
    isHidden: true,
  },
];

const fieldToCustomize = (fieldList: any[] | undefined, fieldName: string) => {
  if (fieldList) {
    return fieldList.find((field: { fieldName: string }) => field.fieldName === fieldName);
  }
  return false;
};
const typeOfQrCode = (qrCodeDropVal: number, prefilledCustomFields?: any[] | undefined) => {
  let result: string = '';
  const type = qrCodeDropVal.toString();
  switch (type) {
    case '0':
      result = t('collection_item.label_fields.sendToWallet');
      break;

    case '1':
      result = t('collection_item.label_fields.singleQr');
      if (prefilledCustomFields) {
        const customName = fieldToCustomize(prefilledCustomFields, 'redeem_via_qr_code1')?.newName;
        if (customName) {
          result = t(`create_contract.form_labels.${customName}`);
        }
      }
      break;

    case '2':
      result = t('collection_item.label_fields.uniqueQr');
      if (prefilledCustomFields) {
        const customName = fieldToCustomize(prefilledCustomFields, 'redeem_via_qr_code2')?.newName;
        if (customName) {
          result = t(`create_contract.form_labels.${customName}`);
        }
      }
      break;

    default:
      break;
  }
  return result;
};
const tokenCreatorSidebarMenuOrderList = [
  'collection',
  'crm',
  'analytics',
  'functionality',
  'company_profile',
];

const maxDrafts = 1000;

//FORM FOR RESTRICTED AREA AND E-COMMERCE
const form_R_E = {
  initialValues_R_E: {
    link: '',
  },
  validationSchema_R_E: yup.object({}),
};

export {
  EMPTY_CONTRACT_ID,
  EMPTY_NFT,
  certificazioneCompetenze,
  certificazioneCompetenzeCustomFields,
  companyWelcomeToken,
  companyWelcomeTokenCustomFields,
  dipendenti,
  dipendentiCustomFields,
  fieldToCustomize,
  form_R_E,
  maxDrafts,
  prefilledCertificate,
  prefilledEvent,
  prefilledSubscription,
  prefilledTest,
  randstad,
  randstadCustomFields,
  randstadNfts,
  templatePrefilledCustomFields,
  templatePrefilledFields,
  tokenCreatorSidebarMenuOrderList,
  tokenExDipendenti,
  tokenExDipendentiCustomFields,
  typeOfQrCode,
};
