import { AvailableOn } from '../../../../imports/types';
import { Contract } from '../../imports/types';

export const getContractsToRemove = (
  prevContracts: AvailableOn,
  currentContract: Contract[]
): AvailableOn => {
  const contractToDelete: AvailableOn = {};

  Object.keys(prevContracts || {}).forEach((contractAddress) => {
    const contract = currentContract.find(({ address }) => address === contractAddress);

    if (contract) {
      let nftsToDelete: number[] = prevContracts[contractAddress];
      contract.nfts.forEach(({ id }) => {
        nftsToDelete = nftsToDelete.filter((tokenId) => tokenId !== id);
      });
      if (nftsToDelete.length) {
        contractToDelete[contractAddress] = nftsToDelete;
      }
    } else {
      contractToDelete[contractAddress] = prevContracts[contractAddress];
    }
  });

  return contractToDelete;
};
