import { BasicCard } from '..';
import { NftBottomCard, NftTopCard } from './index';

type NftCardProps = {
  contract?: any;
  item: any;
  disabled?: boolean;
  isPreview?: boolean;
  isAnalytics?: boolean;
};
const NftCard = ({ contract, item, disabled, isPreview, isAnalytics }: NftCardProps) => {
  return (
    <BasicCard
      top={
        <NftTopCard
          contract={contract}
          item={item}
          disabled={disabled}
          isPreview={isPreview}
          isAnalytics={isAnalytics}
        />
      }
      bottom={<NftBottomCard item={item} />}
      className={disabled ? 'pointer-events-none opacity-50' : ''}
    />
  );
};
export default NftCard;
