import { SVGProps } from 'react';

const IconFastBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5_135032)">
      <path
        d="M1.24712 8.0977L7.18166 11.9166C7.26237 11.968 7.35546 11.9968 7.45113 11.9997C7.5468 12.0027 7.6415 11.9799 7.72527 11.9336C7.80904 11.8872 7.87876 11.8192 7.92711 11.7366C7.97546 11.654 8.00063 11.5599 7.99999 11.4642L7.99999 3.83974C8.00063 3.74402 7.97546 3.64991 7.92711 3.5673C7.87876 3.48469 7.80904 3.41665 7.72527 3.37034C7.6415 3.32403 7.5468 3.30117 7.45113 3.30416C7.35546 3.30715 7.26237 3.33588 7.18166 3.38733L1.24712 7.20619C1.1715 7.25343 1.10914 7.31913 1.06591 7.39711C1.02268 7.47509 1 7.56279 1 7.65195C1 7.74111 1.02268 7.8288 1.06591 7.90678C1.10914 7.98476 1.1715 8.05046 1.24712 8.0977V8.0977Z"
        stroke="#4F4F4F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24712 8.0977L14.1817 11.9166C14.2624 11.968 14.3555 11.9968 14.4511 11.9997C14.5468 12.0027 14.6415 11.9799 14.7253 11.9336C14.809 11.8872 14.8788 11.8192 14.9271 11.7366C14.9755 11.654 15.0006 11.5599 15 11.4642L15 3.83974C15.0006 3.74402 14.9755 3.64991 14.9271 3.5673C14.8788 3.48469 14.809 3.41665 14.7253 3.37034C14.6415 3.32403 14.5468 3.30117 14.4511 3.30416C14.3555 3.30715 14.2624 3.33588 14.1817 3.38733L8.24712 7.20619C8.1715 7.25343 8.10914 7.31913 8.06591 7.39711C8.02268 7.47509 8 7.56279 8 7.65195C8 7.74111 8.02268 7.8288 8.06591 7.90678C8.10914 7.98476 8.1715 8.05046 8.24712 8.0977V8.0977Z"
        stroke="#4F4F4F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_135032">
        <rect width={16} height={16} fill="white" transform="matrix(-1 0 0 -1 16 16)" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFastBack;
