import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { FunctionComponent, SVGProps, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';

import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { useAppSelector } from '../../../../redux/hooks';

import { db } from '../../../../imports/firebase';
import { safeWhere } from '../../../../imports/utils';

import type { Filters, Folder, PublicCompany } from '../../../../imports/types';

import {
  Breadcrumbs,
  Button,
  CollectionFolderList,
  CollectionHeader,
  InnerPage,
  Typography,
} from '../../../../components';

import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import {
  IconCalendar,
  IconFastBack,
  IconFastForward,
  IconInfo,
  IconLoading,
} from '../../../../icons';
import { getAllAnalytics } from '../../../../imports/analyticsParser';
import { getWorkspaceContracts } from '../../../../imports/contractsParsers';
import { AnalyticsComponent, ContractsList } from '../../components';

const initialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'EXACT_MATCH', value: '' },
  inputFilterByDate: { filter: 'DATE_MATCH', value: '' },
};

type ActionType = {
  type: 'SET_STATE';
  filterFieldName: 'inputFilterByName' | 'inputFilterByTag' | 'inputFilterByDate' | 'inputOrderBy';
  payload: string | number | Date;
};

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

const Collections = () => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace, uid, contractsList, api } = useAppSelector(({ team, user, contracts }) => ({
    workspace: team.workspace,
    uid: user.uid,
    contractsList: contracts.list,
    api: contracts.api,
  }));

  const [publicCompany, setPublicCompany] = useState<PublicCompany>();
  const [allowCreateCollection, setAllowCreateCollection] = useState(false);
  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId =isSuperGroup === true ? undefined  : workspace?.activeGroupId;

  useEffect(() => {
    if (publicCompany?.address && publicCompany.companyName && publicCompany.profilePic !== '') {
      setAllowCreateCollection(true);
    } else {
      setAllowCreateCollection(false);
    }
  }, [publicCompany]);

  const [analytics, setAnalytics] = useState<any>();

  const [folders, setFolders] = useState<Array<Folder>>([]);
  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const { data: templates, isFetching } = useFirestoreQueryData(
    ['templates'],
    query(collection(db, 'templates'), where('active', '==', true)),
    {
      subscribe: true, // or undefined
    }
  );

  const { open: openModalNewFolder } = useModal('NewFolderModal');
  const handleOpenNewFolder = () => {
    openModalNewFolder({ folderType: 'contract' });
  };
  const { open: openModalNewCompany } = useModal('NewCompanyModal');
  const handleOpenNewCompany = () => {
    openModalNewCompany({ publicCompany, message: true });
  };
  const { open: openModalTemplate } = useModal('TemplateModal');
  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenTemplate = (standardTemplateName: string) => {
    openModalTemplate({ templates, standardTemplateName, goTo }); //, setValue, onCompleted
  };
  const [isLoading, setIsLoading] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);
  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      icon: IconInfo,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
      icon: IconCalendar,
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
      icon: IconCalendar,
    },
    {
      value: 'name-asc',
      label: 'A-Z',
      icon: IconFastForward,
    },
    {
      value: 'name-desc',
      label: 'Z-A',
      icon: IconFastBack,
    },
  ];

  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  const resetFilterField = (filterFieldName: ActionType['filterFieldName']) => {
    dispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  const getPublicCompany = async () => {
    const ownerAddress = workspace?.ownerAddress.toLowerCase();

    if (!ownerAddress) return;

    const publicDocRef = doc(db, 'publicCompany', ownerAddress);
    const snapshot = await getDoc(publicDocRef);
    if (snapshot.exists()) {
      setPublicCompany({ ...snapshot.data() });
    }
  };

  useEffect(() => {
    if (contractsList.length) {
      setAnalytics(getAllAnalytics(contractsList));
    }
  }, [workspace?.id, contractsList]);




  useEffect(() => {
    getPublicCompany();
    setIsLoading(true);
    const foldersQuery = query(
      collection(db, 'folders'),
      ...safeWhere(uid, workspace?.id, isSuperGroup ? undefined : activeGroupId),
      where('type', '==', 'contract')
    );

    const unsubscribeFolders = onSnapshot(foldersQuery, async (querySnapshot) => {
      const folders: any[] = [];
      querySnapshot.forEach((doc) => {
        folders.push(doc.data());
      });

      setFolders(folders.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setIsLoading(false);
    });
    return () => {
      unsubscribeFolders();
    };
  }, [workspace?.id, activeGroupId]);

  return (
    <>
      <InnerPage>
        {isLoading || api.fetch.loading ? (
          <div className="flex items-center justify-center h-screen grow">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <>
            <CollectionHeader
              orderByOptions={orderByOptions}
              filtersState={state}
              filtersDispatch={dispatch}
              handleSetOrderBy={handleSetOrderBy}
              resetFilterField={resetFilterField}
            />
            <div className="mt-12 flex h-[67px] w-full flex-row  justify-between ">
              <div className="flex flex-col w-2/3 h-full p-2">
                <Breadcrumbs />
                <Typography size="body-medium-30">{t('collection.title_myCollection')}</Typography>
              </div>

              {workspace?.permissions.token_creator.write && (
                <div className="flex flex-row h-full gap-2 p-2 w-fit">
                  <>
                    <Button
                      type="secondary"
                      id="createFolder"
                      action={handleOpenNewFolder}
                      className="px-4"
                    >
                      {t('collection.create_folder')}
                    </Button>
                    <Button
                      type="primary"
                      id="createCollection"
                      action={
                        !allowCreateCollection
                          ? handleOpenNewCompany
                          : //workspace.templatePack.includes('Ex-Collaboratori')
                            //? //workspace.templatePack.includes('standard')
                            () => handleOpenTemplate('standard')
                        //</></>: () => navigate('/nft/create-contract')
                      }
                      // action={
                      //   !allowCreateCollection
                      //     ? handleOpenNewCompany
                      //     : () => navigate('/nft/create-contract')
                      // }
                      className="px-4"
                    >
                      {t('collection.create_collection')}
                    </Button>
                  </>
                  {/* )} */}
                </div>
              )}
            </div>
            {workspace?.owner === uid &&
              getWorkspaceContracts(workspace.id, contractsList).length > 0 && (
                <div>
                  <AnalyticsComponent
                    analytics={analytics ? analytics.sum : undefined}
                    label={t('analytics.all')}
                    to="analytics"
                  />
                </div>
              )}
            <div className="mt-12 space-y-4">
              {/* {Object.keys(publicCompany).length === 0 ? ( */}
              {!allowCreateCollection ? (
                <Typography size="body-medium-20">{t('collection.future_collection')}</Typography>
              ) : (
                <>
                  <CollectionFolderList
                    folders={folders}
                    isLoading={isLoading}
                    type="tokenCreator"
                    hasWritePermission={workspace?.permissions.token_creator.write}
                  />
                  <ContractsList
                    folders={folders}
                    contracts={getWorkspaceContracts(workspace?.id, contractsList,activeGroupId)}
                    isLoading={api.fetch.loading}
                    filtersState={state}
                    orderBy={orderBy}
                  />
                </>
              )}
            </div>
          </>
        )}
      </InnerPage>
    </>
  );
};

export default Collections;
