import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/* root import */
import { Folder } from '../../imports/types';

import { FolderCard, Typography } from '..';
import { IconLoading } from '../../icons';

type CollectionFolderListProps = {
  folders: Folder[];
  isLoading: boolean;
  type: 'notarization' | 'tokenCreator' | 'audit';
  hasWritePermission?: boolean;
};
const CollectionFolderList = ({
  folders,
  isLoading,
  type,
  hasWritePermission,
}: CollectionFolderListProps) => {
  const { t } = useTranslation(['notarization', 'tokenCreator', 'audit']);
  const navigate = useNavigate();

  const openFolder = (folder: Folder) => {
    let url;
    if (type === 'tokenCreator') {
      url = `/nft/folder/${folder.id}`;
    }
    if (type === 'notarization') {
      url = `/notarizations/folder/${folder.id}`;
    }
    if (type === 'audit') {
      url = `/audit/folder/${folder.id}`;
    }
    if (url)
      navigate(url, {
        state: {
          folder: folder.id,
          folders,
        },
      });
  };
  return !isLoading ? (
    <>
      <Typography size="body-medium-20">{t('collection.title_folders')}</Typography>
      <div className="flex flex-wrap gap-5">
        {folders.length > 0 ? (
          folders.map((folder, index) => (
            <FolderCard
              type={type}
              key={`folder-${folder.id}`}
              folder={folder}
              onClick={() => openFolder(folder)}
              hasWritePermission={hasWritePermission}
            />
          ))
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>{t('collection.no_folder_found')}</Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default CollectionFolderList;
