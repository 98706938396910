import { useTranslation } from 'react-i18next';
import { Icon, Tooltip, Typography } from '../../../../components';
import { IconCopy } from '../../../../icons';
import { copyToClipboard, formatDate, truncateText } from '../../../../imports/utils';
import { Contract } from '../../imports/types';

interface TrxTableProps {
  analytics: any[];
  contract: Contract;
}

const GamingAnalyticsTable = (props: TrxTableProps) => {
  const { analytics, contract } = props;
  const { t } = useTranslation(['tokenCreator']);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b shadow border-grey-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-grey-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.gaming.user')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.gaming.userAddress')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.gaming.score')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.gaming.nft')}
                    </Typography>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase"
                  >
                    <Typography weight="semibold" size="md">
                      {t('analytics.gaming.date')}
                    </Typography>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {analytics.map((log, index) => {
                  const userName = log.name;
                  const wallet = log.user
                    ? truncateText(log.user, { maxLength: 30, position: 'middle' }) ||
                      t('analytics.not_found')
                    : t('analytics.not_found');
                  const score = Math.ceil(log.score);
                  const date = log.endTimestamp;
                  const contractName = contract?.name;
                  const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-grey-200';

                  return (
                    <tr key={index} className={rowClass}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-2">
                          <Typography weight="light" size="sm">
                            {userName}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {wallet}
                          </Typography>
                          {log.user && (
                            <div className="flex align-center">
                              <Tooltip content={t('copy')}>
                                <>
                                  <Icon
                                    icon={IconCopy}
                                    onClick={() => handleCopyToClipboard(log.user)}
                                    className="w-4 h-4 cursor-pointer"
                                    color="primary-500"
                                    stroke="white"
                                    size="20"
                                  />
                                </>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {score}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {contractName}
                          </Typography>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Typography weight="light" size="sm">
                            {formatDate(date)}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamingAnalyticsTable;
