import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import { ExportedProps } from '../form/Form';
import './TextareaQuill.css';

interface ITextareaQuillProps
  extends Omit<
    ExportedProps,
    | 'trigger'
    | 'reset'
    | 'getValues'
    | 'isValid'
    | 'handleSubmit'
    | 'fields'
    | 'clearErrors'
    | 'errors'
    | 'resetField'
    | 'control'
    | 'register'
    | 'dirtyFields'
    | 'watch'
  > {
  value: string;
  field: string;
  height?: string; // dimension with pixel example: "300px"
}

const TextareaQuill: React.FC<ITextareaQuillProps> = ({
  setValue,
  value,
  field,
  height = '200px',
}) => {
  const [editorHtml, setEditorHtml] = useState<string>('');
  const modules = {
    toolbar: [
      ['bold', 'italic'], // Grassetto, corsivo
      [{ list: 'ordered' }, { list: 'bullet' }], // Liste
      ['clean'], // Reset la formattazione
    ],
  };

  const handleEditorChange = (html: string) => {
    setEditorHtml(html);
    setValue(field, html);
  };

  useEffect(() => {
    if (value) {
      // const specialChar = /[<>/]/.test(value);
      // const sanitizedText = specialChar ? DOMPurify.sanitize(value) : value;
      setEditorHtml(value);
    }
  }, []);

  return (
    <ReactQuill
      modules={modules}
      theme="snow"
      value={editorHtml}
      onChange={handleEditorChange}
      style={{ height }}
    />
  );
};

export default TextareaQuill;
