import { SVGProps } from 'react';

const IconArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M162 96H30"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84 42L30 96L84 150"
      // eslint-disable-next-line react/destructuring-assignment
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconArrow;
