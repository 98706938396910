import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import { EMPTY_NFT } from '../../imports/constants';
import { Template } from '../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

interface IUploadStep
  extends Omit<
    ExportedProps,
    | 'trigger'
    | 'register'
    | 'reset'
    | 'watch'
    | 'isValid'
    | 'dirtyFields'
    | 'clearErrors'
    | 'errors'
    | 'resetField'
    | 'fields'
  > {
  isLoading: boolean;
  contractsLeft: number;
  values: any;
  onBack: () => void;
  onCompleted: () => void;
  templates: Template[];
  setStep: (arg0: number) => void;
  setIsManual: (arg0: boolean) => void;
}

const UploadStep = ({
  contractsLeft,

  values,
  setIsManual,
  onBack,
  //values,
  setStep,
  templates,
  getValues,
  setValue,
  isLoading,
  handleSubmit,
  onCompleted,
}: IUploadStep) => {
  const { t } = useTranslation(['tokenCreator']);
  const template = useAppSelector((state) => state.template);
  return (
    <>
      <div className="size-full flex-col items-center justify-center space-y-[60px]">
        <div className="flex flex-col items-center justify-center space-y-[20px] pt-[30vh] ">
          <Button
            id="manual-upload"
            type="primary"
            action={handleSubmit(() => {
              const numberOfCategories = getValues('numberOfCategories');
              const nfts = getValues('nfts');

              if (nfts.length <= numberOfCategories) {
                setValue('nfts', [
                  ...nfts,
                  ...Array(Number(numberOfCategories - nfts.length)).fill(EMPTY_NFT),
                ]);
              } else {
                const newNfts = nfts.slice(0, numberOfCategories);
                setValue('nfts', newNfts);
              }
              // setStep(EStep.NFT);
              onCompleted();
              setIsManual(true);
            })}
            className="w-[280px]"
          >
            {t('create_contract.actions.manual')}
          </Button>

          <div className="flex items-center justify-center space-x-[8px]">{t('or')}</div>
          <div className="flex  w-[280px] flex-col items-center justify-center">
            <Button
              id="csv-upload"
              type="secondary"
              action={() => {
                // setStep(EStep.NFT);
                onCompleted();
                setIsManual(false);
              }}
              className="mb-[12px]"
            >
              {t('create_contract.actions.csv')}
            </Button>
          </div>
        </div>
        <div className="mt-20 flex w-full justify-between">
          <div className="w-[17rem]">
            <Button id="cc-step-1" action={onBack} disabled={isLoading || contractsLeft < 1}>
              {t('create_contract.form_labels.back') as string}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadStep;
