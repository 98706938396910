import { useTranslation } from 'react-i18next';
import { ImagePlaceholder } from '../../../../assets/images';
import { CustomizedText, Icon, Image, Typography } from '../../../../components';
import { IconWebsite } from '../../../../icons';
import { formatDate } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import DetailItem from './DetailItem';

interface IPreview {
  values: any;
}

const Preview = ({ values }: IPreview) => {
  const { t } = useTranslation(['tokenCreator']);
  const { name: userName } = useAppSelector((state) => state.user);
  const {
    doesExpire,
    doesTransfer,
    expiration,
    dropDate,
    contractImage,
    name,
    chain,
    description,
    maxTokensPerUser,
    contractExternalUrl,
  } = values;

  let img = ImagePlaceholder;
  if (contractImage) {
    if (Array.isArray(contractImage) && contractImage.length > 0) {
      img = contractImage[0].preview;
    }
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div className="border-grey3 flex h-[740px] w-[370px] flex-col gap-8 rounded-[20px] border-2 bg-grey-600 p-5 pt-[15%] text-white shadow-inner shadow-black">
        <Image src={img} type="cover" className="h-[211px] w-auto rounded-lg" />
        <div className="mt-4 flex items-center justify-between">
          <Typography size="body-semibold-20" weight="semibold">
            {name}
          </Typography>

          {contractExternalUrl && <Icon icon={IconWebsite} stroke="white" size="20" />}
        </div>
        <div className="flex gap-1">
          <Typography size="body-regular-14" weight="regular">
            {t('collection.created_by')}
          </Typography>
          <Typography size="body-regular-14" weight="medium" className="underline">
            {userName}
          </Typography>
        </div>

        <div className="mt-2 flex flex-wrap justify-between gap-y-2">
          {/* Created */}
          <DetailItem left={`${t('created')}`} right={formatDate(Date.now())} />

          {/* Elments */}
          <div className="min-w-[50%]">
            <DetailItem left={`${t('collection.elements')}`} right={maxTokensPerUser.toString()} />
          </div>
          {/* Chain */}
          <div className="min-w-[50%]">
            <DetailItem left={t('collection_item.label_fields.chain')} right={chain} />
          </div>
          {/* Transfer */}
          <div className="min-w-[50%]">
            <DetailItem
              left={t('collection_item.label_fields.does_transfer')}
              right={t(`boolean.${doesTransfer.toString()}`)}
            />
          </div>
          {/* Release */}
          {dropDate && (
            <div className="min-w-[50%]">
              <DetailItem
                left={t('collection_item.label_fields.release')}
                right={formatDate(new Date(dropDate).getTime()) || formatDate(Date.now())}
              />
            </div>
          )}
          {/* Expires */}
          {doesExpire ? (
            <div className="min-w-[50%]">
              <DetailItem
                left={t('collection_item.label_fields.nft_expiration')}
                right={
                  dropDate
                    ? `${formatDate(new Date(dropDate).getTime() + expiration * 86400000)}`
                    : `${formatDate(new Date().getTime() + expiration * 86400000)}`
                }
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <CustomizedText text={description} />
      </div>
    </div>
  );
};

export default Preview;
