import { PDFDownloadLink } from '@react-pdf/renderer';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FunctionComponent, SVGProps, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
/* root imports */
import { db } from '../../../../imports/firebase';
import { compare, formatDate, safeWhere } from '../../../../imports/utils';

import type { Filters, FiltersActionType } from '../../../../imports/types';

import { ImageFileAttach } from '../../../../assets/images';

import {
  Breadcrumbs,
  Button,
  CollectionHeader,
  Image,
  InnerPage,
  NotarizationCard,
  SidebarField,
  Tag,
  Typography,
} from '../../../../components';

/* module audit imports */
import { getUserAuthToken } from '../../../../api/firebase';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import {
  IconCalendar,
  IconFastBack,
  IconFastForward,
  IconInfo,
  IconLoading,
} from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';
import { ReportNotarization } from '../../../notarizations/components';
import { Notarization, Receipt } from '../../../notarizations/imports/types';
import { getFileFromStorage } from '../../api';
import { AuditList } from '../../component';
import { Audit } from '../../imports/types';

const filtersInitialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByDate: { filter: 'NOTARIZATION_DATE_MATCH', value: '' },
};

const filtersReducer = (state: typeof filtersInitialState, action: FiltersActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

type ViewAuditProps = {
  audit: Audit;
  notarizations: Array<Notarization & Receipt>;
};
const ViewAudit = ({ audit, notarizations }: ViewAuditProps) => {
  const { t } = useTranslation(['audit']);

  const [auditNotarizations, setAuditNotarizations] = useState<Array<Notarization & Receipt>>([]);
  const [auditWithReceipt, setAuditWithReceipt] = useState<Audit>(audit);

  const isReportDownloadable = () => {
    if (auditWithReceipt.tx && auditWithReceipt.merkleTreeURI) {
      return true;
    }

    return false;
  };

  const handleGetFileFromStorage = async () => {
    const { value: authTokenId } = await getUserAuthToken();

    if (auditWithReceipt.externalUrl) {
      await getFileFromStorage(auditWithReceipt.externalUrl, authTokenId)
        .then((res) => {
          window.open(res.value.url, '_blank');
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    let auditFormatted: Audit = audit;

    if (audit.notarizationRef) {
      const notarizationRef = notarizations.find(
        (notarization) => notarization.id === audit.notarizationRef?.id
      );

      auditFormatted = {
        ...audit,
        tx: notarizationRef?.tx,
        merkleTreeURI: notarizationRef?.merkleTreeURI,
      };
    }

    setAuditWithReceipt(auditFormatted);

    const verifiedNotarizationsRef = audit.verifiedNotarizations.map(
      (notarization: any) => notarization.id
    );

    const verifiedNotarizations: any = verifiedNotarizationsRef.map((notarizationRef: any) => {
      const notarizationData = notarizations.find(
        (notarization) => notarization.id === notarizationRef
      );

      if (notarizationData) {
        return notarizationData;
      }

      return null;
    });

    if (verifiedNotarizations.length > 0 && verifiedNotarizations[0] !== null) {
      const filteredNotarizations = verifiedNotarizations.sort((a: any, b: any) =>
        compare(a, b, 'name', 'asc')
      );

      setAuditNotarizations(filteredNotarizations);
    }
  }, []);

  return (
    <div className="flex w-full flex-col items-center space-y-4">
      <Image src={ImageFileAttach} alt={t('image.audit')} className="w-[50px]" />

      <section className="w-full space-y-4 pb-5">
        {auditWithReceipt.name && (
          <SidebarField label={t('view_audit.labels.name')}>
            <Typography>{auditWithReceipt.name}</Typography>
          </SidebarField>
        )}

        {auditWithReceipt.description && (
          <SidebarField label={t('view_audit.labels.description')}>
            <Typography>{auditWithReceipt.description}</Typography>
          </SidebarField>
        )}

        {auditWithReceipt.date && (
          <SidebarField label={t('view_audit.labels.date')}>
            <Typography>{formatDate(auditWithReceipt.date)}</Typography>
          </SidebarField>
        )}

        {auditWithReceipt.tags && auditWithReceipt.tags.length > 0 && (
          <SidebarField label={t('view_audit.labels.tags')}>
            <div className="flex items-center space-x-2">
              {auditWithReceipt.tags.map((tag) => (
                <Tag key={tag} title={tag} />
              ))}
            </div>
          </SidebarField>
        )}

        {auditNotarizations && auditNotarizations.length > 0 && (
          <SidebarField label={t('view_audit.labels.notarizations')}>
            <div className="flex max-h-[16.25rem] w-full flex-col items-center space-y-4 overflow-y-auto py-2">
              {auditNotarizations.map((notarization, index) => (
                <NotarizationCard item={notarization} />
              ))}
            </div>
          </SidebarField>
        )}

        <div className="!my-8 w-full border-b border-primary-400" />

        <div className="w-full space-y-4">
          {auditWithReceipt.externalUrl && (
            <Button action={handleGetFileFromStorage} type="primary">
              {t('view_audit.buttons.download_file')}
            </Button>
          )}

          {isReportDownloadable() && (
            <div>
              <PDFDownloadLink
                className="w-full"
                fileName={auditWithReceipt.name}
                document={
                  <ReportNotarization notarizations={auditNotarizations} audit={auditWithReceipt} />
                }
              >
                <Button type="secondary" action={() => {}}>
                  {t('view_audit.buttons.download_report_notarizations')}
                </Button>
              </PDFDownloadLink>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

const Collection = () => {
  const { t } = useTranslation(['audit']);
  const navigate = useNavigate();
  const { uid, canNotarize, workspace } = useAppSelector(({ user, subscription, team }) => ({
    ...user,
    workspace: team.workspace,
    canNotarize: subscription.canNotarize,
  }));

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();

  const targetOwnerUid = workspace?.owner ?? uid;

  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState<Array<Audit>>([]);
  const [notarizations, setNotarizations] = useState<Array<Notarization & Receipt>>([]);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);

  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      icon: IconInfo,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
      icon: IconCalendar,
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
      icon: IconCalendar,
    },
    {
      value: 'name-asc',
      label: 'A-Z',
      icon: IconFastForward,
    },
    {
      value: 'name-desc',
      label: 'Z-A',
      icon: IconFastBack,
    },
  ];
  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  const getDraftAudits = () =>
    // filteredAudits.filter((audit) => audit?.status === 'pending' || audit?.status === 'notarizing');
    audits.filter((audit) => audit?.status === 'pending' || audit?.status === 'notarizing');

  const getNotarizedAudits = () =>
    // filteredAudits.filter((audit) => audit?.status === 'completed');
    audits.filter((audit) => audit?.status === 'completed');
  const getNotarizationsWithReceipts = () => {
    const notarizationsFormatted: (Notarization & Receipt)[] = [];
    notarizations.forEach((notarization) => {
      const notarizationReceipt = receipts.find(
        (receipt) => notarization.receiptId === receipt.receiptId
      );
      if (notarizationReceipt) {
        notarizationsFormatted.push({ ...notarization, ...notarizationReceipt });
      }
    });

    return notarizationsFormatted;
  };
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    const auditsQuery = query(
      collection(db, 'audits'),
      ...safeWhere(uid, workspace?.id, isSuperGroup === true ? '' : activeGroupId)
    );
    const receiptsQuery = query(collection(db, 'receipts'));
    const notarizationsQuery = query(
      collection(db, 'notarizations'),
      where('userId', '==', targetOwnerUid)
    );

    const unsubscribeAudits = onSnapshot(auditsQuery, (querySnapshot) => {
      const audits: Array<Audit & { auditId: string }> = [];

      querySnapshot.forEach((doc) => {
        const audit = doc.data() as Audit;

        audits.push({ auditId: doc.id, ...audit });
      });

      setAudits(audits.sort((a, b) => compare(a, b, 'date', 'desc')));
      setIsLoading(false);
    });

    const unsubscribeReceipts = onSnapshot(receiptsQuery, async (querySnapshot) => {
      const receipts: any[] = [];
      querySnapshot.forEach((doc) => {
        receipts.push(doc.data());
      });

      setReceipts(receipts);
    });

    const unsubscribeNotarizations = onSnapshot(notarizationsQuery, async (querySnapshot) => {
      const notarizations: any[] = [];

      querySnapshot.forEach((doc) => {
        notarizations.push({ ...doc.data(), id: doc.id });
      });

      setNotarizations(notarizations.sort((a, b) => compare(a, b, 'date', 'desc')));
      setIsLoading(false);
    });

    return () => {
      unsubscribeAudits();
      unsubscribeReceipts();
      unsubscribeNotarizations();
    };
  }, [workspace?.id, activeGroupId]);

  return (
    <>
      <InnerPage>
        {isLoading ? (
          <div className="flex h-screen grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <>
            <CollectionHeader
              orderByOptions={orderByOptions}
              filtersState={filtersState}
              filtersDispatch={filtersDispatch}
              handleSetOrderBy={handleSetOrderBy}
              resetFilterField={resetFilterField}
            />
            <div className="mt-12 flex h-[67px] w-full flex-row  justify-between ">
              <div className="flex h-full w-2/3 flex-col p-2">
                <Breadcrumbs />
                <Typography size="body-medium-30">{t('collection.title_myAudit')}</Typography>
              </div>

              {workspace?.permissions?.audit?.write && (
                <div className="flex h-full w-fit flex-row gap-2 p-2">
                  <Button
                    type="primary"
                    id="createAudit"
                    action={() => navigate('/audit-db/create-audit')}
                    //action={handleNewAudit}
                    className="px-4"
                    disabled={!canNotarize}
                  >
                    {t('collection.create_audit')}
                  </Button>
                </div>
              )}
            </div>
            <AuditList
              // title={t('collection.audit_in_progress')}
              type="pending"
              audits={getDraftAudits()}
              isLoading={isLoading}
              filtersState={filtersState}
              orderBy={orderBy}
              notarizations={notarizations}
              receipts={receipts}
            />
            <AuditList
              type="completed"
              // title={t('collection.audit_completed')}
              audits={getNotarizedAudits()}
              isLoading={isLoading}
              notarizations={notarizations}
              receipts={receipts}
              filtersState={filtersState}
              orderBy={orderBy}
              notarized
            />
          </>
        )}
      </InnerPage>
    </>
  );
};

export default Collection;
