import { utils } from 'ethers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import type { ChangeEvent } from 'react';

import Field from '../field/Field';
import Textarea from '../textarea/Textarea';

import type { InputType } from '../../imports/types';

type InputMnemonicPhraseProps = InputType & {
  onSuccess?: (mnemonicPhrase: string) => void;
};

const InputMnemonicPhrase = ({ placeholder, onSuccess }: InputMnemonicPhraseProps) => {
  const { t } = useTranslation();

  const [error, setError] = useState('');

  const handleChange = async (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
  ) => {
    const errorMessage = t('form_validation.seed_phrase_not_valid');

    if (
      await yup
        .string()
        .test('is-seed-phrase-valid', errorMessage, () => utils.isValidMnemonic(event.target.value))
        .isValid(event.target.value)
    ) {
      setError('');
      onSuccess?.(event.target.value);
      return;
    }

    setError(errorMessage);
  };

  return (
    <Field error={error}>
      <div className="flex w-full justify-center">
        <Textarea
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          error={error}
          rows={8}
          cols={36}
        />
      </div>
    </Field>
  );
};

export default InputMnemonicPhrase;
