import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import { Input, Tag } from '..';
import { getUserAuthToken } from '../../api/firebase';
import { useLoadingStatusContext } from '../../context';
import { IconCheck, IconClose, IconCopy, IconEdit, IconTrash } from '../../icons';
import { paidIpfsGateway } from '../../imports/constants';
import { Folder } from '../../imports/types';
import { copyToClipboard, formatDate, getExplorerTx, truncateText } from '../../imports/utils';
import {
  deleteNotarization,
  getFileFromStorage,
  updateNotarization,
} from '../../modules/notarizations/api';
import ReportNotarization from '../../modules/notarizations/components/reportNotarization/ReportNotarization';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};

type NotesFieldProps = {
  notes: string;
  notarizationId: string;
  hasWritePermission: boolean;
};

const NotesField = ({ notes, notarizationId, hasWritePermission }: NotesFieldProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [notesValue, setNotesValue] = useState(notes);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { t } = useTranslation(['notarization']);

  const updateNotes = async () => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('edit_field.actions.pending'),
      },
    });
    const { error: updateError, value: updateSuccess } = await updateNotarization(notarizationId, {
      notes: notesValue,
    });
    if (updateError) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('edit_field.actions.failed'),
        },
      });
    }
    if (updateSuccess) {
      setIsEditMode(!isEditMode);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('edit_field.actions.success'),
        },
      });
    }
  };

  return (
    <div className="flex items-center justify-center">
      {isEditMode ? (
        <>
          <Input
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
            elementsRight={[
              <IconClose
                key="close"
                className="cursor-pointer"
                onClick={() => {
                  setIsEditMode(!isEditMode);
                  setNotesValue(notes);
                }}
                width={20}
                stroke="red"
              />,
              <IconCheck
                key="copy"
                className="cursor-pointer"
                onClick={() => updateNotes()}
                width={20}
                stroke="green"
              />,
            ]}
          />
        </>
      ) : (
        <>
          {truncateText(notesValue, {
            maxLength: 40,
            rightChars: 0,
          })}
          <div className="w-min">
            <Button icon={IconCopy} type="ghost" action={() => handleCopyToClipboard(notesValue)} />
          </div>
          {hasWritePermission && (
            <div className="w-min">
              <Button icon={IconEdit} type="ghost" action={() => setIsEditMode(!isEditMode)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="m-2 flex h-auto flex-col gap-2">
      <Typography>{label.toUpperCase()}</Typography>
      <div className="flex max-h-40 flex-row justify-between overflow-auto text-justify">
        {children}
      </div>
    </div>
  );
};

type NotarizationDetailsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { notarization, folders, hasWritePermission } = useModalProps('NotarizationDetailsModal');
  const [storageLink, setStorageLink] = useState<string | undefined>(undefined);
  const [notarizationFolder, setFolder] = useState<Folder | undefined>(undefined);
  const [showHelper, setShowHelper] = useState<boolean>(false);
  const { t } = useTranslation(['notarization']);

  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  const handleGetNotarizationExternalUrl = async () => {
    let url;
    if (notarization.externalUrl) {
      const { value: authTokenId } = await getUserAuthToken();

      const res = await getFileFromStorage(notarization.externalUrl, authTokenId);

      url = res.value.url;
      setStorageLink(url);
    } else if (notarization.ipfsUri) {
      url = `${paidIpfsGateway}/${notarization.ipfsUri}`;
      setStorageLink(url);
    }
    return url;
  };

  const handleDownloadFile = async () => {
    const url =
      notarization.storage === 'ipfs'
        ? `${paidIpfsGateway}/${notarization.ipfsUri}`
        : await handleGetNotarizationExternalUrl();

    if (url) {
      if (notarization.ipfsUri) {
        window.open(url, '_blank');
        return;
      }

      const link = document.createElement('a');
      link.download = notarization.name;
      link.href = url;
      link.setAttribute('download', notarization.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const handleDownloadFileFromIpfs = async () => {
    const ipfsUrl = await handleGetNotarizationExternalUrl();
    if (ipfsUrl) {
      try {
        const response = await fetch(ipfsUrl);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = notarization.name;
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (notarization.folderId && folders) {
      setFolder(folders.find((folder: Folder) => folder.id === notarization.folderId));
    }
  }, [notarization, folders]);

  const handleDeleteS3StorageFile = async () => {
    const { value: authTokenId } = await getUserAuthToken();
    const id = notarization.id;
    const { value: deleteNotarizationValue, error: deleteNotarizationError } =
      await deleteNotarization({ id, onlyContent: true }, authTokenId);

    if (deleteNotarizationError) {
      toast.error(deleteNotarizationError);
      return;
    }
    if (deleteNotarizationValue) {
      toast.success(t('delete_notarization.file_actions.success') as string);
      setShowHelper(false);
      setOpen(false);
    }
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full max-w-xl flex-col ">
        <div className="grid h-full grid-cols-2 content-center p-4">
          <div className="col-span-2 mx-2 py-2">
            <Typography size="body-semibold-30" color="black">
              {notarizationFolder
                ? `${notarizationFolder.name} / ${notarization.name}`
                : notarization.name}
            </Typography>
          </div>
          {notarization.description && (
            <InfoSection label={t('view_notarization.description') as string}>
              <div>{notarization.description}</div>
            </InfoSection>
          )}

          <InfoSection label={t('view_notarization.date') as string}>
            {formatDate(notarization.date)}
          </InfoSection>
          {notarization.tags && notarization.tags.length > 0 && (
            <InfoSection label={t('view_notarization.tags') as string}>
              <div className="grid grid-cols-3 gap-3 ">
                {notarization.tags.map((tag: string) => {
                  return <Tag key={tag} title={tag} />;
                })}
              </div>
            </InfoSection>
          )}

          {notarization.notes && (
            <InfoSection label={t('view_notarization.notes') as string}>
              <NotesField
                notes={notarization.notes}
                notarizationId={notarization.id}
                hasWritePermission={hasWritePermission}
              />
            </InfoSection>
          )}
          {notarization.hash && (
            <InfoSection label={t('view_notarization.hash') as string}>
              {/* {t('view_notarization.hash')} */}
              {truncateText(notarization.hash, { maxLength: 25 })}
              <div className="w-min">
                <Button
                  icon={IconCopy}
                  type="ghost"
                  action={() => handleCopyToClipboard(notarization.hash)}
                />
              </div>
            </InfoSection>
          )}

          {notarization.storageType && (notarization.externalUrl || notarization.ipfsUri) && (
            <InfoSection label={t('view_notarization.storage') as string}>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-1">
                  <Typography>{notarization.storageType}</Typography>
                  <div className="flex w-min flex-row gap-1">
                    <Button
                      icon={IconCopy}
                      type="ghost"
                      action={async () =>
                        handleCopyToClipboard(
                          storageLink ?? (await handleGetNotarizationExternalUrl())
                        )
                      }
                    />
                    {notarization.storageType === 'bcode' &&
                      notarization.externalUrl &&
                      notarization.status === 'success' && (
                        <Button
                          icon={IconTrash}
                          type="ghost"
                          action={() => {
                            setShowHelper(!showHelper);
                          }}
                        />
                      )}
                  </div>
                </div>
                {showHelper && (
                  <>
                    <Typography size="body-medium-12" color="error">
                      {t('delete_notarization.disclaimer_file')}
                    </Typography>
                    <div className="flex flex-row justify-center gap-2">
                      <Button
                        type="secondary"
                        action={() => {
                          setShowHelper(false);
                        }}
                        height="30"
                        textSize="md"
                      >
                        No
                      </Button>
                      <Button
                        type="ghost"
                        action={() => {
                          handleDeleteS3StorageFile();
                        }}
                        height="30"
                        textSize="md"
                      >
                        Yes
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </InfoSection>
          )}

          {notarization.txHash && (
            <InfoSection label={t('view_notarization.blockchain_explorer_api') as string}>
              <Typography>
                <a
                  href={getExplorerTx(notarization.txHash || '').toString()}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('view_notarization.go_to_explorer')}
                </a>
              </Typography>
            </InfoSection>
          )}
        </div>

        <div className="flex flex-row justify-end gap-4 pr-[20px]">
          {(notarization.externalUrl || notarization.ipfsUri) && (
            <Button
              type="secondary"
              id="createFolder"
              action={handleDownloadFile}
              className="space-y-4"
            >
              {t('view_notarization.download_file')}
            </Button>
          )}
          {notarization.status === 'success' && (
            <PDFDownloadLink
              fileName={notarization.name}
              document={<ReportNotarization notarizations={notarization} />}
            >
              <Button type="primary" id="createNotarization" action={() => ''}>
                {t('view_notarization.download_report')}
              </Button>
            </PDFDownloadLink>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

const NotarizationDetailsModal = ({ children }: NotarizationDetailsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="NotarizationDetailsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default NotarizationDetailsModal;
