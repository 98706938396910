import { useTranslation } from 'react-i18next';
import { BaseSyntheticEvent, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Icon, Input } from '../../../../components';
import { IconClose, IconResearch } from '../../../../icons';
import GamingAnalyticsTable from '../gaminAnalyticsTable/GamingAnalyticsTable';
import { Contract } from '../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';
import { getGamingLeaderboards } from '../../../../imports/analyticsParser';
import PageSlider from '../pageSlider/PageSlider';
import useGamingTableFilters from '../../../../hooks/useGamingTableFilters';

const DetailsGamingTable = () => {
  const { t } = useTranslation(['tokenCreator']);
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const { id: contractId } = useParams();
  const contract = contractsList.find((contract: Contract) => contract.id === contractId)

  const {
    gaming,
    gamingNameFilter,
    gamingDateFilter,
    gamingPage,
    filterMin,
    filterMax,
    setGamingNameFilter,
    setGamingDateFilter,
    setGamingPage,
    setFilterMin,
    setFilterMax,
  } = useGamingTableFilters({ contractId: contractId!, contractsList });

  const maxElementPerPage = 20;

  const gamingBatches = gaming?.reduce((resultArray: any, item: any, index: number) => {
    const chunkIndex = Math.floor(index / maxElementPerPage);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  const numberOfGamingPages = gamingBatches ? gamingBatches.length : 0;

  return (
    <div className="flex flex-col mt-4" id="transaction">
      <div className="mt-[32px] flex w-full items-center gap-4">
        <Input
          type="text"
          placeholder={t('collection.filters.name')}
          onChange={(event) => {
            setGamingPage(0);
            setGamingNameFilter(event.target.value);
          }}
          elementLeft={<Icon icon={IconResearch} />}
          elementRight={
            <Button type="ghost" icon={IconClose} action={() => setGamingNameFilter('')} />
          }
          value={gamingNameFilter}
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
        />

        <Input
          type="date"
          placeholder={t('collection.filters.date')}
          onChange={(event: BaseSyntheticEvent) => {
            setGamingPage(0);
            setGamingDateFilter(event.target.valueAsNumber);
          }}
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-400"
        />

        <Input
          type="number"
          placeholder={t('collection.filters.min')}
          onChange={(event) => {
            setGamingPage(0);
            setFilterMin(parseFloat(event.target.value));
          }}
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-400"
        />
        <Input
          type="number"
          placeholder={t('collection.filters.max')}
          onChange={(event) => {
            setGamingPage(0);
            setFilterMax(parseFloat(event.target.value));
          }}
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-400"
        />
      </div>
      {gamingBatches && gamingBatches?.length > 0 && contractsList && (
        <div className="flex flex-col mt-4" id="transaction">
          <div className={`mt-[32px] flex w-full flex-col space-y-[20px] `}>
            <GamingAnalyticsTable analytics={gamingBatches[gamingPage]} contract={contract!} />
          </div>
          {gamingBatches && numberOfGamingPages > 1 && (
            <div className="mt-[32px] flex w-full flex-row place-content-center">
              <PageSlider
                numberOfPages={numberOfGamingPages}
                page={gamingPage}
                setPage={setGamingPage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsGamingTable;



