/* eslint-disable react/no-unknown-property */
/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconInvisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38793)">
      <path
        d="M36 30L156 162"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.176 118.2C110.67 123.244 103.467 126.028 96.0007 126C89.9457 125.999 84.0326 124.167 79.0387 120.742C74.0449 117.318 70.2041 112.463 68.0212 106.815C65.8383 101.167 65.4156 94.9911 66.8086 89.0985C68.2015 83.2059 71.345 77.8727 75.8257 73.8"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.5 51.45C24.9 66.9 12 96 12 96C12 96 36 150 96 150C110.059 150.115 123.943 146.875 136.5 140.55"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.452 126.825C172.802 112.2 180.002 95.9999 180.002 95.9999C180.002 95.9999 156.002 41.9999 96.0016 41.9999C90.8 41.9897 85.6068 42.4162 80.4766 43.2749"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.625 66.5249C108.005 67.7337 113.818 70.9849 118.188 75.7878C122.558 80.5906 125.247 86.6846 125.85 93.1499"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38793">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconInvisible;
