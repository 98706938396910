import { ReactNode } from 'react';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import 'reactflow/dist/style.css';
import { PollsFlows } from '../../modules/tokenCreator/components';
import ModalLayout from '../layouts/ModalLayout';

type QuestionsFlowsProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { questions } = useModalProps('QuestionsFlowsModal');
  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="h-screen w-[700px]">
        <PollsFlows questions={questions} />
      </div>
    </ModalLayout>
  );
};

const QuestionsFlowsModal = ({ children }: QuestionsFlowsProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="QuestionsFlowsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default QuestionsFlowsModal;
