import { utils } from 'ethers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { isFileTypeImage } from '../imports/utils';

const validareUrl = (errorMessage: string) =>
  yup
    .string()
    .matches(
      /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
      errorMessage
    );

const validateEmail = (errorMessage: string) => yup.string().email(errorMessage);

const validateRepeatEmail = (errorMessage: string, ref: string = 'email') =>
  yup.string().oneOf([yup.ref(ref), null], errorMessage);

const validatePassword = (
  errorMessageMinLength: string,
  errorMessageMaxLength: string,
  errorMessageAtLeastOneNumber: string,
  errorMessageAtLeastOneLowercaseChar: string,
  errorMessageAtLeastOneUppercaseChar: string
) =>
  yup
    .string()
    .min(6, errorMessageMinLength)
    .max(16, errorMessageMaxLength)
    .matches(/\d/g, errorMessageAtLeastOneNumber)
    .matches(/[a-z]/g, errorMessageAtLeastOneLowercaseChar)
    .matches(/[A-Z]/g, errorMessageAtLeastOneUppercaseChar);

const validateRepeatPassword = (errorMessage: string, ref: string = 'password') =>
  yup.string().oneOf([yup.ref(ref), null], errorMessage);

const validateFileImage = (errorMessage: string) =>
  yup.mixed().test('is-file', errorMessage, (value: File) => isFileTypeImage(value));

const validateSeedPhrase = (errorMessage: string) =>
  yup
    .string()
    .test('is-seed-phrase-valid', errorMessage, (value?: string) => utils.isValidMnemonic(value!));

const validateFilePresence = (errorMessage: string, isNotRequired: boolean) =>
  yup.mixed().test('file', errorMessage, (value) => {
    if (value) {
      return true;
    }
    if (isNotRequired) {
      return true;
    }
    return false;
  });

const disallowSpecialCharacters = (errorMessage: string) =>
  yup.string().test('does-string-contains-special-characters', errorMessage, (value?: string) => {
    const specialCharactersRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
    if (value) {
      return !specialCharactersRegex.test(value);
    }
    return true;
  });

const disallowSpecialCharacterAndNumbersAtTheBeginning = (errorMessage: string) =>
  yup
    .string()
    .test(
      'does-string-contains-special-characters-and-begin-with-numbers',
      errorMessage,
      (value?: string) => {
        const beginWithNumberRegex = /(^[a-zA-Z])/g;
        const lettersWithAccentRegex = /[\u00C0-\u00FF]/g;
        const specialCharactersRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
        if (value) {
          return (
            beginWithNumberRegex.test(value) &&
            !lettersWithAccentRegex.test(value) &&
            !specialCharactersRegex.test(value)
          );
        }
        return true;
      }
    );

const required = (validation: yup.AnySchema, errorMessage: string) =>
  validation.required(errorMessage);

const useValidation = () => {
  const { t } = useTranslation();

  return {
    validateEmail: () => validateEmail(t('form_validation.email_not_valid')),

    validateRepeatEmail: (ref?: string) =>
      validateRepeatEmail(t('form_validation.emails_dont_match'), ref),

    validatePassword: () =>
      validatePassword(
        t('form_validation.min_length', { minChars: 8 }),
        t('form_validation.max_length', { maxChars: 16 }),
        t('form_validation.one_number'),
        t('form_validation.one_letter_lowercase'),
        t('form_validation.one_letter_uppercase')
      ),

    validateStartDate: () =>
      yup.mixed().test('startDate', t('form_validation.cant_start_before_today'), (value) => {
        if (value && Date.now() <= new Date(value).getTime()) {
          return true;
        }
        return false;
      }),

    validateEndDate: () =>
      yup.mixed().test('endDate', t('form_validation.expire_to_early'), (value) => {
        if (value && new Date(value).getTime() > Date.now()) {
          return true;
        }
        return false;
      }),
    // { [key: string]: number[] }
    validateContractsArray: () =>
      yup.array().test('contractArray', t('form_validation.select_at_least_one'), (value) => {
        if (value && value?.length > 0) {
          return true;
        }
        return false;
      }),

    validateVat: () =>
      yup.string().test(
        'check_length',
        t('authentication.wrong_format'),
        (value) =>
          /^ATU\d{8}$/.test(value || '') || //austria
          /^BE\d{9,10}$/.test(value || '') || //belgio
          /^BG\d{9,10}$/.test(value || '') || // bulgaria
          /^HR\d{11}$/.test(value || '') || //croazia
          /^CY\d{9}[A-Z]$/.test(value || '') || //cipro
          /^CZ\d{8,10}$/.test(value || '') || //repubblica ceca
          /^DK\d{8}[A-Z]$/.test(value || '') || //danimarca
          /^EE\d{9}$/.test(value || '') || //estonia
          /^FI\d{8}$/.test(value || '') || //finlandia
          /^FR\d{2}[0-9A-Z]{9,11}$/.test(value || '') || //francia
          /^DE\d{9}$/.test(value || '') || //germania
          /^EL\d{9}$/.test(value || '') || //grecia
          /^HU\d{8}$/.test(value || '') || //ungheria
          /^IE\d{8}[A-Z0-9]|IE\d{7}[A-Z0-9W]$/.test(value || '') || //irlanda
          /^LV\d{11}$/.test(value || '') || //lettonia
          /^LT\d{9}$|^LT\d{12}$/.test(value || '') || //lituania
          /^LU\d{8}$/.test(value || '') || //lussemburgo
          /^MT\d{8}$/.test(value || '') || //malta
          /^NL[A-Z0-9]{9}B[A-Z]{2}$/.test(value || '') || //olanda
          /^PL\d{10}$/.test(value || '') || //polonia
          /^PT\d{9}$/.test(value || '') || //portogallo
          /^RO\d{2,10}$/.test(value || '') || //romania
          /^SK\d{10}$/.test(value || '') || //slovacchia
          /^SI\d{8}$/.test(value || '') || //slovenia
          /^ES\d{8}[A-Z]$/.test(value || '') || //spagna
          /^SE\d{12}$/.test(value || '') || //svezia
          /^SM\d{3}$|^SM\d{5}$/.test(value || '') || //san marino
          /^XI\d{9}$|XI\d{12}$|XIGD\d{3}|XIHA\d{3}/.test(value || '') || //irlanda del nord
          /^CHE\d{9}$/.test(value || '') ||
          /^IT\d{11}$/.test(value || '') ||
          /^GB\d{9}$/.test(value || '') ||
          /^\d{3}-\d{2}-\d{4}$/.test(value || '') || // stati uniti
          /^\d{2}-\d{7}$/.test(value || '') ||
          /^\d{8}[A-Z]$/.test(value || '') ||
          /^[A-Z]\d{8}$/.test(value || '') ||
          /^[A-Z]\d{7}[A-Z]$/.test(value || '')
      ),

    validateSDI: () =>
      yup
        .string()
        .test('check_sdi', t('authentication.wrong_format'), (value) => value?.length === 7),
    validateRepeatPassword: (ref?: string) =>
      validateRepeatPassword(t('form_validation.dont_match'), ref),

    validateFileImage: () => validateFileImage(t('form_validation.file_not_image')),

    validateSeedPhrase: () => validateSeedPhrase(t('form_validation.seed_phrase_not_valid')),

    validateUrl: () => validareUrl(t('form_validation.invalid_url')),

    validateFilePresence: (isNotRequired?: boolean) =>
      validateFilePresence(t('form_validation.file_not_present'), isNotRequired || false),

    disallowSpecialCharacters: () =>
      disallowSpecialCharacters(t('form_validation.string_contains_special_characters')),

    disallowSpecialCharacterAndNumbersAtTheBeginning: () =>
      disallowSpecialCharacterAndNumbersAtTheBeginning(
        t('form_validation.string_contains_special_characters_and_begin_with_numbers')
      ),
    required: (validation: yup.AnySchema) => required(validation, t('form_validation.required')),
  };
};

export default useValidation;
