import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, FAQComponent, Icon, Input, Typography } from '../../components';
import i18n from '../../i18n';
import { IconArrow, IconClose, IconResearch } from '../../icons';
import { getRemoteConfigValue } from '../../imports/remoteConfig';
import { FAQ, FAQQuestion } from '../../imports/types';

const FAQPage = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const faqJson: FAQ = getRemoteConfigValue('FAQ', 'json')[i18n.language];
  const faq = [...faqJson.common, ...faqJson.tokenCreator];
  const navigate = useNavigate();

  const filteredFaq = faq.filter((faqQuestion: FAQQuestion) => {
    return faqQuestion.question
      ? faqQuestion?.question.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      : false;
  });

  return (
    <div className="flex min-h-screen flex-col justify-between p-10">
      <div>
        <div className="flex">
          <Icon
            icon={IconArrow}
            onClick={() => navigate(-1)}
            size="40"
            stroke="primary-500"
            className="my-3 cursor-pointer rounded-md"
          />
          <div className="mx-auto w-[460px]">
            <Typography className="my-4 text-center" size="body-medium-30">
              FAQ
            </Typography>
            <Input
              type="text"
              value={filter}
              placeholder={t('search')}
              onChange={(event) => setFilter(event.target.value)}
              elementLeft={<Icon icon={IconResearch} stroke="grey-500" />}
              elementRight={
                filter && (
                  <Button
                    type="ghost"
                    icon={IconClose}
                    action={() => setFilter('')}
                    className="max-h-8 hover:bg-white"
                  />
                )
              }
              className="!border-grey4 text-grey2 h-[36px] rounded-[4px] !text-body-regular-12"
            />
          </div>
          <div className="w-[40px]"></div>
        </div>
        <div className="mt-7 flex flex-col gap-2">
          {filteredFaq && filteredFaq.length > 0 ? (
            filteredFaq.map((faqQuestion: FAQQuestion, index) => {
              return (
                <FAQComponent
                  key={index}
                  question={faqQuestion.question}
                  answer={faqQuestion.answer}
                />
              );
            })
          ) : (
            <FAQComponent defaultText="Nessun risultato"></FAQComponent>
          )}
        </div>
      </div>
      <Typography className="mt-7 text-center">
        {t('support')}
        <a className="text-body-medium-16" href="mailto:supporto@bcode.cloud">
          supporto@bcode.cloud
        </a>
      </Typography>
    </div>
  );
};
export default FAQPage;
