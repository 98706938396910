import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainLogo } from '../../assets/images';
import { IconCheck } from '../../icons';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import Image from '../image/Image';
import Typography from '../typography/Typography';
// import { MouseEvent } from 'react';

interface EmailScreenProps {
  title?: string;
  subtitle?: string;
}

const EmailScreen = ({ title, subtitle }: EmailScreenProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex h-[100vh] w-full flex-col items-center justify-center align-middle">
      <Image src={MainLogo} alt={t('bcode_logo')} className="w-[217px]" />
      <div className="relative flex h-[465px] items-center justify-center rounded-[10px] border border-primary-500 bg-white shadow-[0_7px_10px_rgba(0,0,0,0.25)] sm:w-screen  md:max-w-[640px]">
        {/* <Icon
          icon={IconClose}
          className="absolute top-4 right-4"
          role="button"
          size="29"
          stroke="primary-500"
          onClick={() => navigate(-1)}
        /> */}
        <div className="flex flex-col items-center gap-20">
          <Icon icon={IconCheck} size="54" stroke="success" />
          <div className="flex flex-col text-center text-grey-600">
            <Typography size="body-regular-20">{title}</Typography>
            <Typography size="body-regular-20">{subtitle}</Typography>
          </div>

          <Button action={() => navigate(-1)}>{t('authentication.go_back')}</Button>
        </div>
      </div>
    </div>
  );
};
// const EmailScreen = ({ title, subtitle }: EmailScreenProps) => {
//   const { t } = useTranslation();
//   return (
//     <div className="absolute flex flex-col items-center justify-center w-full h-full p-8 space-y-32 bg-primary-400">
//       <header className="h-32">
//         <Image src={LogoBCode} alt={t('bcode_logo')} className="h-full" />
//       </header>

//       <div className="flex flex-col items-center justify-center w-full space-y-12">
//         <Image src={ImageEmail} alt={t('email')} className="h-24" />

//         <div className="flex flex-col items-center">
//           <Typography color="white" size="2xl" as="h1">
//             {title}
//           </Typography>
//           {subtitle && (
//             <Typography color="white" size="2xl" as="h2">
//               {subtitle}
//             </Typography>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

export default EmailScreen;
