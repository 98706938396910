import { useState } from 'react';
import { IconTag } from '../../icons';
import Checkbox from '../checkbox/Checkbox';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

interface FilterDropdownProps {
  items: string[] | undefined;
  set: (selection: string) => void;
  mainClassName?: string;
  itemsClassName?: string;

  width?: string;
  height?: string;

  placeholder?: string;
  id?: string;
}
const FilterDropdown = ({
  items = [''],
  set,
  mainClassName,
  itemsClassName,
  width = '450px',
  height = '175px',
  placeholder,
  id,
}: FilterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`relative flex w-[270px] flex-col space-y-[5px] `}
      style={{ width: ` ${width}` }}
      id={id}
    >
      <div
        className={`relative flex min-h-[36px] cursor-pointer items-center gap-[11px] rounded-[4px] border border-grey-300 py-[4px] pl-[10px] text-body-regular-12 text-grey-500 hover:opacity-70 active:opacity-100 ${mainClassName}`}
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: ` ${width}` }}
      >
        <div className="flex items-center justify-center gap-2">
          <Icon icon={IconTag} size="14" />
          <Typography size="body-regular-14">{placeholder}</Typography>
        </div>
        {/* <Typography size="body-regular-12">Visualizzazione</Typography> */}
      </div>

      <div
        className=" absolute top-full z-10 flex  w-full  flex-col  space-y-[12px] overflow-hidden  rounded-[4px]  border border-grey-300 bg-white "
        style={{
          display: isOpen ? 'flex' : 'none',
          height: `${items} ? ${items.length * 36}px : 36px`,
          width: ` ${width}`,
          maxHeight: `${height}`,
        }}
      >
        <div className="flex flex-col overflow-auto">
          {items &&
            items.map((item) => {
              return (
                <div
                  key={item}
                  className={`flex h-[36px] items-center gap-2 px-3 py-1 hover:bg-primary-100 ${itemsClassName}`}
                >
                  <Checkbox onChange={() => set(item)} />
                  <Typography size="body-regular-14">{item}</Typography>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
