import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Typography } from '../../../../../components';
import { ExportedProps } from '../../../../../components/form/Form';
import CustomTooltip from '../../../../../components/tooltips/CustomTooltip';
import { IconTrash } from '../../../../../icons';
import { NFT_ATTRIBUTES } from '../../../../../imports/constants';
import { NftCustomAttribute } from '../../../imports/types';
import DocsCustomField from './DocsCustomFileds';
import ImageCustomField from './ImageCustomField';
import NotarizationsCustomField from './NotarizationsCustomField';
import PartnerCustomField from './PartnerCustomField';
import TextCustomField from './TextCustomField';
import { useAppSelector } from '../../../../../redux/hooks';
import i18n from '../../../../../i18n';

interface CustomFieldsProps
  extends Omit<
    Omit<ExportedProps, 'isValid'>,
    'fields' | 'handleSubmit' | 'trigger' | 'reset' | 'getValues' | 'watch' | 'clearErrors'
    // | 'dirtyFields'
  > {
  category: number;
  values: any;
  fields: any;
  customLabels?: any;
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  category,
  errors,
  resetField,
  control,
  customLabels,
  values,
  register,
  setValue,
  dirtyFields,
  fields,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const template = useAppSelector((state) => state.template);
  const attributes = values.nfts[category].attributes;
  const [parent] = useAutoAnimate({ duration: 200 });
  const [hasNotarizations, setHasNotarizations] = useState(false);
  const [howManyNotarizations, setHowManyNotarizations] = useState(0);

  const {
    append,
    remove,
    insert,
    fields: attFields,
  } = useFieldArray({
    control,
    name: `nfts[${category}].attributes`,
  });

  const handleRemoveField = (idToRemove: string) => {
    const indexToRemove = attFields.findIndex((field: any) => field.id === idToRemove);

    if (indexToRemove !== -1) {
      remove(indexToRemove);
    }
  };
  const optElement = [
    {
      value: 'text',
      label: t('create_contract.form_labels.text'),
    },
    {
      value: 'document',
      label: t('create_contract.form_labels.document'),
    },
    {
      value: 'partner',
      label: t('create_contract.form_labels.partner'),
    },
    {
      value: 'image',
      label: t('create_contract.form_labels.image'),
    },
    {
      value: 'warranty_certificate',
      label: t('create_contract.form_labels.warranty_certificate'),
    },
    {
      value: 'authenticity_certificate',
      label: t('create_contract.form_labels.authenticity_certificate'),
    },
    {
      value: 'notarizations',
      label: t('create_contract.form_labels.notarizations'),
    },
  ];

  let options: { value: string; label: any }[] = [];

  if (template.dropDowns?.customFields && template.dropDowns?.customFields.length > 0) {
    const customOptions: { value: string; label: any }[] = [];
    template.dropDowns?.customFields.forEach((option) =>
      customOptions.push({ value: option.value, label: option.label[i18n.language] })
    );
    options = customOptions;
  } else {
    options = optElement;
  }

  useEffect(() => {
    attributes.forEach((attribute: any, i: number) => {
      if (attribute.customType) {
        const attributeCustomType = attribute.customType;
        const templateField = template?.customFields.find(
          (field: any) => field.value === attributeCustomType
        );

        if (templateField?.fields?.trait_type?.value) {
          setValue(
            `nfts[${category}].attributes[${i}].trait_type`,
            templateField?.fields?.trait_type?.value
          );
        }
      }
    });
  }, [attFields]);

  useEffect(() => {
    const hasNotarizationCustomField = attributes.some(
      (attribute: NftCustomAttribute) => attribute.type === 'notarizations'
    );
    setHasNotarizations(hasNotarizationCustomField);
    let howMany = 0;
    attributes.forEach((attribute: NftCustomAttribute) => {
      if (attribute.type === 'notarizations') {
        howMany += 1;
      }
    });
    setHowManyNotarizations(howMany);
  }, [values]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const draggedItem = attributes[result.source.index];
    remove(result.source.index);
    insert(result.destination.index, draggedItem);
  };

  // /* IMAGE LOADING HANDLING */
  // const [isImageValid, setIsImageValid] = useState<boolean>(true);
  // const allowedFileType: { [key: string]: string[] } = getRemoteConfigValue('allowedFiles', 'json');
  return (
    <>
      <div className="flex flex-row gap-2 ">
        <div>{t('create_contract.form_labels.additional_custom_fields')}</div>
        <div className="pt-[4px]">
          <CustomTooltip>
            <Typography size="xs">
              {t('create_contract.form_tooltips.additional_custom_fields') as string}
            </Typography>
          </CustomTooltip>
        </div>
      </div>
      <div className="space-y-12 ">
        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result /* , category */)}>
          <Droppable droppableId="custom-fields">
            {(provided) => {
              return (
                <div key="custom-fields" {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="flex flex-wrap gap-2" ref={parent}>
                    {attFields
                      // ?.filter(({ type }: any) => NFT_ATTRIBUTES.includes(type)) //SE CI SONO NUOVI ATTRIBUTI VANNO AGGIUNTI ALL'ARRAY OPPURE ELEMINO IL FILTER
                      ?.map(({ id }: any, filteredIndex: number) => {
                        const index = attFields.findIndex((el) => el.id === id);

                        const fieldLabel = register(
                          `nfts[${category}].attributes[${index}].trait_type`
                        );
                        const fieldValue = register(`nfts[${category}].attributes[${index}].value`);
                        const fieldFile = register(`nfts[${category}].attributes[${index}].file`);
                        const fieldType = register(`nfts[${category}].attributes[${index}].type`);
                        const fieldPrivate = register(
                          `nfts[${category}].attributes[${index}].private`
                        );
                        const fieldLink = register(`nfts[${category}].attributes[${index}].link`);
                        return (
                          <Draggable draggableId={id} key={id} index={index}>
                            {(provided) => {
                              return (
                                <div
                                  className="flex w-full flex-col gap-4 rounded-[4px] border border-grey-200 p-2"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  key={id + index}
                                >
                                  <div className="flex items-center justify-between">
                                    <Typography weight="medium">
                                      {t('create_contract.form_labels.custom_field_label', {
                                        index: filteredIndex + 1,
                                      })}
                                    </Typography>

                                    <Icon
                                      icon={IconTrash}
                                      onClick={() => handleRemoveField(id)}
                                      size="20"
                                      className="cursor-pointer "
                                      stroke="primary-500"
                                    />
                                  </div>

                                  <div className="flex w-full flex-col gap-4">
                                    {attributes[index].type === 'text' && (
                                      <TextCustomField
                                        errors={errors}
                                        category={category}
                                        index={index}
                                        values={values}
                                        resetField={resetField}
                                        setValue={setValue}
                                        fieldLabel={fieldLabel}
                                        fieldValue={fieldValue}
                                        fieldLink={fieldLink}
                                        fieldType={fieldType}
                                        fieldPrivate={fieldPrivate}
                                        options={options}
                                      />
                                    )}
                                    {attributes[index].type === 'image' && (
                                      <ImageCustomField
                                        errors={errors}
                                        category={category}
                                        index={index}
                                        values={values}
                                        resetField={resetField}
                                        setValue={setValue}
                                        fieldLabel={fieldLabel}
                                        fieldValue={fieldValue}
                                        fieldLink={fieldLink}
                                        fieldType={fieldType}
                                        fieldPrivate={fieldPrivate}
                                        options={options}
                                      />
                                    )}
                                    {attributes[index].type === 'partner' && (
                                      <PartnerCustomField
                                        errors={errors}
                                        category={category}
                                        index={index}
                                        values={values}
                                        resetField={resetField}
                                        setValue={setValue}
                                        fieldLabel={fieldLabel}
                                        fieldValue={fieldValue}
                                        fieldLink={fieldLink}
                                        fieldType={fieldType}
                                        fieldPrivate={fieldPrivate}
                                        options={options}
                                      />
                                    )}
                                    {(attributes[index].type === 'document' ||
                                      attributes[index].type === 'warranty_certificate' ||
                                      attributes[index].type === 'authenticity_certificate') && (
                                      <DocsCustomField
                                        errors={errors}
                                        category={category}
                                        index={index}
                                        values={values}
                                        resetField={resetField}
                                        setValue={setValue}
                                        fieldLabel={fieldLabel}
                                        fieldValue={fieldValue}
                                        fieldLink={fieldLink}
                                        fieldType={fieldType}
                                        fieldPrivate={fieldPrivate}
                                        options={options}
                                      />
                                    )}
                                    {attributes[index].type === 'notarizations' && (
                                      <NotarizationsCustomField
                                        errors={errors}
                                        category={category}
                                        index={index}
                                        values={values}
                                        resetField={resetField}
                                        setValue={setValue}
                                        fieldLabel={fieldLabel}
                                        fieldValue={fieldValue}
                                        fieldLink={fieldLink}
                                        fieldType={fieldType}
                                        fieldPrivate={fieldPrivate}
                                        options={options}
                                        howMany={howManyNotarizations}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="mt-4 flex flex-col gap-1">
        <div className="flex w-full gap-7">
          <Button
            id={`add_field_${category}`}
            action={() => {
              const defaultCustomType = template?.customFields?.[0]?.value ?? '';
              const defaultType = template?.customFields?.[0]?.type ?? 'text';
              append({
                trait_type: '',
                value: '',
                private: false,
                type: defaultType,
                link: '',
                customType: defaultCustomType,
                image: '',
              });
            }}
          >
            +
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomFields;
