/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconFileNotarized = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1864_45286)">
      <path
        d="M126 168H150C151.591 168 153.117 167.368 154.243 166.243C155.368 165.117 156 163.591 156 162V66L114 24H42C40.4087 24 38.8826 24.6321 37.7574 25.7574C36.6321 26.8826 36 28.4087 36 30V96"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 24V66H156"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_1864_45286)">
      <path
        d="M104.75 150.251V121.749C104.748 121.337 104.637 120.934 104.429 120.578C104.221 120.223 103.923 119.929 103.565 119.726L78.1273 105.417C77.7846 105.219 77.3958 105.115 77 105.115C76.6042 105.115 76.2154 105.219 75.8727 105.417L50.4352 119.726C50.0772 119.929 49.7791 120.223 49.571 120.578C49.3629 120.934 49.2522 121.337 49.25 121.749V150.251C49.2522 150.662 49.3629 151.066 49.571 151.421C49.7791 151.777 50.0772 152.071 50.4352 152.274L75.8727 166.583C76.2154 166.781 76.6042 166.885 77 166.885C77.3958 166.885 77.7846 166.781 78.1273 166.583L103.565 152.274C103.923 152.071 104.221 151.777 104.429 151.421C104.637 151.066 104.748 150.662 104.75 150.251V150.251Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={10.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.43 120.564L77.2586 136L49.5664 120.564"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={10.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.2602 136L77 166.872"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={10.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1864_45286">
        <rect width={192} height={192} fill="white" />
      </clipPath>
      <clipPath id="clip1_1864_45286">
        <rect width={74} height={74} fill="white" transform="translate(40 99)" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFileNotarized;
