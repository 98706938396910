import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import { FunctionComponent, SVGProps, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../redux/hooks';

import { db } from '../../../../imports/firebase';
import { safeWhere } from '../../../../imports/utils';

import { PublicCompany, type Filters } from '../../../../imports/types';

import { Breadcrumbs, CollectionHeader, InnerPage, Typography } from '../../../../components';

import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import {
  IconCalendar,
  IconFastBack,
  IconFastForward,
  IconInfo,
  IconLoading,
} from '../../../../icons';
import { getAllAnalytics } from '../../../../imports/analyticsParser';
import { ContractsList } from '../../components';
import ClickAnalyticsComponent from '../../components/ClickAnalyticsComponent/ClickAnalyticsComponent';
import type { Contract } from '../../imports/types';

const initialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'EXACT_MATCH', value: '' },
  inputFilterByDate: { filter: 'DATE_MATCH', value: '' },
};

type ActionType = {
  type: 'SET_STATE';
  filterFieldName: 'inputFilterByName' | 'inputFilterByTag' | 'inputFilterByDate' | 'inputOrderBy';
  payload: string | number | Date;
};

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};
const CompanyAnalytics = () => {
  const { workspace } = useAppSelector((state) => state.team);
  const { t } = useTranslation(['tokenCreator']);

  const { address } = useAppSelector((state) => state.user.wallet);
  const { uid } = useAppSelector((state) => state.user);
  const { list: contractsList } = useAppSelector((state) => state.contracts);

  const [publicCompany, setPublicCompany] = useState<PublicCompany>({});

  const [analytics, setAnalytics] = useState<any>();

  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const [isLoading, setIsLoading] = useState(true);
  const [contracts, setContracts] = useState<Array<Contract>>([]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      icon: IconInfo,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
      icon: IconCalendar,
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
      icon: IconCalendar,
    },
    {
      value: 'name-asc',
      label: 'A-Z',
      icon: IconFastForward,
    },
    {
      value: 'name-desc',
      label: 'Z-A',
      icon: IconFastBack,
    },
  ];

  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  const resetFilterField = (filterFieldName: ActionType['filterFieldName']) => {
    dispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };

  const getPublicCompany = async () => {
    // setIsLoading(true);
    const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
    const snapshot = await getDoc(publicDocRef);
    if (snapshot.exists()) {
      setPublicCompany({ ...snapshot.data() });
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    if (contractsList.length) {
      setAnalytics(getAllAnalytics(contractsList));
    }
  }, [workspace?.id, contractsList]);

  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    getPublicCompany();
    setIsLoading(true);
    const contractsQuery = query(
      collection(db, 'contracts'),
      ...safeWhere(uid, workspace?.id, isSuperGroup ? undefined : activeGroupId)
    );

    const unsubscribeContracts = onSnapshot(contractsQuery, async (querySnapshot) => {
      const contracts: any[] = [];

      querySnapshot.forEach((doc) => {
        contracts.push(doc.data());
      });

      setContracts(contracts);
      setIsLoading(false);
    });

    return () => {
      unsubscribeContracts();
    };
  }, [workspace?.id, groupId]);

  return (
    <>
      <InnerPage>
        {isLoading ? (
          <div className="flex h-screen grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <>
            <div className="mt-12 flex h-[67px] w-full flex-col  justify-between ">
              <Breadcrumbs />
              <div className="flex h-full w-2/3 ">
                <Typography size="body-medium-30">{publicCompany.companyName}</Typography>
              </div>
            </div>
            {workspace?.owner === uid ? (
              <>
                <ClickAnalyticsComponent shouldFetch type="company" />
              </>
            ) : (
              <div className="flex h-screen grow items-center justify-center">
                <IconLoading className="size-12 animate-spin text-primary-500" />
              </div>
            )}
            <div className="mt-12 space-y-4">
              {Object.keys(publicCompany).length === 0 ? (
                <>
                  <Typography size="body-medium-20">{t('collection.future_collection')}</Typography>
                </>
              ) : (
                <>
                  <ContractsList
                    renderSearchBar={
                      <CollectionHeader
                        orderByOptions={orderByOptions}
                        filtersState={state}
                        filtersDispatch={dispatch}
                        handleSetOrderBy={handleSetOrderBy}
                        resetFilterField={resetFilterField}
                      />
                    }
                    contracts={contracts}
                    isLoading={isLoading}
                    filtersState={state}
                    orderBy={orderBy}
                    isAnalytics
                  />
                </>
              )}
            </div>
          </>
        )}
      </InnerPage>
    </>
  );
};

export default CompanyAnalytics;
