import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Button } from '..';
import { getUserAuthToken } from '../../api/firebase';
import { useLoadingStatusContext } from '../../context';
import { deleteNotarization, removeNotarizationFromFolder } from '../../modules/notarizations/api';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type DeleteNotarizationModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { notarization } = useModalProps('DeleteNotarizationModal');
  const { t } = useTranslation(['notarization']);
  const { dispatch: loaderDispatch } = useLoadingStatusContext();

  const handleDeleteNotarization = async () => {
    const { value: authTokenId } = await getUserAuthToken();
    loaderDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('delete_notarization.actions.pending'),
      },
    });
    if (notarization.folderId) {
      await removeNotarizationFromFolder(notarization.folderId, notarization.id)
        .then(() => {
          loaderDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('delete_notarization.actions.success'),
            },
          });
        })
        .catch(() => {
          loaderDispatch({
            type: 'SET_ERROR',
            payload: {
              message: t('delete_notarization.actions.failed'),
            },
          });
        });
    }

    await deleteNotarization({ id: notarization.id, onlyContent: false }, authTokenId)
      .then(() => {
        loaderDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('delete_notarization.actions.success'),
          },
        });
      })
      .catch(() => {
        loaderDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('delete_notarization.actions.failed'),
          },
        });
      });
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col gap-4 px-[20px] pt-[50px]">
        <div data-cy="deleteNotarization" className="mt-7">
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-7">
            {t('delete_notarization.title')}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_notarization.delete_notarization', { name: notarization.name })}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_notarization.disclaimer')}
          </Typography>
        </div>
        <div className="mt-[20px] flex flex-row justify-end">
          <Button id="deleteNotarizarion" type="warning" action={() => handleDeleteNotarization()}>
            {t('delete_notarization.submit_data')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DeleteNotarizationModal = ({ children }: DeleteNotarizationModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DeleteNotarizationModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DeleteNotarizationModal;
