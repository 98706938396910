import { createSlice } from '@reduxjs/toolkit';
import { Template } from '../../modules/tokenCreator/imports/types';

/* STANDARD */
const initialState: Template = {
  isStandardTemplate: true,
  type: 'standard',
  active: true,
  by: 'Bcode',
  icon: 'IconArrow',
  id: 'standard',
  image: '',
  name: 'standard',
  orderBy: 1,
  owner: 'Bcode',
  pack: '',
  steps: ['TEMPLATE', 'GENERAL', 'SPECIFIC', 'REDEEM', 'UPLOAD_MODE', 'NFT', 'SUMMARY'],
  labels: {
    name: { it: 'Standard', en: 'standard' },
    add_category: { it: 'Aggiungi categoria', en: 'Add category' },
    category: { it: 'Categoria', en: 'Category' },
  },
  customFields: [
    {
      value: 'text',
      type: 'text',
      fields: {
        value: {
          label: { it: 'Descrizione', en: 'Description' },
          placeholder: { it: 'Inserisci descrizione', en: 'Insert description' },
        },
        trait_type: {
          label: { it: 'Nome campo', en: "Field's name" },
        },
      },
    },
    {
      value: 'document',
      type: 'document',
      fields: {
        link: {
          label: { it: 'Link al documento', en: "Document's link" },
        },
        file: {
          label: { it: 'Carica documento', en: 'Upload document' },
        },
        trait_type: {
          label: { it: 'Nome documento', en: "Document's name" },
        },
      },
    },
    {
      value: 'authenticity_certificate',
      type: 'authenticity_certificate',
      fields: {
        link: {
          label: { it: 'Link al certificato', en: "Certificate's link" },
        },
        file: {
          label: { it: 'Carica certificato', en: 'Upload certificate' },
        },
        trait_type: {
          label: { it: 'Nome certificato', en: "Certificate's name" },
        },
      },
    },
    {
      value: 'warranty_certificate',
      type: 'warranty_certificate',
      fields: {
        link: {
          label: { it: 'Link al certificato', en: "Certificate's link" },
        },
        file: {
          label: { it: 'Carica certificato', en: 'Upload certificate' },
        },
        trait_type: {
          label: { it: 'Nome certificato', en: "Certificate's name" },
        },
      },
    },
    {
      value: 'partner',
      type: 'partner',
      fields: {
        trait_type: {
          label: { it: 'Nome partner', en: "Partners's name" },
        },
      },
    },
    {
      value: 'image',
      type: 'image',
      fields: {
        trait_type: {
          label: { it: 'Nome immagine', en: "Image's name" },
        },
      },
    },
    {
      value: 'notarizations',
      type: 'notarizations',
      fields: {},
    },
  ],
  dropDowns: {
    tags: [],
    redeemViaQrCode: [
      {
        value: 1,
        label: { it: 'Qr Code singolo per ogni Token', en: 'Single Qr Code for each Token' },
      },
      {
        value: 2,
        label: { it: 'Qr Code unico per tutti i Token', en: 'Unique Qr Code for all Tokens' },
      },
    ],
  },
  fields: {
    /* Generic Step */
    // contractImage: {
    //   label: {
    //     it: 'Immagine collezione (immagine non bianca)',
    //     en: 'Collection image (not white image)',
    //   },
    // },
    // owner: {
    //   label: { it: 'Proprietario collezione', en: 'Collection owner' },
    // },
    // chain: {
    //   label: { it: 'Blockchain', en: 'Blockchain' },
    // },
    // name: {
    //   label: {
    //     it: 'Nome collezione',
    //     en: 'Collection name',
    //   },
    // },
    // type: {
    //   label: { it: 'Tipologia Token', en: 'Token type' },
    // },
    // description: {
    //   label: { it: 'Descrizione collezione', en: 'Collection description' },
    // },
    // tag: {
    //   label: { it: 'Tag collezione', en: 'Collection tag' },
    // },
    // contractExternalUrl: {
    //   label: { it: 'Link sito web', en: 'External link' },
    // },
    /* Specific Step */
    // maxTokensPerUser: {
    //   label: { it: 'Numero massimo di Token per utente', en: 'Maximum number of Tokens per user' },
    // },
    // doesTransfer: {
    //   label: { it: 'Trasferimento Token', en: 'Token transfer' },
    // },
    // transfer: {
    //   value: 'creator&owner',
    // },
    // doesExpire: {
    //   label: { it: 'Scadenza Token', en: 'Expire Token' },
    // },
    // expiration: {},
    // expirationFrom: {},
    // tokenBurn: {
    //   label: { it: 'Burn Token', en: 'Burn Token' },
    // },
    // update: {
    //   label: { it: 'Aggiornamento Token', en: 'Token update' },
    // },
    // doesUpdate: {
    //   value: false,
    // },
    // hasGame: {},
    /* Redeem Step */
    // qrCodeDrop: {
    //   label: { it: 'Modalità riscatto Token', en: 'Token redemption mode' },
    // },
    // qrCodeDropVal???
    // doesDropDate: {
    //   label: { it: 'Data', en: 'Date' },
    //   // value: '',
    // },
    /* Upload Step */
    /* Nfts Step */
    // unlimited: {
    //   value: false,
    // },
    // unlimitedNft: {
    //   value: false,
    // },
    // tokenImage: {
    //   label: { it: 'Immagine token', en: "Token's image" },
    // },
    // tokenImageUploader: {
    //   label: { it: 'Inserire immagine token', en: "Insert token's image" },
    // },
    // tokenName: {
    //   label: { it: 'Nome Token', en: 'Token Name' },
    // },
    // tokenDescription: {
    //   label: { it: 'Descrizione Token', en: 'Token description' },
    // },
    // isUnlimited: {},
    // tokenQuantity: {
    //   label: { it: 'Quantità Token', en: 'Token quantity' },
    //   value: '',
    // },
    // tokenExternalLink: {
    //   label: { it: 'Link sito web', en: 'Website link' },
    //   value: '',
    // },
    // customFields: {
    //   label: { it: '', en: '' },
    //   value: '',
    // },
    // status: {
    //   label: { it: 'Status', en: 'Status' },
    // },
    // totalTokens: {
    //   label: { it: 'Token totali', en: 'Total Tokens' },
    // },
    // numberOfCategories: {
    //   label: { it: 'Token totali', en: 'Total Tokens' },
    // },
    // smartContract: {},
    // isPrivate: {},
    // redeemed: {},
    // category: { label: { it: 'Categoria', en: 'Category' } },
  },
};

export const templatesSlice = createSlice({
  name: 'templateState',
  initialState,
  reducers: {
    setTemplateValue: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        isStandardTemplate: payload,
      };
    },
    setTemplate: (state, action) => {
      const { payload } = action;
      state = payload;
      return state;
    },
    // setTemplate: (state, action) => {
    //   const { payload } = action;
    //   return { ...payload };
    // },
    logOutTemplate: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setTemplateValue, setTemplate, logOutTemplate } = templatesSlice.actions;
export default templatesSlice.reducer;
