import Papa from 'papaparse';
import { useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  FileUploader,
  OverlayDialog,
  SectionTitle,
  Typography,
} from '../../../../components';
import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import { renameFile } from '../../../../imports/utils';

interface CsvStepProps {
  onBack: () => void;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  onNext: () => void;
}

const CsvStep = (props: CsvStepProps) => {
  const { onBack, setValue, onNext, getValues } = props;
  const [csv, setCsv] = useState<File>();
  const [imagesRequested, setImagesRequested] = useState(false);
  const [images, setImages] = useState<File[]>();
  const [showModal, setShowModal] = useState(false);
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');

  const { t } = useTranslation(['tokenCreator']);

  const repeatImages = () => {
    const nfts = getValues('nfts');
    setValue(
      'nfts',
      nfts.map((nft: any) => ({ ...nft, image: images }))
    );
    setShowModal(false);
    onNext();
  };

  const handleModalDecline = () => {
    const nfts = getValues('nfts');
    setShowModal(false);
    setImages(undefined);
    toast.warn(
      t('create_contract.csv_or_manual.doesnt_match', {
        csv: nfts.length,
        images: 1,
      }) as string
    );
  };

  const handleImages = (images: File[]) => {
    setImages(images);
  };

  const handleCsv = (csv: File) => {
    if (!csv) {
      setCsv(csv);
      return;
    }
    Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        const headerAttr = results.meta.fields;
        const mandatoryFields = ['name', 'description', 'quantity', 'external_url'];
        if (!mandatoryFields.every((el) => headerAttr.includes(el))) {
          toast.error('Wrong CSV format');
          return;
        }
        setImagesRequested(!headerAttr.includes('image'));
        setCsv(csv);
      },
    });
  };

  const handleCsvSubmit = () => {
    Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        if (results.data.length > 5000) {
          toast.warn(
            t('create_contract.csv_or_manual.maximum_categories', {
              max: 5000,
              detected: results.data.length,
            }) as string
          );
          return;
        }
        const headerAttr = results.meta.fields;
        const mandatoryFields = ['name', 'description', 'quantity', 'external_url', 'image'];
        const cfTypes = [
          'text',
          'restricted_area',
          'document',
          'partner',
          'warranty_certificate',
          'authenticity_certificate',
        ];

        const csvCustomFields = headerAttr.filter((el: string) => !mandatoryFields.includes(el));

        const nfts = results.data.map((el: any, i: number) => ({
          name: el.name,
          description: el.description,
          external_url: el.external_url,
          quantity: parseInt(el.quantity, 10),
          image: el?.image,
          isUnlimited: false,
          attributes: csvCustomFields.map((cf: string) => {
            const isPrivateField = cf.startsWith('#');
            const cfType = cf.match(/-{3}(.*?)\+{3}/)?.[1] || ''; // ---dato+++
            const cfLink = cf.match(/\+{3}(.*?)$/)?.[1] || ''; // +++dato

            // const trait_type = cf
            //   .split('#')
            //   ?.slice(-1)?.[0]
            //   .split('---')
            //   ?.slice(-1)?.[0]
            //   ?.split('+++')
            //   ?.slice(-1)?.[0];

            const trait_type = /^(?:(?!-{3}|\+{3}).)*$/.test(cf)
              ? cf
              : cf.match(/(.*?)-{3}/)?.[1].replace('#', '');

            return {
              trait_type,
              value: el[cf],
              private: isPrivateField,
              type: cfTypes.includes(cfType) ? cfType : 'text',
              link: cfLink !== cf ? cfLink : '',
            };
          }),
        }));

        setValue('nfts', nfts);
        setValue('numberOfCategories', nfts.length);

        if (!headerAttr.includes('image')) {
          if (!images?.length) throw new Error('no images');

          if (images.length === 1) {
            setShowModal(true);
            return;
          }

          if (nfts.length !== images.length) {
            setImages(undefined);
            toast.warn(
              t('create_contract.csv_or_manual.doesnt_match', {
                csv: nfts.length,
                images: images.length,
              }) as string
            );
            return;
          }

          const orderedImages = [...images]
            .sort((a: File, b: File) => (a.name < b.name ? -1 : 1))
            .map((file) => renameFile(file, file.name.replace(' ', '_')));

          setValue(
            'nfts',
            nfts.map((nft: any, i: number) => ({
              ...nft,
              image: orderedImages[i] ? [orderedImages[i]] : undefined,
            }))
          );
        }

        onNext();
      },
    });
  };

  return (
    <>
      <div className="size-full flex-col items-center justify-center space-y-[60px]">
        <div className="flex flex-col space-y-[20px] ">
          <SectionTitle
            title={t('create_contract.csv_or_manual.without_image')}
            size="xl"
            onBack={onBack}
          />

          <Typography className="whitespace-pre-wrap">
            {t('create_contract.csv_or_manual.desc')}
          </Typography>

          <div className="flex w-full flex-col space-y-[60px] pb-[60px] pt-[100px] ">
            <div className="mx-auto flex w-1/4 flex-col  pb-[64px]">
              <FileUploader
                label={t('create_contract.actions.csv')}
                accept={{ 'text/csv': ['.csv'] }}
                onChange={(files) => handleCsv(files[0])}
                id="loadCSV"
              />
              <Typography className="text-center">
                {' '}
                {`${t('create_contract.actions.download')}`}{' '}
                <a href="/common/example.csv" download>
                  <span className="underline">{t('create_contract.actions.example')}</span>
                </a>
              </Typography>
            </div>

            {imagesRequested && (
              <div className="mx-auto flex w-1/4 flex-col pb-[64px]">
                <FileUploader
                  id="loadImages"
                  label={t('create_contract.actions.images')}
                  //accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
                  accept={allowedFileType}
                  maxFiles={9999}
                  maxSize={100 * 1000000} // not working...
                  onChange={(files) => handleImages(files)}
                />
                <Typography>{`${t('create_contract.csv_or_manual.format_images')}`} </Typography>
              </div>
            )}
          </div>
          <Button
            id="csv-step-next"
            disabled={!csv || (!images?.length && imagesRequested)}
            action={handleCsvSubmit}
            className="mx-auto w-min"
          >
            {t('create_contract.form_labels.next')}
          </Button>
        </div>
      </div>

      <OverlayDialog isOpen={showModal} handleClose={() => setShowModal(false)}>
        <div className="flex w-full grow flex-col items-center justify-between space-y-10 p-10">
          <Typography weight="medium" size="xl">
            {t('create_contract.csv_or_manual.repeat_image')}
          </Typography>

          <div className="flex w-full items-center justify-center space-x-6">
            <Button type="secondary" action={handleModalDecline}>
              {t('no')}
            </Button>
            <Button action={repeatImages}>{t('yes')}</Button>
          </div>
        </div>
      </OverlayDialog>
    </>
  );
};

export default CsvStep;
