import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
/* root imports */
import 'swiper/css';
import 'swiper/css/navigation';
import {
  Button,
  CustomizedText,
  InnerPage,
  SectionTitle,
  Typography,
} from '../../../../components';
import StepperCard from '../../../../components/stepperCard/StepperCard';
import { useLoadingStatusContext } from '../../../../context/loading/LoadingContext';
import { IconArrow, IconEdit, IconLoading } from '../../../../icons';
import { db } from '../../../../imports/firebase';
import { getSpecificPoll } from '../../../../imports/pollsParser';
import type { AvailableOn, PollForm, Submition } from '../../../../imports/types';
import { formatDate } from '../../../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateSubmitions } from '../../../../redux/polls/polls.slice';
import { setPollAvailableOn } from '../../api';
import { Contract } from '../../imports/types';
import PollsAnalytics from '../pollsAnalytics/PollsAnalytics';
import PollsFlows from '../pollsFlows/PollsFlows';

const PollsDetails = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const { id: pollsId } = useParams();
  const { workspace } = useAppSelector((state) => state.team);
  const { uid } = useAppSelector((state) => state.user);
  const { list: pollsList, submitions } = useAppSelector((state) => state.polls);
  const { loading } = useAppSelector((state) => state.polls.api.fetch);
  const targetOwnerUid = workspace?.owner ?? uid;
  const { open: openContractsManagerModal } = useModal('ContractsManagerModal');
  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const [poll, setPoll] = useState<PollForm | undefined>(undefined);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const prevContracts = useRef(contracts);
  const [pollStatus, setPollStatus] = useState<'undefined' | 'incoming' | 'active' | 'completed'>(
    'undefined'
  );
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const dispatch = useAppDispatch();
  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: { ...poll, ...{ type: 'poll' } } });
  };
  const handleOpenContractsManager = (availableContracts: any) => {
    openContractsManagerModal({ availableContracts, setContracts });
  };
  const handleSubmit = async (editedContracts: Contract[], pollId: string) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('polls.actions.pending'),
      },
    });
    const newPollRef = doc(collection(db, 'polls'), pollId);
    const availableOn: AvailableOn = editedContracts.reduce((acc, contract) => {
      acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
      return acc;
    }, {} as { [key: string]: number[] });
    const { value } = await setPollAvailableOn(newPollRef, availableOn);
    if (value) {
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('polls.actions.edit_success'),
        },
      });
      setContracts([]);
    } else {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('polls.actions.error'),
        },
      });
      setContracts([]);
    }
  };

  useEffect(() => {
    if (poll && contracts.length > 0 && prevContracts.current !== contracts) {
      handleSubmit(contracts, poll.id);
    }
    prevContracts.current = contracts;
  }, [contracts]);

  useEffect(() => {
    setPoll(getSpecificPoll(pollsId, pollsList));
    if (pollsId) {
      if (Object.keys(submitions).includes(pollsId)) {
        let lastUpdate: number = 0;
        submitions[pollsId].forEach((submition: Submition) => {
          if (submition.createdAt.seconds > lastUpdate) {
            lastUpdate =
              submition.createdAt.seconds + Math.ceil(submition.createdAt.nanoseconds / 1000000000);
          }
        });
        const lastUpdateDate = new Date(lastUpdate * 1000);
        const submitionsQuery = query(
          collection(db, `polls/${pollsId}/submitions`),
          where('createdAt', '>', lastUpdateDate)
        );

        const unsubscribe = onSnapshot(submitionsQuery, (snapshot) => {
          const changes: Submition[] = [];
          snapshot.docChanges().forEach((change) => {
            changes.push(change.doc.data() as Submition);
          });
          dispatch(updateSubmitions({ [pollsId]: changes }));
        });
        return () => {
          unsubscribe();
        };
      }
      const submitionsQuery = query(collection(db, `polls/${pollsId}/submitions`));

      const unsubscribe = onSnapshot(submitionsQuery, (snapshot) => {
        const changes: Submition[] = [];
        snapshot.docChanges().forEach((change) => {
          changes.push(change.doc.data() as Submition);
        });
        dispatch(updateSubmitions({ [pollsId]: changes }));
      });
      return () => {
        unsubscribe();
      };
    }
  }, [targetOwnerUid]);

  const now = Date.now();
  useEffect(() => {
    if (!poll) return;
    if (poll.owner !== targetOwnerUid) {
      navigate('/nft/polls');
    }
    const start = new Date(poll.startDate).getTime();
    const end = new Date(poll.endDate).getTime();

    if (now < start && now < end) {
      setPollStatus('incoming');
    } else if (start < now && end < now) {
      setPollStatus('completed');
    } else if (start < now && now < end) {
      setPollStatus('active');
    }
  }, [poll]);

  const countDifferenceDay = (date1: number, date2: number) => {
    if (date1.toString().length <= 10) {
      date1 *= 1000;
    }
    if (date2.toString().length <= 10) {
      date2 *= 1000;
    }
    return Math.ceil(Math.abs(date1 - date2) / (1000 * 3600 * 24)).toString();
  };
  return (
    <InnerPage>
      {loading ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!poll ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('functionality.functionality_not_found')}
              </Typography>

              <Button action={() => navigate('/nft/polls')} className="mt-10">
                {t('functionality.goBack')}
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-8 ">
                <div className="flex">
                  <IconArrow
                    onClick={() => navigate(-1)}
                    width={30}
                    height={30}
                    stroke="#642eff"
                    className="mb-3 cursor-pointer rounded-md"
                  />
                </div>
              </div>
              <section className="mt-4">
                <SectionTitle title={poll.title}></SectionTitle>

                <div className="flex w-full justify-end gap-4">
                  <IconEdit
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    width={30}
                    height={30}
                    stroke="white"
                    onClick={() => handleOpenNewFunctionality()}
                    //onClick={() => navigate(`/nft/edit-polls/${poll.id}`)}
                  />
                </div>
                <ul className="mt-4 grid grid-cols-1 gap-x-8 gap-y-2">
                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {t('poll.label_fields.description')}
                    </Typography>
                    <CustomizedText text={poll.body} />
                  </li>
                </ul>
                <ul className="mt-4 grid grid-cols-3 gap-x-8 gap-y-2">
                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {t('poll.label_fields.start')}
                    </Typography>

                    <Typography>{formatDate(new Date(poll.startDate).getTime())}</Typography>
                  </li>
                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {t('poll.label_fields.end')}
                    </Typography>

                    <Typography>{formatDate(new Date(poll.endDate).getTime())}</Typography>
                  </li>
                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {t('poll.label_fields.status')}
                    </Typography>
                    <Typography>{t(`poll.status.${pollStatus}`)}</Typography>
                  </li>
                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {t('poll.label_fields.questions')}
                    </Typography>
                    <Typography>{poll.questions.length}</Typography>
                  </li>

                  <li>
                    <Typography as="h3" size="lg" weight="medium" className="mt-4">
                      {pollStatus === 'active'
                        ? t('poll.label_fields.expiration')
                        : pollStatus === 'completed'
                        ? t('poll.label_fields.expired')
                        : pollStatus === 'incoming' && t('poll.label_fields.starting')}
                    </Typography>
                    <Typography>
                      {pollStatus === 'active' || pollStatus === 'completed'
                        ? `${t('poll.label_fields.days', {
                            days: countDifferenceDay(now, new Date(poll.endDate).getTime()),
                          })}`
                        : pollStatus === 'incoming' &&
                          `${t('poll.label_fields.days', {
                            days: countDifferenceDay(now, new Date(poll.startDate).getTime()),
                          })}`}
                    </Typography>
                  </li>
                </ul>
              </section>
              <section className="mt-6">
                <StepperCard
                  steps={[
                    {
                      title: t('poll.label_fields.answer_title').toString(),
                      component: (
                        <PollsAnalytics
                          questions={poll.questions}
                          submitions={pollsId ? submitions[pollsId] : undefined}
                        />
                      ),
                    },
                    {
                      title: t('poll.label_fields.flow').toString(),
                      component: (
                        <div className="flex h-[700px] w-full justify-center rounded-lg shadow-lg">
                          <PollsFlows questions={poll.questions} />
                        </div>
                      ),
                    },
                  ]}
                />
              </section>
            </>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default PollsDetails;
