import { ReactNode, useEffect } from 'react';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import ModalLayout from '../layouts/ModalLayout';

import { useLoadingStatusContext } from '../../context';
import { delayed } from '../../imports/utils';

type LoadingModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { goTo, type } = useModalProps('LoadingModal');
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  useEffect(() => {
    if (state.status === 'success' && type && goTo) {
      delayed(() => {
        if (type === 'poll') {
          goTo('/nft/functionality');
        }
        if (type === 'contract') {
          goTo('/nft/');
        }
        if (type === 'audit') {
          goTo('/audit-db');
        }
      }, 3500);
    }
    if (state.status === 'error') {
      delayed(() => {
        setOpen(false);
        loadingStatusDispatch({
          type: 'RESET_STATE',
        });
      }, 3500);
    }
  }, [state.status]);

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    />
  );
};

const LoadingModal = ({ children }: LoadingModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="LoadingModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default LoadingModal;
