import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useClickOutsideToClose from '../../hooks/useClickOutsideToClose';
import { IconArrowDown, IconEdit, IconVisible } from '../../icons';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

interface SimpleDropdownOption {
  value: string;
  label: string;
}

interface SimpleDropdownProps {
  onChange?: any /* (options: SimpleDropdownOption[] | string) => void */;
  className?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  selected?: 'editor' | 'viewer' | 'no_access';
  placeholder?: string;
  id?: string;
  name?: string;
  error?: string;
}

const TeamRoleSelect = (props: SimpleDropdownProps) => {
  const {
    onChange,
    className,
    disabled,
    width = '450px',
    height = '175px',
    selected,
    placeholder = 'Select',
    id,
    name,
    error,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(selected || '');
  const [title, setTitle] = useState('');
  const domNode = useClickOutsideToClose(() => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(!open);
  };
  const { t } = useTranslation(['team']);
  const handleSelect = (role: string) => {
    setSelectedState(role);

    onChange(role);
    setOpen(!open);
  };
  useEffect(() => {
    setSelectedState(selected || '');
  }, [selected]);
  // useEffect(() => {
  //   const newItem = items.find((item) => item.value === selectedState);
  //   if (newItem) setTitle(newItem?.label);
  // }, [selectedState]);

  return (
    <div
      className={`relative flex w-[150px] flex-col space-y-[5px]  ${
        disabled ? 'pointer-events-none' : ''
      }`}
      ref={domNode}
      id={id}
    >
      <div
        className={`relative flex min-h-[36px] max-w-[150px] cursor-pointer items-center justify-between rounded-[4px] border border-grey-300 py-[4px] pl-[10px]  text-body-regular-12 text-grey-500 hover:opacity-70 active:opacity-100 ${
          open ? '  bg-grey-200 ' : 'bg-white '
        } 

     ${className}`}
        onClick={handleOpen}
        style={{ width: ` ${width}` }}
      >
        {selectedState ? t(`select.${selectedState}`) || title || placeholder : t('select.select')}
        <Icon
          icon={IconArrowDown}
          className={`absolute right-[10px] top-1/2 -translate-y-1/2 ${
            open ? 'rotate-180' : 'rotate-0'
          } transition-all duration-200`}
          size="16"
          stroke={disabled ? 'grey-300' : 'grey-500'}
        />
      </div>

      <div
        className=" absolute top-full z-10 flex max-w-[150px]  flex-col  space-y-[12px] overflow-x-hidden  rounded-[4px]  border border-grey-300 bg-white shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
        style={{
          display: open ? 'flex' : 'none',
          width: ` ${width}`,
          // height: ` ${items.length * 44}px`,
          maxHeight: `${height}`,
        }}
      >
        <div className="h-full min-w-full max-w-[150px] overflow-hidden ">
          <div
            className={` flex  min-h-[36px] w-full max-w-[150px] cursor-pointer items-center gap-[11px] px-[12px] hover:bg-primary-100  ${
              selectedState === 'viewer' ? 'bg-primary-100' : ''
            } `}
            onClick={() => handleSelect('viewer')}
            key="viewer"
          >
            <Icon
              icon={IconVisible}
              size="16"
              stroke={`${selectedState === 'viewer' ? 'primary-500' : 'grey-500'}`}
            />
            <Typography
              size="body-regular-12"
              color={`${selectedState === 'viewer' ? 'primary-500' : 'grey-500'}`}
            >
              {t('select.viewer')}
            </Typography>
          </div>
          <div
            className={` flex  min-h-[35px] w-full max-w-[150px] cursor-pointer items-center gap-[11px]   px-[12px] hover:bg-primary-100  ${
              selectedState === 'editor' ? 'bg-primary-100' : ''
            } `}
            onClick={() => handleSelect('editor')}
            key="editor"
          >
            <Icon
              icon={IconEdit}
              size="16"
              stroke={`${selectedState === 'editor' ? 'primary-500' : 'grey-500'}`}
            />
            <Typography
              size="body-regular-12"
              color={`${selectedState === 'editor' ? 'primary-500' : 'grey-500'}`}
            >
              {t('select.editor')}
            </Typography>
          </div>

          <div
            className={` flex  min-h-[35px] w-full max-w-[150px] cursor-pointer items-center gap-[11px]   px-[12px] hover:bg-primary-100  ${
              selectedState === 'no_access' ? 'bg-primary-100' : ''
            } `}
            onClick={() => handleSelect('no_access')}
            key="no_access"
          >
            <Typography size="body-regular-12" color="error">
              {t('select.no_access')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamRoleSelect;
