import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { Typography } from '../../../../components';
import { QuestionForm, Submition } from '../../../../imports/types';

type PollsAnalyticsProps = {
  questions: QuestionForm[];
  submitions?: Submition[];
};

const PollsAnalytics = ({ questions, submitions }: PollsAnalyticsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { open: openPollsAnalyticsModal } = useModal('PollsAnalyticsModal');

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-grey-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-grey-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  >
                    <Typography weight="semibold" size="md">
                      {t('poll.label_fields.answer_title')}
                    </Typography>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {questions.map((question, index) => {
                  const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-grey-200';

                  return (
                    <tr key={question.qId} className={rowClass}>
                      <td className="whitespace-nowrap px-6 py-4">
                        <div
                          className="flex cursor-pointer items-center gap-2"
                          onClick={() =>
                            openPollsAnalyticsModal({
                              question,
                              submitions, //magari dargli quelle relative alla domanda?
                            })
                          }
                        >
                          <Typography className="px-2">{question.question}</Typography>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PollsAnalytics;
