import { useEffect, useState } from 'react';
import useClickOutsideToClose from '../../hooks/useClickOutsideToClose';
import { IconArrowDown } from '../../icons';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

interface SimpleDropdownOption {
  value: string;
  label: string;
}

interface SimpleDropdownProps {
  items: SimpleDropdownOption[];
  onChange: (options: SimpleDropdownOption[] | string) => void;
  className?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  selected?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  error?: string;
}

const SelectDropdown = (props: SimpleDropdownProps) => {
  const {
    items,
    onChange,
    className,
    disabled,
    width = '450px',
    height = '175px',
    selected,
    placeholder,
    id,
    name,
    error,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(selected || '');
  const [title, setTitle] = useState('');
  const domNode = useClickOutsideToClose(() => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (option: number) => {
    setSelectedState(items[option].value);
    onChange(items[option].value);
    setOpen(!open);
  };

  useEffect(() => {
    const newItem = items.find((item) => item.value === selectedState);
    if (newItem) setTitle(newItem?.label);
  }, [selectedState]);

  return (
    <div
      className={`relative flex flex-col space-y-[9px] ${disabled ? 'pointer-events-none' : ''}`}
      ref={domNode}
      id={id}
    >
      <div
        className={`relative flex min-h-[40px] cursor-pointer  items-center justify-between rounded-[4px] border py-[4px]  pl-[17px] text-body-regular-16 hover:opacity-70 active:opacity-100 ${
          open
            ? ' border-primary-500 text-grey-500 outline outline-[4px] outline-[#0042D04D]'
            : 'border-grey-500   '
        } 
       ${title ? '' : 'text-grey-300'}
        ${disabled ? ' border-grey-300' : ''} ${className}`}
        onClick={handleOpen}
        style={{ width: ` ${width}` }}
      >
        {title || placeholder}
        <Icon
          icon={IconArrowDown}
          className={`absolute right-[17px] top-1/2 -translate-y-1/2 ${
            open ? 'rotate-180' : 'rotate-0'
          } transition-all duration-200`}
          size="16"
          stroke={disabled ? 'grey-300' : 'grey-500'}
        />
      </div>

      <div
        className=" absolute top-full z-10 flex  flex-col  space-y-[12px] overflow-x-hidden  rounded-[4px]  border border-grey-500 bg-white"
        style={{
          display: open ? 'flex' : 'none',
          width: ` ${width}`,
          height: ` ${items.length * 44}px`,
          maxHeight: `${height}`,
        }}
      >
        <div className="h-full min-w-full overflow-y-auto overflow-x-hidden ">
          {items.map((field, i) => {
            return (
              <div
                className={` flex  min-h-[44px] w-full cursor-pointer items-center justify-between border-b border-grey-500  px-[12px] hover:bg-primary-100  ${
                  field.value === selectedState ? 'bg-primary-100' : ''
                } ${i === items.length - 1 ? 'border-none' : ''}`}
                onClick={() => handleSelect(i)}
                key={i}
              >
                <Typography
                  size="body-regular-16"
                  color={`${field.value === selectedState ? 'primary-500' : 'grey-500'}`}
                >
                  {field.label}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectDropdown;
