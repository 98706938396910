import React, { BaseSyntheticEvent, FunctionComponent, SVGProps, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Icon, Input, OrderBySelect, Typography } from '../../../../components';
import { IconClose, IconFileConfirmed, IconInfo, IconListPlus, IconQrCode, IconResearch, IconSwap } from '../../../../icons';
import PageSlider from '../pageSlider/PageSlider';
import { AnalyticsActivity } from '../../api/analytics';
import { Activity } from '../../../../imports/types';
import TrxTable from '../TrxTable/TrxTable';
import { Contract } from '../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

type DetailsTableProps = {
    analytics: any,
}

const DetailsTable = ({analytics}:DetailsTableProps) => {
  const {t} = useTranslation(['tokenCreator']);
  const [nameFilter, setNameFilter] = useState('')
  const [dateFilter, setDateFilter] = useState<number>(NaN);
  const [stateFilter, setStateFilter] = useState('');
  const [page, setPage] = useState(0);
  const {list: contractList } = useAppSelector((state) => state.contracts)
  const { id: contractId } = useParams();
  const contract = contractList.find((contract: Contract) => contract.id === contractId)

  const stateFilterOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: '',
      label: t('collection.filters.state.state'),
      icon: IconInfo,
    },
    {
      value: 'redeem',
      label: t('collection.filters.state.redeem'),
      icon: IconQrCode,
    },
    {
      value: 'transfer',
      label: t('collection.filters.state.transfer'),
      icon: IconSwap,
    },
    {
      value: 'tokenVerification',
      label: t('collection.filters.state.verified'),
      icon: IconFileConfirmed,
    },
    {
      value: '',
      label: t('collection.filters.state.all'),
      icon: IconListPlus,
    },
  ];
 
 let activities = analytics
  activities = activities?.filter(
    (activity: Activity) =>
      nameFilter === '' ||
      activity.data.contract_id?.toLowerCase().startsWith(nameFilter.toLowerCase())
  );
  activities = activities?.filter(
    (activity: { createdAt: number }) =>
      Number.isNaN(dateFilter) || activity?.createdAt >= dateFilter
  );
  activities = activities?.filter(
    (activity: { type: 'string' }) =>
      stateFilter === '' || activity.type.toLowerCase().startsWith(stateFilter.toLowerCase())
  );
  const maxElementPerPage = 20;
  const logInBatches = activities?.reduce(
    (resultArray: AnalyticsActivity[][], item: any, index: number) => {
      const chunkIndex = Math.floor(index / maxElementPerPage);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    },
    []
  );
  const numberOfPages = logInBatches ? logInBatches.length : 0;
  
  return (
    <>
    <div className="flex flex-col mt-4" id="transaction">
    <Typography size="body-regular-20">
      {t('collection_item.transaction')}
    </Typography>
    <div className="mt-[32px] flex w-full items-center gap-4">
      <Input
        type="text"
        placeholder={t('collection.filters.name')}
        onChange={(event) => {
          setPage(0);
          setNameFilter(event.target.value);
        }}
        elementLeft={<Icon icon={IconResearch} />}
        elementRight={
          <Button
            type="ghost"
            icon={IconClose}
            action={() => setNameFilter('')}
          />
        }
        value={nameFilter}
        className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
      />
      <OrderBySelect
        items={stateFilterOptions}
        onChange={(value) => {
          setPage(0);
          setStateFilter(value);
        }}
      />

      <Input
        type="date"
        placeholder={t('collection.filters.date')}
        onChange={(event: BaseSyntheticEvent) => {
          setPage(0);
          setDateFilter(event.target.valueAsNumber);
        }}
        className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-400"
      />
    </div>
    {logInBatches && logInBatches?.length > 0 && contract && (
      <div className="flex flex-col mt-4" id="transaction">
        <div className={`mt-[32px] flex w-full flex-col space-y-[20px] `}>
          <TrxTable transactions={logInBatches[page]} contracts={[contract]} />
        </div>
        {logInBatches && numberOfPages > 1 && (
          <div className="mt-[32px] flex w-full flex-row place-content-center">
            <PageSlider
              numberOfPages={numberOfPages}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
    )}
  </div>
  </>
  )
}

export default DetailsTable