import Lottie from 'react-lottie-player';

import { animationError, animationLoading, animationSuccess } from '../../assets/animations';

import Button from '../button/Button';
import Typography from '../typography/Typography';

type LoadingProps = {
  status: 'pending' | 'success' | 'error' | null;
  title?: string | null;
  message?: string;
  action?: { label: string; onClick: () => void };
};
const SplashScreen = ({ status, title, message, action }: LoadingProps) => (
  <div className="bg-overlay fixed left-0 top-0 flex size-full flex-col items-center justify-center space-y-4 p-10">
    {status === 'pending' && (
      <Lottie loop animationData={animationLoading} play style={{ width: 100, height: 100 }} />
    )}
    {status === 'success' && (
      <Lottie loop animationData={animationSuccess} play style={{ width: 100, height: 100 }} />
    )}
    {status === 'error' && (
      <Lottie loop animationData={animationError} play style={{ width: 100, height: 100 }} />
    )}

    {title && (
      <Typography as="h2" size="xl" weight="semibold" color="white" className="pt-8">
        {title}
      </Typography>
    )}
    {message && <Typography color="white">{message}</Typography>}
    {action && (
      <div className="w-[17rem] pt-12">
        <Button type="primary" action={action.onClick}>
          {action.label}
        </Button>
      </div>
    )}
  </div>
);

export default SplashScreen;
