import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getDefaultDateTimeValue } from '../../imports/utils';
import useValidation from '../useValidation';

const usePollsSchema = (isQuestionStep: boolean) => {
  const { required, validateStartDate, validateContractsArray } = useValidation();
  const { defaultDate, defaultTime } = getDefaultDateTimeValue({ extraHours: 2 });

  const { t } = useTranslation(['tokenCreator']);

  return yup.object({
    title: required(yup.string()),
    body: required(yup.string()),
    startHours: required(
      yup
        .number()
        .test('twoHoursAdvance', t('polls.errors.start_2_hour_later'), (value) => {
          if (value) {
            return value >= defaultTime;
          }
          return true;
        })
        .test('compareTime', t('polls.errors.date_start_higher_than_end'), (value, schema) => {
          const { startDate, endDate, endHours } = schema.parent;
          if (value && new Date(startDate).getTime() === new Date(endDate).getTime()) {
            return value < endHours;
          }

          return true;
        })
    ),
    endHours: yup.number(),
    startDate: required(
      yup.mixed().test('startDate', t('messages.dropDateError'), (value, schema) => {
        const { endDate } = schema.parent;
        if (endDate) {
          return new Date(value).getTime() <= new Date(endDate).getTime();
        }
        return true;
      })
    ),
    endDate: required(
      yup.mixed().test('endDate', t('messages.dropDateError'), (value, schema) => {
        const { startDate } = schema.parent;
        if (startDate) {
          return new Date(value).getTime() >= new Date(startDate).getTime();
        }
        return true;
      })
    ),
    options: required(validateContractsArray()),
    minTotalAnswers: required(
      yup.number().test('test-min', t('form_validation.min_more_than_max'), (value, context) => {
        return (
          context &&
          context.parent.minTotalAnswers >= 1 &&
          context.parent.minTotalAnswers <= context.parent.maxTotalAnswers
        );
      })
    ),
    maxTotalAnswers: required(
      yup.number().test('test-min', t('form_validation.min_more_than_max'), (value, context) => {
        return (
          context &&
          context.parent.minTotalAnswers >= 1 &&
          context.parent.minTotalAnswers <= context.parent.maxTotalAnswers
        );
      })
    ),
    questions: isQuestionStep
      ? yup.mixed()
      : yup
          .array()
          .of(
            yup.object().shape({
              minAnswers: yup.number(),
              maxAnswers: yup.number(),
              question: required(
                yup
                  .string()
                  .test(
                    'question',
                    t('polls.form_validation.required'),
                    (question) => !!question?.length
                  )
              ),
              answers: yup
                .array()
                .of(
                  yup.object().shape({
                    answer: required(yup.string()),
                    nextQuestion: required(
                      yup
                        .string()
                        .test(
                          'nextQuestion',
                          t('polls.form_validation.nextQuestion_required'),
                          (nextQuestion) => !!nextQuestion?.length
                        )
                    ),
                  })
                )
                .min(1, t('polls.form_validation.min_answer_1')),
            })
          )
          .min(1, t('polls.form_validation.min_question_1')),
  });
};

export default usePollsSchema;
