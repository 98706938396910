import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { NftAttribute, NftCustomAttribute } from '../../modules/tokenCreator/imports/types';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type DuplicateCategoryModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { category, duplicate } = useModalProps('DuplicateCategoryModal');
  const { t } = useTranslation(['tokenCreator']);

  const createEmptyCategory = () => {
    const categoryStartingSelection: {
      image: boolean;
      name: boolean;
      description: boolean;
      quantity: boolean;
      external_url: boolean;
      attributes: boolean[];
      [key: string]: any;
    } = {
      image: false,
      name: false,
      description: false,
      quantity: false,
      external_url: false,
      attributes: [],
    };

    category.attributes?.forEach(() => {
      categoryStartingSelection.attributes.push(false);
    });
    return categoryStartingSelection;
  };
  const [selected, setSelected] = useState(createEmptyCategory());
  const [howMany, setHowMany] = useState('1');

  const handleDuplicate = () => {
    const newCategory: {
      name: string;
      description: string;
      quantity: number;
      external_url: string;
      image: File[] | string;
      isUnlimited: boolean;
      attributes?: NftAttribute[];
      [key: string]: any;
    } = {
      name: '',
      description: '',
      quantity: 1,
      external_url: '',
      image: [],
      isUnlimited: false,
      attributes: [],
    };
    Object.keys(selected).forEach((field: string) => {
      if (field !== 'attributes' && selected[field]) {
        newCategory[field] = category[field];
      }
      if (field === 'attributes' && selected.attributes.length > 0) {
        const newAttributes: any = [];
        selected.attributes.forEach((attribute: boolean, index: number) => {
          if (attribute) {
            newAttributes.push(category.attributes[index]);
          }
        });
        newCategory.attributes = newAttributes;
      }
    });
    duplicate(newCategory, howMany);
    setOpen(false);
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="max-h-[90%] overflow-auto"
    >
      <div className="flex size-full flex-col gap-6 px-[25px] pb-6 pt-[50px]">
        <div className="flex flex-col gap-2">
          <Typography size="body-medium-20">
            {t('create_contract.titles.select_field_duplicate')}
          </Typography>
          {category.image[0] && (
            <div className="flex items-center space-x-[12px]">
              <Checkbox
                id="image"
                onChange={() => {
                  setSelected({ ...selected, image: !selected.image });
                }}
              />
              <Typography size="body-regular-16" color="grey-500">
                {t('create_contract.form_labels.nft_image_title')}
              </Typography>
            </div>
          )}
          {category.name && (
            <div className="flex items-center space-x-[12px]">
              <Checkbox
                id="name"
                onChange={() => {
                  setSelected({ ...selected, name: !selected.name });
                }}
              />
              <Typography size="body-regular-16" color="grey-500">
                {t('create_contract.form_labels.nft_name')}
              </Typography>
            </div>
          )}
          {category.description && (
            <div className="flex items-center space-x-[12px]">
              <Checkbox
                id="desctiption"
                onChange={() => {
                  setSelected({ ...selected, description: !selected.description });
                }}
              />
              <Typography size="body-regular-16" color="grey-500">
                {t('create_contract.form_labels.nft_description')}
              </Typography>
            </div>
          )}
          {category.quantity && (
            <div className="flex items-center space-x-[12px]">
              <Checkbox
                id="quantity"
                onChange={() => {
                  setSelected({ ...selected, quantity: !selected.quantity });
                }}
              />
              <Typography size="body-regular-16" color="grey-500">
                {t('create_contract.form_labels.nft_quantity')}
              </Typography>
            </div>
          )}
          {category.external_url && (
            <div className="flex items-center space-x-[12px]">
              <Checkbox
                id="external_url"
                onChange={() => {
                  setSelected({ ...selected, external_url: !selected.external_url });
                }}
              />
              <Typography size="body-regular-16" color="grey-500">
                {t('create_contract.form_labels.nft_external_link')}
              </Typography>
            </div>
          )}
          {category.attributes && category.attributes.length > 0 && (
            <Typography size="body-regular-16" className="mt-2">
              {t('create_contract.form_labels.additional_custom_fields')}
            </Typography>
          )}
          {category.attributes?.map((attribute: NftCustomAttribute, index: number) => {
            return (
              <div className="flex items-center space-x-[12px]">
                <Checkbox
                  id={index.toString()}
                  onChange={() => {
                    setSelected({
                      ...selected,
                      attributes: [
                        ...selected.attributes.slice(0, index),
                        !selected.attributes[index],
                        ...selected.attributes.slice(index + 1),
                      ],
                    });
                  }}
                />
                <Typography size="body-regular-16" color="grey-500">
                  {attribute.trait_type}
                </Typography>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-2">
          <Typography size="body-medium-20">
            {t('create_contract.titles.number_of_copy')}
          </Typography>
          <Input
            type="number"
            minValue="1"
            placeholder={t('create_contract.form_placeholders.quantity')}
            onChange={(change) => setHowMany(change.target.value)}
            defaultValue="1"
          />
        </div>
        <div className="flex justify-end">
          <Button action={() => handleDuplicate()}>{t('create_contract.titles.duplicate')}</Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DuplicateCategoryModal = ({ children }: DuplicateCategoryModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DuplicateCategoryModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DuplicateCategoryModal;
