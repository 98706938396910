import React, { forwardRef, FunctionComponent, ReactNode, SVGProps } from 'react';
import type { Colors } from '../../imports/types';

import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

import './Button.css';

type ButtonProps = {
  id?: string;
  action: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  className?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconSize?: 'sm' | 'md' | 'lg' | string;
  iconColor?: Colors;
  iconClass?: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'ghost-dark' | 'warning' | 'gradient';
  loading?: boolean;
  disabled?: boolean;
  iconStroke?: Colors;
  isInvisibleButton?: boolean;
  textSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'body-regular-18' | 'body-regular-12';
  textWeight?: 'regular' | 'medium' | 'semibold' | 'bold' | 'light';
  classNameText?: string;
  width?: string;
  height?: string;
};

const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    id = '',
    action,
    children,
    className = '',
    icon,
    iconSize = '16',
    iconColor,
    iconClass,
    type = 'primary',
    loading,
    disabled,
    iconStroke,
    isInvisibleButton,
    textSize = 'md',
    textWeight = 'bold',
    classNameText,
    width = 'full',
    height = '52px',
  } = props;

  const isIcon = !children && icon;
  const isDisabled = disabled || loading;

  return (
    <button
      id={id}
      type="button"
      className={`btn ${isInvisibleButton && 'invisible'} ${type} ${disabled && 'disabled'}
      ${className}`}
      onClick={action}
      disabled={isDisabled}
      ref={ref}
      style={icon ? {} : { width, height }}
    >
      {isIcon ? (
        <Icon
          icon={icon}
          size={iconSize}
          color={iconColor}
          loading={loading}
          stroke={iconStroke}
          className={` ${
            type === 'ghost' && isDisabled ? 'cursor-not-allowed opacity-50' : 'opacity-100'
          }`}
        />
      ) : (
        <>
          {loading && <Icon size="sm" color={iconColor} stroke={iconStroke} loading />}
          {icon && !loading && (
            <Icon
              icon={icon}
              size={iconSize}
              stroke={iconStroke}
              color={iconColor}
              className={iconClass}
            />
          )}

          <Typography className={`px-[30px] py-[15px] ${classNameText}`} size={textSize}>
            {children}
          </Typography>
        </>
      )}
    </button>
  );
});

export default Button;
