import { SVGProps } from 'react';

const IconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="iconcheck"
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38763)">
      <path
        d="M162 54L78 138L36 96"
        // eslint-disable-next-line react/destructuring-assignment
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38763">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconCheck;
