/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx,svg}'],
  theme: {
    colors: {
      primary: {
        100: '#f1eafe', // -> viola semi-trasparente
        400: '#9368fe', // -> viola chiaro
        500: '#642eff', // -> viola base
      },
      secondary: {
        100: '#F5F9FF', // -> blu semi-trasparente
        500: '#1c63fe', // -> blu base
        800: '#101052', // -> blu scuro
      },
      grey: {
        50: 'fff0', // transparent
        100: '#F7F7F7', // grey6 o F7F7F7 p F7F8FD
        200: '#E0E0E0', // grey5
        300: '#CFD0D5', // grey4
        400: '#828282', // grey3
        500: '#4f4f4f', // grey2
        600: '#333333', // grey1
      },
      success: '#118900',
      warning: '#FFBF00',
      error: '#D64045',
      white: '#ffffff',
      black: '#000000',
    },
    fontFamily: {
      sans: ['Work Sans', 'sans-serif'],
    },
    fontSize: {
      h1: ['50px', { lineHeight: '55px', fontWeight: '700' }],
      h2: ['40px', { lineHeight: '50px', fontWeight: '700' }],
      h3: ['30px', { lineHeight: '35px', fontWeight: '700' }],
      h4: ['20px', { lineHeight: '25px', fontWeight: '700' }],
      h5: ['16px', { lineHeight: '22px', fontWeight: '700' }],

      'body-regular-20': ['20px', { lineHeight: '30px', fontWeight: '400' }],
      'body-regular-18': ['18px', { lineHeight: '25px', fontWeight: '400' }],
      'body-regular-16': ['16px', { lineHeight: '22px', fontWeight: '400' }],
      'body-regular-14': ['14px', { lineHeight: '20px', fontWeight: '400' }],
      'body-regular-12': ['12px', { lineHeight: '18px', fontWeight: '400' }],
      'body-regular-10': ['10px', { lineHeight: '12px', fontWeight: '400' }],

      'body-medium-30': ['30px', { lineHeight: '30px', fontWeight: '500' }],
      'body-medium-20': ['20px', { lineHeight: '24px', fontWeight: '500' }],
      'body-medium-18': ['18px', { lineHeight: '25px', fontWeight: '500' }],
      'body-medium-16': ['16px', { lineHeight: '22px', fontWeight: '500' }],
      'body-medium-14': ['14px', { lineHeight: '20px', fontWeight: '500' }],
      'body-medium-12': ['12px', { lineHeight: '18px', fontWeight: '500' }],
      'body-medium-10': ['10px', { lineHeight: '20px', fontWeight: '500' }],

      'body-semibold-30': ['30px', { lineHeight: '36px', fontWeight: '600' }],
      'body-semibold-20': ['20px', { lineHeight: '22px', fontWeight: '600' }],
      'body-semibold-18': ['18px', { lineHeight: '22px', fontWeight: '600' }],

      'body-bold-16': ['16px', { lineHeight: '22px', fontWeight: '700' }],
      'body-bold-14': ['14px', { lineHeight: '22px', fontWeight: '700' }],
      'body-bold-12': ['12px', { lineHeight: '12px', fontWeight: '700' }],

      cta: ['18px', { lineHeight: '20px', fontWeight: '500' }],
      faq: ['20px', { lineHeight: '26px', fontWeight: '300' }],
      footer: ['15px', { lineHeight: '20px', fontWeight: '400' }],

      'body-medium-underline-16': [
        '16px',
        { lineHeight: '22px', fontWeight: '500', textDecoration: 'underline' },
      ],
      'body-medium-underline-14': [
        '14px',
        { lineHeight: '22px', fontWeight: '500', textDecoration: 'underline' },
      ],
      'body-medium-underline-12': [
        '12px',
        { lineHeight: '22px', fontWeight: '500', textDecoration: 'underline' },
      ],
      'body-medium-underline-10': [
        '10px',
        { lineHeight: '12px', fontWeight: '500', textDecoration: 'underline' },
      ],

      'body-regular-underline-12': [
        '12px',
        { lineHeight: '22px', fontWeight: '400', textDecoration: 'underline' },
      ],
      xs: ['0.75rem', { lineHeight: '1rem' }],
      sm: ['0.875rem', { lineHeight: '1.25rem' }],
      md: ['1rem', { lineHeight: '1.5rem' }],
      lg: ['1.125rem', { lineHeight: '1.75rem' }],
      xl: ['1.25rem', { lineHeight: '1.75rem' }],
    },
  },
  // extends: {
  //   fontSize: {
  //     xs: ['0.75rem', { lineHeight: '1rem' }],
  //     sm: ['0.875rem', { lineHeight: '1.25rem' }],
  //     md: ['1rem', { lineHeight: '1.5rem' }],
  //     lg: ['1.125rem', { lineHeight: '1.75rem' }],
  //     xl: ['1.25rem', { lineHeight: '1.75rem' }],
  //   },
  // },
  plugins: [],
};
