import { SVGProps } from 'react';

const IconAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_329_116445"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={3}
      width={16}
      height={10}
    >
      <path d="M15.9781 3.34131H0.0722656V12.659H15.9781V3.34131Z" fill="white" />
    </mask>
    <g mask="url(#mask0_329_116445)">
      <path
        d="M15.0138 3.37826C14.7197 3.35291 14.4993 3.4119 14.3405 3.5579C14.1335 3.74801 14.0335 4.08053 14.0262 4.60316C13.855 4.79835 13.6844 4.99251 13.5144 5.18564C13.1217 5.6321 12.7156 6.0937 12.3215 6.54911C11.9273 7.00453 11.5297 7.47125 11.1451 7.92255C10.98 8.11616 10.8148 8.30995 10.6494 8.50393C10.6047 8.50296 10.5581 8.49969 10.5092 8.4963C10.3648 8.47727 10.2181 8.48473 10.0764 8.51833C9.88777 8.57557 9.77568 8.52208 9.60984 8.38886C8.69704 7.65633 7.84869 7.08716 7.01624 6.64899C6.95088 6.61895 6.89331 6.5741 6.848 6.51792C6.80274 6.46175 6.77093 6.39578 6.75514 6.32517C6.68912 6.07118 6.52682 5.85347 6.30312 5.71892C6.07948 5.58437 5.81232 5.54374 5.55922 5.60578C5.30187 5.66635 5.07669 5.82258 4.92864 6.04324C4.78058 6.26391 4.72056 6.53276 4.76058 6.79609C4.7709 6.86567 4.78339 6.93525 4.7954 7.0015L4.80504 7.05594L1.46224 10.2963C1.44525 10.2946 1.42832 10.2928 1.41145 10.291C1.35574 10.285 1.29809 10.2789 1.24029 10.2774C0.687423 10.2613 0.347574 10.5051 0.170089 11.0448C0.0369596 11.4496 0.182693 11.8109 0.303938 12.0474C0.386083 12.2174 0.511186 12.3626 0.666678 12.4683C0.822173 12.5741 1.00256 12.6367 1.18968 12.6498C1.20852 12.6508 1.22732 12.6513 1.24609 12.6514C1.42622 12.6487 1.60285 12.6007 1.76003 12.512C1.91721 12.4233 2.04999 12.2965 2.14642 12.1432C2.43453 11.7067 2.47931 11.2976 2.28244 10.8941L5.5261 7.77958L6.4297 7.49824L9.11029 9.3519C9.13142 9.75057 9.22992 10.0495 9.42782 10.3176C9.54302 10.4822 9.71027 10.6026 9.90217 10.6591C10.0941 10.7156 10.2993 10.7047 10.4843 10.6284C11.0192 10.4236 11.3162 9.9696 11.3022 9.38076L14.5553 5.30797C15.0168 5.39873 15.375 5.35639 15.6204 5.18153C15.8101 5.04654 15.9256 4.83726 15.9635 4.55948C15.9853 4.41942 15.9783 4.27632 15.943 4.13907C15.9077 4.00183 15.8449 3.87336 15.7583 3.76162C15.6668 3.64977 15.5536 3.55805 15.4255 3.49211C15.2975 3.42617 15.1573 3.38742 15.0138 3.37826Z"
        fill="#4F4F4F"
      />
    </g>
  </svg>
);
export default IconAnalytics;
