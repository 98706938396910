import api from '../imports/axios';
import { auth } from '../imports/firebase';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const validatePec = async (pec: string, authTokenId: string) => {
  try {
    const response = await api.post(
      '/requestPecValidation',
      { pecAddress: pec },
      {
        headers: {
          authorization: authTokenId,
        },
      }
    );

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

/**
 * sends a list of nft ids of a given contract that have been changed, users who have them will receive a push notification
 * @param askerUid
 * @param contractId
 * @param nftsIds
 * @returns
 */
export const sendModifyPushNotification = async (
  askerUid: string,
  contractId: string,
  nftsIds: number[]
) => {
  const response = await api.post(
    '/messaging/pushNotification/modifiedToken',
    { askerUid, contractId, nftsIds },
    {
      headers: {
        // TODO: use getUserAuthToken after feat/supportChat merge
        authorization: await auth.currentUser?.getIdToken()!,
      },
    }
  );

  return response.data;
};
