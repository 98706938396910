import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { useLoadingStatusContext } from '../../context';
import useValidation from '../../hooks/useValidation';
import { IconClose } from '../../icons';
import { updateAuditDoc } from '../../modules/audit/api';
import { Audit } from '../../modules/audit/imports/types';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type AuditRenameModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { t } = useTranslation(['audit']);
  const { audit, setAudit } = useModalProps('AuditRenameModal');
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  const { workspace } = useAppSelector((user) => user.team);
  const { required } = useValidation();
  const form = {
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: required(yup.string()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleEditName = async (values: any) => {
    const { name } = values;

    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('rename_audit.actions.pending'),
      },
    });

    if (audit && audit.auditId) {
      const auditData: Audit = { ...audit.data, name };
      const { value: updateAuditValue, error: updateAuditError } = await updateAuditDoc(
        audit.auditId,
        auditData
      );
      if (updateAuditError) {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('rename_audit.actions.failed'),
          },
        });
        return;
      }
      if (updateAuditValue) {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('rename_audit.actions.success'),
          },
        });
        if (setAudit) {
          setAudit(updateAuditValue);
        }
      }
    }
  };

  return (
    <ModalLayout
      classNameLayout="w-auto"
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="my-7 flex flex-col gap-7 px-7" key={audit.auditId}>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          className="flex w-full flex-col space-y-4"
        >
          {({ fields, handleSubmit, errors, resetField }) => (
            <>
              <Typography weight="medium" size="lg" color="primary-500" className="mt-7">
                {t('create_audit.change_new_audit_name')}
              </Typography>
              <Field error={errors.name?.message}>
                <Input
                  type="text"
                  placeholder={t('create_audit.form_placeholders.name')}
                  id={fields.name.name}
                  name={fields.name.name}
                  onBlur={fields.name.onBlur}
                  onChange={fields.name.onChange}
                  inputRef={fields.name.ref}
                  error={errors.name?.message}
                  elementRight={
                    <Button
                      type="ghost"
                      icon={IconClose}
                      action={() => resetField(fields.name.name)}
                    />
                  }
                />
              </Field>
              {workspace?.permissions.audit.write && (
                <div className="flex justify-end">
                  <Button
                    action={() => handleSubmit(handleEditName)()}
                    loading={state.status === 'pending'}
                  >
                    {t('create_audit.buttons.edit_audit_name')}
                  </Button>
                </div>
              )}
            </>
          )}
        </Form>
      </div>
    </ModalLayout>
  );
};

const AuditRenameModal = ({ children }: AuditRenameModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="AuditRenameModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AuditRenameModal;
