import { Document, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerPage: {
    width: '90%',
    height: '90%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#2ca6ff',
    borderBottomWidth: 2,
    textAlign: 'center',
    fontSize: '28px',
    width: '100%',
    height: 80,
    paddingBottom: 10,
  },
  content: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    margin: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
  signContainer: {
    position: 'absolute',
    bottom: 50,
    right: 50,
    width: '150px',
    height: '70px',
    borderBottomWidth: '1px',
    borderColor: 'black',
  },
  signSpace: {
    textAlign: 'left',
  },
});

type PecValidationDocumentProps = {
  user: any;
  pec: string;
};

const PecValidationDocument = ({ user, pec }: PecValidationDocumentProps) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.innerPage}>
          <View style={styles.header}>
            {/* @ts-ignore */}
            <Svg width={150} height={51.5} viewBox="0 0 1526 532">
              <Path
                d="M1369.06 395H1489.46C1522.76 395 1530.06 352.2 1523.26 326.1C1517.86 305.2 1507.96 284.8 1491.56 270.4C1423.66 210.6 1315.96 260.3 1290.16 337.3C1285.56 351 1283.26 365.4 1283.26 379.8C1283.26 420.8 1301.66 457.5 1330.76 482.2C1354.66 502.6 1385.76 514.9 1419.86 514.9C1443.26 514.9 1466.36 510.5 1486.66 498.4C1495.46 493.1 1503.96 486.3 1508.16 476.7C1508.96 474.9 1509.56 473 1509.96 471.1C1511.66 463.4 1510.56 455 1507.56 447.7C1500.86 431.6 1484.46 421.5 1467.16 420.7C1459.36 420.3 1451.76 422.6 1445.06 426.6C1436.56 431.6 1426.76 434.3 1416.76 434.3C1399.76 434.3 1385.26 430 1375.66 420.3C1370.66 415.2 1366.86 408.6 1364.86 400.4C1364.16 397.6 1366.26 395 1369.06 395ZM1378.76 334.4C1381.46 327.5 1390.36 311 1413.56 311C1436.76 311 1445.66 327.5 1448.36 334.4C1448.96 335.9 1447.86 337.4 1446.36 337.4H1380.86C1379.26 337.4 1378.16 335.9 1378.76 334.4Z"
                fill="#000217"
              />
              <Path
                d="M1255.56 400.4C1255.56 390.1 1255.46 370.4 1255.46 345.2C1255.46 286.4 1255.46 198.2 1255.46 133.9C1255.46 118.7 1255.46 104.8 1255.46 93C1255.46 92.9 1255.46 92.8 1255.46 92.6C1255.46 86.8 1255.46 81.4 1255.46 76.6C1255.46 75.8 1255.46 75 1255.46 74.3C1255.46 72.8 1255.46 71.4 1255.46 70C1255.46 69.8 1255.46 69.6 1255.46 69.4C1255.46 69 1255.46 68.6 1255.46 68.2C1255.46 67.6 1255.46 67 1255.46 66.5C1255.46 63.1 1255.46 60.3 1255.46 58V57.9C1255.46 57 1255.46 56.2 1255.46 55.5C1255.46 55.3 1255.46 55.1 1255.46 54.9C1255.46 54.4 1255.46 53.9 1255.46 53.6C1255.46 53.3 1255.46 53.1 1255.46 52.9C1255.46 52.4 1255.46 52 1255.46 51.9V51.8C1255.46 23.3 1232.16 0 1203.66 0C1175.16 0 1151.86 23.3 1151.86 51.8V131.9V247.9C1143.06 246.1 1134.06 245.2 1124.76 245.2C1050.26 245.2 989.557 305.8 989.557 380.4C989.557 454.9 1050.16 515.6 1124.76 515.6C1129.86 515.6 1135.06 515.3 1140.16 514.8C1145.26 514.3 1150.26 513.5 1155.26 512.4C1160.16 511.3 1165.06 510 1169.86 508.5C1174.56 507 1179.26 505.2 1183.76 503.1C1188.26 501.1 1192.66 498.8 1196.96 496.3C1201.16 493.8 1205.26 491.2 1209.16 488.2C1213.06 485.3 1216.76 482.2 1220.26 478.9C1223.76 475.6 1227.06 472.1 1230.16 468.4C1233.26 464.7 1236.06 460.9 1238.66 456.9C1241.26 452.9 1243.66 448.7 1245.66 444.3C1247.76 439.9 1249.56 435.4 1251.06 430.8C1252.76 425.4 1254.26 419.9 1254.96 414.3C1255.06 413.5 1255.56 403.7 1255.26 402.5C1255.56 401.8 1255.56 401.1 1255.56 400.4ZM1157.26 422.8C1148.26 429.6 1137.06 433.7 1124.96 433.7C1095.46 433.7 1071.46 409.7 1071.46 380.2C1071.46 350.7 1095.46 326.7 1124.96 326.7C1134.86 326.7 1144.16 329.4 1152.06 334.1C1167.76 343.4 1178.36 360.6 1178.36 380.1C1178.36 397.6 1170.06 413.1 1157.26 422.8Z"
                fill="#000217"
              />
              <Path
                d="M967.757 379.6C967.457 305.4 906.957 245 832.557 245C761.057 245 702.357 300.8 697.657 371.1C697.657 371.4 697.657 371.6 697.657 371.9C697.557 372.9 697.557 373.9 697.557 374.9C697.557 375.3 697.557 375.7 697.557 376.1C697.557 377.5 697.457 378.8 697.457 380.2C697.457 454.7 758.057 515.4 832.657 515.4C907.157 515.4 967.857 454.8 967.857 380.2C967.757 380.1 967.757 379.9 967.757 379.6ZM832.557 433.7C803.057 433.7 779.057 409.7 779.057 380.2C779.057 379.4 779.057 378.6 779.157 377.8C779.157 377.6 779.157 377.4 779.157 377.2C779.157 376.5 779.257 375.8 779.357 375.1C779.357 375 779.357 374.9 779.357 374.8C782.157 347.9 804.957 326.9 832.557 326.9C862.057 326.9 886.057 350.9 886.057 380.4C886.057 409.7 862.057 433.7 832.557 433.7Z"
                fill="#000217"
              />
              <Path
                d="M532.657 332.2C540.757 327 550.357 323.9 560.457 323.6C573.157 323.3 585.257 327.2 595.057 334.8C599.857 338.5 605.557 340.6 611.557 340.9C639.657 342.1 662.757 319.2 661.857 291.1C661.657 285.5 649.857 273 645.757 269.2C620.357 245.9 597.057 240.3 562.257 240.2C546.657 240.2 531.557 242.9 517.357 248C464.857 266.7 425.457 317 424.057 374.8C422.257 452.4 484.857 516.2 562.057 516.2C590.457 516.2 618.057 507.4 641.157 491.3C647.757 486.7 652.157 479.5 653.557 471.6C658.357 443.4 635.957 418.1 607.357 419.3C601.557 419.6 595.957 421.4 590.957 424.5C582.357 429.9 572.357 432.8 562.057 432.8C530.457 432.8 505.057 405.9 507.657 373.8C509.057 356.3 518.757 341.2 532.657 332.2Z"
                fill="#000217"
              />
              <Path
                d="M388.757 343.1C385.957 319.9 379.557 297.7 367.057 277.7C354.857 258.2 338.957 242.3 320.057 229.5C318.857 228.7 317.757 228 316.557 227.2C329.657 197.2 331.957 162 320.457 132C304.357 90.0001 268.557 55.3001 223.157 47.3001C202.657 43.7001 182.157 44.5001 161.457 44.5001H91.0572C85.8572 44.5001 80.6572 44.2001 75.5572 44.6001C42.7572 46.9001 17.1572 66.1001 5.05724 96.4001C-2.14276 114.6 0.45723 137.8 0.45723 157.1V382.6C0.45723 395.9 0.35723 409.1 0.45723 422.4C0.95723 471.4 34.8572 517 83.2572 528.4C102.857 533 123.557 531.7 143.557 531.7H216.057C238.857 531.7 261.557 531.7 283.457 524.3C325.457 510.1 359.757 479.3 377.057 438.3C389.557 408.8 392.557 374.7 388.757 343.1ZM260.457 449.7C246.357 453.3 231.557 453.3 216.957 453.3C184.457 453.2 151.957 453.2 119.557 453.1C109.157 453.1 97.7572 452.7 89.9572 445.7C81.1572 437.7 80.5572 424.4 80.5572 412.5C80.5572 316.2 80.5572 220 80.6572 123.7C105.957 122.2 131.357 121.8 156.657 122.4C179.157 122.9 204.357 122 224.657 133.2C264.157 155.1 260.057 219.2 221.257 239.1C209.157 245.3 182.557 246.8 169.057 248.2C152.557 249.9 113.157 248.8 113.057 275.2C112.957 301 129.357 306.2 151.657 306.2C183.657 306.2 215.857 305.6 247.757 306.2C264.657 306.5 282.357 309.7 295.457 320.5C307.057 330 313.657 344.7 315.457 359.5C319.957 396.8 298.657 439.8 260.457 449.7Z"
                fill="#000217"
              />
            </Svg>
          </View>

          {/* @ts-ignore */}
          <View style={styles.content}>
            <Text style={{ ...styles.text, marginBottom: 30, marginTop: 30 }}>
              {t('verify_pec.pec_document.declaration', {
                name: user?.company,
                address: user?.address,
              })}
            </Text>
            <Text style={{ ...styles.text }}>
              {t('verify_pec.pec_document.request', {
                pec,
              })}
            </Text>
          </View>
          <View style={styles.signContainer}>
            <Text style={{ ...styles.text, ...styles.signSpace }}>
              {t('verify_pec.pec_document.sign')}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PecValidationDocument;
