/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1708_42152)">
      <path
        d="M162 42H30"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 78V126"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 78V126"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 42V156C150 157.591 149.368 159.117 148.243 160.243C147.117 161.368 145.591 162 144 162H48C46.4087 162 44.8826 161.368 43.7574 160.243C42.6321 159.117 42 157.591 42 156V42"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126 42V30C126 26.8174 124.736 23.7652 122.485 21.5147C120.235 19.2643 117.183 18 114 18H78C74.8174 18 71.7652 19.2643 69.5147 21.5147C67.2643 23.7652 66 26.8174 66 30V42"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1708_42152">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconTrash;
