import type { ReactNode } from 'react';

import { LoadingStatusProvider } from '.';

const providers = [LoadingStatusProvider];

const ContextProvider = providers.reduce(
  (AccumulatedComponents, CurrentComponent) =>
    ({ children }: { children: ReactNode }) =>
      (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
  ({ children }: any) => <>{children}</>
);

export default ContextProvider;
