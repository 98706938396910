import { t } from 'i18next';
import { MainLogo } from '../../assets/images';
import Image from '../image/Image';
import LinkText from '../linkText/LinkText';
import LegalDropdown from './LegalDropdown';

type FooterProps = {
  id?: string;
  className?: string;
  email?: boolean;
};

const Footer = ({ id, className, email }: FooterProps) => {
  return (
    <div className="static bottom-0 left-0 z-40  flex h-[76px] w-full shrink-0 flex-row ">
      <div
        id={id}
        className={`relative mx-auto  flex size-full items-center justify-between bg-grey-100 px-[70px] py-[21px] text-body-regular-16 text-white ${className}`}
      >
        {email ? (
          <div className="mx-auto text-center text-body-regular-16 text-black">
            {t('footer.support') as string}
            <a className="text-body-bold-16" href="mailto:supporto@bcode.cloud">
              supporto@bcode.cloud
            </a>
          </div>
        ) : (
          <>
            {/* left */}
            <Image src={MainLogo} alt={t('bcode_logo')} className="w-[91px]" />

            {/* <div>{copyright}</div> */}
            <LinkText
              to="/faq"
              className="text-body-medium-16 text-black underline hover:decoration-white"
            >
              FAQ
            </LinkText>
            <LegalDropdown />
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
