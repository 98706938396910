import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button, Form, Typography } from '../../../../components';
import { CHAIN_MODULES } from '../../../../imports/constants';
import { useAppSelector } from '../../../../redux/hooks';
import { grantPermission, grantPermissionsOnDb, handleBackendError } from '../../api/api';
import {
  checkPermissionChanged,
  checkWritePermissionChanged,
  computeRoles,
} from '../../common/utils';
import { Permissions, WorkspaceMember } from '../../imports/types';
import { getInitialValues } from '../../pages/manage-teams/utils/manageTeamsUtils';
import { ManageTeamsTableBody, ManageTeamsTableHeader } from '../index';

type WorkspaceManageProps = {
  members: WorkspaceMember[] | undefined;
  pendingRequest: boolean;
};

const Workspace = ({ members, pendingRequest }: WorkspaceManageProps) => {
  const { t } = useTranslation(['team']);
  const {
    uid,
    wallet: { privateKey },
  } = useAppSelector((state) => state.user);
  const workspace = useAppSelector((state) => state.team.workspace);

  const handleChangePermissions = async (
    address: string,
    userId: string,
    permissions: Permissions
  ) => {
    if (workspace == null) return;
    try {
      const prevPermissions = members?.find(({ user }) => user === userId)
        ?.permissions as Permissions;
      if (checkPermissionChanged(prevPermissions, permissions)) {
        const writePermissionChanged = checkWritePermissionChanged(prevPermissions, permissions);
        // First value needs to be setted, doesn't mind if is true or false beacuse index 0 is not releated to any modules
        if (writePermissionChanged) {
          await grantPermission(privateKey, workspace?.id, address, userId, [
            false,
            ...CHAIN_MODULES.map((mod: string) => permissions[mod.toLowerCase()].write || false),
          ]);
        }

        const setDbPermissions = await grantPermissionsOnDb(
          workspace?.id,
          userId,
          computeRoles(permissions),
          writePermissionChanged
        );
        if (typeof setDbPermissions === 'object' && setDbPermissions.error) {
          throw new Error(setDbPermissions.error);
        }
      }
      // toast.success(t('manage.detail.change.success') as string);
    } catch (error) {
      handleBackendError(error);
    }
  };

  const onSubmit = (permissions: { [key: string]: Permissions }) => {
    if (workspace?.isOwner) {
      try {
        members?.forEach((member, i) =>
          handleChangePermissions(member!.address, member!.user, permissions[member!.user])
        );
        toast.success(t('manage.detail.change.success') as string);
      } catch (error) {
        handleBackendError(error);
      }
    } else toast.error(t('errors.request_not_authorized') as string);
    // qui ci dovrà essere la funzione per non poter modificare in attesa di approvazione
  };
  return (
    <div className={`mt-7 text-body-medium-18 `}>
      {pendingRequest && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full text-center bg-white opacity-80">
          <Typography
            size="body-medium-20"
            weight="medium"
            className="m-auto text-center pointer-events-none "
          >
            {t('errors.wait_pending_approvation')}
          </Typography>
        </div>
      )}
      <ManageTeamsTableHeader pendingRequest={pendingRequest} />

      <Form
        initialValues={getInitialValues(members ?? [])}
        validationSchema={yup.object({})}
        className={`size-full pb-5 ${pendingRequest && 'pointer-events-none opacity-30'}`}
      >
        {({ handleSubmit, setValue, watch, reset }) => {
          const role = watch();
          // const handleSelected = (permissions: { read: boolean; write: boolean }) => {
          //   if (permissions.write) return 'editor';
          //   if (!permissions.write && permissions?.read) return 'viewer';
          //   if (!permissions?.write && !permissions?.read) return 'no_access';
          // };

          const changeSelection = (
            selection: string,
            index: number | string,
            module: 'token_creator' | 'notarization' | 'audit'
          ) => {
            if (selection === 'viewer') {
              setValue(`${index}.${module}.write`, false);
              setValue(`${index}.${module}.read`, true);
            }
            if (selection === 'editor') {
              setValue(`${index}.${module}.write`, true);
              setValue(`${index}.${module}.read`, true);
            }
            if (selection === 'no_access') {
              setValue(`${index}.${module}.write`, false);
              setValue(`${index}.${module}.read`, false);
            }
          };

          return (
            <>
              {members && members.length > 0 ? (
                members
                  ?.map((member, i) => {
                    console.log(member)
                    return (
                      <ManageTeamsTableBody
                        key={`${member.user}_${i}`}
                        member={member}
                        index={i}
                        role={role}
                        changeSelection={changeSelection}
                        workspaceIsOwner={workspace?.isOwner ?? false}
                      />
                    );
                  })
              ) : (
                <Typography size="body-medium-18" weight="medium">
                  {t('errors.no_members')}
                </Typography>
              )}
              {workspace?.isOwner && (
                <div className="mt-[60px] flex justify-between">
                  <Button
                    type="secondary"
                    action={() => {
                      reset();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    disabled={isEqual(role, getInitialValues(members ?? []))}
                    action={handleSubmit(onSubmit)}
                  >
                    {t('save_changes')}
                  </Button>
                </div>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default Workspace;
