import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, Typography } from '../../../../components';
import { IconAnalytics, IconLoading } from '../../../../icons';

type analyticsProps = {
  analytics: {
    redeem: number;
    transfer: number;
    tokenVerification: number;
  };
  label?: string;
  to?: string;
  hasGaming?: boolean;
  gamingAnalytics?: any;
};
const AnalyticsComponent = ({
  analytics,
  label,
  to,
  hasGaming,
  gamingAnalytics,
}: analyticsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const analyticsField = (label: string, value: any) => {
    return (
      <div className="min-w-[170px] ">
        <div className="flex flex-auto">
          <div className="item-center flex flex-col justify-start gap-1 ">
            <Typography
              weight="semibold"
              color="primary-500"
              size="h3"
              className={`${analytics === undefined ? 'mx-auto' : ''}`}
            >
              {value}
            </Typography>
            <Typography
              size="body-regular-16"
              className={`${analytics === undefined ? 'opacity-30' : ''}`}
            >
              {label}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-7 flex flex-col" id="analytics">
      <div className="flex justify-between">
        <div>
          <div className="flex flex-row items-center gap-8">
            <Typography size="lg" weight="medium">
              {label}
            </Typography>
            {to && (
              <Icon
                icon={IconAnalytics}
                onClick={() => navigate(to)}
                size="20"
                stroke="primary-500"
                className="cursor-pointer rounded-md "
              />
            )}
          </div>
          <div className="mt-[30px] flex flex-row gap-4">
            {analyticsField(
              t(`analytics.redeems`) as string,
              analytics === undefined ? (
                <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
              ) : (
                analytics.redeem || 0
              )
            )}
            {analyticsField(
              t(`analytics.transfers`) as string,
              analytics === undefined ? (
                <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
              ) : (
                analytics.transfer || 0
              )
            )}
            {analyticsField(
              t(`analytics.verifications`) as string,
              analytics === undefined ? (
                <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
              ) : (
                analytics.tokenVerification || 0
              )
            )}
          </div>
        </div>
        {hasGaming && (
          <div>
            <div className="flex flex-row items-center gap-8">
              <Typography size="body-medium-20" weight="medium">
                Gaming Analytics
              </Typography>
            </div>
            <div className="mt-[30px] flex flex-row gap-4">
              {analyticsField(
                t(`analytics.gaming.playersNumber`) as string,
                gamingAnalytics === undefined ? (
                  <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
                ) : (
                  gamingAnalytics.activePlayers
                )
              )}
              {analyticsField(
                t(`analytics.gaming.totalScore`) as string,
                gamingAnalytics === undefined ? (
                  <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
                ) : (
                  gamingAnalytics.totalScore.toFixed(0)
                )
              )}
              {analyticsField(
                t(`analytics.gaming.gamesNumber`) as string,
                gamingAnalytics === undefined ? (
                  <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
                ) : (
                  gamingAnalytics.games
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsComponent;
