import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from 'react-portal';

import OutsideAlerter from '../outsideAlerter/OutsideAlerter';
import Typography from '../typography/Typography';

import { IconClose } from '../../icons';

import { colors } from '../../imports/constants';

type OverlaySidebarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
};

const OverlaySidebar = ({ children, isOpen, handleClose, title }: OverlaySidebarProps) => {
  const { black, overlay } = colors;

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <div className="fixed top-0 left-0 h-screen w-screen bg-black/75">
            <OutsideAlerter onOutsideClick={handleClose}>
              <motion.div
                initial={{ x: '50vw', y: '-110%', translateY: 0, translateX: '-50%' }}
                animate={{ x: '50vw', y: '50vh', translateY: '-50%', translateX: '-50%' }}
                exit={{ x: '50vw', y: '-110%', translateY: 0, translateX: '-50%' }}
                transition={{ type: 'spring', bounce: 0.4, duration: 1 }}
                className="absolute flex min-h-[24rem] w-[40rem] flex-col overflow-hidden rounded-3xl bg-white"
              >
                <header className="relative flex h-14 items-center justify-center px-4">
                  {/* titolo accentrato perchè a sinistra non viene mai usato */}

                  {title && (
                    <Typography as="h2" size="xl" weight="semibold" className="text-center ">
                      {title}
                    </Typography>
                  )}
                  <button
                    type="button"
                    className="duration-250 absolute top-4 right-4 ml-auto rounded-full bg-[#EEEEEE] p-1 transition hover:bg-[#d6d6d6]"
                    onClick={handleClose}
                  >
                    <IconClose className="h-4 w-4" />
                  </button>
                </header>
                {children}
              </motion.div>
            </OutsideAlerter>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default OverlaySidebar;
