import { t } from 'i18next';
import * as yup from 'yup';
import { formFieldsWithMultipleOptions } from '../../imports/constants';
import { regexFromUnionType } from '../../imports/utils';
import { isUrl } from '../../modules/tokenCreator/imports/utils';
import useValidation from '../useValidation';

const useContractSchema = (isCollectionStep: boolean) => {
  const { required, disallowSpecialCharacterAndNumbersAtTheBeginning, validateFileImage } =
    useValidation();

  return yup.object({
    name: required(
      disallowSpecialCharacterAndNumbersAtTheBeginning().max(
        50,
        t('form_validation.max_length', { maxChars: 50 })
      )
    ),
    description: required(yup.string()),
    type: required(yup.string()),
    owner: required(yup.string()),
    symbol: yup.string(),
    tag: yup.array(),
    chain: required(yup.string()),
    collectionExternalUrl: yup.string(),
    contractImage: yup.array().of(validateFileImage()).min(1, t('form_validation.required')),
    metadataUri: yup.string(),
    numberOfCategories: required(yup.number().min(1)),
    // maxTokensPerUser: required(yup.number().min(1)),
    maxTokensPerUser: yup
      .number()
      .typeError(t('form_validation.required'))
      .min(1, t('form_validation.required')),
    doesExpire: required(yup.boolean()),
    expiration: yup.number().when('doesExpire', {
      is: true,
      then: required(yup.number().min(0)),
    }),
    expirationFrom: required(
      yup.string().when('doesExpire', {
        is: true,
        then: required(
          yup
            .string()
            .matches(
              regexFromUnionType(formFieldsWithMultipleOptions['create-contract'].expirationFrom)
            )
        ),
      })
    ),
    doesTransfer: required(yup.boolean()),
    transfer: yup.string().when('doesTransfer', {
      is: true,
      then: required(
        yup
          .string()
          .matches(regexFromUnionType(formFieldsWithMultipleOptions['create-contract'].transfer))
      ),
    }),
    transferAddresses: yup.string().when('transfer', {
      is: 'specificList',
      then: yup.string(),
      otherwise: yup
        .string()
        .test(
          'transfer-addresses-should-be-empty',
          '',
          (transferAddresses) => transferAddresses === ''
        ),
    }),
    doesUpdate: required(yup.boolean()),
    update: yup.string().when('doesUpdate', {
      is: true,
      then: required(
        yup
          .string()
          .matches(regexFromUnionType(formFieldsWithMultipleOptions['create-contract'].update))
      ),
    }),
    updateAddresses: yup.string().when('update', {
      is: 'specificList',
      then: yup.string(),
      otherwise: yup
        .string()
        .test(
          'update-addresses-should-be-empty',
          '',
          (transferAddresses) => transferAddresses === ''
        ),
    }),
    transferOwnership: required(yup.boolean()),
    tokenBurn: required(yup.boolean()),
    status: yup
      .string()
      .matches(regexFromUnionType(formFieldsWithMultipleOptions['create-contract'].status)),
    qrCodeDrop: required(yup.boolean()),
    qrCodeDropVal: required(yup.number()),
    doesDropDate: required(yup.boolean()),
    dropDate: yup.mixed().when('doesDropDate', {
      is: true,
      then: required(yup.mixed()),
    }),
    nfts: isCollectionStep
      ? yup.mixed()
      : yup.array().of(
          yup.object().shape({
            name: required(
              yup
                .string()
                .test(
                  'lol',
                  t('form_validation.no_slash_character'),
                  (name: string | undefined) => name?.includes('/') === false
                )
                .max(50, t('form_validation.max_length', { maxChars: 50 }))
            ),
            description: required(yup.string()),
            quantity: required(yup.number().min(1)),
            external_url: yup.string(),
            isUnlimited: required(yup.boolean()),
            image: yup.lazy((value) =>
              typeof value === 'string'
                ? required(yup.string())
                : yup.array().of(validateFileImage()).min(1, t('form_validation.required'))
            ),
            attributes: yup.array().of(
              yup.object().shape({
                private: yup.boolean(),
                trait_type: required(yup.string()),
                type: required(yup.string()),
                file: yup.array(),
                // value: yup.string().when(['file', 'type'], {
                //   is: (file: any, type: string) =>
                //     !file?.[0]?.name && ['text', 'partner'].includes(type),
                //   then: yup.string().when('type', {
                //     is: 'partner',
                //     then: yup
                //       .string()
                //       .test(
                //         'check-non-empty',
                //         t('form_validation.required'),
                //         (url) => !!url && isUrl(url)
                //       ),
                //     otherwise: yup.string().required(t('form_validation.required')),
                //   }),
                // }),
                // value: yup.mixed().when(['file', 'type'], {
                //   is: (file: any, type: string) =>
                //     !file?.[0]?.name && ['text', 'partner'].includes(type),
                //   then: yup.lazy((value: any) => {
                //     // if (value && typeof value === 'object') {
                //     //    return yup.object();
                //     // }
                //     if (Array.isArray(value) && value.length > 0) {
                //       return yup.array().of(yup.object()).required(t('form_validation.required'));
                //     }

                //     return yup.string().when('type', {
                //       is: 'partner',
                //       then: yup
                //         .string()
                //         .test(
                //           'check-non-empty',
                //           t('form_validation.required'),
                //           (url) => !!url && isUrl(url)
                //         ),
                //       otherwise: yup.string().required(t('form_validation.required')),
                //     });
                //   }),
                // }),
                value: yup.mixed().when(['file', 'type'], {
                  is: (file: any, type: string) =>
                    !file?.[0]?.name && ['text', 'partner', 'notarizations'].includes(type),
                  then: yup.lazy((value: any) => {
                    if (Array.isArray(value) && value.length > 0) {
                      return yup.array().min(1, t('form_validation.required'));
                    }
                    return yup.string().when('type', {
                      is: 'partner',
                      then: yup
                        .string()
                        .test(
                          'check-non-empty',
                          t('form_validation.required'),
                          (url) => !!url && isUrl(url)
                        ),
                      otherwise: yup.string().required(t('form_validation.required')),
                    });
                  }),
                }),
                link: yup
                  .string()
                  .ensure()
                  .when(['file', 'type'], {
                    is: (file: any, type: string) =>
                      !file?.[0]?.name && type !== 'text' && type !== 'notarizations',
                    then: required(
                      yup
                        .string()
                        .test(
                          'check-non-empty',
                          t('form_validation.required'),
                          (url) => !!url && isUrl(url)
                        )
                    ),
                  }),
                // link: yup.string().when(['file', 'type'], {
                //   is: (file: any, type: string) =>
                //     !file?.[0]?.name && type !== 'text' && type !== 'notarizations',
                //   then: required(
                //     yup
                //       .string()
                //       .test(
                //         'check-non-empty',
                //         t('form_validation.required'),
                //         (url) => !!url && isUrl(url)
                //       )
                //   ),
                // }),
              })
            ),
          })
        ),
  });
};

export default useContractSchema;
