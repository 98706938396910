import { useLocation, useNavigate } from 'react-router-dom';
import { IconMail } from '../../icons';
import Icon from '../icon/Icon';

type MessageIconProps = {
  className?: string;
};

const MessageIcon = ({ className }: MessageIconProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={`h-[40px] w-[40px] rounded-lg ${className}`}>
      <div
        onClick={() => navigate('/messages')}
        className={`flex !h-[40px] !w-[40px] cursor-pointer items-center justify-center rounded-[10px] ${
          pathname === '/messages'
            ? 'bg-primary-400'
            : 'border border-primary-400 bg-white hover:bg-primary-100'
        }`}
      >
        <Icon
          icon={IconMail}
          size="md"
          stroke={`${pathname === '/messages' ? 'white' : 'primary-400'}`}
        />
      </div>
      {/* per ora non mostrare, sarà collegato a notifiche */}
      {/* <span className="absolute -right-1 bottom-0 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-secondary-500 text-center text-[10px] text-white">
              {String(0).padStart(2, '0')}
            </span> */}
      {/* logica di funzionamento completa */}
      {/* {unreadMessages > 0 && (
              <span className="absolute right-0 bottom-0 flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 text-center text-[10px]">
                {String(unreadMessages).padStart(2, '0')}
              </span>
            )} */}
    </div>
  );
};

export default MessageIcon;
