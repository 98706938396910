import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { Breadcrumbs, Button, InnerPage, Typography } from '../../../../components';
import { useLoading } from '../../../../context/LoadingContext';
import { FIREBASECHANGEEVENTS } from '../../../../imports/constants';
import { db } from '../../../../imports/firebase';
import {
  Filters,
  NewsForm,
  ProductForm,
  RestrictedAreaForm,
  ShopForm,
} from '../../../../imports/types';
import {
  removeProduct,
  removeRestrictedArea,
  removeShop,
  updateNews,
  updateProduct,
  updateRestrictedAreas,
  updateShops,
} from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  AttributesList,
  NewsList,
  PollsList,
  RestrictedAreaList,
  ShopList,
} from '../../components';

const initialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'EXACT_MATCH', value: '' },
  inputFilterByDate: { filter: 'DATE_MATCH', value: '' },
};

type ActionType = {
  type: 'SET_STATE';
  filterFieldName: 'inputFilterByName' | 'inputFilterByTag' | 'inputFilterByDate' | 'inputOrderBy';
  payload: string | number | Date;
};

const reducer = (state: typeof initialState, action: ActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

const Functionality = () => {
  const { workspace } = useAppSelector((state) => state.team);
  const { list: pollsList } = useAppSelector((state) => state.polls);
  const { shops, restrictedAreas, news, products } = useAppSelector(
    (state) => state.functionalities
  );
  const { t } = useTranslation(['tokenCreator']);
  const { uid } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: null });
  };
  const { isLoading, setLoading } = useLoading();

  // const [functionalities, setFunctionalities] = useState<Array<Functionalities>>([]);
  // const [polls, setPolls] = useState<Array<PollForm>>([]);
  //const [news, setNews] = useState<Array<FunctionalitiesNews>>([]);
  const workspaceId = workspace?.id;
  //const [attributes, setAttributes] = useState<Array<any>>([]);

  const [state] = useReducer(reducer, initialState);

  useEffect(() => {
    if (workspace?.id) {
      if (!shops.length || !restrictedAreas.length) {
        setLoading({ loading: true });
        const functionalityQuery = query(
          collection(db, 'functionalities'),
          where('workspaceId', '==', workspaceId)
        );
        onSnapshot(functionalityQuery, (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.doc.data().type === 'shop') {
              const shop: ShopForm = change.doc.data() as ShopForm;
              if (change.type === FIREBASECHANGEEVENTS.ADDED) {
                dispatch(updateShops(shop));
              }
              if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
                dispatch(removeShop(shop.id));
              }
            }
            if (change.doc.data().type === 'restricted_area') {
              const restrictedArea: RestrictedAreaForm = change.doc.data() as RestrictedAreaForm;
              if (change.type === FIREBASECHANGEEVENTS.ADDED) {
                dispatch(updateRestrictedAreas(restrictedArea));
              }
              if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
                dispatch(removeRestrictedArea(restrictedArea.id));
              }
            }
          });
        });
        setLoading({ loading: false });
      }
      if (!news.length) {
        setLoading({ loading: true });

        const newsQuery = query(collection(db, 'news'), where('workspaceId', '==', workspaceId)); //

        onSnapshot(newsQuery, (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.doc.data().type === 'news') {
              const news: NewsForm = change.doc.data() as NewsForm;
              if (change.type === FIREBASECHANGEEVENTS.ADDED) {
                dispatch(updateNews(news));
              }
              if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
                dispatch(removeShop(news.id));
              }
            }
          });
        });
        setLoading({ loading: false });
      }
      if (!products.length) {
        setLoading({ loading: true });

        const productQuery = query(
          collection(db, 'products'),
          where('workspaceId', '==', workspaceId)
        );
        onSnapshot(productQuery, (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.doc.data().type === 'product') {
              const news: ProductForm = change.doc.data() as ProductForm;
              if (change.type === FIREBASECHANGEEVENTS.ADDED) {
                dispatch(updateProduct(news));
              }
              if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
                dispatch(removeProduct(news.id));
              }
            }
          });
        });
        setLoading({ loading: false });
      }
    }
  }, [workspace?.id]);

  return (
    <>
      <InnerPage>
        <div className="mt-12 flex h-[67px] w-full flex-row  justify-between ">
          <div className="flex h-full w-2/3 flex-col p-2">
            <Breadcrumbs />
            <Typography size="body-medium-30">{t('collection.title_functionality')}</Typography>
          </div>

          <div className="flex h-full w-1/3 flex-row gap-2 p-2">
            {workspace?.permissions?.token_creator?.write && (
              <Button
                type="secondary"
                id="createFunctionality"
                action={handleOpenNewFunctionality}
                className="px-4"
              >
                {t('collection.create_functionality')}
              </Button>
            )}
          </div>
        </div>
        <div className="mt-12 space-y-4">
          <ShopList shop={shops} isLoading={isLoading} filtersState={state} orderBy={orderBy} />
        </div>
        <div className="mt-12 space-y-4">
          <RestrictedAreaList
            restrictedArea={restrictedAreas}
            isLoading={isLoading}
            filtersState={state}
            orderBy={orderBy}
          />
        </div>
        <div className="mt-12 space-y-4">
          <PollsList
            polls={pollsList}
            isLoading={isLoading}
            filtersState={state}
            orderBy={orderBy}
          />
        </div>
        {/* TODO: uncomment news and product buttons */}

        <div className="mt-12 space-y-4">
          <AttributesList
            attributes={products}
            isLoading={isLoading}
            filtersState={state}
            orderBy={orderBy}
          />
        </div>
        <div className="mt-12 space-y-4">
          <NewsList news={news} isLoading={isLoading} filtersState={state} orderBy={orderBy} />
        </div>
      </InnerPage>
    </>
  );
};

export default Functionality;
