import { useFirestoreDocument } from '@react-query-firebase/firestore';
import { doc } from 'firebase/firestore';
import React, { ReactNode, useEffect, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Contract, Nft, NftAttribute } from '../../imports/types';

import { ImageBilling, ImagePlaceholder } from '../../../../assets/images';

import { Button, Image, SectionTitle, ShowMore } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';

import { db } from '../../../../imports/firebase';
import { useAppSelector } from '../../../../redux/hooks';
import { EMPTY_NFT } from '../../imports/constants';
import NftComponent from './NftComponent';

interface IAddCategoriesStep
  extends Omit<
    ExportedProps,
    'fields' | 'trigger' | 'reset' | 'getValues' | 'isValid' | 'dirtyFields' | 'clearErrors'
  > {
  isLoading: boolean;
  action: () => void;
  toggleIsBillingOverlayOpen?: () => void;
  onBack: () => void;
  values: any;
  title: string;
  ctaLabel: string;
  topBarElements?: (nft: Nft) => ReactNode;
  dirtyFields: any;
  fields: any;
  contract: Contract | undefined;
}
const AddCategoriesStep: React.FC<IAddCategoriesStep> = ({
  errors,
  handleSubmit,
  resetField,
  control,
  register,
  setValue,
  isLoading,
  title,
  action: handleSaveContract,
  toggleIsBillingOverlayOpen,
  onBack,
  values,
  ctaLabel,
  topBarElements,
  dirtyFields,
  fields,
  watch,
  contract,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const [howManyNotarizations, setHowManyNotarizations] = useState(0);

  const { nfts, doesExpire } = values;
  const valuesForNftComponent = {
    ...values,
    doesTransfer: contract?.transfer !== 'none',
    chain: contract?.chain,
    maxTokensPerUser: contract?.maxTokensPerUser,
    expiration: contract?.expiration,
    hasGame: contract?.hasGame,
    dropDate: contract?.dropDate,
    numberOfCategories: contract?.numberOfCategories,
  };

  const { data: quantityModifiable } = useFirestoreDocument(
    ['template', contract?.templateId],
    doc(db, 'templates', contract?.templateId || ''),
    {},
    {
      select(snapshot) {
        const template = snapshot.exists() ? snapshot.data() : null;

        return (
          (contract?.addCategory && template?.type === 'standard') ||
          contract?.templateId === 'standard'
        );
      },
    }
  );

  useEffect(() => {
    let howMany: number = 0;
    nfts.forEach((nft: Nft) => {
      let partialHowMany = 0;
      if (nft.attributes) {
        nft.attributes.forEach((attribute: NftAttribute) => {
          if (attribute.type === 'notarizations') {
            partialHowMany += 1;
          }
        });
      }
      if (partialHowMany > howMany) {
        howMany = partialHowMany;
      }
    });
    setHowManyNotarizations(howMany);
  }, [values]);

  const addCategory = () => {
    const newNfts = nfts;
    newNfts.push(EMPTY_NFT);
    setValue('nfts', newNfts);
    setValue('numberOfCategories', (contract?.nfts?.length || 0) + 1);
  };

  return (
    <>
      <SectionTitle title={title} onBack={onBack}>
        {toggleIsBillingOverlayOpen && (
          <button type="button" onClick={toggleIsBillingOverlayOpen}>
            <Image src={ImageBilling} alt={t('billing')} className="h-10 w-10" />
          </button>
        )}
      </SectionTitle>
      <ShowMore localMax={10} data={nfts} buttonLabel={t('show_more')}>
        {(nft, index) => {
          const { id, ...fieldsToRegister } = nft as {
            id: string;
            name: string;
            description: string;
            quantity: number;
            external_url: string;
            image: File[];
            isUnlimited: boolean;
            attributes: any[];
          };
          const nftFields: { [key: string]: UseFormRegisterReturn } = Object.keys(
            fieldsToRegister
          ).reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue]: register(`nfts[${index}].${currentValue}`), //{valueAsNumber: currentValue==='quantity'}),
            }),
            {}
          );

          const img = nfts[index].image ? nfts[index].image[0]?.preview : ImagePlaceholder;

          return (
            <div className="mt-10" key={id + index} id={id}>
              <SectionTitle
                as="h3"
                title={`${t('create_contract.form_labels.category')} ${index + 1}`}
                size="md"
              >
                {topBarElements?.(nft as Nft)}
              </SectionTitle>
              {/* <div className="flex justify-between mt-8"> */}
              <div className="mx-auto mt-10 w-[80%]" key={index}>
                <NftComponent
                  values={valuesForNftComponent}
                  nft={nft}
                  index={index}
                  quantityModifiable={contract?.templateId === 'standard' || quantityModifiable}
                  errors={errors}
                  resetField={resetField}
                  register={register}
                  setValue={setValue}
                  control={control}
                  key={index}
                  dirtyFields={dirtyFields}
                  fields={fields}
                  watch={watch}
                  doesExpire={doesExpire}
                />
              </div>
            </div>
          );
        }}
      </ShowMore>
      <div className="mt-20 flex flex-col items-center justify-center gap-4">
        <Button className="w-[17rem]" action={addCategory} type="secondary">
          {t(`create_contract.form_labels.add_category`)}
        </Button>
        <Button
          id="start-creation"
          className="w-[17rem]"
          action={handleSubmit(handleSaveContract)}
          disabled={isLoading || howManyNotarizations > 1}
        >
          {ctaLabel}
        </Button>
      </div>
    </>
  );
};

export default AddCategoriesStep;
