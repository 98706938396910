import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { InputType } from '../../imports/types';

import { IconCheck, IconWarning } from '../../icons';

import Icon from '../icon/Icon';

import './checkbox.css';

type CheckboxProps = InputType & {
  deny?: boolean;
  onClick?: () => void;
  isChecked?: boolean;
  type?: 'primary' | 'secondary';
  size?: string;
};

const Checkbox = ({
  id,
  name,
  disabled,
  error,
  deny,
  success,
  onBlur,
  onChange,
  inputRef,
  onClick,
  isChecked,
  size,
  type = 'primary',
}: CheckboxProps) => {
  const [checked, setChecked] = useState(isChecked);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const getCheckboxStatus = () => {
    let status = '';

    if (error || deny) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  return (
    <div className={`checkbox-container  ${getCheckboxStatus()}`}>
      {deny ? (
        <>
          <input id={id} className="checkbox !bg-grey-50 align-middle" type="checkbox" checked />
          <Icon icon={IconWarning} />
        </>
      ) : (
        <>
          <input
            id={id}
            name={name}
            type="checkbox"
            className={`checkbox  ${
              type === 'secondary' ? `${checked && '!bg-primary-500'} !border-primary-500` : ''
            } !w-[${size}px] !h-[${size}px]`}
            checked={isChecked}
            onBlur={onBlur}
            onChange={handleCheckboxChange}
            ref={inputRef}
            disabled={disabled}
            onClick={onClick}
          />
          <Icon
            icon={IconCheck}
            stroke={
              checked
                ? disabled
                  ? 'grey-200'
                  : `${type === 'secondary' ? 'white' : 'primary-500'}`
                : 'white'
            }
            size="19"
          />
        </>
      )}
    </div>
  );
};

export default Checkbox;
