import type { Routes } from './types';

import {
  ImageAccount,
  ImageAuditDB,
  ImageCustom,
  ImageNft,
  ImageNotarization,
  ImageShare,
} from '../assets/images';

import routesTeam from '../modules/team/imports/routes';
import { JoinTeam } from '../modules/team/pages';
import {
  Api,
  EasterEgg,
  Error404,
  FAQPage,
  Feedback,
  ForgotPassword,
  Home,
  Profile,
  Redirect,
  ResetPassword,
  SendVerificationEmail,
  SignIn,
  SignUp,
  VerifyEmail,
} from '../pages';

import { Audit, Notarizations, Team, TokenCreator } from '../modules';

import { IconMail } from '../icons';
import routesAudit from '../modules/audit/imports/routes';
import notarizationRoutes from '../modules/notarizations/imports/routes';
import routesTokenCreator from '../modules/tokenCreator/imports/routes';
import { Messages } from '../modules/tokenCreator/pages';
import { currentEnv, environments } from './constants';

const isProd = currentEnv === environments.PROD;

const routes: Routes[] = [
  {
    name: 'home',
    path: '/',
    component: Home,
    // hasSidebar: false,
    hasTopbar: true,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    // hasSidebar: false,
    hasTopbar: false,
    hasntFooter: true,
  },
  {
    name: 'redirect',
    path: '/redirect',
    component: Redirect,
    // hasSidebar: false,
    hasTopbar: false,
  },
  {
    name: 'verifyEmail',
    path: '/redirect/verifyEmail',
    component: VerifyEmail,
    // hasSidebar: false,
    hasTopbar: false,
  },
  {
    name: 'join_team',
    path: '/join-team',
    component: JoinTeam,
    // hasSidebar: false,
    hasTopbar: false,
  },

  {
    name: 'resetPassword',
    path: '/redirect/resetPassword',
    component: ResetPassword,
    // hasSidebar: false,
    hasTopbar: false,
    hasntFooter: true,
  },
  {
    name: 'easterEgg',
    path: '/easterEgg',
    component: EasterEgg,
    // hasSidebar: false,
    hasTopbar: true,
    restrictedTo: ['guest', 'consumer'],
    redirect: '/sign-in',
  },
  {
    name: 'sign_in',
    path: '/sign-in',
    component: SignIn,
    restrictedTo: ['guest', 'taker'],
    redirect: '/',
    // hasSidebar: true,
    hasTopbar: false,
  },
  {
    name: 'sign_up',
    path: '/sign-up',
    component: SignUp,
    restrictedTo: ['guest'],
    redirect: '/',
    // hasSidebar: false,
    hasTopbar: false,
    hasntFooter: true,
  },
  {
    name: 'verify_email',
    path: '/verify-email',
    component: SendVerificationEmail,
    restrictedTo: ['unverified'],
    // hasSidebar: false,
    hasTopbar: false,
    redirectByRole: {
      consumer: '/',
      guest: '/sign-in',
      taker: '/sign-in',
    },
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    restrictedTo: ['consumer'],
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageAccount,
    redirect: '/sign-in',
  },
  {
    name: 'verify-pec',
    path: '/profile/?verify-pec=1',
    component: Profile,
    // hasSidebar: false,
    hasTopbar: true,
  },
  {
    name: 'api',
    path: '/api',
    component: Api,
    restrictedTo: ['consumer'],
    // hasSidebar: false,
    hasTopbar: true,
    redirect: '/sign-in',
  },
  {
    name: 'notarization',
    path: '/notarizations',
    component: Notarizations,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageNotarization,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    childRoutes: notarizationRoutes,
    requiresPermission: {
      moduleName: 'notarization',
      permissions: ['read', 'write'],
    },
  },
  {
    name: 'nft',
    path: '/nft',
    component: TokenCreator,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageNft,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    childRoutes: routesTokenCreator,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['read', 'write'],
    },
  },
  {
    name: 'audit_db',
    path: '/audit-db',
    component: Audit,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageAuditDB,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    childRoutes: routesAudit,
    requiresPermission: {
      moduleName: 'audit',
      permissions: ['read', 'write'],
    },
  },
  {
    name: 'team',
    path: '/team',
    component: Team,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageAuditDB,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    childRoutes: routesTeam,
    requiresPermission: {
      moduleName: 'team',
      permissions: ['read', 'write'],
    },
  },
  {
    name: 'share',
    path: '/share',
    component: Home,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageShare,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
  },
  {
    name: 'custom',
    path: '/custom',
    component: Home,
    // hasSidebar: false,
    hasTopbar: true,
    sidebarIcon: ImageCustom,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
  },
  {
    name: 'feedback',
    path: '/feedback',
    // hasSidebar: false,
    hasTopbar: true,
    component: Feedback,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
  },
  // {
  //   name: 'not-found',
  //   path: '*',
  //   // hasSidebar: false,
  //   hasTopbar: false,
  //   component: NotFound,
  //   restrictedTo: ['guest', 'consumer'],
  // },
  {
    name: 'messages',
    path: '/messages',
    component: Messages,
    topbarIcon: IconMail,
    hasTopbar: true,
    // hasSidebar: true,
    requiresPermission: {
      moduleName: 'token_creator',
      permissions: ['write'],
    },
  },
  {
    name: 'not-found',
    path: '*',
    component: Error404,
    // hasSidebar: false,
    hasTopbar: false,
    hasntFooter: true,
  },
  {
    name: 'faq',
    path: '/faq',
    component: FAQPage,
    hasTopbar: false,
  },
];

if (!isProd) {
  // only prod routes
}

export default routes;
