import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, ItemOption, Typography } from '..';
import { IconFolderNft, IconInfo, IconOptions } from '../../icons';
import { truncateText } from '../../imports/utils';

type FunctionalityCardProps = {
  functionality: any;
  action?: ReactNode;
  onClick?: () => void;
  hasWritePermission?: boolean;
};

const FunctionalityCard = ({
  functionality,
  action,
  onClick,
  hasWritePermission,
}: FunctionalityCardProps) => {
  const { t } = useTranslation(['notarization', 'tokenCreator', 'audit']);
  const [isHover, setIsHover] = useState<'default' | 'selected' | 'hover'>('default');
  const [openMenu, setOpenMenu] = useState(false);
  // const { open: openModalDeleteFolder } = useModal('DeleteFolderModal');
  // const handleOpenDeleteFolder = () => {
  //   openModalDeleteFolder({ folder, folderType: type });
  // };

  return (
    <>
      <div
        onMouseEnter={() => setIsHover('hover')}
        onMouseLeave={() => setIsHover('default')}
        className={`flex w-[173px] flex-col rounded-lg border ${
          isHover === 'hover' && 'border-primary-500'
        }  shadow-md `}
        // onClick={onClick}
      >
        <div
          className={` flex h-[37px] w-full cursor-pointer flex-row items-center justify-start space-x-4 rounded-t-lg border-b px-4  ${
            isHover === 'hover' && 'overflow-hidden truncate border-b-primary-500 bg-primary-100'
          }`}
          onClick={onClick}
        >
          <Icon icon={IconFolderNft} size="md" />

          <Typography size="body-medium-12">
            {truncateText(functionality.title ? functionality.title : functionality.type, {
              maxLength: 20,
              rightChars: 0,
            })}
          </Typography>
        </div>

        <div className="relative flex h-[27px] w-full flex-row items-center justify-between rounded-b-lg px-4 ">
          <div className="absolute bottom-0 right-0 ">
            <div
              onClick={() => setOpenMenu(!openMenu)}
              className=" relative flex h-[27px] w-[27px] cursor-pointer items-center justify-between"
            >
              <Icon icon={IconOptions} size="md" />
              <div
                onMouseEnter={() => setOpenMenu(true)}
                onMouseLeave={() => setOpenMenu(false)}
                className={`absolute top-0 left-0 z-10 mt-7 flex w-[210px] flex-col rounded-lg border border-primary-500 bg-white ${
                  openMenu ? 'block' : 'hidden'
                }`}
              >
                <div className="flex flex-col">
                  <ItemOption
                    id={`info_folder-${functionality.id}`}
                    name={t('collection.info_folder')}
                    icon={IconInfo}
                    onClick={onClick}
                  />
                  {/* <ItemOption
                    id={`editFolder-${folder.id}`}
                    name={t('collection.edit_folder')}
                    icon={IconEdit}
                  /> */}
                  {/* <ItemOption
                    id={`share_folder-${folder.id}`}
                    name={t('collection.share_folder')}
                    icon={IconShare}
                  /> */}
                  {/* {hasWritePermission && (
                    <ItemOption
                      id={`delete_folder-${functinality.id}`}
                      name={t('collection.delete_folder')}
                      color="error"
                      onClick={handleOpenDeleteFolder}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FunctionalityCard;
