import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { IconCopy } from '../../icons';
import { copyToClipboard, formatDate } from '../../imports/utils';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};
const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="m-2 flex h-[51px] flex-col">
      <Typography>{label.toUpperCase()}</Typography>
      <div className="flex flex-row items-center justify-start gap-2">{children}</div>
    </div>
  );
};

type FunctionalityDetailsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { functionality } = useModalProps('FunctionalityDetailsModal');
  const { t } = useTranslation(['tokenCreator']);
  const handleCopyToClipboard = (value: any) => {
    copyToClipboard(value);
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="w-[540px] min-h-[300px]"
      // classNameLayout="w-[540px] h-[470px]"
    >
      <div className="flex size-full flex-col ">
        <div className="grid h-full grid-cols-2 content-center p-4">
          <div className="col-span-2 mx-2 py-2">
            <Typography size="body-medium-30" color="primary-500">
              {functionality.type.toUpperCase()}
            </Typography>
          </div>
          <InfoSection label={t('link') as string}>
            <div className="flex items-center justify-center">
              {functionality.link}
              <div className="w-min">
                <Button
                  icon={IconCopy}
                  type="ghost"
                  action={() => handleCopyToClipboard(functionality.link)}
                />
              </div>
            </div>
          </InfoSection>

          {functionality.createdAt && (
            <InfoSection label={t('created_at') as string}>
              {formatDate(functionality.createdAt)}
            </InfoSection>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

const FunctionalityDetailsModal = ({ children }: FunctionalityDetailsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="FunctionalityDetailsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default FunctionalityDetailsModal;
