import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { BasicCard } from '..';
import { Contract, Template } from '../../modules/tokenCreator/imports/types';
import { CollectionBottomCard, CollectionTopCard } from './index';
import { db } from '../../imports/firebase';
import { logOutTemplate, setTemplate } from '../../redux/templates/templates.slice';
import { useAppDispatch } from '../../redux/hooks';

type CollectionCardProps = {
  item: Contract;
  folders?: any;

  hasWritePermission?: boolean;
  isAnalytics?: boolean;
};
const CollectionCard = ({
  item,
  hasWritePermission,
  folders,
  isAnalytics,
}: CollectionCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSetTemplate = async () => {
    const templatesQuery = query(collection(db, 'templates'), where('id', '==', item?.templateId));

    const querySnapshot = await getDocs(templatesQuery);
    const template = querySnapshot?.docs[0]?.data() as Template;

    if (!template) {
      dispatch(logOutTemplate());
    } else {
      dispatch(setTemplate(template));
    }
    navigate(isAnalytics ? `/nft/analytics/${item.id}` : `/nft/collection/${item.id}`);
  };

  return (
    <BasicCard
      top={
        <CollectionTopCard
          item={item}
          hasWritePermission={hasWritePermission}
          isAnalytics={isAnalytics}
          folders={folders}
        />
      }
      bottom={<CollectionBottomCard item={item} />}
      onClick={handleSetTemplate}
      className="cursor-pointer"
    />
  );
};
export default CollectionCard;
