import { SVGProps } from 'react';

const IconSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38755)">
      <path
        d="M130.5 64.5L162 96L130.5 127.5"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 96H162"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 162H36C34.4087 162 32.8826 161.368 31.7574 160.243C30.6321 159.117 30 157.591 30 156V36C30 34.4087 30.6321 32.8826 31.7574 31.7574C32.8826 30.6321 34.4087 30 36 30H78"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38755">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconSignOut;
