import { ethers } from 'ethers';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getSubscription, getUserAuthToken } from '../../api/firebase';
import { useLoadingStatusContext } from '../../context';
import { useValidation } from '../../hooks';
import useWorkspaceAndGroup from '../../hooks/useWorkspaceAndGroup';
import { IconClose } from '../../icons';
import { getRemoteConfigValue } from '../../imports/remoteConfig';
import { calculateFileHash, signMessage } from '../../imports/utils';
import { addNotarizationToFolder, createNotarization } from '../../modules/notarizations/api';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Field from '../field/Field';
import FileUploader from '../fileUploader/FileUploader';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import SelectDropdown from '../selectDropdown/SelectDropdown';
import TagInput from '../tagInput/TagInput';
import CustomTooltip from '../tooltips/CustomTooltip';
import Typography from '../typography/Typography';

type NewNotarizationModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { folders } = useModalProps('NewNotarizationModal');
  const { t } = useTranslation(['notarization']);

  const { dispatch: loaderDispatch } = useLoadingStatusContext();
  const profile = useAppSelector((state) => state.user);
  const { required, validateFilePresence } = useValidation();

  const { workspace } = useAppSelector((state) => state.team);

  const {
    workspaceGroupObject: { workspaceId, groupId, inGroup },
  } = useWorkspaceAndGroup();
  const targetOwnerUid = workspace?.owner ?? profile.uid;

  const form = {
    initialValues: {
      fileList: null,
      name: '',
      description: '',
      notes: '',
      tags: [],
      storage: 'nowhere',
      password: '',
      folderId: '',
    },
    validationSchema: yup.object({
      fileList: required(yup.array().of(validateFilePresence())),
      name: required(yup.string()),
      storage: required(yup.string()),
      tags: yup.array().of(yup.string()).max(5),
    }),
  };

  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const { initialValues, validationSchema } = form;

  const handleAddNotarization = async (values: typeof initialValues) => {
    const { fileList, name, description, notes, tags, storage, folderId } = values;

    try {
      loaderDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('create_notarization.actions.pending'),
        },
      });
      const { value: authTokenId } = await getUserAuthToken();
      const userWallet = new ethers.Wallet(profile.wallet.privateKey);

      const file = fileList?.[0];

      if (!file) {
        loaderDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('create_notarization.actions.failed'),
          },
        });
        return;
      }

      const generatedHash = await calculateFileHash(file, (generatedhash: string) => generatedhash);
      const fileHash = `0x${generatedHash}`;

      const formData = new FormData();

      if (workspace == null) {
        throw new Error('Workspace not set');
      }

      formData.append('senderId', profile.uid);
      formData.append('ownerId', workspace.owner);
      formData.append('ownerAddress', workspace?.ownerAddress);
      formData.append('senderAddress', profile.wallet.address);
      formData.append('workspaceId', workspaceId ?? '');
      if (inGroup && groupId) {
        formData.append('groupId', groupId);
      }

      formData.append('hash', fileHash);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('notes', notes);
      formData.append('tags', JSON.stringify(tags));
      formData.append('signature', await signMessage(fileHash, userWallet));
      formData.append('folderId', folderId);

      /*  if (storage === 'ipfs') {
        await uploadFileToIpfs(file)
          .then((res) => {
            formData.append('ipfsUri', res.value);
          })
          .catch(() => {
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                title: t('create_notarization.actions.failed'),
              },
            });
            delayed(() => {
              loadingStatusDispatch({ type: 'SET_HIDE' });
            }, 2500);
          });
      } */
      if (storage === 'ipfs') {
        formData.append('storageType', 'ipfs');
        formData.append('file', file);
      } else if (storage === 'storage') {
        formData.append('storageType', 'storage');
        formData.append('file', file);
      } else {
        formData.append('storageType', 'nowhere');
      }

      const { value, error } = await createNotarization(formData, authTokenId);

      if (error) {
        loaderDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('create_notarization.modal.wait'),
          },
        });
      }

      if (value) {
        if (folderId) {
          await addNotarizationToFolder(folderId, value.id)
            .then(() => {
              loaderDispatch({
                type: 'SET_SUCCESS',
                payload: {
                  title: t('create_notarization.modal.success'),
                },
              });
            })
            .catch(() => {
              loaderDispatch({
                type: 'SET_ERROR',
                payload: {
                  message: t('create_notarization.modal.error'),
                },
              });
            });
        } else {
          loaderDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('create_notarization.modal.success'),
            },
          });
        }
      }
    } catch (error) {
      loaderDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('create_notarization.modal.error'),
        },
      });
    }
  };

  const handleCheckSubscription = async () => {
    const { value, error } = await getSubscription(targetOwnerUid);
    if (error) {
      loaderDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('create_notarization.modal.error'),
        },
      });
    }

    if (value) {
      const { expDate, currentNotarizations, maxNotarizations } = value;
      const expDateToDate = new Date(expDate.seconds * 1000 + expDate.nanoseconds / 1000000);

      if (expDateToDate <= new Date()) {
        toast.error(t('subscription_toasts.subscription_expired') as string);
        setOpen(false);

        return;
      }

      if (currentNotarizations >= maxNotarizations) {
        toast.error(t('subscription_toasts.notarizations_limit_reached') as string);
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (profile) {
      handleCheckSubscription();
    }
  }, []);

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      // width="1000px"
      // height="720px"
    >
      <div data-cy="addNotarization">
        <div className="flex size-full flex-col px-[20px] pt-[80px]">
          <Typography color="primary-500" size="body-medium-30">
            {t('create_notarization.title')}
          </Typography>

          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="mt-4 flex h-full w-full flex-col items-center "
          >
            {({ fields, handleSubmit, errors, resetField, watch, setValue, isValid }) => {
              const [showDisclaimer, setShowDisclaimer] = useState(false);
              const ipfs = watch('ipfs');
              const storage = watch('storage');

              const foldersMap = folders.map((folder: { id: any; name: any }) => ({
                value: folder.id,
                label: folder.name,
              }));
              const foldersOptions = [
                { value: 'none', label: t('create_notarization.form_placeholders.no_folder') },
                ...foldersMap,
              ];
              const values = watch();

              const storageOptions = [
                {
                  value: 'nowhere',
                  label: t('create_notarization.form_placeholders.upload_to_nowhere'),
                },
                {
                  value: 'storage',
                  label: t('create_notarization.form_placeholders.upload_to_storage'),
                },

                {
                  value: 'ipfs',
                  label: t('create_notarization.form_placeholders.upload_to_ipfs'),
                },
              ];

              const handleShowDisclaimer = () => {
                toast.warn(t('create_notarization.disclaimer') as string, {
                  autoClose: 10000,
                  pauseOnHover: true,
                });
              };

              useEffect(() => {
                if (!showDisclaimer && ipfs) {
                  setShowDisclaimer(true);
                }
              }, [ipfs]);

              useEffect(() => {
                if (showDisclaimer) {
                  handleShowDisclaimer();
                }
              }, [showDisclaimer]);

              return (
                <div className="mt-[20px] grid h-max w-full grid-cols-2 content-center gap-6">
                  <Field error={errors.fileList?.message} className="row-span-2 p-0">
                    <FileUploader
                      label={t('create_notarization.form_placeholders.drag_file')}
                      onChange={(files) => {
                        if (files.length) {
                          setValue(fields.fileList.name, files, {
                            shouldValidate: true,
                          });
                        } else {
                          resetField(fields.fileList.name);
                        }
                      }}
                      id={fields.fileList.name}
                      accept={allowedFileType}
                      name={fields.fileList.name}
                      inputOnBlur={fields.fileList.onBlur}
                      inputRef={fields.fileList.ref}
                      unlimitedSize={storage === 'nowhere'}
                      checkStorage
                    />
                  </Field>

                  <Field
                    error={errors.name?.message}
                    label={t('create_notarization.name')}
                    asterisk
                    className="mt-3"
                  >
                    <Input
                      type="text"
                      placeholder={t('create_notarization.form_placeholders.name')}
                      id={fields.name.name}
                      name={fields.name.name}
                      onBlur={fields.name.onBlur}
                      onChange={fields.name.onChange}
                      inputRef={fields.name.ref}
                      error={errors.name?.message}
                      elementRight={
                        values.name && (
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.name.name)}
                          />
                        )
                      }
                      className="mt-[5px]"
                    />
                  </Field>

                  <Field
                    error={errors.description?.message}
                    label={t('create_notarization.description')}
                    asterisk
                  >
                    <Input
                      type="text"
                      placeholder={t('create_notarization.form_placeholders.description')}
                      id={fields.description.name}
                      name={fields.description.name}
                      onBlur={fields.description.onBlur}
                      onChange={fields.description.onChange}
                      inputRef={fields.description.ref}
                      error={errors.description?.message}
                      elementRight={
                        values.description && (
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.description.name)}
                          />
                        )
                      }
                      className="mt-[5px]"
                    />
                  </Field>

                  {/* save file to storage SELECT */}
                  <div className="flex w-full flex-col justify-between">
                    <div className="flex items-center">
                      <Typography>
                        {t('create_notarization.form_placeholders.save_file_to_storage')}
                      </Typography>
                      <CustomTooltip>
                        <Typography size="xs">
                          {t('create_notarization.tooltips.save_file_to_storage')}
                        </Typography>
                        <Typography size="xs">
                          {t('create_notarization.tooltips.upload_to_storage')}
                        </Typography>
                        <Typography size="xs">
                          {t('create_notarization.tooltips.upload_to_ipfs')}
                        </Typography>
                      </CustomTooltip>
                    </div>
                    <Field error={errors.storage?.message}>
                      <SelectDropdown
                        id={fields.storage.name}
                        items={storageOptions}
                        onChange={(options) => setValue('storage', options)}
                        name={fields.storage.name}
                        className={`mt-[5px] ${values.storage ? '!text-grey-500' : ''}`}
                        placeholder={t('create_notarization.form_placeholders.upload_to_nowhere')}
                        error={errors.storage?.message}
                      />
                    </Field>
                  </div>

                  <Field
                    error={errors.notes?.message}
                    label={t('create_notarization.notes')}
                    info
                    infoText={t('create_notarization.tooltips.notes')}
                  >
                    <Input
                      type="text"
                      placeholder={t('create_notarization.form_placeholders.notes')}
                      id={fields.notes.name}
                      name={fields.notes.name}
                      onBlur={fields.notes.onBlur}
                      onChange={fields.notes.onChange}
                      inputRef={fields.notes.ref}
                      error={errors.notes?.message}
                      elementRight={
                        values.notes && (
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.notes.name)}
                          />
                        )
                      }
                      className="mt-[5px]"
                    />
                  </Field>

                  <Field
                    error={errors.folderId?.message}
                    label={t('create_notarization.form_placeholders.folder')}
                  >
                    {folders.length === 0 ? (
                      <Typography className="mt-[5px]">
                        {t('create_notarization.no_folders')}
                      </Typography>
                    ) : (
                      <SelectDropdown
                        id={fields.folderId.name}
                        items={foldersOptions}
                        onChange={(options) => {
                          if (options === 'none') {
                            resetField(fields.folderId.name);
                          } else {
                            setValue('folderId', options);
                          }
                        }}
                        name={fields.folderId.name}
                        className={`mt-[5px] ${values.folderId ? '!text-grey-500' : ''}`}
                        placeholder={t('create_notarization.form_placeholders.select_folder')}
                        error={errors.folderId?.message}
                      />
                    )}
                  </Field>

                  <Field error={errors.tags?.message} label={t('create_notarization.tags')}>
                    <TagInput
                      type="text"
                      placeholder={t('create_notarization.form_placeholders.tags')}
                      id={fields.tags.name}
                      name={fields.tags.name}
                      onBlur={fields.tags.onBlur}
                      onTagsChange={(allTags) => setValue(fields.tags.name, allTags)}
                      error={errors.tags?.message}
                      className="mt-[5px]"
                      maxTags={5}
                    />
                  </Field>
                  <div className="col-span-2 mt-[60px] flex flex-row items-center justify-end  gap-4">
                    <Button
                      id="notarize"
                      disabled={!isValid}
                      type="primary"
                      action={() => handleSubmit(handleAddNotarization)()}
                      className="px-6 "
                    >
                      {t('create_notarization.submit_data')}
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>

          {/* </div> */}
        </div>
      </div>
    </ModalLayout>
  );
};

const NewNotarizationModal = ({ children }: NewNotarizationModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="NewNotarizationModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default NewNotarizationModal;
