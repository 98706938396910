import React from 'react';
import { Card, Typography } from '../../../../components';
import { BLURRED_EMAIL } from '../../../../imports/constants';

interface IUserCard {
  email: string | null;
  leftImage: string;
  onClick: () => void;
  name?: string;
}

const UserCard: React.FC<IUserCard> = (props) => {
  const { email, leftImage, onClick, name } = props;
  const namesplit = name?.split(' ');
  let nameInitials = 'AA';
  if (namesplit) {
    nameInitials = `${namesplit[0][0]}${namesplit[1] ? namesplit[1][0] : ''}`.toUpperCase();
  }

  return (
    <Card
      title=""
      image={nameInitials}
      onClick={onClick}
      imageAlt="user"
      // action={<Button type="ghost" action={() => {}} icon={IconOptions} />} // use it if we need to add an action button
    >
      <div className="flex flex-col ">
        <Typography size="body-medium-12">{name}</Typography>
        <div className="flex w-full items-center space-x-[12px]">
          {email === null ? (
            <div
              className="select-none"
              style={{ color: 'grey-50', textShadow: '0 0 5px rgba(0,0,0,0.5)' }}
            >
              <Typography size="body-regular-10" color="grey-500">
                {BLURRED_EMAIL}
              </Typography>
            </div>
          ) : (
            <Typography size="body-regular-10" color="grey-500">
              {email}
            </Typography>
          )}
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
