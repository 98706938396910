import Icon from '../icon/Icon';
import LinkText from '../linkText/LinkText';
import Typography from '../typography/Typography';

type ItemMenuBarProps = {
  id: string | number;
  icon?: any;
  name: string;
  linkTo?: string;
  onClick?: () => void;
};

const ItemMenuBar = ({ id, icon, name, onClick, linkTo }: ItemMenuBarProps) => {
  return (
    <div
      data-cy={id}
      key={id}
      onClick={onClick}
      className="flex h-[56px] w-full cursor-pointer flex-row items-center justify-start gap-x-4 rounded-lg  px-4 hover:bg-secondary-100"
    >
      {icon && <Icon icon={icon} />}
      {linkTo ? (
        <LinkText
          data-cy={`${id}-link`}
          to={linkTo}
          textColor="black"
          textColorHover="secondary-500"
          textSize="text-body-regular-16"
          noUnderline
        >
          <Typography size="body-regular-16">{name}</Typography>
        </LinkText>
      ) : (
        <Typography color="black" size="body-regular-16" className="hover:text-secondary-500">
          {name}
        </Typography>
      )}
    </div>
  );
};
export default ItemMenuBar;
