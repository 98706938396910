import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { validatePec } from '../../api/axios';
import { getUserAuthToken } from '../../api/firebase';
import { useValidation } from '../../hooks';
import { IconDownload } from '../../icons';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Icon from '../icon/Icon';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import PecValidationDocument from '../pecValidationDocument/PecValidationDocument';
import Typography from '../typography/Typography';

type PecModalProps = {
  children: ReactNode;
};
// const VerifyPec = ({ user, handleCloseSidebar }: VerifyPecProps) => {
//   const { t } = useTranslation();
//   const { required, validateEmail } = useValidation();

//   const [isLoading, setIsLoading] = useState(false);
//   const [step, setStep] = useState(0);
//   const [pec, setPec] = useState('');

//   const form = {
//     initialValues: {
//       email: '',
//     },
//     validationSchema: yup.object({
//       email: required(validateEmail()),
//     }),
//   };

//   const { initialValues, validationSchema } = form;

//   const handleSetStep = (step: string) => {
//     if (step === 'next') {
//       setStep((prev) => prev + 1);
//     }
//     if (step === 'back') {
//       setStep((prev) => prev - 1);
//     }
//   };

//   const handleVerifyPec = async (values: any) => {
//     setIsLoading(true);

//     const { value: authTokenId, error: authTokenError } = await getUserAuthToken();
//     const { error } = await validatePec(values.email, authTokenId);

//     if (error && error.response.status === 511) {
//       setIsLoading(false);
//       toast.error(t('verify_pec.error.already_sent_validation_request') as string);
//       return;
//     }

//     setIsLoading(false);
//     setPec(values.email);
//     handleSetStep('next');
//   };

//   return (
//     <div className="flex flex-col items-center w-full space-y-4">
//       <Image src={ImageEmailVerified} alt={t('image.filehash')} className="w-[50px]" />

//       <Form
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         className="flex flex-col w-full space-y-4"
//       >
//         {({ fields, handleSubmit, errors, resetField }) => (
//           <>
//             <div className="flex flex-col items-center space-y-2 text-center">
//               <Typography weight="medium" size="lg">
//                 {step === 0 ? t('verify_pec.form_title') : t('verify_pec.file_title')}
//               </Typography>

//               {step === 0 ? (
//                 <Field error={errors.email?.message}>
//                   <Input
//                     type="text"
//                     placeholder={t('verify_pec.form_placeholders.email')}
//                     id={fields.email.name}
//                     name={fields.email.name}
//                     onBlur={fields.email.onBlur}
//                     onChange={fields.email.onChange}
//                     inputRef={fields.email.ref}
//                     error={errors.email?.message}
//                     elementRight={
//                       <Button
//                         type="ghost"
//                         icon={IconClose}
//                         action={() => resetField(fields.email.name)}
//                       />
//                     }
//                   />
//                 </Field>
//               ) : (
//                 <div className="!my-8 flex w-full items-center space-x-4">
//                   <div className="shrink-0">
//                     <Image src={ImageFileUpload} alt={t('download')} className="w-8 h-8" />
//                   </div>

//                   <div className="flex items-center text-left grow">
//                     <div className="w-20">
//                       <Typography className="truncate">{user.company}</Typography>
//                     </div>
//                     <Typography>_PEC.pdf</Typography>
//                   </div>

//                   <div className="shrink-0">
//                     <PDFDownloadLink
//                       fileName={`${user.company}_PEC.pdf`}
//                       document={<PecValidationDocument user={user} pec={pec} />}
//                     >
//                       <Tooltip content={t('download')}>
//                         <Button type="ghost" action={handleCloseSidebar}>
//                           <Icon icon={IconDownload} className="!h-4 !w-4" color="secondary-500" />
//                         </Button>
//                       </Tooltip>
//                     </PDFDownloadLink>
//                   </div>
//                 </div>
//               )}
//             </div>

//             {step === 0 && (
//               <Button
//                 type="primary"
//                 action={() => handleSubmit(handleVerifyPec)()}
//                 loading={isLoading}
//               >
//                 {t('verify_pec.next')}
//               </Button>
//             )}
//           </>
//         )}
//       </Form>
//     </div>
//   );
// };

const ModalBody = ({ setOpen }: any) => {
  const { user } = useModalProps('PecModal');

  const { t } = useTranslation();
  const { required, validateEmail } = useValidation();

  const [isLoading, setIsLoading] = useState(false);

  const [pec, setPec] = useState('');

  const form = {
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: required(validateEmail()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleVerifyPec = async (values: any) => {
    setIsLoading(true);

    const { value: authTokenId, error: authTokenError } = await getUserAuthToken();
    const { error } = await validatePec(values.email, authTokenId);

    if (error && error.response.status === 511) {
      setIsLoading(false);
      toast.error(t('verify_pec.error.already_sent_validation_request') as string);
      return;
    }

    setIsLoading(false);
    setPec(values.email);
  };
  return (
    <ModalLayout onClose={() => setOpen(false)} classNameLayout="w-[530px] h-[560px]">
      <div className="flex w-full flex-col items-start justify-start space-y-4 px-[40px] pt-[75px]">
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          className="flex w-full flex-col space-y-4"
        >
          {({ fields, handleSubmit, errors, resetField, watch }) => {
            const values = watch();
            return (
              <>
                <div className="mb-[50px] flex flex-col items-start text-left">
                  <Typography size="body-medium-30" color="primary-500">
                    {t('verify_pec.form_title')} {/* : t('verify_pec.file_title')  */}
                  </Typography>

                  <Field
                    asterisk
                    label={t('verify_pec.insert_address')}
                    error={errors.email?.message}
                    className="mt-[28px]"
                  >
                    <Input
                      type="text"
                      placeholder={t('verify_pec.form_placeholders.email')}
                      id={fields.email.name}
                      name={fields.email.name}
                      onBlur={fields.email.onBlur}
                      onChange={fields.email.onChange}
                      inputRef={fields.email.ref}
                      error={errors.email?.message}
                      className="mt-[5px]"
                    />
                  </Field>

                  <Typography size="body-regular-18" className="mt-[28px]">
                    {t('verify_pec.text_1')}
                    <span className="text-body-semibold-18">{t('verify_pec.text_mail')}</span>
                    {t('verify_pec.text_2')}
                  </Typography>
                  <div className="mt-[38px]">
                    <PDFDownloadLink
                      fileName={`${user.company}_PEC.pdf`}
                      document={<PecValidationDocument user={user} pec={values.email} />}
                    >
                      <Button action={() => {}} type="secondary">
                        <div className="flex items-center gap-[10px]">
                          <Icon icon={IconDownload} stroke="primary-500" size="20" />
                          Scarica il form
                        </div>
                      </Button>
                    </PDFDownloadLink>
                  </div>
                </div>
                <div className="w-full ">
                  <Button
                    type="primary"
                    action={() => handleSubmit(handleVerifyPec)()}
                    loading={isLoading}
                    className="mr-0"
                  >
                    {t('verify_pec.add_pec')}
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </ModalLayout>
  );
};

const PecModal = ({ children }: PecModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal id="PecModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default PecModal;
