/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2189_46606)">
      <path
        d="M24 42H168V144C168 145.591 167.368 147.117 166.243 148.243C165.117 149.368 163.591 150 162 150H30C28.4087 150 26.8826 149.368 25.7574 148.243C24.6321 147.117 24 145.591 24 144V42Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168 42L96 108L24 42"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2189_46606">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconMail;
