import { is } from 'date-fns/locale';
import * as yup from 'yup';

export const form = {
  initialValues: {
    name: '',
    email: '',
    role: {
      token_creator: { read: false, write: false },
      notarization: { read: false, write: false },
      audit: { read: false, write: false },
      team: { read: false, write: false },
      crm: { read: false, write: false },
    },
    groupId: '',
    isGroupAdmin: false,
  },
  validationSchema: yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    groupId: yup.string().optional(),
    role: yup
      .object({
        token_creator: yup.object({
          read: yup.boolean().required(),
          write: yup.boolean().required(),
        }),
        notarization: yup.object({
          read: yup.boolean().required(),
          write: yup.boolean().required(),
        }),
        audit: yup.object({ read: yup.boolean().required(), write: yup.boolean().required() }),
        team: yup.object({ read: yup.boolean().required(), write: yup.boolean().required() }),
        crm: yup.object({ read: yup.boolean().required(), write: yup.boolean().required() }),
      })
      .required(),
    isGroupAdmin: yup.boolean().optional(),
  }),
};
const emptyValidation = yup.object({});
export type FormType = typeof form.initialValues;
