import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { hexColors } from '../../imports/constants';
import { Answer, AnswerForm, Submition } from '../../imports/types';
import ModalLayout from '../layouts/ModalLayout';
import PieChart from '../pieChart/PieChart';
import Typography from '../typography/Typography';

type PollsAnalyticsModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { question, submitions } = useModalProps('PollsAnalyticsModal');
  const { t } = useTranslation(['tokenCreator']);

  const answers: { [key: string]: number } = {};
  const answersName: { [key: string]: string | undefined } = {};
  let totalAnswers: number = 0;

  question.answers.forEach((answer: AnswerForm) => {
    answersName[answer.aId] = answer.answer;
  });

  submitions.forEach((submition: Submition) => {
    const modalQuestion: Answer | undefined = submition.answers.find(
      (subQuestion) => subQuestion.qId === question.qId
    );
    if (modalQuestion) {
      modalQuestion.answers.forEach((answer: { aId: string; value: boolean }) => {
        if (answer.value) {
          totalAnswers += 1;
          const name = answersName[answer.aId];
          if (name) {
            if (Object.keys(answers).includes(name)) {
              answers[name] += 1;
            } else {
              answers[name] = 1;
            }
          }
        }
      });
    }
  });

  const chartColors: string[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Object.keys(answers).length; i++) {
    const stringaRandom = hexColors[Math.floor(Math.random() * hexColors.length)];
    chartColors.push(stringaRandom);
  }
  const chartData = {
    labels: Object.keys(answers),
    datasets: [
      {
        label: '%',
        data: Object.keys(answers).map((ans: string) => {
          return parseFloat(((answers[ans] / totalAnswers) * 100).toFixed(2));
        }),
        backgroundColor: chartColors,
      },
    ],
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="max-h-[95%] overflow-auto max-w-[50%]"
    >
      <div className="flex size-full flex-col gap-3 px-[25px] pb-6 pt-[50px]">
        <div>
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-4">
            {t('poll.label_fields.modal_title')}
          </Typography>
        </div>

        <Typography>{question.question}</Typography>
        <Typography>
          {t('poll.label_fields.total_answers')}: {totalAnswers}
        </Typography>
        <div className="flex gap-6">
          <div className=" max-w-[50%] ">
            {Object.keys(answers).map((answer, index) => {
              return (
                <div className="flex items-center gap-2" key={answer}>
                  <div
                    className="h-4 min-w-[16px]"
                    style={{ backgroundColor: chartColors[index] }}
                  ></div>
                  <Typography size="body-regular-14">{answer}</Typography>
                </div>
              );
            })}
          </div>
          <div className="mx-auto">
            <PieChart chartData={chartData} />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

const PollsAnalyticsModal = ({ children }: PollsAnalyticsModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="PollsAnalyticsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default PollsAnalyticsModal;
