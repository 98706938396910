import { createSlice } from '@reduxjs/toolkit';
import { PollForm, Submition } from '../../imports/types';

export type PollsState = {
  list: PollForm[];
  submitions: {
    [key: string]: Submition[];
  };
  api: { fetch: { loading: boolean } };
};

const initialState: PollsState = {
  list: [],
  submitions: {},
  api: {
    fetch: {
      loading: false,
    },
  },
};

export const pollsSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    updatePoll: (
      state,
      action: {
        payload: PollForm;
        type: string;
      }
    ) => {
      const pollIndex = state.list.findIndex((poll) => poll.id === action.payload.id);
      if (pollIndex !== -1) {
        state.list[pollIndex] = { ...state.list[pollIndex], ...action.payload };
      } else {
        state.list.push(action.payload);
      }
      return state;
    },
    updateSubmitions: (
      state,
      action: {
        payload: { [key: string]: Submition[] };
        type: string;
      }
    ) => {
      const pollId = Object.keys(action.payload)[0];
      const pollIndex = Object.keys(state.submitions).findIndex((id) => id === pollId);
      if (pollIndex !== -1) {
        state.submitions[pollId] = [...state.submitions[pollId], ...action.payload[pollId]];
      } else {
        state.submitions[pollId] = action.payload[pollId];
      }
    },
    removePoll: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.list.filter((poll) => poll.id !== idToRemove);
      state.list = updatedList;
      return state;
    },
    pollsFetching: (
      state,
      action: {
        payload: boolean;
        type: string;
      }
    ) => {
      state = {
        ...state,
        api: {
          ...state.api,
          fetch: {
            loading: action.payload,
          },
        },
      };
      return state;
    },
    logOutPolls: (state: PollsState) => {
      state = initialState;
      return state;
    },
    logOutSubmitions: (state: PollsState) => {
      state.submitions = {};
      return state;
    },
  },
});

export const {
  updatePoll,
  removePoll,
  pollsFetching,
  logOutPolls,
  logOutSubmitions,
  updateSubmitions,
} = pollsSlice.actions;
export default pollsSlice.reducer;
