import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import 'swiper/css';
import 'swiper/css/navigation';
import { Button, InnerPage, SectionTitle, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconArrow, IconEdit, IconLoading, IconTrash } from '../../../../icons';
import { getContractsFromFunctionalities } from '../../../../imports/contractsParsers';
import { db } from '../../../../imports/firebase';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { removeShop } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { deleteFunctionalityDoc, removeFieldFromNft } from '../../api';
import { Contract, FunctionalitiesShop, Nft } from '../../imports/types';

const ShopDetails = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const { id: shopId } = useParams();
  const { workspace } = useAppSelector((state) => state.team);
  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const { open: openDeleteModal } = useModal('DeleteModal');
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [shop, setShop] = useState<FunctionalitiesShop | null>(null);

  const handleDeleteShop = async () => {
    if (workspace) {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });
      if (shop) {
        /* delete the functionality from the nfts */
        const contracts = getContractsFromFunctionalities(shop.availableOn, contractsList);
        const data = await Promise.allSettled(
          contracts
            .map(async (contract) => {
              const fullContract = contractsList.find(
                (fullContract: Contract) => fullContract.address === contract.address
              );
              const updatedContract = cloneDeep(fullContract);

              const promiseContract = contract.nfts.map(async (nft: Nft) => {
                /* update nft.attributes on redux contract */
                if (updatedContract) {
                  const newNfts: Nft[] = [];
                  updatedContract.nfts.forEach((updatedNft: Nft) => {
                    if (nft.id === updatedNft.id) {
                      const newNft = cloneDeep(updatedNft);
                      delete newNft.shop;
                      newNfts.push(newNft);
                    } else {
                      newNfts.push(updatedNft);
                    }
                  });
                  updatedContract.nfts = newNfts;
                }
                const nftPromise = await removeFieldFromNft(contract.id, nft, 'shop');
                return nftPromise;
              });
              /* update redux state */
              if (updatedContract) {
                dispatch(updateContract(updatedContract));
              }
              return promiseContract;
            })
            .flat()
        );

        /* delete the functionality from the functionalities DB*/
        const { error } = await deleteFunctionalityDoc(shop.id);

        /** Check if there are errors in promises */
        let isError = false;
        if (error) {
          isError = true;
        }
        data.forEach((promise: any) => {
          if (promise.status === 'fulfilled' && promise.value.error) {
            isError = true;
          }
        });
        if (isError) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        } else {
          dispatch(removeShop(shop.id));
          navigate(-1);
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('collection.functionality.actions.delete'),
            },
          });
        }
      }
    }
  };

  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: shop });
  };
  const handleDelete = () => {
    openDeleteModal({ handleDelete: handleDeleteShop });
  };
  useEffect(() => {
    const attributesDocument = query(
      collection(db, 'functionalities'),
      where('workspaceId', '==', workspace?.id),
      where('id', '==', shopId)
    );
    const unsubscribeAttributes = onSnapshot(attributesDocument, async (querySnapshot) => {
      const snapshot: FunctionalitiesShop[] = [];
      querySnapshot.forEach((doc) => {
        snapshot.push(doc.data() as FunctionalitiesShop);
      });
      if (snapshot.length === 1) {
        setShop(snapshot[0]);
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribeAttributes();
    };
  }, [workspace?.id]);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!shop ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('functionality.functionality_not_found')}
              </Typography>

              <Button action={() => navigate('/nft/')} className="mt-10">
                {t('functionality.goBack')}
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-8 ">
                <div className="flex">
                  <IconArrow
                    onClick={() => navigate(-1)}
                    width={40}
                    height={40}
                    stroke="#642eff"
                    className="mb-3 cursor-pointer rounded-md"
                  />
                </div>
              </div>
              <section className="mt-4">
                <SectionTitle title={shop.title || shop.type}></SectionTitle>
                <div className="flex w-full justify-end gap-4">
                  <IconEdit
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    width={30}
                    height={30}
                    stroke="white"
                    onClick={() => handleOpenNewFunctionality()}
                  />
                  <IconTrash
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    width={30}
                    height={30}
                    stroke="white"
                    onClick={() => handleDelete()}
                  />
                </div>
                <div className="mt-4 flex flex-row gap-4">
                  <Typography weight="semibold">{`${t('functionality.shop_link')}:`}</Typography>
                  <Typography>{shop.link}</Typography>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default ShopDetails;
