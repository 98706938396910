import {
  collection,
  doc,
  DocumentData,
  getDocs,
  QuerySnapshot,
  updateDoc,
} from 'firebase/firestore';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

import { updatePassword } from 'firebase/auth';
import { useValidation } from '../../hooks';

import {
  decryptWallet,
  encryptWallet,
  hashPassword,
  loginUser,
  resetPassword,
} from '../../imports/auth';
import { auth, db } from '../../imports/firebase';

import { MainLogo } from '../../assets/images';
import { Button, Field, Form, Icon, Image, Input, Typography } from '../../components';
import LinkText from '../../components/linkText/LinkText';
import { IconInvisible, IconLogo, IconVisible } from '../../icons';

enum Steps {
  PASSWORD,
  WALLET,
  SUCCESS,
}

const ResetPassword = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(Steps.PASSWORD);
  const [showInputPassword, setShowInputPassword] = useState(false);
  const [showInputRepeatPassword, setShowInputRepeatPassword] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [backupRef, setBackupRef] = useState<QuerySnapshot<DocumentData>>();
  const [newPassword, setNewPassword] = useState('');
  const location = useLocation();
  const { validatePassword, validateRepeatPassword, required } = useValidation();
  const { oobCode, email } = location.state as { oobCode: string; email: string };
  const navigate = useNavigate();

  const setNewPasswordAndLogin: SubmitHandler<{
    password: string;
    repeatPassword: string;
  }> = async ({ password }) => {
    try {
      setIsLoading(true);
      setNewPassword(password);

      await resetPassword(auth, oobCode, password);
      await loginUser(auth, email, password);

      if (auth.currentUser == null) {
        throw new Error('User not logged in');
      }

      const backupRef = await getDocs(collection(db, `users/${auth.currentUser.uid}/backup`));
      if (backupRef.empty) {
        toast.error('No Backup found! Please create a Backup before proceeding');
        return;
      }

      setBackupRef(backupRef);
      setStep(Steps.WALLET);
    } catch (error) {
      const firebaseCodeError = `authentication.errors.${(error as any).code}`;
      if (i18next.exists(firebaseCodeError)) {
        toast.error(t(firebaseCodeError) as string);
        return;
      }

      toast.error(t('authentication.errors.generic') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const recoverWallet: SubmitHandler<{ secret: string }> = async ({ secret }) => {
    try {
      setIsLoading(true);
      if (!auth.currentUser || !backupRef) return;

      if (backupRef.empty) throw new Error('User has no backup wallet');

      const backupWallet = backupRef?.docs?.[0].data();

      const decryptedBackupWallet = decryptWallet(
        backupWallet.backupWallet,
        secret.toLowerCase().trim()
      );

      const newPrimaryWallet = encryptWallet(decryptedBackupWallet, newPassword);
      if (auth.currentUser) {
        const hashedNewPassword = hashPassword(newPassword, 'sha1');
        await updatePassword(auth.currentUser, hashedNewPassword);
        await updateDoc(doc(db, 'users', auth.currentUser.uid), {
          wallet: newPrimaryWallet,
        });
      }

      setStep(Steps.SUCCESS);
    } catch (error) {
      if (/is not valid JSON/.test((error as any).message)) {
        toast.error(t('authentication.errors.wrong_secret') as string);
        return;
      }
      toast.error(t('authentication.errors.generic') as string);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!email) toast.error(t('authentication.errors.generic') as string);
  }, []);

  switch (step) {
    case Steps.PASSWORD:
      return (
        <section className="flex flex-col items-center">
          <Form
            initialValues={{ password: '', repeatPassword: '' }}
            validationSchema={object({
              password: required(validatePassword()),
              repeatPassword: required(validateRepeatPassword()),
            })}
            mode="onChange"
            // className="mx-auto flex min-h-screen max-w-[768px] flex-col items-center justify-center"
            className="absolute mx-auto flex h-full flex-col items-start justify-center gap-[60px] px-4 sm:w-screen md:w-[450px] "
          >
            {({ fields, errors, handleSubmit, isValid, dirtyFields }) => {
              return (
                <>
                  <Image src={MainLogo} alt={t('bcode_logo')} className="w-[217px]" />
                  <Typography
                    weight="semibold"
                    size="body-semibold-30"
                    color="grey-600"
                    className="text-center "
                  >
                    {t('authentication.forgot.new_password')}
                  </Typography>
                  <div className="flex w-full flex-col gap-[34px]">
                    <Field
                      // error={errors.password?.message}
                      asterisk
                      label={t('new_password')}
                    >
                      <Input
                        id={fields.password.name}
                        name={fields.password.name}
                        error={errors.password?.message}
                        inputRef={fields.password.ref}
                        onChange={fields.password.onChange}
                        onBlur={fields.password.onBlur}
                        placeholder={t('authentication.form_placeholders.new_password')}
                        type={showInputPassword ? 'text' : 'password'}
                        className="mt-[5px] "
                        elementRight={
                          <Button
                            className="max-h-5"
                            type="ghost"
                            icon={showInputPassword ? IconVisible : IconInvisible}
                            action={() => setShowInputPassword(!showInputPassword)}
                          />
                        }
                      />

                      {dirtyFields.password ? (
                        errors.password?.message ? (
                          <Typography className="mt-2" size="body-regular-12">
                            {t('authentication.forgot.password_error')}
                            <span className="text-error">{errors.password?.message}</span>
                          </Typography>
                        ) : (
                          <Typography className="mt-2" size="body-regular-12">
                            {t('authentication.forgot.password_ok_1')}
                            <span className="text-success">
                              {t('authentication.forgot.password_ok_2')}
                            </span>
                          </Typography>
                        )
                      ) : (
                        ''
                      )}
                    </Field>
                    <Field
                      asterisk
                      // error={errors.repeatPassword?.message}
                      label={t('authentication.form_placeholders.repeat_password')}
                    >
                      <Input
                        id={fields.repeatPassword.name}
                        name={fields.repeatPassword.name}
                        error={errors.repeatPassword?.message}
                        inputRef={fields.repeatPassword.ref}
                        onChange={fields.repeatPassword.onChange}
                        onBlur={fields.repeatPassword.onBlur}
                        placeholder={t('authentication.form_placeholders.repeat_new_password')}
                        type={showInputRepeatPassword ? 'text' : 'password'}
                        className="mt-[5px] "
                        elementRight={
                          <Button
                            className="max-h-5"
                            type="ghost"
                            icon={showInputRepeatPassword ? IconVisible : IconInvisible}
                            action={() => setShowInputRepeatPassword(!showInputRepeatPassword)}
                          />
                        }
                      />
                      {/* <p className="mt-2 text-body-regular-12 text-[#D74045]">
                        {errors.repeatPassword?.message}
                      </p> */}
                      <Typography className="mt-2" size="body-regular-12" color="error">
                        {errors.repeatPassword?.message}
                      </Typography>
                    </Field>
                  </div>
                  <div className="flex w-full justify-end">
                    <Button
                      action={handleSubmit(setNewPasswordAndLogin)}
                      className="ml-0"
                      disabled={!isValid || loading}
                    >
                      {t('confirm')}
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </section>
      );
    case Steps.WALLET:
      const question = t(
        `security_questions.${backupRef?.docs[0].data().question.replaceAll('-', '_')}`
      );

      return (
        <>
          <section className="flex flex-col items-center">
            <Form
              initialValues={{ secret: '' }}
              validationSchema={object({ secret: required(string()) })}
              className="absolute mx-auto flex h-full flex-col items-start justify-center gap-[60px] px-4 sm:w-screen md:w-[450px] "
            >
              {({ fields, errors, handleSubmit, setValue, isValid }) => {
                setValue('secret', '');

                return (
                  <>
                    <Icon icon={IconLogo} size="217" className="z-50 mx-auto" />

                    <Typography
                      weight="semibold"
                      size="body-semibold-30"
                      color="grey-600"
                      className="text-center "
                    >
                      {t('authentication.forgot.backup')}
                    </Typography>
                    <div className="flex w-full flex-col gap-[20px]">
                      <Typography color="grey-600" size="body-regular-18">
                        {question}
                      </Typography>
                      <Field
                        error={errors.secret?.message}
                        asterisk
                        label={t('answer')}
                        info
                        infoText={t('authentication.forgot.info_recover')}
                      >
                        <Input
                          id={fields.secret.name}
                          name={fields.secret.name}
                          error={errors.secret?.message}
                          inputRef={fields.secret.ref}
                          type="text"
                          onChange={fields.secret.onChange}
                          onBlur={fields.secret.onBlur}
                          placeholder={t('authentication.form_placeholders.answer')}
                          className="mt-[5px] "
                        />
                      </Field>
                    </div>
                    <div className="flex w-full justify-end">
                      <Button
                        action={handleSubmit(recoverWallet)}
                        // loading={loading}s
                        className="ml-0"
                        disabled={!isValid || loading}
                      >
                        {t('authentication.continue')}
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          </section>
        </>
      );
    case Steps.SUCCESS:
      return (
        <section className="flex flex-col items-center">
          <div className="absolute mx-auto flex h-full w-[340px] flex-col items-start justify-center gap-[60px] ">
            <div className="absolute top-0 w-full">
              <Icon icon={IconLogo} size="217" className="z-50 mx-auto" />
            </div>
            <Typography
              weight="semibold"
              size="body-semibold-30"
              color="grey-600"
              className="text-center "
            >
              {t('authentication.forgot.password_updated')}
            </Typography>
            <Typography size="body-regular-18" color="grey-600">
              {t('authentication.forgot.success_password')}
            </Typography>
            <LinkText
              textSize="text-body-medium-underline-16"
              to="/sign-in"
              direction="right"
              iconSize="19"
              className="underline"
            >
              {t('authentication.sign_in')}
            </LinkText>
          </div>
        </section>
      );
    default:
      return <></>;
  }
};

export default ResetPassword;
