import { createSlice } from '@reduxjs/toolkit';
import type { Operation } from '../../imports/types';

const initialState: Operation = {};

export const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    setUpdateRequestId: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        updateRequestId: payload,
      };
    },
    setPendingWorkspaceInvitation: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        pendingWorkspace: payload,
      };
    },
  },
});

export const { setUpdateRequestId, setPendingWorkspaceInvitation } = operationSlice.actions;
export default operationSlice.reducer;
