import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { Icon, ItemOption } from '..';
import { IconFolderHash, IconFolderNotch, IconInfo, IconOptions } from '../../icons';

type NotarizationTopCardProps = {
  item: any;
  hasWritePermission?: boolean;
  folders?: any;
  action?: () => void;
};

const NotarizationTopCard = ({
  item,
  hasWritePermission,
  action,
  folders,
}: NotarizationTopCardProps) => {
  const { t } = useTranslation(['notarization']);
  const [openMenu, setOpenMenu] = useState(false);

  const { open: openModalDetail } = useModal('NotarizationDetailsModal');
  const handleOpenDetail = () => {
    openModalDetail({ notarization: item, folders, hasWritePermission });
  };

  const { open: openModalDeleteNotarization } = useModal('DeleteNotarizationModal');
  const handleOpenDeleteNotarization = () => {
    openModalDeleteNotarization({ notarization: item, folders });
  };
  const { open: openModalChangeFolder } = useModal('ChangeFolderModal');
  const handleOpenChangeFolder = (item: any) => {
    openModalChangeFolder({ folderType: 'notarizations', folders, item });
  };

  const handleOnClick = () => {
    if (action) {
      action();
    } else {
      openModalDetail({ notarization: item, folders });
    }
  };
  return (
    <>
      <div className="flex size-full items-center justify-center" onClick={() => handleOnClick()}>
        {!item.status && <Icon icon={IconFolderHash} size="lg" />}
        {item.status === 'pending' && <Icon icon={IconFolderHash} size="lg" />}
        {item.status === 'success' && <Icon icon={IconFolderHash} size="lg" />}
        {item.status === 'error' && <Icon icon={IconFolderHash} size="lg" />}
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpenMenu(!openMenu);
        }}
        className="absolute right-0 top-0 size-[27px] cursor-pointer  items-center justify-between pt-2"
      >
        <Icon icon={IconOptions} size="md" />
        <div
          onMouseEnter={() => setOpenMenu(true)}
          onMouseLeave={() => setOpenMenu(false)}
          className={`absolute left-0 top-0 z-10 mt-7 flex w-[210px]  flex-col rounded-lg border border-primary-500 bg-white ${
            openMenu ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-col">
            <ItemOption
              id={`info_notarization-${item.id}`}
              name={t('collection.options.info_notarization')}
              icon={IconInfo}
              onClick={handleOpenDetail}
            />
            {folders && hasWritePermission && (
              <ItemOption
                id={`folder_collection-${item.id}`}
                name={t('collection.options.folder_collection')}
                icon={IconFolderNotch}
                onClick={() => handleOpenChangeFolder(item)}
              />
            )}

            {/* <ItemOption
              id={`share_notarization-${item.id}`}
              name={t('collection.options.share_notarization')}
              icon={IconShare}
            /> */}
            {hasWritePermission && item.auditId === undefined && (
              <ItemOption
                id={`delete_notarization-${item.id}`}
                name={t('collection.options.delete_notarization')}
                color="error"
                onClick={handleOpenDeleteNotarization}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default NotarizationTopCard;
