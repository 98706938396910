import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { getUserAuthToken } from '../../api/firebase';
import useValidation from '../../hooks/useValidation';
import { IconLink } from '../../icons';
import { paidIpfsGateway } from '../../imports/constants';
import { getRemoteConfigValue } from '../../imports/remoteConfig';
import { calculateFileHash, truncateText } from '../../imports/utils';
import { getFolderName } from '../../modules/audit/api';
import { getFileFromStorage } from '../../modules/notarizations/api';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Field from '../field/Field';
import FileUploader from '../fileUploader/FileUploader';
import Form from '../form/Form';
import Icon from '../icon/Icon';
import ModalLayout from '../layouts/ModalLayout';
import Tag from '../tag/Tag';
import Tooltip from '../tooltips/Tooltip';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};
const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography size="body-regular-18">{label.toUpperCase()}</Typography>
      <div className="flex flex-row items-center justify-start gap-4 ">{children}</div>
    </div>
  );
};
type AuditNotarizeModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  //const navigate = useNavigate();
  // const { t } = useTranslation(['audit']);
  const { notarization, setNotarizations } = useModalProps('AuditNotarizeModal');
  // const { uid } = useAppSelector((state) => state.user);
  // const profile = useAppSelector((state) => state.user);
  // const { address } = useAppSelector((state) => state.user.wallet);
  // const { workspace } = useAppSelector((state) => state.team);
  const { name, description, date, folderId, tags, notes, hash, ipfsUri, externalUrl, id } =
    notarization;

  const { t } = useTranslation(['audit']);
  const { required, validateFilePresence } = useValidation();

  const [step, setStep] = useState(0);
  const [folder, setFolder] = useState<string>('');
  const [fileStorageUrl, setFileStorageUrl] = useState<string | null>(null);
  const [uploadedFileHash, setUploadedFileHash] = useState<string | null>(null);
  const { workspace } = useAppSelector((user) => user.team);

  const isViewNotarizationStep = step === 0;
  const isVerifyNotarizationStep = step === 1;
  const isSuccessfulStep = step === 2;
  const isFailedStep = step === 3;
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');

  const isUploadedFileHashValid = uploadedFileHash === hash;

  const form = {
    initialValues: {
      fileList: null,
    },
    validationSchema: yup.object({
      fileList: required(validateFilePresence()),
    }),
  };
  const { initialValues, validationSchema } = form;

  const handleGetFileFromStorage = async () => {
    const { value: authTokenId } = await getUserAuthToken();

    let url = '';

    if (externalUrl) {
      await getFileFromStorage(externalUrl, authTokenId).then((res) => {
        url = res.value.url;
      });
    }

    return url;
  };

  const handleGetFileHash = async (fileList: File[]) => {
    if (fileList[0]) {
      const file = fileList[0];
      const generatedHash = await calculateFileHash(file, (generatedhash: string) => generatedhash);
      const fileHash = `0x${generatedHash}`;

      setUploadedFileHash(fileHash);
    } else {
      setUploadedFileHash(null);
    }
  };

  const handleVerifyNotarization = async () => {
    if (isUploadedFileHashValid || ipfsUri || externalUrl) {
      setNotarizations((prev: any[]) =>
        prev.map((item) => {
          if (item.id === notarization.id) {
            return {
              ...item,
              verified: true,
            };
          }

          return item;
        })
      );
      setStep(2);
    } else {
      setUploadedFileHash(null);
      setStep(3);
    }
  };

  useEffect(() => {
    if (ipfsUri) {
      setFileStorageUrl(`${paidIpfsGateway}${ipfsUri}`);
    } else if (externalUrl) {
      handleGetFileFromStorage().then((res) => {
        setFileStorageUrl(res);
      });
    }
  }, []);

  useEffect(() => {
    if (folderId) {
      getFolderName(folderId).then((res) => {
        setFolder(res.value);
      });
    }
  }, []);

  return (
    <ModalLayout
      classNameLayout=""
      onClose={() => {
        setOpen(false);
      }}
    >
      {isViewNotarizationStep && (
        <div className="mt-9 flex flex-col gap-7 px-5" key={id}>
          <Typography className="" color="primary-500" size="body-medium-30">
            {name}
          </Typography>
          <div className="flex flex-row gap-7">
            <div className="flex w-1/2 flex-col gap-4">
              {folder && (
                <InfoSection label={t('create_audit.labels.folder') as string}>
                  <Typography size="body-medium-16">{folder}</Typography>
                </InfoSection>
              )}
              <InfoSection label={t('create_audit.labels.description') as string}>
                <Typography size="body-medium-16">{description}</Typography>
              </InfoSection>

              {hash && (
                <InfoSection label={t('create_audit.labels.hash') as string}>
                  <Typography size="body-medium-16">
                    {truncateText(hash, {
                      maxLength: 20,
                      rightChars: 0,
                    })}
                  </Typography>
                </InfoSection>
              )}
              {(ipfsUri || externalUrl) && (
                <InfoSection label={t('create_audit.labels.storage') as string}>
                  {fileStorageUrl ? (
                    <div className="flex items-center justify-between">
                      <Typography>{t('create_audit.labels.download_file')}</Typography>

                      <Tooltip content={t('create_audit.tooltips.download_file')}>
                        <a href={fileStorageUrl} target="_blank" rel="noreferrer">
                          <Button type="ghost" action={() => {}}>
                            <Icon icon={IconLink} className="!h-4 !w-4" />
                          </Button>
                        </a>
                      </Tooltip>
                    </div>
                  ) : (
                    <Typography>{t('create_audit.no_url_found')}</Typography>
                  )}
                </InfoSection>
              )}
            </div>

            <div className="flex w-1/2 flex-col gap-4">
              <InfoSection label={t('create_audit.labels.date') as string}>
                <Typography size="body-medium-16">
                  {format(new Date(date), 'dd/MM/yyyy')}
                </Typography>
              </InfoSection>
              <InfoSection label={t('create_audit.labels.tags') as string}>
                {tags && tags.length > 0 && (
                  <div className="grid grid-cols-3 gap-3 ">
                    {tags.map((tag: string) => (
                      <Tag key={tag} title={tag} />
                    ))}
                  </div>
                )}
              </InfoSection>
            </div>
          </div>

          <div className="flex flex-row items-center gap-7">
            {notes && (
              <InfoSection label={t('create_audit.labels.notes') as string}>
                <Typography size="body-medium-16">{notes}</Typography>
              </InfoSection>
            )}
          </div>
          {workspace?.permissions.audit.write && (
            <div className="flex flex-row justify-end gap-7">
              {ipfsUri || externalUrl ? (
                <Button className="w-[190px]" action={() => handleVerifyNotarization()}>
                  {t('create_audit.buttons.verify')}
                </Button>
              ) : (
                <Button className="w-[190px]" action={() => setStep(1)} type="secondary">
                  {t('create_audit.buttons.manually_verify')}
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {isVerifyNotarizationStep && (
        <>
          <div className="mt-12 flex flex-col gap-7 px-5" key={id}></div>
          {/* <Image src={ImageVerifyHash} alt={t('image.file_hash')} className="w-[50px]" /> */}

          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex flex-col space-y-12"
          >
            {({ fields, handleSubmit, errors, setValue }) => (
              <>
                <div className="flex flex-col justify-center gap-7">
                  <Field error={errors.fileList?.message}>
                    <FileUploader
                      label={t('create_audit.labels.upload_file')}
                      onChange={(files) => {
                        setValue(fields.fileList.name, files);
                        handleGetFileHash(files);
                      }}
                      accept={allowedFileType}
                      unlimitedSize
                    />
                  </Field>

                  <InfoSection label={t('create_audit.labels.uploaded_file_hash') as string}>
                    <Typography size="body-medium-16">{hash}</Typography>
                  </InfoSection>

                  <hr className="!my-8 border-primary-400" />
                  <InfoSection label={t('create_audit.labels.hash_to_be_verified') as string}>
                    <Typography size="body-medium-16">
                      {uploadedFileHash || t('create_audit.upload_a_file_to_view_hash')}
                    </Typography>
                  </InfoSection>
                </div>

                <div className="flex flex-row justify-end gap-7">
                  <Button action={() => handleSubmit(handleVerifyNotarization)()}>
                    {t('create_audit.buttons.verify')}
                  </Button>

                  <Button action={() => setStep(0)} type="secondary">
                    {t('create_audit.buttons.go_back')}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      )}

      {isSuccessfulStep && (
        <>
          {/* <Image src={ImageSuccess} alt={t('image.success')} className="w-[50px]" /> */}

          <section className="flex size-full flex-col space-y-12 text-center">
            <div className="mt-12 flex flex-row justify-center">
              <Typography weight="medium">{t('create_audit.hash_is_valid')}</Typography>
            </div>

            <div className="flex flex-row justify-end gap-7">
              <Button action={() => setOpen(false)}>{t('create_audit.buttons.finish')}</Button>
            </div>
          </section>
        </>
      )}

      {isFailedStep && (
        <>
          {/* <Image src={ImageError} alt={t('image.error')} className="w-[50px]" /> */}

          <section className="flex size-full flex-col space-y-12 text-center">
            <div className="mt-12 flex flex-row justify-center">
              <Typography weight="medium">{t('create_audit.hash_is_invalid')}</Typography>
            </div>

            <div className="flex flex-row justify-end gap-7">
              <Button action={() => setStep(1)}>{t('create_audit.buttons.try_again')}</Button>
            </div>
          </section>
        </>
      )}
    </ModalLayout>
  );
};

const AuditNotarizeModal = ({ children }: AuditNotarizeModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="AuditNotarizeModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AuditNotarizeModal;
