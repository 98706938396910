import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { Button, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { getParametersFromUrl } from '../../../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  acceptInvitation,
  declineInvitation,
  handleBackendError,
  saveWorkspaceToStore,
} from '../../api/api';

const JoinTeam = () => {
  const { t } = useTranslation(['team']);
  const location = useLocation();
  const navigate = useNavigate();
  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const dispatch = useAppDispatch();
  const params = getParametersFromUrl(location.search);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { open: openLoadingModal } = useModal('LoadingModal');

  const workspaceId = params?.workspaceId;
  const invitationId = params?.invitationId;
  const isNotAlreadyUser = params?.isNotAlreadyUser;
  const sdi = params?.sdi;
  const company = params?.company;
  const vat = params?.vat;
  const office = params?.office;
  const sender = decodeURIComponent(params?.sender);
  const workspaceName = decodeURI(params?.team);
  const email = params?.email;

  const invitationGroupId = params?.group;

  const handleAcceptInvitation = async () => {
    try {
      openLoadingModal({});
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.add_user.pending'),
        },
      });
      await acceptInvitation(workspaceId, invitationId);
      await saveWorkspaceToStore(
        workspaceId,
        uid,
        workspace?.owner || '',
        dispatch,
        invitationGroupId
      );
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.add_user.success'),
        },
      });
      navigate('/profile');
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.add_user.error'),
        },
      });
      handleBackendError(error);
    }
  };

  const handleDeclineInvitation = async () => {
    try {
      openLoadingModal({});
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.add_user.pending'),
        },
      });
      await declineInvitation(workspaceId, invitationId);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('success.generic'),
        },
      });
      navigate('/');
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('success.generic'),
        },
      });
      handleBackendError(error);
    }
  };

  const handleSignInClick = () => {
    const location = window.location.href.split('/').slice(0, 3).join('/');

    const frameWindow = window.open(`${location}/sign-in`, '_blank');

    const listener = (message: any) => {
      if (message.data === 'userlogged') {
        frameWindow?.close();
        window.location.reload();
        frameWindow?.removeEventListener('message', listener);
      }
    };

    frameWindow?.addEventListener('message', listener);
  };

  const handleSignUpClick = () => {
    const location = window.location.href.split('/').slice(0, 3).join('/');
    window.open(
      `${location}/sign-up?workspaceId=${workspaceId}&office=${office}&vat=${vat}&sdi=${sdi}&company=${company}&email=${email}&invitationId=${invitationId}`,
      '_blank'
    );
  };

  return (
    <section className="flex min-h-screen flex-col items-center justify-center space-y-[32px]">
      <div className="flex flex-col items-center justify-center space-y-[32px] text-center">
        <Typography weight="semibold" size="xl">
          {t('join.title')}
        </Typography>
        <div className="flex items-center space-x-[6px]">
          <Typography size="md" weight="medium" color="secondary-500">
            {sender}
          </Typography>
          <Typography size="md">{t('join.subtitle')}</Typography>
          <Typography size="md" weight="medium" color="secondary-500">
            {workspaceName}
          </Typography>
        </div>
      </div>
      {uid ? (
        <div className="flex w-full items-center justify-center space-x-[64px]">
          <Button className="w-min" action={handleAcceptInvitation}>
            {t('join.cta_pos')}
          </Button>
          <Button className="w-min" type="secondary" action={handleDeclineInvitation}>
            {t('join.cta_neg')}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-[12px]">
          <Typography>{t('join.no_user')}</Typography>
          {/* If user already exists redirect to sign in. Otherwise, sign up. */}
          {isNotAlreadyUser ? (
            <Button disabled={!isNotAlreadyUser} className="w-min" action={handleSignUpClick}>
              {t('sign_up')}
            </Button>
          ) : (
            <Button disabled={isNotAlreadyUser} className="w-min" action={handleSignInClick}>
              {t('sign_in')}
            </Button>
          )}
        </div>
      )}
    </section>
  );

  // useEffect(() => {
  //   setState('loading');
  //   const params = getParametersFromUrl(location.search);
  //   const workspaceId = params?.workspaceId;
  //   const invitationId = params?.invitationId;
  //   acceptInvitation(workspaceId, invitationId)
  //     .then(() => {
  //       setState('success');
  //       delayed(() => navigate('/team'), 1000);
  //     })
  //     .catch((error) => {
  //       setState('error');
  //       console.error(error);
  //       delayed(() => navigate('/team'), 1000);
  //     });
  // }, []);

  // switch (state) {
  //   case 'loading':
  //     return (
  //       <InnerPage>
  //         <div className="flex items-center justify-center min-h-screen grow">
  //           <IconLoading className="size-12 animate-spin text-primary-500" />
  //         </div>
  //       </InnerPage>
  //     );
  //   case 'error':
  //     return (
  //       <InnerPage>
  //         <div className="flex flex-col items-center justify-center min-h-screen grow">
  //           <div className="rounded-full bg-error">
  //             <Lottie
  //               loop
  //               animationData={animationError}
  //               play
  //               style={{ width: 100, height: 100 }}
  //             />
  //           </div>
  //           <div className="mt-[40px] text-center">
  //             <Typography weight="semibold" size="xl" className="mb-[8px]">
  //               {t('redirect_messages.error.default.title') as string}
  //             </Typography>
  //             <Typography>{t('redirect_messages.error.default.message') as string}</Typography>
  //           </div>
  //         </div>
  //       </InnerPage>
  //     );
  //   case 'success': {
  //     return (
  //       <InnerPage>
  //         <div className="flex flex-col items-center justify-center min-h-screen grow ">
  //           <div className="rounded-full bg-success">
  //             <Lottie
  //               loop
  //               animationData={animationSuccess}
  //               play
  //               style={{ width: 100, height: 100 }}
  //             />
  //           </div>
  //           <div className="mt-[40px] text-center">
  //             <Typography weight="semibold" size="xl" className="mb-[8px]">
  //               {t('redirect_messages.success.default.title') as string}
  //             </Typography>
  //             <Typography>{t('redirect_messages.success.default.message') as string}</Typography>
  //           </div>
  //         </div>
  //       </InnerPage>
  //     );
  //   }
  //   default:
  //     return <></>;
  // }
};

export default JoinTeam;
