import { SVGProps } from 'react';

const IconOptions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96 114C105.941 114 114 105.941 114 96C114 86.0589 105.941 78 96 78C86.0589 78 78 86.0589 78 96C78 105.941 86.0589 114 96 114Z"
      stroke="#4F4F4F"
      strokeWidth={12}
      strokeMiterlimit={10}
    />
    <path
      d="M96 54C105.941 54 114 45.9411 114 36C114 26.0589 105.941 18 96 18C86.0589 18 78 26.0589 78 36C78 45.9411 86.0589 54 96 54Z"
      stroke="#4F4F4F"
      strokeWidth={12}
      strokeMiterlimit={10}
    />
    <path
      d="M96 174C105.941 174 114 165.941 114 156C114 146.059 105.941 138 96 138C86.0589 138 78 146.059 78 156C78 165.941 86.0589 174 96 174Z"
      stroke="#4F4F4F"
      strokeWidth={12}
      strokeMiterlimit={10}
    />
  </svg>
);
export default IconOptions;
