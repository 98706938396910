import { useTranslation } from 'react-i18next';
import { Icon, Image, Typography } from '../../../../components';
import { IconAuthenticity, IconDoc, IconFolderHash, IconWarranty } from '../../../../icons';
import { formatIPFSUri } from '../../../../imports/utils';
import { NftCustomAttribute } from '../../imports/types';
import DetailItem from './DetailItem';

type RenderAttributeType = {
  attribute: NftCustomAttribute;
};

const RenderAttribute = ({ attribute }: RenderAttributeType) => {
  const { t } = useTranslation(['tokenCreator']);

  switch (attribute.type) {
    case 'warranty_certificate':
      return (
        // eslint-disable-next-line react/button-has-type
        <div
          key={attribute.trait_type}
          className="flex items-center h-full gap-4 cursor-pointer"
          onClick={() => {
            if (attribute.value) {
              window.open(attribute.value, '_blank');
            }
            if (attribute.file) {
              window.open(attribute.file[0]?.preview, '_blank');
            }
          }}
          style={{ maxWidth: '100%' }}
        >
          <div className="min-w-[37px]">
            <Icon icon={IconWarranty} size="37" />
          </div>
          <Typography
            className="max-w-full overflow-auto break-words whitespace-normal"
            size="body-medium-14"
          >
            {attribute.trait_type}
          </Typography>
        </div>
      );

    case 'authenticity_certificate':
      return (
        <div
          key={attribute.trait_type}
          className="flex items-center h-full gap-4 cursor-pointer"
          onClick={() => {
            if (attribute.value) {
              window.open(attribute.value, '_blank');
            }
            if (attribute.file) {
              window.open(attribute.file[0]?.preview, '_blank');
            }
          }}
        >
          <div className="min-w-[37px]">
            <Icon icon={IconAuthenticity} size="37" />
          </div>
          <Typography
            className="max-w-full overflow-auto break-words whitespace-normal"
            size="body-medium-14"
          >
            {attribute.trait_type}
          </Typography>
        </div>
      );

    case 'document':
      return (
        <div
          key={attribute.trait_type}
          className="flex items-center h-full gap-4 cursor-pointer"
          onClick={() => {
            if (attribute.value) {
              window.open(attribute.value, '_blank');
            }
            if (attribute.file) {
              window.open(attribute.file[0]?.preview, '_blank');
            }
          }}
        >
          <div className="min-w-[37px]">
            <Icon icon={IconDoc} size="37" />
          </div>
          <Typography
            className="max-w-full overflow-auto break-words whitespace-normal"
            size="body-medium-14"
          >
            {attribute.trait_type}
          </Typography>
        </div>
      );

    case 'partner':
      return (
        <div className="flex flex-col items-start w-full">
          <Typography className="ml-2" size="body-regular-12">
            {attribute.trait_type}
          </Typography>
          {(attribute.value || attribute.file) && (
            <Image
              className=" aspect-square max-h-[170px] w-full max-w-[375px] rounded-[20px] object-contain pr-1"
              src={
                attribute.link
                  ? formatIPFSUri(attribute.link)
                  : attribute.file
                  ? attribute.file[0]?.preview
                  : ''
              }
            ></Image>
          )}
        </div>
      );

    case 'image':
      return (
        <div className="flex flex-col w-full">
          {(attribute.value || attribute.file) && (
            <Image
              className="mt-3 aspect-square max-h-[170px] w-full max-w-[375px] rounded-[20px]  object-contain pr-1"
              src={
                attribute.value
                  ? formatIPFSUri(attribute.value)
                  : attribute.file
                  ? attribute.file[0]?.preview
                  : ''
              }
            ></Image>
          )}
        </div>
      );
    case 'notarizations':
      return (
        <div key={attribute.trait_type} className="flex items-center h-full gap-4 cursor-pointer">
          <div className="min-w-[37px]">
            <Icon icon={IconFolderHash} size="37" stroke="primary-500" />
          </div>
          <Typography
            className="max-w-full overflow-auto break-words whitespace-normal"
            size="body-medium-14"
          >
            {/* {t('create_contract.attributesType.notarizations')} */}
            {attribute.trait_type}
          </Typography>
        </div>
      );
    case 'product':
      return null;
    default:
      return (
        <DetailItem
          key={attribute.trait_type + attribute.value}
          left={attribute.trait_type}
          right={
            typeof attribute.value === 'string' && attribute.value?.startsWith('https')
              ? t('create_contract.attributesType.go_to_link')
              : attribute.value
          }
          link={
            typeof attribute.value === 'string' && attribute.value?.startsWith('https')
              ? attribute.value
              : undefined
          }
          isCustomAttribute={attribute.type === 'text'}
        />
      );
  }
};

export default RenderAttribute;
