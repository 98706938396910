/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48 120C61.2548 120 72 109.255 72 96C72 82.7452 61.2548 72 48 72C34.7452 72 24 82.7452 24 96C24 109.255 34.7452 120 48 120Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132 174C145.255 174 156 163.255 156 150C156 136.745 145.255 126 132 126C118.745 126 108 136.745 108 150C108 163.255 118.745 174 132 174Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132 66C145.255 66 156 55.2548 156 42C156 28.7452 145.255 18 132 18C118.745 18 108 28.7452 108 42C108 55.2548 118.745 66 132 66Z"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.826 54.9751L68.1758 83.0251"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.1758 108.975L111.826 137.025"
      stroke={props.stroke || '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconShare;
