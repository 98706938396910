import { createSlice } from '@reduxjs/toolkit';
import { NewsForm, ProductForm, RestrictedAreaForm, ShopForm } from '../../imports/types';

export type FunctionlitiesState = {
  news: NewsForm[];
  products: ProductForm[];
  shops: ShopForm[];
  restrictedAreas: RestrictedAreaForm[];
};

const initialState: FunctionlitiesState = {
  news: [],
  products: [],
  restrictedAreas: [],
  shops: [],
};

export const functionlitiesSlice = createSlice({
  name: 'functionalities',
  initialState,
  reducers: {
    updateNews: (
      state,
      action: {
        payload: NewsForm;
        type: string;
      }
    ) => {
      const newsIndex = state.news.findIndex((news) => news.id === action.payload.id);
      if (newsIndex !== -1) {
        state.news[newsIndex] = { ...state.news[newsIndex], ...action.payload };
      } else {
        state.news.push(action.payload);
      }
      return state;
    },

    updateProduct: (
      state,
      action: {
        payload: ProductForm;
        type: string;
      }
    ) => {
      const productIndex = state.products.findIndex((product) => product.id === action.payload.id);
      if (productIndex !== -1) {
        state.products[productIndex] = {
          ...state.products[productIndex],
          ...action.payload,
        };
      } else {
        state.products.push(action.payload);
      }
      return state;
    },

    updateRestrictedAreas: (
      state,
      action: {
        payload: RestrictedAreaForm;
        type: string;
      }
    ) => {
      const RestrictedAreaIndex = state.restrictedAreas.findIndex(
        (restrictedArea) => restrictedArea.id === action.payload.id
      );
      if (RestrictedAreaIndex !== -1) {
        state.restrictedAreas[RestrictedAreaIndex] = {
          ...state.restrictedAreas[RestrictedAreaIndex],
          ...action.payload,
        };
      } else {
        state.restrictedAreas.push(action.payload);
      }
      return state;
    },
    updateShops: (
      state,
      action: {
        payload: ShopForm;
        type: string;
      }
    ) => {
      const ShopIndex = state.shops.findIndex((shop) => shop.id === action.payload.id);
      if (ShopIndex !== -1) {
        state.shops[ShopIndex] = {
          ...state.shops[ShopIndex],
          ...action.payload,
        };
      } else {
        state.shops.push(action.payload);
      }
      return state;
    },

    removeNews: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.news.filter((news) => news.id !== idToRemove);
      state.news = updatedList;
      return state;
    },

    removeProduct: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.products.filter((product) => product.id !== idToRemove);
      state.products = updatedList;
      return state;
    },

    removeRestrictedArea: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.restrictedAreas.filter(
        (restrictedArea) => restrictedArea.id !== idToRemove
      );
      state.restrictedAreas = updatedList;
      return state;
    },

    removeShop: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.shops.filter((shop) => shop.id !== idToRemove);
      state.shops = updatedList;
      return state;
    },

    logOutFunctionalities: (state: FunctionlitiesState) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  updateNews,
  updateProduct,
  updateRestrictedAreas,
  updateShops,
  removeNews,
  removeProduct,
  removeRestrictedArea,
  removeShop,
  logOutFunctionalities,
} = functionlitiesSlice.actions;
export default functionlitiesSlice.reducer;
