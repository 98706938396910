import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import databox from './locales/index';
import audit from './modules/audit/locales/index';
import notarizations from './modules/notarizations/locales/index';
import tokenCreator from './modules/tokenCreator/locales/index';
import team from './modules/team/locales/index';

const resources = {
  it: {
    translation: databox.it,
    notarization: notarizations.it,
    tokenCreator: tokenCreator.it,
    audit: audit.it,
    team: team.it,
  },
  en: {
    translation: databox.en,
    notarization: notarizations.en,
    tokenCreator: tokenCreator.en,
    audit: audit.en,
    team: team.en,
  },
};

const defaultLng = localStorage.getItem('lng') || navigator.language.slice(0, 2);

const backUpLanguage = defaultLng === 'it' ? 'en' : 'it';

i18n.use(initReactI18next).init(
  {
    resources,
    lng: defaultLng || 'it',
    fallbackLng: backUpLanguage || 'en',
    ns: ['translation', 'notarization', 'tokenCreator', 'audit', 'team', 'crm'],
    defaultNS: 'translation',
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  },
  (err, t) => {
    // eslint-disable-next-line no-console
    if (err) console.error('i18n Error', err);
  }
);

export default i18n;
