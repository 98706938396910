import * as yup from 'yup';

export const form = {
  initialValues: {
    name: '',
    isSuperGroup:false,
  },
  validationSchema: yup.object({
    name: yup.string().required(),
    isSuperGroup: yup.boolean().required(),
  }),
};
const emptyValidation = yup.object({});
export type FormType = typeof form.initialValues;
