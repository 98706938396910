export enum ECollections {
  Contracts = 'contracts',
  Folders = 'folders',
  Notarizations = 'notarizations',
  Payments = 'payments',
  Receipts = 'receipts',
  Subscriptions = 'subscriptions',
  TcReceipts = 'tcReceipts',
  Users = 'users',
}

export default ECollections;
