import { createSlice } from '@reduxjs/toolkit';
import type { UserState } from '../../imports/types';

const initialState: UserState = {
  uid: '',
  email: '',
  name: '',
  company: '',
  office: '',
  sdi: '',
  vat: '',
  wallet: {
    address: '',
    privateKey: '',
  },
  createdAt: null,
  role: 'guest',
  isEmailVerified: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action) => {
      const { payload } = action;
      const { profile } = payload;

      state = {
        ...profile,
      };

      return state;
    },
    emailVerified: (state, action) => {
      const { payload } = action;
      const { role } = payload;

      state = {
        ...state,
        role,
        isEmailVerified: true,
      };

      return state;
    },
    logOutUser: (state) => {
      state = initialState;
      return state;
    },
    updateProfile: (state, action) => {
      const { payload } = action;
      const { profile } = payload;

      state = {
        ...state,
        ...profile,
      };

      return state;
    },
  },
});

export const { signIn, logOutUser, emailVerified, updateProfile } = userSlice.actions;
export default userSlice.reducer;
