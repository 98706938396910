import { collection, onSnapshot, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from '..';
import { db } from '../imports/firebase';
import { getGroups } from '../modules/team/components/query';
import { Group } from '../modules/team/imports/types';

export const useGetGroups = (workspaceId: string | undefined) => {
  const {
    data: groups,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['groups', workspaceId],
    queryFn: () => getGroups(workspaceId),
    enabled: !!workspaceId,
  });

  useEffect(() => {
    if (!workspaceId) return;

    const groupsUnsub = onSnapshot(
      query(collection(db, `workspaces/${workspaceId}/groups`)),
      (snapshot) => {
        const updatedGroups = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Group));
        queryClient.setQueryData(['groups', workspaceId], updatedGroups);
      }
    );

    return () => groupsUnsub();
  }, [workspaceId]);

  return { groups, isLoading, isError };
};
