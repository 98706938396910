import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/* root imports */
import { db } from '../../../../imports/firebase';
import { useAppSelector } from '../../../../redux/hooks';

import { compare, safeWhere } from '../../../../imports/utils';

import type {
  Filters,
  FiltersActionType,
  Folder as FolderType,
  Location,
  SidebarType,
} from '../../../../imports/types';

import { CollectionHeader, InnerPage, SectionTitle } from '../../../../components';

/* notarization module imports */
import { Notarization, Receipt } from '../../imports/types';

import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import { NotarizationsList } from '../../components';

const filtersInitialState: {
  [key: string]: {
    filter: Filters;
    value: any;
  };
} = {
  inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByTag: { filter: 'FUZZY_MATCH', value: '' },
  inputFilterByDate: { filter: 'NOTARIZATION_DATE_MATCH', value: '' },
};

const filtersReducer = (state: typeof filtersInitialState, action: FiltersActionType) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
      };
    default:
      return state;
  }
};

const Folder = () => {
  const { t } = useTranslation(['notarization']);

  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);

  const location = useLocation() as Location<{
    folder: string;
    folders: FolderType[];
  }>;
  const { state: folderState } = location;
  // console.log(folderState);
  const orderByInitialState = { type: 'date', direction: 'desc' };
  const [orderBy, setOrderBy] = useState<{
    type: string;
    direction: string;
  }>(orderByInitialState);

  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);

  const showSidebarInitialState = { type: '', optionalProps: {} };
  const [showSidebar, setShowSidebar] = useState<{ type: string; optionalProps?: any }>(
    showSidebarInitialState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [folder, setFolder] = useState<FolderType>();
  const [notarizations, setNotarizations] = useState<Array<Notarization>>([]);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);

  const orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    bold?: boolean;
  }[] = [
    {
      value: 'order',
      label: t('collection.order_by.order'),
      bold: true,
    },
    {
      value: 'date-asc',
      label: t('collection.order_by.date_oldest'),
    },
    {
      value: 'date-desc',
      label: t('collection.order_by.date_recent'),
    },
    {
      value: 'name-asc',
      label: t('collection.order_by.name_asc'),
    },
    {
      value: 'name-desc',
      label: t('collection.order_by.name_desc'),
    },
  ];

  const handleSetOrderBy = (value: string) => {
    if (value === 'order') {
      setOrderBy({ ...orderBy, type: orderByInitialState.type });
      return;
    }

    const [type, direction] = value.split('-');
    setOrderBy({ type, direction });
  };

  const handleShowSidebar = (type: SidebarType, optionalProps: any = {}) => {
    setShowSidebar({ type, optionalProps });
  };

  const handleCloseSidebar = () => {
    setShowSidebar(showSidebarInitialState);
  };

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };
  const {
    workspaceGroupObject: { groupId, isSuperGroup },
  } = useWorkspaceAndGroup();
  const activeGroupId = workspace?.activeGroupId;
  useEffect(() => {
    const folderDocRef = doc(db, 'folders', folderState.folder);
    const receiptsQuery = query(collection(db, 'receipts'));

    const unsubscribeFolder = onSnapshot(folderDocRef, async (doc) => {
      const folderData = doc.data() as FolderType;

      if (folderData) {
        const notarizationsQuery = query(
          collection(db, 'notarizations'),
          ...safeWhere(uid, workspace?.id, isSuperGroup === true ? undefined : activeGroupId),
          where('folderId', '==', folderData.id)
        );

        onSnapshot(notarizationsQuery, async (querySnapshot) => {
          const notarizations: any[] = [];

          querySnapshot.forEach((doc) => {
            notarizations.push({ ...doc.data(), id: doc.id });
          });

          setFolder(folderData);
          setNotarizations(notarizations.sort((a, b) => compare(a, b, 'date', 'desc')));
          setIsLoading(false);
        });
      }
    });

    const unsubscribeReceipts = onSnapshot(receiptsQuery, async (querySnapshot) => {
      const receipts: any[] = [];
      querySnapshot.forEach((doc) => {
        receipts.push(doc.data());
      });

      setReceipts(receipts);
    });

    return () => {
      unsubscribeFolder();
      unsubscribeReceipts();
    };
  }, [activeGroupId]);

  return (
    <>
      <InnerPage>
        <CollectionHeader
          orderByOptions={orderByOptions}
          filtersState={filtersState}
          filtersDispatch={filtersDispatch}
          folder={folder}
          isFolder
          handleSetOrderBy={handleSetOrderBy}
          resetFilterField={resetFilterField}
          type="notarizations"
        />
        {folderState ? (
          <div className="mt-12 space-y-4">
            <SectionTitle title={t('collection.title_notarizations')} />

            <NotarizationsList
              notarizations={notarizations}
              receipts={receipts}
              folders={folderState.folders}
              isLoading={isLoading}
              filtersState={filtersState}
              orderBy={orderBy}
              handleShowSidebar={handleShowSidebar}
            />
          </div>
        ) : (
          <div>no folder</div>
        )}
      </InnerPage>
    </>
  );
};

export default Folder;
