import { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Field, Input, Select, Typography } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import CustomTooltip from '../../../../components/tooltips/CustomTooltip';
import { Nft, Template } from '../../imports/types';
import Preview from './Preview';
import { useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

interface IRedeemStep
  extends Omit<
    ExportedProps,
    | 'trigger'
    | 'register'
    | 'reset'
    | 'clearErrors'
    | 'getValues'
    | 'watch'
    | 'isValid'
    | 'dirtyFields'
  > {
  isLoading: boolean;
  contractsLeft: number;
  values: any;
  onBack: () => void;
  onCompleted: () => void;
  templates: Template[];
}

const RedeemStep = ({
  fields,
  contractsLeft,
  errors,
  values,
  templates,
  setValue,
  isLoading,
  onBack,
  onCompleted,
}: IRedeemStep) => {
  const { t } = useTranslation(['tokenCreator']);
  const { qrCodeDrop, doesDropDate } = values;
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;

  const modeOptions = [
    {
      value: '1',
      label: t('create_contract.form_labels.redeem_via_qr_code'),
    },
    {
      value: '0',
      label: t('create_contract.form_labels.manual_redeem'),
    },
  ];

  const options = [
    {
      value: template.dropDowns.redeemViaQrCode[0].value,
      label: template.dropDowns.redeemViaQrCode[0].label[language],
      isHidden:
        values.nfts.reduce((acc: boolean, cur: Nft) => acc || cur.isUnlimited, false) ||
        template.dropDowns.redeemViaQrCode[0].label[language] === '',
    },
    {
      value: template.dropDowns.redeemViaQrCode[1].value,
      label: template.dropDowns.redeemViaQrCode[1].label[language],
      isHidden: values.addCategory || template.dropDowns.redeemViaQrCode[1].label[language] === '',
    },
  ];

  const optionsAvailable = options.reduce((acc, cur) => acc || !cur.isHidden, false);

  return (
    <>
      <div className="space-y-12">
        <Typography weight="semibold">{t('create_contract.titles.redeem')}</Typography>
        <div className="grid grid-cols-2 gap-x-8 gap-y-2">
          <Preview values={values} />

          {/* Categories  */}
          {!template.fields?.qrCodeDrop?.hidden && (
            <div className="flex flex-col gap-8">
              <Field
                label={
                  template.fields?.qrCodeDrop?.label?.[language] ||
                  t('create_contract.form_labels.nft_redemption_method')
                }
                labelElementRight={
                  <div className="flex items-center space-x-2">
                    <CustomTooltip>
                      <Typography size="xs">
                        {t('create_contract.form_labels.redeem_modality_0')}
                      </Typography>
                      <Typography size="xs">
                        {t('create_contract.form_labels.redeem_modality_1')}
                      </Typography>
                    </CustomTooltip>
                  </div>
                }
              >
                <Select
                  disabled={template.fields?.qrCodeDrop?.disabled}
                  options={modeOptions.filter(({ value }) => optionsAvailable || value === '0')}
                  defaultValue={optionsAvailable ? '1' : '0'}
                  onChange={(e: BaseSyntheticEvent) => {
                    switch (e.target.selectedIndex) {
                      case 0:
                        setValue('qrCodeDrop', true);
                        setValue('qrCodeDropVal', 1);
                        break;
                      case 1:
                        setValue('qrCodeDrop', false);
                        setValue('qrCodeDropVal', 0);
                        setValue('doesDropDate', false);
                        setValue('dropDate', null);
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Field>
              {qrCodeDrop && !template.fields?.qrCodeDrop?.hidden && optionsAvailable && (
                <>
                  <div className="flex justify-between space-x-2">
                    <span> </span>
                    <CustomTooltip>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.redeem_via_qr_code.1') as string}
                      </Typography>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.redeem_via_qr_code.2') as string}
                      </Typography>
                    </CustomTooltip>
                  </div>
                  <Select
                    options={options.filter(({ isHidden }) => !isHidden)}
                    name={fields.qrCodeDropVal.name}
                    onBlur={fields.qrCodeDropVal.onBlur}
                    onChange={fields.qrCodeDropVal.onChange}
                    inputRef={fields.qrCodeDropVal.ref}
                    error={errors.qrCodeDropVal?.message}
                  />
                </>
              )}

              {qrCodeDrop && !template.fields?.doesDropDate?.hidden && (
                <div className="w-full space-y-[12px]">
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <Typography>
                        {t('create_contract.form_labels.does_drop_date') as string}
                      </Typography>
                      <CustomTooltip>
                        <Typography size="xs">
                          {t('create_contract.form_tooltips.does_drop_date') as string}
                        </Typography>
                      </CustomTooltip>
                    </div>

                    <Checkbox
                      name={fields.doesDropDate.name}
                      onBlur={fields.doesDropDate.onBlur}
                      onChange={() => {
                        if (values.doesDropDate) {
                          setValue('dropDate', '');
                        }
                        setValue('doesDropDate', !values.doesDropDate);
                      }}
                      inputRef={fields.doesDropDate.ref}
                      error={errors.doesDropDate?.message}
                      isChecked={doesDropDate}
                    />
                  </div>

                  {doesDropDate && (
                    <Input
                      type="date"
                      name={fields.dropDate.name}
                      onBlur={fields.dropDate.onBlur}
                      onChange={fields.dropDate.onChange}
                      inputRef={fields.dropDate.ref}
                      error={errors.dropDate?.message}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="mt-20 flex justify-center space-x-16">
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={onBack} type="secondary">
            {t('create_contract.form_labels.back') as string}
          </Button>
        </div>
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={onCompleted} disabled={isLoading || contractsLeft < 1}>
            {t('create_contract.form_labels.next') as string}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RedeemStep;
