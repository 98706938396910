import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartProps = {
  chartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
};

const options: {
  plugins: {
    legend: {
      display: boolean;
      position: 'left';
    };
  };
} = {
  plugins: {
    legend: {
      display: false,
      position: 'left',
    },
  },
};

const PieChart = ({ chartData }: PieChartProps) => {
  return (
    <div>
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
