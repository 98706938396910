import { BasicCard } from '..';
import { AuditBottomCard, AuditTopCard } from './index';

type AuditCardProps = {
  audit: any;
  notarizations: any;
  receipts: any;
  hasWritePermission?: boolean;
  notarized?: boolean;
};

const AuditCard = ({
  audit,
  notarizations,
  receipts,
  notarized,
  hasWritePermission,
}: AuditCardProps) => {
  return (
    <BasicCard
      top={
        <AuditTopCard
          item={audit}
          hasWritePermission={hasWritePermission}
          notarized={notarized}
          notarizations={notarizations}
          receipts={receipts}
        />
      }
      bottom={<AuditBottomCard item={audit} />}
    />
  );
};
export default AuditCard;
