import { ImageChecked } from '../../assets/images';
import Typography from '../typography/Typography';
import './loadingSpinner.css';

type LoadingSpinnerProps = {
  isCompleted?: boolean;
  title?: string;
};

const LoadingSpinner = ({ isCompleted, title }: LoadingSpinnerProps) => (
  <div className="fixed inset-0 z-50 flex size-full flex-col items-center justify-center bg-black opacity-50">
    {isCompleted ? (
      <img src={ImageChecked} alt="check" />
    ) : (
      <div className="lds-default">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    )}

    {title && (
      <Typography size="sm" weight="regular" as="p" className="z-50 p-5 text-center text-white">
        {title}
      </Typography>
    )}
  </div>
);

export default LoadingSpinner;
