import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { isEqual } from 'lodash';
import { ChangeHandler } from 'react-hook-form';
import { Icon, Image, Typography } from '..';

import { getSubscription } from '../../api/firebase';
import { usePrevious } from '../../hooks';
import { IconClose, IconExport, IconFileGeneral } from '../../icons';
import { UploadFile } from '../../imports/types';
import { ratioChecker } from '../../imports/utils';
import { isValidImageName } from '../../modules/tokenCreator/imports/utils';
import { useAppSelector } from '../../redux/hooks';

type FileUploaderProps = {
  id?: number | string;
  label: string;
  accept?: { [key: string]: string[] };
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  onChange?: (files: File[]) => void;
  defaultFiles?: File[];
  className?: string;
  name?: string;
  inputOnBlur?: ChangeHandler;
  inputRef?: any;
  onCancel?: () => void;
  ratio?: '1:1' | '16:9' | 'all';
  unlimitedSize?: boolean;
  checkStorage?: boolean;
  checkName?: boolean;
};

const FileUploader = ({
  id,
  label,
  accept,
  maxSize,
  onCancel,
  minSize,
  onChange,
  className,
  defaultFiles = [],
  maxFiles = 1,
  name,
  inputOnBlur,
  inputRef,
  ratio = 'all',
  unlimitedSize = false,
  checkStorage = false,
  checkName = false,
}: FileUploaderProps) => {
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.user);

  const memoizedDefaultFiles = useMemo(
    () => (Array.isArray(defaultFiles) ? defaultFiles : []),
    [defaultFiles]
  );
  const prevMemoized = usePrevious(memoizedDefaultFiles);

  const [files, setFiles] = useState<UploadFile[]>(
    memoizedDefaultFiles?.map((file) => {
      return Object.assign(file, { preview: URL.createObjectURL(file) });
    })
  );

  useEffect(() => {
    if (!isEqual(memoizedDefaultFiles, prevMemoized)) {
      setFiles(
        memoizedDefaultFiles?.map((file) => {
          return Object.assign(file, { preview: URL.createObjectURL(file) });
        })
      );
    }
  }, [memoizedDefaultFiles]);

  /**
   * CALCOLO DEI RATIO:
   * formula -> ratio = width / heigth
   * 1:1 -> 1000x1000 (px indicativamente)
   * 16:9 -> 1600x900 (px indicativamente)
   * lo scarto per i minimi e massimi dopo alcune prove l'ho fissato sul 20%
   * quindi le variazioni oscillano tra
   * 1:1 ->
   * 800x1000, r= 0.8
   * 1200x1000,  r= 1.2
   * 1000x800,  r= 1.25
   * 1000x1200, r= 0.83
   * 16:9 ->
   * 1600x1080, r= 1.48
   * 1600x720, r= 2.22
   * 1920x900, r= 2.67
   * 1280x900, r= 1.42
   * facendo una media tra i min e max di ogni formato ottengo i valori inseriti nell'oggetto ratioTollerance
   */

  const ratioTollerance = [
    {
      ratio: '1:1',
      min: 0.815,
      max: 1.225,
    },
    {
      ratio: '16:9',
      min: 1.45,
      max: 2.445,
    },
  ];

  const getAvailableSpace = async (size: number) => {
    const { value, error } = await getSubscription(profile.uid);
    if (value) {
      const available = value.storageLimit - value.storageUsed;
      const fileSize = size / 1024 / 1024;
      if (available - fileSize > 0) return true;
      return false;
    }
    return false;
  };

  const handleSetFile = (files: File[]) => {
    if (ratio !== 'all' && files[0]?.type.includes('image')) {
      const tollerance = ratioTollerance.find((img) => img.ratio === ratio);
      if (tollerance) {
        ratioChecker(files[0], tollerance.min, tollerance.max, (x) => {
          if (x) {
            const file = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
            setFiles([file]);
            onChange?.(files);
          } else {
            toast.error(t('file_ratio', { ratio }) as string);
          }
        });
      }
    } else if (files.length) {
      const file = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
      setFiles([file]);
      onChange?.(files);
    } else {
      setFiles([]);
      onChange?.([]);
    }
  };

  const checkFiles = async (acceptedFiles: File[]) => {
    const files = acceptedFiles.map((file) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    let size = 0;

    for (const file of files) {
      if (!unlimitedSize && !file.type.includes('csv') && file.size > 5 * 1048576) {
        handleSetFile([]);
        toast.warn(t('maxMB') as string);

        return;
      }
      size += file.size;
    }

    if (checkName) {
      for (const file of files) {
        if (!isValidImageName(file.name)) {
          handleSetFile([]);
          toast.warn(t('invalid_file_name') as string);
          return;
        }
      }
    }

    if (accept) {
      const acceptedString = Object.values(accept).toString().replaceAll('.', '');
      const accepttedArray = acceptedString.split(',');
      files.forEach((file) => {
        const type = file.type.split('/')[1];
        const allowed = accepttedArray.find((acceptFile) => acceptFile === type);
        if (allowed === undefined) {
          toast.warn(t('not_accepted_specific', { acceptedFile: acceptedString }) as string);
        }
      });
    }

    if (!unlimitedSize && size > 100 * 1048576) {
      handleSetFile([]);
      toast.warn(t('maxMB') as string);
      return;
    }

    if (checkStorage) {
      if (await getAvailableSpace(size)) {
        handleSetFile(files);
      } else {
        handleSetFile([]);
        toast.warn(t('exceded_memory') as string);
      }
    } else {
      handleSetFile(files);
    }
  };
  const onDrop = useCallback(checkFiles, [checkStorage, unlimitedSize]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept,
    maxFiles,
    // maxSize, // not working properly, so i check above
    minSize,
    multiple: maxFiles !== 1,
  });

  const {
    onBlur: onBlurRoot,
    onClick: onClickRoot,
    onDragEnter: onDragEnterRoot,
    onDragLeave: onDragLeaveRoot,
    onDragOver: onDragOverRoot,
    onDrop: onDropRoot,
    onFocus: onFocusRoot,
    onKeyDown: onKeyDownRoot,
    ref: refRoot,
    role: roleRoot,
    tabIndex: tabIndexRoot,
  } = getRootProps();

  const {
    accept: acceptInput,
    multiple: multipleInput,
    onChange: onChangeInput,
    onClick: onClickInput,
    style: styleInput,
    tabIndex: tabIndexInput,
    type: typeInput,
  } = getInputProps();

  const handleRemoveFile = (index: number) => {
    const newArray = [...files];
    newArray.splice(index, 1);
    if (onCancel) {
      onCancel();
    }
    setFiles(newArray);
    onChange?.(newArray);
  };

  const uploadFile = () => {
    document.getElementById(`fileInput_${id}`)?.click();
  };

  useEffect(() => {
    checkFiles(files);
  }, [checkStorage, unlimitedSize]);

  return (
    <div className={`flex w-full flex-col items-center ${className}`}>
      <article
        onBlur={onBlurRoot}
        onClick={onClickRoot}
        onDragEnter={onDragEnterRoot}
        onDragLeave={onDragLeaveRoot}
        onDragOver={onDragOverRoot}
        onDrop={onDropRoot}
        onFocus={onFocusRoot}
        onKeyDown={onKeyDownRoot}
        ref={refRoot}
        role={roleRoot}
        tabIndex={tabIndexRoot}
        className="flex h-[8.12rem] w-full flex-col items-center justify-center rounded-lg border border-dashed border-primary-400 p-4 text-center"
      >
        <input
          id={`fileInput_${id}`}
          accept={acceptInput}
          multiple={multipleInput}
          onChange={onChangeInput}
          onClick={onClickInput}
          tabIndex={tabIndexInput}
          type={typeInput}
          style={styleInput}
          onBlur={inputOnBlur}
          ref={inputRef}
        />

        <div className="flex size-full flex-col items-center justify-center space-y-6">
          <div>
            <Typography size="lg">{label}</Typography>
            {maxSize && (
              <Typography size="sm">
                <span>Max. {maxSize / 1000000}MB</span>
              </Typography>
            )}
          </div>

          <button
            type="button"
            className="rounded-md text-lg font-semibold text-primary-500"
            onClick={uploadFile}
          >
            <div className="flex items-center gap-2">
              {t('file_uploader_action')}
              <Icon icon={IconExport} stroke="primary-500" size="20" />
            </div>
          </button>
        </div>
      </article>

      <ul className="ml-0 mt-4 flex list-none flex-wrap justify-center gap-x-4 gap-y-2">
        {files?.length > 50 ? (
          <Typography>{t('file_uploader_maximum', { number: files.length })}</Typography>
        ) : (
          files?.map((file, index) => {
            const { preview, name, type } = file;

            return (
              <li
                className={`relative ${
                  !type.startsWith('image/') ? 'w-full' : 'size-16 list-none'
                }`}
                key={name}
              >
                {!type.startsWith('image/') ? (
                  <div className="flex w-full space-x-[12px]">
                    <Icon icon={IconFileGeneral} size="lg" />
                    <Typography>{name}</Typography>
                  </div>
                ) : (
                  <Image src={preview} alt={name} type="cover" className="size-full rounded-lg" />
                )}
                <button
                  type="button"
                  className="duration-250 absolute right-0 top-0 -translate-y-1/4 translate-x-1/4 rounded-full bg-[#EEEEEE] p-1 transition hover:bg-[#d6d6d6]"
                  onClick={() => handleRemoveFile(index)}
                >
                  <IconClose className="size-3" />
                </button>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default FileUploader;
