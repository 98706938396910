import { useState } from 'react';

interface Image {
  preview: string;
}
export const useImagePreview = (initialImage: string) => {
  const [imagePreview, setImagePreview] = useState(initialImage);

  const updateImagePreview = (newImage: Image[]) => {
    if (newImage.length > 0 && Array.isArray(newImage)) {
      setImagePreview(newImage[0]?.preview || initialImage);
    } else {
      setImagePreview(initialImage);
    }
  };

  return { imagePreview, updateImagePreview };
};
