import axios from 'axios';
import { ref, uploadBytes } from 'firebase/storage';
import { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal, { useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import { useLoadingStatusContext } from '../../context';
import { useValidation } from '../../hooks';
import useMessageSchema from '../../hooks/schemas/useMessageSchema';
import { IconLoading } from '../../icons';
import { backendEndpoint, firebaseConfig } from '../../imports/constants';
import {
  getAvailableContracts,
  getWorkspaceCreatedContracts,
  isAllNftsSelected,
} from '../../imports/contractsParsers';
import { auth, storage } from '../../imports/firebase';
import { FormMessage } from '../../imports/types';
import { getDefaultDateTimeValue, hoursOptionsForSelect } from '../../imports/utils';
import { ContractsManager } from '../../modules/tokenCreator/components';
import { Contract, Nft } from '../../modules/tokenCreator/imports/types';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import DateAndTimeSelect from '../dateAndTimeSelect/DateAndTimeSelect';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import Textarea from '../textarea/Textarea';
import Typography from '../typography/Typography';

type NewMessageModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { t } = useTranslation(['tokenCreator']);
  const { state, dispatch } = useLoadingStatusContext();
  const { required, validateEndDate } = useValidation();
  const { list: contractList } = useAppSelector((state) => state.contracts);
  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);

  const { defaultDate, defaultTime } = getDefaultDateTimeValue({ extraHours: 2 });

  const messageSchema = useMessageSchema();
  const form: FormMessage = {
    initialValues: {
      title: '',
      body: '',
      options: [] as any[],
      expireDate: false,
      expiresAt: '',
      startHours: defaultTime,
      endHours: defaultTime + 1,
      isDropDate: false,
      dropDate: defaultDate,
      image: '',
    },
    validationSchema: messageSchema,
  };
  const submit: SubmitHandler<typeof form.initialValues> = async (rawValues) => {
    try {
      dispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('messages.pending') as string,
        },
      });

      const imgId: string = Date.now().toString();

      if (typeof rawValues.image === 'object') {
        try {
          const imgRef = ref(storage, `images/${uid}/${imgId}`);
          await uploadBytes(imgRef, rawValues.image);
          const url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2F${uid}%2F${imgId}?alt=media`;
          rawValues = { ...rawValues, image: url };
        } catch (error) {
          toast.error(t(`poll.errors.image`) as string);
        }
      }
      const dropDateNoHours = new Date(rawValues.dropDate).getTime();
      const expirationDateNoHours = new Date(rawValues.expiresAt).getTime();
      const dropDate = new Date(
        dropDateNoHours + (rawValues.startHours || 0) * 60 * 60 * 1000
      ).getTime();
      const expirationDate = new Date(
        expirationDateNoHours + (rawValues?.endHours || 0) * 60 * 60 * 1000
      ).getTime();
      if (
        (expirationDate && expirationDate < Date.now()) ||
        (dropDate && dropDate > expirationDate)
      ) {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('messages.errors.date') as string,
          },
        });
      }

      const options: {
        contractId: string;
        nfts: string[];
      }[] = rawValues.options.map((contract: Contract) => {
        return {
          contractId: contract.id,
          nfts: contract.nfts.map((option: Nft) => option.id.toString()),
        };
      });

      await axios.post(
        `${backendEndpoint}/send-message`,
        {
          message: {
            title: rawValues.title,
            description: rawValues.body,
            expiresAt: rawValues.expiresAt ? expirationDate : undefined,
            dropDate: rawValues.isDropDate ? dropDate : undefined,
            image: rawValues.image ? rawValues.image : undefined,
          },
          all: isAllNftsSelected(
            rawValues.options,
            getWorkspaceCreatedContracts(workspace?.id, contractList)
          ),
          options,
        },
        {
          headers: {
            authorization: (await auth.currentUser?.getIdToken()) ?? '',
          },
        }
      );
      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('messages.success') as string,
        },
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('messages.errors.send') as string,
        },
      });
    }
  };

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="overflow-auto max-w-[95%] max-h-[90%]"
    >
      {state.status === 'pending' ? (
        <div className="mt-[100px] flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <div className="!pb-0 ">
          <Typography className="mt-[30px] pl-[20px]" size="body-medium-30">
            {t('messages.new_message')}
          </Typography>
          <Form initialValues={form.initialValues} validationSchema={form.validationSchema}>
            {({ fields, errors, setValue, handleSubmit, watch, resetField }) => {
              const values = watch();
              if (errors.dropDate?.type === 'dropDate') {
                toast.warning(t('messages.dropDateError') as string);
              }
              if (
                errors.startHours?.type === 'compareTime' ||
                errors.endHours?.type === 'startHours'
              ) {
                toast.warning(t('messages.errors.same_day_end_hour_lower') as string);
              }
              if (errors.startHours?.type === 'twoHoursAdvance') {
                toast.warning(t('messages.dropDateBeforeTwoHours') as string);
              }
              // add a check for the drop date === today and the start hours < defaultTime

              return (
                <>
                  <div className="mt-[40px] flex gap-12 px-[20px]">
                    <section className=" ml-0  mr-auto flex w-[450px] min-w-[250px] flex-col space-y-[20px] ">
                      <Field
                        label={t('messages.title')}
                        error={errors.title?.message}
                        asterisk
                        info
                        infoText={t('messages.title_tooltip')}
                      >
                        <Textarea
                          resize
                          rows={1}
                          name={fields.title.name}
                          id={fields.title.name}
                          onChange={fields.title.onChange}
                          onBlur={fields.title.onBlur}
                          inputRef={fields.title.ref}
                          placeholder={t('messages.title_placeholder')}
                          className="mt-[5px]"
                        />
                      </Field>
                      <Field
                        label={t('messages.content')}
                        error={errors.body?.message}
                        asterisk
                        info
                        infoText={t('messages.text_tooltip')}
                      >
                        <Textarea
                          resize
                          rows={10}
                          name={fields.body.name}
                          id={fields.body.name}
                          onChange={fields.body.onChange}
                          onBlur={fields.body.onBlur}
                          inputRef={fields.body.ref}
                          placeholder={t('messages.content_placeholder')}
                          className="mt-[5px]"
                        />
                      </Field>
                    </section>

                    <div className="mx-auto h-[400px] w-[1px] bg-grey-400 py-[30px]"></div>
                    <section className="ml-auto  mr-0 flex w-full  max-w-[450px] flex-col gap-[12px] ">
                      {/* programmed messages */}
                      <div className="w-[200px] space-y-[12px]">
                        <div className="flex w-full items-center justify-between ">
                          <Field
                            label={t('messages.dropDate')}
                            info
                            infoText={t('messages.dropDateTooltip')}
                            className="mt-[5px]"
                          >
                            <div></div>
                          </Field>
                          <Checkbox
                            name={fields.isDropDate.name}
                            onBlur={fields.isDropDate.onBlur}
                            onChange={() => {
                              setValue('isDropDate', !values.isDropDate);
                            }}
                            inputRef={fields.isDropDate.ref}
                            error={errors.isDropDate?.message}
                          />
                        </div>
                      </div>
                      {values.isDropDate && (
                        <div className="flex w-full items-center justify-between gap-4">
                          <DateAndTimeSelect
                            date={{
                              label: t('messages.startDate'),
                              error: errors.dropDate?.message,
                              name: fields.dropDate.name,
                              onBlur: fields.dropDate.onBlur,
                              onChange: fields.dropDate.onChange,
                              inputRef: fields.dropDate.ref,
                              minValue: defaultDate,
                            }}
                            time={{
                              label: t('messages.startHours'),
                              error: errors.startHours?.message,
                              options: hoursOptionsForSelect,
                              name: fields.startHours.name,
                              onBlur: fields.startHours.onBlur,
                              onChange: fields.startHours.onChange,
                              inputRef: fields.startHours.ref,
                            }}
                          />
                        </div>
                      )}
                      {/* expires at */}
                      <div className="mt-5 w-[200px] space-y-[12px]">
                        <div className="flex w-full items-center justify-between gap-4">
                          <Field
                            label={t('messages.expiresAt')}
                            info
                            infoText={t('messages.expireDateTooltip')}
                          >
                            <div></div>
                          </Field>
                          <Checkbox
                            name={fields.expireDate.name}
                            onBlur={fields.expireDate.onBlur}
                            onChange={() => {
                              setValue('expireDate', !values.expireDate);
                              if (values.expireDate) {
                                setValue('expiresAt', defaultDate);
                              }
                            }}
                            inputRef={fields.expireDate.ref}
                            error={errors.expireDate?.message}
                          />
                        </div>
                      </div>
                      {values.expireDate && (
                        <div className="flex w-full items-center justify-between gap-4">
                          <DateAndTimeSelect
                            date={{
                              label: t('messages.endDate'),
                              name: fields.expiresAt.name,
                              onBlur: fields.expiresAt.onBlur,
                              onChange: fields.expiresAt.onChange,
                              inputRef: fields.expiresAt.ref,
                              error: errors.expiresAt?.message,
                              minValue: values.dropDate ?? defaultDate,
                            }}
                            time={{
                              label: t('messages.endHours'),
                              options: hoursOptionsForSelect,
                              name: fields.endHours.name,
                              onBlur: fields.endHours.onBlur,
                              onChange: fields.endHours.onChange,
                              inputRef: fields.endHours.ref,
                              error: errors.endHours?.message,
                            }}
                          />
                        </div>
                      )}

                      <Field
                        label={t('messages.add_image')}
                        error={errors.img?.message || values.image?.size > 5000000}
                        className="col-span-2 mt-3 "
                        info
                        infoText={t('messages.img_info')}
                      >
                        <Input
                          type="file"
                          name={fields.image.name}
                          onBlur={fields.image.onBlur}
                          error={errors.imgage?.message || values.image?.size > 5000000}
                          onChange={(e) => {
                            setValue('image', (e.target as HTMLInputElement)?.files?.[0]);
                          }}
                          className="mt-[5px]"
                        />

                        {values.image?.size > 5000000 && (
                          <div>
                            <Typography size="body-regular-12" color="error">
                              {t('messages.image_size_error')}
                            </Typography>
                          </div>
                        )}
                      </Field>
                    </section>
                  </div>
                  <div className="my-8 flex flex-col px-[20px]">
                    <Field
                      label={t('messages.option')}
                      asterisk
                      info
                      infoText={t('messages.select_tooltip')}
                    >
                      <div className="!max-h-[400px] overflow-auto">
                        <ContractsManager
                          availableContracts={getAvailableContracts(values.options)}
                          setContracts={(values: Contract[]) => setValue('options', values)}
                        />
                      </div>
                    </Field>

                    <div className="mt-4 flex w-full items-end justify-end pt-[32px]">
                      <Button
                        action={handleSubmit(submit)}
                        className="w-[200px]"
                        disabled={
                          values.image?.size > 5000000 ||
                          !values.body ||
                          !values.title ||
                          !values.options.length
                        }
                      >
                        {t('messages.send')}
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      )}
    </ModalLayout>
  );
};

const NewMessageModal = ({ children }: NewMessageModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="NewMessageModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default NewMessageModal;
