import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
/* root imports */
import { CustomizedText, Icon, Image, InnerPage, Tag, Typography } from '../../../../components';
import { IconArrow, IconLoading } from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';
/* tokenCreator imports */
import 'swiper/css';
import 'swiper/css/navigation';
import { getNftAnalytics } from '../../../../imports/analyticsParser';
import { getContractFromContractId } from '../../../../imports/contractsParsers';
import { formatIPFSUri } from '../../../../imports/utils';
import {
  AnalyticsComponent,
  DetailsField,
  NftDetailsCard,
  NftDetailsTable,
} from '../../components';
import type { Contract } from '../../imports/types';
import i18n from '../../../../i18n';

type NftDetailsProps = {
  isLoading: boolean;
  contract: Contract | null;
  nftId?: string;
};

const NftDetails = ({ contract, nftId, isLoading }: NftDetailsProps) => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const { list: contractList } = useAppSelector((state) => state.contracts);
  const { id: contractId } = useParams();

  const template = useAppSelector((state) => state.template);
  const language = i18n.language;

  const nft = contract?.nfts.find((nft) => nft.id === Number(nftId));

  const [analytics, setAnalytics] = useState<any>();

  useEffect(() => {
    if (contractId && nft) {
      const activities = getContractFromContractId(contractId, contractList)?.activities;
      setAnalytics(getNftAnalytics(activities || [], nft.id));
    }
  }, [contractId, nft, contractList]);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex h-screen grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!nft ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('collection_item.nft_not_found_title')}
              </Typography>

              <Icon
                icon={IconArrow}
                onClick={() => navigate(-1)}
                size="40"
                stroke="primary-500"
                className="mb-3 cursor-pointer rounded-md"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <div className="flex">
                <Icon
                  icon={IconArrow}
                  onClick={() => navigate(-1)}
                  size="40"
                  stroke="primary-500"
                  className="mb-3 cursor-pointer rounded-md"
                />
              </div>
              <div className="flex flex-row gap-8 ">
                <Image
                  src={formatIPFSUri(nft.image as string)}
                  type="cover"
                  className="h-[262px] w-auto rounded-lg"
                />
                <div className="flex flex-col">
                  <div className="flex flex-row gap-8">
                    <div className="flex flex-col gap-8">
                      <Typography size="body-medium-30" weight="semibold">
                        {nft.name}
                      </Typography>
                      <div className="flex flex-col gap-1">
                        {DetailsField(t('collection_item.label_fields.by') as string, '')}
                        <Typography color="primary-500">{contract?.owner}</Typography>
                      </div>
                    </div>
                    {!template.fields?.tag?.hidden &&
                      contract?.tag &&
                      contract?.tag?.length > 0 && (
                        <div className="flex space-x-2">
                          {contract?.tag.map((tag) => (
                            <Tag key={tag} title={tag} />
                          ))}
                        </div>
                      )}
                  </div>
                  <div className="flex">
                    <CustomizedText text={nft?.description} />
                  </div>
                  {contract && <NftDetailsCard contract={contract} nft={nft} />}
                </div>
              </div>
              <AnalyticsComponent
                label={t('analytics.nft')}
                analytics={analytics ? analytics.sum : undefined}
                to={`/nft/analytics/${contractId}/${nftId}`}
              />

              {contract && analytics && analytics.nftActivities && (
                <NftDetailsTable analytics={analytics.nftActivities} contract={contract} />
              )}
            </div>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default NftDetails;
