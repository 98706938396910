/* eslint-disable import/no-extraneous-dependencies */
import { Env } from '@bcode-tech/bcode-sdk';
import resolveConfig from 'tailwindcss/resolveConfig';
import packageJson from '../../package.json';
import tailwindConfig from '../../tailwind.config';
// const PACKAGE_VERSION = require("../../package.json").version;

import { mockContractAndNftCreationGetter } from './utils';

import type { Environment, SidebarItems, TopbarItems } from './types';

export const APP_VERSION = packageJson.version;
export const environments = {
  DEV: 'DEV',
  DEMO: 'DEMO',
  PROD: 'PROD',
};

export const currentEnv = (process.env.REACT_APP_ENV as Environment) ?? environments.DEV;
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;
export const rpcProvider = process.env.REACT_APP_RPC_PROVIDER as string;
export const explorerPolygon = process.env.REACT_APP_EXPLORER_POLYGON as string;
export const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT as string;
export const redeemer = process.env.REACT_APP_REDEEMER as string;
export const redeemerEvents = process.env.REACT_APP_REDEEMER_EVENTS as string;
export const freeIpfsGateway = process.env.REACT_APP_FREE_GATEWAY as string;
export const paidIpfsGateway = process.env.REACT_APP_PAID_GATEWAY as string;
export const dsnSentry = process.env.REACT_APP_DSN_SENTRY as string;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messaggingSenderId: process.env.REACT_APP_MESSAGGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  databaseURL: process.env.REACT_APP_RTDB_FIREBASE,
};

export const chainsByEnvironment: { [key: string]: { [key in Environment]: string } } = {
  polygon: {
    DEV: 'https://oklink.com/amoy',
    DEMO: 'https://oklink.com/amoy',
    PROD: 'https://polygonscan.com',
  },
};

export const BLURRED_EMAIL = 'ehvolevi@volevi.com';

export const mediaQuery = {
  isMobile: '(max-width: 768px)',
  isTablet: '(min-width: 768px)',
  isDesktop: '(min-width: 1024px)',
};

export const sidebarModulesMenuOrderList: SidebarItems[] = [
  'notarization',
  'audit_db',
  'nft',
  'crm',
];
export const sidebarProfileMenuOrderList: SidebarItems[] = ['profile', 'logout'];

export const topbarModulesMenuOrderList: TopbarItems[] = [
  'notarization',
  'audit_db',
  'analyics',
  'nft',
  'crm',
  'api',
];
export const topbarProfileMenuOrderList: SidebarItems[] = ['profile', 'logout'];

export const {
  theme: { colors },
} = resolveConfig(tailwindConfig);

export const textColors = {
  'primary-100': 'text-primary-100',
  'primary-400': 'text-primary-400',
  'primary-500': 'text-primary-500',
  'secondary-100': 'text-secondary-100',
  'secondary-500': 'text-secondary-500',
  'secondary-800': 'text-secondary-800',
  'grey-50': 'text-grey-50',
  'grey-100': 'text-grey-100',
  'grey-200': 'text-grey-200',
  'grey-300': 'text-grey-300',
  'grey-400': 'text-grey-400',
  'grey-500': 'text-grey-500',
  'grey-600': 'text-grey-600',
  success: 'text-success',
  warning: 'text-warning',
  error: 'text-error',
  white: 'text-white',
  black: 'text-black',
  inherit: 'text-inherit',
};

export const hoverColors = {
  'primary-100': 'hover:text-primary-100',
  'primary-400': 'hover:text-primary-400',
  'primary-500': 'hover:text-primary-500',
  'secondary-100': 'hover:text-secondary-100',
  'secondary-500': 'hover:text-secondary-500',
  'secondary-800': 'hover:text-secondary-800',
  'grey-50': 'hover:text-grey-50',
  'grey-100': 'hover:text-grey-100',
  'grey-200': 'hover:text-grey-200',
  'grey-300': 'hover:text-grey-300',
  'grey-400': 'hover:text-grey-400',
  'grey-500': 'hover:text-grey-500',
  'grey-600': 'hover:text-grey-600',
  success: 'hover:text-success',
  warning: 'hover:text-warning',
  error: 'hover:text-error',
  white: 'hover:text-white',
  black: 'hover:text-black',
  inherit: 'hover:text-inherit',
};

export const decorationColors = {
  'primary-100': 'hover:decoration-primary-100',
  'primary-400': 'hover:decoration-primary-400',
  'primary-500': 'hover:decoration-primary-500',
  'secondary-100': 'hover:decoration-secondary-100',
  'secondary-500': 'hover:decoration-secondary-500',
  'secondary-800': 'hover:decoration-secondary-800',
  'grey-50': 'hover:decoration-grey-50',
  'grey-100': 'hover:decoration-grey-100',
  'grey-200': 'hover:decoration-grey-200',
  'grey-300': 'hover:decoration-grey-300',
  'grey-400': 'hover:decoration-grey-400',
  'grey-500': 'hover:decoration-grey-500',
  'grey-600': 'hover:decoration-grey-600',
  success: 'hover:decoration-success',
  warning: 'hover:decoration-warning',
  error: 'hover:decoration-error',
  white: 'hover:decoration-white',
  black: 'hover:decoration-black',
  inherit: 'hover:decoration-inherit',
};

export const fillColors = {
  'primary-100': 'fill-primary-100',
  'primary-400': 'fill-primary-400',
  'primary-500': 'fill-primary-500',
  'secondary-100': 'fill-secondary-100',
  'secondary-500': 'fill-secondary-500',
  'secondary-800': 'fill-secondary-800',
  'grey-50': 'fill-grey-50',
  'grey-100': 'fill-grey-100',
  'grey-200': 'fill-grey-200',
  'grey-300': 'fill-grey-300',
  'grey-400': 'fill-grey-400',
  'grey-500': 'fill-grey-500',
  'grey-600': 'fill-grey-600',
  success: 'fill-success',
  warning: 'fill-warning',
  error: 'fill-error',
  white: 'fill-white',
  black: 'fill-black',
  inherit: 'fill-inherit',
};

export const COLORS = {
  'primary-100': '#f1eafe',
  'primary-400': '#9368fe',
  'primary-500': '#642eff',
  'secondary-100': '#F5F9FF',
  'secondary-500': '#1c63fe',
  'secondary-800': '#101052',
  'grey-50': '#fff0',
  'grey-100': '#F7F7F7',
  'grey-200': '#E0E0E0',
  'grey-300': '#CFD0D5',
  'grey-400': '#828282',
  'grey-500': '#4F4F4F',
  'grey-600': '#333333',
  success: '#118900',
  warning: '#FFBF00',
  error: '#D64045',
  white: '#FFFFFF',
  black: '#0E0E10',
  inherit: 'fill-inherit',
};

export const contractAndNftsCreationRates = {
  contractCreation: ({ value = 10, multiplier = 1 }: { value?: number; multiplier?: number }) =>
    mockContractAndNftCreationGetter(value, multiplier),
  expiration: ({ value, multiplier }: { value: 'contract' | 'mint'; multiplier: number }) =>
    mockContractAndNftCreationGetter(value === 'contract' ? 5 : 10, multiplier),
  category: ({ value = 0.0, multiplier }: { value?: number; multiplier: number }) =>
    mockContractAndNftCreationGetter(value, multiplier),
  quantity: ({ value = 0.03, multiplier }: { value?: number; multiplier: number }) =>
    mockContractAndNftCreationGetter(value, multiplier),
  bcodeFee: ({ value, multiplier = 0.1 }: { value: number; multiplier?: number }) =>
    mockContractAndNftCreationGetter(value, multiplier),
};

export const contractCreationFee = 0.1;

export const formFieldsWithMultipleOptions = {
  'create-contract': {
    tag: ['gaming', 'sport', 'music'],
    chain: ['polygon'],
    expirationFrom: ['mint', 'contract'],
    transfer: ['creator', 'owners', 'creator&owner'],
    //transfer: ['creator', 'owners'],
    update: ['creator', 'owners', 'specificList'],
    status: ['new', 'paid', 'deployed'],
  },
};

export const roles = {
  guest: 'guest',
  consumer: 'consumer',
  unverified: 'unverified',
  taker: 'taker',
};

export const SECURITY_QUESTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: 'fav-breed-dog',
    label: 'security_questions.fav_breed_dog',
  },
  {
    value: 'fav-movie',
    label: 'security_questions.fav_movie',
  },
  {
    value: 'first-concert',
    label: 'security_questions.first_concert',
  },
  {
    value: 'city-parent-meet',
    label: 'security_questions.city_parent_meet',
  },
  {
    value: 'first-car',
    label: 'security_questions.first_car',
  },
  {
    value: 'first-pet-name',
    label: 'security_questions.first_pet_name',
  },
];

export const pablockApiKey = process.env.REACT_APP_BCODE_API_KEY;

export const pablockConfig = {
  env: process.env.REACT_APP_SDK_ENV as Env,
  debugMode: true,
  rpcProvider,
  pablockContracts: {
    PABLOCK_TOKEN_ADDRESS: process.env.REACT_APP_BCODE_TOKEN_ADDRESS,
    PABLOCK_META_TRANSACTION: process.env.REACT_APP_BCODE_META_TRANSACTION,
    PABLOCK_NOTARIZATION: process.env.REACT_APP_BCODE_NOTARIZATION,
    PABLOCK_NFT: process.env.REACT_APP_BCODE_NFT,
    PABLOCK_MULTISIGN_FACTORY: process.env.REACT_APP_BCODE_MULTISIGN_FACTORY,
    PABLOCK_TEAM_STORAGE: process.env.REACT_APP_BCODE_TEAM_STORAGE,
  },
};

export const DEFAULT_USER_IMAGE = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2Fpropic.png?alt=media&token=cb6dd1d9-1188-48ba-8787-64c97bca0816`;

// Indice 0 sarà sempre false perchè nel contratto partono tutti a false
// export const MODULES = {
//   TEAM: ethers.utils.keccak256(Buffer.from('TEAM')), // Indice 1 onchain
//   NOTARIZATION: ethers.utils.keccak256(Buffer.from('NOTARIZATION')), //Indice 2
//   TOKEN_CREATOR: ethers.utils.keccak256(Buffer.from('TOKEN_CREATOR')), //Indice 3
//   AUDIT: ethers.utils.keccak256(Buffer.from('AUDIT')), //Indice 4
//   CRM: ethers.utils.keccak256(Buffer.from('CRM')), // Non presente onchain
// };

// export const CHAIN_MODULES = {
//   TEAM: ethers.utils.keccak256(Buffer.from('TEAM')), // Indice 1 onchain
//   NOTARIZATION: ethers.utils.keccak256(Buffer.from('NOTARIZATION')), //Indice 2
//   TOKEN_CREATOR: ethers.utils.keccak256(Buffer.from('TOKEN_CREATOR')), //Indice 3
//   AUDIT: ethers.utils.keccak256(Buffer.from('AUDIT')), //Indice 4
//   // CRM: ethers.utils.keccak256(Buffer.from('CRM')), // Non presente onchain
// };

export const MODULES = ['TEAM', 'NOTARIZATION', 'TOKEN_CREATOR', 'AUDIT', 'CRM'];
export const CHAIN_MODULES = ['TEAM', 'NOTARIZATION', 'TOKEN_CREATOR', 'AUDIT'];

export const privacyCheckUrl =
  'https://firebasestorage.googleapis.com/v0/b/databox-dev-2a26f.appspot.com/o/documents%2FappConsents%2FInformativa%20Privacy%20Demo%20BCode.docx.pdf?alt=media&token=5d613cae-1597-487a-b983-dbc8c03d4505';
export const bcodeConsentUrl =
  'https://firebasestorage.googleapis.com/v0/b/databox-dev-2a26f.appspot.com/o/documents%2FappConsents%2FCondizioni%20Generali%20Demo%20Bcode.docx.pdf?alt=media&token=ae164104-88c8-4d9b-bb39-92fcabd35cf8';

// export const allowedFilesType = getRemoteConfigValue('allowedFiles', "json")

export const FIREBASECHANGEEVENTS = {
  ADDED: 'added',
  MODIFIED: 'modified',
  REMOVED: 'removed',
};

export const hexColors = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

export const SPECIAL_SYMBOLS = [
  { char: '%', sub: '%25' },
  { char: ' ', sub: '%20' },
  { char: '!', sub: '%21' },
  { char: '"', sub: '%22' },
  { char: '#', sub: '%23' },
  { char: '$', sub: '%24' },
  { char: '&', sub: '%26' },
  { char: "'", sub: '%27' },
  { char: '(', sub: '%28' },
  { char: ')', sub: '%29' },
  { char: '*', sub: '%2A' },
  { char: '+', sub: '%2B' },
  { char: ',', sub: '%2C' },
  { char: '-', sub: '%2D' },
  { char: '/', sub: '%2F' },
  { char: ':', sub: '%3A' },
  { char: ';', sub: '%3B' },
  { char: '<', sub: '%3C' },
  { char: '=', sub: '%3D' },
  { char: '>', sub: '%3E' },
  { char: '?', sub: '%3F' },
  { char: '@', sub: '%40' },
  { char: '[', sub: '%5B' },
  { char: ']', sub: '%5D' },
  { char: '^', sub: '%5E' },
  { char: '_', sub: '%5F' },
  { char: '`', sub: '%60' },
  { char: '{', sub: '%7B' },
  { char: '|', sub: '%7C' },
  { char: '}', sub: '%7D' },
  { char: '~', sub: '%7E' },
  { char: '€', sub: '%E2%82%AC' },
  { char: '£', sub: '%C2%A3' },
  { char: '¥', sub: '%C2%A5' },
  { char: '§', sub: '%C2%A7' },
  { char: '©', sub: '%C2%A9' },
  { char: '°', sub: '%C2%B0' },
  { char: 'ç', sub: '%C3%A7' },
  { char: '÷', sub: '%C3%B7' },
];

export const NFT_ATTRIBUTES = [
  'text',
  'document',
  'image',
  'warranty_certificate',
  'partner',
  'authenticity_certificate',
  'notarizations',
];

export const NFT_FUNCTIONALITIES = ['restricted_area', 'poll', 'shop', 'product', 'news'];
export const NON_SCROLLABLE_PATH = ['/nft/create-polls', '/nft/collection'];
