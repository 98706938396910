import { BcodeSDK } from '@bcode-tech/bcode-sdk';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
/* root imports */
import axios from 'axios';
import { maxBy, range, sortBy } from 'lodash';
import { useModal } from 'react-simple-modal-provider';
import { Form, InnerPage } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { IconLoading } from '../../../../icons';
import {
  NFT_ATTRIBUTES,
  NFT_FUNCTIONALITIES,
  backendEndpoint,
  freeIpfsGateway,
  pablockApiKey,
  pablockConfig,
} from '../../../../imports/constants';
import type { UserState } from '../../../../imports/types';
import { capitalizeFirstLetter, delayed, formatImage } from '../../../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
/* tokenCreator imports */
import addCategoriesAbi from '../../../../abi/addCategories';
import { useLoadingStatusContext } from '../../../../context';
import { useLoading } from '../../../../context/LoadingContext';
import { auth } from '../../../../imports/firebase';
import { generateMerkleRoot } from '../../../../imports/merkleTree';
import { generateSigners, getRTDBSigners } from '../../../../imports/signers';
import { storeAttributedData } from '../../../../imports/storage';
import { ContractsState } from '../../../../redux/contracts/contracts.slice';
import { setUpdateRequestId } from '../../../../redux/operations/operations.slice';
import { uploadNftsIpfs } from '../../api/ipfs';
import { AddCategoriesStep } from '../../components';
import { EMPTY_NFT } from '../../imports/constants';
import type { Contract, Nft } from '../../imports/types';
import { isUrl } from '../../imports/utils';
import { bcodeSDK } from '../../../../imports/bcodeSDK';

const AddCategories = () => {
  const { t: generalT } = useTranslation();
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { required, validateFileImage } = useValidation();
  const [nfts, setNfts] = useState<Contract['nfts']>([]);
  const { dispatch: loadingContext, state } = useLoadingStatusContext();
  const { open: openLoadingModal } = useModal('LoadingModal');
  const dispatch = useAppDispatch();
  const { workspace } = useAppSelector((state) => state.team);
  const { id: contractId } = useParams();
  const { isLoading, setLoading } = useLoading();
  const { privateKey, contract } = useAppSelector(
    ({ user, contracts }: { user: UserState; contracts: ContractsState }) => ({
      privateKey: user.wallet.privateKey,
      contract: contracts.list.find(({ id }) => id === contractId),
    })
  );

  const contractForm = {
    initialValues: {
      nfts: [EMPTY_NFT],
    },
    validationSchema: yup.object({
      nfts: yup.array().of(
        yup.object().shape({
          name: required(yup.string()),
          description: required(yup.string()),
          quantity: required(yup.number().min(0)),
          external_url: yup.string(),
          isUnlimited: required(yup.boolean()),
          image: yup.array().of(validateFileImage()).min(1, generalT('form_validation.required')),
          // attributes: yup
          //   .array()
          //   .of(
          //     yup
          //       .object()
          //       .shape({ trait_type: required(yup.string()), value: required(yup.string()) })
          //   ),
          attributes: yup.array().of(
            yup.object().shape({
              private: yup.boolean(),
              trait_type: required(yup.string()),
              type: required(yup.string()),
              file: yup.array(),
              value: yup.mixed().when(['file', 'type', 'link'], {
                is: (file: any, type: string, link: string) =>
                  !file?.[0]?.name && type !== 'text' && !link && type !== 'notarizations',
                then: yup.mixed().when('type', {
                  is: 'partner',
                  then: yup
                    .string()
                    .test(
                      'check-non-empty',
                      generalT('form_validation.required'),
                      (url) => !!url && isUrl(url)
                    ),
                  otherwise: yup.mixed().when('type', {
                    is: (type: string) => NFT_FUNCTIONALITIES.includes(type),
                    then: yup.mixed(),
                    otherwise: yup.string().required(generalT('form_validation.required')),
                  }),
                }),
                otherwise: yup.mixed().when('type', {
                  is: (type: string) => type !== 'image',
                  then: yup.string().required(generalT('form_validation.required')),
                }),
              }),
              link: yup
                .string()
                .ensure()
                .when(['file', 'type'], {
                  is: (file: any, type: string) => !file?.[0]?.name && type === 'partner',
                  then: yup
                    .string()
                    .test(
                      'check-non-empty',
                      generalT('form_validation.required'),
                      (url) => !!url && isUrl(url)
                    ),
                }),
            })
          ),
        })
      ),
    }),
  };

  const handleLoadingModal = () => {
    openLoadingModal({ goTo: navigate, type: 'contract' });
  };

  /**
   * @description This function is called when user wants to add new categories for his contract
   * Endpoints are called in this function:
   * - /uploadFile (storeAttrbuteData => uploadFilesOnStorage)
   * - /uploadMetadataToIpfs (uploadNftsIpfs)
   * - /addCategories
   * @param values
   * @returns Visual results
   */
  const handleAddCategories: SubmitHandler<typeof contractForm.initialValues> = async (values) => {
    let newNfts = values.nfts as unknown as Nft[];

    if (!contract) {
      toast.warn(t('update.nothing_updated') as string);
      return;
    }

    try {
      loadingContext({
        type: 'SET_PENDING',
        payload: {
          title: t('update.pending'),
        },
      });

      handleLoadingModal();

      if (workspace?.id && contract) {
        newNfts = await Promise.all(
          storeAttributedData(
            newNfts,
            { name: contract?.name, contractId: contract?.id },
            workspace?.id
          )
        );
      }

      /**
       * Upload token metadata and image to IPFS, but only the public nft attributes
       */
      const { metadataCid, imagesCid } = await uploadNftsIpfs(
        [
          ...nfts,
          ...newNfts.map((nft) => ({
            ...nft,
            attributes: nft.attributes?.filter(
              (attribute) => !attribute.private && NFT_ATTRIBUTES.includes(attribute.type)
            ),
          })),
        ],
        contract!.id,
        workspace?.id || ''
      );

      // Get the updated data of the nfts that has changed
      const lastId = maxBy(nfts, 'id')?.id || 0;

      newNfts = newNfts.map((nft, index) => {
        const imageName = formatImage((nft.image as File[]).at(0)?.name ?? '');

        return {
          ...nft,
          image: `${freeIpfsGateway}/${imagesCid}/${imageName}`,
          id: lastId + index + 1,
          quantity: Number(nft.quantity),
        };
      });

      try {
        if (!bcodeSDK.isInitialized()) {
          await bcodeSDK.init();
        }

        bcodeSDK.setPrivateKey(privateKey);

        const signers = [
          ...(await getRTDBSigners({
            contractId: contract?.id,
            tokens: contract.maxSupplyPerRarity.length - 1,
          })),
          ...generateSigners(newNfts.map(({ quantity }) => quantity)),
        ];

        // const signers = generateSigners(newNfts.map(({ quantity }) => quantity));
        const merkleRoot = generateMerkleRoot(signers);

        const metaTx = await bcodeSDK.prepareTransaction(
          {
            address: contract!.address,
            abi: addCategoriesAbi,
            name: capitalizeFirstLetter(contract!.name).replace(/\s/g, ''),
            version: '1.0',
          },
          'addCategories',
          [
            merkleRoot,
            // range(nfts.length - 1, nfts.length + newNfts.length),
            `${freeIpfsGateway}/${metadataCid}/`,
          ]
        );

        const {
          data: { requestId, error },
        } = await axios.post(
          `${backendEndpoint}/addCategories`,
          {
            contractId,
            nfts: newNfts,
            metadataCid,
            signers: signers.slice(nfts.length),
            merkleRoot,
            metaTx,
            startIndex: contract.nfts.length,
          },
          {
            headers: {
              authorization: (await auth.currentUser?.getIdToken()) as string,
              contractId: contract.id,
              workspaceId: workspace?.id || '',
            },
          }
        );
        if (!error) {
          dispatch(setUpdateRequestId(requestId));
        } else {
          loadingContext({
            type: 'SET_ERROR',
            payload: {
              title: t('update.failed'),
            },
          });
          delayed(() => {
            navigate(`/nft/collection/${contractId}`);
            loadingContext({
              type: 'RESET_STATE',
            });
          }, 2500);
        }
      } catch (e) {
        console.error(e);
      }

      loadingContext({
        type: 'SET_SUCCESS',
        payload: {
          title: t('update.success'),
        },
      });
    } catch (err) {
      console.error(err);
      loadingContext({
        type: 'SET_ERROR',
        payload: {
          title: t('update.failed'),
        },
      });
      delayed(() => {
        navigate(`/nft/collection/${contractId}`);
        loadingContext({
          type: 'RESET_STATE',
        });
      }, 2500);
    }
  };

  useEffect(() => {
    setLoading({ loading: true });

    if (contract?.nfts?.length) {
      setNfts(sortBy(contract.nfts, ['id']));
    }

    setLoading({ loading: false });
  }, []);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex h-screen grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : nfts?.length ? (
        <Form
          validationSchema={contractForm.validationSchema}
          initialValues={contractForm.initialValues}
        >
          {({
            errors,
            handleSubmit,
            watch,
            resetField,
            fields,
            control,
            register,
            setValue,
            dirtyFields,
          }) => {
            const values = watch();

            return (
              <AddCategoriesStep
                watch={watch}
                title={t('addCategories.title')}
                ctaLabel={t('addCategories.submit')}
                errors={errors}
                handleSubmit={handleSubmit}
                resetField={resetField}
                values={values}
                control={control}
                register={register}
                setValue={setValue}
                onBack={() => navigate(-1)}
                isLoading={false}
                action={() => handleAddCategories(values)}
                dirtyFields={dirtyFields}
                fields={fields}
                contract={contract}
              />
            );
          }}
        </Form>
      ) : (
        ''
      )}
    </InnerPage>
  );
};

export default AddCategories;
