import { createCipheriv, createDecipheriv, randomBytes } from 'crypto-browserify';
import { ethers } from 'ethers';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import forge from 'node-forge';

export const hashPassword = (password: string, sha: 'sha1' | 'sha256' = 'sha1'): string => {
  const md = forge.md[sha].create();

  md.update(password);
  const hex = md.digest().toHex();

  return hex;
};

const symmetricDecryptData = (rawData: any, hashedPassword: any): string => {
  const iv = rawData.split('.')[0];
  const data = rawData.split('.')[1];

  const decipher = createDecipheriv(
    'aes-256-ctr',
    Buffer.from(hashedPassword, 'hex'),
    Buffer.from(iv, 'hex')
  );

  let decryptedData = decipher.update(data, 'hex', 'utf-8');
  decryptedData += decipher.final();

  return decryptedData;
};

const symmetricEncryptData = (data: any, hashedPassword: any): string => {
  const iv = randomBytes(16);
  const cipher = createCipheriv('aes-256-ctr', Buffer.from(hashedPassword, 'hex'), iv);

  let encryptedData = cipher.update(data, 'utf-8', 'hex');
  encryptedData += cipher.final('hex');
  encryptedData = `${iv.toString('hex')}.${encryptedData}`;

  return encryptedData;
};

export const createUserAuth = async (
  auth: Auth,
  email: string,
  password: string
): Promise<UserCredential> => {
  const hashedPassword = hashPassword(password, 'sha1');
  const user = await createUserWithEmailAndPassword(auth, email, hashedPassword);
  return user;
};

export const loginUser = async (
  auth: Auth,
  email: string,
  password: string
): Promise<UserCredential> => {
  const hashedPassword = hashPassword(password, 'sha1');
  const user = await signInWithEmailAndPassword(auth, email, hashedPassword);
  return user;
};

export const createUserCredentials = async (email: string, password: string) => {
  const hashedPassword = hashPassword(password, 'sha1');
  const credentials = EmailAuthProvider.credential(email, hashedPassword);
  return credentials;
};

export const encryptWallet = (wallet: ethers.Wallet, password: string): string => {
  const hashedPassword = hashPassword(password, 'sha256');
  const ecryptedWallet = symmetricEncryptData(
    JSON.stringify({
      privateKey: wallet?.privateKey.substring(2),
      mnemonic: wallet?.mnemonic.phrase,
      address: wallet?.address,
      publicKey: wallet?.publicKey.substring(2),
    }),
    hashedPassword
  );
  return ecryptedWallet;
};

export const decryptWallet = (encryptedWallet: string, password: string): ethers.Wallet => {
  const hashedPassword = hashPassword(password, 'sha256');
  const decryptedWallet = JSON.parse(symmetricDecryptData(encryptedWallet, hashedPassword));
  return ethers.Wallet.fromMnemonic(decryptedWallet.mnemonic);
};

export const convertOldWalletToNewWallet = async (
  oldEncryptedWallet: string,
  password: string
): Promise<string> => {
  const hashedPassword = hashPassword(password, 'sha1');
  const oldWallet = await ethers.Wallet.fromEncryptedJson(oldEncryptedWallet, hashedPassword);
  const newEncryptedWallet = encryptWallet(oldWallet, password);
  return newEncryptedWallet;
};

export const resetPassword = async (
  auth: Auth,
  oobCode: string,
  newPassword: string
): Promise<void> => {
  const hashedPassword = hashPassword(newPassword, 'sha1');

  await confirmPasswordReset(auth, oobCode, hashedPassword);
};
