import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '..';
import { IconFolderHash, IconFolderNft } from '../../icons';
import { formatDate, truncateText } from '../../imports/utils';

type NotarizationBottomCardProps = {
  item: any;
};

const NotarizationBottomCard = ({ item }: NotarizationBottomCardProps) => {
  const { t } = useTranslation(['notarization']);

  const statusColor = (state: string) => {
    switch (state) {
      case 'pending':
        return 'warning';
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      case undefined:
        return 'warning';
      default:
        return 'black';
    }
  };

  return (
    <>
      <div className="flex-column flex gap-1">
        {item.status === 'pending' && <Icon icon={IconFolderNft} size="md" />}
        {item.status === 'success' && <Icon icon={IconFolderHash} size="md" />}
        {item.status === 'error' && <Icon icon={IconFolderHash} size="md" />}
        <div className="flex cursor-pointer flex-col items-start overflow-hidden truncate">
          <Typography size="body-medium-12">
            {truncateText(item.name, {
              maxLength: 20,
              rightChars: 0,
            })}
          </Typography>
          <div className="flex flex-row">
            <Typography size="body-regular-12" color={statusColor(item.status)}>
              {t(`status.${item.status}`)}
            </Typography>
            <Typography size="body-regular-12">&nbsp;</Typography>
            <Typography size="body-regular-12" color="grey-400">
              {formatDate(item.date)}
            </Typography>
          </div>
        </div>{' '}
      </div>
    </>
  );
};
export default NotarizationBottomCard;
