import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Button } from '..';
import { useLoadingStatusContext } from '../../context';
import { deleteAuditDoc } from '../../modules/audit/api';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type DeleteAuditModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { audit } = useModalProps('DeleteAuditModal');
  const { t } = useTranslation(['audit']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();

  const handleDeleteAudit = async () => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('delete_audit.actions.pending'),
      },
    });
    await deleteAuditDoc(audit.auditId)
      .then(() => {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('delete_audit.actions.success'),
          },
        });
      })
      .catch(() => {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('delete_audit.actions.failed'),
          },
        });
      });
  };
  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col gap-4 px-[20px] pt-[50px]">
        <div data-cy="deleteAudit" className="mt-7">
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-7">
            {t('delete_audit.title')}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_audit.delete_audit', { name: audit.name })}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('delete_audit.disclaimer')}
          </Typography>
        </div>
        <div className="mt-[20px] flex flex-row justify-end">
          <Button id="deleteAudit" type="warning" action={() => handleDeleteAudit()}>
            {t('delete_audit.submit_data')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DeleteAuditModal = ({ children }: DeleteAuditModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DeleteAuditModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DeleteAuditModal;
