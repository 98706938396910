import { PollForm } from './types';

/**  Given the pollId: string returns the specified Poll: PollForm
 * @param pollId: string
 * @param pollList: PollForm[]
 * @returns PollForm | undefined
 */
export const getSpecificPoll = (
  pollId: string | undefined,
  pollList: PollForm[]
): PollForm | undefined => {
  return pollList.find((poll) => poll.id === pollId);
};
