import { SVGProps } from 'react';

const IconGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38816)">
      <path
        d="M96 114C105.941 114 114 105.941 114 96C114 86.0589 105.941 78 96 78C86.0589 78 78 86.0589 78 96C78 105.941 86.0589 114 96 114Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72 60C81.9411 60 90 51.9411 90 42C90 32.0589 81.9411 24 72 24C62.0589 24 54 32.0589 54 42C54 51.9411 62.0589 60 72 60Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 96C159.941 96 168 87.9411 168 78C168 68.0589 159.941 60 150 60C140.059 60 132 68.0589 132 78C132 87.9411 140.059 96 150 96Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 156C159.941 156 168 147.941 168 138C168 128.059 159.941 120 150 120C140.059 120 132 128.059 132 138C132 147.941 140.059 156 150 156Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 162C51.9411 162 60 153.941 60 144C60 134.059 51.9411 126 42 126C32.0589 126 24 134.059 24 144C24 153.941 32.0589 162 42 162Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.7234 79.5748L79.2734 58.4248"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.902 83.7002L113.102 90.3002"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.826 126.975L110.176 107.025"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5758 107.925L55.4258 132.075"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38816">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconGraph;
