import { ChangeHandler } from 'react-hook-form';
import Field from '../field/Field';
import Input from '../input/Input';
import Select from '../select/Select';

type DateAndTimeProps = {
  label: string;
  error: string;
  onBlur: ChangeHandler;
  onChange: ChangeHandler;
  inputRef: any;
  name?: string;
  minValue?: string;
};

const DateAndTimeSelect = ({
  date: {
    label: dateLabel,
    error: dateError,
    onBlur: dateOnBlur,
    onChange: dateOnChange,
    inputRef: dateInputRef,
    name: dateName,
    minValue: dateMinValue,
  },
  time: {
    label: timeLabel,
    error: timeError,
    onBlur: timeOnBlur,
    onChange: timeOnChange,
    inputRef: timeInputRef,
    name: timeName,
    minValue: timeMinValue,
    options,
  },
}: {
  date: DateAndTimeProps;
  time: DateAndTimeProps & {
    options: {
      value: any;
      label: string;
      disabled?: boolean;
      bold?: boolean;
      isHidden?: boolean;
    }[];
  };
}) => {
  return (
    <>
      <Field label={dateLabel} error={dateError}>
        <Input
          type="date"
          name={dateName}
          onBlur={dateOnBlur}
          onChange={dateOnChange}
          inputRef={dateInputRef}
          error={dateError}
          minValue={dateMinValue}
        />
      </Field>
      <Field label={timeLabel} error={timeError}>
        <Select
          options={options}
          name={timeName}
          onBlur={timeOnBlur}
          onChange={timeOnChange}
          inputRef={timeInputRef}
          error={timeError}
        />
      </Field>
    </>
  );
};

export default DateAndTimeSelect;
