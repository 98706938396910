import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/* root imports */

import { IconLoading } from '../../../../icons';

import { FunctionalityCard, Typography } from '../../../../components';

import type { Filters, RestrictedAreaForm } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

/* tokencreator imports */

type RestrictedAreaListProps = {
  restrictedArea: RestrictedAreaForm[];
  isLoading: boolean;
  // handleShowSidebar: (type: SidebarType, optionalProps?: any) => void;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
};
const RestrictedAreaList = ({
  restrictedArea,
  isLoading,
  filtersState,
  orderBy,
}: RestrictedAreaListProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace } = useAppSelector((user) => user.team);

  const handleGoToAttributeDetails = (attributesId: String) => {
    navigate(`restricted/${attributesId}`);
  };

  return !isLoading ? (
    <>
      <Typography size="body-semibold-20">{t('collection.title_restricted_area')}</Typography>
      <div className="flex flex-wrap gap-5">
        {restrictedArea.length > 0 ? (
          restrictedArea.map((item, index) => {
            return (
              <div className="cursor-pointer" key={`${item.id}-${index}`}>
                <FunctionalityCard
                  functionality={item}
                  key={item.id}
                  hasWritePermission={workspace?.permissions.token_creator.write}
                  onClick={() => handleGoToAttributeDetails(item.id)}
                />
              </div>
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                restrictedArea.length === 0
                  ? 'collection.no_restricted_area_found'
                  : 'collection.no_filtered_restricted_area_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default RestrictedAreaList;
