import { Contract, FunctionalityContractList, Nft } from '../modules/tokenCreator/imports/types';
import { AvailableOn, ChangedContracts } from './types';

/**  Returns a contract from contractsList
 * @param contractId: string
 * @param contractsList: Contract[]
 * @returns Contract | undefined
 */
export const getContractFromContractId = (
  contractId: string,
  contractsList: Contract[]
): Contract | undefined => {
  const contract: Contract | undefined = contractsList.find(
    (contract) => contract.id === contractId
  );
  return contract;
};

/**  Returns all workspace's contracts. Needs workspace.id and state.contracts.list
 * @param workspaceId: string | undefined
 * @param contractsList: Contract[]
 * @returns Contract[]
 */
export const getWorkspaceContracts = (
  workspaceId: string | undefined,
  contractsList: Contract[],
  groupId?: string
): Contract[] => {
  if (!workspaceId || contractsList.length === 0) return [];
  const contracts: Contract[] = contractsList.filter((contract) => {
    if (groupId) {
      return contract.workspace_id === workspaceId && contract.groupId === groupId;
    }
    return contract.workspace_id === workspaceId;
  });
  return contracts;
};

/** Returns all workspace's CREATED contracts. Needs workspace.id and state.contracts.list
 * @param workspaceId: string | undefined
 * @param contractsList: Contract[]
 * @returns Contract[] - CREATED
 */
export const getWorkspaceCreatedContracts = (
  workspaceId: string | undefined,
  contractsList: Contract[]
): Contract[] => {
  if (!workspaceId || contractsList.length === 0) return [];
  const contracts: Contract[] = contractsList.filter(
    (contract) => contract.workspace_id === workspaceId && contract.status === 'created'
  );
  return contracts;
};

/**  Given the nftId: string returns the specified Nft: Nft from contractsList
 * @param contractId: string
 * @param nftId: number
 * @param contractsList: Contract[]
 * @returns Nft | undefined
 */
export const getSpecificNft = (
  contractId: string,
  nftId: number,
  contractsList: Contract[]
): Nft | undefined => {
  return contractsList
    .find((contract) => contract.id === contractId)
    ?.nfts.find((nft) => nft.id === nftId);
};

/** Returns available contracts type: AvailableOn
 * @param contractsList: Contract[]
 * @returns AvailableOn
 */
export const getAvailableContracts = (contractsList: Contract[]): AvailableOn => {
  const availableOn: { [key: string]: number[] } = {};

  contractsList.forEach((contract: Contract) => {
    const nftsIds: number[] = [];
    contract.nfts.forEach((nft: Nft) => {
      nftsIds.push(nft.id);
    });
    availableOn[contract.address] = nftsIds;
  });

  return availableOn;
};

/**
 * From a contract list return an array of only related contract and nfts ids
 * @param contractsList
 * @returns
 */
export const getAvailableContractsIds = (
  contractsList: Contract[]
): { contractId: string; nftsIds: number[] }[] => {
  const ids: { contractId: string; nftsIds: number[] }[] = [];

  contractsList.forEach((contract: Contract) => {
    const nftsIds: number[] = [];
    contract.nfts.forEach((nft: Nft) => {
      nftsIds.push(nft.id);
    });
    if (nftsIds.length === 0) return;

    ids.push({ contractId: contract.id, nftsIds });
  });

  return ids;
};

/** Return availables contracts from functionality contractsList
 * @param contractsList: FunctionalityContractList[]
 * @returns AvailableOn
 */
export const getAvailableContractsFromFunctionality = (
  contractsList: FunctionalityContractList[]
): AvailableOn => {
  const availableOn: { [key: string]: number[] } = {};
  contractsList.forEach((contract: FunctionalityContractList) => {
    const nftsIds: number[] = [];
    contract.tokenIds.forEach((id) => {
      nftsIds.push(id);
    });
    availableOn[contract.contractAddress] = nftsIds;
  });
  return availableOn;
};

/**  Return true if all nfts are selected
 * @param selectedNfts: Contract[]
 * @param contractsList: Contract[]
 * @returns boolean
 */
export const isAllNftsSelected = (selectedNfts: Contract[], contractsList: Contract[]): boolean => {
  if (contractsList.length === selectedNfts.length) {
    for (const contract of contractsList) {
      const selectedContract = selectedNfts.find(
        (selContract: Contract) => contract.address === selContract.address
      );
      if (selectedContract?.nfts.length !== contract.nfts.length) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

/**
 * @description Given availableOn from Functionalities and contractsList returns all the Contracts: Contract[].
 * @param functionalitiesContracts: AvailableOn
 * @param contractList: Contract[]
 * @returns Contract[]
 */
export const getContractsFromFunctionalities = (
  functionalitiesContracts: AvailableOn,
  contractList: Contract[]
): Contract[] => {
  const contracts: Contract[] = [];
  Object.keys(functionalitiesContracts).forEach((key: string) => {
    contractList.forEach((contract: Contract) => {
      if (contract.address === key) {
        const newContract: Contract = { ...contract };
        newContract.nfts = [];
        functionalitiesContracts[key].forEach((tokenId) => {
          const nft: Nft | undefined = contract.nfts.find((nft: Nft) => nft.id === tokenId);
          if (nft) {
            newContract.nfts.push(nft);
          }
        });
        contracts.push(newContract);
      }
    });
  });
  return contracts;
};

/**  Get changes in Contracts[]: 
  - changedContracts: ChangedContracts[]
  - addedContracts: Contract[]
  - removedContracts: Contract[]
* @param oldContracts: Contract[]
* @param newContracts: Contract[]
* @param oldAvailableOn: AvailableOn
* @param newAvailableOn: AvailableOn
* @returns { changedContracts: ChangedContracts[], addedContracts: Contract[], removedContracts: Contract[] }
*/
export const getContractsChanges = (
  oldContracts: Contract[],
  newContracts: Contract[],
  oldAvailableOn: AvailableOn,
  newAvailableOn: AvailableOn
) => {
  const changedContracts: ChangedContracts[] = [];
  const addedContracts: Contract[] = [];
  const removedContracts: Contract[] = [];

  newContracts.forEach((contract: Contract) => {
    const oldContract: Contract | undefined = oldContracts.find(
      (oldContract: Contract) => oldContract.address === contract.address
    );
    if (!oldContract) {
      addedContracts.push(contract);
    } else {
      const oldNftsIds: number[] = oldAvailableOn[contract.address];
      const newNftsIds: number[] = newAvailableOn[contract.address];
      const removedNfts: number[] = [];
      const addedNfts: number[] = [];
      oldNftsIds.forEach((nftId: number) => {
        if (!newNftsIds.includes(nftId)) {
          addedNfts.push(nftId);
        }
      });
      newNftsIds.forEach((nftId: number) => {
        if (!oldNftsIds.includes(nftId)) {
          removedNfts.push(nftId);
        }
      });
      if (removedNfts.length > 0 || addedNfts.length > 0) {
        changedContracts.push({
          contractAddress: contract.address,
          removedNfts,
          addedNfts,
        });
      }
    }
  });

  oldContracts.forEach((contract: Contract) => {
    const newContract: Contract | undefined = newContracts.find(
      (newContract: Contract) => newContract.address === contract.address
    );
    if (!newContract) {
      removedContracts.push(contract);
    }
  });

  return { changedContracts, addedContracts, removedContracts };
};

/**  Returns the last game date from contractsList
 * @param contractsList: Contract[]
 * @returns number
 */
export const getLastGameDate = (contractsList: Contract[]): number => {
  const dates: number[] = [];
  contractsList.forEach((contract: Contract) => {
    if (!contract.scores) return;
    contract.scores.forEach((score) => {
      dates.push(score.createdAt);
    });
  });
  return Math.max(...dates);
};

/**  This function traverses an array of new NFTs and compares them with existing NFTs
 * in a specific contract to determine which NFTs have been changed.
 * An NFT is considered modified if its `updatedAt` timestamp is more recent than
 * the similar timestamp in the array of existing NFTs.
 * @param newNfts: Nft[], fresh contract'nfts
 * @param contractFromState: Contract | undefined, is the outdated contract present in the redux state before the dispatch
 * @returns number[], array of modified ids
 */
export const getModifiedNftsIds = (newNfts: Nft[], contractFromState: Contract | undefined) => {
  const { nfts: oldNfts = [] } = contractFromState || {};

  const modifiedNftsIds = newNfts.reduce((acc: number[], nft) => {
    const { id, updatedAt = 0 } = nft;
    const foundNft = oldNfts.find((oldNft) => oldNft.id === id);

    if (!foundNft) return acc;

    const { updatedAt: oldUpdate = 0 } = foundNft;
    if (oldUpdate < updatedAt) {
      acc.push(id);
    }
    return acc;
  }, []);

  return modifiedNftsIds;
};
