import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import 'swiper/css';
import 'swiper/css/navigation';
import { Button, InnerPage, SectionTitle, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconArrow, IconEdit, IconTrash } from '../../../../icons';
import { getContractsFromFunctionalities } from '../../../../imports/contractsParsers';
import { RestrictedAreaForm } from '../../../../imports/types';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { removeRestrictedArea } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { deleteFunctionalityDoc, setNftData } from '../../api';
import { Contract, Nft } from '../../imports/types';

const RestrictedAreaDetails = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);

  const navigate = useNavigate();
  const { id: restrictedAreaId } = useParams();
  const { workspace, restrictedAreas, contractsList } = useAppSelector(
    ({ team, functionalities, contracts }) => ({
      workspace: team.workspace,
      restrictedAreas: functionalities.restrictedAreas,
      contractsList: contracts.list,
    })
  );
  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const { open: openDeleteModal } = useModal('DeleteModal');
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const dispatch = useAppDispatch();
  const [restrictedArea, setRestrictedArea] = useState<RestrictedAreaForm>();

  const handleDeleteRestrictedArea = async () => {
    if (workspace) {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });
      if (restrictedArea) {
        /* delete the functionality from the nfts */
        try {
          const contracts = getContractsFromFunctionalities(
            restrictedArea.availableOn,
            contractsList
          );
          const data = await Promise.allSettled(
            contracts
              .map(async (contract) => {
                const fullContract = contractsList.find(
                  (fullContract: Contract) => fullContract.address === contract.address
                );
                const updatedContract = cloneDeep(fullContract);
                const promiseContract = contract.nfts.map(async (nft: Nft) => {
                  if (nft.attributes) {
                    const newAttributes = nft.attributes;

                    const data = {
                      attributes: nft.attributes.filter(({ id }) => id !== restrictedArea.id),
                    };

                    /* update nft.attributes on redux contract */
                    if (updatedContract) {
                      updatedContract.nfts = updatedContract.nfts.map((updatedNft: Nft) => {
                        if (nft.id === updatedNft.id) {
                          const newNft = cloneDeep(updatedNft);
                          newNft.attributes = newAttributes;
                          return newNft;
                        }
                        return updatedNft;
                      });
                    }

                    const promiseNft = await setNftData(contract?.id, nft, data);
                    return promiseNft;
                  }
                });
                /* update redux state */
                if (updatedContract) {
                  dispatch(updateContract(updatedContract));
                }
                return promiseContract;
              })
              .flat()
          );
          /* delete the functionality from the functionalities DB*/
          const { value } = await deleteFunctionalityDoc(restrictedArea.id);

          // /** Check if there are errors in promises */
          let isError = false;
          if (!value) {
            isError = true;
          }

          data.forEach((promise: any) => {
            if (promise.status === 'fulfilled' && promise.value.error) {
              isError = true;
            }
          });
          if (isError) {
            console.log(isError);
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                title: t('collection.functionality.actions.failed'),
              },
            });
          } else {
            dispatch(removeRestrictedArea(restrictedArea.id));

            navigate(-1);
            loadingStatusDispatch({
              type: 'SET_SUCCESS',
              payload: {
                title: t('collection.functionality.actions.delete'),
              },
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: restrictedArea });
  };

  const handleDelete = () => {
    openDeleteModal({ handleDelete: handleDeleteRestrictedArea });
  };

  useEffect(() => {
    setRestrictedArea(restrictedAreas.find(({ id }) => id === restrictedAreaId));
  }, [workspace?.id, restrictedAreas]);

  return (
    <InnerPage>
      {!restrictedArea ? (
        <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
          <Typography as="h2" size="xl" className="text-center">
            {t('functionality.functionality_not_found')}
          </Typography>

          <Button action={() => navigate('/nft/')} className="mt-10">
            {t('functionality.goBack')}
          </Button>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-8 ">
            <div className="flex">
              <IconArrow
                onClick={() => navigate(-1)}
                width={40}
                height={40}
                stroke="#642eff"
                className="mb-3 cursor-pointer rounded-md"
              />
            </div>
          </div>
          <section className="mt-4 flex flex-col gap-4">
            <SectionTitle title={restrictedArea.title || restrictedArea.type}></SectionTitle>
            <div className="flex w-full justify-end gap-4">
              <IconEdit
                className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                width={30}
                height={30}
                stroke="white"
                onClick={() => handleOpenNewFunctionality()}
              />
              <IconTrash
                className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                width={30}
                height={30}
                stroke="white"
                onClick={() => handleDelete()}
              />
            </div>
            <div className="mt-4 flex flex-row gap-4">
              <Typography weight="semibold">{`${t(
                'functionality.restricted_area_link'
              )}:`}</Typography>
              <Typography>{restrictedArea.link}</Typography>
            </div>
          </section>
        </>
      )}
    </InnerPage>
  );
};

export default RestrictedAreaDetails;
