import { FunctionComponent, SVGProps, useState } from 'react';
import { IconArrowDown } from '../../icons';
import Icon from '../icon/Icon';
import ItemOption from '../item/ItemOption';
import Typography from '../typography/Typography';

interface filterOption {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

interface OrderBySelectProps {
  items: filterOption[];
  onChange: (options: string) => void;
  width?: string;
  height?: string;
  id?: string;
}
const OrderBySelect = ({
  items,
  onChange,
  width = '150px',
  height = '36px',
  id,
}: OrderBySelectProps) => {
  const [open, setOpen] = useState(false);
  const [filterIndex, setFilterIndex] = useState<number>(0);
  const handleSelect = (option: number) => {
    setFilterIndex(option);
    onChange(items[option].value);
    setOpen(!open);
  };
  return (
    <div className={`relative flex flex-col space-y-[0px] `} id={id}>
      <div
        className="relative flex cursor-pointer items-center gap-[11px] rounded-[4px] border border-grey-300 py-[4px] pl-[10px] text-body-regular-12 text-grey-500 hover:opacity-70 active:opacity-100"
        onClick={() => setOpen(!open)}
        style={{
          width,
          height,
        }}
      >
        <div className="flex w-full items-center justify-between pr-2" key="orderByPlaceholder">
          <div className="flex items-center gap-3">
            <Icon icon={items[filterIndex].icon} size="16" />
            <Typography size="body-regular-12" color={filterIndex === 0 ? 'grey-400' : 'grey-500'}>
              {items[filterIndex].label}
            </Typography>
          </div>
          <div>
            <Icon icon={IconArrowDown} className={open ? 'rotate-180' : ''} />
          </div>
        </div>
      </div>
      <div
        className="absolute top-full z-10 flex  flex-col space-y-[12px] rounded-[4px] border  border-grey-300 bg-white"
        style={{
          display: open ? 'flex' : 'none',
          width,
          height: `${36 * (items.length - 1) + 2}px`,
          maxHeight: '180px',
          marginTop: '5px',
        }}
      >
        <div className="flex flex-col">
          {items.map((item, i) => {
            if (i > 0) {
              return (
                <ItemOption
                  id={`${item.label}-${i}`}
                  name={item.label}
                  key={`${item.value}-${i}`}
                  icon={items[i].icon}
                  onClick={() => {
                    setOpen(false);
                    handleSelect(i);
                  }}
                  color="grey-500"
                />
              );
            }
            return <div key={item.label}></div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderBySelect;
