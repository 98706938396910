/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconExport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.5 43.5L96 12L127.5 43.5"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96 96V12"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M132 72H150C151.591 72 153.117 72.6321 154.243 73.7574C155.368 74.8826 156 76.4087 156 78V156C156 157.591 155.368 159.117 154.243 160.243C153.117 161.368 151.591 162 150 162H42C40.4087 162 38.8826 161.368 37.7574 160.243C36.6321 159.117 36 157.591 36 156V78C36 76.4087 36.6321 74.8826 37.7574 73.7574C38.8826 72.6321 40.4087 72 42 72H60"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconExport;
