import type { ReactNode } from 'react';
import useScrollBehavior from '../../hooks/useScrollBehavior';

type InnerPageProps = {
  children: ReactNode;
  className?: string;
};

const InnerPage = ({ children, className }: InnerPageProps) => {
  // I am not sure why, even though the default scroll behaviour in useScrollBehaviour is the same
  // as the one used as a parameter,without the parameter it is not working as expected in CreateContract.tsx.
  useScrollBehavior(() => window.scrollTo({ top: 0, behavior: 'auto' }));

  return (
    <section className="size-full p-8 ">
      <div className={`flex min-h-full flex-col pb-20 ${className}`}>{children}</div>
    </section>
  );
};

export default InnerPage;
