import { SVGProps } from 'react';

const IconAccountManagement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2812_135649)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.0014 54C46.7466 54 36.0014 64.7452 36.0014 78C36.0014 90.6564 45.7983 101.025 58.2224 101.935C58.8144 101.916 59.4075 101.906 60.0014 101.906C60.5954 101.906 61.1885 101.916 61.7805 101.935C74.2046 101.025 84.0014 90.6564 84.0014 78C84.0014 64.7452 73.2563 54 60.0014 54ZM81.9253 106.557C90.4849 99.9752 96.0014 89.6318 96.0014 78C96.0014 58.1177 79.8837 42 60.0014 42C40.1192 42 24.0014 58.1177 24.0014 78C24.0014 89.6318 29.518 99.9752 38.0776 106.557C34.1167 108.316 30.3575 110.557 26.8901 113.249C17.4184 120.601 10.663 130.896 7.68894 142.512C6.86701 145.722 8.80305 148.99 12.0132 149.812C15.2234 150.634 18.492 148.698 19.3139 145.488C21.6271 136.454 26.8813 128.446 34.2482 122.728C41.1084 117.403 49.4372 114.345 58.0812 113.95C58.7171 113.983 59.3573 114 60.0014 114C60.6456 114 61.2858 113.983 61.9217 113.95C70.5657 114.345 78.8945 117.403 85.7547 122.728C93.1216 128.446 98.3758 136.454 100.689 145.488C101.511 148.698 104.78 150.634 107.99 149.812C111.2 148.99 113.136 145.722 112.314 142.512C109.34 130.896 102.584 120.601 93.1128 113.249C89.6454 110.557 85.8862 108.316 81.9253 106.557ZM108.001 60C108.001 56.6863 110.688 54 114.001 54H186.001C189.315 54 192.001 56.6863 192.001 60C192.001 63.3137 189.315 66 186.001 66H114.001C110.688 66 108.001 63.3137 108.001 60ZM114.001 90C110.688 90 108.001 92.6863 108.001 96C108.001 99.3137 110.688 102 114.001 102H186.001C189.315 102 192.001 99.3137 192.001 96C192.001 92.6863 189.315 90 186.001 90H114.001ZM126.001 132C126.001 128.686 128.688 126 132.001 126H186.001C189.315 126 192.001 128.686 192.001 132C192.001 135.314 189.315 138 186.001 138H132.001C128.688 138 126.001 135.314 126.001 132Z"
        fill="#4F4F4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2812_135649">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconAccountManagement;
