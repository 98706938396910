import { addDoc, collection } from 'firebase/firestore';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { useLoadingStatusContext } from '../../context/loading/LoadingContext';
import { db } from '../../imports/firebase';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type SubscriptionModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { t } = useTranslation();
  const { plan } = useModalProps('subscriptionModal') as { plan: string };
  const { email, company } = useAppSelector((state) => state.user);
  const { state, dispatch } = useLoadingStatusContext();

  const handleSendEmail = async () => {
    dispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('subscription.messages.pending') as string,
      },
    });
    try {
      const messageData = {
        to: 'info@bcode.cloud',
        message: {
          subject: 'Richiesta contatto per modifica piano',
          html: `l'azienda ${company} con email: "${email}" ha richiesto di essere contattata in merito a piano: ${plan}`,
        },
      };
      const messagesCollection = collection(db, 'mail');
      await addDoc(messagesCollection, messageData);

      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('subscription.messages.success') as string,
        },
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('subscription.messages.failed') as string,
        },
      });
    }
  };

  return (
    <ModalLayout
      classNameLayout="h-auto w-auto"
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mx-7 mt-7 flex flex-col ">
        <Typography weight="semibold" size="body-semibold-30" color="primary-500">
          {plan === 'contactUs' ? t('subscription.contactUs') : plan}
        </Typography>
      </div>
      <div className="my-4 mx-7 border"></div>
      <div className="mx-7 max-w-lg">{t('subscription.messages.send_email')}</div>
      <div className="mx-7 mt-10 flex justify-end">
        <Button action={handleSendEmail} disabled={state.status === 'pending'}>
          {t('subscription.contactUs')}
        </Button>
      </div>
    </ModalLayout>
  );
};

const SubscriptionModal = ({ children }: SubscriptionModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal id="subscriptionModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default SubscriptionModal;
