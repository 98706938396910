import { SVGProps } from 'react';

const IconDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg width={54} height={54} viewBox="0 0 54 54" fill="none" version="1.1" id="svg68" {...props}>
    <rect
      width={54}
      height={54}
      rx="13.7647"
      id="rect62"
      x="0.031854153"
      y="0.032067776"
      fill="#ffffff"
    />
    <path
      d="M31.4668 9.13245V19.5552H41.8896"
      stroke="#1767FE"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path64"
    />
    <path
      d="m 14.173994,44.871028 26.225806,-0.0033 c 0.3949,-5e-5 0.7736,-0.1568 1.0529,-0.4361 0.2792,-0.2792 0.4361,-0.6579 0.4361,-1.0528 V 19.5552 L 31.466,9.13245 H 13.5983 c -0.3949,0 -0.784208,0.167512 -1.0528,0.43611 -0.2686,0.2686058 -0.4361,0.65794 -0.4361,1.05284 v 32.12926 c -0.04386,1.817284 0.889984,2.15144 2.064594,2.120368 z"
      strokeWidth="2.74887"
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path66"
      stroke="#1767fe"
    />
  </svg>
);

export default IconDoc;
