import { addDoc, collection } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  Button,
  Field,
  Form,
  InnerPage,
  Input,
  LoadingSpinner,
  Typography,
} from '../../components';
import { useValidation } from '../../hooks';
import { IconClose } from '../../icons';
import { db } from '../../imports/firebase';

const ChildrenItem = ({ title, description }: { title: string; description: string }) => (
  <div className="col-span-1">
    <Typography size="lg" weight="medium" as="h2" className="text-center">
      {title}
    </Typography>
    <Typography size="sm" weight="regular" as="p" className="p-5 text-center ">
      {description}
    </Typography>
  </div>
);

const Feedback = () => {
  const { t } = useTranslation();
  const items = [
    {
      title: t('feedback.children_items.title_1'),
      description: t('feedback.children_items.description_1'),
    },
    {
      title: t('feedback.children_items.title_2'),
      description: t('feedback.children_items.description_2'),
    },
    {
      title: t('feedback.children_items.title_3'),
      description: t('feedback.children_items.description_3'),
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { required } = useValidation();
  const navigate = useNavigate();

  const FeedbackForm = {
    initialValues: {
      name: '',
      email: '',
      description: '',
    },
    validationSchema: yup.object({
      name: required(yup.string()),
      email: required(yup.string().email()),
      description: required(yup.string()),
    }),
  };

  const { initialValues, validationSchema } = FeedbackForm;

  const handleFeedback = async (values: { name: string; email: string; description: string }) => {
    setIsLoading(true);

    const { name, email, description } = values;

    const feedbackQuery = collection(db, 'feedbacks');
    const docRef = await addDoc(feedbackQuery, {
      name,
      email,
      description,
    });

    if (docRef.id) {
      setIsLoading(false);
      setIsSubmitted(true);
    } else {
      toast.error(t('feedback.error') as string);
      setIsLoading(false);
      navigate('/feedback');
    }
  };

  return (
    <>
      <InnerPage>
        {!isLoading && (
          <>
            <Typography size="xl" weight="bold" as="h1" className="mx-auto w-2/5 px-5 text-center">
              {t('feedback.main_title')}
            </Typography>
            <div className="mt-14 grid grid-cols-3 gap-5">
              {items.map((item, index) => (
                <ChildrenItem key={index} title={item.title} description={item.description} />
              ))}
            </div>

            <div>
              <Typography
                size="xl"
                weight="bold"
                as="h2"
                className="mx-auto mt-10 w-2/5 px-5 text-center"
              >
                {t('feedback.form_title')}
              </Typography>
              <Typography size="sm" weight="regular" as="p" className="pt-5 text-center">
                {t('feedback.form_description')}
              </Typography>

              {/* Form */}
              <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                className="mx-auto w-1/2 pt-5"
              >
                {({ fields, handleSubmit, errors, resetField, watch, setValue }) => (
                  <>
                    <Field error={errors.name?.message}>
                      <Input
                        type="text"
                        placeholder={t('feedback.form_fields.form_placeholders.name')}
                        id={fields.name.name}
                        name={fields.name.name}
                        onBlur={fields.name.onBlur}
                        onChange={fields.name.onChange}
                        inputRef={fields.name.ref}
                        error={errors.name?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.name.name)}
                          />
                        }
                      />
                    </Field>
                    <Field error={errors.email?.message}>
                      <Input
                        type="text"
                        placeholder={t('feedback.form_fields.form_placeholders.email')}
                        id={fields.email.name}
                        name={fields.email.name}
                        onBlur={fields.email.onBlur}
                        onChange={fields.email.onChange}
                        inputRef={fields.email.ref}
                        error={errors.email?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.email.name)}
                          />
                        }
                      />
                    </Field>
                    <Field error={errors.description?.message}>
                      <Input
                        type="textarea"
                        placeholder={t('feedback.form_fields.form_placeholders.description')}
                        id={fields.description.name}
                        name={fields.description.name}
                        onBlur={fields.description.onBlur}
                        onChange={fields.description.onChange}
                        inputRef={fields.description.ref}
                        error={errors.description?.message}
                        elementRight={
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.description.name)}
                          />
                        }
                      />
                    </Field>

                    <Button
                      type="primary"
                      action={() => handleSubmit(handleFeedback)()}
                      loading={isLoading}
                    >
                      {t('notarization.form_placeholders.submit_button')}
                    </Button>
                  </>
                )}
              </Form>
            </div>
          </>
        )}
      </InnerPage>
      {isLoading && (
        <div className="flex grow items-center justify-center">
          {/* <IconLoading className="size-12 animate-spin text-primary-500" /> */}
          <LoadingSpinner title={t('feedback.loading_title')} />
        </div>
      )}
      {isSubmitted && (
        <div className="flex grow items-center justify-center">
          <LoadingSpinner isCompleted={isSubmitted} title={t('feedback.complete_message')} />
        </div>
      )}
    </>
  );
};

export default Feedback;
