import { PropsWithChildren, useEffect, useRef } from 'react';
import { collection, getDocs, onSnapshot, query } from 'firebase/firestore';
import useWorkspaceAndGroup from '../hooks/useWorkspaceAndGroup';
import { sendModifyPushNotification } from '../api/axios';
import { FIREBASECHANGEEVENTS } from '../imports/constants';
import { getModifiedNftsIds } from '../imports/contractsParsers';
import { db } from '../imports/firebase';
import { Activity, Scores } from '../imports/types';
import { safeWhere } from '../imports/utils';
import { Contract, Nft } from '../modules/tokenCreator/imports/types';
import { removeContract, updateContract } from '../redux/contracts/contracts.slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';


const ContractsWrapper = (props: PropsWithChildren) => {
  const { children } = props;
  const { workspace } = useAppSelector((state) => state.team);
  const { uid } = useAppSelector((state) => state.user);
  const { list } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const activeGroupId = workspace?.activeGroupId; // Add null check for workspace object

  const {
    workspaceGroupObject: { isSuperGroup },
  } = useWorkspaceAndGroup();
  // Use useRef to keep a reference to the last state of `list`e each time we render it we save the updated reference
  const listRef = useRef(list);
  listRef.current = list;

  useEffect(() => {
    if (!workspace?.id) return;

    const contractsUnsub = onSnapshot(
      query(
        collection(db, 'contracts'),
        ...safeWhere(uid, workspace?.id, isSuperGroup === true ? undefined : activeGroupId)
      ),
      (snapshot) => {
        // access the last state of `list` via the reference, because if we used `list` directly,
        // not being in the useEffect dependencies, we would have the outdated value
        const currentList = listRef.current;
        snapshot.docChanges().forEach(async (change, index) => {
          const contract: Contract = change.doc.data() as Contract;
          if (change.type === FIREBASECHANGEEVENTS.ADDED) {
            if (contract.status === 'created') {
              const nfts = (
                await getDocs(collection(db, `contracts/${contract.id}/nfts`))
              ).docs.map((doc) => doc.data() as Nft);
              contract.nfts = nfts;

              const activities = (
                await getDocs(collection(db, `contracts/${contract.id}/activities`))
              ).docs.map((doc) => doc.data() as Activity);
              contract.activities = activities;

              const scores = (
                await getDocs(collection(db, `contracts/${contract.id}/scores`))
              ).docs.map((doc) => doc.data() as Scores);
              contract.scores = scores;
            }

            dispatch(updateContract(contract));
          }

          if (change.type === FIREBASECHANGEEVENTS.MODIFIED) {
            if (currentList.length === 0) return;

            const contractFromState = currentList.find(
              (contractFromState) => contractFromState.id === contract.id
            );
            if (!contractFromState) return;

            // TODO: manage contract not in state and modified (generic issue management)
            if (contractFromState?.metadataUri !== contract.metadataUri) {
              const nfts = (
                await getDocs(collection(db, `contracts/${contract.id}/nfts`))
              ).docs.map((doc) => doc.data() as Nft);
              const modifiedIds = getModifiedNftsIds(nfts, contractFromState);
              sendModifyPushNotification(uid, contractFromState.id, modifiedIds);
              contract.nfts = nfts;
            }
            if (contractFromState?.activities !== contract.activities) {
              // TODO: check creation Date
              const activities = (
                await getDocs(collection(db, `contracts/${contract.id}/activities`))
              ).docs.map((doc) => doc.data() as Activity);
              contract.activities = activities;
            }
            if (contractFromState?.scores !== contract.scores) {
              // TODO: implement more check to avoid other queries
              const scores = (
                await getDocs(collection(db, `contracts/${contract.id}/scores`))
              ).docs.map((doc) => doc.data() as Scores);
              contract.scores = scores;
            }

            dispatch(updateContract(contract));
            // contracts.push(contract);
          }

          // Remove contract - to check if it's necessary.
          if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
            dispatch(removeContract(contract.id));
          }

          // if (contracts.length === 2 || snapshot.docChanges().length === index + 1) {
          //   console.log('LOADING CONTRACTS ...');
          //   dispatch(updateContracts(contracts));
          //   contracts = [];
          // }
        });
      }
    );

    return () => {
      contractsUnsub();
    };
  }, [workspace?.id, activeGroupId, isSuperGroup,listRef]);

  return <>{children}</>;
};

export default ContractsWrapper;
