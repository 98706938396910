import { useState } from 'react';
import { IconArrowDown, IconArrowUp } from '../../icons';
import { FAQQuestion } from '../../imports/types';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

const FAQComponent = ({ question, answer, defaultText }: FAQQuestion) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const canOpen = question || answer; // Check if at least question or answer is present

  const handleShowMore = () => {
    if (canOpen) {
      setShowMore(!showMore);
    }
  };

  return (
    <div
      className={`w-full cursor-pointer rounded-[6px] border border-grey4 py-2 px-4 ${
        canOpen ? 'hover:bg-gray-200' : ''
      }`}
      onClick={handleShowMore}
      key={question || defaultText} // Use defaultText as the key if question is not present
    >
      <div className="flex w-full items-center justify-between gap-1">
        <Typography size="body-medium-20" weight="medium">
          {question || defaultText}
        </Typography>
        <div>
          {canOpen && // Only show the arrow icon if at least question or answer is present
            (showMore ? (
              <Icon icon={IconArrowUp} size="30" />
            ) : (
              <Icon icon={IconArrowDown} size="30" />
            ))}
        </div>
      </div>
      <Typography
        size="body-regular-16"
        className={`${(!showMore || !canOpen) && 'hidden'} mt-2 whitespace-pre-line`}
      >
        {answer || defaultText}
      </Typography>
    </div>
  );
};

export default FAQComponent;
