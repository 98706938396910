import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { getUserAuthToken } from '../../api/firebase';
import { IconLink } from '../../icons';
import { paidIpfsGateway } from '../../imports/constants';
import { truncateText } from '../../imports/utils';
import { getFolderName } from '../../modules/audit/api';
import { getFileFromStorage } from '../../modules/notarizations/api';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import ModalLayout from '../layouts/ModalLayout';
import Tag from '../tag/Tag';
import Tooltip from '../tooltips/Tooltip';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
  children: ReactNode;
};
const InfoSection = ({ label, children }: InfoSectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography size="body-regular-18">{label.toUpperCase()}</Typography>
      <div className="flex flex-row items-center justify-start gap-4 ">{children}</div>
    </div>
  );
};

type AuditViewNotarizedModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  //const navigate = useNavigate();
  // const { t } = useTranslation(['audit']);
  const { notarization } = useModalProps('AuditViewNotarizedModal');
  // const { uid } = useAppSelector((state) => state.user);
  // const profile = useAppSelector((state) => state.user);
  // const { address } = useAppSelector((state) => state.user.wallet);
  // const { workspace } = useAppSelector((state) => state.team);
  const { name, description, date, folderId, tags, notes, hash, ipfsUri, externalUrl, id } =
    notarization;

  const { t } = useTranslation(['audit']);

  const [folder, setFolder] = useState<string>('');
  const [fileStorageUrl, setFileStorageUrl] = useState<string | null>(null);

  const handleGetFileFromStorage = async () => {
    const { value: authTokenId } = await getUserAuthToken();

    let url = '';

    if (externalUrl) {
      await getFileFromStorage(externalUrl, authTokenId).then((res) => {
        url = res.value.url;
      });
    }

    return url;
  };

  useEffect(() => {
    if (ipfsUri) {
      setFileStorageUrl(`${paidIpfsGateway}${ipfsUri}`);
    } else if (externalUrl) {
      handleGetFileFromStorage().then((res) => {
        setFileStorageUrl(res);
      });
    }
  }, []);

  useEffect(() => {
    if (folderId) {
      getFolderName(folderId).then((res) => {
        setFolder(res.value);
      });
    }
  }, []);

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mt-7 flex size-full flex-col gap-7 px-5" key={id}>
        <Typography className="" color="primary-500" size="body-medium-30">
          {name}
        </Typography>
        <div className="flex flex-row gap-7">
          <div className="flex w-1/2 flex-col gap-4">
            {folder && (
              <InfoSection label={t('create_audit.labels.folder') as string}>
                <Typography size="body-medium-16">{folder}</Typography>
              </InfoSection>
            )}
            <InfoSection label={t('create_audit.labels.description') as string}>
              <Typography size="body-medium-16">{description}</Typography>
            </InfoSection>

            {hash && (
              <InfoSection label={t('create_audit.labels.hash') as string}>
                <Typography size="body-medium-16">
                  {truncateText(hash, {
                    maxLength: 20,
                    rightChars: 0,
                  })}
                </Typography>
              </InfoSection>
            )}
            {(ipfsUri || externalUrl) && (
              <InfoSection label={t('create_audit.labels.storage') as string}>
                {fileStorageUrl ? (
                  <div className="flex items-center justify-between">
                    <Typography>{t('create_audit.labels.download_file')}</Typography>

                    <Tooltip content={t('create_audit.tooltips.download_file')}>
                      <a href={fileStorageUrl} target="_blank" rel="noreferrer">
                        <Button type="ghost" action={() => {}}>
                          <Icon icon={IconLink} className="!h-4 !w-4" />
                        </Button>
                      </a>
                    </Tooltip>
                  </div>
                ) : (
                  <Typography>{t('create_audit.no_url_found')}</Typography>
                )}
              </InfoSection>
            )}
          </div>

          <div className="flex w-1/2 flex-col gap-4">
            <InfoSection label={t('create_audit.labels.date') as string}>
              <Typography size="body-medium-16">{format(new Date(date), 'dd/MM/yyyy')}</Typography>
            </InfoSection>
            <InfoSection label={t('create_audit.labels.tags') as string}>
              {tags && tags.length > 0 && (
                <div className="grid grid-cols-3 gap-3 ">
                  {tags.map((tag: string) => (
                    <Tag key={tag} title={tag} />
                  ))}
                </div>
              )}
            </InfoSection>
          </div>
        </div>

        <div className="flex flex-row items-center justify-end gap-4 px-6 pb-6">
          {notes && (
            <InfoSection label={t('create_audit.labels.notes') as string}>
              <Typography size="body-medium-16">{notes}</Typography>
            </InfoSection>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

const AuditViewNotarizedModal = ({ children }: AuditViewNotarizedModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="AuditViewNotarizedModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default AuditViewNotarizedModal;
