import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { sendModifyPushNotification } from '../../../../api/axios';
import { Button, Field, Form, Input, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { IconClose } from '../../../../icons';
import {
  getAvailableContracts,
  getContractsFromFunctionalities,
} from '../../../../imports/contractsParsers';
import { AvailableOn, ShopForm } from '../../../../imports/types';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { updateShops } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getFunctionalityRef,
  removeFieldFromNft,
  setFunctionality,
  setNftData,
  updateAvailableOn,
} from '../../api';
import { Contract, FunctionalitiesShop, Nft } from '../../imports/types';
import { isSecureUrl } from '../../imports/utils';
import ContractsManager from '../contractsManager/ContractsManager';
import { getContractsToRemove } from './query';

type ShopProps = {
  edit: FunctionalitiesShop | null;
};

const Shop = ({ edit }: ShopProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { workspace } = useAppSelector((state) => state.team);
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const { uid } = useAppSelector((state) => state.user);
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
  const dispatch = useAppDispatch();
  const formShop = {
    initialValuesShop: {
      title: edit?.title || '',
      link: edit?.link || '',
    },
    validationSchemaShop: yup.object({}),
  };
  const { initialValuesShop, validationSchemaShop } = formShop;

  const handleSubmitShop = async (fields: { options: []; link: string; title: string }) => {
    if (workspace) {
      const newFunctionalityRef = getFunctionalityRef(edit);

      const functionality: FunctionalitiesShop = {
        type: 'shop',
        workspaceId: workspace.id, //workspace?.owner ?? profile.uid,
        link: fields.link,
        availableOn: edit?.availableOn || {},
        id: newFunctionalityRef.id,
        title: edit?.title ? edit?.title : fields.title,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      const url = isSecureUrl(fields.link);

      if (url && selectedContracts?.length > 0) {
        functionality.availableOn = getAvailableContracts(selectedContracts);

        if (edit) {
          /**
           * Code to delete the functionality from NFTs
           */
          await Promise.allSettled(
            getContractsFromFunctionalities(
              getContractsToRemove(edit.availableOn, selectedContracts),
              contractsList
            ).map((contract) => {
              return contract.nfts.map((nft: Nft) => {
                /* update nft.attributes on redux contract */

                return removeFieldFromNft(contract.id, nft, 'shop');
              });
            })
          );
        }

        const data = await Promise.allSettled(
          selectedContracts
            .map(async (contract) => {
              /**
               * Create a copy of contract
               */
              const updatedContract = cloneDeep(contract);

              const modifiedNftsIds: number[] = [];
              const promises = contract.nfts.map(async (nft) => {
                try {
                  /**
                   * If edit mode update only the shop_url
                   */
                  const data = {
                    shop: { value: fields.link, id: newFunctionalityRef.id },
                  };

                  updatedContract.nfts = contract.nfts.map((updatedNft: Nft) => {
                    if (nft.id === updatedNft.id) {
                      return { ...updatedNft, shop: data.shop };
                    }
                    return updatedNft;
                  });
                  /* add to redux */

                  dispatch(updateContract(updatedContract));

                  modifiedNftsIds.push(nft.id);
                  /** Add shop to Nft  */
                  const promise = await setNftData(contract.id, nft, data);
                  return promise;
                } catch (error) {
                  console.error(error);
                  loadingStatusDispatch({
                    type: 'SET_ERROR',
                    payload: {
                      title: t('collection.functionality.actions.failed'),
                    },
                  });
                  return error;
                }
              });
              await sendModifyPushNotification(uid, contract.id, modifiedNftsIds);

              return promises;
            })
            .flat()
        );
        /* add functionality on db in functionalities collection*/
        setFunctionality(newFunctionalityRef, functionality, true);
        if (edit) {
          const availableOn: AvailableOn = selectedContracts.reduce((acc, contract) => {
            acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
            return acc;
          }, {} as AvailableOn);
          updateAvailableOn(newFunctionalityRef, availableOn);
        }
        /* check if all promises had success */
        let isError = false;
        data.forEach((promise: any) => {
          if (promise.status === 'fulfilled' && promise.value.error) {
            isError = true;
          }
        });
        if (isError) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        } else {
          dispatch(updateShops(functionality as unknown as ShopForm));
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: edit
                ? t('collection.functionality.actions.updated')
                : t('collection.functionality.actions.success'),
            },
          });
        }
      } else {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('collection.functionality.actions.failed'),
          },
        });
      }
    }
  };

  return (
    <div>
      <Typography color="primary-500" size="body-medium-30" className="p-4">
        {t('collection.functionality.shop')}
      </Typography>

      <Form
        initialValues={initialValuesShop}
        validationSchema={validationSchemaShop}
        className="mt-4 flex size-full flex-col gap-4 p-4 "
      >
        {({ fields, handleSubmit, errors, resetField, watch, setValue, isValid, register }) => {
          const values = watch();

          return (
            <>
              <Field label={t('collection.functionality.news_title')} error={errors.title?.message}>
                <Input
                  id={fields.title.name}
                  type="text"
                  placeholder={t('collection.functionality.placeholders_title')}
                  name={fields.title.name}
                  onBlur={fields.title.onBlur}
                  onChange={fields.title.onChange}
                  inputRef={fields.title.ref}
                  error={errors.title?.message}
                  elementRight={
                    values.title && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.title.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field label={t('collection.functionality.link')} error={errors.link?.message}>
                <Input
                  id={fields.link.name}
                  type="text"
                  placeholder={t('collection.functionality.placeholders_link')}
                  name={fields.link.name}
                  onBlur={fields.link.onBlur}
                  onChange={fields.link.onChange}
                  inputRef={fields.link.ref}
                  error={errors.link?.message}
                  elementRight={
                    values.link && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.link.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field error={errors.options?.message}>
                <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
                  {t('collection.functionality.select_contract')}
                </Typography>
                <div className=" max-h-[400px] overflow-y-auto overflow-x-hidden">
                  <ContractsManager
                    availableContracts={edit?.availableOn ? edit.availableOn : {}}
                    setContracts={setSelectedContracts}
                  />
                </div>
              </Field>

              <div className="mt-4 flex justify-end ">
                <Button
                  id="create-functionality"
                  type="primary"
                  disabled={!values.title || !values.link || !selectedContracts.length}
                  action={() => handleSubmit(() => handleSubmitShop(values))()}
                >
                  {t('collection.functionality.submit')}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default Shop;
