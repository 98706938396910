import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DateRange = {
  date_to: string;
  date_from: string;
};

type useDateRangeHookParams = {
  initialDates?: DateRange;
  selectOptionsArray?: {
    label: string;
    select_value: DateRange;
    value: string | undefined;
    disabled?: boolean;
  }[];
};

export default function useDateRange(params?: useDateRangeHookParams | null) {
  const defaultDates = {
    date_to: new Date().toISOString().split('T')[0],
    date_from: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())
      .toISOString()
      .split('T')[0],
  };

  const { initialDates, selectOptionsArray } = params || {};

  const initialDateRange = initialDates || defaultDates;

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);

  const { t } = useTranslation(['tokenCreator']);

  const { date_to, date_from } = dateRange;

  const calculateDateRange = (daysAgo: number) => ({
    date_from: new Date(new Date().getTime() - daysAgo * 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0],
    date_to: new Date().toISOString().split('T')[0],
  });

  const defaultSelectOptions: {
    label: string;
    select_value: DateRange;
    value: string | undefined;
    disabled?: boolean;
  }[] = [
    {
      label: t(`analytics.date_range.undefined`) as string,
      value: undefined,
      select_value: dateRange,
    },
    {
      label: t(`analytics.date_range.today`) as string,
      value: 'today',
      select_value: calculateDateRange(0),
    },
    {
      label: t(`analytics.date_range.week`) as string,
      value: 'week',
      select_value: calculateDateRange(7),
    },
    {
      label: t(`analytics.date_range.month`) as string,
      value: 'month',
      select_value: calculateDateRange(30),
    },
    {
      label: t(`analytics.date_range.quarter`) as string,
      value: 'quarter',
      select_value: calculateDateRange(90),
    },
    {
      label: t(`analytics.date_range.half_year`) as string,
      value: 'half_year',
      select_value: calculateDateRange(180),
    },

    {
      label: t(`analytics.date_range.year`) as string,
      value: 'year',
      select_value: calculateDateRange(365),
    },
  ];

  const selectOptions = selectOptionsArray || defaultSelectOptions;

  const handleDateRange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;

    // Check if the selected date range matches any of the predefined options
    const matchedOption = selectOptions.find(
      (option) =>
        option.select_value.date_from === date_from && option.select_value.date_to === date_to
    );

    // If it matches, update the selected value in the state
    if (matchedOption) {
      setSelectedValue(matchedOption.value);
    } else {
      // If it doesn't match, set the selected value to undefined
      setSelectedValue(undefined);
    }

    setDateRange((prevState) => {
      if (name === 'date_from' && new Date(value) > new Date(prevState.date_to)) {
        return {
          ...prevState,
          date_from: value,
          date_to: value,
        };
      }

      if (name === 'date_to' && new Date(value) < new Date(prevState.date_from)) {
        return {
          ...prevState,
          date_from: value,
          date_to: value,
        };
      }

      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSelectChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    const selectedOption = selectOptions.find((option) => option.value === value)?.select_value;
    if (selectedOption) setDateRange(selectedOption);
  };

  return {
    dateRange,
    handleDateRange,
    initialDateRange,
    setDateRange,
    selectedValue,
    handleSelectChange,
    selectOptions,
  };
}
