/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconOffline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    width="100"
    height="100"
    id="wifi-x"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      fill="none"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      d="M92.91969,166.06177a50.7769,50.7769,0,0,1,70.145,0"
    ></path>
    <line
      x1="224"
      x2="176"
      y1="56"
      y2="104"
      fill="none"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    ></line>
    <line
      x1="224"
      x2="176"
      y1="104"
      y2="56"
      fill="none"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    ></line>
    <path
      fill="none"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      d="M25.06379 98.17952A145.90174 145.90174 0 0 1 127.99219 56.0029a148.757 148.757 0 0 1 16.01524.86162M58.97857 132.12064a98.11506 98.11506 0 0 1 69.01362-28.11774 99.87589 99.87589 0 0 1 16.02985 1.28855"
    ></path>
    <circle
      fill={props.stroke ? props.stroke : '#4F4F4F'}
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      cx="128"
      cy="200"
      r="12"
    ></circle>
  </svg>
);
export default IconOffline;
