import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { Button, Field, Form, Input } from '..';
import { createFolder } from '../../api/folders';
import { useLoadingStatusContext } from '../../context';
import { useValidation } from '../../hooks';
import { IconClose } from '../../icons';
import { useAppSelector } from '../../redux/hooks';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type NewFolderModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { folderType } = useModalProps('NewFolderModal');
  const { t } = useTranslation(['notarization']);

  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const targetOwnerUid = workspace?.owner ?? uid;
  const { required } = useValidation();
  const { dispatch: loaderDispatch, state } = useLoadingStatusContext();

  const form = {
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: required(yup.string()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleAddFolder = async (values: { name: string }) => {
    const { name } = values;

    loaderDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('add_folder.actions.pending'),
      },
    });

    await createFolder(targetOwnerUid, name, folderType, workspace?.id!)
      .then(() => {
        loaderDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('add_folder.actions.success'),
          },
        });
      })
      .catch(() => {
        loaderDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('add_folder.actions.failed'),
          },
        });
      });
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mt-7 flex size-full flex-col gap-7 p-4 ">
        <div>
          <Typography weight="medium" size="body-medium-30" color="primary-500">
            {t('add_folder.title')}
          </Typography>
        </div>
        <div data-cy="addFolder">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex size-full flex-col gap-7"
          >
            {({ fields, handleSubmit, errors, resetField, isValid, watch }) => {
              const values = watch();
              return (
                <>
                  <div className="flex flex-col items-center space-y-2">
                    <Field
                      error={errors.name?.message}
                      label={`${t('add_folder.form_placeholders.folderName')}*`}
                    >
                      <Input
                        type="text"
                        placeholder={t('add_folder.form_placeholders.name')}
                        id={fields.name.name}
                        name={fields.name.name}
                        onBlur={fields.name.onBlur}
                        onChange={fields.name.onChange}
                        inputRef={fields.name.ref}
                        error={errors.name?.message}
                        elementRight={
                          values.name && (
                            <Button
                              type="ghost"
                              icon={IconClose}
                              action={() => resetField(fields.name.name)}
                            />
                          )
                        }
                      />
                    </Field>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      id="newFolder"
                      disabled={!isValid}
                      type="primary"
                      action={() => handleSubmit(handleAddFolder)()}
                      loading={state.status === 'pending'}
                      className="px-6"
                    >
                      {t('add_folder.submit_data')}
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </ModalLayout>
  );
};

const NewFolderModal = ({ children }: NewFolderModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="NewFolderModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default NewFolderModal;
