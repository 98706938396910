import { SVGProps } from 'react';

const IconTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38850)">
      <path
        d="M92.0243 19.425L31.4993 31.5L19.4243 92.025C19.2351 92.9914 19.2866 93.9895 19.5744 94.9313C19.8621 95.873 20.3773 96.7295 21.0743 97.425L99.3743 175.725C99.9261 176.29 100.585 176.738 101.313 177.045C102.041 177.351 102.822 177.509 103.612 177.509C104.401 177.509 105.183 177.351 105.911 177.045C106.638 176.738 107.297 176.29 107.849 175.725L175.724 107.85C176.289 107.298 176.738 106.639 177.044 105.911C177.35 105.184 177.508 104.402 177.508 103.613C177.508 102.823 177.35 102.041 177.044 101.314C176.738 100.586 176.289 99.9269 175.724 99.375L97.4243 21.075C96.7287 20.378 95.8723 19.8629 94.9305 19.5751C93.9888 19.2874 92.9907 19.2358 92.0243 19.425V19.425Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 72C67.9706 72 72 67.9706 72 63C72 58.0294 67.9706 54 63 54C58.0294 54 54 58.0294 54 63C54 67.9706 58.0294 72 63 72Z"
        fill="#4F4F4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38850">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconTag;
