import { getAuth } from 'firebase/auth';
import i18next from 'i18next';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { changeEmail, changePassword, reauthenticateUser } from '../../api/firebase';
import { useValidation } from '../../hooks';
import { IconInvisible, IconVisible } from '../../icons';
import { delayed } from '../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateProfile } from '../../redux/user/user.slice';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type FieldsModalProps = {
  children: ReactNode;
};
// const EditField = ({ type, handleCloseSidebar }: EditFieldProps) => {
//   const { t } = useTranslation();
//   const { required, validateEmail } = useValidation();
//   const profile = useAppSelector((state) => state.user);
//   const { uid, wallet } = profile;
//   const dispatch = useAppDispatch();

//   const [isLoading, setIsLoading] = useState(false);
//   const [step, setStep] = useState(0);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showCurrentPassword, setShowCurrentPassword] = useState(false);

//   const isStepForm = step === 0;
//   const isStepSuccess = step === 1;
//   const isStepError = step === 2;

//   const form = {
//     initialValues: {
//       ...(type === 'email' && {
//         email: '',
//       }),
//       ...(type === 'password' && {
//         password: '',
//       }),
//       currentPassword: '',
//     },
//     validationSchema: yup.object({
//       ...(type === 'email' && {
//         email: required(validateEmail()),
//       }),
//       ...(type === 'password' && {
//         password: required(yup.string()),
//       }),
//       currentPassword: required(yup.string()),
//     }),
//   };

//   const { initialValues, validationSchema } = form;

//   const handleEditField = async (values: any) => {
//     const { email, password, currentPassword } = values;
//     setIsLoading(true);

//     const auth = getAuth();
//     const { currentUser } = auth;

//     if (currentUser && currentUser.email) {
//       const { error: reauthenticationError } = await reauthenticateUser(
//         currentUser,
//         currentPassword
//       );

//       if (reauthenticationError) {
//         console.log('reauthenticationError', reauthenticationError);
//         delayed(() => {
//           setIsLoading(false);
//           setStep(2);
//         }, 2500);
//         return;
//       }

//       if (type === 'email') {
//         const { error: changeEmailError } = await changeEmail(currentUser, email);

//         if (changeEmailError) {
//           console.log('changeEmailError', changeEmailError);
//           delayed(() => {
//             setIsLoading(false);
//             setStep(2);
//           }, 2500);
//           return;
//         }

//         dispatch(updateProfile({ profile: { email } }));
//       }

//       if (type === 'password') {
//         const { error: changePasswordError } = await changePassword(uid, currentPassword, password);

//         if (changePasswordError) {
//           console.log('changePasswordError', changePasswordError);
//           delayed(() => {
//             setIsLoading(false);
//             setStep(2);
//           }, 2500);
//           return;
//         }
//       }

//       delayed(() => {
//         setIsLoading(false);
//         setStep(1);
//       }, 2500);
//     }
//   };
//   const handleLanguage = (lng: 'it' | 'en') => {
//     localStorage.setItem('lng', lng);
//     i18next.changeLanguage(lng);
//     handleCloseSidebar();
//   };
//   const lng = localStorage.getItem('lng') || navigator.language.slice(0, 2);

//   return (
//     <div className="flex flex-col items-center w-full space-y-4">
//       {isStepForm && (
//         <Form
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           className="flex flex-col w-full"
//         >
//           {({ fields, handleSubmit, errors, resetField }) => (
//             <div className="flex flex-col items-center space-y-4 text-center">
//               <Typography weight="medium" size="lg">
//                 {type === 'email' && t('account.edit_profile_field.title', { field: 'email' })}
//                 {type === 'password' &&
//                   t('account.edit_profile_field.title', { field: 'password' })}
//                 {type === 'language' &&
//                   t('account.edit_profile_field.title', {
//                     field: t('account.profile_fields.language'),
//                   })}
//               </Typography>

//               <div className="w-full text-left">
//                 {type === 'language' && (
//                   <>
//                     <Button
//                       className={`!mt-2 ${lng === 'it' ? 'primary' : 'secondary'}`}
//                       action={() => handleLanguage('it')}
//                     >
//                       {t('account.edit_profile_field.it')}
//                     </Button>
//                     <Button
//                       className={`!mt-2 ${lng === 'en' ? 'primary' : 'secondary'}`}
//                       action={() => handleLanguage('en')}
//                     >
//                       {t('account.edit_profile_field.en')}
//                     </Button>
//                   </>
//                 )}

//                 {type === 'email' && (
//                   <>
//                     <Field error={errors.email?.message}>
//                       <Input
//                         type="text"
//                         placeholder={t('account.edit_profile_field.form_placeholders.email')}
//                         id={fields.email.name}
//                         name={fields.email.name}
//                         onBlur={fields.email.onBlur}
//                         onChange={fields.email.onChange}
//                         inputRef={fields.email.ref}
//                         error={errors.email?.message}
//                         elementRight={
//                           <Button
//                             type="ghost"
//                             icon={IconClose}
//                             action={() => resetField(fields.email.name)}
//                           />
//                         }
//                       />
//                     </Field>

//                     <Field error={errors.currentPassword?.message}>
//                       <Input
//                         type={showCurrentPassword ? 'text' : 'password'}
//                         placeholder={t(
//                           'account.edit_profile_field.form_placeholders.current_password'
//                         )}
//                         id={fields.currentPassword.name}
//                         name={fields.currentPassword.name}
//                         onBlur={fields.currentPassword.onBlur}
//                         onChange={fields.currentPassword.onChange}
//                         inputRef={fields.currentPassword.ref}
//                         error={errors.currentPassword?.message}
//                         elementRight={
//                           <Button
//                             type="ghost"
//                             icon={showCurrentPassword ? IconEyeClose : IconEyeOpen}
//                             action={() => setShowCurrentPassword((prev) => !prev)}
//                           />
//                         }
//                       />
//                     </Field>
//                   </>
//                 )}

//                 {type === 'password' && (
//                   <>
//                     <Field error={errors.currentPassword?.message}>
//                       <Input
//                         type={showCurrentPassword ? 'text' : 'password'}
//                         placeholder={t(
//                           'account.edit_profile_field.form_placeholders.current_password'
//                         )}
//                         id={fields.currentPassword.name}
//                         name={fields.currentPassword.name}
//                         onBlur={fields.currentPassword.onBlur}
//                         onChange={fields.currentPassword.onChange}
//                         inputRef={fields.currentPassword.ref}
//                         error={errors.currentPassword?.message}
//                         elementRight={
//                           <Button
//                             type="ghost"
//                             icon={showCurrentPassword ? IconEyeClose : IconEyeOpen}
//                             action={() => setShowCurrentPassword((prev) => !prev)}
//                           />
//                         }
//                       />
//                     </Field>

//                     <Field error={errors.password?.message}>
//                       <Input
//                         type={showNewPassword ? 'text' : 'password'}
//                         placeholder={t('account.edit_profile_field.form_placeholders.password')}
//                         id={fields.password.name}
//                         name={fields.password.name}
//                         onBlur={fields.password.onBlur}
//                         onChange={fields.password.onChange}
//                         inputRef={fields.password.ref}
//                         error={errors.password?.message}
//                         elementRight={
//                           <Button
//                             type="ghost"
//                             icon={showNewPassword ? IconEyeClose : IconEyeOpen}
//                             action={() => setShowNewPassword((prev) => !prev)}
//                           />
//                         }
//                       />
//                     </Field>
//                   </>
//                 )}
//                 {type !== 'language' && (
//                   <div className="!mt-2">
//                     <Button action={() => handleSubmit(handleEditField)()} loading={isLoading}>
//                       {t('account.edit_profile_field.save')}
//                     </Button>
//                   </div>
//                 )}
//               </div>
//             </div>
//           )}
//         </Form>
//       )}

//       {(isStepSuccess || isStepError) && (
//         <div className="flex flex-col items-center space-y-4 text-center">
//           {isStepSuccess && (
//             <>
//               <Typography weight="medium" size="lg">
//                 {type === 'email' && t('account.success.field_changed', { field: 'email' })}
//                 {type === 'password' && t('account.success.field_changed', { field: 'password' })}
//               </Typography>

//               <Button action={handleCloseSidebar}>{t('account.edit_profile_field.close')}</Button>
//             </>
//           )}

//           {isStepError && (
//             <>
//               <Typography weight="medium" size="lg">
//                 {t('account.error.generic')}
//               </Typography>

//               <Button action={() => setStep(0)}>{t('account.edit_profile_field.go_back')}</Button>
//             </>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };
const ModalBody = ({ setOpen }: any) => {
  const { type } = useModalProps('ProfileFieldsModal');

  const { t } = useTranslation();
  const { required, validateEmail } = useValidation();
  const profile = useAppSelector((state) => state.user);
  const { uid, wallet } = profile;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const isStepForm = step === 0;
  const isStepSuccess = step === 1;
  const isStepError = step === 2;

  const form = {
    initialValues: {
      ...(type === 'email' && {
        email: '',
      }),
      ...(type === 'password' && {
        password: '',
      }),
      currentPassword: '',
    },
    validationSchema: yup.object({
      ...(type === 'email' && {
        email: required(validateEmail()),
      }),
      ...(type === 'password' && {
        password: required(yup.string()),
      }),
      currentPassword: required(yup.string()),
    }),
  };

  const { initialValues, validationSchema } = form;

  const handleEditField = async (values: any) => {
    const { email, password, currentPassword } = values;
    setIsLoading(true);

    const auth = getAuth();
    const { currentUser } = auth;

    if (currentUser && currentUser.email) {
      const { error: reauthenticationError } = await reauthenticateUser(
        currentUser,
        currentPassword
      );

      if (reauthenticationError) {
        console.error('[ReauthenticationError]', reauthenticationError);
        delayed(() => {
          setIsLoading(false);
          setStep(2);
        }, 2500);
        return;
      }

      if (type === 'email') {
        const { error: changeEmailError } = await changeEmail(currentUser, email);

        if (changeEmailError) {
          console.error('[ChangeEmailError]', changeEmailError);
          delayed(() => {
            setIsLoading(false);
            setStep(2);
          }, 2500);
          return;
        }

        dispatch(updateProfile({ profile: { email } }));
      }

      if (type === 'password') {
        const { error: changePasswordError } = await changePassword(uid, currentPassword, password);

        if (changePasswordError) {
          console.error('[ChangePasswordError]', changePasswordError);
          delayed(() => {
            setIsLoading(false);
            setStep(2);
          }, 2500);
          return;
        }
      }

      delayed(() => {
        setIsLoading(false);
        setStep(1);
      }, 2500);
    }
  };
  const handleLanguage = (lng: 'it' | 'en') => {
    localStorage.setItem('lng', lng);
    i18next.changeLanguage(lng);
  };
  const lng = localStorage.getItem('lng') || navigator.language.slice(0, 2);
  return (
    <ModalLayout onClose={() => setOpen(false)}>
      <div className="flex w-full flex-col items-center justify-center space-y-4 px-[40px] pt-[80px]">
        {isStepForm && (
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex w-full flex-col space-y-4"
          >
            {({ fields, handleSubmit, errors, resetField }) => (
              <div className="mb-[50px] flex flex-col items-start gap-[28px] text-left">
                <Typography size="body-medium-30" color="primary-500">
                  {type === 'email' && t('account.edit_profile_field.title', { field: 'email' })}
                  {type === 'password' &&
                    t('account.edit_profile_field.title', { field: 'password' })}
                  {type === 'language' &&
                    t('account.edit_profile_field.title', {
                      field: t('account.profile_fields.language'),
                    })}
                </Typography>

                <div className="w-full text-left">
                  {/* {type === 'language' && (
                    <>
                      <Button
                        className={`!mt-2 ${lng === 'it' ? 'primary' : 'secondary'}`}
                        action={() => handleLanguage('it')}
                      >
                        {t('account.edit_profile_field.it')}
                      </Button>
                      <Button
                        className={`!mt-2 ${lng === 'en' ? 'primary' : 'secondary'}`}
                        action={() => handleLanguage('en')}
                      >
                        {t('account.edit_profile_field.en')}
                      </Button>
                    </>
                  )} */}

                  {type === 'email' && (
                    <>
                      <Field
                        error={errors.email?.message}
                        label={t('account.edit_profile_field.form_placeholders.email')}
                      >
                        <Input
                          type="text"
                          placeholder={t(
                            'account.edit_profile_field.form_placeholders.insert_new_mail'
                          )}
                          id={fields.email.name}
                          name={fields.email.name}
                          onBlur={fields.email.onBlur}
                          onChange={fields.email.onChange}
                          inputRef={fields.email.ref}
                          error={errors.email?.message}
                          className="mt-[5px]"
                          // elementRight={
                          //   <Button
                          //     type="ghost"
                          //     icon={IconClose}
                          //     action={() => resetField(fields.email.name)}
                          //   />
                          // }
                        />
                      </Field>

                      <Field
                        error={errors.currentPassword?.message}
                        label={t('account.edit_profile_field.form_placeholders.current_password')}
                        className="mt-[20px]"
                      >
                        <Input
                          type={showCurrentPassword ? 'text' : 'password'}
                          placeholder={t('authentication.form_placeholders.password')}
                          id={fields.currentPassword.name}
                          name={fields.currentPassword.name}
                          onBlur={fields.currentPassword.onBlur}
                          onChange={fields.currentPassword.onChange}
                          inputRef={fields.currentPassword.ref}
                          error={errors.currentPassword?.message}
                          className="mt-[5px]"
                          // elementRight={
                          //   <Button
                          //     type="ghost"
                          //     icon={showCurrentPassword ? IconEyeClose : IconEyeOpen}
                          //     action={() => setShowCurrentPassword((prev) => !prev)}
                          //   />
                          // }
                        />
                      </Field>
                    </>
                  )}

                  {type === 'password' && (
                    <>
                      <Field
                        error={errors.currentPassword?.message}
                        label={t('account.edit_profile_field.form_placeholders.current_password')}
                      >
                        <Input
                          type={showCurrentPassword ? 'text' : 'password'}
                          placeholder={t('authentication.form_placeholders.password')}
                          id={fields.currentPassword.name}
                          name={fields.currentPassword.name}
                          onBlur={fields.currentPassword.onBlur}
                          onChange={fields.currentPassword.onChange}
                          inputRef={fields.currentPassword.ref}
                          error={errors.currentPassword?.message}
                          className="mt-[5px]"
                          elementRight={
                            <Button
                              type="ghost"
                              icon={showCurrentPassword ? IconInvisible : IconVisible}
                              action={() => setShowCurrentPassword((prev) => !prev)}
                            />
                          }
                        />
                      </Field>

                      <Field
                        error={errors.password?.message}
                        label={t('account.edit_profile_field.form_placeholders.password')}
                        className="mt-[20px]"
                      >
                        <Input
                          type={showNewPassword ? 'text' : 'password'}
                          placeholder={t('authentication.form_placeholders.new_password')}
                          id={fields.password.name}
                          name={fields.password.name}
                          onBlur={fields.password.onBlur}
                          onChange={fields.password.onChange}
                          inputRef={fields.password.ref}
                          error={errors.password?.message}
                          className="mt-[5px]"
                          elementRight={
                            <Button
                              type="ghost"
                              icon={showNewPassword ? IconInvisible : IconVisible}
                              action={() => setShowNewPassword((prev) => !prev)}
                            />
                          }
                        />
                      </Field>
                    </>
                  )}
                  {type !== 'language' && (
                    <div className="!mt-[50px]">
                      <Button
                        className="mr-0 "
                        action={() => handleSubmit(handleEditField)()}
                        loading={isLoading}
                      >
                        {t('account.edit_profile_field.save')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Form>
        )}

        {(isStepSuccess || isStepError) && (
          <div className="flex flex-col items-center space-y-4 text-center">
            {isStepSuccess && (
              <>
                <Typography weight="medium" size="lg">
                  {type === 'email' && t('account.success.field_changed', { field: 'email' })}
                  {type === 'password' && t('account.success.field_changed', { field: 'password' })}
                </Typography>

                {/* <Button action={handleCloseSidebar}>{t('account.edit_profile_field.close')}</Button> */}
              </>
            )}

            {isStepError && (
              <>
                <Typography weight="medium" size="lg">
                  {t('account.error.generic')}
                </Typography>

                <Button action={() => setStep(0)}>{t('account.edit_profile_field.go_back')}</Button>
              </>
            )}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

const ProfileFiledsModal = ({ children }: FieldsModalProps) => {
  const [isOpen, setOpen] = useModalState();

  return (
    <Modal id="ProfileFieldsModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ProfileFiledsModal;
