import { getRemoteConfigValue } from '../../../imports/remoteConfig';
import { Nft, NftCustomAttribute, NftsCustomField } from './types';

export const isSecureUrl = (url: string | undefined): boolean => {
  if (url !== undefined) {
    try {
      const newUrl = new URL(url);
      if (newUrl.protocol === 'https:') {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }
  return false;
};

export const isFileTypeImage = ({ type }: File) => {
  const [key, value] = type.split('/');
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');

  if (allowedFileType[key + '/*']) {
    return allowedFileType[key + '/*'].includes('.' + value);
  }
  return false;
};

/**
 * @description Returns Nft's ordered attributes, ready for visualization
 * @param index: number
 * @param nfts: NftsCustomField[]
 * @returns orderedAttr: NftAttributes[][]
 */
export const handleOrderAttributes = (
  index: number,
  nfts: NftsCustomField[]
): NftCustomAttribute[][] => {
  const typeDocs = ['document', 'warranty_certificate', 'authenticity_certificate'];
  const orderedAttributes: NftCustomAttribute[][] = [];

  let partialArray: NftCustomAttribute[] = [];
  nfts[index]?.attributes?.forEach((attr: NftCustomAttribute, i: number) => {
    if (
      partialArray.length === 0 ||
      (nfts[index]?.attributes?.[i - 1]?.type === attr?.type && attr?.type === 'partner') ||
      (nfts[index]?.attributes?.[i - 1]?.type === attr?.type && attr?.type === 'image') /* || */
      // (typeDocs.includes(nfts[index]?.attributes?.[i - 1]?.type) &&
      //   typeDocs.includes(attr?.type) &&
      //   partialArray.length === 1)
    ) {
      partialArray.push(attr);
    } else {
      orderedAttributes.push(partialArray);
      partialArray = [attr];
    }

    // eslint-disable-next-line no-unsafe-optional-chaining
    if (i === nfts[index]?.attributes?.length - 1) {
      orderedAttributes.push(partialArray);
    }
  });

  return orderedAttributes;
};

export const isUrl = (url: string | undefined) => {
  if (url !== undefined) {
    try {
      if (url === '' || new URL(url)) {
        return true;
      }
    } catch {
      return false;
    }
  }
  return false;
};

/**
 *
 * @param nft
 * @returns A new object with {name: string, size:number} instead of every File or Blob object
 */
export const transformBlobIntoNameAndSize = (nft: Nft) => {
  const newObject = {};
  Object.keys(nft).forEach((key) => {
    const value = nft[key as keyof typeof nft] as any;
    // if value?.[0]?.size != null then it's a blob!
    if (value?.[0]?.size != null) {
      Object.assign(newObject, { [key]: { size: value?.[0]?.size, name: value?.[0]?.name } });
    } else {
      // just copy the field
      Object.assign(newObject, { [key]: value });
    }
  });
  return newObject;
};

/**
 * @description compares two nfts using name and size
 * @param nft1
 * @param nft2
 * @returns
 */
export const nftsAreEqual = (nft1: Nft, nft2: Nft) => {
  // File or Blob are crazy to handle so we'll substitute those only with (name, size)

  const nft1NoBlobs = transformBlobIntoNameAndSize(nft1);
  const nft2NoBlobs = transformBlobIntoNameAndSize(nft2);

  return JSON.stringify(nft1NoBlobs) === JSON.stringify(nft2NoBlobs);
};

/**
 * @description check if the name is valid (no symbols)
 * @param imageName
 * @returns boolean
 */
export const isValidImageName = (imageName: string): boolean => {
  const pattern: RegExp = /^[a-zA-Z0-9\s.]+$/;
  return pattern.test(imageName);
};
