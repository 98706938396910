import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { doc, getDoc } from 'firebase/firestore';
import { SetStateAction, useEffect, useState } from 'react';
import { useModal } from 'react-simple-modal-provider';
import { useAppSelector } from '../../redux/hooks';
import LinkText from '../linkText/LinkText';

import { topbarModulesMenuOrderList } from '../../imports/constants';

import { tokenCreatorSidebarMenuOrderList } from '../../modules/tokenCreator/imports/constants';

import { IconFileGeneral } from '../../icons';
import { auth, db } from '../../imports/firebase';
import routes from '../../imports/routes';
import { PublicCompany } from '../../imports/types';
import ItemMenuBar from '../item/ItemMenuBar';

// type TopbarMenuProps = {
//   menuList: { [key: string]: string[] };
// };
const TopbarMenu = () => {
  //{ menuList }: TopbarMenuProps
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { workspace } = useAppSelector((state) => state.team);
  const { company, wallet } = useAppSelector((state) => state.user);
  const { address } = wallet || {};

  const [open, setOpen] = useState('none');

  const [publicCompany, setPublicCompany] = useState<PublicCompany>();
  const getPublicCompany = async () => {
    // setIsLoading(true);
    const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
    const snapshot = await getDoc(publicDocRef);

    if (snapshot.exists()) {
      setPublicCompany({
        website: '',
        ...snapshot.data(),
      } as PublicCompany);
    } else {
      const publicCompany: PublicCompany = {
        address,
        companyName: company,
        cta: '',
        ctaLink: '',
        description: '',
        profilePic: '',
        website: '',
      };
      setPublicCompany(publicCompany);
    }
  };

  useEffect(() => {
    if (auth.currentUser) getPublicCompany();
  }, [auth.currentUser]);

  const subMenuItemsOrderList: { [key: string]: string[] } = {
    //notarization: notarizationsSidebarMenuOrderList,
    //audit_db: auditSidebarMenuOrderList,
    nft: !workspace?.permissions?.token_creator?.write
      ? tokenCreatorSidebarMenuOrderList.filter(
          (menuItem) => !['crm', 'company_profile'].includes(menuItem)
        )
      : tokenCreatorSidebarMenuOrderList,
    //api: ['api'],
  };
  const { open: openModalNewCompany } = useModal('NewCompanyModal');
  const handleOpenNewCompany = (page: SetStateAction<string>) => {
    openModalNewCompany({ publicCompany });
  };
  const [active, setActive] = useState(Object.keys(subMenuItemsOrderList)[0]);

  const checkPermission = ['api'];

  return (
    <div className="flex flex-row gap-x-12">
      {topbarModulesMenuOrderList.map((page, i) => {
        const menuItem = routes.find((route) => route.name === page);
        const hasChildren = menuItem?.childRoutes && menuItem.childRoutes.length > 0;
        const isAllowed = checkPermission.includes(page) ? workspace?.isOwner : true;

        if (
          (page === 'audit_db' &&
            !workspace?.permissions?.audit?.write &&
            !workspace?.permissions?.audit?.read) ||
          (page === 'nft' &&
            !workspace?.permissions?.token_creator?.write &&
            !workspace?.permissions?.token_creator?.read) ||
          (page === 'notarization' &&
            !workspace?.permissions?.notarization?.write &&
            !workspace?.permissions?.notarization?.read)
        ) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return (
          menuItem &&
          isAllowed && (
            <div key={i} onClick={() => setActive(menuItem.name)}>
              <div
                onMouseEnter={() => setOpen(menuItem.name)}
                onMouseLeave={() => setOpen('none')}
                className="relative"
              >
                <LinkText
                  to={menuItem.path as string}
                  textColor={`${pathname.includes(menuItem.path) ? 'secondary-500' : 'black'}`}
                  textColorHover="secondary-500"
                  noUnderline
                  textSize="text-body-regular-20"
                >
                  {t(`topbarMenu.items.${menuItem.name}`) as string}
                </LinkText>
                {menuItem.name === 'nft' && hasChildren && (
                  <div
                    onMouseEnter={() => setOpen(menuItem.name)}
                    onMouseLeave={() => setOpen('none')}
                    className={`absolute left-0 flex w-[210px] flex-col rounded-lg border border-grey-300 bg-white ${
                      open === menuItem.name ? 'block' : 'hidden'
                    }`}
                  >
                    {subMenuItemsOrderList[menuItem.name]?.map((subPage, i) => {
                      const subMenuItem = menuItem.childRoutes?.find(
                        (route: any) => route.name === subPage
                      );
                      let path = '';
                      if (subMenuItem?.path) {
                        if (typeof subMenuItem?.path === 'string') {
                          path = (menuItem.path as string) + subMenuItem.path;
                        } else {
                          path = menuItem.path as string;
                        }
                      }
                      return subMenuItem && subMenuItem.name !== 'company_profile' ? (
                        <ItemMenuBar
                          key={subMenuItem.name}
                          id={subMenuItem.name}
                          name={t(`topbarMenu.sub_items.${subMenuItem.name}`)}
                          icon={subMenuItem.topbarIcon ? subMenuItem.topbarIcon : IconFileGeneral}
                          linkTo={path}
                        />
                      ) : (
                        subMenuItem && subMenuItem.name === 'company_profile' && (
                          <ItemMenuBar
                            key={subMenuItem.name}
                            id={subMenuItem.name}
                            name={t(`topbarMenu.sub_items.${subMenuItem.name}`)}
                            icon={subMenuItem.topbarIcon ? subMenuItem.topbarIcon : IconFileGeneral}
                            onClick={() => handleOpenNewCompany(page)}
                            // action={handleOpenNewCompany}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default TopbarMenu;
