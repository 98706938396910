import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import 'swiper/css';
import 'swiper/css/navigation';
import { ImagePlaceholder } from '../../../../assets/images';
import {
  Button,
  CustomizedText,
  Image,
  InnerPage,
  SectionTitle,
  Typography,
} from '../../../../components';
import { useLoadingStatusContext } from '../../../../context/loading/LoadingContext';
import { IconArrow, IconEdit, IconLoading, IconTrash } from '../../../../icons';
import { getContractsFromFunctionalities } from '../../../../imports/contractsParsers';
import type { NewsForm } from '../../../../imports/types';
import { updateContracts } from '../../../../redux/contracts/contracts.slice';
import { removeNews } from '../../../../redux/functionalities/functionalities.slice';
import { useAppSelector } from '../../../../redux/hooks';
import { deleteDocument, updateNft } from '../../api';
import { Nft } from '../../imports/types';

const NewsDetails = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id: newsId } = useParams();

  const { allNews, contractsList, workspace } = useAppSelector((state) => ({
    allNews: state.functionalities.news,
    contractsList: state.contracts.list,
    workspace: state.team.workspace,
  }));
  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const { open: openDeleteModal } = useModal('DeleteModal');
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState<NewsForm | null>(null);

  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();

  const handleDeleteNews = async () => {
    if (workspace) {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      if (news) {
        /* delete the functionality from the nfts */

        // Get contracts affected by the delete action
        const contracts = getContractsFromFunctionalities(news.availableOn, contractsList);

        const updatedContracts = await Promise.all(
          contracts.map(async (contract) => {
            return {
              ...contract,
              nfts: await Promise.all(
                contract.nfts.map((nft: Nft) => {
                  return updateNft(
                    {
                      ...nft,
                      attributes: nft.attributes?.filter(({ id }) => !(id === news.id)),
                      updatedAt: Date.now(),
                    },
                    contract.id
                  );
                })
              ),
            };
          })
        );

        if (updatedContracts.length) {
          dispatch(updateContracts(updatedContracts));
        }

        /* delete the functionality from the functionalities DB*/
        const { error } = await deleteDocument(news.id, 'news');

        /** Check if there are errors in promises */
        let isError = false;
        if (error) {
          console.error(error);
          isError = true;
        }

        if (isError) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        } else {
          dispatch(removeNews(news.id));
          navigate(-1);
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('collection.functionality.actions.delete'),
            },
          });
        }
      }
    }
  };

  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: news });
  };
  const handleDelete = () => {
    openDeleteModal({ handleDelete: handleDeleteNews });
  };

  useEffect(() => {
    setIsLoading(true);
    const showNews = allNews.find((article: { id: string }) => article.id === newsId);
    if (showNews) setNews(showNews);
    setIsLoading(false);
  }, [allNews]);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!news ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('functionality.functionality_not_found')}
              </Typography>

              <Button action={() => navigate('/nft/functionality')} className="mt-10">
                {t('functionality.goBack')}
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-8 ">
                <div className="flex">
                  <IconArrow
                    onClick={() => navigate(-1)}
                    width={40}
                    height={40}
                    stroke="#642eff"
                    className="mb-3 cursor-pointer rounded-md"
                  />
                </div>
              </div>
              <section className="mt-4 flex flex-col gap-4">
                <Image
                  src={news?.img || ImagePlaceholder}
                  type="cover"
                  className="h-[360px] w-full rounded-lg shadow-xl"
                />

                <SectionTitle title={news.title}></SectionTitle>

                <div className="flex w-full justify-end gap-4">
                  <IconEdit
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    width={30}
                    height={30}
                    stroke="white"
                    onClick={() => handleOpenNewFunctionality()}
                  />
                  <IconTrash
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    width={30}
                    height={30}
                    stroke="white"
                    onClick={() => handleDelete()}
                  />
                </div>

                <ul className="mt-4 grid grid-cols-1 gap-x-8 gap-y-2">
                  <li>
                    <CustomizedText text={news.description} />
                  </li>
                </ul>
              </section>
            </>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default NewsDetails;
