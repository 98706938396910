import type { ReactNode } from 'react';

import { IconClose } from '../../icons';

import Button from '../button/Button';
import Typography from '../typography/Typography';

import './chip.css';

type ChipProps = {
  children: ReactNode;
  action?: () => void;
};
const Chip = ({ children, action }: ChipProps) => (
  <div className={`chip ${action ? 'with-btn' : ''} `}>
    <Typography size="body-regular-14" color="grey-600" className="mx-auto">
      {children}
    </Typography>

    {action && <Button type="ghost" icon={IconClose} action={action} />}
  </div>
);

export default Chip;
