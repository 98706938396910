import getUnixTime from 'date-fns/getUnixTime';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { db } from '../imports/firebase';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const createFolder = async (
  uid: string,
  name: string,
  type: string,
  workspaceId: string
) => {
  const timestamp = getUnixTime(Date.now());
  const folderId = `${name.replace(/\s/g, '')}${timestamp}`;

  try {
    await setDoc(doc(db, 'folders', folderId), {
      id: folderId,
      name,
      userId: uid,
      type,
      notarizationsId: [],
      createdAt: timestamp,
      workspace_id: workspaceId,
    });

    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

//addFolderToNotarization
export const addFolderToDocument = async (
  folderId: string,
  notarizationId: string,
  type: string
) => {
  //const docRef = doc(db, 'notarizations', notarizationId);
  const docRef = doc(db, type, notarizationId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(docRef, { ...data, folderId });
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

//addNotarizationToFolder
export const addDocumentToFolder = async (folderId: string, notarizationId: string) => {
  const docRef = doc(db, 'folders', folderId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      const notarizationsId = data?.notarizationsId;

      if (notarizationsId) {
        notarizationsId.push(notarizationId);
        await setDoc(docRef, { ...data, notarizationsId });
      }
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

//addNotarizationFolder
export const addDocumentFolder = async (folderId: string, notarizationId: string, type: string) => {
  await addFolderToDocument(folderId, notarizationId, type)
    .then(async () => {
      await addDocumentToFolder(folderId, notarizationId)
        .then(() => result({ value: true }))
        .catch((error) => result({ error }));
    })
    .catch((error) => result({ error }));
};

//removeNotarizationFromFolder
export const removeDocumentFromFolder = async (
  folderId: string,
  notarizationId: string,
  type: string
) => {
  const docRefFolder = doc(db, 'folders', folderId);
  const docSnapFolder = await getDoc(docRefFolder);

  const docRefNotarization = doc(db, type, notarizationId);
  const docSnapNotarization = await getDoc(docRefNotarization);

  try {
    if (docSnapFolder.exists()) {
      const data = docSnapFolder.data();
      const notarizationsId = data?.notarizationsId;

      if (notarizationsId) {
        const index = notarizationsId.indexOf(notarizationId);
        notarizationsId.splice(index, 1);

        await setDoc(docRefFolder, { ...data, notarizationsId });

        if (docSnapNotarization.exists()) {
          const data = docSnapNotarization.data();
          await setDoc(docRefNotarization, { ...data, folderId: null });
        }
      }
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

//replaceNotarizationFolder
export const replaceDocumentFolder = async (
  currentFolderId: string,
  newFolderId: string,
  notarizationId: string,
  type: string
) => {
  await removeDocumentFromFolder(currentFolderId, notarizationId, type)
    .then(async () => {
      await addFolderToDocument(newFolderId, notarizationId, type)
        .then(async () => {
          await addDocumentToFolder(newFolderId, notarizationId)
            .then(() => result({ value: true }))
            .catch((error) => result({ error }));
        })
        .catch((error) => result({ error }));
    })
    .catch((error) => result({ error }));
};
