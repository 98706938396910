const updateAbi = [
  {
    inputs: [
      {
        internalType: 'string',
        name: 'newUri',
        type: 'string',
      },
    ],
    name: 'updateUri',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default updateAbi;
