/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconResearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38764)">
      <path
        d="M87 142C121.794 142 150 112.451 150 76C150 39.5492 121.794 10 87 10C52.2061 10 24 39.5492 24 76C24 112.451 52.2061 142 87 142Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.551 148.55L168.001 185"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38764">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconResearch;
