/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M72 162H36C34.4087 162 32.8826 161.368 31.7574 160.243C30.6322 159.117 30 157.591 30 156V122.475C29.9973 121.696 30.1483 120.924 30.4443 120.203C30.7403 119.483 31.1755 118.827 31.725 118.275L121.725 28.2751C122.283 27.7082 122.949 27.258 123.683 26.9507C124.417 26.6434 125.204 26.4851 126 26.4851C126.796 26.4851 127.583 26.6434 128.317 26.9507C129.051 27.258 129.717 27.7082 130.275 28.2751L163.725 61.7251C164.292 62.2834 164.742 62.9488 165.049 63.6828C165.357 64.4167 165.515 65.2044 165.515 66.0001C165.515 66.7957 165.357 67.5834 165.049 68.3174C164.742 69.0513 164.292 69.7168 163.725 70.2751L72 162Z"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M162 162H72"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102 48L144 90"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconEdit;
