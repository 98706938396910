/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.5 82.5L96 114L127.5 82.5"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96 30V114"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M162 114V156C162 157.591 161.368 159.117 160.243 160.243C159.117 161.368 157.591 162 156 162H36C34.4087 162 32.8826 161.368 31.7574 160.243C30.6321 159.117 30 157.591 30 156V114"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconDownload;
