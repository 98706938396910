import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { PollForm } from '../../imports/types';
import {
  News,
  Poll,
  Product,
  RestrictedArea,
  SelectTemplate,
  Shop,
} from '../../modules/tokenCreator/components/functionalitiesTemplates';
import {
  FunctionalitiesNews,
  FunctionalitiesProduct,
  FunctionalitiesRestrictedArea,
  FunctionalitiesShop,
  FunctionalityType,
} from '../../modules/tokenCreator/imports/types';
import ModalLayout from '../layouts/ModalLayout';

type NewFunctionalityModalProps = {
  children: ReactNode;
};

type ModalBodyProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalBody = ({ setOpen }: ModalBodyProps) => {
  const { goTo, edit } = useModalProps('NewFunctionalityModal');
  const [template, setTemplate] = useState<FunctionalityType | null>(edit?.type || null);

  return (
    <ModalLayout
      classNameLayout="max-h-[95%] w-auto overflow-auto "
      onClose={() => {
        setOpen(false);
      }}
    >
      {template === 'restricted_area' ? (
        <RestrictedArea edit={edit as FunctionalitiesRestrictedArea} />
      ) : template === 'poll' ? (
        <Poll edit={edit as PollForm} />
      ) : template === 'shop' ? (
        <Shop edit={edit as FunctionalitiesShop} />
      ) : template === 'product' ? (
        <Product edit={edit as FunctionalitiesProduct} />
      ) : template === 'news' ? (
        <News edit={edit as FunctionalitiesNews} />
      ) : (
        <SelectTemplate edit={edit} setTemplate={setTemplate} setOpen={setOpen} goTo={goTo} />
      )}
    </ModalLayout>
  );
};

const NewFunctionalityModal = ({ children }: NewFunctionalityModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="NewFunctionalityModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default NewFunctionalityModal;
