import { ref, uploadBytes } from 'firebase/storage';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { sendModifyPushNotification } from '../../../../api/axios';
import { ImagePlaceholder } from '../../../../assets/images';
import {
  Button,
  CustomizedText,
  Field,
  FileUploader,
  Form,
  Image,
  Input,
  TextareaQuill,
  Typography,
} from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { useImagePreview } from '../../../../hooks/useImagePreview';
import { IconClose } from '../../../../icons';
import { firebaseConfig } from '../../../../imports/constants';
import {
  getAvailableContracts,
  getContractsFromFunctionalities,
  getWorkspaceCreatedContracts,
} from '../../../../imports/contractsParsers';
import { storage } from '../../../../imports/firebase';
import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import { AvailableOn, NewsForm } from '../../../../imports/types';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { updateNews } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getCollectionRef,
  setFunctionality,
  setNftData,
  updateAvailableOn,
  updateNft,
} from '../../api';
import { Contract, FunctionalitiesNews, Nft, NftAttribute } from '../../imports/types';
import ContractsManager from '../contractsManager/ContractsManager';
import { getContractsToRemove } from './query';

type NewsProps = {
  edit: FunctionalitiesNews | null;
};

const News = ({ edit }: NewsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const { workspace } = useAppSelector((state) => state.team);
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>(
    edit
      ? getContractsFromFunctionalities(edit.availableOn ? edit.availableOn : {}, contractsList)
      : []
  );
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const dispatch = useAppDispatch();
  const { uid } = useAppSelector((state) => state.user);

  // FORM FOR NEWS
  const formNews = {
    initialValuesNews: {
      img: [],
      title: edit?.title || '',
      description: edit?.description || '',
    },
    validationSchemaNews: yup.object({}),
  };
  const { initialValuesNews, validationSchemaNews } = formNews;

  const uploadFile = async (key: string, image: File) => {
    try {
      const imgRef = ref(storage, `images/${uid}/${key}`);
      await uploadBytes(imgRef, image);
      const url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2F${uid}%2F${key}?alt=media`;
      return url;
    } catch (error) {
      return '';
    }
  };

  const handleSubmitNews = async (fields: { title: string; description: string; img: File[] }) => {
    if (workspace) {
      const newFunctionalityRef = getCollectionRef('news', edit);

      const functionality: FunctionalitiesNews = {
        type: 'news',
        workspaceId: workspace?.id,
        availableOn: edit?.availableOn || {},
        id: newFunctionalityRef.id,
        createdAt: edit?.createdAt || Date.now(),
        updatedAt: Date.now(),
        title: fields.title,
        img: edit?.img || '', // va prima caricata
        description: fields.description,
      };

      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      if (
        getWorkspaceCreatedContracts(workspace.id, contractsList) &&
        selectedContracts?.length > 0
      ) {
        try {
          functionality.availableOn = getAvailableContracts(selectedContracts);

          if (fields.img[0]) {
            functionality.img = await uploadFile(functionality.id, fields.img[0]);
          }

          if (edit) {
            /**
             * Code to delete the functionality from NFTs
             */
            await Promise.allSettled(
              getContractsFromFunctionalities(
                getContractsToRemove(edit.availableOn, selectedContracts) || [],
                contractsList
              ).map((contract) => {
                return contract.nfts.map((nft: Nft) => {
                  /* update nft.attributes on redux contract */

                  return updateNft(
                    {
                      ...nft,
                      attributes: nft.attributes?.filter(({ id }) => !(id === functionality.id)),
                      updatedAt: Date.now(),
                    },
                    contract.id
                  );
                });
              })
            );
            const availableOn: AvailableOn = selectedContracts.reduce((acc, contract) => {
              acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
              return acc;
            }, {} as AvailableOn);
            updateAvailableOn(newFunctionalityRef, availableOn);
          }

          const data = await Promise.allSettled(
            selectedContracts
              .map(async (contract) => {
                /**
                 * Create a copy of contract
                 */
                const updatedContract = cloneDeep(contract);

                const modifiedNftsIds: number[] = [];
                const promises = contract.nfts.map(async (nft) => {
                  try {
                    /**
                     * if edit exists we are modifying the attribute, if not we are creating it
                     */
                    const newAttributes: NftAttribute[] = cloneDeep(
                      nft?.attributes
                    ) as NftAttribute[];

                    const attributeIndex = newAttributes?.findIndex(({ id }) => {
                      return id === functionality.id;
                    });

                    if (attributeIndex === -1) {
                      newAttributes.push({
                        trait_type: fields.title,
                        type: functionality.type,
                        value: fields.description,
                        id: functionality.id,
                        img: functionality.img,
                      });
                    } else {
                      newAttributes[attributeIndex] = {
                        ...newAttributes[attributeIndex],
                        trait_type: fields.title,
                        value: fields.description,
                        img: functionality.img,
                      };
                    }
                    const data = { attributes: newAttributes };

                    updatedContract.nfts = updatedContract.nfts.map((updatedNft: Nft) => {
                      if (nft.id === updatedNft.id) {
                        return { ...updatedNft, attributes: newAttributes };
                      }
                      return updatedNft;
                    });

                    modifiedNftsIds.push(nft.id);
                    const promise = await setNftData(contract.id, nft, data);

                    /**
                     * create newNfts array and adding it only once. Populated with
                     * cloned and eventuyally modified nfts
                     *  */

                    return promise;
                  } catch (error) {
                    console.log(error);
                    loadingStatusDispatch({
                      type: 'SET_ERROR',
                      payload: {
                        title: t('collection.functionality.actions.failed'),
                      },
                    });
                    return error;
                  }
                });
                await sendModifyPushNotification(uid, contract.id, modifiedNftsIds);
                dispatch(updateContract(updatedContract));

                return promises;
              })
              .flat()
          );

          const { value, error } = await setFunctionality(newFunctionalityRef, functionality, true);
          let isError = false;

          data.forEach((promise: any) => {
            if (promise.status === 'fulfilled' && promise.value.error) {
              isError = true;
            }
          });
          if (isError || error) {
            loadingStatusDispatch({
              type: 'SET_ERROR',
              payload: {
                title: t('collection.functionality.actions.failed'),
              },
            });
          }
          if (value) {
            dispatch(updateNews(functionality as unknown as NewsForm));
            loadingStatusDispatch({
              type: 'SET_SUCCESS',
              payload: {
                title: edit
                  ? t('collection.functionality.actions.updated')
                  : t('collection.functionality.actions.success'),
              },
            });
          }
        } catch (error) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        }
      } else {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('collection.functionality.actions.failed'),
          },
        });
      }
    }
  };

  return (
    <div>
      <Typography color="primary-500" size="body-medium-30" className="pl-8 pt-8">
        {t('collection.functionality.news')}
      </Typography>
      <Form
        initialValues={initialValuesNews}
        validationSchema={validationSchemaNews}
        className="flex size-full flex-col items-center gap-4 p-6"
      >
        {({ fields, handleSubmit, errors, resetField, watch, setValue, isValid, register }) => {
          const values = watch();

          const { imagePreview, updateImagePreview } = useImagePreview(ImagePlaceholder);

          useEffect(() => {
            updateImagePreview(values.img);
          }, [values.img, updateImagePreview]);

          return (
            <>
              <div className="flex min-w-[250px] gap-4">
                <div className="flex w-1/2 flex-col content-center gap-4">
                  <Image
                    src={imagePreview}
                    className="h-[211px] w-[376px] rounded-lg object-scale-down"
                  />
                  <Typography weight="semibold">{values.title}</Typography>
                  <CustomizedText text={values.description} />
                </div>
                <div className="flex h-full w-1/2 flex-col content-center gap-4">
                  <Field
                    label={t('create_contract.form_labels.nft_image')}
                    error={errors.img?.message}
                  >
                    <FileUploader
                      label={t('create_contract.form_placeholders.nft_image')}
                      accept={allowedFileType}
                      maxSize={5000000}
                      onChange={(files) => {
                        if (files?.length) {
                          setValue('img', files, {
                            shouldValidate: true,
                          });
                        } else {
                          resetField('img');
                        }
                      }}
                      onCancel={() => setValue('img', [])}
                      ratio="16:9"
                    />
                  </Field>

                  <Field
                    label={t('collection.functionality.news_title')}
                    error={errors.title?.message}
                  >
                    <Input
                      id={fields.title.name}
                      type="text"
                      placeholder={t('collection.functionality.placeholders_title')}
                      name={fields.title.name}
                      onBlur={fields.title.onBlur}
                      onChange={fields.title.onChange}
                      inputRef={fields.title.ref}
                      error={errors.title?.message}
                      elementRight={
                        values.title && (
                          <Button
                            type="ghost"
                            icon={IconClose}
                            action={() => resetField(fields.title.name)}
                          />
                        )
                      }
                    />
                  </Field>
                  <Field
                    label={t('collection.functionality.description')}
                    error={errors.description?.message}
                    className=" h-[300px]"
                  >
                    <TextareaQuill
                      setValue={setValue}
                      field="description"
                      value={values.description}
                    />
                  </Field>
                </div>
              </div>

              <Field error={errors.options?.message}>
                <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
                  {t('collection.functionality.select_contract')}
                </Typography>
                <div className="!max-h-[350px] overflow-auto">
                  <ContractsManager
                    availableContracts={edit?.availableOn ? edit.availableOn : {}}
                    setContracts={setSelectedContracts}
                  />
                </div>
              </Field>

              <div className="mt-4 flex w-full items-end justify-end">
                <Button
                  id="create-functionality"
                  type="primary"
                  disabled={!values.title || !values.description || !selectedContracts?.length}
                  action={handleSubmit(() => handleSubmitNews(values))}
                  className="px-6 "
                >
                  {t('collection.functionality.submit')}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default News;
