import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
/* root imports */
import { Breadcrumbs, Button, Icon, InnerPage, Typography } from '../../../../components';
import { IconArrow, IconLoading } from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';
/* tokenCreator imports */
import 'swiper/css';
import 'swiper/css/navigation';
import useDateRange from '../../../../hooks/useDateRange';
import { getNftAnalytics } from '../../../../imports/analyticsParser';
import { getContractFromContractId } from '../../../../imports/contractsParsers';
import ClickAnalyticsComponent from '../../components/ClickAnalyticsComponent/ClickAnalyticsComponent';
import type { Contract } from '../../imports/types';

const NftAnalytics = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const { contractId, nftId } = useParams();
  const { list: contractList } = useAppSelector((state) => state.contracts);
  const { loading } = useAppSelector((state) => state.contracts.api.fetch);
  const [contract, setContract] = useState<Contract | undefined>(undefined);
  const [analytics, setAnalytics] = useState<any>();
  const { dateRange, handleDateRange, setDateRange, initialDateRange } = useDateRange();

  const { date_to, date_from } = dateRange;

  const nft = contract?.nfts.find((nft) => nft.id === Number(nftId));

  useEffect(() => {
    if (contractId && nft) {
      const activities = getContractFromContractId(contractId, contractList)?.activities;
      setAnalytics(getNftAnalytics(activities || [], nft.id));
    }
  }, [contractId, nft, contractList]);

  useEffect(() => {
    if (contractId) {
      const fullContract = getContractFromContractId(contractId, contractList);
      setContract(fullContract);
    }
  }, []);

  const shouldFetch = (): boolean => {
    if (!nft) {
      return false;
    }
    if (contract?.nfts.length === 1 && contract?.nfts[0].quantity === 1) {
      return false;
    }
    if (nft?.quantity >= 2 || nft.isUnlimited) {
      return true;
    }
    return false;
  };

  const shouldFetchValue = shouldFetch();

  return (
    <InnerPage>
      {loading ? (
        <div className="flex h-screen grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!nft ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('collection_item.nft_not_found_title')}
              </Typography>

              <Button action={() => navigate(`/nft/analytics/${contractId}`)} className="mt-10">
                {t('collection_item.nft_not_found_action')}
              </Button>
            </div>
          ) : (
            <div className="flex flex-col gap-8 ">
              <div className="flex">
                <Icon
                  icon={IconArrow}
                  onClick={() => navigate(-1)}
                  size="40"
                  stroke="primary-500"
                  className="mb-3 cursor-pointer rounded-md"
                />
              </div>
              <div className="flex flex-col " id="header">
                <Breadcrumbs />
                <div className="flex flex-col gap-2">
                  <Typography size="body-medium-30">{nft.name}</Typography>

                  {contract?.owner && (
                    <Typography size="body-regular-16">
                      Address: <span className="text-primary-500">{contract.owner}</span>
                    </Typography>
                  )}
                </div>
              </div>

              <ClickAnalyticsComponent
                shouldFetch={shouldFetchValue}
                type="nft"
                contractId={contractId}
                nftId={nftId}
              />
            </div>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default NftAnalytics;
