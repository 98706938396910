import React, { ReactNode, useState } from 'react';
import { Button } from '..';

interface ShowMoreProps<T> {
  data: T[];
  localMax: number;
  children: (args: T, index: number) => ReactNode;
  buttonLabel: string;
}

const ShowMore = <T extends unknown>(props: ShowMoreProps<T>) => {
  const { localMax, data, children, buttonLabel } = props;
  const [currentMax, setCurrentMax] = useState(localMax);

  return (
    <div className="flex flex-col">
      {data.slice(0, currentMax).map((obj: T, i: number) => (
        <React.Fragment key={i}>{children(obj, i)}</React.Fragment>
      ))}
      {currentMax < data.length && (
        <div className="w-full items-center justify-center">
          <Button
            type="secondary"
            className="mx-auto mt-[32px] w-min shadow-lg"
            action={() => setCurrentMax(currentMax + localMax)}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShowMore;
