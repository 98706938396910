import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uid as uniqueId } from 'uid';
import { Button, Field, Icon, Select, Textarea, Tooltip, Typography } from '../../../../components';
import { IconArrowDown, IconPlus, IconTrash } from '../../../../icons';

interface AnswerFieldsProps {
  questionId: string;
  values: any;
  control: any;
  errors: any;
  resetField: any;
  register: any;
  setValue: any;
}

const AnswerFields: React.FC<AnswerFieldsProps> = ({
  errors,
  resetField,
  control,
  values,
  register,
  questionId,
  setValue,
}) => {
  const { t } = useTranslation(['tokenCreator']);
  const { questions } = values;
  const questionIndex = questions.findIndex(
    (question: { qId: string }) => question.qId === questionId
  );

  const [answerVisibility, setAnswerVisibility] = useState<Array<number>>([]);
  const handleAnswerVisibility = (aid: any) => {
    const found = answerVisibility.findIndex((element) => String(element) === String(aid));
    const newVisibility = [...answerVisibility];
    if (found < 0) {
      newVisibility.push(aid);
      setAnswerVisibility(newVisibility);
    } else {
      newVisibility.splice(found, 1);
      setAnswerVisibility(newVisibility);
    }
  };

  const { append, remove, fields } = useFieldArray({
    control,
    name: `questions[${questionIndex}].answers`,
  });
  const nextIdOptionsBase = [
    {
      value: '',
      label: t('polls.nextQuestion_placeholder'),
      isHidden: true,
    },
    {
      value: 'end',
      label: t('polls.nothing'),
    },
  ];
  const [nextIdOptions, setNextIdOptions] = useState(nextIdOptionsBase);
  useEffect(() => {
    const newOptions = nextIdOptionsBase;
    for (let i = 0; i < questions.length; i += 1) {
      if (questions[i].qId !== questionId) {
        newOptions.push({
          //value: i.toString(),
          value: questions[i].qId,
          label: t('polls.question', { number: i + 1 }),
        });
      }
    }
    setNextIdOptions(newOptions);
  }, [values.questions.length]);

  return (
    <div className="mt-8 space-y-12 p-2">
      <div className="grid grid-cols-1 gap-x-8 gap-y-2">
        {fields.map(({ id }, index) => {
          // const hasOtherType = register(
          //   `questions[${questionIndex}].answers[${index}].hasOtherType`
          // );
          //const type = register(`questions[${questionIndex}].answers[${index}].type`);
          const answer = register(`questions[${questionIndex}].answers[${index}].answer`);
          const nextQuestion = register(
            `questions[${questionIndex}].answers[${index}].nextQuestion`
          );
          return (
            <div
              key={index}
              className="grid grid-cols-2 gap-x-8 gap-y-2 rounded-lg border-2 border-grey-200 p-2"
            >
              <div className="col-span-2">
                <div className="flex flex-row justify-between">
                  <Button
                    type="ghost"
                    action={() =>
                      handleAnswerVisibility(values.questions[questionIndex].answers[index].aId)
                    }
                  >
                    <div className="flex flex-row">
                      {answerVisibility.findIndex(
                        (element) => element === values.questions[questionIndex].answers[index].aId
                      ) >= 0 ? (
                        <Icon icon={IconArrowDown} className="!h-6 !w-6" color="primary-400" />
                      ) : (
                        <Icon
                          icon={IconArrowDown}
                          className="!h-6 !w-6 rotate-180"
                          color="primary-400"
                        />
                      )}
                      <Typography>
                        {t('polls.answer', {
                          aId: String.fromCharCode(index + 65),
                        })}
                      </Typography>
                    </div>
                  </Button>
                  {values.questions[questionIndex].type !== 'boolean' && (
                    <Tooltip content={t('polls.removeAnswer')}>
                      <Button
                        type="ghost"
                        action={() => remove(index)}
                        disabled={values.questions[questionIndex].answers.length === 1}
                      >
                        <IconTrash className="!h-4 !w-4" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div
                className={`${
                  answerVisibility.findIndex(
                    (element) => element === values.questions[questionIndex].answers[index].aId
                  ) >= 0
                    ? ' collapse h-0'
                    : ''
                } col-span-2`}
              >
                <div className="grid grid-cols-2 gap-x-8 gap-y-2 ">
                  <Field
                    className="col-span-2"
                    error={errors.questions?.[questionIndex]?.answers?.[index]?.answer?.message}
                  >
                    {questions && (
                      <Textarea
                        type="text"
                        rows={3}
                        placeholder={
                          //questions[questionIndex].answers[index].answer ||
                          t('polls.answer_placeholder')
                        }
                        name={answer.name}
                        onBlur={answer.onBlur}
                        onChange={answer.onChange}
                        inputRef={answer.ref}
                        error={errors.questions?.[questionIndex]?.answers?.[index]?.answer?.message}
                        disabled={values.questions[questionIndex].type === 'boolean'}
                        value={questions[questionIndex].answers?.[index].answer}
                      />
                    )}
                  </Field>
                  <Field
                    label={t('polls.nextQuestion')}
                    error={
                      errors.questions?.[questionIndex]?.answers?.[index]?.nextQuestion?.message
                    }
                  >
                    <Select
                      options={nextIdOptions}
                      id={nextQuestion.name}
                      placeholder={t('polls.nextQuestion')}
                      name={nextQuestion.name}
                      onBlur={nextQuestion.onBlur}
                      value={
                        questions[questionIndex]?.answers[index]?.nextQuestion
                          ? questions[questionIndex].answers[index].nextQuestion
                          : ''
                      }
                      onChange={nextQuestion.onChange}
                      inputRef={nextQuestion.ref}
                      error={
                        errors.questions?.[questionIndex]?.answers?.[index]?.nextQuestion?.message
                      }
                    />
                  </Field>
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex flex-row justify-around">
          {values.questions[questionIndex].type === 'check' && (
            <Tooltip content={t('polls.addAnswer')}>
              <Button
                type="ghost"
                action={() => {
                  append({
                    aId: uniqueId(4),
                    answer: '',
                    nextQuestion: '',
                  });
                }}
                icon={IconPlus}
                iconSize="lg"
                iconColor="grey-50"
                className="mx-auto"
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnswerFields;
