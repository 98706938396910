import type { ReactNode } from 'react';

import { t } from 'i18next';
import { IconAsterisk, IconInfo } from '../../icons';
import Icon from '../icon/Icon';
import Tooltip from '../tooltips/Tooltip';
import Typography from '../typography/Typography';

type FieldProps = {
  children: ReactNode;
  label?: string;
  labelElementRight?: ReactNode;
  error?: string;
  className?: string;
  childDivClassName?: string;
  asterisk?: boolean;
  asteriskText?: string;
  info?: boolean;
  infoText?: string;
  textSize?: any;
};

const Field = ({
  children,
  label,
  labelElementRight,
  error,
  className,
  childDivClassName,
  asterisk,
  asteriskText,
  info,
  infoText,
  textSize,
}: FieldProps) => (
  <div className={`flex w-full flex-col ${className}`}>
    {label && (
      <div className="flex items-center ">
        <Typography color="grey-600" size={textSize}>
          {label}
        </Typography>
        <div className="ml-2 flex gap-1 ">
          {asterisk && (
            <Tooltip
              content={asteriskText || (t('form_validation.required') as string)}
              maxWidth={300}
            >
              <div className="cursor-help p-0.5">
                <Icon icon={IconAsterisk} size="16" stroke="primary-500" />
              </div>
            </Tooltip>
          )}
          {info && (
            <Tooltip content={infoText} maxWidth={300}>
              <div className="cursor-help p-0.5">
                <Icon icon={IconInfo} size="16" stroke="primary-500" />
              </div>
            </Tooltip>
          )}
        </div>
        {labelElementRight}
        {/* {asterisc && <icona></icona>}
        {info && }
        {tootltip &&} */}
      </div>
    )}

    <div className={childDivClassName}>{children}</div>

    <div className={childDivClassName}>
      {error && (
        <Typography size="body-regular-12" color="error">
          {error || ''}
        </Typography>
      )}
    </div>
  </div>
);

export default Field;
