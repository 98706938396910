import { QuerySnapshot, collection, getDocs } from 'firebase/firestore';
import { t } from 'i18next';
import { db } from '../../../../imports/firebase';
import { Workspace } from '../../../../imports/types';
import { isOnlyReader, isVerifiedOnChain } from '../../api/api';
import { orderTeamMembers } from '../../common/utils';
import { WorkspaceMember } from '../../imports/types';

export async function selectMember({
  uid,
  searchField,
  workspace,
}: {
  uid: string;
  searchField: string;
  workspace: Workspace | undefined;
}) {
  try {
    const snapshot = await getDocs(collection(db, 'workspaces', workspace?.id!, 'members'));

    let members = orderTeamMembers(
      snapshot?.docs?.map((doc: any) => doc.data() as WorkspaceMember) ?? [],
      searchField
    );

    if (!workspace?.isOwner) {
      members = members?.filter((member) => member.user !== workspace?.owner);
    }
    const res = (
      await Promise.all(
        members?.map(async (member) => {
          if (member.user === uid) {
            return { ...member, name: t('you'), verifiedOnChain: true };
          }

          const verifiedOnChain =
            (await isVerifiedOnChain(member.address, workspace?.id || '')) || isOnlyReader(member);
          return { ...member, verifiedOnChain };
        })
      )
    ).sort((member1, member2) => member1.created_at - member2.created_at);
    return {
      members: res.filter((member) => member.verifiedOnChain),
      unverified: res.filter((member) => !member.verifiedOnChain),
    };
  } catch (e) {
    console.error(e);
    return { members: [], unverified: [] };
  }
}
export async function processMembers({
  snapshot,
  uid,
  searchField,
  workspace,
}: {
  snapshot: QuerySnapshot;
  uid: string;
  searchField: string;
  workspace: Workspace | undefined;
}) {
  try {
    let members = orderTeamMembers(
      snapshot?.docs?.map((doc: any) => doc.data() as WorkspaceMember) ?? [],
      searchField
    );
    if (!workspace?.isOwner) {
      members = members?.filter((member) => member.user !== workspace?.owner);
    }
    const res = (
      await Promise.all(
        members?.map(async (member) => {
          if (member.user === uid) {
            return { ...member, name: t('you'), verifiedOnChain: true };
          }

          const verifiedOnChain =
            (await isVerifiedOnChain(member.address, workspace?.id || '')) || isOnlyReader(member);
          return { ...member, verifiedOnChain };
        })
      )
    ).sort((member1, member2) => member1.created_at - member2.created_at)

    
    return {
      groupMembers: res.filter((member) => member.inGroup),
      members: res.filter((member) => !member.inGroup),
    };
  } catch (e) {
    console.error(e);
    return { members: [], unverified: [], groupMembers: [] };
  }
}
