import { CHAIN_MODULES } from '../../../imports/constants';
import { RequestedPermissions } from '../../../imports/types';
import { Permissions, WorkspaceInvitation, WorkspaceMember } from '../imports/types';

export const computeRoles = (role: { [module: string]: { write: boolean; read: boolean } }) => {
  const roles: any = {};
  Object.keys(role).forEach((roleName) => {
    roles[roleName as keyof Permissions] = {
      read: role[roleName].read,
      write: role[roleName].write,
      update: role[roleName].write,
      delete: role[roleName].write,
    };
  });
  return roles;
};

export const orderTeamMembers = (
  members: WorkspaceMember[],
  filterName: string
): WorkspaceMember[] => {
  let membersClone = [...members];
  if (filterName !== '' && !!membersClone?.length) {
    membersClone = membersClone?.filter((member) =>
      member.name?.toLowerCase().includes(filterName.toLowerCase())
    );
  }
  membersClone?.sort((a, b) => b.created_at - a.created_at);
  return membersClone;
};

export const orderTeamInvitations = (
  invitations: WorkspaceInvitation[],
  filterName: string
): Array<WorkspaceMember & { pending: true }> => {
  const invitationsClone = [...invitations];
  let invited = invitationsClone.map((invite) => {
    const { permissions, email, name, inGroup, groupId } = invite;
    return { created_at: invite.created_at, email, permissions, name, inGroup, groupId };
  }) as WorkspaceMember[];

  invited = invited.map((invite) => ({
    ...invite,
    pending: true,
  }));

  if (filterName !== '' && !!invited?.length) {
    invited = invited?.filter((invited) =>
      invited.name.toLowerCase().includes(filterName.toLowerCase())
    );
  }

  invited?.sort((a, b) => b.created_at - a.created_at);
  return invited as Array<WorkspaceMember & { pending: true }>;
};

export const verifyPermission = (
  userPermissions: Permissions,
  requiresPermission: RequestedPermissions
) => {
  const permissions = userPermissions[requiresPermission.moduleName];
  const requested = requiresPermission.permissions;

  for (const request of requested) {
    if (!permissions[request]) return false;
  }

  return true;
};

export const hasAtLeastAPermission = (
  userPermissions: Permissions,
  requiresPermission: RequestedPermissions
) => {
  const permissions = userPermissions[requiresPermission.moduleName];
  const requested = requiresPermission.permissions;

  for (const request of requested) {
    if (permissions[request]) return true;
  }

  return false;
};

export const checkWritePermissionChanged = (prev: Permissions, current: Permissions) => {
  return CHAIN_MODULES.reduce((acc, key) => {
    return prev[key.toLowerCase()].write === current[key.toLowerCase()].write ? acc : true;
  }, false);
};

export const checkPermissionChanged = (prev: Permissions, current: Permissions) => {
  return CHAIN_MODULES.reduce((acc, key) => {
    return prev[key.toLowerCase()].write === current[key.toLowerCase()].write &&
      prev[key.toLowerCase()].read === current[key.toLowerCase()].read
      ? acc
      : true;
  }, false);
};
