import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Button } from '..';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type DeleteModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { handleDelete } = useModalProps('DeleteModal');
  const { t } = useTranslation();

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col gap-6 px-[25px] pb-6 pt-[50px]">
        <div>
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-4">
            {t('modals.delete_modal.title')}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('modals.delete_modal.subtitle')}
          </Typography>
        </div>
        <div className="mt-[20px] flex flex-row justify-between">
          <Button type="secondary" action={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button action={handleDelete}>{t('delete')}</Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DeleteModal = ({ children }: DeleteModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DeleteModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DeleteModal;
