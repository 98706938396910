import { IconArrowDown } from '../../icons';

import type { InputType } from '../../imports/types';

import Icon from '../icon/Icon';

import './select.css';

type SelectProps = InputType & {
  options: {
    value: string | any;
    label: string;
    disabled?: boolean;
    bold?: boolean;
    isHidden?: boolean;
  }[];
};
const Select = ({
  id,
  name,
  value,
  disabled,
  error,
  success,
  onBlur,
  onChange,
  inputRef,
  defaultValue,
  options,
}: SelectProps) => {
  const getSelectStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  return (
    <div className="select-container">
      <select
        className={`select ${getSelectStatus()}`}
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        ref={inputRef}
      >
        {options.map((option, i) => {
          return (
            <option
              key={i}
              value={option.value}
              hidden={!!option.isHidden}
              // hidden={isPlaceholder}
              className={option.bold ? 'font-semibold' : ''}
              // selected={option.value === defaultValue}
              // defaultValue={defaultValue}
            >
              {option.label}
            </option>
          );
        })}
      </select>

      <div className="icon-container">
        <Icon icon={IconArrowDown} size="md" />
      </div>
    </div>
  );
};

export default Select;
