import { IconFileAudit } from '../../../icons';
import type { Routes } from '../../../imports/types';

import { Collection, CreateAudit } from '../pages';

const routes: Routes[] = [
  {
    name: 'audit_list',
    path: '/',
    component: Collection,
    topbarIcon: IconFileAudit,
    // hasSidebar: true,
    isHome: true,
    requiresPermission: {
      moduleName: 'audit',
      permissions: ['read'],
    },
  },
  {
    name: 'new_audit',
    path: '/create-audit',
    component: CreateAudit,
    topbarIcon: IconFileAudit,
    // hasSidebar: true,
    requiresPermission: {
      moduleName: 'audit',
      permissions: ['write'],
    },
  },
];

export default routes;
