import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Folder } from '../../imports/types';
import Checkbox from '../checkbox/Checkbox';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type SelectFolderModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { folders, category, index, setValue, values } = useModalProps('SelectFolderModal');
  const { t } = useTranslation(['tokenCreator']);
  const [selected, setSelected] = useState<Folder>();

  const handleChangeSelection = (folder: Folder) => {
    if (selected && selected?.id === folder.id) {
      setSelected(undefined);
      setValue(`nfts[${category}].attributes[${index}].value`, '');
    } else {
      setSelected(folder);
      setValue(`nfts[${category}].attributes[${index}].value`, folder.id);
    }
  };

  useEffect(() => {
    if (values.nfts?.[category]?.attributes?.[index]?.value === '') {
      setValue(`nfts[${category}].attributes[${index}].value`, '');
    } else {
      setSelected(
        folders.find(
          (folder: Folder) => folder.id === values.nfts?.[category]?.attributes?.[index]?.value
        )
      );
    }
  }, []);

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="max-h-[85%] min-h-[300px] overflow-hidden"
      width="485px"
    >
      <Typography className="mt-[40px] mb-4 ml-2" size="body-medium-30" color="primary-500">
        {t('create_contract.form_labels.select_notarizations_folder')}
      </Typography>
      {folders?.length > 0 && (
        <div className="flex max-h-[500px] flex-col overflow-auto">
          {folders.map((folder: Folder) => {
            return (
              <div
                key={folder.id}
                className="hover:bg-indaco2 flex h-[36px] items-center gap-2 px-3 py-1"
              >
                <Checkbox
                  onChange={() => handleChangeSelection(folder)}
                  isChecked={values.nfts?.[category]?.attributes?.[index]?.value === folder.id}
                />
                <Typography size="body-regular-14">{folder.name}</Typography>
              </div>
            );
          })}
        </div>
      )}
      {folders?.length === 0 && (
        <Typography className="ml-2">{t('create_contract.no_folders')}</Typography>
      )}
    </ModalLayout>
  );
};

const SelectFolderModal = ({ children }: SelectFolderModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="SelectFolderModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default SelectFolderModal;
