import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Icon, Input, Select, Typography } from '../../../../components';
import useAnalyticsQuery from '../../../../hooks/useAnalyticsQuery';
import useDateRange from '../../../../hooks/useDateRange';
import { IconAnalytics, IconResearch } from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';

type AnalyticsEvent = {
  event_nft: string;
  event_company: string;
  event_data: {};
  event_timestamp: string;
};
type AnalyticsDataType = {
  analytics: {
    event_type: string;
    events_array: { event_name: string; event_subtype: string; events: AnalyticsEvent[] }[];
    total_count: number;
  };
};

type analyticsProps = {
  contractId?: string;
  nftId?: string;
  type: 'nft' | 'company' | 'contract';
  shouldFetch: boolean;
  to?: string;
};

const ClickAnalyticsComponent = ({ contractId, nftId, type, shouldFetch, to }: analyticsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { address: companyId } = useAppSelector((state) => state.user.wallet);
  const navigate = useNavigate();

  const {
    dateRange,
    setDateRange,
    handleDateRange,
    initialDateRange,
    handleSelectChange,
    selectedValue,
    selectOptions,
  } = useDateRange();

  const { date_to, date_from } = dateRange;

  const {
    data: analytics,
    error,
    isLoading,
    refetch,
  } = useAnalyticsQuery({
    companyId,
    contractId,
    nftId,
    shouldFetch,
    type,
    dateRange,
  });

  const analyticsField = (name: string, value: any) => {
    return (
      <div className="min-w-[170px]">
        <div className="flex flex-auto items-baseline gap-1">
          <Typography
            weight="semibold"
            color="primary-500"
            size="h3"
            className={`${analytics === undefined ? 'mx-auto' : ''} `}
          >
            {value}
          </Typography>
          <Typography
            size="body-regular-12"
            className={`${analytics === undefined ? 'opacity-30' : ''} mx-4 text-grey-500`}
          >
            {name}
          </Typography>
        </div>
      </div>
    );
  };

  const handleClick = () => {
    //validate if date_from or date_to are not valid dates, if not revert to initialDateRange
    if (Number.isNaN(new Date(date_from).getTime()) || Number.isNaN(new Date(date_to).getTime())) {
      setDateRange(initialDateRange);
      toast.warning(t(`analytics.invalid_date_range`) as string);
    }
    refetch();
  };

  if (error) {
    toast.error(t(`analytics.fetch_error`) as string);
  }

  const DateInputs = (
    <>
      <div className="flex flex-row items-center justify-start gap-4">
        <Input
          elementLeft={
            <Typography color="grey-300" size="body-regular-10">
              {t('analytics.from')}
            </Typography>
          }
          type="date"
          onChange={(e) => handleDateRange(e)}
          value={date_from}
          maxValue={date_to}
          name="date_from"
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
        />
        <Input
          elementLeft={
            <Typography color="grey-300" size="body-regular-10">
              {t('analytics.to')}
            </Typography>
          }
          type="date"
          minValue={date_from}
          onChange={(e) => handleDateRange(e)}
          value={date_to}
          name="date_to"
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
        />
        <Select
          value={selectedValue}
          name="date_range_select"
          onChange={(e) => handleSelectChange(e)}
          options={selectOptions}
        ></Select>
        <Button type="ghost" icon={IconResearch} iconSize="md" action={handleClick}></Button>
      </div>
    </>
  );

  const renderAnalytics = (
    events_array: { event_name: string; event_subtype: string; events: AnalyticsEvent[] }[]
  ) => {
    const uniqueSubtypes = Array.from(new Set(events_array.map((item) => item.event_subtype)));
    return (
      <div>
        <div className="mt-4 md:flex md:flex-row md:flex-wrap md:gap-4">
          {uniqueSubtypes.map((subtype, index) => (
            <div key={index} className="rounded-sm border-2 border-grey-100 md:p-4">
              <Typography size="body-regular-16" weight="medium" className="mb-2 text-grey-600">
                {subtype ? t(`analytics.event_subtype.${subtype}`) : ''}
              </Typography>
              {events_array
                .filter((item) => item.event_subtype === subtype)
                .map((item, subIndex) => (
                  <div key={subIndex} className="pt-2">
                    {analyticsField(
                      item.event_name ? item.event_name : '',
                      analytics === undefined ? 'loading...' : item.events.length || 0
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <>
        {DateInputs}
        <Typography className="mt-7" size="lg" weight="medium">
          {t('analytics.loading')}
        </Typography>
      </>
    );
  }

  if (!analytics || analytics.length === 0)
    return (
      <>
        {DateInputs}
        <Typography className="mt-7" size="lg" weight="medium">
          {t('analytics.no_data')}
        </Typography>
      </>
    );

  return (
    <>
      {DateInputs}
      <div className="flex w-full flex-col">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {!!analytics?.length &&
            analytics?.map((item: AnalyticsDataType['analytics']) => {
              return (
                <div key={item.event_type} className="mt-7 flex flex-col" id="analytics">
                  <div className="flex justify-between">
                    <div>
                      <div className="flex flex-row items-center gap-8">
                        <Typography size="lg" weight="medium">
                          {t(`analytics.event_type.${item.event_type}`)}
                        </Typography>
                        {to && (
                          <Icon
                            icon={IconAnalytics}
                            onClick={() => navigate(to)}
                            size="20"
                            stroke="primary-500"
                            className="cursor-pointer rounded-md "
                          />
                        )}
                      </div>
                      {analytics && renderAnalytics(item.events_array)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Typography className="mt-2" size="body-regular-10">
          {t('analytics.missing_category')}
        </Typography>
      </div>
    </>
  );
};

export default ClickAnalyticsComponent;
