import { useEffect, useState } from 'react';
import './image.css';
import { useTranslation } from 'react-i18next';

type ImageProps = {
  src?: string;
  alt?: string;
  type?: 'contain' | 'cover';
  className?: string;
};
const Image = ({ src, alt, type = 'contain', className = '' }: ImageProps) => {
  const { t } = useTranslation();
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    if (!isAvailable) {
      setInterval(() => setIsAvailable(true), 20000);
    }
  }, [isAvailable]);

  return isAvailable ? (
    <img
      alt={alt ?? t('loading_image')}
      src={src}
      width={258}
      height={145}
      className={`image ${type} ${className}`}
      onError={() => setIsAvailable(false)}
    />
  ) : (
    <div
      className={`image ${type} ${className} flex animate-pulse items-center justify-center rounded-[10px] bg-grey-500 object-fill`}
    >
      {t('loading_image')}
    </div>
  );
};

export default Image;
