import getUnixTime from 'date-fns/getUnixTime';
import { collection, doc, query, setDoc, where } from 'firebase/firestore';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
/* root imports */
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { getSubscription } from '../../../../api/firebase';
import useContractSchema from '../../../../hooks/schemas/useContractSchema';
import { useAppSelector } from '../../../../redux/hooks';

import { delayed, formatImage } from '../../../../imports/utils';

import { IconLoading } from '../../../../icons';

import type { Location } from '../../../../imports/types';

import { Form, InnerPage, Sidebar } from '../../../../components';

/* token creator imports */
import { getDraftContracts, getLeftContracts, saveContract } from '../../api';
import { uploadContractInfoIpfs, uploadNftsIpfs } from '../../api/ipfs';

import { EMPTY_CONTRACT_ID, EMPTY_NFT, maxDrafts } from '../../imports/constants';

import {
  CsvStep,
  GeneralStep,
  NftsStep,
  RedeemStep,
  SpecificStep,
  SummaryStep,
  TemplateStep,
  UploadStep,
} from '../../components';

import type {
  Contract,
  Nft,
  TemplateStepNames,
  TContractAdditionalInfo,
  Template,
} from '../../imports/types';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';
import { useLoadingStatusContext } from '../../../../context';
import { freeIpfsGateway } from '../../../../imports/constants';
import { db } from '../../../../imports/firebase';

import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';
import { storeAttributedData } from '../../../../imports/storage';

const CreateContract = () => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { state, key } = useLocation() as Location<{
    contract?: any;
  }>;

  const stepNameLabel = [
    { name: 'TEMPLATE', label: t('create_contract.titles.template') },
    { name: 'GENERAL', label: t('create_contract.titles.general') },
    { name: 'SPECIFIC', label: t('create_contract.titles.specific') },
    { name: 'UPLOAD_MODE', label: t('create_contract.titles.upload_mode') },
    { name: 'NFT', label: t('create_contract.titles.nft') },
    { name: 'REDEEM', label: t('create_contract.titles.redeem') },
    { name: 'SUMMARY', label: t('create_contract.titles.summary') },
  ];
  const template = useAppSelector((state) => state.template);

  const steps = stepNameLabel.filter((item) =>
    template.steps.includes(item.name as TemplateStepNames)
  );
  // const filteredArray = stepNameLabel.filter((item) => inputArray.includes(item.name));
  const {
    isStandardTemplate,
    team: { workspace },
    user: profile,
  } = useAppSelector(({ template, team, user }) => ({
    isStandardTemplate: template.isStandardTemplate,
    team,
    user,
  }));

  // const [allStep, setallStep] = useState(steps);
  const findStep = (stepName: string) => {
    return steps.findIndex((e) => e.name === stepName);
  };

  const {
    wallet: { address },
    uid,
  } = profile;

  const targetOwnerUid = workspace?.owner ?? profile.uid;

  const { contract: draftContract } = state || {};

  const [isMounted, setIsMounted] = useState(false);
  const [step, setStep] = useState(0);
  const [templatePack, setTemplatePack] = useState<Array<Template>>([]);
  const [contractsLeft, setContractsLeft] = useState(-1);
  const [isManual, setIsManual] = useState(false);
  const [actualDraftsContracts, setActualDraftContracts] = useState(-1);
  const { dispatch: loadingStatusDispatch, state: ContextState } = useLoadingStatusContext();
  const contractSchema = useContractSchema(step < findStep('NFT'));
  const { open: openLoadingModal } = useModal('LoadingModal');

  const {
    workspaceGroupObject: { groupId, workspaceId, inGroup },
  } = useWorkspaceAndGroup();

  const handleStepForward = () => {
    if (step !== -1 && step < steps.length - 1) {
      setStep(step + 1);
    }
  };
  const handleStepBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const { data: templates, isFetching } = useFirestoreQueryData(
    ['templates'],
    query(
      collection(db, 'templates'),
      where('active', '==', true),
      where('pack', 'in', workspace?.templatePack || ['standard'])
    ),
    {
      subscribe: true, // or undefined
    }
  );

  useEffect(() => {
    const templatePack = workspace?.templatePack || [];

    const filteredTemplates = templates?.filter((template) => {
      return template.type === 'standard' || templatePack.includes(template.name);
    });
    setTemplatePack(filteredTemplates as Template[]);
  }, [workspace]);

  const [templateName, setTemplateName] = useState('');
  const contractForm = {
    initialValues: {
      scores: null,
      activities: null,
      name: template?.fields?.name?.value || '',
      description: template?.fields?.description?.value || '',
      type: 'ERC1155',
      owner: workspace?.ownerAddress?.toLowerCase() ?? address.toLowerCase(),
      symbol: 'ftt',
      tag: template?.fields?.tag?.value || [],
      chain: 'polygon',
      hasGame: template?.fields?.hasGame?.value || false,
      isPrivate: template?.fields?.isPrivate?.value || true,
      metadataUri: '',
      numberOfCategories: 1,
      maxTokensPerUser: template?.fields?.maxTokensPerUser?.value || 1,
      doesExpire: template?.fields?.doesExpire?.value || false,
      expiration: template?.fields?.expiration?.value || 0,
      expirationFrom: template?.fields?.expirationFrom?.value || 'contract',
      doesTransfer: template?.fields?.doesTransfer?.value || false,
      transfer: template?.fields?.transfer?.value || 'none',
      transferAddresses: '',
      doesUpdate: template?.fields?.doesUpdate?.value || false,
      update: template?.fields?.update?.value || 'none',
      addCategory: template?.fields?.addCategory?.value || false,
      updateAddresses: '',
      transferOwnership: false,
      tokenBurn: true,
      status: 'new',
      qrCodeDrop: true,
      qrCodeDropVal: template?.fields?.qrCodeDropVal?.value || 1,
      doesDropDate: false,
      dropDate: null,
      contractExternalUrl: '',
      contractImage: [],
      useAsLogo: false,
      nfts: [EMPTY_NFT],
      templateId: template?.id ?? 'standard' /* EMPTY_CONTRACT_ID */,
    },
    validationSchema: contractSchema,
  };

  const { initialValues, validationSchema } = contractForm;

  const goTo = (page: string) => {
    navigate(page);
  };

  const handleLoadingModal = () => {
    openLoadingModal({ goTo, type: 'contract' });
  };

  const handleSaveContract = async ({
    activities,
    chain,
    description,
    expiration,
    expirationFrom,
    numberOfCategories,
    maxTokensPerUser,
    name,
    owner,
    qrCodeDrop,
    qrCodeDropVal,
    dropDate,
    status,
    symbol,
    tag,
    tokenBurn,
    transfer,
    transferAddresses,
    updateAddresses,
    transferOwnership,
    update,
    addCategory,
    type,
    hasGame,
    isPrivate,
    nfts,
    useAsLogo,
    contractExternalUrl,
    contractImage,
    scores,
    templateId,
  }: Contract & {
    dropDate: string | null;
    numberOfCategories: number;
  } & TContractAdditionalInfo) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('create_contract.create.actions.pending'),
      },
    });

    // Check if file size does not exceed 100mb
    if (typeof nfts?.[0].image !== 'string') {
      // in case of CSV upload image is a string
      let size = 0;
      nfts.forEach((nft) => {
        size += (nft?.image as File[])?.[0]?.size ?? 0;
        if (nft.isUnlimited) nft.quantity = 0;
      });
      if (size > 100 * 1048576) {
        toast.warn(t('maxMB') as string);
        return;
      }
    }

    // Check if user has enough draft contracts left
    if (actualDraftsContracts > maxDrafts) {
      toast.error(t('create_contract.actions.max_draft_reached') as string);
      return;
    }

    // Check if user can mint the quantity
    const quantityRequested = nfts
      .map((nft: Nft) => Number(nft.quantity))
      .reduce((prev: number, curr: number) => prev + curr);

    const subscription = (await getSubscription(targetOwnerUid)).value;
    const maximumMintable = subscription.maxTokenNumber - subscription.currentTokenNumber;

    if (quantityRequested > maximumMintable) {
      toast.error(`${t('create_contract.actions.maxTokens')} ${maximumMintable}`);
      return;
    }

    if (10 + 0.03 * quantityRequested > parseFloat(subscription.eurWallet)) {
      toast.error(`${t('create_contract.actions.noMoney')} ${subscription.eurWallet}`);
      return;
    }

    // const maxSupplyPerRarity = [0, ...Array(Number(numberOfCategories)).fill(1)];
    const maxSupplyPerRarity = [0, ...nfts.map((nft) => Number(nft.quantity))];

    handleLoadingModal();

    try {
      const timestamp = getUnixTime(Date.now());
      const contractId = `${name.replace(/\s/g, '')}${timestamp}`;

      const { contractURI, image } = await uploadContractInfoIpfs(
        contractImage?.[0],
        {
          contractExternalUrl,
          contractId,
          name,
          description,
        },
        workspace?.id || ''
      );

      // per ogni custom field, controllare se esiste un file e se è privato o meno
      //passare al backend il file ricevendo un url {file, workspaceId, contractID, tokenId, identificare customField, idFile }
      //url -> value
      //file -> eliminare il campo

      /* FUNZIONE PER UPLOAD FILE, ORA COMMENTATA PERCHE' NON PRONTA LATO BACKEND */
      // if (workspace?.id) {
      //   uploadFiles(nfts, contractId, workspace.id);
      // }

      if (workspace?.id) {
        const uploadedNfts = await Promise.all(
          storeAttributedData(nfts, { name, contractId }, workspace?.id)
        );
        nfts = uploadedNfts;
      }

      const { metadataCid: metadataUri, imagesCid } = await uploadNftsIpfs(
        nfts,
        contractId,
        workspace?.id || ''
      );

      await saveContract({
        scores,
        activities,
        chain,
        contractURI,
        image,
        description,
        expiration: Number(expiration) || 0,
        expirationFrom,
        maxTokensPerUser: Number(maxTokensPerUser),
        qrCodeDropVal,
        metadataUri,
        name,
        owner,
        hasGame,
        contractExternalUrl,
        isPrivate,
        qrCodeDrop: Number(qrCodeDrop),
        dropDate: dropDate && Math.floor(new Date(dropDate).getTime() / 1000),
        status,
        symbol,
        tag,
        tokenBurn,
        transfer,
        transferAddresses,
        update,
        addCategory,
        updateAddresses,
        transferOwnership,
        type,
        useAsLogo,
        maxSupplyPerRarity,
        userId: targetOwnerUid,
        workspace_id: workspace?.id,
        templateId,
        id: contractId,
        ...(inGroup && groupId && { groupId, inGroup }),
      });

      await Promise.all(
        nfts.map((nft: Nft, index: number) => {
          const { id, image, quantity, ...metadata } = nft;

          const docId = `${nft.name.replace(/\s/g, '')}${index + 1}`;

          // CSV with images as IPFS uris
          if (typeof image[0] === 'string') {
            return setDoc(doc(db, 'contracts', contractId, 'nfts', docId), {
              id: index + 1,
              image,
              quantity: Number(quantity),
              ...metadata,
              createdAt: Date.now(),
              updatedAt: Date.now(),
            });
          }
          // CSV without images or manual upload
          const { name: imageName } = image[0];

          return setDoc(doc(db, 'contracts', contractId, 'nfts', docId), {
            id: index + 1,
            image: `${freeIpfsGateway}/${imagesCid}/${formatImage(imageName)}`,
            quantity: Number(quantity),
            createdAt: Date.now(),
            updatedAt: Date.now(),
            ...metadata,
          });
        })
      );

      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('create_contract.create.actions.success'),
        },
      });
    } catch (err) {
      console.error(err);
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('create_contract.create.actions.error'),
        },
      });
    }
  };

  const handleCheckSubscription = async () => {
    const { value, error } = await getSubscription(targetOwnerUid);

    if (error) {
      toast.error(t('subscription_toasts.no_subscription') as string);
      navigate('/');
      return;
    }

    if (value) {
      const {
        expDate,
        currentContractsNumber,
        maxContractsNumber,
        currentTokenNumber,
        maxTokenNumber,
      } = value;
      const expDateToDate = new Date(expDate.seconds * 1000 + expDate.nanoseconds / 1000000);
      if (expDateToDate <= new Date()) {
        toast.error(t('subscription_toasts.subscription_expired') as string);
        navigate('/');
        return;
      }

      if (currentContractsNumber >= maxContractsNumber) {
        toast.error(t('subscription_toasts.contracts_limit_reached') as string);
        navigate('/');
        return;
      }

      if (currentTokenNumber >= maxTokenNumber) {
        toast.error(t('subscription_toasts.tokens_limit_reached') as string);
        navigate('/');
      }
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const checkDraftContracts = async () => {
      const { value } = await getDraftContracts(address);
      setActualDraftContracts(value || 0);
    };

    const checkContracts = async () => {
      const cleft = await getLeftContracts(targetOwnerUid);

      setContractsLeft(cleft.value || 0);

      if (!cleft.value) {
        toast.error(t('create_contract.no_contracts_left') as string, {
          toastId: 'no-contracts-left',
        });
      }
    };

    checkContracts();
    checkDraftContracts();

    if (profile) {
      delayed(() => handleCheckSubscription(), 500);
    }
  }, []);

  return (
    <InnerPage>
      {isFetching ? (
        <div className="flex min-h-screen grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <div className="flex flex-row">
          {step !== 0 && (
            <Sidebar
              stepperCurrent={step}
              stepperLabels={steps.map((step) => step.label)}
              title={t('create_contract.titles.sidebar_title')}
              subtitle={t('create_contract.titles.sidebar_subtitle', {
                companyName: templateName, // profile.name || profile.company,
              })}
            />
          )}

          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className={`${step !== 0 && 'ml-[max(min(30vw,420px),360px)]'} flex-1 px-8`}
          >
            {({
              fields,
              errors,
              handleSubmit,
              watch,
              resetField,
              getValues,
              control,
              register,
              setValue,
              reset,
              isValid,
              dirtyFields,
            }) => {
              useEffect(() => {
                if (isMounted) {
                  reset(initialValues);
                }
              }, [key]);

              const values = watch();

              // console.log('template', template);
              // console.log('🚀 ~ CreateContract ~ values:', values.update);
              // console.log('initial', initialValues.update);

              useEffect(() => {
                if (isStandardTemplate) {
                  setStep(findStep(steps[1].name));
                }
              }, []);
              useEffect(() => {
                reset(initialValues);
              }, [initialValues.templateId]);

              return (
                <div className="h-full">
                  {step === findStep('TEMPLATE') && (
                    <TemplateStep
                      contractsLeft={contractsLeft}
                      isLoading={ContextState.status === 'pending'}
                      onBack={() => navigate('/nft/')}
                      // onCompleted={() => setStep(findStep('GENERAL'))}
                      onCompleted={handleStepForward}
                      fields={fields}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      values={values}
                      resetField={resetField}
                      reset={() => reset(initialValues)}
                      control={control}
                      setValue={setValue}
                      templates={templates as Template[]}
                    />
                  )}
                  {step === findStep('GENERAL') && (
                    <GeneralStep
                      fields={fields}
                      contractsLeft={contractsLeft}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      values={values}
                      resetField={resetField}
                      control={control}
                      setValue={setValue}
                      isLoading={ContextState.status === 'pending'}
                      dirtyFields={dirtyFields}
                      templates={templates as Template[]}
                      watch={watch}
                      onCompleted={handleSubmit(handleStepForward)}
                      onBack={handleStepBack}
                    />
                  )}
                  {step === findStep('SPECIFIC') && (
                    <SpecificStep
                      fields={fields}
                      contractsLeft={contractsLeft}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      values={values}
                      resetField={resetField}
                      control={control}
                      setValue={setValue}
                      isLoading={ContextState.status === 'pending'}
                      templates={templates as Template[]}
                      onCompleted={handleSubmit(handleStepForward)}
                      onBack={handleStepBack}
                    />
                  )}

                  {step === findStep('UPLOAD_MODE') && (
                    <UploadStep
                      values={values}
                      handleSubmit={handleSubmit}
                      setValue={setValue}
                      setStep={setStep}
                      setIsManual={setIsManual}
                      isLoading={ContextState.status === 'pending'}
                      contractsLeft={contractsLeft}
                      templates={templates as Template[]}
                      getValues={getValues}
                      control={control}
                      onCompleted={handleSubmit(handleStepForward)}
                      onBack={handleStepBack}
                    />
                  )}
                  {step === findStep('NFT') && (
                    <>
                      {isManual || !template.steps.includes('UPLOAD_MODE') ? (
                        <NftsStep
                          watch={watch}
                          fields={fields}
                          title={t('create_contract.titles.nft_creation')}
                          errors={errors}
                          contractsLeft={contractsLeft}
                          handleSubmit={handleSubmit}
                          resetField={resetField}
                          values={values}
                          control={control}
                          register={register}
                          setValue={setValue}
                          isLoading={ContextState.status === 'pending'}
                          ctaLabel={t('create_contract.form_labels.go_to_payment')}
                          templates={templates as Template[]}
                          quantityModifiable
                          dirtyFields={dirtyFields}
                          onCompleted={handleSubmit(handleStepForward)}
                          onBack={handleStepBack}
                        />
                      ) : (
                        <CsvStep
                          setValue={setValue}
                          onNext={() => {
                            const nfts = getValues('nfts');
                            if (typeof nfts?.[0]?.image === 'string') {
                              setStep(findStep('REDEEM'));
                            } else {
                              setStep(findStep('UPLOAD_MODE'));
                            }
                          }}
                          getValues={getValues}
                          // onCompleted={handleStepForward}
                          onBack={handleStepBack}
                        />
                      )}
                    </>
                  )}
                  {step === findStep('REDEEM') && (
                    <RedeemStep
                      fields={fields}
                      contractsLeft={contractsLeft}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      values={values}
                      resetField={resetField}
                      control={control}
                      setValue={setValue}
                      isLoading={ContextState.status === 'pending'}
                      templates={templates as Template[]}
                      onCompleted={handleSubmit(handleStepForward)}
                      onBack={handleStepBack}
                    />
                  )}
                  {step === findStep('SUMMARY') && (
                    <SummaryStep
                      values={values}
                      onSubmit={() => handleSaveContract(values)}
                      onBack={handleStepBack}
                      templates={templates as Template[]}
                    />
                  )}
                </div>
              );
            }}
          </Form>
        </div>
      )}
    </InnerPage>
  );
};

export default CreateContract;
