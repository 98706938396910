import { useLocation } from 'react-router-dom';
import { Contract } from '../../modules/tokenCreator/imports/types';
import { useAppSelector } from '../../redux/hooks';
import LinkText from '../linkText/LinkText';
import Typography from '../typography/Typography';

const getFixedPathname = (
  pathname: string,
  pathValues: string[],
  contracList: Contract[]
): string => {
  if (!pathname.startsWith('/nft/analytics/')) return pathname;

  const contractId = pathValues[2];
  const nftId = pathValues[3];
  const contract = contracList.find((contract) => contract.id === contractId);

  if (!contract) {
    return pathname;
  }

  let customPathname = `/nft/analytics/${contract.name}`;
  if (nftId) {
    const nft = contract.nfts.find((nft) => nft.id.toString() === nftId);
    if (nft) {
      customPathname += `/${nft.name}`;
    }
  }

  return customPathname;
};

const getPathValuesFromPathname = (pathname: string): string[] => {
  return pathname.split('/').filter((page) => page !== '');
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const { list } = useAppSelector((state) => state.contracts);
  const pathValues = getPathValuesFromPathname(pathname);
  const fixedPathname = getFixedPathname(pathname, pathValues, list);
  const fixedPathValues = getPathValuesFromPathname(fixedPathname);
  const redirects = pathValues.filter((page) => '/' + page);

  return (
    <div className="flex flex-row">
      {fixedPathValues.map((element, index) => {
        const linkTo = `/${redirects.slice(0, index + 1).join('/')}`;

        return (
          <div key={index} className="flex flex-row">
            <LinkText
              textColor="primary-500"
              decorationColor="primary-500"
              textColorHover="primary-500"
              to={linkTo}
            >
              {element}
            </LinkText>
            <Typography color="primary-500">&nbsp;&nbsp;/&nbsp;&nbsp;</Typography>
          </div>
        );
      })}
    </div>
  );
};
export default Breadcrumbs;
