import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../components/index';

const ManageTeamsTableHeader = ({ pendingRequest }: { pendingRequest: boolean }) => {
  const { t } = useTranslation(['team']);
  return (
    <div
      className={`sticky top-0 grid h-[65px] w-full grid-cols-6 items-center bg-primary-500 text-center text-white ${
        pendingRequest && 'opacity-50'
      }`}
    >
      <Typography size="body-medium-18" weight="medium">
        {t('user_name')}
      </Typography>
      <Typography size="body-medium-18" weight="medium">
        {t('select.nft')}
      </Typography>
      <Typography size="body-medium-18" weight="medium">
        {t('select.notarization')}
      </Typography>
      <Typography size="body-medium-18" weight="medium">
        {t('select.audit')}
      </Typography>
      <Typography size="body-medium-18" weight="medium">
        {t('manage.account_type')}
      </Typography>
      <Typography size="body-medium-18" weight="medium">
        {t('verify')}
      </Typography>
    </div>
  );
};

export default ManageTeamsTableHeader;
