import { SVGProps } from 'react';

const IconCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1806_45236)">
      <path
        d="M156 30H36C32.6863 30 30 32.6863 30 36V156C30 159.314 32.6863 162 36 162H156C159.314 162 162 159.314 162 156V36C162 32.6863 159.314 30 156 30Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132 18V42"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 18V42"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 66H162"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69 96H90L78 111C79.9749 110.997 81.9201 111.481 83.663 112.409C85.406 113.338 86.8928 114.682 87.9916 116.323C89.0904 117.964 89.7672 119.851 89.962 121.816C90.1568 123.782 89.8635 125.765 89.1082 127.589C88.3529 129.414 87.159 131.024 85.6322 132.277C84.1054 133.53 82.2931 134.386 80.356 134.771C78.4188 135.155 76.4168 135.055 74.5274 134.48C72.6381 133.905 70.9198 132.873 69.525 131.475"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108 105L120 96V135"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1806_45236">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconCalendar;
