/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconNft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38759)">
      <path
        d="M165 131.475V60.525C164.994 59.4568 164.707 58.409 164.167 57.4873C163.627 56.5655 162.854 55.8024 161.925 55.275L98.925 19.65C98.0357 19.1365 97.0269 18.8662 96 18.8662C94.9731 18.8662 93.9643 19.1365 93.075 19.65L30.075 55.275C29.1461 55.8024 28.3728 56.5655 27.8329 57.4873C27.293 58.409 27.0057 59.4568 27 60.525V131.475C27.0057 132.543 27.293 133.591 27.8329 134.513C28.3728 135.434 29.1461 136.197 30.075 136.725L93.075 172.35C93.9643 172.863 94.9731 173.134 96 173.134C97.0269 173.134 98.0357 172.863 98.925 172.35L161.925 136.725C162.854 136.197 163.627 135.434 164.167 134.513C164.707 133.591 164.994 132.543 165 131.475V131.475Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165 131.475V60.525C164.994 59.4568 164.707 58.409 164.167 57.4873C163.627 56.5655 162.854 55.8024 161.925 55.275L98.925 19.65C98.0357 19.1365 97.0269 18.8662 96 18.8662C94.9731 18.8662 93.9643 19.1365 93.075 19.65L30.075 55.275C29.1461 55.8024 28.3728 56.5655 27.8329 57.4873C27.293 58.409 27.0057 59.4568 27 60.525V131.475C27.0057 132.543 27.293 133.591 27.8329 134.513C28.3728 135.434 29.1461 136.197 30.075 136.725L93.075 172.35C93.9643 172.863 94.9731 173.134 96 173.134C97.0269 173.134 98.0357 172.863 98.925 172.35L161.925 136.725C162.854 136.197 163.627 135.434 164.167 134.513C164.707 133.591 164.994 132.543 165 131.475V131.475Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1012 82.98V114H67.5732L59.1132 98.631L57.1392 94.448H57.0452L57.2332 100.558V114H48.8672V82.98H59.3482L67.8082 98.349L69.8292 102.532H69.8762L69.6882 96.422V82.98H78.1012Z"
        fill={props.stroke ? props.stroke : '#4F4F4F'}
      />
      <path
        d="M111.149 90.171H95.7798V95.576H108.094V102.767H95.7798V114H86.1918V82.98H111.149V90.171Z"
        fill={props.stroke ? props.stroke : '#4F4F4F'}
      />
      <path
        d="M144.955 82.98V90.171H135.085V114H125.497V90.171H115.533V82.98H144.955Z"
        fill={props.stroke ? props.stroke : '#4F4F4F'}
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38759">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconNft;
