import { useCallback, useEffect } from 'react';
import ReactFlow, {
  Background,
  Connection,
  Edge,
  Node,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { AnswerForm, QuestionForm } from '../../../../imports/types';

type PollsFlowsType = {
  questions: QuestionForm[];
};

const PollsFlows = ({ questions }: PollsFlowsType) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const getNodesFromQuestions = (questions: QuestionForm[]): Node[] => {
    const nodes: Node[] = [];
    let currentY = 30;

    const questionX = window.innerWidth / 2 - 240;
    const answerWidth = 200;
    const spaceBetweenAnswers = 50;

    questions.forEach((question: QuestionForm, questionIndex: number) => {
      nodes.push({
        id: question.qId,
        data: { label: question.question },
        position: { x: questionX, y: currentY },
        className: 'shadow-xl text-white bg-primary-500 w-[400px]',
      });

      const numAnswers = question.answers.length;
      const totalAnswersWidth = numAnswers * (answerWidth + spaceBetweenAnswers);
      const startX = questionX - totalAnswersWidth / 2 + answerWidth / 2 + 125;

      question.answers.forEach((answer: AnswerForm, answerIndex: number) => {
        const answerY =
          questionIndex + 1 < questions.length &&
          answer.nextQuestion !== questions[questionIndex + 1].qId
            ? currentY + 100 + 50
            : currentY + 100;
        const answerX =
          questionIndex + 1 < questions.length &&
          answer.nextQuestion !== questions[questionIndex + 1].qId
            ? answerIndex < numAnswers / 2
              ? startX + answerIndex * (answerWidth + spaceBetweenAnswers) - 270
              : startX + answerIndex * (answerWidth + spaceBetweenAnswers) + 270
            : startX + answerIndex * (answerWidth + spaceBetweenAnswers);

        nodes.push({
          id: answer.aId,
          data: { label: answer.answer },
          position: { x: answerX, y: answerY },
          className: 'shadow-xl border-2 border-primary-500 text-primary-500 w-[200px]',
        });
      });
      currentY += 250;
    });

    return nodes;
  };

  const getEdgesFromQuestions = (questions: QuestionForm[]): Edge[] => {
    const edges: Edge[] = [];
    questions.forEach((question: QuestionForm) => {
      question.answers.forEach((answer: AnswerForm, index: number) => {
        edges.push({
          id: question.qId + index,
          source: question.qId,
          target: answer.aId,
          animated: false,
          style: { stroke: '#1767FE' },
        });
        if (answer.nextQuestion !== '') {
          edges.push({
            id: answer.aId + index,
            source: answer.aId,
            target: answer.nextQuestion,
            animated: true,
            style: { stroke: '#1767FE' },
          });
        }
      });
    });

    return edges;
  };

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
    [setEdges]
  );
  useEffect(() => {
    setNodes(getNodesFromQuestions(questions));
    setEdges(getEdgesFromQuestions(questions));
  }, [questions]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      deleteKeyCode={null}
    >
      <Background />
    </ReactFlow>
  );
};

export default PollsFlows;
