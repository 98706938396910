import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { Button, Field, Form, Select } from '..';
import {
  addDocumentToFolder,
  addFolderToDocument,
  removeDocumentFromFolder,
  replaceDocumentFolder,
} from '../../api/folders';
import { useLoadingStatusContext } from '../../context';
import { useAppSelector } from '../../redux/hooks';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type ChangeFolderModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { folderType, folders, item } = useModalProps('ChangeFolderModal');
  const { t } = useTranslation(['notarization']);

  const { workspace } = useAppSelector((state) => state.team);
  const { dispatch: loaderDispatch, state } = useLoadingStatusContext();

  const form = {
    initialValues: {
      folder: item.folderId,
    },
    validationSchema: yup.object({}),
  };
  const { initialValues, validationSchema } = form;

  // const handleFolder = async (values: { name: string }) => {
  //   const { name } = values;

  //   loaderDispatch({
  //     type: 'SET_PENDING',
  //     payload: {
  //       title: t('edit_folder.actions.pending'),
  //     },
  //   });

  // await createFolder(targetOwnerUid, name, folderType, workspace?.id!)
  //   .then(() => {
  //     loaderDispatch({
  //       type: 'SET_SUCCESS',
  //       payload: {
  //         title: t('edit_folder.actions.success'),
  //       },
  //     });
  //   })
  //   .catch(() => {
  //     loaderDispatch({
  //       type: 'SET_ERROR',
  //       payload: {
  //         title: t('edit_folder.actions.failed'),
  //       },
  //     });
  //   });
  // };

  const handleChangeFolder = async (values: { folder: string }) => {
    const { folder } = values;
    loaderDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('edit_folder.actions.pending'),
      },
    });

    if (item?.folderId) {
      await replaceDocumentFolder(item.folderId, folder, item.id, folderType)
        .then(() => {
          loaderDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('edit_folder.actions.success'),
            },
          });
        })
        .catch(() => {
          loaderDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('edit_folder.actions.failed'),
            },
          });
        });
    } else {
      await addFolderToDocument(folder, item.id, folderType)
        .then(async () => {
          await addDocumentToFolder(folder, item.id)
            .then(() => {
              loaderDispatch({
                type: 'SET_SUCCESS',
                payload: {
                  title: t('edit_folder.actions.success'),
                },
              });
            })
            .catch((error) => {
              loaderDispatch({
                type: 'SET_ERROR',
                payload: {
                  title: t('edit_folder.actions.failed1'),
                },
              });
            });
        })
        .catch((error) => {
          loaderDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('edit_folder.actions.failed2'),
            },
          });
        });
    }
  };

  const handleRemoveFolder = async () => {
    loaderDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('edit_folder.actions.pending'),
      },
    });

    await removeDocumentFromFolder(item.folderId, item.id, folderType)
      .then(() => {
        loaderDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('edit_folder.actions.success'),
          },
        });
      })
      .catch(() => {
        loaderDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('edit_folder.actions.failed'),
          },
        });
      });
  };

  const [selectedSize, setSelectedSize] = useState('');

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mt-7 flex size-full flex-col gap-7 p-4 ">
        <div>
          <Typography weight="medium" size="body-medium-30" color="primary-500">
            {t('edit_folder.title')}
          </Typography>
        </div>
        <div data-cy="changeFolder">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="flex w-full flex-col"
          >
            {({ fields, handleSubmit, errors, watch, getValues }) => {
              const selectedFolder = watch('folder');
              const currentFolder = item?.folderId || '-';

              // const selectFolderOptions = folders.reduce(
              //   (
              //     acc: Array<{ value: string; label: string; disabled: boolean }>,
              //     folder: { id: string; name: string }
              //   ) => {
              //     if (folder.id !== currentFolder) {
              //       acc.push({
              //         value: folder.id,
              //         label: folder?.name,
              //         disabled: false,
              //       });
              //     }
              //     return acc;
              //   },
              //   []
              // );
              const selectFolderOptions = folders.map((folder: { id: string; name: string }) => {
                return {
                  value: folder.id,
                  label: folder?.name,
                  disabled: false,
                };
              }, []);

              //Add disabled empty row as first element
              selectFolderOptions.unshift({ value: '-', label: 'none', disabled: false });
              return (
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <Typography weight="medium" size="lg">
                      {t('edit_folder.current_fodler')}
                    </Typography>
                    {/* <Typography>{folderName}</Typography> */}
                  </div>

                  {workspace?.permissions.notarization.write && (
                    <div className="flex flex-col gap-7">
                      <Field error={errors.folder?.message}>
                        <Select
                          options={selectFolderOptions}
                          id={fields.folder.name}
                          name={fields.folder.name}
                          onBlur={fields.folder.onBlur}
                          onChange={fields.folder.onChange}
                          inputRef={fields.folder.ref}
                          // value={currentFolder}
                          defaultValue={currentFolder}
                        />
                      </Field>
                      <div className="flex justify-end gap-5">
                        {item.folderId && (
                          <Button type="secondary" action={handleRemoveFolder}>
                            {t('edit_folder.form_placeholders.remove')}
                          </Button>
                        )}
                        <Button
                          type="primary"
                          action={() => handleSubmit(handleChangeFolder)()}
                          //action={() => handleChangeFolder(values)}
                          //loading={isLoading}
                          disabled={selectedFolder === ''}
                        >
                          {t('edit_folder.submit_data')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </Form>
        </div>
      </div>
    </ModalLayout>
  );
};

const ChangeFolderModal = ({ children }: ChangeFolderModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ChangeFolderModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ChangeFolderModal;
