import { collection, doc, updateDoc } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';

/* root imports */
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../redux/hooks';

import { db } from '../../../../imports/firebase';

import { Button, Icon, InnerPage, SectionTitle, Typography } from '../../../../components';

import { IconArrow, IconEdit, IconLoading, IconTrash } from '../../../../icons';

import 'swiper/css';
import 'swiper/css/navigation';
import { useLoadingStatusContext } from '../../../../context/loading/LoadingContext';
import { getContractsFromFunctionalities } from '../../../../imports/contractsParsers';
import { ProductForm } from '../../../../imports/types';
import { updateContracts } from '../../../../redux/contracts/contracts.slice';
import { removeProduct } from '../../../../redux/functionalities/functionalities.slice';
import { deleteDocument, updateNft } from '../../api';
import { Contract, Nft } from '../../imports/types';

const AttributesDetails = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: attributesId } = useParams();

  const { allProducts, contractsList, workspace, uid } = useAppSelector((state) => ({
    allProducts: state.functionalities.products,
    contractsList: state.contracts.list,
    uid: state.user.uid,
    workspace: state.team.workspace,
  }));

  const targetOwnerUid = workspace?.owner ?? uid;
  const { open: openContractsManagerModal } = useModal('ContractsManagerModal');
  const { open: openModalNewFunctionality } = useModal('NewFunctionalityModal');
  const { open: openDeleteModal } = useModal('DeleteModal');

  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState<ProductForm | null>(null);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const prevContracts = useRef(contracts);

  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();

  const handleDeleteProduct = async () => {
    if (workspace) {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      if (product) {
        /* delete the functionality from the nfts */

        // Get contracts affected by the delete action
        const contracts = getContractsFromFunctionalities(product.availableOn, contractsList);

        const updatedContracts = await Promise.all(
          contracts.map(async (contract) => {
            return {
              ...contract,
              nfts: await Promise.all(
                contract.nfts.map((nft: Nft) => {
                  /* update nft.attributes on redux contract */

                  return updateNft(
                    {
                      ...nft,
                      attributes: nft.attributes?.filter(({ id }) => !(id === product.id)),
                      updatedAt: Date.now(),
                    },
                    contract.id
                  );
                })
              ),
            };
          })
        );

        if (updatedContracts.length) {
          dispatch(updateContracts(updatedContracts));
        }

        /* delete the functionality from the functionalities DB*/
        const { error } = await deleteDocument(product.id, 'products');

        /** Check if there are errors in promises */
        let isError = false;
        if (error) {
          console.error(error);
          isError = true;
        }

        if (isError) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        } else {
          dispatch(removeProduct(product.id));
          navigate(-1);
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('collection.functionality.actions.delete'),
            },
          });
        }
      }
    }
  };

  const goTo = (page: string) => {
    navigate(page);
  };
  const handleOpenNewFunctionality = () => {
    openModalNewFunctionality({ goTo, edit: product });
  };

  const handleOpenContractsManager = (availableContracts: any) => {
    openContractsManagerModal({ availableContracts, setContracts });
  };

  const handleDelete = () => {
    openDeleteModal({ handleDelete: handleDeleteProduct });
  };

  const handleSubmit = async (editedContracts: Contract[], pollId: string) => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('news.actions.pending'),
      },
    });
    try {
      const newAttributesRef = doc(collection(db, 'attributes'), attributesId);
      const nftObject = editedContracts.reduce((acc, contract) => {
        acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
        return acc;
      }, {} as { [key: string]: number[] });
      await updateDoc(newAttributesRef, {
        availableOn: nftObject,
      });
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('news.actions.edit_success'),
        },
      });
      // Empty the contracts array after every submission
    } catch (error) {
      console.log(error);
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('news.actions.error'),
        },
      });
    } finally {
      setContracts([]);
    }
  };

  useEffect(() => {
    if (product && contracts.length > 0 && prevContracts.current !== contracts) {
      handleSubmit(contracts, product.id);
    }
    prevContracts.current = contracts;
  }, [contracts]);

  useEffect(() => {
    const showProduct = allProducts.find((product: { id: string }) => product.id === attributesId);
    if (showProduct) setProduct(showProduct);
    setIsLoading(false);
  }, [attributesId]);

  return (
    <InnerPage>
      {isLoading ? (
        <div className="flex grow items-center justify-center">
          <IconLoading className="size-12 animate-spin text-primary-500" />
        </div>
      ) : (
        <>
          {!product ? (
            <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
              <Typography as="h2" size="xl" className="text-center">
                {t('functionality.functionality_not_found')}
              </Typography>

              <Button action={() => navigate('/nft/')} className="mt-10">
                {t('functionality.goBack')}
              </Button>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-8 ">
                <div className="flex">
                  <IconArrow
                    onClick={() => navigate(-1)}
                    width={40}
                    height={40}
                    stroke="#642eff"
                    className="mb-3 cursor-pointer rounded-md"
                  />
                </div>
              </div>
              <section className="mt-4 flex flex-col gap-4">
                <SectionTitle title={product.title}></SectionTitle>
                <div className="flex w-full justify-end gap-4">
                  {/* <Icon
                    icon={IconShare}
                    className="p-2 mt-4 rounded-lg shadow-xl cursor-pointer bg-primary-500"
                    size="30"
                    stroke="white"
                    onClick={() => handleOpenContractsManager(product.availableOn)}
                  /> */}
                  <Icon
                    icon={IconEdit}
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    size="30"
                    stroke="white"
                    onClick={() => handleOpenNewFunctionality()}
                  />
                  <Icon
                    icon={IconTrash}
                    className="mt-4 cursor-pointer rounded-lg bg-primary-500 p-2 shadow-xl"
                    size="30"
                    stroke="white"
                    onClick={() => handleDelete()}
                  />
                </div>
                <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4">
                  {product.attributes.map((attribute: { name: string; description: string }) => {
                    return (
                      <div key={attribute.name} className="flex flex-row gap-4">
                        <div>
                          <Typography weight="semibold">{`${t(
                            'functionality.attribute'
                          )}:`}</Typography>
                          <Typography weight="semibold">{`${t(
                            'functionality.description'
                          )}:`}</Typography>
                        </div>
                        <div>
                          <Typography>{attribute.name}</Typography>
                          <Typography>{attribute.description}</Typography>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
            </>
          )}
        </>
      )}
    </InnerPage>
  );
};

export default AttributesDetails;
