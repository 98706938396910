import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/* root imports */

import { IconLoading } from '../../../../icons';

import { FunctionalityCard, Typography } from '../../../../components';

import type { Filters, PollForm } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

/* tokencreator imports */

type PollsListProps = {
  polls: PollForm[];
  isLoading: boolean;
  // handleShowSidebar: (type: SidebarType, optionalProps?: any) => void;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
};
const PollsList = ({ polls, isLoading, filtersState, orderBy }: PollsListProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace } = useAppSelector((user) => user.team);

  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;
  // const filteredContracts = useMemo(() => {
  //   const filteredCollection = filterCollection({
  //     filtersInOrder: [
  //       {
  //         type: inputFilterByName.filter,
  //         propertyToFilter: 'name',
  //         filterValue: inputFilterByName.value,
  //       },
  //       {
  //         type: inputFilterByTag.filter,
  //         propertyToFilter: 'tag',
  //         filterValue: inputFilterByTag.value,
  //       },
  //       {
  //         type: inputFilterByDate.filter,
  //         propertyToFilter: 'createdAt',
  //         filterValue: inputFilterByDate.value,
  //       },
  //     ],
  //     collection: polls,
  //   });

  //   if (orderBy) {
  //     if (orderBy.type === 'date') {
  //       return filteredCollection.sort((a, b) =>
  //         compareByDate(a.startDate, b.startDate, orderBy.direction)
  //       );
  //     }

  //     if (orderBy.type === 'name') {
  //       return filteredCollection.sort((a, b) => compare(a, b, 'name', orderBy.direction));
  //     }
  //   }

  //   return filteredCollection;
  // }, [polls, inputFilterByName, inputFilterByTag, inputFilterByDate, orderBy]);

  const handleGoToPollDetails = (pollId: String) => {
    navigate(`polls/${pollId}`);
  };

  return !isLoading ? (
    <>
      <Typography size="body-semibold-20">{t('collection.title_poll')}</Typography>
      <div className="flex flex-wrap gap-5">
        {/* {filteredContracts.length > 0 ? ( */}
        {polls.length > 0 ? (
          //filteredContracts.map((item, index) => { *
          polls.map((item, index) => {
            return (
              <div className="cursor-pointer" key={`${item.id}-${index}`}>
                <FunctionalityCard
                  functionality={item}
                  key={item.id}
                  hasWritePermission={workspace?.permissions.token_creator.write}
                  onClick={() => handleGoToPollDetails(item.id)}
                />
              </div>
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                polls.length === 0
                  ? 'collection.no_filtered_polls_found'
                  : 'collection.no_polls_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default PollsList;
