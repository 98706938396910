import { SVGProps } from 'react';

const IconFileAudit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38798)">
      <path
        d="M126 168H150C151.591 168 153.117 167.368 154.243 166.243C155.368 165.117 156 163.591 156 162V66L114 24H42C40.4087 24 38.8826 24.6321 37.7574 25.7574C36.6321 26.8826 36 28.4087 36 30V96"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 24V66H156"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.5 136.75L57.025 122.144C56.7086 121.903 56.4516 121.594 56.2739 121.238C56.0961 120.883 56.0024 120.491 56 120.094V108.562C56 107.883 56.27 107.231 56.7505 106.751C57.2311 106.27 57.8829 106 58.5625 106H94.4375C95.1171 106 95.7689 106.27 96.2495 106.751C96.73 107.231 97 107.883 97 108.562V119.966C96.9976 120.363 96.9039 120.755 96.7261 121.11C96.5484 121.465 96.2914 121.775 95.975 122.016L76.5 136.75Z"
        stroke="#4F4F4F"
        strokeWidth={11}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.5 136.75L57.025 151.356C56.7086 151.597 56.4516 151.906 56.2739 152.262C56.0961 152.617 56.0024 153.009 56 153.406V164.938C56 165.617 56.27 166.269 56.7505 166.749C57.2311 167.23 57.8829 167.5 58.5625 167.5H94.4375C95.1171 167.5 95.7689 167.23 96.2495 166.749C96.73 166.269 97 165.617 97 164.938V153.534C96.9976 153.137 96.9039 152.745 96.7261 152.39C96.5484 152.035 96.2914 151.725 95.975 151.484L76.5 136.75Z"
        stroke="#4F4F4F"
        strokeWidth={11}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38798">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFileAudit;
