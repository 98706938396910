import axios from 'axios';
import {
  getIdToken,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  updateEmail,
  updatePassword,
  User,
} from 'firebase/auth';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { omit } from 'lodash';

import {
  convertOldWalletToNewWallet,
  createUserAuth,
  createUserCredentials,
  decryptWallet,
  encryptWallet,
  hashPassword,
  loginUser,
} from '../imports/auth';
import api from '../imports/axios';
import { backendEndpoint } from '../imports/constants';
import { auth, db } from '../imports/firebase';

import type { SignupFormProps } from '../imports/types';
import { createTeam } from '../modules/team/api/api';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const signIn = async ({ email, password }: { email: string; password: string }) => {
  try {
    const userCredentials = await loginUser(auth, email, password);
    const { user } = userCredentials;

    const docRef = doc(db, 'users', user.uid);
    const docSnapshot = await getDoc(docRef);

    const { emailVerified } = user;
    let profile;

    if (docSnapshot.exists()) {
      profile = docSnapshot.data();
    }

    let wallet;

    try {
      wallet = decryptWallet(profile?.wallet, password);
    } catch (err) {
      if (profile?.wallet == null) throw new Error('No wallet');
      const newWallet = await convertOldWalletToNewWallet(profile.wallet, password);
      await setDoc(
        doc(db, 'users', user.uid),
        {
          wallet: newWallet,
        },
        { merge: true }
      );
    }
    let team = null;
    try {
      team = (await createTeam(profile?.company || profile?.name?.replace(' ', ''))).workspace;

      if (typeof team === 'object' && team?.error) {
        throw new Error(team?.error);
      }
    } catch (e) {
      console.error(e);
      return result({ error: 'cannot setup workspace' });
    }

    return result({
      value: {
        profile: {
          ...profile,
          wallet: {
            address: wallet?.address.toLowerCase(),
            privateKey: wallet?.privateKey,
          },
          isEmailVerified: emailVerified,
        },
        team: omit(team, ['updated_at', 'created_at']),
      },
    });
  } catch (error) {
    console.error(error);
    return result({ error });
  }
};

export const signUp = async ({
  company,
  vat,
  sdi,
  office,
  email,
  password,
  wallet,
  question,
  answer,
  backup,
  tec1,
  tec2,
  tec3,
  tec4,
  tec5,
}: SignupFormProps) => {
  try {
    const userCredentials = await createUserAuth(auth, email, password);
    const userMainWallet = encryptWallet(wallet, password);
    const backupWallet = encryptWallet(wallet, answer.toLocaleLowerCase().trim());

    const { user } = userCredentials;

    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      company,
      vat: vat.toUpperCase(),
      sdi,
      office,
      email: email.trim().toLowerCase(),
      wallet: userMainWallet,
      address: wallet.address.toLocaleLowerCase(),
      createdAt: Date.now(),
      backup,
      termAndConditionsBcode: {
        tec1,
        tec2,
        tec3,
        tec4,
        tec5,
      },
    });

    await addDoc(collection(db, 'users', user.uid, 'backup'), {
      backupWallet,
      question,
    });

    await api.post(
      '/trial',
      {},
      {
        headers: {
          authorization: await user.getIdToken(),
        },
      }
    );

    await sendEmailVerification(user);

    return result({ value: 'user registered' });
  } catch (error) {
    console.error(error);
    return result({ error });
  }
};

export const logOut = async () => {
  await signOut(auth)
    .then(() => result({ value: 'logged out' }))
    .catch((error) => result({ error }));
};

export const getUserAuthToken = () => {
  const user = auth.currentUser;
  if (user) {
    return getIdToken(user)
      .then((tokenId) => result({ value: tokenId }))
      .catch((error) => result({ error }));
  }
  return result({ error: 'No user' });
};

export const getUserDoc = async (uid: string) => {
  const docRef = doc(db, 'users', uid);
  const docSnapshot = await getDoc(docRef);

  let data;

  if (docSnapshot.exists()) {
    data = docSnapshot.data();
  }

  return result({ value: data });
};

export const updateUserDoc = async (uid: string, data: any) => {
  const docRef = doc(db, 'users', uid);

  try {
    await setDoc(docRef, data, { merge: true });
    return result({ value: data });
  } catch (error) {
    return result({ error });
  }
};

export const getSubscription = async (userId: string) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      return result({ error: 'No token' });
    }
    const response = await axios.get(`${backendEndpoint}/team/get-subscription/${userId}`, {
      headers: { authorization: token },
    });
    const subscription = response.data;

    return result({ value: subscription });
  } catch (e) {
    console.error(e);
    return result({ error: e });
  }
};

export const reauthenticateUser = async (user: User, password: string) => {
  try {
    const credentials = await createUserCredentials(user.email!, password);
    await reauthenticateWithCredential(user, credentials);

    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const changeEmail = async (user: User, email: string) => {
  try {
    await updateEmail(user, email);
    await updateUserDoc(user.uid, { email });

    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const changePrivateCollection = async (privateState: boolean, contractId: string) => {
  const docRef = doc(db, 'contracts', contractId);

  try {
    await setDoc(docRef, { isPrivate: privateState }, { merge: true });
    return result({ value: 'success' });
  } catch (error) {
    return result({ error });
  }
};

export const changePassword = async (uid: string, currentPassword: string, newPassword: string) => {
  try {
    const user = auth.currentUser!;

    const userDocRef = doc(db, 'users', uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userDocData = userDocSnapshot.data();
      const decryptedWallet = decryptWallet(userDocData.wallet, currentPassword);
      const encryptedWallet = encryptWallet(decryptedWallet, newPassword);
      const hashedNewPassword = hashPassword(newPassword, 'sha1');

      await updatePassword(user, hashedNewPassword);
      await setDoc(doc(db, 'users', uid), { wallet: encryptedWallet }, { merge: true });

      return result({ value: 'success' });
    }

    return result({ error: 'User does not exists' });
  } catch (error) {
    return result({ error });
  }
};

// TODO: to be tested
export const sendResetPasswordEmail = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    //toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);

    return result({ value: 'success' });
    //   toast.success(i18n.t("signup.email_alert"), TOAST_CONFIG);
  } catch (error) {
    //   toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
    return result({ error });
  }
  // setUser({ ...user, needrecover: true });
};
