/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconStamp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1708_42820)">
      <path
        d="M30 171H162"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.9989 102L70.6489 39.75C70.0897 37.1251 70.1247 34.4084 70.7511 31.7988C71.3774 29.1892 72.5795 26.7526 74.2691 24.6675C75.9588 22.5824 78.0933 20.9015 80.5165 19.7479C82.9396 18.5942 85.5901 17.997 88.2739 18H103.724C106.408 17.997 109.058 18.5942 111.481 19.7479C113.904 20.9015 116.039 22.5824 117.729 24.6675C119.418 26.7526 120.62 29.1892 121.247 31.7988C121.873 34.4084 121.908 37.1251 121.349 39.75L107.999 102"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156 102H36C32.6863 102 30 104.686 30 108V138C30 141.314 32.6863 144 36 144H156C159.314 144 162 141.314 162 138V108C162 104.686 159.314 102 156 102Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1708_42820">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconStamp;
