import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { getDefaultDateTimeValue } from '../../imports/utils';
import useValidation from '../useValidation';

const useMessageSchema = () => {
  const { required } = useValidation();
  const { t } = useTranslation(['tokenCreator']);

  const { defaultDate, defaultTime } = getDefaultDateTimeValue({ extraHours: 2 });

  const validateDropDate = () => {
    //validate the dropDate only when isDropDate is true, the dropDate cannot be later than the expiresAt if expiresDate is true
    return yup.mixed().test('dropDate', t('messages.errors.date'), (value, schema) => {
      const { isDropDate, expireDate, expiresAt } = schema.parent;
      if (isDropDate && expireDate && expiresAt) {
        return new Date(value).getTime() <= new Date(expiresAt).getTime();
      }
      return true;
    });
  };

  const validateExpiresAt = () => {
    return yup
      .mixed()
      .test('expiresAt', t('messages.errors.same_day_end_hour_lower'), (value, schema) => {
        const { expireDate, dropDate } = schema.parent;
        if (expireDate && dropDate) {
          return new Date(value).getTime() >= new Date(dropDate).getTime();
        }
        return true;
      });
  };

  return yup.object({
    title: required(yup.string()),
    body: required(yup.string()),
    options: yup.array(),
    expireDate: yup.boolean(),
    expiresAt: validateExpiresAt(),
    startHours: yup
      .number()
      .test('twoHoursAdvance', t('messages.errors.start_2_hour_later'), (value, schema) => {
        const { isDropDate } = schema.parent;
        if (value && isDropDate) {
          return value >= defaultTime;
        }
        return true;
      })
      .test('compareTime', t('messages.errors.date_start_higher_than_end'), (value, schema) => {
        const { dropDate, isDropDate, expireDate, expiresAt, endHours } = schema.parent;
        if (
          value &&
          isDropDate &&
          expireDate &&
          new Date(dropDate).getTime() === new Date(expiresAt).getTime()
        ) {
          return value < endHours;
        }

        return true;
      }),
    endHours: yup
      .number()
      .test('compareTime', t('messages.errors.same_day_end_hour_lower'), (value, schema) => {
        const { dropDate, isDropDate, expireDate, expiresAt, startHours } = schema.parent;
        if (
          value &&
          isDropDate &&
          expireDate &&
          new Date(dropDate).getTime() === new Date(expiresAt).getTime()
        ) {
          return value > startHours;
        }

        return true;
      }),
    isDropDate: yup.boolean(),
    dropDate: validateDropDate(),
  });
};

export default useMessageSchema;
