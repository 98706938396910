//react
import { useState } from 'react';
import { Tooltip, Typography } from '../../../../components';

//components

//style
// import "./fileInput.scss";

interface ProfileInputProps {
  styleType: string;
  onChange: (image: any) => void;
  isDisabled?: boolean;
  children: JSX.Element;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  className?: string;
  acceptingFiles?: string;
  message?: string;
  tooltip?: string;
  id: string;
}

const ProfileInput = ({
  styleType = 'primary',
  onChange,
  isDisabled = false,
  children,
  leftIcon,
  rightIcon,
  message,
  className = '',
  acceptingFiles = 'application/pdf, .jpg, .png, .json',
  tooltip,
  id,
}: ProfileInputProps) => {
  const handleClick = () => {
    document.getElementById(id)?.click();
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    // const formData = new FormData(document.getElementById(id));
    onChange(e);
  };

  return (
    <div
      id={`${id}_container`}
      className="relative flex w-full flex-col items-center justify-center"
    >
      <div
        //input-container
        className="hidden"
      >
        <input
          id={id}
          type="file"
          onInput={(e) => {
            onChange((e.target as HTMLInputElement)?.files?.[0]);
          }}
          accept={acceptingFiles}
        />
      </div>
      {
        // eslint-disable-next-line react/button-has-type
      }
      <Tooltip content={tooltip}>
        <button
          type="button"
          onClick={handleClick}
          disabled={isDisabled}
          className={`${className}`} // hover:bg-sky-700
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onBlur={() => {}}
          onFocus={() => {}}
        >
          {styleType !== 'file' && isHovering && (
            <div className="max-h-150 absolute bottom-0 left-0 z-10 w-full rounded-b-2xl bg-primary-400">
              <Typography size="xs" color="white">
                {message}
              </Typography>
            </div>
          )}
          {children}
        </button>
      </Tooltip>
    </div>
  );
};

export default ProfileInput;
