import type { Routes } from '../../imports/types';

import Layout from './pages/Layout';

type AuditProps = {
  route: Routes;
};
const Audit = ({ route }: AuditProps) => <Layout route={route} />;

export default Audit;
