import { createSlice } from '@reduxjs/toolkit';
import type { SubscriptionState } from '../../imports/types';

const initialState: SubscriptionState = {
  createdAt: 0,
  crm: false,
  currentContractNumber: 0,
  currentNotarizations: 0,
  currentTokenNumber: 0,
  eurWallet: 0,
  expData: 0,
  maxContractsNumber: 0,
  maxNotarizations: 0,
  maxTeamMembers: 0,
  maxTokenNumber: 0,
  ownerId: '',
  ownerUid: '',
  storageLimit: 0,
  storageUsed: 0,
  canNotarize: false,
  canCreateContract: false,
};

export const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        ...payload,
        createdAt:
          payload.createdAt.seconds + Math.ceil(payload.createdAt.nanoseconds / 1000000000),
        canNotarize: payload.maxNotarizations > payload.currentNotarizations,
        canCreateContract: payload.maxContractsNumber > payload.currentContractsNumber,
      };
    },
    logOutSubscription: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setSubscription, logOutSubscription } = operationSlice.actions;
export default operationSlice.reducer;
