import Tippy from '@tippyjs/react';
import { ReactElement, ReactNode } from 'react';
import { animateFill, followCursor } from 'tippy.js';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';

type TooltipProps = {
  content: ReactNode;
  children: ReactElement;
  maxWidth?: number;
};

const Tooltip = ({ content, children, maxWidth = 200 }: TooltipProps) => (
  <Tippy
    content={content}
    maxWidth={maxWidth}
    animateFill
    followCursor
    theme="translucent"
    delay={[300, null]}
    plugins={[animateFill, followCursor]}
  >
    {children}
  </Tippy>
);

export default Tooltip;
