import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/* root imports */

import { IconLoading } from '../../../../icons';

import { FunctionalityCard, Typography } from '../../../../components';

import type { Filters, ProductForm } from '../../../../imports/types';
import { useAppSelector } from '../../../../redux/hooks';

/* tokencreator imports */

type AttributesListProps = {
  attributes: ProductForm[];
  isLoading: boolean;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
};
const AttributesList = ({ attributes, isLoading, filtersState, orderBy }: AttributesListProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const { workspace } = useAppSelector((user) => user.team);

  // attributes = [
  //   {
  //     type: 'Attributes',
  //     id: 'sadasd',
  //     title: 'test',
  //     workspaceId: '',
  //     createdAt: 1695945600000,
  //     attributesList: [
  //       {
  //         name: 'attr. 1',
  //         description: 'descr. Attr 1',
  //       },
  //       {
  //         name: 'attr. 2',
  //         description: 'descr. Attr 2',
  //       },
  //       {
  //         name: 'attr. 3',
  //         description: 'descr. Attr 3',
  //       },
  //     ],
  //     contractsList: [],
  //   },
  // ];
  const handleGoToAttributeDetails = (attributesId: String) => {
    navigate(`attributes/${attributesId}`);
  };

  return !isLoading ? (
    <>
      <Typography size="body-semibold-20">{t('collection.title_attributes')}</Typography>
      <div className="flex flex-wrap gap-5">
        {attributes.length > 0 ? (
          attributes.map((item, index) => {
            return (
              <div className="cursor-pointer" key={`${item.id}-${index}`}>
                <FunctionalityCard
                  functionality={item}
                  key={item.id}
                  hasWritePermission={workspace?.permissions.token_creator.write}
                  onClick={() => handleGoToAttributeDetails(item.id)}
                />
              </div>
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                attributes.length === 0
                  ? 'collection.no_attributes_found'
                  : 'collection.no_filtered_attributes_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default AttributesList;
