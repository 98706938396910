import { SVGProps } from 'react';

const IconFolderNft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M69.975 78H24V48C24 46.4087 24.6321 44.8826 25.7574 43.7574C26.8826 42.6321 28.4087 42 30 42H69.975C71.2724 42.0053 72.5339 42.4259 73.575 43.2L96 60L73.575 76.8C72.5339 77.5741 71.2724 77.9947 69.975 78V78Z"
      stroke="#4F4F4F"
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.362 131V162.02H94.518L87.092 147.92L85.4 144.113H85.306L85.494 150.505V162.02H76V131H87.844L95.27 145.147L96.962 148.907H97.056L96.868 142.515V131H106.362Z"
      fill="#4F4F4F"
    />
    <path
      d="M139.125 139.037H124.367V143.361H136.305V151.398H124.367V162.02H113.416V131H139.125V139.037Z"
      fill="#4F4F4F"
    />
    <path
      d="M173.589 131V139.037H163.907V162.02H152.956V139.037H143.227V131H173.589Z"
      fill="#4F4F4F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90 60C90 56.6863 92.6863 54 96 54H162C165.183 54 168.235 55.2643 170.485 57.5147C172.736 59.7652 174 62.8174 174 66V115H162V66H96C92.6863 66 90 63.3137 90 60ZM60 150V162H30C26.8174 162 23.7652 160.736 21.5147 158.485C19.2643 156.235 18 153.183 18 150V78C18 74.6863 20.6863 72 24 72C27.3137 72 30 74.6863 30 78V150H60Z"
      fill="#4F4F4F"
    />
  </svg>
);
export default IconFolderNft;
