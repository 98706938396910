/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconWebsite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2345_65419)">
      <path
        d="M96 168C135.765 168 168 135.765 168 96C168 56.2355 135.765 24 96 24C56.2355 24 24 56.2355 24 96C24 135.765 56.2355 168 96 168Z"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeMiterlimit={10}
      />
      <path
        d="M28.125 72H163.875"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.125 120H163.875"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96 166.05C112.569 166.05 126 134.688 126 96C126 57.3124 112.569 25.95 96 25.95C79.4315 25.95 66 57.3124 66 96C66 134.688 79.4315 166.05 96 166.05Z"
        stroke={props.stroke || '#4F4F4F'}
        strokeWidth={12}
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="clip0_2345_65419">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconWebsite;
