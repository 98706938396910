import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '../../../../components';
import { FunctionalityType } from '../../imports/types';

type TemplateProps = {
  edit: any;
  setTemplate: Dispatch<SetStateAction<FunctionalityType | null>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  goTo: (page: string) => void;
};

const SelectTemplate = ({ edit, setTemplate, setOpen, goTo }: TemplateProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const [selectedTemplate, setSelectedTemplate] = useState<FunctionalityType | null>(
    edit?.type || null
  );

  const handleTemplate = (value: FunctionalityType | null) => {
    if (value === 'poll' && !edit) {
      setOpen(false);
      goTo('/nft/create-polls');
    } else {
      setTemplate(value);
    }
  };

  return (
    <div className="flex h-full flex-col">
      <Typography color="primary-500" size="body-medium-30" className="pt-8">
        {t('collection.functionality.title')}
      </Typography>
      <div className="my-7 flex flex-wrap gap-4">
        <Button
          id="create-restricted-area"
          type={`${selectedTemplate === 'restricted_area' ? 'primary' : 'secondary'}`}
          action={() => setSelectedTemplate('restricted_area')}
        >
          {t('collection.functionality.restricted')}
        </Button>
        <Button
          id="create-poll"
          type={`${selectedTemplate === 'poll' ? 'primary' : 'secondary'}`}
          action={() => setSelectedTemplate('poll')}
        >
          {t('collection.functionality.poll')}
        </Button>
        <Button
          id="create-shop"
          type={`${selectedTemplate === 'shop' ? 'primary' : 'secondary'}`}
          action={() => setSelectedTemplate('shop')}
        >
          {t('collection.functionality.shop')}
        </Button>
        {/* TODO: uncomment news and product buttons */}
        <Button
          id="create-news"
          type={`${selectedTemplate === 'news' ? 'primary' : 'secondary'}`}
          action={() => setSelectedTemplate('news')}
        >
          {t('collection.functionality.news')}
        </Button>
        <Button
          id="create-product"
          type={`${selectedTemplate === 'product' ? 'primary' : 'secondary'}`}
          action={() => setSelectedTemplate('product')}
        >
          {t('collection.functionality.product')}
        </Button>
      </div>
      <div className="flex w-full flex-row justify-end gap-4 px-6 pb-6 ">
        <Button
          id="select-functionality"
          type="primary"
          disabled={selectedTemplate === null}
          action={() => handleTemplate(selectedTemplate)}
        >
          {t('collection.functionality.select')}
        </Button>
      </div>
    </div>
  );
};

export default SelectTemplate;
