import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { usePrevious } from '../../../../hooks';
import { getContractFromContractId } from '../../../../imports/contractsParsers';
import { useAppSelector } from '../../../../redux/hooks';
import type { Contract } from '../../imports/types';
import Details from '../analytics/Details';

const CollectionItem = () => {
  const navigate = useNavigate();
  const { id: contractId } = useParams();
  const { contractList, workspace, address, loading } = useAppSelector(
    ({ contracts, team, user }) => ({
      contractList: contracts.list,
      workspace: team.workspace,
      address: user.wallet.address,
      loading: contracts.api.fetch.loading,
    })
  );
  const [contract, setContract] = useState<Contract | undefined>(undefined);
  const prevContract = usePrevious(contract);

  useEffect(() => {
    if (contractId && contractList) {
      const fullContract = getContractFromContractId(contractId, contractList);
      if (!prevContract || !isEqual(fullContract, prevContract)) {
        setContract(fullContract);
      }
    }
  }, [contractId, contractList]);

  useEffect(() => {
    if (!contract) return;
    const isTeamMember = contract.workspace_id === workspace?.id;
    if (contract.owner !== address.toLowerCase() && !isTeamMember) {
      navigate('/nft/collection');
    }
  }, [contract]);

  return <Details contract={contract} isLoading={loading} />;
};

export default CollectionItem;
