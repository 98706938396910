import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '..';
import { IconFolderNft } from '../../icons';
import { truncateText } from '../../imports/utils';

type CollectionBottomCardProps = {
  item: any;
};

const CollectionBottomCard = ({ item }: CollectionBottomCardProps) => {
  const { t } = useTranslation(['tokenCreator']);

  return (
    <>
      <div className="flex-column flex gap-2">
        <Icon icon={IconFolderNft} size="lg" />
        <div className="flex flex-col items-start overflow-hidden truncate">
          <Typography size="body-medium-12">
            {truncateText(item.name, {
              maxLength: 20,
              rightChars: 0,
            })}
          </Typography>
          <div className="flex flex-row">
            <div className="flex flex-row">
              <Typography size="body-regular-12" color="grey-400">
                {t('collection.quantity') + ': '}
              </Typography>
              <Typography size="body-regular-12" color="grey-400">
                {item.maxSupplyPerRarity.length - 1 || ''}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CollectionBottomCard;
