import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import contractsSlice from './contracts/contracts.slice';
import functionalitiesSlice from './functionalities/functionalities.slice';
import operationsSlice from './operations/operations.slice';
import pollsSlice from './polls/polls.slice';
import subscriptionSlice from './subscription/subscription.slice';
import teamSlice from './team/team.slice';
import templatesSlice from './templates/templates.slice';
import userSlice from './user/user.slice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
  whitelist: ['contracts', 'user', 'team', 'functionalities', 'subscription', 'template'],
};

const rootReducer = combineReducers({
  user: userSlice,
  operations: operationsSlice,
  team: teamSlice,
  contracts: contractsSlice,
  polls: pollsSlice,
  functionalities: functionalitiesSlice,
  subscription: subscriptionSlice,
  template: templatesSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      /* serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }, */
      serializableCheck: false,
    }),
});

//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store;
}

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
