import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Template } from '../../modules/tokenCreator/imports/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOutTemplate, setTemplateValue } from '../../redux/templates/templates.slice';
import TemplateCard from '../cards/TemplateCard';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';
import i18n from '../../i18n';

type TemplateModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { templates, goTo } = useModalProps('TemplateModal');
  const { t } = useTranslation(['tokenCreator']);
  const dispatch = useAppDispatch();
  const { workspace } = useAppSelector((state) => state.team);
  const standardTemplate = templates?.find(
    (template: { name: string }) => template.name === 'standard'
  );

  const hasStandardTemplate = workspace?.templatePack?.includes('standard');
  const hasCustomTemplate =
    workspace?.templatePack &&
    workspace.templatePack.length > 0 &&
    workspace.templatePack.some((template: string) => template !== 'standard');

  const allTemplate: any = {
    type: 'standard',
    owner: '0x00..',
    id: 'az09',
    name: 'all',
    image:
      'https://firebasestorage.googleapis.com/v0/b/databox-dev-2a26f.appspot.com/o/templates%2FcustomTemplateImg.jpg?alt=media&token=25d5babd-c0b3-464c-b918-52da794188f7',
    icon: 'string',
    active: true,
    pack: '',
  };

  const selectTemplate = (value: boolean) => {
    setOpen(false);
    if (value) {
      dispatch(logOutTemplate());
    } else {
      dispatch(setTemplateValue(value));
    }
    goTo('/nft/create-contract');
  };

  return (
    <ModalLayout
      classNameLayout=""
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="my-5 flex flex-col gap-7 ">
        <Typography className="px-5" color="primary-500" size="body-medium-30">
          {t('create_contract.form_labels.modal_title')}
        </Typography>
        <div className="space-between m-5 flex min-h-[300px] flex-row justify-center gap-10 ">
          <div className="m-5 flex h-full w-1/2 flex-col items-center justify-between gap-7">
            <TemplateCard
              isSelected={false}
              template={standardTemplate}
              onClick={() => selectTemplate(true)}
              disabled={!hasStandardTemplate}
            />
          </div>
          {/* <div className="h-full min-h-[300px] border-l-[1px] border-solid border-l-black"></div> */}
          <div className="m-5 flex h-full w-1/2 flex-col items-center justify-between gap-7">
            <TemplateCard
              isSelected={false}
              template={allTemplate}
              onClick={() => selectTemplate(false)}
              disabled={!hasCustomTemplate}
            />
            <div>
              <a
                href="https://bcode.cloud/lista-template/"
                className="text-blue underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  size="body-regular-14"
                  className="overflow-hidden break-words text-center "
                >
                  {t('create_contract.form_labels.seeCustomTemplate')}
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

const TemplateModal = ({ children }: TemplateModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="TemplateModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default TemplateModal;
