import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';

import { initializeFirestore } from 'firebase/firestore';
import { firebaseConfig as firebaseConfigEnvs } from './constants';

const firebaseConfig = firebaseConfigEnvs;

const app = initializeApp(firebaseConfig);
const messagesApp = initializeApp(
  { ...firebaseConfig, databaseURL: process.env.REACT_APP_RTDB_FIREBASE_MESSAGE },
  'messagesApp'
);

export const auth = getAuth(app);
export const db = initializeFirestore(app, { experimentalForceLongPolling: true });
export const database = getDatabase(app);
export const dbMessages = getDatabase(messagesApp);
export const storage = getStorage(app);
export const remoteConfig = getRemoteConfig(app);

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA as string),

//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });
