import React, { ReactNode } from 'react';
import { IconInfo } from '../../icons';
import Icon from '../icon/Icon';
import Tooltip from './Tooltip';

interface ICustomTooltip {
  children: ReactNode;
}
const CustomTooltip: React.FC<ICustomTooltip> = ({ children }) => (
  <Tooltip content={children} maxWidth={300}>
    <div className="ml-2 cursor-help p-0.5">
      <Icon icon={IconInfo} size="16" stroke="primary-500" />
    </div>
  </Tooltip>
);

export default CustomTooltip;
