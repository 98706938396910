import { cloneElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '..';

type CardProps = {
  dataCy?: string;
  title: string;
  children: ReactNode | ReactNode[];
  image?: string;
  imageAlt?: string;
  action?: ReactNode;
  onClick?: () => void;
  className?: string;
};

const Card = ({
  dataCy,
  title,
  children,
  image,
  imageAlt,
  action,
  onClick,
  className,
}: CardProps) => {
  const { t } = useTranslation();

  const isClickable = !!onClick;
  const isChildrenArray = Array.isArray(children);

  return (
    <div
      data-cy={dataCy}
      className={`flex h-[50px] w-[240px] items-center justify-between gap-1 space-x-1 rounded-[10px] border border-grey-400 p-2 ${
        isClickable ? 'cursor-pointer' : ''
      } ${className}`}
      onClick={onClick}
    >
      {image && imageAlt && (
        // <Image src={image} alt={imageAlt} className="h-[25px] w-[25px] shrink-0" />
        <div className="flex h-[25px] w-[25px] items-center justify-center rounded-lg bg-primary-500">
          {/* <Image src={image} alt={name || t('avatar')} type="cover" /> */}
          {/* <img src={image} alt={imageAlt} className="object-fill" /> */}
          <Typography color="white" size="body-regular-10">
            {image}
          </Typography>
        </div>
      )}

      <div
        className="flex grow flex-col justify-center overflow-hidden"
        role={isClickable ? 'presentation' : ''}
        aria-hidden="true"
      >
        {title && (
          <Typography size="body-medium-12" weight="medium" className="truncate">
            {title}
          </Typography>
        )}

        <div className="flex items-center space-x-4 ">
          {isChildrenArray
            ? children.map((child: any, index) => {
                const hasProps = !!child.props;

                return hasProps
                  ? cloneElement(child, {
                      key: index,
                      className: `${child.props.className} truncate`,
                    })
                  : child;
              })
            : children}
        </div>
      </div>

      {action && (
        <div className="flex h-fit w-fit shrink-0 items-center justify-center">{action}</div>
      )}
    </div>
  );
};
export default Card;
