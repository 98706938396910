import type { Routes } from '../../../imports/types';

import { JoinTeam, ManageTeams } from '../pages';

const routes: Routes[] = [
  {
    name: 'manage_teams',
    path: '/',
    component: ManageTeams,
    // hasSidebar: true,
    isHome: true,
    restrictedTo: ['consumer'],
  },
  {
    name: 'join_team',
    path: '/join-team',
    component: JoinTeam,
  },
];

export default routes;
