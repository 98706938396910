import { SVGProps } from 'react';

const IconFileConfirmed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1864_45240)">
      <path
        d="M126 168H150C151.591 168 153.117 167.368 154.243 166.243C155.368 165.117 156 163.591 156 162V66L114 24H42C40.4087 24 38.8826 24.6321 37.7574 25.7574C36.6321 26.8826 36 28.4087 36 30V96"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 24V66H156"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_1864_45240)">
      <path
        d="M124 107L78.6667 152.333L56 129.667"
        stroke="#4F4F4F"
        strokeWidth={13}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1864_45240">
        <rect width={192} height={192} fill="white" />
      </clipPath>
      <clipPath id="clip1_1864_45240">
        <rect width={131} height={131} fill="white" transform="translate(25 60)" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFileConfirmed;
