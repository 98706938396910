import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
/* root imports */
import { Breadcrumbs, Button, Icon, InnerPage, NftCard, Typography } from '../../../../components';
import { IconArrow } from '../../../../icons';
import { useAppSelector } from '../../../../redux/hooks';
/* tokenCreator imports */
import 'swiper/css';
import 'swiper/css/navigation';
import { getContractFromContractId } from '../../../../imports/contractsParsers';
import { formatDate } from '../../../../imports/utils';
import ClickAnalyticsComponent from '../../components/ClickAnalyticsComponent/ClickAnalyticsComponent';
import type { Contract, Nft } from '../../imports/types';

const CollectionAnalytics = () => {
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { contractList } = useAppSelector(({ contracts }) => ({
    contractList: contracts.list,
  }));
  const [nfts, setNfts] = useState<Nft[]>();
  const [contract, setContract] = useState<Contract | undefined>(undefined);

  useEffect(() => {
    if (contractId) {
      const fullContract = getContractFromContractId(contractId, contractList);
      setContract(fullContract);
      if (fullContract?.nfts) {
        setNfts([...fullContract.nfts]);
      }
    }
  }, []);

  const shouldFetch = (): boolean => {
    if (!contract) {
      return false;
    }
    if (contract.nfts.length === 1 && contract.nfts[0].quantity === 1) {
      //singola categoria con singolo token con quantitá 1
      return false;
    }
    if (contract.nfts.length === 1 && contract.nfts[0].quantity > 1) {
      //singola categoria con singolo token con quantitá > 1
      return true;
    }
    if (contract.nfts.length > 1) {
      //piú categorie
      return true;
    }
    return false;
  };

  const shouldFetchValue = shouldFetch();
  return (
    <InnerPage>
      {!contract ? (
        <div className="mx-auto flex max-w-sm grow flex-col items-center justify-center">
          <Typography as="h2" size="xl" className="text-center">
            {t('collection_item.contract_not_found_title')}
          </Typography>

          <Button action={() => navigate('/nft/analytics')} className="mt-10">
            {t('collection_item.contract_not_found_action')}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-8 ">
          <div className="flex">
            <Icon
              icon={IconArrow}
              onClick={() => navigate(-1)}
              size="40"
              stroke="primary-500"
              className="mb-3 cursor-pointer rounded-md"
            />
          </div>
          <div className="flex flex-col " id="header">
            <Breadcrumbs />
            <div className="flex flex-col gap-4">
              <Typography size="body-medium-30">{contract?.name}</Typography>
              {contract?.owner && (
                <div className="flex gap-8">
                  <Typography size="body-regular-16">
                    Address: <span className="text-primary-500">{contract.owner}</span>
                  </Typography>
                  <Typography size="body-regular-16">
                    {t('created_at')}:{' '}
                    <span className="text-primary-500">{formatDate(contract.createdAt)}</span>
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <ClickAnalyticsComponent
            shouldFetch={shouldFetchValue}
            type="contract"
            contractId={contractId}
          />
          <div className="mt-4 flex flex-col gap-4" id="nfts">
            <Typography size="body-regular-20">{t('collection_item.nfts')}</Typography>
            <div className="grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-5">
              {contract.nfts &&
                nfts &&
                nfts
                  .sort((a, b) => a.id - b.id)
                  .map((nft) => {
                    return (
                      <NftCard
                        contract={contract}
                        item={nft}
                        disabled={contract?.status !== 'created'}
                        key={nft.id}
                        isAnalytics
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </InnerPage>
  );
};

export default CollectionAnalytics;
