import { Dispatch, FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/* root imports */
import { Button, Icon, Image, Input, OrderBySelect, Typography } from '..';
import { ImageArrowBack } from '../../assets/images';
import { IconClose, IconResearch, IconTag } from '../../icons';
import type { Filters, FiltersActionType, Folder } from '../../imports/types';

type CollectionHeaderProps = {
  orderByOptions: {
    value: string;
    label: string;
    disabled?: boolean;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[];
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  filtersDispatch: Dispatch<FiltersActionType>;
  folder?: Folder;
  isFolder?: boolean;
  type?: 'notarizations' | 'tokenCreator';
  handleSetOrderBy: (value: string) => void;
  resetFilterField: (filterFieldName: FiltersActionType['filterFieldName']) => void;
};
const CollectionHeader = ({
  orderByOptions,
  filtersState,
  filtersDispatch,
  folder,
  isFolder,
  type,
  handleSetOrderBy,
  resetFilterField,
}: CollectionHeaderProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();

  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;

  const navigateTo = () => {
    if (type === 'notarizations') {
      navigate('/notarizations');
    }
    if (type === 'tokenCreator') {
      navigate('/nft');
    }
  };

  return (
    <header className="flex flex-col items-center space-y-12">
      {isFolder && (
        <div className="flex w-full items-center justify-between">
          <div className="shrink-0">
            <Button type="ghost" action={() => navigateTo()}>
              <Image src={ImageArrowBack} alt={t('back')} className="w-8" />
            </Button>
          </div>

          {folder && (
            <div className="grow text-center">
              <Typography weight="medium" size="lg">
                {folder.name}
              </Typography>
            </div>
          )}
        </div>
      )}
      {/* <div className="grid grid-cols-8 gap-x-4"> */}
      <div className="flex w-full items-start justify-start gap-x-4">
        <div className="ml-0">
          <div className="w-[260px]  ">
            <Input
              type="text"
              value={inputFilterByName.value}
              placeholder={t('collection.filters.name')}
              onChange={(event) =>
                filtersDispatch({
                  type: 'SET_STATE',
                  filterFieldName: 'inputFilterByName',
                  payload: event.target.value,
                })
              }
              elementLeft={<Icon icon={IconResearch} stroke="grey-200" />}
              elementRight={
                inputFilterByName.value && (
                  <Button
                    type="ghost"
                    icon={IconClose}
                    action={() => resetFilterField('inputFilterByName')}
                  />
                )
              }
              className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-200"
            />
          </div>
        </div>
        <div className="w-[260px]">
          <Input
            type="text"
            value={inputFilterByTag.value}
            placeholder={t('collection.filters.tag')}
            onChange={(event) =>
              filtersDispatch({
                type: 'SET_STATE',
                filterFieldName: 'inputFilterByTag',
                payload: event.target.value,
              })
            }
            elementLeft={<Icon icon={IconTag} />}
            elementRight={
              inputFilterByTag.value && (
                <Button
                  type="ghost"
                  icon={IconClose}
                  action={() => resetFilterField('inputFilterByTag')}
                />
              )
            }
            className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-200"
          />
        </div>
        <Input
          type="date"
          value={inputFilterByDate.value}
          placeholder={t('collection.filters.date')}
          onChange={(event) =>
            filtersDispatch({
              type: 'SET_STATE',
              filterFieldName: 'inputFilterByDate',
              payload: event.target.value,
            })
          }
          className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-400"
        />

        <div className="col-span-2">
          <OrderBySelect
            items={orderByOptions}
            onChange={(value) => {
              handleSetOrderBy(value);
            }}
          />
        </div>
      </div>
    </header>
  );
};

export default CollectionHeader;
