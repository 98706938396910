import getUnixTime from 'date-fns/getUnixTime';
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { NFTStorage } from 'nft.storage';

import api from '../../../imports/axios';
import { auth, db } from '../../../imports/firebase';
import { client } from '../../../imports/utils';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const createNotarization = async (data: FormData, authTokenId: string) => {
  try {
    const response = await api.post('/notarize', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: authTokenId,
      },
    });

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

export const deleteNotarization = async (
  data: { id: string; onlyContent: boolean },
  authTokenId: string
) => {
  try {
    const response = await api.delete('/notarization', {
      data,
      headers: {
        authorization: authTokenId,
      },
    });

    return result({ value: response });
  } catch (error) {
    return result({ error });
  }
};

export const updateNotarization = async (id: string, data: any) => {
  try {
    const docRef = doc(db, 'notarizations', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const docData = docSnap.data();
      await setDoc(docRef, { ...docData, ...data }, { merge: true });
    }

    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const getNotarization = async (id: string) => {
  try {
    const docRef = doc(db, 'notarizations', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return result({ value: data });
    }
    return result({ value: null });
  } catch (error) {
    return result({ error });
  }
};

export const getFileFromStorage = async (fileUrl: string, authTokenId: string) => {
  try {
    const response = await api.get(`content/${fileUrl}`, {
      headers: {
        authorization: authTokenId,
      },
    });

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

export const deleteFileFromStorage = async (fileUrl: string, authTokenId: string) => {
  try {
    return result({ value: 'delete' });
  } catch (error) {
    return result({ error });
  }
};

export const uploadFileToIpfs = async (image: File) => {
  const authToken = await auth.currentUser?.getIdToken();

  try {
    const { did, token } = await client('getUcan', {
      body: {
        token: authToken,
      },
    });

    const storageClient = new NFTStorage({ token, did });
    const metadata = await storageClient.storeBlob(image);

    return result({ value: metadata });
  } catch (error) {
    return result({ error });
  }
};

export const createFolder = async (uid: string, name: string, workspaceId: string) => {
  const timestamp = getUnixTime(Date.now());
  const folderId = `${name.replace(/\s/g, '')}${timestamp}`;

  await setDoc(doc(db, 'folders', folderId), {
    id: folderId,
    name,
    userId: uid,
    type: 'notarization',
    notarizationsId: [],
    createdAt: timestamp,
    workspace_id: workspaceId,
  });
};

export const editFolder = async (folderId: string, name: string) => {
  const docRef = doc(db, 'folders', folderId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(docRef, { ...data, name });
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const deleteFolder = async (folderId: string, notarizationsId: string[]) => {
  try {
    await Promise.all(
      notarizationsId.map(async (notarizationId) => {
        const docRef = doc(db, 'notarizations', notarizationId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          await setDoc(docRef, { ...data, folderId: null });
        }
      })
    );

    await deleteDoc(doc(db, 'folders', folderId));

    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const addNotarizationToFolder = async (folderId: string, notarizationId: string) => {
  const docRef = doc(db, 'folders', folderId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      const notarizationsId = data?.notarizationsId;

      if (notarizationsId) {
        notarizationsId.push(notarizationId);
        await setDoc(docRef, { ...data, notarizationsId });
      }
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const removeNotarizationFromFolder = async (folderId: string, notarizationId: string) => {
  const docRefFolder = doc(db, 'folders', folderId);
  const docSnapFolder = await getDoc(docRefFolder);

  const docRefNotarization = doc(db, 'notarizations', notarizationId);
  const docSnapNotarization = await getDoc(docRefNotarization);

  try {
    if (docSnapFolder.exists()) {
      const data = docSnapFolder.data();
      const notarizationsId = data?.notarizationsId;

      if (notarizationsId) {
        const index = notarizationsId.indexOf(notarizationId);
        notarizationsId.splice(index, 1);

        await setDoc(docRefFolder, { ...data, notarizationsId });

        if (docSnapNotarization.exists()) {
          const data = docSnapNotarization.data();
          await setDoc(docRefNotarization, { ...data, folderId: null });
        }
      }
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const addFolderToNotarization = async (folderId: string, notarizationId: string) => {
  const docRef = doc(db, 'notarizations', notarizationId);
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(docRef, { ...data, folderId });
    }
    return result({ value: true });
  } catch (error) {
    return result({ error });
  }
};

export const addNotarizationFolder = async (folderId: string, notarizationId: string) => {
  await addFolderToNotarization(folderId, notarizationId)
    .then(async () => {
      await addNotarizationToFolder(folderId, notarizationId)
        .then(() => result({ value: true }))
        .catch((error) => result({ error }));
    })
    .catch((error) => result({ error }));
};

export const replaceNotarizationFolder = async (
  currentFolderId: string,
  newFolderId: string,
  notarizationId: string
) => {
  await removeNotarizationFromFolder(currentFolderId, notarizationId)
    .then(async () => {
      await addFolderToNotarization(newFolderId, notarizationId)
        .then(async () => {
          await addNotarizationToFolder(newFolderId, notarizationId)
            .then(() => result({ value: true }))
            .catch((error) => result({ error }));
        })
        .catch((error) => result({ error }));
    })
    .catch((error) => result({ error }));
};
