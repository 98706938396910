import { QueryKey, useQuery } from 'react-query';
import {
  getClickAnalyticsByCompany,
  getClickAnalyticsByCompanyAndProject,
  getClickAnalyticsByCompanyAndProjectAndToken,
} from '../modules/tokenCreator/api/analytics';

import { AnalyticsParameters } from '../modules/tokenCreator/imports/types';

type QueryParameters = AnalyticsParameters & {
  shouldFetch?: boolean;
  type: 'nft' | 'company' | 'contract';
};

const useAnalyticsQuery = ({
  companyId,
  contractId,
  nftId,
  shouldFetch,
  type,
  dateRange,
}: QueryParameters) => {
  const analyticsParams: AnalyticsParameters = {
    companyId,
    contractId,
    nftId,
    dateRange,
  };

  const queryKey: QueryKey = [type, contractId, nftId];

  const fetchFunctionsMap = {
    nft: () => getClickAnalyticsByCompanyAndProjectAndToken(analyticsParams),
    company: () => getClickAnalyticsByCompany(analyticsParams),
    contract: () => getClickAnalyticsByCompanyAndProject(analyticsParams),
  };

  const { data, error, isLoading, refetch, isFetching } = useQuery(
    queryKey,
    () => fetchFunctionsMap[type](),
    { enabled: shouldFetch, cacheTime: 0, refetchOnMount: 'always' }
  );

  return { data, error, isLoading, refetch, isFetching };
};

export default useAnalyticsQuery;
