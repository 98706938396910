import { SVGProps } from 'react';

const IconChartPie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2385_68225)">
      <path
        d="M96 96V24"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.326 60L33.6758 132"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2007 109.2C24.3822 104.848 23.9804 100.428 24.0007 96C23.9913 81.1094 28.6036 66.583 37.2013 54.4253C45.799 42.2677 57.9581 33.0782 72.0007 28.125V82.125L25.2007 109.2Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.9984 24C108.615 24.0014 121.009 27.3179 131.94 33.6172C142.871 39.9165 151.955 48.9775 158.282 59.8926C164.609 70.8077 167.957 83.1936 167.99 95.8098C168.023 108.426 164.741 120.829 158.472 131.778C152.203 142.726 143.167 151.835 132.269 158.192C121.372 164.549 108.995 167.931 96.3788 167.999C83.7627 168.067 71.3503 164.819 60.3847 158.58C49.4191 152.341 40.2854 143.33 33.8984 132.45"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2385_68225">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconChartPie;
