import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// import { Form } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';

import { useLoadingStatusContext } from '../../context';
import { form } from '../../modules/team/common/add-user-form';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import TeamRoleSelect from '../selectDropdown/TeamRoleSelect';
import Typography from '../typography/Typography';
import Checkbox from '../checkbox/Checkbox';

type AddUserModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { t } = useTranslation(['team']);
  const { state } = useLoadingStatusContext();
  const { handleAddUser } = useModalProps('AddUserModal');
  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mt-[20px] mb-16 flex flex-col justify-start gap-[16px] px-[50px] pt-[60px]">
        <section className="flex flex-col items-start h-full ">
          <Typography
            weight="medium"
            size="body-medium-30"
            color="primary-500"
            className="mb-[10px]"
          >
            {t('manage.add_user.title')}
          </Typography>
          <Form
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            className="w-full h-full py-5"
          >
            {({ fields, handleSubmit, errors, setValue, resetField, watch, isValid }) => {
              const { role } = watch();

              return (
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col w-full">
                    <Field error={errors.name?.message} label={t('manage.add_user.name')}>
                      <Input
                        placeholder={t('manage.add_user.name-placeholder')}
                        name={fields.name.name}
                        id={fields.name.name}
                        onChange={fields.name.onChange}
                        onBlur={fields.name.onBlur}
                        inputRef={fields.name.ref}
                        className="mt-[5px] "
                      />
                    </Field>
                    <Field
                      error={errors.email?.message}
                      label={t('manage.add_user.email')}
                      className="mt-[10px]"
                    >
                      <Input
                        placeholder={t('manage.add_user.email-placeholder')}
                        name={fields.email.name}
                        id={fields.email.name}
                        onChange={fields.email.onChange}
                        onBlur={fields.email.onBlur}
                        inputRef={fields.email.ref}
                        className="mt-[5px]"
                      />
                    </Field>
                    <Typography weight="medium" size="body-medium-18" className="mt-5 mb-1">
                      {t('manage.permissions')}
                    </Typography>
                    {/* TOKEN_CREATOR */}
                    <div className="flex justify-between gap-6">
                      <Field label={t('manage.add_user.role.token_creator')} className="w-full">
                        <TeamRoleSelect
                          onChange={(selection: string) => {
                            if (selection === 'viewer') {
                              setValue('role.token_creator.write', false);
                              setValue('role.token_creator.read', true);
                            }
                            if (selection === 'editor') {
                              setValue('role.token_creator.write', true);
                              setValue('role.token_creator.read', true);
                            }
                            if (selection === 'no_access') {
                              setValue('role.token_creator.write', false);
                              setValue('role.token_creator.read', false);
                            }
                          }}
                          className="mt-[5px] mb-1 "
                        />
                      </Field>

                      {/* NOTARIZATION */}
                      <Field label={t('manage.add_user.role.notarization')}>
                        <TeamRoleSelect
                          onChange={(selection: string) => {
                            if (selection === 'viewer') {
                              setValue('role.notarization.write', false);
                              setValue('role.notarization.read', true);
                            }
                            if (selection === 'editor') {
                              setValue('role.notarization.write', true);
                              setValue('role.notarization.read', true);
                            }
                            if (selection === 'no_access') {
                              setValue('role.notarization.write', false);
                              setValue('role.notarization.read', false);
                            }
                          }}
                          className="mt-[5px] mb-1"
                        />
                      </Field>

                      {/* AUDIT */}
                      <Field label={t('manage.add_user.role.audit')}>
                        <TeamRoleSelect
                          onChange={(selection: string) => {
                            if (selection === 'viewer') {
                              setValue('role.audit.write', false);
                              setValue('role.audit.read', true);
                            }
                            if (selection === 'editor') {
                              setValue('role.audit.write', true);
                              setValue('role.audit.read', true);
                            }
                            if (selection === 'no_access') {
                              setValue('role.audit.write', false);
                              setValue('role.audit.read', false);
                            }
                          }}
                          className="mt-[5px] "
                        />
                      </Field>
                    </div>
                  </div>

                  <div className="mt-7 flex items-end justify-end space-y-[25px]">
                    <Button
                      disabled={
                        !isValid ||
                        state.status === 'pending' ||
                        !Object.keys(role).some((module) => role[module].write || role[module].read)
                      }
                      action={() => {
                        handleSubmit(handleAddUser)().finally(() => {});
                      }}
                    >
                      {t('manage.add_user.title')}
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>
        </section>
      </div>
    </ModalLayout>
  );
};

const AddUserModal = ({ children }: AddUserModalProps) => {
  const [isOpen, setOpen] = useModalState();
  const { t } = useTranslation();
  return (
    <Modal id="AddUserModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default AddUserModal;
