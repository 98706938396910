import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useModal } from 'react-simple-modal-provider';
import { useCopyToClipboard } from '../../hooks';
import { db } from '../../imports/firebase';
import { useAppSelector } from '../../redux/hooks';

import { Button, Card, InnerPage, Tooltip, Typography } from '../../components';
import { IconCopy, IconLoading, IconTrash } from '../../icons';

const Api = () => {
  const { t } = useTranslation();
  const { uid } = useAppSelector((state) => state.user);
  const [stateCopyToClipboard, copyToClipboard] = useCopyToClipboard();
  const [tokenList, setTokenList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { open } = useModal('ApiModal');

  const showToast = (message: string, success?: boolean) => {
    if (success) {
      toast.success(t(`api.success.${message}`) as string);
      return;
    }
    toast.error(t(`api.error.${message}`) as string);
  };

  const handleCopyToClipboard = (token: string) => {
    if (stateCopyToClipboard.value !== token) {
      copyToClipboard(token);
      showToast('api_key_copied', true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribeApi = onSnapshot(doc(db, 'users', uid), (doc) => {
      const data = doc.data();
      setTokenList(data!.authTokensCollections);
      setIsLoading(false);
    });

    return () => {
      unsubscribeApi();
    };
  }, []);

  return (
    <>
      <InnerPage>
        {isLoading ? (
          <div className="flex h-screen grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between pb-[60px] pt-[30px]">
              <Typography size="body-medium-30" color="black">
                {t('api.page_title')}
              </Typography>

              <Button action={() => open({ type: 'create' })} className="!w-auto">
                {t('api.create_api_key')}
              </Button>
            </div>
            <div className="flex flex-wrap gap-7">
              {tokenList?.map((token, index) => (
                <Card
                  key={index}
                  title={token.name}
                  action={
                    <div className="flex space-x-2">
                      <Tooltip content={t('copy')}>
                        <Button
                          type="ghost"
                          action={() => {
                            handleCopyToClipboard(token.token);
                          }}
                          icon={IconCopy}
                        ></Button>
                      </Tooltip>
                      <Tooltip content={t('delete')}>
                        <Button
                          type="ghost"
                          icon={IconTrash}
                          action={() => open({ type: 'delete', token })}
                        ></Button>
                      </Tooltip>
                    </div>
                  }
                >
                  <Typography size="body-regular-10" className="truncate">
                    {token.token}
                  </Typography>
                </Card>
              ))}
            </div>
          </>
        )}
      </InnerPage>
    </>
  );
};

export default Api;
