/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96 120C122.51 120 144 98.5097 144 72C144 45.4903 122.51 24 96 24C69.4903 24 48 45.4903 48 72C48 98.5097 69.4903 120 96 120Z"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeMiterlimit={10}
    />
    <path
      d="M23.25 162C30.622 149.229 41.2262 138.623 53.9967 131.249C66.7671 123.875 81.2536 119.993 96 119.993C110.746 119.993 125.233 123.875 138.003 131.249C150.774 138.623 161.378 149.229 168.75 162"
      stroke={props.stroke ? props.stroke : '#4F4F4F'}
      strokeWidth={12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconUser;
