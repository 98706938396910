import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Field,
  FileUploader,
  Input,
  Select,
  TagSelect,
  TextareaQuill,
  Typography,
} from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import CustomTooltip from '../../../../components/tooltips/CustomTooltip';
import { IconClose } from '../../../../icons';
import { formFieldsWithMultipleOptions } from '../../../../imports/constants';
import { getRemoteConfigValue } from '../../../../imports/remoteConfig';
import { capitalizeFirstLetter } from '../../../../imports/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Template } from '../../imports/types';
import { isFileTypeImage } from '../../imports/utils';
import i18n from '../../../../i18n';

interface IGeneralStep
  extends Omit<
    ExportedProps,
    'trigger' | 'register' | 'clearErrors' | 'getValues' | 'isValid' | 'reset'
  > {
  isLoading: boolean;
  contractsLeft: number;
  values: any;
  templates: Template[];
  onBack: () => void;
  onCompleted: () => void;
}

const GeneralStep = ({
  fields,
  contractsLeft,
  errors,
  handleSubmit,
  values,
  resetField,
  templates,
  setValue,
  dirtyFields,
  isLoading,
  onBack,
  watch,
  onCompleted,
}: IGeneralStep) => {
  const { t } = useTranslation(['tokenCreator']);
  const navigate = useNavigate();
  const [isImageValid, setIsImageValid] = useState<boolean>(true);
  const { isStandardTemplate } = useAppSelector((state) => state.template);
  const language = i18n.language;
  /* TEMPLATE TASK */
  const template = useAppSelector((state) => state.template);
  const { tag, contractImage } = values;
  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   console.log('aa');
  //   const options: { value: string; label: any }[] = [];
  //   template.dropDowns.customFields.forEach((option) =>
  //     options.push({ value: option.value, label: option.label[i18n.language] })
  //   );
  //   console.log('options', options);
  //   dispatch(updateTranslatedDropdown({ field: 'customFields', value: options }));
  // }, []);
  // console.log(template.translatedDropDowns);
  const tagList = () => {
    if (template.dropDowns?.tags && template.dropDowns?.tags?.length > 0) {
      const templateTags = template.dropDowns?.tags?.map(
        (tag: { label: { [key: string]: string }; value: string }) => {
          return { label: tag.label[language], value: tag.value };
        }
      );
      return templateTags;
    }
    return getRemoteConfigValue('tags', 'json').map((tag: string) => {
      return {
        value: tag === 'none' ? '' : tag.toLowerCase(),
        label: t([
          `create_contract.form_labels.${tag.toLowerCase()}`,
          capitalizeFirstLetter(tag.toLocaleLowerCase()),
        ]),
      };
    });
  };

  const handleBack = () => {
    if (isStandardTemplate) {
      navigate(`/nft`);
    } else {
      onBack();
    }
  };

  /* TODO: trovare una soluzione migliore per aggiornamento values alla selezione del template */

  return (
    <>
      <div className="space-y-12 ">
        <Typography weight="semibold">{t('create_contract.titles.general')}</Typography>
        <div className="grid grid-cols-2 gap-y-10 gap-x-12">
          {!template.fields?.contractImage?.hidden && (
            <Field className="row-span-2 " error={errors.contractImage?.message}>
              <FileUploader
                ratio="16:9"
                id="contractCover"
                defaultFiles={contractImage}
                accept={{ 'image/*': allowedFileType['image/*'] }}
                name={fields.contractImage.name}
                label={
                  template.fields?.contractImage?.label?.[language] ||
                  t('create_contract.form_labels.contract_image')
                }
                onChange={(files) => {
                  if (files.length === 0) {
                    setValue('contractImage', []);
                  }
                  files.forEach((file) => {
                    if (isFileTypeImage(file)) {
                      setValue('contractImage', files);
                      setIsImageValid(true);
                    } else {
                      setValue('contractImage', []);
                      setIsImageValid(false);
                      toast.error(`${t('create_contract.errors.file_format')}`);
                    }
                  });
                }}
                maxSize={5 * 1000000}
              />
            </Field>
          )}
          {!template.fields?.owner?.hidden && (
            <Field
              label={
                template.fields?.owner?.label?.[language] || t('create_contract.form_labels.owner')
              }
              labelElementRight={
                <CustomTooltip>
                  <Typography size="xs">
                    {t('create_contract.form_tooltips.owner') as string}
                  </Typography>
                </CustomTooltip>
              }
              error={errors.owner?.message}
            >
              <Input
                type="text"
                placeholder={t('create_contract.form_placeholders.owner')}
                name={fields.owner.name}
                disabled
                onBlur={fields.owner.onBlur}
                onChange={fields.owner.onChange}
                inputRef={fields.owner.ref}
                error={errors.owner?.message}
              />
            </Field>
          )}
          {!template.fields?.chain?.hidden && (
            <Field
              label={
                template.fields?.chain?.label?.[language] || t('create_contract.form_labels.chain')
              }
              labelElementRight={
                <CustomTooltip>
                  <Typography size="xs">
                    {t('create_contract.form_tooltips.chain') as string}
                  </Typography>
                </CustomTooltip>
              }
              error={errors.chain?.message}
            >
              <Select
                options={formFieldsWithMultipleOptions['create-contract'].chain.map(
                  (chain, index) => ({
                    value: chain,
                    label: t(`create_contract.form_select_fields_options_label.chain.${index}`),
                  })
                )}
                name={fields.chain.name}
                onBlur={fields.chain.onBlur}
                onChange={fields.chain.onChange}
                inputRef={fields.chain.ref}
                error={errors.chain?.message}
                disabled
              />
            </Field>
          )}

          {!template.fields?.name?.hidden && (
            <Field
              label={
                template.fields?.name?.label?.[language] || t('create_contract.form_labels.name')
              }
              labelElementRight={
                <CustomTooltip>
                  <Typography size="xs">
                    {t('create_contract.form_tooltips.name') as string}
                  </Typography>
                </CustomTooltip>
              }
              error={errors.name?.message}
            >
              <Input
                type="text"
                placeholder={t('create_contract.form_placeholders.name')}
                name={fields.name.name}
                onBlur={fields.name.onBlur}
                onChange={fields.name.onChange}
                inputRef={fields.name.ref}
                error={errors.name?.message}
                elementRight={
                  values.name && (
                    <Button
                      type="ghost"
                      icon={IconClose}
                      action={() => resetField(fields.name.name)}
                    />
                  )
                }
              />
            </Field>
          )}

          {!template.fields?.type?.hidden && (
            <Field
              label={
                template.fields?.type?.label?.[language] || t('create_contract.form_labels.type')
              }
              labelElementRight={
                <CustomTooltip>
                  <Typography size="xs">
                    {t('create_contract.form_tooltips.type') as string}
                  </Typography>
                </CustomTooltip>
              }
              error={errors.type?.message}
            >
              <Input
                type="text"
                name={fields.type.name}
                onBlur={fields.type.onBlur}
                onChange={fields.type.onChange}
                inputRef={fields.type.ref}
                error={errors.type?.message}
                disabled
              />
            </Field>
          )}
          {!template.fields?.description?.hidden && (
            <Field
              className="row-span-2"
              label={
                template.fields?.description?.label?.[language] ||
                t('create_contract.form_labels.description')
              }
              labelElementRight={
                <CustomTooltip>
                  <Typography size="xs">
                    {t('create_contract.form_tooltips.description') as string}
                  </Typography>
                </CustomTooltip>
              }
              error={errors.description?.message}
            >
              <TextareaQuill
                setValue={setValue}
                field={fields.description.name}
                value={values.description}
              />
            </Field>
          )}
          {!template.fields?.tag?.hidden && (
            <Field
              label={
                template.fields?.tag?.label?.[language] || t('create_contract.form_labels.tag')
              }
              error={errors.tag?.message}
            >
              <TagSelect
                disabled={template.fields?.tag?.disabled}
                inputRef={fields.tag.ref}
                type="text"
                tagList={tagList()}
                placeholder={t('create_contract.form_placeholders.select_tag')}
                id={fields.tag.name}
                name={fields.tag.name}
                onTagsChange={(allTags: any) => setValue(fields.tag.name, allTags)}
                value={tag}
                error={errors.tag?.message}
                elementRight={
                  <Button
                    type="ghost"
                    icon={IconClose}
                    action={() => resetField(fields.tag.name)}
                  />
                }
                tags={tag}
              />
            </Field>
          )}
          {!template.fields?.contractExternalUrl?.hidden && (
            <Field
              label={
                template.fields?.contractExternalUrl?.label?.[language] ||
                t('create_contract.form_labels.nft_external_link')
              }
              error={errors.contractExternalUrl?.message}
              labelElementRight={
                <div className="flex items-center space-x-2">
                  <CustomTooltip>
                    <Typography size="xs">
                      {t('create_contract.form_tooltips.external_url')}
                    </Typography>
                  </CustomTooltip>
                </div>
              }
            >
              <Input
                placeholder={t('create_contract.form_placeholders.external_url')}
                disabled={template.fields?.contractExternalUrl?.disabled}
                name={fields.contractExternalUrl.name}
                onBlur={fields.contractExternalUrl.onBlur}
                onChange={fields.contractExternalUrl.onChange}
                inputRef={fields.contractExternalUrl.ref}
                error={errors.contractExternalUrl?.message}
              />
            </Field>
          )}
        </div>
      </div>

      <div className="mt-20 flex w-full justify-between">
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={() => handleBack()} type="secondary">
            {t('create_contract.form_labels.back') as string}
          </Button>
        </div>
        <div className="w-[17rem]">
          <Button
            id="cc-step-1"
            action={onCompleted}
            disabled={isLoading || contractsLeft < 1 || !isImageValid}
          >
            {t('create_contract.form_labels.next') as string}
          </Button>
        </div>
      </div>
    </>
  );
};

export default GeneralStep;
