import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { PropsWithChildren, useEffect } from 'react';
import { FIREBASECHANGEEVENTS } from '../imports/constants';
import { db } from '../imports/firebase';
import { PollForm } from '../imports/types';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logOutPolls, pollsFetching, removePoll, updatePoll } from '../redux/polls/polls.slice';

const PollsWrapper = (props: PropsWithChildren) => {
  const { children } = props;
  const { workspace } = useAppSelector((state) => state.team);
  const { uid } = useAppSelector((state) => state.user);
  const { list } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const targetOwnerUid = workspace?.owner ?? uid;

  useEffect(() => {
    dispatch(logOutPolls());
    if (!workspace?.id) return;

    dispatch(pollsFetching(true));

    const pollsQuery = query(collection(db, 'polls'), where('owner', '==', targetOwnerUid));
    onSnapshot(pollsQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const poll: PollForm = change.doc.data() as PollForm;
        if (change.type === FIREBASECHANGEEVENTS.ADDED) {
          dispatch(updatePoll(poll));
        }

        if (change.type === FIREBASECHANGEEVENTS.MODIFIED) {
          if (list.length > 0) {
            dispatch(updatePoll(poll));
          }
        }

        if (change.type === FIREBASECHANGEEVENTS.REMOVED) {
          dispatch(removePoll(poll.id));
        }
      });
    });
    dispatch(pollsFetching(false));
  }, [workspace?.id]);

  return <>{children}</>;
};

export default PollsWrapper;
