import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-simple-modal-provider';
import { Icon, ItemOption } from '..';
import { IconFolderNft, IconFolderNotch, IconInfo, IconOptions } from '../../icons';
import { formatIPFSUri } from '../../imports/utils';
import { Contract } from '../../modules/tokenCreator/imports/types';

type CollectionTopCardProps = {
  item: Contract;
  folders?: any;
  hasWritePermission?: boolean;
  isAnalytics?: boolean;
};

const CollectionTopCard = ({
  item,
  hasWritePermission,
  isAnalytics,
  folders,
}: CollectionTopCardProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const [openMenu, setOpenMenu] = useState(false);
  const { open: openModalDelete } = useModal('DeleteContractModal');
  const handleDeleteContract = () => {
    openModalDelete({ contract: item });
  };
  const { open: openModalChangeFolder } = useModal('ChangeFolderModal');
  const handleOpenChangeFolder = (item: Contract) => {
    openModalChangeFolder({ folderType: 'contracts', folders, item });
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`flex size-full items-center justify-center rounded-lg ${
          item.image && `bg-cover bg-center`
        }`}
        onClick={() =>
          navigate(isAnalytics ? `/nft/analytics/${item.id}` : `/nft/collection/${item.id}`)
        }
        {...(item.image && {
          style: {
            backgroundImage: `url(${formatIPFSUri(item.image)})`,
          },
        })}
      >
        {!item.image && <Icon icon={IconFolderNft} size="lg" />}
      </div>
      {/* <Image
        src={item.image ? formatIPFSUri(item.image) : ''}
        type="cover"
        className="h-[360px] w-auto rounded-lg shadow-xl"
      /> */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpenMenu(!openMenu);
        }}
        className="absolute right-0 top-0 size-auto cursor-pointer items-center justify-between rounded-bl-lg rounded-tr-lg bg-white p-1"
      >
        <Icon icon={IconOptions} size="md" />
        <div
          onMouseEnter={() => setOpenMenu(true)}
          onMouseLeave={() => setOpenMenu(false)}
          className={`absolute left-0 top-0 z-10 mt-7 flex w-[210px]  flex-col rounded-lg border border-primary-500 bg-white ${
            openMenu ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-col">
            <ItemOption
              id={`info_collection-${item.id}`}
              name={t('collection.options.info_collection')}
              icon={IconInfo}
              onClick={() => navigate(`/nft/collection/${item.id}`)}
            />
            {folders && (
              <ItemOption
                id={`folder_collection-${item.id}`}
                name={t('collection.options.folder_collection')}
                icon={IconFolderNotch}
                onClick={() => handleOpenChangeFolder(item)}
              />
            )}
            {/* <ItemOption
              id={`share_collection-${item.id}`}
              name={t('collection.options.share_collection')}
              icon={IconShare}
            /> */}
            <ItemOption
              id={`delete_collection-${item.id}`}
              name={t('collection.options.delete_collection')}
              color="error"
              onClick={handleDeleteContract}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CollectionTopCard;
