import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/* root imports */
import { compare, compareByDate, filterCollection } from '../../../../imports/utils';

import { IconLoading } from '../../../../icons';

import { CollectionCard, Typography } from '../../../../components';

import type { Filters } from '../../../../imports/types';

/* tokencreator imports */
import { useAppSelector } from '../../../../redux/hooks';
import type { Contract } from '../../imports/types';

type ContractsListProps = {
  contracts: Contract[];
  isLoading: boolean;
  folders?: any;
  renderSearchBar?: React.ReactNode;
  filtersState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  };
  orderBy: {
    type: string;
    direction: string;
  };
  isAnalytics?: boolean;
};
const ContractsList = ({
  contracts,
  isLoading,
  filtersState,
  orderBy,
  folders,
  renderSearchBar,
  isAnalytics,
}: ContractsListProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { workspace } = useAppSelector((user) => user.team);
  const { inputFilterByName, inputFilterByTag, inputFilterByDate } = filtersState;
  const filteredContracts = useMemo(() => {
    const filteredCollection = filterCollection({
      filtersInOrder: [
        {
          type: inputFilterByName.filter,
          propertyToFilter: 'name',
          filterValue: inputFilterByName.value,
        },
        {
          type: inputFilterByTag.filter,
          propertyToFilter: 'tag',
          filterValue: inputFilterByTag.value,
        },
        {
          type: inputFilterByDate.filter,
          propertyToFilter: 'createdAt',
          filterValue: inputFilterByDate.value,
        },
      ],
      collection: contracts,
    });
    if (orderBy) {
      if (orderBy.type === 'date') {
        return filteredCollection.sort((a, b) =>
          compareByDate(a.createdAt, b.createdAt, orderBy.direction)
        );
      }

      if (orderBy.type === 'name') {
        return filteredCollection.sort((a, b) => compare(a, b, 'name', orderBy.direction));
      }
    }

    return filteredCollection;
  }, [contracts, inputFilterByName, inputFilterByTag, inputFilterByDate, orderBy]);
  return !isLoading ? (
    <>
      <Typography size="body-medium-20">{t('collection.title_contracts')}</Typography>
      {renderSearchBar || null}
      <div className="flex flex-wrap gap-5">
        {filteredContracts.length > 0 ? (
          filteredContracts.map((item) => {
            return (
              <CollectionCard
                item={item}
                key={item.id}
                folders={folders}
                hasWritePermission={workspace?.permissions.token_creator.write}
                isAnalytics={isAnalytics}
              />
            );
          })
        ) : (
          <div className="col-span-full mt-6 text-center">
            <Typography>
              {t(
                contracts.length === 0
                  ? 'collection.no_filtered_contracts_found'
                  : 'collection.no_contracts_found'
              )}
            </Typography>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="flex grow items-center justify-center">
      <IconLoading className="size-12 animate-spin text-primary-500" />
    </div>
  );
};

export default ContractsList;
