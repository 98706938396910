import { SVGProps } from 'react';

const IconFunctionality = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 11.3332H6.66667M10 10.6665H14M2 7.99984H9.33333M2 4.6665H9.33333"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconFunctionality;
