import axios from 'axios';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button, Form } from '..';
import { useLoadingStatusContext } from '../../context/loading/LoadingContext';
import { useValidation } from '../../hooks';
import { apiEndpoint } from '../../imports/constants';
import { auth } from '../../imports/firebase';
import { CustomFeatures, SubscriptionPlan } from '../../imports/types';
import { useAppSelector } from '../../redux/hooks';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type InfoSectionProps = {
  label: string;
};
const InfoSection = ({ label }: InfoSectionProps) => {
  return (
    <Typography size="body-regular-16" className="py-1">
      {label}
    </Typography>
  );
};
type PriceSectionProps = {
  priceLabel: string;
  vatLabel?: string;
};
const PriceSection = ({ priceLabel, vatLabel }: PriceSectionProps) => {
  return (
    <div className="flex flex-col">
      <Typography size="body-semibold-20">{priceLabel}</Typography>
    </div>
  );
};
type PlansModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { t } = useTranslation();
  const { plan } = useModalProps('PlansModal') as { plan: SubscriptionPlan };
  const { uid } = useAppSelector((state) => state.user);
  const { state, dispatch } = useLoadingStatusContext();

  const { required } = useValidation();
  const customFeatures: (keyof CustomFeatures)[] = [
    'maxContractsNumber',
    'storageLimit',
    'maxTeamMembers',
  ];

  const form = {
    initialValues: {
      maxContractsNumber: plan.maxContractsNumber,
      storageLimit: plan.storageLimit,
      maxTeamMembers: plan.maxTeamMembers,
    },
    validationSchema: yup.object({
      maxContractsNumber: required(yup.number().min(plan.maxContractsNumber)),
      storageLimit: required(yup.number().min(plan.storageLimit)),
      maxTeamMembers: required(yup.number().min(plan.maxTeamMembers)),
    }),
  };

  const { initialValues, validationSchema } = form;
  const [price, setPrice] = useState(plan.price);
  const handleSendPlan = async (type: string, values: any) => {
    dispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('subscription.messages.pending') as string,
      },
    });
    try {
      const newPlan = { ...plan, ...values };
      // if (type === 'base') {
      //   newPlan = { ...initialValues, price: plan.price };
      // }
      // if (type === 'custom') {
      //   newPlan = { ...values, price };
      // }
      const accessToken = await auth.currentUser?.getIdToken();
      const { sessionUrl } = (
        await axios.post(`${apiEndpoint}/create-checkout-session`, {
          ...newPlan,
          token: accessToken,
        })
      ).data;

      if (!sessionUrl) {
        throw new Error('Unable to create checkout session');
      }

      window.location.href = sessionUrl;

      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('subscription.messages.success') as string,
        },
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('subscription.messages.failed') as string,
        },
      });
      toast.error(t('subscription.error.cannot_create_c_s') as string);
    }
  };

  const sendMail = () => {
    // TODO: implement sendMail if user wants a custom plan
    window.open(`https://bcode.cloud/contattaci/`, '_blank');
  };

  return (
    <ModalLayout
      classNameLayout="h-auto w-auto"
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="mx-7 mt-7 flex flex-col ">
        <Typography weight="semibold" size="body-semibold-30" color="primary-500">
          {plan.name === 'contactUs' ? t('subscription.contactUs') : plan.name}
        </Typography>
      </div>
      <div className="mx-7 mt-3 flex flex-col">
        <InfoSection
          label={t(`subscription.features.notarization_year`, {
            notarizations: plan.maxNotarizations !== -1 ? plan.maxNotarizations : 'Custom',
          })}
        />
        <InfoSection
          label={t(`subscription.features.nft_year`, {
            nfts: plan.maxTokenNumber !== -1 ? plan.maxTokenNumber : 'Custom',
          })}
        />
        <InfoSection
          label={t('subscription.features.sell_fees', {
            fees: plan.fees,
          })}
        />
        <InfoSection label={t('subscription.features.crm')} />
        <InfoSection label={t('subscription.features.analytics')} />
        <InfoSection label={t('subscription.features.audit')} />
      </div>

      <div className="mx-7 my-4 border"></div>
      {plan.name === 'contactUs' ? (
        <div className="mx-7 flex flex-col gap-3">
          <Typography> {t('subscription.features.contact_us')}</Typography>
          <Button type="primary" action={sendMail} className="px-6 ">
            {t('subscription.buttons.send_mail')}
          </Button>
        </div>
      ) : (
        <div className="mx-7 mb-7 flex flex-col gap-3">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="mt-4 flex size-full flex-col items-center"
          >
            {({ fields, handleSubmit, errors, watch, setValue }) => {
              const values = watch();

              const calculatedPrice =
                (plan.price as number) +
                (values.maxContractsNumber - plan.maxContractsNumber) * 30 +
                ((values.storageLimit - plan.storageLimit) / 10) * 22 +
                (values.maxTeamMembers - plan.maxTeamMembers) * 150;
              setPrice(calculatedPrice);

              const handleCustomButton = (
                fieldName: keyof CustomFeatures,
                action: 'add' | 'remove'
              ) => {
                const qty = fieldName === 'storageLimit' ? 10 : 1;
                if (action === 'add') {
                  setValue(fieldName, (values[fieldName] += qty));
                }
                if (action === 'remove') {
                  setValue(fieldName, (values[fieldName] -= qty));
                }
              };

              return (
                <div className="flex flex-row gap-3">
                  <table className="table-fixed">
                    <tbody>
                      <tr>
                        <td className="w-2/3">
                          <Typography weight="semibold" size="body-regular-16">
                            {t('subscription.table_titles.title')}
                          </Typography>
                        </td>
                        <td className="w-1/3"></td>
                      </tr>
                      <tr>
                        <td className="w-2/3 ">
                          <Typography size="body-regular-14">
                            {t('subscription.table_titles.max_nft_collection', {
                              collections: '',
                            })}
                          </Typography>
                        </td>
                        <td className="w-1/3 items-center">
                          <div className="flex w-full flex-row justify-between">
                            <Button
                              type="primary"
                              action={() => handleCustomButton('maxContractsNumber', 'remove')}
                              className="!h-[30px] !w-[30px]"
                              disabled={values.maxContractsNumber <= plan.maxContractsNumber}
                            >
                              -
                            </Button>
                            {values.maxContractsNumber}
                            <Button
                              type="primary"
                              action={() => handleCustomButton('maxContractsNumber', 'add')}
                              className="!h-[30px] !w-[30px]"
                            >
                              +
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-2/3">
                          <Typography size="body-regular-14">
                            {t('subscription.table_titles.storage', {
                              storage: '',
                            })}
                          </Typography>
                        </td>

                        <td className="w-1/3 ">
                          <div className="flex w-full flex-row justify-between">
                            <Button
                              type="primary"
                              action={() => handleCustomButton('storageLimit', 'remove')}
                              className="!h-[30px] !w-[30px]"
                              disabled={values.storageLimit <= plan.storageLimit}
                            >
                              -
                            </Button>
                            {values.storageLimit}
                            <Button
                              type="primary"
                              action={() => handleCustomButton('storageLimit', 'add')}
                              className="!h-[30px] !w-[30px]"
                            >
                              +
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography size="body-regular-14">
                            {t('subscription.table_titles.team_member', {
                              teamMember: '',
                            })}
                          </Typography>
                        </td>
                        <td className="w-2/3 ">
                          <div className="flex w-full flex-row justify-between">
                            <Button
                              type="primary"
                              action={() => handleCustomButton('maxTeamMembers', 'remove')}
                              className="!h-[30px] !w-[30px]"
                              disabled={values.maxTeamMembers <= plan.maxTeamMembers}
                            >
                              -
                            </Button>
                            {values.maxTeamMembers}
                            <Button
                              type="primary"
                              action={() => handleCustomButton('maxTeamMembers', 'add')}
                              className="!h-[30px] !w-[30px]"
                            >
                              +
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography weight="semibold" size="body-regular-16">
                            {t('subscription.table_titles.price')}
                          </Typography>
                        </td>

                        <td>
                          <div className="my-4 flex w-full flex-row justify-center ">
                            <PriceSection
                              priceLabel={t('subscription.features.price', {
                                price,
                              })}
                              vatLabel={t('subscription.vat_info')}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 ">
                          <div className="flex w-full flex-row justify-center">
                            <Button
                              type="secondary"
                              action={() =>
                                customFeatures.forEach((feat) =>
                                  setValue(feat, initialValues[feat])
                                )
                              }
                            >
                              {t('subscription.buttons.reset')}
                            </Button>
                          </div>
                        </td>
                        <td className="w-1/3">
                          <div className="flex w-full flex-row justify-center">
                            <Button
                              type="primary"
                              action={() => handleSubmit(() => handleSendPlan('custom', values))()}
                            >
                              {t('subscription.buttons.buy')}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            }}
          </Form>
          <Typography size="body-regular-16" className="mt-7 place-self-center">
            {t('subscription.vat_info')}
          </Typography>
        </div>
      )}
    </ModalLayout>
  );
};

const PlansModal = ({ children }: PlansModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="PlansModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default PlansModal;
