import { applyActionCode } from 'firebase/auth';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useLocation } from 'react-router-dom';
import { animationError, animationSuccess } from '../../assets/animations';
import { Typography } from '../../components';
import { auth } from '../../imports/firebase';

const VerifyEmail = () => {
  const location = useLocation();
  const { oobCode } = location.state as { oobCode: string };
  const [message, setMessage] = useState('');

  useEffect(() => {
    applyActionCode(auth, oobCode)
      .then(() => {
        setMessage('Email Verified!');
      })
      .catch((err) => {
        setMessage('Something went wrong.');
      });
  }, []);

  return (
    <section className="bg-primary-400">
      <div className="mx-auto flex min-h-screen max-w-screen-md flex-col items-center justify-center space-y-[64px]">
        {message === 'Email Verified!' && (
          <Lottie loop animationData={animationSuccess} play style={{ width: 100, height: 100 }} />
        )}

        {message === 'Something went wrong.' && (
          <Lottie loop animationData={animationError} play style={{ width: 100, height: 100 }} />
        )}
        <Typography className="text-white" size="2xl">
          {message}
        </Typography>

        <div className="flex w-full items-center justify-center space-x-[121px]">
          <a
            href={process.env.REACT_APP_ENDPOINT_TAKER}
            className="inline-block text-center text-white hover:underline"
          >
            WalletPlace
          </a>
          <a
            href={process.env.REACT_APP_ENDPOINT}
            className="inline-block text-center text-white hover:underline"
          >
            Bcode
          </a>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
