import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// import { Form } from 'react-router-dom';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';

import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

import { useLoadingStatusContext } from '../../context';
import { IconClose } from '../../icons';
import { auth } from '../../imports/firebase';
import { client } from '../../imports/utils';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Field from '../field/Field';
import Form from '../form/Form';
import Input from '../input/Input';

type CrmUserModalProps = {
  children: ReactNode;
};
interface ProjectsList {
  projectName: string;
  projectId: string;
  quantity: number;
}

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { type, token } = useModalProps('ApiModal');
  const { t } = useTranslation();

  const { workspaceId, userAddress } = useAppSelector(({ team, user }) => ({
    workspaceId: team?.workspace?.id,
    userAddress: user.wallet.address,
  }));

  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();
  const apiForm = {
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: yup.string(),
    }),
  };

  const handleAddApi = async (values: { name: string }) => {
    const { name } = values;

    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('api.pending.api_key_create_pending'),
      },
    });

    await client('generateJWT', {
      body: { name, workspaceId, userAddress },
      headers: {
        Authorization: await auth.currentUser?.getIdToken(),
      },
      fromFunctions: false,
    })
      .then(() => {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('api.success.api_key_created'),
          },
        });
      })
      .catch(() => {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('api.error.cannot_generate_token'),
          },
        });
      });
  };

  const handleDeleteApi = async () => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('api.pending.api_key_delete_pending'),
      },
    });
    await client('revokeJWT', {
      body: { token: token.token },
      headers: {
        Authorization: await auth.currentUser?.getIdToken(),
      },
      fromFunctions: false,
    })
      .then(() => {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('api.success.api_key_deleted'),
          },
        });
      })
      .catch(() => {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            message: t('api.error.cannot_delete_token'),
          },
        });
      });
  };

  const { initialValues, validationSchema } = apiForm;

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="w-auto"
    >
      {type === 'create' && (
        <>
          <div className="w-full space-y-4 px-[40px] pt-[75px]">
            <Form
              initialValues={initialValues}
              validationSchema={validationSchema}
              className="w-full space-y-4 "
            >
              {({ fields, handleSubmit, errors, resetField, isValid, dirtyFields }) => (
                <>
                  <div className="mb-[50px] flex flex-col gap-2">
                    <Typography size="body-medium-30" color="primary-500">
                      {t('api.new_key')}
                    </Typography>
                    <Field
                      error={errors.name?.message}
                      label={t('api.add_api')}
                      className="mt-[28px]"
                    >
                      <Input
                        type="text"
                        placeholder={t('api.form_placeholders.name')}
                        id={fields.name.name}
                        name={fields.name.name}
                        onBlur={fields.name.onBlur}
                        onChange={fields.name.onChange}
                        inputRef={fields.name.ref}
                        error={errors.name?.message}
                        className="mt-[5px]"
                        elementRight={
                          dirtyFields.name && (
                            <Button
                              type="ghost"
                              icon={IconClose}
                              action={() => resetField(fields.name.name)}
                            />
                          )
                        }
                      />
                    </Field>
                    <div className="flex justify-end">
                      <Button
                        disabled={!isValid || state.status === 'pending' || !dirtyFields.name}
                        type="primary"
                        action={() => handleSubmit(handleAddApi)()}
                        className="mt-4"
                      >
                        {t('api.create_api_key')}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </>
      )}
      {type === 'delete' && (
        <>
          <div className="mb-[50px] flex flex-col gap-2 px-[40px] pt-[75px]">
            <Typography size="body-medium-30" color="primary-500" className="text-left">
              {t('api.delete_key')}
            </Typography>

            <Typography size="body-regular-18" color="grey-600" className="mt-[28px]">
              {t('api.confirm1')} {token.name}? {t('api.confirm2')}.
            </Typography>

            <div className="flex justify-end">
              <Button
                type="warning"
                disabled={state.status === 'pending'}
                action={() => handleDeleteApi()}
                loading={state.status === 'pending'}
                className="mt-4"
              >
                {t('api.delete')}
              </Button>
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
};

const ApiModal = ({ children }: CrmUserModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ApiModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default ApiModal;
