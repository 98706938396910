import { Colors } from '../../imports/types';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

type ItemOptionProps = {
  id: string | number;
  icon?: any;
  name: string;
  color?: Colors;
  onClick?: () => void;
};

const ItemOption = ({ id, icon, name, color = 'black', onClick }: ItemOptionProps) => {
  return (
    <div
      key={id}
      onClick={onClick}
      className="flex h-[36px] w-full cursor-pointer flex-row items-center justify-start gap-x-3 rounded-lg pl-[10px] pr-4 hover:bg-primary-100"
    >
      {icon && <Icon icon={icon} size="16" />}
      <Typography size="body-regular-12" color={color}>
        {name}
      </Typography>
    </div>
  );
};
export default ItemOption;
