import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { getUserAuthToken } from '../../../api/firebase';
import { backendEndpoint } from '../../../imports/constants';
import { auth, db } from '../../../imports/firebase';
import { AnalyticsParameters, DateRange } from '../imports/types';

export interface AnalyticsProject {
  _id: string;
  redeems: number;
  transfers: number;
  verifications: number;
}

export interface AnalyticsCustomer {
  _id: string;
  email: string | null;
  marketingTerms: boolean;
  address: string;
}
export interface CrmCustomer {
  _id: string;
  email: string | null;
  marketingTerms: boolean;
  address: string;
  projects?: Array<string>;
  customerId: string | null;
  name: string;
  hide?: boolean;
}

export interface AnalyticsActivity {
  // createdAt: Timestamp;
  createdAt: number;
  projectId: string;
  type: 'transfer' | 'redeem' | 'tokenVerification';
  nftName: string;
  customer: AnalyticsCustomer;
  data: {
    chain: 'polygon';
    contract_id: string;
    customer_uid: string;
    customer_address: string;
    quantity: number;
    receiver_uid: string;
    result: boolean;
    token_id: number;
    transaction_hash: string;
  };
}

export interface Analytics {
  analytics: AnalyticsProject;
  activities: AnalyticsActivity[];
}

export interface Player {
  name: string;
  email: string;
  company: string;
  gameVersion: string;
  score: string;
  tokenId: number;
  user: string;
  diamonds: number;
  coins: number;
  endTimestamp: number;
  contract: string;
}

export interface GsmingAnalytics {
  totalScore: number;
  games: number;
  activePlayers: number;
  leaderboard: any[];
}

export const getAnalytics = async (userId: string, projectId: string) => {
  const projectDoc = await getDoc(doc(db, 'CRM', userId, 'projects', projectId));
  return { _id: projectDoc.id, ...projectDoc.data() } as AnalyticsProject;
};

// export const getCustomers = async (userId: string) => {
//   const customers = await getDocs(collection(db, 'CRM', userId, 'customers'));
//   return customers.docs.map(
//     (customer) => ({ _id: customer.id, ...customer.data() } as AnalyticsCustomer)
//   )}

export const fetchProjectAnalytics = async (workspaceOwner: string, contractId: string) => {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.get(
    `${backendEndpoint}/crm/projects/${workspaceOwner}/${contractId}`,
    {
      headers: {
        authorization: token ?? '',
      },
    }
  );

  // console.log('project analythics: ', response.data);
  return response.data as Analytics;
};

export const getCustomersZ = async (customerId: string) => {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.get(`${backendEndpoint}/crm/list/${customerId}`, {
    headers: {
      authorization: token ?? '',
    },
  });
  return response.data?.stats as AnalyticsCustomer[];
};

export const getCustomers = async (workspaceOwner: string) => {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.get(`${backendEndpoint}/crm/users/${workspaceOwner}`, {
    headers: {
      authorization: token ?? '',
    },
  });

  return response.data?.users as AnalyticsCustomer[];
};

export const getUsersStats = async (workspaceOwner: string) => {
  const idToken = await auth.currentUser?.getIdToken();
  if (!idToken) throw new Error('No idToken');
  const stats = await axios.get(`${backendEndpoint}/crm/list/${workspaceOwner}`, {
    headers: {
      authorization: idToken,
    },
  });

  return stats.data.stats;
};
// CLICK ANALYTICS
const getIdToken = async () => {
  const { value: idToken, error } = await getUserAuthToken();
  if (error) throw new Error(`Error in getIdToken ${error}}`);
  return idToken;
};

const formatDate = (date?: string) => date?.toString().split('T')[0];

const getAnalyticsUrl = (companyId: string, contractId?: string, nftId?: string) => {
  if (!companyId) throw new Error('No companyId');
  if (nftId) {
    return `${backendEndpoint}/analytics/${companyId}/${contractId}/${nftId}`;
  }
  if (contractId) {
    return `${backendEndpoint}/analytics/${companyId}/${contractId}`;
  }
  return `${backendEndpoint}/analytics/${companyId}`;
};

const getClickAnalytics = async (url: string, idToken: string, dateRange?: DateRange) => {
  const response = await axios.get(
    `${url}?date_to=${formatDate(dateRange?.date_to)}&date_from=${formatDate(
      dateRange?.date_from
    )}`,
    {
      headers: {
        authorization: idToken,
        'ngrok-skip-browser-warning': '69420',
      },
    }
  );
  return response.data;
};

export const getClickAnalyticsByCompany = async ({ companyId, dateRange }: AnalyticsParameters) => {
  const idToken = await getIdToken();
  const url = getAnalyticsUrl(companyId);
  return getClickAnalytics(url, idToken, dateRange);
};

export const getClickAnalyticsByCompanyAndProject = async ({
  companyId,
  contractId,
  dateRange,
}: AnalyticsParameters) => {
  const idToken = await getIdToken();
  if (!contractId) throw new Error('No projectId');
  const url = getAnalyticsUrl(companyId, contractId);
  return getClickAnalytics(url, idToken, dateRange);
};

export const getClickAnalyticsByCompanyAndProjectAndToken = async ({
  companyId,
  contractId,
  nftId,
  dateRange,
}: AnalyticsParameters) => {
  const idToken = await getIdToken();
  if (!contractId) throw new Error('No projectId');
  if (!nftId) throw new Error('No tokenId');
  const url = getAnalyticsUrl(companyId, contractId, nftId);
  return getClickAnalytics(url, idToken, dateRange);
};
