import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { ReactNode, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import * as yup from 'yup';
import { ImagePlaceholder } from '../../assets/images';
import { useLoadingStatusContext } from '../../context';
import { IconBell, IconClose, IconStar, IconWebsite } from '../../icons';
import { firebaseConfig } from '../../imports/constants';
import { db, storage } from '../../imports/firebase';
import { getRemoteConfigValue } from '../../imports/remoteConfig';
import { PublicCompany } from '../../imports/types';
import { formatDate, sleep, truncateText } from '../../imports/utils';
import { isSecureUrl, isUrl } from '../../modules/tokenCreator/imports/utils';
import { useAppSelector } from '../../redux/hooks';
import Button from '../button/Button';
import Field from '../field/Field';
import FileUploader from '../fileUploader/FileUploader';
import Form from '../form/Form';
import Icon from '../icon/Icon';
import Input from '../input/Input';
import ModalLayout from '../layouts/ModalLayout';
import TextareaQuill from '../textareaQuill/TextareaQuill';
import Typography from '../typography/Typography';

type NewCompanyModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { publicCompany, getPublicCompany, message = false } = useModalProps('NewCompanyModal');
  const [initMessage, setinitMessage] = useState(message);
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch, state } = useLoadingStatusContext();
  const { uid, wallet, createdAt, company, name } = useAppSelector((state) => state.user);
  const { address } = wallet || {};
  const [img, setImg] = useState(null);
  const isNewProfile =
    publicCompany?.website === '' &&
    publicCompany?.profilePic === '' &&
    publicCompany?.description === '';

  const form = {
    initialValues: {
      address,
      profilePic: publicCompany?.profilePic || '',
      companyName: publicCompany?.companyName || '',
      description: publicCompany?.description || '',
      website: publicCompany?.website || '',
      cta: publicCompany?.cta || '',
      ctaLink: publicCompany?.ctaLink || '',
      textColor: publicCompany?.textColor || '',
      bgColor: publicCompany?.bgColor || '',
      bannerPic: publicCompany?.bannerPic || '',
    },
    validationSchema: yup.object({
      description: yup.string().required('Campo obbligatorio'),
      profilePic: yup.mixed().test('non-vuoto', 'Campo obbligatorio', (value) => {
        return (
          (typeof value === 'string' && value.trim() !== '') ||
          (Array.isArray(value) && value.length > 0)
        );
      }),
      website: yup
        .string()
        .test('valid_url', t('collection.create.errors.invalid_url'), (value) => isUrl(value))
        .test('secure_url', t('collection.create.errors.url_not_secure'), (value) =>
          isSecureUrl(value)
        )
        .required('Campo obbligatorio'),
    }),
  };

  const allowedFileType = getRemoteConfigValue('allowedFiles', 'json');
  const { initialValues, validationSchema } = form;

  const uploadFile = async (file: File) => {
    const key = 'profilePic';
    if (!file) {
      throw new Error(`No file passed`);
    }

    const imgRef = ref(storage, `images/${uid}/${key}`);
    await uploadBytes(imgRef, file);
    const url = `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/images%2F${uid}%2F${key}?alt=media`;
    // const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());
    // await updateDoc(publicDocRef, { profilePic: url });

    return url;
  };

  const handleSave: SubmitHandler<PublicCompany> = async (fields: PublicCompany) => {
    try {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('create_contract.create.actions.pending'),
        },
      });
      if (img) {
        fields.profilePic = await uploadFile(img);
      }
      const publicDocRef = doc(db, 'publicCompany', address.toLowerCase());

      if ((await getDoc(publicDocRef)).exists()) {
        await setDoc(publicDocRef, { ...fields, updatedAt: Date.now() }, { merge: true });
      } else {
        await setDoc(publicDocRef, {
          ...fields,
          companyName: company || name,
          createdAt: Date.now(),
          updatedAt: Date.now(),
        });
      }

      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('create_contract.create.actions.saveSuccess'),
        },
      });
      await sleep(1500);
      window.location.reload();
    } catch (e) {
      console.error(e);
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('create_contract.create.actions.saveError'),
        },
      });
    }
  };

  return (
    <ModalLayout
      classNameLayout={state.status || initMessage ? '' : 'max-h-[95%] w-auto overflow-auto'}
      onClose={() => {
        setOpen(false);
      }}
    >
      {initMessage ? (
        <div className="flex flex-col items-center justify-center gap-7">
          <Typography color="primary-500" size="body-medium-30" className="px-8 pt-8">
            {t('collection.create.title_no_profile')}
          </Typography>
          <div className="max-w-md">
            <Typography size="body-regular-20" className="p-8 text-center">
              {t('collection.create.message_no_profile')}
            </Typography>
          </div>
          <Button type="primary" action={() => setinitMessage(false)} className="px-8">
            {t('collection.create.begin')}
          </Button>
        </div>
      ) : (
        <div data-cy="addNotarization" className="size-full">
          <div className="flex size-full flex-col pt-4">
            <Typography color="primary-500" size="body-medium-30" className="pl-8 pt-8">
              {t('collection.create.title')}
            </Typography>

            <Form
              initialValues={initialValues}
              validationSchema={validationSchema}
              className="mt-4 flex size-full flex-col items-center"
            >
              {({ fields, handleSubmit, errors, resetField, watch, setValue, register }) => {
                const values = watch();
                const [proPic, setProPic] = useState<string>('');
                const [disableSave, setDisableSave] = useState(true);

                useEffect(() => {
                  let result = true;
                  if (values.description !== publicCompany?.description) {
                    result = false;
                  }
                  if (values.profilePic !== publicCompany?.profilePic) {
                    result = false;
                  }
                  if (values.website !== publicCompany?.website) {
                    result = false;
                  }
                  setDisableSave(result);
                }, [values]);

                useEffect(() => {
                  if (values.profilePic && Array.isArray(values.profilePic)) {
                    setProPic(values.profilePic[0].preview);
                    setImg(values.profilePic[0]);
                  } else {
                    setProPic(publicCompany?.profilePic);
                  }
                }, [values.profilePic]);

                return (
                  <div className="grid grid-cols-2 gap-4 p-8">
                    <div className="flex size-full flex-col content-center gap-4">
                      <img
                        className="image contain h-[211px] w-[376px]"
                        src={proPic !== '' ? proPic : ImagePlaceholder}
                        alt=""
                      />

                      <div className="flex flex-row justify-between">
                        <Typography className="w-3/4">{values.companyName}</Typography>
                        <div className="flex w-1/4 flex-row justify-between">
                          <Icon icon={IconStar} />
                          <Icon icon={IconWebsite} />
                          <Icon icon={IconBell} />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <Typography className="w-1/2">
                          {t('collection.create.wallet', {
                            wallet: truncateText(values.address, {
                              maxLength: 20,
                              rightChars: 0,
                              position: 'middle',
                            }),
                          })}
                        </Typography>

                        <Typography className="w-1/2">
                          {t('collection.create.date', {
                            date: formatDate(createdAt as unknown as number),
                          })}
                        </Typography>
                      </div>
                      <Typography className="w-3/4">{values.description}</Typography>
                    </div>
                    <div className="flex max-h-[550px] w-full flex-col content-center gap-4 overflow-y-auto">
                      <Field error={errors.profilePic?.message}>
                        <FileUploader
                          ratio="16:9"
                          label={t('collection.create.drag_file')}
                          onChange={(files) => {
                            if (files.length) {
                              setValue(fields.profilePic.name, files, {
                                shouldValidate: true,
                              });
                            } else {
                              resetField(fields.profilePic.name);
                            }
                          }}
                          id={fields.profilePic.name}
                          accept={allowedFileType}
                          name={fields.profilePic.name}
                          inputOnBlur={fields.profilePic.onBlur}
                          inputRef={fields.profilePic.ref}
                        />
                      </Field>

                      <Field
                        error={errors.companyName?.message}
                        label={t('collection.create.company_name')}
                      >
                        <Input
                          type="text"
                          placeholder={t('collection.create.company_name')}
                          id={fields.companyName.name}
                          name={fields.companyName.name}
                          onBlur={fields.companyName.onBlur}
                          onChange={fields.companyName.onChange}
                          inputRef={fields.companyName.ref}
                          error={errors.companyName?.message}
                          disabled
                        />
                      </Field>

                      <Field
                        error={errors.description?.message}
                        label={t('collection.create.description')}
                        className="h-[200px]"
                      >
                        <TextareaQuill
                          setValue={setValue}
                          field="description"
                          value={values.description}
                          // height="100px"
                        />
                      </Field>
                      <Field error={errors.website?.message} label={t('collection.create.website')}>
                        <Input
                          type="text"
                          placeholder={t('collection.create.website')}
                          id={fields.website.name}
                          name={fields.website.name}
                          onBlur={fields.website.onBlur}
                          onChange={fields.website.onChange}
                          inputRef={fields.website.ref}
                          error={errors.website?.message}
                          elementRight={
                            <Button
                              type="ghost"
                              icon={IconClose}
                              action={() => resetField(fields.website.name)}
                            />
                          }
                        />
                      </Field>
                    </div>
                    <div className="col-span-2 flex w-full flex-row justify-end gap-4 pb-6 ">
                      <Button
                        id="notarize"
                        disabled={disableSave}
                        type="primary"
                        action={handleSubmit(() => handleSave(values))}
                        className="px-6 "
                      >
                        {isNewProfile
                          ? t('collection.create.title_no_profile')
                          : t('collection.create.submit_data')}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Form>

            {/* </div> */}
          </div>
        </div>
      )}
    </ModalLayout>
  );
};

const NewCompanyModal = ({ children }: NewCompanyModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="NewCompanyModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default NewCompanyModal;
