import { yupResolver } from '@hookform/resolvers/yup';
import type { ReactNode } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';

import type {
  Control,
  Mode,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import type { OptionalObjectSchema } from 'yup/lib/object';

export type ExportedProps = {
  fields: { [key: string]: UseFormRegisterReturn };
  handleSubmit: UseFormHandleSubmit<any>;
  errors: any;
  isValid: boolean;
  resetField: UseFormResetField<any>;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  control: Control;
  register: UseFormRegister<any>;
  reset: UseFormReset<any>;
  clearErrors: UseFormClearErrors<any>;
  getValues: UseFormGetValues<any>;
  dirtyFields: Partial<
    Readonly<{
      [x: string]: any;
    }>
  >;
};

type FormProps = {
  initialValues: {
    [key: string]: any;
  };
  validationSchema: OptionalObjectSchema<any>;
  children: (props: ExportedProps) => ReactNode;
  className?: string;
  mode?: Mode;
};

const Form = ({
  children,
  initialValues,
  validationSchema,
  className,
  mode = 'onSubmit',
}: FormProps) => {
  const resolver = yupResolver(validationSchema);

  const formState = useForm({
    defaultValues: initialValues,
    resolver,
    mode,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    resetField,
    trigger,
    watch,
    setValue,
    control,
    reset,
    getValues,
    clearErrors,
  } = formState;

  const fields = Object.keys(initialValues).reduce(
    (previousValue, currentValue) => ({ ...previousValue, [currentValue]: register(currentValue) }),
    {}
  );

  return (
    <form className={className}>
      {children({
        fields,
        errors,
        isValid,
        handleSubmit,
        resetField,
        trigger,
        watch,
        setValue,
        control,
        register,
        reset,
        getValues,
        clearErrors,
        dirtyFields,
      })}
    </form>
  );
};

export default Form;
