import { SVGProps } from 'react';

const IconVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38762)">
      <path
        d="M96 42C36 42 12 96 12 96C12 96 36 150 96 150C156 150 180 96 180 96C180 96 156 42 96 42Z"
        // eslint-disable-next-line react/destructuring-assignment
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96 126C112.569 126 126 112.569 126 96C126 79.4315 112.569 66 96 66C79.4315 66 66 79.4315 66 96C66 112.569 79.4315 126 96 126Z"
        // eslint-disable-next-line react/destructuring-assignment
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38762">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconVisible;
