import isArray from 'lodash/isArray';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MessageIcon, TopbarMenu, Typography, UserIcon } from '..';
import { MainLogo } from '../../assets/images';
import { useGetGroups } from '../../hooks/useGetGroups';
import { useAppSelector } from '../../redux/hooks';
import Image from '../image/Image';
import SwapAccountIcon from '../SwapAccountIcon/SwapAccountIcon';

type TopbarRedirectProps = {
  children: ReactNode;
  name: string;
  path: string | string[];
  hasChildren?: boolean;
  sidebarExternalLink?: string;
  handleOpenSubmenu: (path: string) => void;
};

const SidebarRedirect = ({
  children,
  name,
  path,
  hasChildren,
  sidebarExternalLink,
  handleOpenSubmenu,
}: TopbarRedirectProps) => {
  const navigate = useNavigate();

  const style =
    'flex h-11 w-full cursor-pointer items-center truncate rounded-lg px-1 hover:bg-primary-500';

  return sidebarExternalLink ? (
    <a href={sidebarExternalLink} target="_blank" rel="noreferrer" className={style}>
      {children}
    </a>
  ) : (
    <button
      data-cy={`sidebarRedirect-${name}`}
      type="button"
      onClick={() =>
        !hasChildren ? navigate(!isArray(path) ? path : path[0]) : handleOpenSubmenu(name)
      }
      className={style}
    >
      {children}
    </button>
  );
};

const Topbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspace } = useAppSelector((user) => user.team);
  const { groups } = useGetGroups(workspace?.id);
  const activeGroupDetails = groups?.find((group) => group.id === workspace?.activeGroupId);

  return (
    <div className="fixed left-0 top-0 z-40  flex h-[70px] w-full shrink-0 flex-row border-b-2 border-grey-300 bg-white text-primary-500">
      <header className="relative flex size-full content-center items-center justify-between pl-[70px]">
        <div className="relative flex h-full items-center justify-between gap-x-12">
          <Image src={MainLogo} alt={t('bcode_logo')} className="w-[92px]" />
          <TopbarMenu />
        </div>
        <div className="flex flex-row gap-4 pr-[70px]">
          <div className="flex flex-col justify-center gap-1 align-middle">
            <Typography size="body-regular-10">{`${t('workspace.team')}: ${
              workspace?.name
            }`}</Typography>
            {workspace?.inGroup && activeGroupDetails && (
              <Typography size="body-regular-10">{`${t('workspace.group')}: ${
                activeGroupDetails?.name
              }`}</Typography>
            )}
          </div>

          {workspace?.permissions?.token_creator?.read && <MessageIcon />}
          <UserIcon />
          <SwapAccountIcon />
        </div>
      </header>
    </div>
  );
};

export default Topbar;
