import { PropsWithChildren } from 'react';
import { useLoadingStatusContext } from '../../context';
import useClickOutsideToClose from '../../hooks/useClickOutsideToClose';
import { IconCheck, IconClose, IconLoading, IconWrong } from '../../icons';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

export interface ModalProps extends PropsWithChildren {
  open?: boolean;
  onClose: () => void;
  classNameBg?: string;
  canClickOutside?: boolean;
  classNameLayout?: string;
  width?: string;
  height?: string;
}
const ModalLayout = ({
  open = true,
  onClose,
  classNameBg,
  canClickOutside,
  children,
  classNameLayout,
  width,
  height,
}: ModalProps) => {
  const domNode = useClickOutsideToClose(() => {
    if (canClickOutside) {
      onClose();
    }
  });
  const { dispatch: loaderDispatch, state } = useLoadingStatusContext();
  const onCloseAction = () => {
    loaderDispatch({
      type: 'RESET_STATE',
    });
    onClose();
    if (state.action) {
      state.action.onClick();
    }
  };

  if (!open) {
    return <></>;
  }

  return (
    <div
      className={`fixed inset-0 z-40 flex size-full flex-col items-center justify-center bg-[#9B9B9B]/50 ${classNameBg}`}
    >
      <div
        className={`relative min-w-[540px] items-center justify-center rounded-[10px] bg-white p-5 shadow-[0_7px_10px_rgba(0,0,0,0.25)] ${classNameLayout}`}
        style={{
          width: width ? `${width}` : '',
          height: width ? `${height}` : '',
        }}
        ref={domNode}
      >
        {state.status !== 'pending' && (
          <Icon
            icon={IconClose}
            className="absolute right-[40px] top-[32px] z-50"
            role="button"
            size="29"
            stroke="primary-500"
            onClick={onCloseAction}
          />
        )}
        {(() => {
          switch (state.status) {
            case 'pending':
              return (
                <div className="mx-auto flex h-auto w-auto flex-col items-center justify-center py-7">
                  <div className="flex flex-col">
                    <IconLoading className="mx-auto size-12 animate-spin text-primary-500" />
                  </div>
                  {state.message && (
                    <Typography id="modal-message" size="body-regular-18" className="pt-7">
                      {state.message}
                    </Typography>
                  )}
                </div>
              );
            case 'error':
              return (
                <div className="m-2 flex h-auto w-auto flex-col justify-center gap-4 p-8">
                  <div className="flex justify-center">
                    <IconWrong stroke="red" width={40} height={40} />
                  </div>
                  <div className="flex justify-center">
                    <Typography size="body-regular-20" weight="medium" color="error">
                      {state.title}
                    </Typography>
                  </div>
                  <div className="flex justify-center">
                    <Typography id="modal-message" size="body-regular-18" color="grey-600">
                      {state.message}
                    </Typography>
                  </div>
                </div>
              );
            case 'success':
              return (
                <div className="m-2 flex h-auto w-auto flex-col justify-center gap-4 p-8">
                  <div className="flex justify-center">
                    <IconCheck stroke="green" width={40} height={40} />
                  </div>
                  <div className="flex justify-center">
                    <Typography
                      id="modal-message"
                      size="body-regular-18"
                      color="grey-600"
                      className="pt-1"
                    >
                      {state.title}
                    </Typography>
                  </div>
                </div>
              );
            default:
              return <>{children}</>;
          }
        })()}
      </div>
    </div>
  );
};

export default ModalLayout;
