import React, { useState } from 'react';
import Typography from '../typography/Typography';

export interface Step {
  title: string;
  component: React.ReactNode | any;
}
interface StepperProps {
  steps: Step[];
}

const StepperCard = ({ steps }: StepperProps) => {
  const [activeStep, setActiveStep] = useState<number | null>(0);

  if(steps.length === 1) return <>{steps[0].component}</>
  return (
    <div className="bg-white rounded-lg ">
      <div className="flex w-[50%] flex-row ">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`relative flex-1 cursor-pointer rounded-b-none rounded-t-[10px] p-5 ${
              activeStep === index
                ? 'z-10 border border-b-white'
                : 'border-b border-b-black bg-grey-200'
            }`}
            onClick={() => setActiveStep(index)}
          >
            <Typography
              size="body-regular-16"
              weight="medium"
              className={`${activeStep === index ? '' : ''} `}
            >
              {step.title}
            </Typography>
          </div>
        ))}
      </div>
      {activeStep !== null && (
        <div className="-mt-[1px] border-t">{steps[activeStep].component}</div>
      )}
    </div>
  );
};

export default StepperCard;
