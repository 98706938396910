import { Outlet } from 'react-router-dom';

import type { Routes } from '../../../imports/types';

type LayoutProps = {
  route: Routes;
};
const Layout = ({ route }: LayoutProps) => <Outlet context={route} />;

export default Layout;
