import { FunctionComponent, SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { ImageUser } from '../../../../assets/images';
import {
  Button,
  FilterDropdown,
  Icon,
  InnerPage,
  Input,
  OrderBySelect,
  Tooltip,
  Typography,
} from '../../../../components';
import {
  IconAccountManagement,
  IconCopy,
  IconInvisible,
  IconListPlus,
  IconLoading,
  IconResearch,
  IconVisible,
} from '../../../../icons';
import { getAllAnalytics, getProjectsNamesAndQuantity } from '../../../../imports/analyticsParser';
import { BLURRED_EMAIL } from '../../../../imports/constants';
import { getWorkspaceContracts } from '../../../../imports/contractsParsers';
import { Activities } from '../../../../imports/types';
import { copyToClipboard, truncateText } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { Customer } from '../../../team/imports/types';
import { getUsersStats } from '../../api/analytics';
import { UserCard } from '../../components';
import { ProjectToList } from '../../imports/types';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';

const Users = () => {
  const { t } = useTranslation(['tokenCreator']);
  const { workspace } = useAppSelector((state) => state.team);
  const { list } = useAppSelector((state) => state.contracts);
  const [nameFilter, setNameFilter] = useState('');
  const [visualization, setVisualization] = useState<string>('table');
  const { list: contractsList } = useAppSelector((state) => state.contracts);
  const [collectionFilter, setCollectionFilter] = useState<string[]>(['']);
  const [collectionList, setCollectionList] = useState<string[]>();
  const [showHideUsers, setShowHideUsers] = useState('visible');
  const [analytics, setAnalytics] = useState<Activities>();
  const [isLoading, setIsLoading] = useState(true);
  const [crmCustomers, setCrmCustomers] = useState<Customer[]>();

  const {
    workspaceGroupObject: { isSuperGroup },
  } = useWorkspaceAndGroup();

  const activeGroupId = isSuperGroup ? undefined : workspace?.activeGroupId;
  const getContracts = async () => {
    const ownedContracts: string[] = [];
    if (workspace) {
      const contracts = getWorkspaceContracts(workspace.id, list, activeGroupId);
      contracts.forEach((contract) => {
        ownedContracts.push(contract.name);
      });
    }
    setCollectionList(ownedContracts);
    setIsLoading(false);
  };

  const handleChangeSelection = (selection: string) => {
    let newFilter: string[] = [];

    if (collectionFilter.includes(selection)) {
      newFilter = collectionFilter.filter((item) => item !== selection);
    } else {
      newFilter = [...collectionFilter, selection];
    }
    setCollectionFilter(newFilter);
  };

  const haveCollections = (projects: ProjectToList[]) => {
    let have: boolean = false;
    collectionFilter.forEach((collect: string) => {
      const haveThis = projects.find((project: ProjectToList) => project.projectName === collect);
      if (haveThis !== undefined) have = true;
    });

    return have;
  };

  const customers = crmCustomers
    ?.filter(
      (customer: { name: string }) =>
        nameFilter === '' || customer.name?.toLowerCase().startsWith(nameFilter.toLowerCase())
    )
    .filter(
      (customer: { projects: ProjectToList[] }) =>
        collectionFilter.length === 1 || haveCollections(customer.projects)
    )
    .filter((customer: Customer) => (showHideUsers === 'hide' ? customer.hide : !customer.hide));

  const { open: openUserModal } = useModal('CrmUserModal');
  const handleClickUserModal = (customer: Customer, defaultModalType: string) => {
    openUserModal({ customer, defaultModalType });
  };

  const downloadUsers = async () => {
    if (!workspace?.owner) return;
    const stats = await getUsersStats(workspace?.owner);

    const rows = [
      ['Name', 'Email', 'Marketing Terms', 'Customer ID', 'Projects'],
      ...stats
        .filter((stat: any) => !stat?.hide)
        .map((stat: any) => [
          stat.name,
          stat.email,
          stat.marketingTerms,
          stat.customerId,
          stat.projects.join(' '),
        ]),
    ];

    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach((rowArray: any) => {
      const row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    window.open(csvContent);
  };

  const selectVislualizationOptions: {
    value: string;
    label: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: '',
      label: t('crm.table'),
      icon: IconListPlus,
    },
    {
      value: 'grid',
      label: t('crm.grid'),
      icon: IconAccountManagement,
    },
    {
      value: 'table',
      label: t('crm.table'),
      icon: IconListPlus,
    },
  ];
  const showHideSelectOptions: {
    value: string;
    label: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  }[] = [
    {
      value: '',
      label: t('crm.visible_users'),
      icon: IconVisible,
    },
    {
      value: 'visible',
      label: t('crm.visible_users'),
      icon: IconVisible,
    },
    {
      value: 'hide',
      label: t('crm.hidden_users'),
      icon: IconInvisible,
    },
  ];

  useEffect(() => {
    if (contractsList.length) {
      setAnalytics(getAllAnalytics(contractsList));
    }
  }, [workspace?.id, contractsList]);

  useEffect(() => {
    if (workspace) {
      getContracts();
      const crmCustomers: Customer[] = [];
      workspace.customers.forEach((customer: Customer) => {
        const crmCustomer = {
          ...customer,
          projects: getProjectsNamesAndQuantity(
            analytics?.activities || [],
            customer.customerId,
            contractsList
          ),
        };
        crmCustomers.push(crmCustomer);
      });
      setCrmCustomers(crmCustomers);
    }
  }, [workspace?.id, workspace?.customers, analytics?.activities]);

  return (
    <div className="flex min-h-[90vh] flex-col justify-between">
      <InnerPage>
        <header className="flex items-center justify-start gap-[18px]">
          <Input
            type="text"
            placeholder={t('crm.filters.name')}
            onChange={(event) => setNameFilter(event.target.value)}
            elementLeft={<Icon icon={IconResearch} stroke="grey-500" />}
            className="h-[36px] max-w-[270px] rounded-[4px] !border-grey-300 text-body-regular-12"
          />
          <FilterDropdown
            items={collectionList}
            set={handleChangeSelection}
            width="170"
            placeholder={t('crm.collection')}
          />
          <OrderBySelect
            items={selectVislualizationOptions}
            onChange={(value) => {
              setVisualization(value);
            }}
          />
          <OrderBySelect
            items={showHideSelectOptions}
            onChange={(value) => {
              setShowHideUsers(value);
            }}
          />
        </header>
        {isLoading ? (
          <div className="flex min-h-screen grow items-center justify-center">
            <IconLoading className="size-12 animate-spin text-primary-500" />
          </div>
        ) : (
          <div className="mt-[30px]">
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <Typography size="body-medium-14" color="primary-500">
                  CRM /
                </Typography>
                <Typography size="body-medium-30">CRM</Typography>
              </div>
              <div>
                <Button type="secondary" action={downloadUsers}>
                  {t('crm.download_all')}
                </Button>
              </div>
            </div>
            <Typography className="my-[20px]" size="body-medium-20">
              {t('members')}
            </Typography>
            {visualization === 'table' ? (
              <div className="mx-auto  max-w-[1600px] text-body-medium-18">
                <div className="grid h-[65px] w-full  grid-cols-4 items-center bg-primary-500 text-center text-white">
                  <Typography size="body-medium-18" weight="medium">
                    {t('crm.user_name')}
                  </Typography>
                  <Typography size="body-medium-18" weight="medium">
                    {t('crm.collections').toUpperCase()}
                  </Typography>
                  <Typography size="body-medium-18" weight="medium">
                    {t('crm.email')}
                  </Typography>
                  <Typography size="body-medium-18" weight="medium">
                    {t('crm.wallet_address')}
                  </Typography>
                </div>
                {customers?.map((customer: Customer) => (
                  <div
                    key={customer.address}
                    className="grid h-[65px] w-full grid-cols-4 items-center border-b border-grey-200 text-center text-grey-600"
                  >
                    <div
                      onClick={() => handleClickUserModal(customer, 'full')}
                      className="cursor-pointer"
                    >
                      <Typography size="body-medium-18" weight="medium">
                        {customer.name}
                      </Typography>
                    </div>
                    <div className="mx-auto h-[30px] w-[150px]">
                      <Button
                        type="secondary"
                        action={() => handleClickUserModal(customer, 'collections')}
                        className="!h-[30px] rounded-[4px]"
                      >
                        {t('crm.collections')}{' '}
                      </Button>
                    </div>
                    {customer.email === null ? (
                      <div
                        className="select-none"
                        style={{
                          color: 'grey-50',
                          textShadow: '0 0 5px rgba(0,0,0,0.5)',
                        }}
                      >
                        <Typography size="body-medium-18" weight="medium">
                          {BLURRED_EMAIL}
                        </Typography>
                      </div>
                    ) : (
                      <Typography size="body-medium-18" weight="medium">
                        {customer.email}
                      </Typography>
                    )}
                    <div className="flex justify-center gap-1">
                      <Typography size="body-medium-18" weight="medium">
                        {truncateText(customer.address, {
                          maxLength: 20,
                          position: 'middle',
                        })}
                      </Typography>
                      <Tooltip content={t('copy')}>
                        <Button
                          type="ghost"
                          action={() => copyToClipboard(customer.address)}
                          icon={IconCopy}
                        />
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <section className="flex w-full flex-wrap gap-[40px]">
                {customers?.map((customer: Customer) => (
                  <UserCard
                    key={customer.address}
                    leftImage={ImageUser}
                    email={customer.email}
                    onClick={() => handleClickUserModal(customer, 'full')}
                    name={customer.name}
                  />
                ))}
              </section>
            )}
          </div>
        )}
      </InnerPage>
    </div>
  );
};

export default Users;
