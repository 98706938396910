import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserDoc } from '../../api/firebase';
import { roles } from '../../imports/constants';
import { auth } from '../../imports/firebase';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { emailVerified as emailVerifiedAction } from '../../redux/user/user.slice';

import EmailScreen from '../../components/emailScreen/EmailScreen';

const SendVerificationEmail = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { uid } = useAppSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      const { value: valueProfileDoc } = await getUserDoc(uid);

      if (valueProfileDoc) {
        const { company, vat, sdi, office } = valueProfileDoc;
        const isLegalFieldsFilled = company && vat && sdi && office;

        const interval = setInterval(() => {
          if (auth.currentUser) {
            auth.currentUser.reload();

            if (auth.currentUser.emailVerified) {
              clearInterval(interval);
              dispatch(
                emailVerifiedAction({ role: isLegalFieldsFilled ? roles.consumer : roles.taker })
              );
            }
          }
        }, 1000);
      }
    })();
  }, []);
  return (
    <EmailScreen
      title={t('authentication.verify_email.check_your_email')}
      subtitle={t('authentication.verify_email.click_the_link_sent')}
    />
  );
};

export default SendVerificationEmail;
