import { createSlice } from '@reduxjs/toolkit';
import { Contract } from '../../modules/tokenCreator/imports/types';

export type ContractsState = {
  list: Contract[];
  api: { fetch: { loading: boolean } };
};

const initialState: ContractsState = {
  list: [],
  api: {
    fetch: {
      loading: false,
    },
  },
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    updateContract: (
      state,
      action: {
        payload: Contract;
        type: string;
      }
    ) => {
      const contractIndex = state.list.findIndex((contract) => contract.id === action.payload.id);
      if (contractIndex !== -1) {
        state.list[contractIndex] = { ...state.list[contractIndex], ...action.payload };
      } else {
        state.list.push(action.payload);
      }
      return state;
    },
    updateContracts: (state, action: { payload: Contract[]; type: string }) => {
      action.payload.forEach((newContract) => {
        const contractIndex = state.list.findIndex((contract) => contract.id === newContract.id);
        if (contractIndex !== -1) {
          state.list[contractIndex] = { ...state.list[contractIndex], ...action.payload };
        } else {
          state.list.push(newContract);
        }
      });
      return state;
    },
    removeContract: (
      state,
      action: {
        payload: string;
        type: string;
      }
    ) => {
      const idToRemove = action.payload;
      const updatedList = state.list.filter((contract) => contract.id !== idToRemove);
      state.list = updatedList;
      return state;
    },
    contractsFetching: (
      state,
      action: {
        payload: boolean;
        type: string;
      }
    ) => {
      state = {
        ...state,
        api: {
          ...state.api,
          fetch: {
            loading: action.payload,
          },
        },
      };
      return state;
    },
    logOutContracts: (state: ContractsState) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  logOutContracts,
  updateContract,
  updateContracts,
  removeContract,
  contractsFetching,
} = contractsSlice.actions;
export default contractsSlice.reducer;
