import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import 'reactflow/dist/style.css';
import { ContractsManager } from '../../modules/tokenCreator/components';
import { Contract } from '../../modules/tokenCreator/imports/types';
import Button from '../button/Button';
import ModalLayout from '../layouts/ModalLayout';

type ContractsManagerProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { availableContracts, setContracts } = useModalProps('ContractsManagerModal');
  const { t } = useTranslation(['tokenCreator', 'translation']);
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
  const handleSubmit = () => {
    setContracts(selectedContracts);
  };
  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="max-h-[95%] w-auto overflow-auto "
    >
      <div className="h-autow-auto mt-7 flex flex-col">
        <div className="flex h-[500px] p-4">
          <ContractsManager
            availableContracts={availableContracts}
            setContracts={setSelectedContracts}
          />
        </div>
        <div className="flex items-center justify-end">
          <Button
            action={handleSubmit}
            className="rounded bg-primary-500 px-4 py-2 font-bold text-white hover:bg-primary-400"
          >
            {t('token_manager.save')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const ContractsManagerModal = ({ children }: ContractsManagerProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ContractsManagerModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default ContractsManagerModal;
