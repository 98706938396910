import i18next, { t } from 'i18next';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { sendResetPasswordEmail } from '../../api/firebase';
import { MainLogo } from '../../assets/images';
import { Button, EmailScreen, Field, Form, Image, Input, Typography } from '../../components';
import { useValidation } from '../../hooks';

interface FormValues {
  email: string;
}

enum PageState {
  FORM,
  EMAIL_SENT,
}

const ForgotPassword = () => {
  const [currentState, setCurrentState] = useState<PageState>(PageState.FORM);
  const { required } = useValidation();
  const initialValues: FormValues = {
    email: '',
  };

  const navigate = useNavigate();
  const validationSchema = object({ email: required(string().email()) });

  const handleForgotPassword: SubmitHandler<FormValues> = async (values) => {
    const { email } = values;
    const { error } = await sendResetPasswordEmail(email);
    if (error) {
      const errorKey = `authentication.errors.${error.code}`;
      if (i18next.exists(errorKey)) {
        toast.error(t(errorKey) as string);
        return;
      }

      toast.error(t('be_error.generic') as string);
      return;
    }

    setCurrentState(PageState.EMAIL_SENT);
  };

  switch (currentState) {
    case PageState.FORM:
      return (
        <section className="flex h-[100vh] w-full flex-col items-center justify-center align-middle">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            className="mx-auto flex h-full flex-col content-center justify-center gap-[60px] px-4 sm:w-screen md:w-[450px] "
          >
            {({ errors, handleSubmit, fields, isValid }) => (
              <>
                <Image src={MainLogo} className="z-50 mx-auto w-[217px]" />
                <Typography
                  weight="semibold"
                  size="body-semibold-30"
                  color="grey-600"
                  className="text-center "
                >
                  {t('authentication.forgot.cta') as string}
                </Typography>
                <Field
                  error={errors?.email?.message}
                  label={t('feedback.form_fields.form_placeholders.email')}
                  asterisk
                >
                  <Input
                    className="mt-[5px] "
                    error={errors?.email?.message}
                    placeholder={t('authentication.form_placeholders.email')}
                    name={fields.email.name}
                    id={fields.email.name}
                    onChange={fields.email.onChange}
                    onBlur={fields.email.onBlur}
                    inputRef={fields.email.ref}
                  />
                </Field>
                <div className="flex justify-between w-full">
                  <Button
                    action={() => navigate(-1)}
                    className=" text-body-medium-16"
                    type="secondary"
                  >
                    {t('authentication.back') as string}
                  </Button>

                  <Button
                    action={handleSubmit(handleForgotPassword)}
                    className="text-body-medium-16"
                    disabled={!isValid}
                  >
                    {t('authentication.forgot.send_link') as string}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </section>
      );
    case PageState.EMAIL_SENT:
      return (
        <EmailScreen
          title={t('authentication.forgot.email_title')}
          subtitle={t('authentication.forgot.email_subtitle')}
        />
      );

    default:
      return <></>;
  }
};

export default ForgotPassword;
