import { doc, getDoc } from 'firebase/firestore';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useGetGroups } from '../hooks/useGetGroups';
import { IconLoading } from '../icons';
import { db } from '../imports/firebase';
import { getTeam, getWorkspaceCustomers } from '../modules/team/api/api';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setWorkspace } from '../redux/team/team.slice';

// NOTE: this provider is not doing anythign right now, we have disabled the getteam to avoid call on every 📄
// we choose to fetch teams only on ManageTeams page to prevent useless refetch
const TeamWrapper = (props: PropsWithChildren) => {
  const { children } = props;
  const { uid } = useAppSelector((state) => state.user);
  const { workspace } = useAppSelector((state) => state.team);
  const dispatch = useAppDispatch();
  const [startQuery, setStartQuery] = useState(false);
  const { isLoading, data } = useQuery(
    ['owned-team', uid, workspace?.id, workspace?.activeGroupId],
    () => getTeam(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: uid != null && uid !== '' && startQuery,
      // enabled: false,
      refetchInterval: 5000000, // 5 minutes
    }
  );

  const { groups } = useGetGroups(workspace?.id);

  useEffect(() => {
    (async function () {
      if (data != null && uid) {
        let workspaceToSet = data[0];
        if (workspace?.id) {
          workspaceToSet = data.find((ws) => ws.id === workspace.id) ?? data[0];
        }
        const memberResponse = await getDoc(
          doc(db, 'workspaces', workspaceToSet.id, 'members', uid)
        );
        const member = memberResponse.data()!;
        const customers = await getWorkspaceCustomers(workspace?.owner || '');
        dispatch(
          setWorkspace({
            id: workspaceToSet.id,
            owner: workspaceToSet.owner,
            name: workspaceToSet.name,
            permissions: member.permissions,
            ownerAddress: workspaceToSet.owner_address,
            isOwner: workspaceToSet.owner === uid,
            customers,
            groups,
          })
        );
      }
    })();
  }, [data, uid, groups]);

  if (isLoading) {
    return (
      <div className="flex min-h-screen grow items-center justify-center" id="teamwrapper">
        <IconLoading className="size-12 animate-spin text-primary-500" />
      </div>
    );
  }

  return <>{children}</>;
};

export default TeamWrapper;
