import _ from 'lodash';
import { Contract, Nft, ProjectToList } from '../modules/tokenCreator/imports/types';
import { Activity, Scores } from './types';

type AnalyticsParsed = {
  redeem: number;
  transfer: number;
  tokenVerification: number;
};
type GamingAnalyticsParsed = {
  activePlayers: number;
  totalScore: number;
  games: number;
};

/**
 *
 * @param activities
 * @param nftId
 * @returns sum: AnalyticsParsed, nftActivities: Activity[]
 */
export const getNftAnalytics = (activities: Activity[], nftId: number) => {
  const analyticsParsed: AnalyticsParsed = { redeem: 0, transfer: 0, tokenVerification: 0 };
  const nftActivities: any[] = [];
  const rawAnalytics = activities?.filter((activity: any) => activity.data.token_id === nftId);
  rawAnalytics?.forEach((activity: any) => {
    if (activity.type === 'redeem') {
      analyticsParsed.redeem += 1;
      nftActivities.push(activity);
    }
    if (activity.type === 'transfer') {
      analyticsParsed.transfer += 1;
      nftActivities.push(activity);
    }
    if (activity.type === 'tokenVerification') {
      analyticsParsed.tokenVerification += 1;
      nftActivities.push(activity);
    }
  });
  return { sum: analyticsParsed, nftActivities };
};

/**
 * Given the collection activities, returns all the analytics of a collection
 * @param activities
 * @returns sum: AnalyticsParsed, activities: Activity[]
 */
export const getCollectionAnalytics = (activities: Activity[]) => {
  const analyticsParsed: AnalyticsParsed = { redeem: 0, transfer: 0, tokenVerification: 0 };
  if (!activities || activities.length === 0) return { sum: analyticsParsed, activities: [] };
  activities.forEach((activity: any) => {
    if (activity.type === 'redeem') {
      analyticsParsed.redeem += 1;
    }
    if (activity.type === 'transfer') {
      analyticsParsed.transfer += 1;
    }
    if (activity.type === 'tokenVerification') {
      analyticsParsed.tokenVerification += 1;
    }
  });

  return { sum: analyticsParsed, activities };
};

/**
 * Returns all the workspace-contracts analytics
 * @param contractsList
 * @returns sum: AnalyticsParsed, activities: Activity[]
 */
export const getAllAnalytics = (contractsList: Contract[]) => {
  const analyticsParsed: AnalyticsParsed = { redeem: 0, transfer: 0, tokenVerification: 0 };
  const activities: any[] = [];
  contractsList.forEach((contract: Contract) => {
    if (!contract.activities) return;
    contract.activities.forEach((activity: any) => {
      if (activity.type === 'redeem') {
        analyticsParsed.redeem += 1;
        activities.push(activity);
      }
      if (activity.type === 'transfer') {
        analyticsParsed.transfer += 1;
        activities.push(activity);
      }
      if (activity.type === 'tokenVerification') {
        analyticsParsed.tokenVerification += 1;
        activities.push(activity);
      }
    });
  });
  return { sum: analyticsParsed, activities };
};

/**
 * Returns all customer's projects and quantity
 * @param analytics
 * @param customerId
 * @param contractsList
 * @returns projectsList: ProjectToList[]
 */
export const getProjectsNamesAndQuantity = (
  analytics: Activity[],
  customerId: string,
  contractsList: Contract[]
): ProjectToList[] => {
  const projectsList: ProjectToList[] = [];
  const projects: { [key: string]: number } = {};
  analytics.forEach((analytic: Activity) => {
    if (analytic.type === 'redeem' && analytic.data.customer_uid === customerId) {
      if (projects[analytic.data.contract_id]) {
        projects[analytic.data.contract_id] += 1;
      } else {
        projects[analytic.data.contract_id] = 1;
      }
    }
    if (analytic.type === 'transfer' && analytic.data.quantity) {
      if (analytic.data.receiver_uid === customerId) {
        if (projects[analytic.data.contract_id]) {
          projects[analytic.data.contract_id] += analytic.data.quantity;
        } else {
          projects[analytic.data.contract_id] = +analytic.data.quantity;
        }
      }
      if (analytic.data.customer_uid === customerId) {
        if (projects[analytic.data.contract_id]) {
          projects[analytic.data.contract_id] -= analytic.data.quantity;
        } else {
          projects[analytic.data.contract_id] = -analytic.data.quantity;
        }
      }
    }
  });

  Object.keys(projects).forEach((project) => {
    if (projects[project] > 0) {
      const projectName =
        contractsList.find((contract: Contract) => contract.id === project)?.name || '';
      projectsList.push({
        projectName,
        projectId: project,
        quantity: projects[project],
      });
    }
  });
  return projectsList.sort((a, b) => a.projectName.charCodeAt(0) - b.projectName.charCodeAt(0));
};

/**
 *
 * @param contractsList
 * @param contractId
 * @returns gamingAnalyticsParsed: GamingAnalyticsParsed
 */
export const getCollectionGamingAnalytics = (
  contractsList: Contract[],
  contractId: string
): GamingAnalyticsParsed => {
  const gamingAnalyticsParsed: GamingAnalyticsParsed = {
    activePlayers: 0,
    totalScore: 0,
    games: 0,
  };
  const players: string[] = [];
  const score: number[] = [];
  contractsList.forEach((contract: Contract) => {
    if (contract.id === contractId) {
      if (!contract.scores || contract.scores.length === 0) return;
      contract.scores.forEach((game: Scores) => {
        players.push(game.user);
        score.push(game.score);
        gamingAnalyticsParsed.totalScore += game.score;
      });
    }
  });
  gamingAnalyticsParsed.games = score.length;
  gamingAnalyticsParsed.activePlayers = _.uniqBy(players, (player) => player).length;
  return gamingAnalyticsParsed;
};

/**
 * Get the best scores of a user for a game ordered by score
 * @param contractsList
 * @param contractId
 * @ returns highestScores: Scores[]
 */
export const getGamingLeaderboards = (contractsList: Contract[], contractId: string): Scores[] => {
  if (!contractsList) return [];

  const userScoresMap: Map<string, Scores> = new Map();
  contractsList?.forEach((contract: Contract) => {
    if (contractId === contract.id) {
      if (!contract?.scores || contract?.scores?.length === 0) return;

      contract.scores.forEach((game: Scores) => {
        const prevScore = userScoresMap.get(game.user);
        if (!prevScore || game.score > prevScore.score) {
          userScoresMap.set(game.user, game);
        }
      });
    }
  });
  const highestScores: Scores[] = Array.from(userScoresMap.values()).sort(
    (a, b) => b.score - a.score
  );
  return highestScores;
};

export const getTotalNftsQuantity = (nfts: Nft[]) => {
  // Inizializza la variabile di somma a 0
  let sumQuantity = 0;
  let unlimited = false;
  // Loop sugli oggetti e somma il campo "quantity"
  nfts?.forEach((nft) => {
    if (nft.quantity === 0) {
      unlimited = true;
    } else {
      sumQuantity += nft.quantity;
    }
  });
  return { sumQuantity, unlimited };
};
