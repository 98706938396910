/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

const IconInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1708_42205)">
      <path
        d="M96 168C135.765 168 168 135.765 168 96C168 56.2355 135.765 24 96 24C56.2355 24 24 56.2355 24 96C24 135.765 56.2355 168 96 168Z"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90 90H96V132H102"
        stroke={props.stroke ? props.stroke : '#4F4F4F'}
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5 72C99.4706 72 103.5 67.9706 103.5 63C103.5 58.0294 99.4706 54 94.5 54C89.5294 54 85.5 58.0294 85.5 63C85.5 67.9706 89.5294 72 94.5 72Z"
        fill={props.stroke ? props.stroke : '#4F4F4F'}
      />
    </g>
    <defs>
      <clipPath id="clip0_1708_42205">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconInfo;
