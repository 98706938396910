import axios from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image, Typography } from '../../../../components';
import { IconLoading } from '../../../../icons';
import { paidIpfsGateway } from '../../../../imports/constants';
import { formatIPFSUri } from '../../../../imports/utils';
import { Contract, Nft } from '../../imports/types';

interface NftHistoryModalProps {
  contract: Contract;
  nft: Nft;
}

const NftHistoryModal = ({ contract, nft }: NftHistoryModalProps): JSX.Element => {
  const { t } = useTranslation(['tokenCreator']);

  const fetchNft = async (metadataUri: string, nftId: string) => {
    try {
      const json = await axios.get(`${paidIpfsGateway}/${metadataUri}/${Number(nftId) - 1}.json`);
      return json.data;
    } catch (err) {
      console.error(err);
    }
  };

  const historyArray = contract.updateHistory ? [...contract.updateHistory] : [];

  return (
    <>
      {historyArray.length > 0 && (
        <motion.div
          initial={{ x: '100%', y: '0' }}
          animate={{ x: '0', y: '0' }}
          exit={{ x: '120%', y: '0' }}
          transition={{ type: 'spring', bounce: 0.4, duration: 1 }}
          className="absolute right-0 top-full z-50 flex w-[400px] flex-col  justify-end overflow-hidden rounded-3xl bg-white p-[32px] shadow-2xl"
        >
          <div className="mb-[32px] w-full ">
            <Swiper
              navigation
              modules={[Navigation]}
              spaceBetween={200}
              initialSlide={historyArray.length - 1}
            >
              {historyArray.map(({ replacedAt, metadataUri }, i) => {
                const [nftToShow, setNftToShow] = useState<Nft>();
                if (!nft?.id) throw new Error('no id in Nft object');

                useEffect(() => {
                  fetchNft(metadataUri, nft.id.toString()).then((nft) => setNftToShow(nft));
                }, []);

                return (
                  <SwiperSlide key={i}>
                    <>
                      <Typography weight="semibold" className="mb-[32px]">
                        {`${t('replaced_at')}: ${new Date(replacedAt).toUTCString()}`}
                      </Typography>
                      {nftToShow ? (
                        <div className="w-full">
                          <div className="flex justify-between">
                            <Typography weight="semibold">{t('nft.name')}</Typography>
                            <Typography>{nftToShow.name}</Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography weight="semibold">{t('nft.description')}</Typography>
                            <Typography>{nftToShow.description}</Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography weight="semibold">{t('nft.external_url')}</Typography>
                            <Typography>{nftToShow.external_url}</Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography weight="semibold">{t('nft.quantity')}</Typography>
                            <Typography>{nftToShow.quantity}</Typography>
                          </div>
                          {/* //TODO: Need to format value correctly, poor fix for Product Information
                          data */}
                          {nftToShow.attributes?.map(({ trait_type, value }) => (
                            <div className="mt-[10px] flex justify-between">
                              <Typography weight="semibold">{trait_type}</Typography>
                              <Typography>
                                {typeof value === 'string' ? value : JSON.stringify(value)}
                              </Typography>
                            </div>
                          )) || []}
                          <Image
                            alt={nftToShow.name}
                            src={formatIPFSUri(nftToShow.image as string)}
                            type="cover"
                            className="mx-auto mt-[12px] aspect-square max-w-[200px]"
                          />
                        </div>
                      ) : (
                        <div className="flex grow items-center justify-center">
                          <IconLoading className="h-6 w-6 animate-spin text-primary-500" />
                        </div>
                      )}
                    </>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default NftHistoryModal;
