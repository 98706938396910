import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ethers } from 'ethers';
import { collection, doc, setDoc } from 'firebase/firestore';
import { PablockSDK } from 'pablock-sdk';
import Papa from 'papaparse';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { Location as ReactLocation } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useModal } from 'react-simple-modal-provider';
import mintAbi from '../../../../abi/mint';
import { Button, Field, InnerPage, Input, SectionTitle, Select } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { useValidation } from '../../../../hooks';
import useValidateSend from '../../../../hooks/useValidateSend';
import { IconClose } from '../../../../icons';
import { apiEndpoint, pablockApiKey, pablockConfig } from '../../../../imports/constants';
import { auth, db } from '../../../../imports/firebase';
import { UserState } from '../../../../imports/types';
import { capitalizeFirstLetter } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { Contract, TSendFormValues, TTransaction } from '../../imports/types';
import { bcodeSDK } from '../../../../imports/bcodeSDK';

interface Location<State> extends Omit<ReactLocation, 'state'> {
  state: State;
}

type CustomState = {
  contract?: Contract;
  failedTransactions?: TTransaction[];
};

const ManualRedeem = () => {
  const { t } = useTranslation(['tokenCreator']);
  const { open: openSendNftModal } = useModal('SendNftModal');

  const navigate = useNavigate();
  const { state } = useLocation() as Location<CustomState>;

  const inputRef = useRef<HTMLInputElement>(null);

  const contract = state?.contract!;
  const preFilledTransactions = state?.failedTransactions;

  const { dispatch: loadingStatusDispatch, state: LoadingState } = useLoadingStatusContext();

  const {
    wallet: { privateKey },
    uid,
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { required } = useValidation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    watch,
    control,
    setError,
    setValue,
  } = useForm<TSendFormValues>({
    defaultValues: {
      values: preFilledTransactions
        ? preFilledTransactions.map(({ to, category, quantity }) => ({
            address: to,
            category,
            quantity,
          }))
        : [{ address: '', category: '', quantity: 1 }],
    },

    resolver: yupResolver(
      yup.object({
        values: yup.array().of(
          yup.object().shape({
            address: required(
              yup
                .string()
                .matches(/^0x[0-9a-fA-f]{40}$/g, t('send.errors.address.not_valid'))
                .transform((val) => val.toLowerCase())
            ),
            category: required(yup.string()),
            quantity: required(yup.number().min(1)),
          })
        ),
      })
    ),
    mode: 'onSubmit',
  });

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'values',
  });

  const validateForm = useValidateSend({
    contract,
    setError,
  });

  const handleCsv = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e?.target?.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        const headerAttr = results.meta.fields;
        const mandatoryFields = ['address', 'category', 'quantity'];
        if (!mandatoryFields.every((el) => headerAttr.includes(el))) {
          toast.error('Wrong CSV format');
          return;
        }
        const trans = results.data.map((el: any) => ({
          address: el.address,
          category: parseInt(el.category, 10),
          quantity: parseInt(el.quantity, 10),
        }));

        remove(0);

        setValue('values', trans);
      },
    });
  };

  const handleNftsSend = async ({ values }: TSendFormValues) => {
    openSendNftModal({});
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        title: t('send.send_action_pending'),
      },
    });

    try {
      const valid = await validateForm(values);

      if (!valid) throw new Error('FormError');

      if (!bcodeSDK.isInitialized()) {
        await bcodeSDK.init();
      }

      bcodeSDK.setPrivateKey(privateKey);

      if (!contract) {
        throw new Error('No contract');
      }

      const nonce = await bcodeSDK.getNonce(bcodeSDK.getWalletAddress());
      console.log(nonce, contract.name);
      await Promise.all(
        values.map(async ({ address: recipientAddress, category, quantity }, index) => {
          const metaTx = await bcodeSDK.prepareTransaction(
            {
              address: contract.address,
              abi: mintAbi,
              name: capitalizeFirstLetter(contract.name).replace(/\s/g, ''),
              version: '1.0',
            },
            'mint',
            [recipientAddress, category, quantity, ethers.utils.formatBytes32String('')],
            { nonce: nonce.toNumber() + index }
          );

          const createdAt = Date.now();
          const updatedAt = createdAt;

          const categoryName = contract.nfts.find((nft) => nft.id.toString() === category)?.name;

          const firebaseToken = (await auth.currentUser?.getIdToken()) as string;
          const { requestId } = (
            await axios.post(
              `${apiEndpoint}/executeManual`,
              {
                metaTx,
              },
              {
                headers: {
                  'x-access-token': firebaseToken,
                },
              }
            )
          ).data;

          if (!requestId) {
            console.warn('REQUEST ID NOT RETURNED BY BACKEND');
            return;
          }

          console.log('Request ID: ', requestId);

          // const requestId: string = await bcodeSDK.executeAsyncTransaction(metaTx, {
          //   webhookUrl: `${apiEndpoint}/saveReceipt`,
          //   verbose: true,
          //   metadata: {
          //     category,
          //     categoryName,
          //     uid,
          //     quantity,
          //     createdAt,
          //     updatedAt,
          //     contractId: contract.id,
          //     manual: true,
          //     from: bcodeSDK.wallet.address,
          //     to: recipientAddress,
          //   },
          // });

          // TODO: uniform to TC & WP standard
          //TODO: delete
          const docRef = doc(collection(db, 'contracts', contract.id, 'manual'), requestId);
          await setDoc(docRef, {
            requestId,
            category,
            quantity,
            from: bcodeSDK?.wallet?.address,
            categoryName,
            to: recipientAddress,
            status: 'pending',
            createdAt,
            updatedAt,
          });

          return requestId;
        })
      );

      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('send.send_action_success'),
        },
      });
      navigate(-1);
    } catch (error) {
      const errorMsg =
        error instanceof Error && error.message === 'FormError'
          ? t('send.errors.form')
          : t('send.send_action_failed');
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          title: errorMsg,
        },
      });
    }
  };

  useEffect(() => {
    if (!contract) {
      navigate(-1);
    }
  }, [contract]);

  return (
    <>
      {contract && (
        <InnerPage>
          <SectionTitle title={t('send.title')} onBack={() => navigate(-1)} />
          {!LoadingState.status && (
            <form className="flex w-full flex-col items-center space-y-8">
              <div className="w-full">
                <input
                  className="hidden"
                  type="file"
                  accept=".csv"
                  ref={inputRef}
                  onChange={(e) => handleCsv(e)}
                />

                <Button
                  type="ghost"
                  className="mt-[32px] w-[200px]"
                  action={() => inputRef.current?.click()}
                >
                  {t('send.csv')}
                </Button>
              </div>

              <div className="w-full space-y-4">
                {fields.map(({ id }, index) => {
                  const addressField = register(`values.${index}.address`);
                  const categoryField = register(`values.${index}.category`);
                  const quantity = register(`values.${index}.quantity`);

                  const categoryFieldValue = watch(`values.${index}.category`);
                  const nft = contract.nfts.find((nft) => String(nft.id) === categoryFieldValue);

                  return (
                    <div className="relative grid grid-cols-3 gap-x-8" key={index}>
                      {fields.length !== 1 && (
                        <Button
                          icon={IconClose}
                          className="absolute top-0 right-0"
                          action={() => {
                            remove(index);
                          }}
                          iconSize="sm"
                          type="ghost"
                        />
                      )}

                      <Field
                        label={t('send.form_labels.address')}
                        error={errors.values?.[index]?.address?.message}
                      >
                        <Input
                          type="text"
                          placeholder={t('send.form_placeholders.address')}
                          name={addressField.name}
                          onBlur={addressField.onBlur}
                          onChange={addressField.onChange}
                          inputRef={addressField.ref}
                          error={errors.values?.[index]?.address?.message}
                          elementRight={
                            <Button
                              type="ghost"
                              icon={IconClose}
                              action={() => resetField(addressField.name)}
                            />
                          }
                        />
                      </Field>
                      <Field
                        label={t('send.form_labels.category')}
                        error={errors.values?.[index]?.category?.message}
                      >
                        <Select
                          options={contract.nfts.map((nft) => ({
                            value: String(nft.id),
                            label: nft.name,
                          }))}
                          name={categoryField.name}
                          onBlur={categoryField.onBlur}
                          onChange={categoryField.onChange}
                          inputRef={categoryField.ref}
                          error={errors.values?.[index]?.category?.message}
                        />
                      </Field>
                      <Field
                        label={t('send.form_labels.quantity')}
                        error={errors.values?.[index]?.quantity?.message}
                      >
                        <Input
                          type="number"
                          minValue="1"
                          maxValue={nft?.quantity ? String(nft?.quantity) : ''}
                          placeholder={t('send.form_placeholders.quantity')}
                          name={quantity.name}
                          onBlur={quantity.onBlur}
                          onChange={quantity.onChange}
                          inputRef={quantity.ref}
                          error={errors.values?.[index]?.quantity?.message}
                          elementRight={
                            <Button
                              type="ghost"
                              icon={IconClose}
                              action={() => resetField(quantity.name)}
                            />
                          }
                          disabled={!nft}
                        />
                      </Field>
                    </div>
                  );
                })}
              </div>

              <div className="flex flex-col items-center space-y-8">
                <Button
                  type="secondary"
                  action={() => append({ address: '', category: '', quantity: 1 })}
                >
                  {t('send.add_nft')}
                </Button>

                <Button action={() => handleSubmit(handleNftsSend)()}>{t('send.send_nfts')}</Button>
              </div>
            </form>
          )}
        </InnerPage>
      )}
    </>
  );
};

export default ManualRedeem;
