import { createContext, useContext, useEffect, useState } from 'react';

type Status = 'error' | 'success' | '';

interface LoadingState {
  isLoading: boolean;
  setLoading: ({ loading, status }: { loading: boolean; status?: Status }) => void;
  status: 'error' | 'success' | '';
  resetLoading: () => void;
}

const LoadingContext = createContext<LoadingState>({
  isLoading: false,
  setLoading: () => {},
  status: '',
  resetLoading: () => {},
});

interface LoadingProps {
  children: React.ReactNode;
}

export const LoadingProvider = ({ children }: LoadingProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<Status>('');

  const resetLoading = () => {
    setStatus('');
    setIsLoading(false);
  };

  const setLoading = ({ loading, status }: { loading: boolean; status?: Status }) => {
    setStatus(status || '');
    setIsLoading(loading);
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { isLoading, setLoading, status, resetLoading };

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('no-action');
    } else {
      document.body.classList.remove('no-action');
    }
  }, [isLoading]);

  return (
    <LoadingContext.Provider value={value}>
      <>
        {/* {loading ? (
          <div className="absolute z-[99] flex h-screen w-screen items-center justify-center bg-black bg-opacity-60">
            <IconLoading className="size-12 animate-spin" />
          </div>
        ) : (
          <></>
        )} */}
        {children}
      </>
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
