import { ReactNode, useState } from 'react';
// import { Form } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { IconArrow } from '../../icons';
import Icon from '../icon/Icon';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type ReadMessageModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen, isOpen }: any) => {
  const { msg } = useModalProps('ReadMessageModal');
  const [showImage, setShowImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('tokenCreator');

  return (
    <ModalLayout
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      classNameLayout="overflow-auto max-w-[85%] max-h-[95%]"
      width={showImage ? 'auto' : '540px'}
      height={showImage ? 'auto' : '640px'}
    >
      {showImage ? (
        <div className="overflow-auto  pt-[40px] pb-7 ">
          <div className="mx-auto max-h-[60%] max-w-[85%]">
            <Icon
              icon={IconArrow}
              onClick={() => setShowImage(false)}
              size="40"
              stroke="primary-500"
              className="mb-3 cursor-pointer rounded-md"
            />
            <img src={msg.image} alt={t('messages.img_alt')} className="rounded-[10px]" />{' '}
          </div>
        </div>
      ) : (
        <div className="overflow-auto px-[40px] pt-[75px]">
          <div
            className="mb-7 flex flex-col space-y-2 overflow-hidden"
            onClick={() => setShowImage(true)}
          >
            {msg.image && (
              <img
                // src={msg.image}
                src={msg.image}
                alt={t('messages.img_alt')}
                className="h-[200px] w-[200px] rounded-md object-cover"
                width={200}
                height={200}
              />
            )}
          </div>
          <Typography size="body-medium-30" weight="medium" color="primary-500">
            {msg.title}
          </Typography>

          <Typography size="body-regular-20" className="mt-[20px]">
            {msg.description}
          </Typography>
        </div>
      )}
    </ModalLayout>
  );
};

const ReadMessageModal = ({ children }: ReadMessageModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="ReadMessageModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} isOpen={isOpen}></ModalBody>
    </Modal>
  );
};

export default ReadMessageModal;
