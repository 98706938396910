import { useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, Typography } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import TemplateCard from '../../../../components/cards/TemplateCard';
import { IconClose, IconResearch } from '../../../../icons';
import { Filters, FiltersActionType } from '../../../../imports/types';
import { compare, filterCollection } from '../../../../imports/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { StandardTemplateNames, Template } from '../../imports/types';
import { setTemplate } from '../../../../redux/templates/templates.slice';

interface ITemplateStep
  extends Omit<
    ExportedProps,
    'trigger' | 'register' | 'getValues' | 'watch' | 'isValid' | 'clearErrors' | 'dirtyFields'
  > {
  isLoading: boolean;
  contractsLeft: number;
  values?: any;
  onBack: () => void;
  onCompleted: () => void;
  templates: Template[];
}

const TemplateStep = ({
  contractsLeft,
  values,
  isLoading,
  onBack,
  templates,
  onCompleted,
  setValue,
  reset,
  errors,
}: ITemplateStep) => {
  const { t } = useTranslation(['tokenCreator']);
  const [selectedTemplate, setSelectedTemplate] = useState<StandardTemplateNames>('');
  const filtersInitialState: {
    [key: string]: {
      filter: Filters;
      value: any;
    };
  } = {
    inputFilterByName: { filter: 'FUZZY_MATCH', value: '' },
  };
  const filtersReducer = (state: typeof filtersInitialState, action: FiltersActionType) => {
    switch (action.type) {
      case 'SET_STATE':
        return {
          ...state,
          [action.filterFieldName]: { ...state[action.filterFieldName], value: action.payload },
        };
      default:
        return state;
    }
  };
  const [filtersState, filtersDispatch] = useReducer(filtersReducer, filtersInitialState);
  const { inputFilterByName } = filtersState;
  const { workspace } = useAppSelector((state) => state.team);
  const [showTemplate, setShowTemplate] = useState<Array<Template>>(templates);
  const dispatch = useDispatch();

  const handleSetTemplate = (template: Template) => {
    dispatch(setTemplate(template));
    setValue('templateId', template.id);
    onCompleted();
  };

  const resetFilterField = (filterFieldName: FiltersActionType['filterFieldName']) => {
    filtersDispatch({
      type: 'SET_STATE',
      filterFieldName,
      payload: '',
    });
  };
  function compareTemplates(template1: Template, template2: Template): number {
    // Ordina per type
    if (template1.type === 'standard' && template2.type === 'private') {
      return -1;
    }
    if (template1.type === 'private' && template2.type === 'standard') {
      return 1;
    }

    // Se template sono tutti 'private' o 'standard', ordina per 'by'(azienda)
    if (
      template1.type === 'private' &&
      template2.type === 'private' &&
      template1.by &&
      template2.by
    ) {
      if (template1.by < template2.by) {
        return -1;
      }
      if (template1.by > template2.by) {
        return 1;
      }
    }
    // Se 'by' è uguale o manca, ordina per 'orderBy'(ordine che deciadiamo noi in fase di creazione del template)
    if (template1.orderBy && template2.orderBy) {
      if (template1.orderBy < template2.orderBy) {
        return -1;
      }
      if (template1.orderBy > template2.orderBy) {
        return 1;
      }
    }

    return 0;
  }
  // Filtra gli oggetti che hanno il nome presente in templatePack
  useEffect(() => {
    const allowedTemplate = workspace?.templatePack || [];
    const allowedFilter = showTemplate.filter(
      (template) => allowedTemplate.includes(template.pack) && template.name !== 'standard'
    );
    const sortedFilter = allowedFilter.sort(compareTemplates);
    setShowTemplate(sortedFilter);
  }, [workspace]);

  const filteredTemplates = useMemo(() => {
    const filteredCollection = filterCollection({
      filtersInOrder: [
        {
          type: inputFilterByName.filter,
          propertyToFilter: ['name', 'txHash', 'folderName'],
          filterValue: inputFilterByName.value,
        },
      ],
      collection: showTemplate,
    });

    return filteredCollection
      .filter((template) => template.name !== 'standard')
      .sort((a, b) => compare(a, b, 'name', 'desc'));
  }, [templates, inputFilterByName]);

  return (
    <>
      <div className="h-full space-y-12">
        <Typography size="body-semibold-30">{t('create_contract.titles.template')}</Typography>
        <div className="ml-0">
          <div className="w-[260px]  ">
            <Input
              type="text"
              value={inputFilterByName.value}
              placeholder={t('collection.filters.name')}
              onChange={(event) =>
                filtersDispatch({
                  type: 'SET_STATE',
                  filterFieldName: 'inputFilterByName',
                  payload: event.target.value,
                })
              }
              elementLeft={<Icon icon={IconResearch} stroke="grey-500" />}
              elementRight={
                inputFilterByName.value && (
                  <Button
                    type="ghost"
                    icon={IconClose}
                    action={() => resetFilterField('inputFilterByName')}
                  />
                )
              }
              className="h-[36px] rounded-[4px] !border-grey-300 !text-body-regular-12 text-grey-500"
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-y-10 gap-x-12">
          {showTemplate.length ? (
            showTemplate.map((template) => {
              if (!template.old) {
                return (
                  <TemplateCard
                    key={template.id}
                    isSelected={selectedTemplate === template.name}
                    template={template}
                    onClick={() => handleSetTemplate(template)}
                  />
                );
              }
              return <></>;
            })
          ) : (
            <Typography className="w-full text-center">
              {t('create_contract.template.no_template_available')}
            </Typography>
          )}
        </div>
      </div>

      <div className="mb-20 flex w-full justify-between">
        <Button id="cc-step-1-back" action={onBack} type="secondary" className="w-[120px]">
          {t('create_contract.form_labels.back') as string}
        </Button>
      </div>
    </>
  );
};

export default TemplateStep;
