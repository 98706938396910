import axios from 'axios';
import { backendEndpoint } from '../../../imports/constants';

import { auth } from '../../../imports/firebase';
import { cleanNftData } from '../../../imports/storage';
import type { ContractMetadata, Nft } from '../imports/types';

export async function uploadContractInfoIpfs(
  contractImage: File,
  metadata: ContractMetadata,
  workspaceId: string
) {
  if (!contractImage) {
    return { contractURI: '', image: '' } as { contractURI: string; image: string };
  }

  const formdata = new FormData();
  formdata.append('files', contractImage);
  Object.entries(metadata).forEach(([key, value]) => formdata.set(key, value));

  const { data } = await axios.post(`${backendEndpoint}/pinContractInfo`, formdata, {
    headers: {
      'Content-Type': 'multipart/formdata',
      authorization: (await auth.currentUser?.getIdToken()) || '',
      contractId: metadata.contractId,
      workspaceId,
    },
  });

  return data as { contractURI: string; image: string };
}

/**
 * @description Function to upload NFT token's metadata on IPFS
 * @param nfts
 * @param contractId
 * @param workspaceId
 * @returns {metadataCid, imagesCid}
 */
export async function uploadNftsIpfs(nfts: Nft[], contractId: string, workspaceId: string) {
  const formdata = new FormData();

  nfts.forEach((nft) => {
    const { image, ...metadata } = cleanNftData(nft);

    if (typeof image !== 'string') {
      formdata.append('files', image[0]);
    } else {
      formdata.append('files', new File([], metadata.name));
    }
    formdata.append('metadata', JSON.stringify({ ...metadata, image }));
  });

  const response = await axios.post(`${backendEndpoint}/uploadMetadataToIpfs`, formdata, {
    headers: {
      'Content-Type': 'multipart/formdata',
      authorization: (await auth.currentUser?.getIdToken()) || '',
      contractId,
      workspaceId,
    },
  });

  return response.data.message as { metadataCid: string; imagesCid: string };
}

export const uploadFilesOnStorage = async ({
  file,
  contractId,
  tokenId,
  workspaceId,
  storageType,
}: {
  file: File;
  contractId: string;
  workspaceId: string;
  tokenId: string;
  storageType: string;
}) => {
  try {
    const formdata = new FormData();
    // const data = {
    //   nftId: tokenId,
    //   attributeId: attribute.trait_type + i.toString(),
    // };
    formdata.append('file', file);
    // formdata.append('data', JSON.stringify(data));
    formdata.append('tokenId', tokenId);
    formdata.append('contractId', contractId);
    formdata.append('storageType', storageType);

    const response = await axios.post(`${backendEndpoint}/uploadFile`, formdata, {
      headers: {
        'Content-Type': 'multipart/formdata',
        authorization: (await auth.currentUser?.getIdToken()) || '',
        workspaceid: workspaceId,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
