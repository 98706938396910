import copyToClipboard from "copy-to-clipboard";
import { Icon, Typography } from "../../../../components";
import { IconCopy } from "../../../../icons";

const DetailsField = (label: string, value: any, copy: boolean = false, copyValue?: string) => {
    return (
      <div className="flex flex-row gap-1">
        <Typography>{`${label}:`}</Typography>
        <Typography weight="semibold">{value}</Typography>
        {copy && (
          <Icon
            icon={IconCopy}
            onClick={() => copyToClipboard(copyValue || value)}
            className="w-4 h-4 cursor-pointer"
            color="primary-500"
            stroke="white"
            size="20"
          />
        )}
      </div>
    );
  };

export default DetailsField