import { useState } from 'react';
import { IconLeft } from '../../../../icons';

const DetailItem = ({
  left,
  right,
  link,
  isCustomAttribute,
}: {
  left: string;
  right: string;
  link?: string;
  isCustomAttribute?: boolean;
}) => {
  const [showMore, setShowMore] = useState(false);
  const [overflowing, setOverflowing] = useState(false);
  const cssToHideMore = 'overflow-hidden text-ellipsis whitespace-nowrap';

  const handleOverflowing = (ref: any) => {
    if (!ref) return;
    const isOverflowing = ref.scrollWidth > ref.clientWidth;
    if (isOverflowing) {
      setOverflowing(true);
    }
  };

  const handleShomMore = () => {
    if (isCustomAttribute) {
      setShowMore(!showMore);
    }
  };
  return (
    <div className="flex justify-between">
      <div
        className={`flex ${
          isCustomAttribute ? (showMore ? 'flex-col flex-wrap gap-y-0' : '') : 'whitespace-nowrap'
        } gap-2  overflow-hidden `}
      >
        {link ? (
          <a
            ref={handleOverflowing}
            className={` ${
              isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
            }   ${
              isCustomAttribute ? 'text-body-bold-14' : 'text-body-bold-underline-14 underline '
            }`}
            target="_blank"
            href={link}
            rel="noreferrer"
          >
            {left}
          </a>
        ) : (
          <>
            <p
              ref={handleOverflowing}
              className={` ${
                isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
              }  text-body-regular-14 ${
                link ? 'text-body-bold-underline-14 underline ' : 'text-body-14 font-semibold'
              }`}
            >{`${left}:`}</p>
            <span
              ref={handleOverflowing}
              className={` ${
                isCustomAttribute ? (showMore ? '' : cssToHideMore) : 'whitespace-nowrap'
              } ${link ? 'text-body-regular-14 underline' : 'text-body-regular-14 '}`}
            >
              {right}
            </span>
          </>
        )}
      </div>
      {overflowing && isCustomAttribute && (
        <div className=" text-body-regular-12" onClick={handleShomMore}>
          {showMore ? (
            <IconLeft width={20} height={20} style={{ transform: 'rotate(90deg)' }} />
          ) : (
            <IconLeft width={20} height={20} style={{ transform: 'rotate(-90deg)' }} />
          )}
        </div>
      )}
    </div>
  );
};

export default DetailItem;
