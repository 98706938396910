import { useMemo } from 'react';
import { getWorkspaceAndGroup } from '../imports/utils';
import { useAppSelector } from '../redux/hooks';

const useWorkspaceAndGroup = () => {
  const { id: workspaceId, activeGroupId, isSuperGroup } = useAppSelector(
    (state) => state.team?.workspace || { id: '', activeGroupId: '', groupIds: [], isSuperGroup: false}
  );
  const workspaceGroupObject = useMemo(
    () => getWorkspaceAndGroup(workspaceId, activeGroupId, isSuperGroup),
    [workspaceId, activeGroupId, isSuperGroup]
  );

  return { workspaceGroupObject };
};

export default useWorkspaceAndGroup;
