import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { collection, onSnapshot, query } from 'firebase/firestore';
import i18next, { t } from 'i18next';
import { SubmitHandler } from 'react-hook-form';
import { uid } from 'uid';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-simple-modal-provider';
import { WorkspaceInvitation, WorkspaceMember } from '../../imports/types';
import { usePrevious } from '../../../../hooks';
import { useLoadingStatusContext } from '../../../../context';
import { auth, db } from '../../../../imports/firebase';
import { addMember, handleBackendError } from '../../api/api';
import { FormType } from '../../common/add-user-form';
import { orderTeamInvitations, computeRoles } from '../../common/utils';
import { useAppSelector } from '../../../../redux/hooks';
import { queryClient } from '../../../..';
import { processMembers } from './query';
import useWorkspaceAndGroup from '../../../../hooks/useWorkspaceAndGroup';

type ManageTeamsContextType = {
  currentSelectedUser: WorkspaceMember | undefined;
  setCurrentSelectedUser: Dispatch<SetStateAction<WorkspaceMember | undefined>>;
  pendingRequest: boolean;
  setPendingRequest: Dispatch<SetStateAction<boolean>>;
  searchField: string;
  setSearchField: Dispatch<SetStateAction<string>>;
  invited: (WorkspaceMember[] & { pending: boolean }[]) | undefined;
  loadInvitations: () => void;
  data: {
    members: WorkspaceMember[];
    groupMembers: WorkspaceMember[];
  };
  handleAddUserToGroup: (member: any) => void;
  handleAddUser: (member: any) => void;
};

type ManageTeamsProviderProps = {
  children: React.ReactNode;
};

export const ManageTeamsContext = createContext<ManageTeamsContextType>(
  {} as ManageTeamsContextType
);
export const useManageTeamsContext = () => useContext(ManageTeamsContext);

export const ManageTeamsProvider = ({ children }: ManageTeamsProviderProps) => {
  const { t } = useTranslation(['team']);

  const { uid } = useAppSelector((state) => state.user);
  const workspace = useAppSelector((state) => state.team.workspace);

  const [currentSelectedUser, setCurrentSelectedUser] = useState<WorkspaceMember>();
  const [pendingRequest, setPendingRequest] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [data, setData] = useState<{
    members: WorkspaceMember[];
    groupMembers: WorkspaceMember[];
  }>();

  const prevWorkspaceId = usePrevious(workspace?.id);
  // !! removing this ternary will break the app because the workspace is not defined and the query will fail with indexOf of undefined error
  const collectionRef = workspace
    ? collection(db, 'workspaces', workspace.id, 'members')
    : collection(db, 'workspaces', '0', 'members');

  const {
    data: invited,
    error: invitationsError,
    refetch: loadInvitations,
  } = useFirestoreQuery(
    ['invitations', workspace?.id],
    collectionRef,
    {},
    {
      enabled: false,
      select(snapshot) {
        return orderTeamInvitations(
          snapshot?.docs.map((doc) => doc.data() as WorkspaceInvitation) ?? [],
          searchField
        );
      },
      onError(error) {
        console.error('Error while fetching invitations', error);
      },
    }
  );

  useEffect(() => {
    if (workspace?.id && prevWorkspaceId !== workspace?.id) {
      if (auth.currentUser) {
        loadInvitations();
      }

      const membersUnsub = onSnapshot(
        query(collection(db, 'workspaces', workspace?.id, 'members')),
        async (snapshot) => {
          // queryClient.setQueryData(['members', workspace?.id], snaps);
          setData(await processMembers({ snapshot, uid, searchField, workspace }));
        }
      );

      if (
        data?.members?.find((member) => member.status === 'pending') ||
        data?.groupMembers.find((member) => member.status === 'pending')
      )
        setPendingRequest(true);
      const invitationsUnsub = workspace?.permissions?.team?.read
        ? onSnapshot(query(collection(db, 'workspaces', workspace?.id, 'invitations')), (snaps) => {
            queryClient.setQueryData(['invitations', workspace?.id], snaps);
          })
        : () => {};

      return () => {
        membersUnsub();
        invitationsUnsub();
      };
    }
  }, [workspace?.id]);

  useEffect(() => {
    if (
      data?.members?.find((member) => member.status === 'pending') ||
      data?.groupMembers?.find((member) => member.status === 'pending')
    ) {
      setPendingRequest(true);
    } else {
      setPendingRequest(false);
    }
  }, [data?.members, data?.groupMembers]);

  /**
   * FirestoreQuery to fetch invitations docs
   */

  const iAmCurrentSelected = currentSelectedUser?.user === uid;
  const isMyTeam = workspace?.owner === uid;
  const canAddUsers = isMyTeam || workspace?.permissions?.team.write;
  const canDeleteUsers = (isMyTeam || workspace?.permissions?.team.delete) && !iAmCurrentSelected;
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();

  const handleAddUser: SubmitHandler<FormType> = async (values) => {
    try {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.add_user.pending'),
        },
      });
      const invitation = {
        workspace_id: workspace?.id,
        lang: i18next.language as 'it' | 'en',
        appBaseURL: window.location.origin,
        email: values.email,
        name: values.name,
        permissions: computeRoles(values.role),
      } as WorkspaceInvitation;

      await addMember(invitation);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.add_user.success'),
        },
      });
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.add_user.error'),
        },
      });
      console.error(error);
      handleBackendError(error);
    }
  };

  const handleAddUserToGroup: SubmitHandler<FormType> = async (values) => {
    try {
      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          message: t('manage.add_user.pending'),
        },
      });
      const invitation = {
        workspace_id: workspace?.id,
        lang: i18next.language as 'it' | 'en',
        appBaseURL: window.location.origin,
        email: values.email,
        name: values.name,
        permissions: computeRoles(values.role),
        inGroup: true,
        groupId: values.groupId,
        isGroupAdmin: values.isGroupAdmin,
      } as WorkspaceInvitation & { groupId: string; inGroup: boolean; isGroupAdmin: boolean };

      await addMember(invitation);
      loadingStatusDispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('manage.add_user.success'),
        },
      });
    } catch (error) {
      loadingStatusDispatch({
        type: 'SET_ERROR',
        payload: {
          message: t('manage.add_user.error'),
        },
      });
      console.error(error);
      handleBackendError(error);
    }
  };

  const contextValue = useMemo(() => {
    if (!data)
      return {
        currentSelectedUser,
        setCurrentSelectedUser,
        pendingRequest,
        setPendingRequest,
        setSearchField,
        searchField,
        invited: invited || undefined,
        data: {
          members: [],
          groupMembers: [],
          unverified: [],
        },
        loadInvitations: () => {},
        handleAddUser,
        handleAddUserToGroup,
      };
    return {
      currentSelectedUser,
      setCurrentSelectedUser,
      setSearchField,
      searchField,
      pendingRequest,
      setPendingRequest,
      invited: invited || undefined,
      loadInvitations: () => {},
      data,
      handleAddUser,
      handleAddUserToGroup,
    };
  }, [data]);

  return <ManageTeamsContext.Provider value={contextValue}>{children}</ManageTeamsContext.Provider>;
};
export default ManageTeamsContext;
