import DOMPurify from 'dompurify';
import Typography from '../typography/Typography';

type CustomizedTextProps = {
  text: string;
};

const CustomizedText = ({ text }: CustomizedTextProps) => {
  const specialChar = /[<>/]/.test(text);
  const sanitizedText = specialChar ? DOMPurify.sanitize(text) : text;

  if (specialChar) {
    return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
  }
  return <Typography>{sanitizedText}</Typography>;
};

export default CustomizedText;
