import { SVGProps } from 'react';

const IconSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38823)">
      <path
        d="M60 120H156C157.591 120 159.117 119.368 160.243 118.243C161.368 117.117 162 115.591 162 114V36C162 34.4087 161.368 32.8826 160.243 31.7574C159.117 30.6321 157.591 30 156 30H72C70.4087 30 68.8826 30.6321 67.7574 31.7574C66.6321 32.8826 66 34.4087 66 36V42"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 102L60 120L78 138"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132 72H36C34.4087 72 32.8826 72.6321 31.7574 73.7574C30.6321 74.8826 30 76.4087 30 78V156C30 157.591 30.6321 159.117 31.7574 160.243C32.8826 161.368 34.4087 162 36 162H120C121.591 162 123.117 161.368 124.243 160.243C125.368 159.117 126 157.591 126 156V150"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 90L132 72L114 54"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38823">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconSwap;
