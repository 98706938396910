import { solidityKeccak256 } from 'ethers/lib/utils';
import keccak256 from 'keccak256';
import MerkleTree from 'merkletreejs';

const hashLeaf = (leaf: { a: string; id: number }) =>
  Buffer.from(solidityKeccak256(['address', 'uint'], [leaf.a, leaf.id]).slice(2), 'hex');

const hashLeaves = (leaves: { a: string; id: number }[]) => leaves.map(hashLeaf);
const computeTree = (rawLeaves: { a: string; id: number }[]) => {
  const leaves = hashLeaves(rawLeaves);

  return new MerkleTree(leaves, keccak256, { sortPairs: true });
};

export function generateMerkleRoot(signers: { a: string; p: string }[][]) {
  const tmpLeafs: { a: string; id: number }[] = [];
  for (const [index, leafs] of signers.entries()) {
    leafs.forEach((signer: { a: string; p: string }) =>
      tmpLeafs.push({ a: signer.a, id: index + 1 })
    );
  }

  const merkleTree = computeTree(
    tmpLeafs
    // signers
    //   .slice(signersCount, signersCount + quantity[i])
    //   .map((signer: { address: string; privateKey: string }) => signer.address)
  );
  // signersCount += quantity[i];

  // root.push(merkleTree.getHexRoot());
  return merkleTree.getHexRoot();
}
