import { cloneDeep, isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { sendModifyPushNotification } from '../../../../api/axios';
import { Button, Field, Form, Input, Typography } from '../../../../components';
import { useLoadingStatusContext } from '../../../../context';
import { useValidation } from '../../../../hooks';
import { IconClose } from '../../../../icons';
import {
  getAvailableContracts,
  getContractsFromFunctionalities,
} from '../../../../imports/contractsParsers';
import { AvailableOn, RestrictedAreaForm } from '../../../../imports/types';
import { updateContract } from '../../../../redux/contracts/contracts.slice';
import { updateRestrictedAreas } from '../../../../redux/functionalities/functionalities.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getFunctionalityRef,
  setFunctionality,
  setNftData,
  updateAvailableOn,
  updateNft,
} from '../../api';
import { Contract, FunctionalitiesRestrictedArea, Nft, NftAttribute } from '../../imports/types';
import { isSecureUrl } from '../../imports/utils';
import ContractsManager from '../contractsManager/ContractsManager';
import { getContractsToRemove } from './query';

type RestrictedAreaProps = {
  edit: FunctionalitiesRestrictedArea | null;
};

const RestrictedArea = ({ edit }: RestrictedAreaProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const dispatch = useAppDispatch();
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);

  const { workspace, contractsList, uid } = useAppSelector((state) => ({
    workspace: state.team.workspace,
    contractsList: state.contracts.list,
    uid: state.user.uid,
  }));
  const { required, validateUrl, validateContractsArray } = useValidation();

  const formRestrictedArea = {
    initialValuesRestrictedArea: {
      title: edit?.title || '',
      link: edit?.link || '',
      availableOn: edit?.availableOn || [],
    },
    validationSchemaRestrictedArea: yup.object({
      title: required(yup.string()),
      link: required(validateUrl()),
      // availableOn: required(validateContractsArray()),
    }),
  };
  const { initialValuesRestrictedArea, validationSchemaRestrictedArea } = formRestrictedArea;

  const handleSubmitRestricted = async (fields: { options: []; link: string; title: string }) => {
    if (workspace) {
      const newFunctionalityRef = getFunctionalityRef(edit);

      const functionality: FunctionalitiesRestrictedArea = {
        type: 'restricted_area',
        workspaceId: workspace.id, //workspace?.owner ?? profile.uid,
        link: fields.link,
        availableOn: edit?.availableOn || {},
        id: newFunctionalityRef.id,
        title: edit?.title ? edit?.title : fields.title,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      loadingStatusDispatch({
        type: 'SET_PENDING',
        payload: {
          title: t('collection.functionality.actions.pending'),
        },
      });

      const url = isSecureUrl(fields.link);
      if (url && selectedContracts?.length > 0) {
        functionality.availableOn = getAvailableContracts(selectedContracts);

        if (edit) {
          /**
           * Code to delete the functionality from NFTs
           */
          await Promise.allSettled(
            getContractsFromFunctionalities(
              getContractsToRemove(edit.availableOn, selectedContracts),
              contractsList
            ).map((contract) => {
              return contract.nfts.map((nft: Nft) => {
                /* update nft.attributes on redux contract */

                return updateNft(
                  {
                    ...nft,
                    attributes: nft.attributes?.filter(({ id }) => !(id === functionality.id)),
                    updatedAt: Date.now(),
                  },
                  contract.id
                );
              });
            })
          );
        }

        const data = await Promise.allSettled(
          selectedContracts
            .map(async (contract) => {
              /**
               * Create a copy of contract
               */
              const updatedContract = cloneDeep(contract);

              const modifiedNftsIds: number[] = [];
              const promises = contract.nfts.map(async (nft) => {
                try {
                  /**
                   * if edit exists we are modifying the attribute, if not we are creating it
                   */
                  const newAttributes: NftAttribute[] = cloneDeep(
                    nft?.attributes
                  ) as NftAttribute[];

                  const attributeIndex = newAttributes?.findIndex(({ id }) => {
                    return id === functionality.id;
                  });

                  if (attributeIndex === -1) {
                    newAttributes.push({
                      trait_type: fields.title,
                      type: functionality.type,
                      value: fields.link,
                      id: functionality.id,
                    });
                  } else {
                    newAttributes[attributeIndex] = {
                      ...newAttributes[attributeIndex],
                      trait_type: fields.title,
                      value: fields.link,
                    };
                  }
                  const data = { attributes: newAttributes };

                  updatedContract.nfts = updatedContract.nfts.map((updatedNft: Nft) => {
                    if (nft.id === updatedNft.id) {
                      return { ...updatedNft, attributes: newAttributes };
                    }
                    return updatedNft;
                  });

                  modifiedNftsIds.push(nft.id);
                  const promise = await setNftData(contract.id, nft, data);

                  /**
                   * create newNfts array and adding it only once. Populated with
                   * cloned and eventuyally modified nfts
                   *  */

                  return promise;
                } catch (error) {
                  console.log(error);
                  loadingStatusDispatch({
                    type: 'SET_ERROR',
                    payload: {
                      title: t('collection.functionality.actions.failed'),
                    },
                  });
                  return error;
                }
              });
              await sendModifyPushNotification(uid, contract.id, modifiedNftsIds);
              dispatch(updateContract(updatedContract));

              return promises;
            })
            .flat()
        );
        /* add functionality on db in functionalities collection*/
        setFunctionality(newFunctionalityRef, functionality, true);
        if (edit) {
          const availableOn: AvailableOn = selectedContracts.reduce((acc, contract) => {
            acc[contract.address] = [...contract.nfts.map((nft) => nft.id)];
            return acc;
          }, {} as AvailableOn);
          updateAvailableOn(newFunctionalityRef, availableOn);
        }
        /* check if all promises had success */
        let isError = false;
        data.forEach((promise: any) => {
          if (promise.status === 'fulfilled' && promise.value.error) {
            isError = true;
          }
        });
        if (isError) {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('collection.functionality.actions.failed'),
            },
          });
        } else {
          dispatch(updateRestrictedAreas(functionality as unknown as RestrictedAreaForm));
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: edit
                ? t('collection.functionality.actions.updated')
                : t('collection.functionality.actions.success'),
            },
          });
        }
      } else {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('collection.functionality.actions.failed'),
          },
        });
      }
    }
  };

  return (
    <div>
      <Typography color="primary-500" size="body-medium-30" className="p-4">
        {t('collection.functionality.restricted')}
      </Typography>

      <Form
        initialValues={initialValuesRestrictedArea}
        validationSchema={validationSchemaRestrictedArea}
        className="mt-4 flex size-full flex-col gap-4 p-4"
      >
        {({ fields, handleSubmit, errors, resetField, watch, isValid, setValue }) => {
          const values = watch();

          return (
            <>
              <Field label={t('collection.functionality.news_title')} error={errors.title?.message}>
                <Input
                  id={fields.title.name}
                  type="text"
                  placeholder={t('collection.functionality.placeholders_title')}
                  name={fields.title.name}
                  onBlur={fields.title.onBlur}
                  onChange={fields.title.onChange}
                  inputRef={fields.title.ref}
                  error={errors.title?.message}
                  elementRight={
                    values.title && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.title.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field label={t('collection.functionality.link')} error={errors.link?.message}>
                <Input
                  id={fields.link.name}
                  type="text"
                  placeholder={t('collection.functionality.placeholders_link')}
                  name={fields.link.name}
                  onBlur={fields.link.onBlur}
                  onChange={fields.link.onChange}
                  inputRef={fields.link.ref}
                  error={errors.link?.message}
                  elementRight={
                    values.link && (
                      <Button
                        type="ghost"
                        icon={IconClose}
                        action={() => resetField(fields.link.name)}
                      />
                    )
                  }
                />
              </Field>
              <Field error={errors.options?.message}>
                <Typography as="h3" color={errors.options?.message ? 'error' : 'black'}>
                  {t('collection.functionality.select_contract')}
                </Typography>
                <div className=" max-h-[400px] overflow-y-auto overflow-x-hidden">
                  {/* <ContractsManager
                    availableContracts={
                      edit ? edit?.availableOn : getAvailableContracts(values.availableOn)
                    }
                    setContracts={(values: Contract[]) => setValue('availableOn', values)}
                  /> */}
                  <ContractsManager
                    availableContracts={edit?.availableOn ? edit.availableOn : {}}
                    setContracts={setSelectedContracts}
                  />
                </div>
              </Field>

              <div className="mt-4 flex justify-end">
                <Button
                  id="create-functionality"
                  type="primary"
                  action={handleSubmit(handleSubmitRestricted)}
                  disabled={!isEmpty(errors) || !selectedContracts.length}
                >
                  {t('collection.functionality.submit')}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default RestrictedArea;
