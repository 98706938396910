import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ModalProvider } from 'react-simple-modal-provider';
import { LoadingStatusProvider } from './context';
import ContextProvider from './context/ContextProvider';
import { persistor, store } from './redux/store';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'simplebar-react/dist/simplebar.min.css';
import packageJson from '../package.json';
import {
  AddUserModal,
  AddUserToGroupModal,
  AddWorkspaceGroupModal,
  ApiModal,
  AuditDetailsModal,
  AuditNotarizeModal,
  AuditRenameModal,
  AuditViewNotarizedModal,
  ChangeAccountModal,
  ChangeFolderModal,
  CollectionDetailsModal,
  ContractsManagerModal,
  CrmUserModal,
  DeleteAuditModal,
  DeleteContractModal,
  DeleteFolderModal,
  DeleteModal,
  DeleteNotarizationModal,
  DuplicateCategoryModal,
  FunctionalityDetailsModal,
  LoadingModal,
  MessagesCollectionsModal,
  NewCompanyModal,
  NewFolderModal,
  NewFunctionalityModal,
  NewMessageModal,
  NewNotarizationModal,
  NotarizationDetailsModal,
  PecModal,
  PlansModal,
  PollsAnalyticsModal,
  QrModal,
  QuestionsFlowsModal,
  ReadMessageModal,
  RenameFolderModal,
  SelectFolderModal,
  SendNftModal,
  ShareRestrictedAreaModal,
  ShareShopModal,
  SubscriptionModal,
  TemplateModal,
  TermsModal,
} from './components/modals';
import ProfileFieldsModal from './components/modals/ProfileFieldsModal';
import { LoadingProvider } from './context/LoadingContext';
import './i18n';
import { dsnSentry } from './imports/constants';
import './index.css';
import ContractsWrapper from './pages/ContractsWrapper';
import PollsWrapper from './pages/PollsWrapper';
import SubscriptionWrapper from './pages/SubscriptionWrapper';
import TeamWrapper from './pages/TeamWrapper';
import { ManageTeamsProvider } from './modules/team/pages/manage-teams/ManageTeamsContext';

if (!['LOCAL', 'PREPROD'].includes(process.env.REACT_APP_ENV as string)) {
  Sentry.init({
    dsn: dsnSentry,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    release: packageJson.version,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, staleTime: Infinity, refetchOnWindowFocus: false } },
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LoadingProvider>
        <ContextProvider>
          <LoadingStatusProvider>
            <QueryClientProvider client={queryClient}>
              <SubscriptionWrapper>
                <TeamWrapper>
                  <ManageTeamsProvider>
                    <ContractsWrapper>
                      <PollsWrapper>
                        <ModalProvider
                          value={[
                            AddUserToGroupModal,
                            AddWorkspaceGroupModal,
                            AddUserModal,
                            ApiModal,
                            ChangeFolderModal,
                            AuditDetailsModal,
                            AuditNotarizeModal,
                            AuditViewNotarizedModal,
                            ChangeAccountModal,
                            CollectionDetailsModal,
                            ContractsManagerModal,
                            AuditRenameModal,
                            CrmUserModal,
                            DeleteAuditModal,
                            DeleteContractModal,
                            DeleteFolderModal,
                            RenameFolderModal,
                            DeleteNotarizationModal,
                            SendNftModal,
                            FunctionalityDetailsModal,
                            MessagesCollectionsModal,
                            LoadingModal,
                            NewCompanyModal,
                            TemplateModal,
                            NewFolderModal,
                            NewFunctionalityModal,
                            DuplicateCategoryModal,
                            NewMessageModal,
                            NewNotarizationModal,
                            NotarizationDetailsModal,
                            PecModal,
                            PlansModal,
                            SubscriptionModal,
                            QrModal,
                            ReadMessageModal,
                            ProfileFieldsModal,
                            TermsModal,
                            QuestionsFlowsModal,
                            ShareRestrictedAreaModal,
                            ShareShopModal,
                            DeleteModal,
                            PollsAnalyticsModal,
                            SelectFolderModal,
                          ]}
                        >
                          <App />

                          <ReactQueryDevtools initialIsOpen={false} />
                        </ModalProvider>
                      </PollsWrapper>
                    </ContractsWrapper>
                  </ManageTeamsProvider>
                </TeamWrapper>
              </SubscriptionWrapper>
            </QueryClientProvider>
          </LoadingStatusProvider>
        </ContextProvider>
      </LoadingProvider>
    </PersistGate>
  </Provider>
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

reportWebVitals();
