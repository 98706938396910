import { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Field, Input, Select, Typography } from '../../../../components';
import { ExportedProps } from '../../../../components/form/Form';
import CustomTooltip from '../../../../components/tooltips/CustomTooltip';
import { formFieldsWithMultipleOptions } from '../../../../imports/constants';
import { Template } from '../../imports/types';
import Preview from './Preview';
import { useAppSelector } from '../../../../redux/hooks';
import i18n from '../../../../i18n';

interface ISpecificStep
  extends Omit<
    ExportedProps,
    | 'trigger'
    | 'register'
    | 'reset'
    | 'getValues'
    | 'clearErrors'
    | 'watch'
    | 'isValid'
    | 'dirtyFields'
  > {
  isLoading: boolean;
  contractsLeft: number;
  values: any;
  onBack: () => void;
  templates: Template[];
  onCompleted: () => void;
}

const SpecificStep = ({
  fields,
  contractsLeft,
  errors,
  templates,
  handleSubmit,
  values,
  resetField,
  setValue,
  isLoading,
  onBack,
  onCompleted,
}: ISpecificStep) => {
  const { t } = useTranslation(['tokenCreator']);

  const {
    doesExpire,
    doesTransfer,
    qrCodeDrop,
    doesDropDate,
    tag,
    contractImage,
    name,
    chain,
    description,
    // template,
    doesUpdate,
    addCategory,
    hasGame,
    isPrivate,
  } = values;
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;
  const img = contractImage ? contractImage[0]?.preview : '';

  return (
    <>
      <div className="space-y-12">
        <Typography weight="semibold">{t('create_contract.titles.specific')}</Typography>

        <div className="grid grid-cols-2 gap-x-8 gap-y-2">
          <Preview values={values} />
          {/* Categories  */}

          <div className="flex flex-col gap-8">
            {/* Max token per user  */}
            {!template.fields?.maxTokensPerUser?.hidden && (
              <Field
                label={
                  template.fields?.maxTokensPerUser?.label?.[language] ||
                  t('create_contract.form_labels.max_tokens_per_user')
                }
                labelElementRight={
                  <CustomTooltip>
                    <Typography size="xs">
                      {t('create_contract.form_tooltips.max_tokens_per_user') as string}
                    </Typography>
                  </CustomTooltip>
                }
                error={errors.maxTokensPerUser?.message}
              >
                <Input
                  type="number"
                  placeholder={t('create_contract.form_placeholders.max_tokens_per_user')}
                  minValue="1"
                  disabled={template.fields?.maxTokensPerUser?.disabled}
                  name={fields.maxTokensPerUser.name}
                  onBlur={fields.maxTokensPerUser.onBlur}
                  onChange={fields.maxTokensPerUser.onChange}
                  inputRef={fields.maxTokensPerUser.ref}
                  error={errors.maxTokensPerUser?.message}
                />
              </Field>
            )}
            {/* Transfer NFT  */}

            {!template.fields?.doesTransfer?.hidden && (
              <div className="w-full space-y-2">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Typography>
                      {t('create_contract.form_labels.does_transfer') as string}
                    </Typography>
                    <CustomTooltip>
                      <Typography size="xs">
                        {t('create_contract.form_tooltips.does_transfer.0') as string}
                      </Typography>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.does_transfer.1') as string}
                      </Typography>
                      <Typography size="xs">
                        {t('create_contract.form_tooltips.does_transfer.2') as string}
                      </Typography>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.does_transfer.3') as string}
                      </Typography>
                    </CustomTooltip>
                  </div>

                  <Checkbox
                    name={fields.doesTransfer.name}
                    onBlur={fields.doesTransfer.onBlur}
                    onChange={(e) => {
                      const event = e as BaseSyntheticEvent;
                      const checked = event.target.checked as boolean;
                      if (checked) {
                        setValue('doesTransfer', true);
                      } else {
                        setValue('doesTransfer', false);
                        setValue('transfer', 'none');
                      }
                    }}
                    inputRef={fields.doesTransfer.ref}
                    error={errors.doesTransfer?.message}
                    disabled={template.fields?.doesTransfer?.disabled}
                    isChecked={doesTransfer}
                  />
                </div>

                {doesTransfer && (
                  <>
                    <Select
                      options={formFieldsWithMultipleOptions['create-contract'].transfer.map(
                        (transfer, index) => ({
                          value: transfer,
                          label: t(
                            `create_contract.form_select_fields_options_label.transfer.${index}`
                          ),
                        })
                      )}
                      name={fields.transfer.name}
                      onBlur={fields.transfer.onBlur}
                      onChange={fields.transfer.onChange}
                      inputRef={fields.transfer.ref}
                      error={errors.transfer?.message}
                    />
                  </>
                )}
              </div>
            )}
            {/* Deadline in days  */}
            {!template.fields?.doesExpire?.hidden && (
              <div className="w-full space-y-2">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Typography>
                      {t('create_contract.form_labels.does_expire') as string}
                    </Typography>
                    <CustomTooltip>
                      <Typography size="xs">
                        {t('create_contract.form_tooltips.does_expire.0') as string}
                      </Typography>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.does_expire.1') as string}
                      </Typography>
                      <Typography size="xs">
                        {t('create_contract.form_tooltips.does_expire.2') as string}
                      </Typography>
                      <Typography size="xs" className="mt-4">
                        {t('create_contract.form_tooltips.does_expire.3') as string}
                      </Typography>
                    </CustomTooltip>
                  </div>

                  <Checkbox
                    name={fields.doesExpire.name}
                    onBlur={fields.doesExpire.onBlur}
                    onChange={fields.doesExpire.onChange}
                    inputRef={fields.doesExpire.ref}
                    error={errors.doesExpire?.message}
                    isChecked={doesExpire}
                    disabled={template.fields?.doesExpire?.disabled}
                  />
                </div>

                {doesExpire && (
                  <>
                    <Select
                      options={formFieldsWithMultipleOptions['create-contract'].expirationFrom.map(
                        (expirationFrom, index) => ({
                          value: expirationFrom,
                          label: t(
                            `create_contract.form_select_fields_options_label.expiration_from.${index}`
                          ),
                        })
                      )}
                      name={fields.expirationFrom.name}
                      onBlur={fields.expirationFrom.onBlur}
                      onChange={fields.expirationFrom.onChange}
                      inputRef={fields.expirationFrom.ref}
                      error={errors.expirationFrom?.message}
                    />

                    <Field error={errors.expiration?.message}>
                      <Input
                        type="number"
                        placeholder={t('create_contract.form_placeholders.expiration')}
                        minValue="0"
                        disabled={template.fields?.expiration?.disabled}
                        name={fields.expiration.name}
                        onBlur={fields.expiration.onBlur}
                        onChange={fields.expiration.onChange}
                        inputRef={fields.expiration.ref}
                        error={errors.expiration?.message}
                      />
                    </Field>
                  </>
                )}
              </div>
            )}

            {/* Token Burn  */}
            {!template.fields?.tokenBurn?.hidden && (
              <div className="flex w-full justify-between">
                <div className="flex">
                  <Typography>{t('create_contract.form_labels.token_burn') as string}</Typography>
                </div>

                <Checkbox
                  name={fields.tokenBurn.name}
                  onBlur={fields.tokenBurn.onBlur}
                  error={errors.tokenBurn?.message}
                  isChecked
                  disabled
                />
              </div>
            )}

            {/* Update */}
            {!template.fields?.update?.hidden && (
              <div className="flex w-full justify-between">
                <div className="flex">
                  <Typography>{t('create_contract.form_labels.does_update') as string}</Typography>
                </div>

                <Checkbox
                  name={fields.update.name}
                  onChange={(ev) => {
                    const event = ev as BaseSyntheticEvent;
                    const checked = event.target.checked as boolean;
                    if (checked) {
                      setValue('doesUpdate', true);
                      setValue('update', 'creator');
                    } else {
                      setValue('doesUpdate', false);
                      setValue('update', 'none');
                    }
                  }}
                  disabled={template.fields?.update?.disabled}
                  inputRef={fields.doesUpdate.ref}
                  error={errors.update?.message}
                  isChecked={doesUpdate}
                />
              </div>
            )}

            {/* Add post creation category */}

            <div className="flex w-full justify-between">
              <div className="flex">
                <Typography>{t('create_contract.form_labels.addCategory') as string}</Typography>
              </div>

              <Checkbox
                name={fields.addCategory.name}
                onBlur={fields.addCategory.onBlur}
                onChange={(ev) => {
                  const event = ev as BaseSyntheticEvent;
                  const checked = event.target.checked as boolean;

                  setValue('addCategory', checked);
                }}
                inputRef={fields.addCategory.ref}
                error={errors.addCategory?.message}
                isChecked={addCategory}
              />
            </div>

            {/* Token hasGame  */}
            {!template.fields?.hasGame?.hidden && (
              <div className="w-full space-y-2">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Typography>{t('create_contract.form_labels.playable') as string}</Typography>
                    <CustomTooltip>
                      <Typography size="xs">
                        {t('create_contract.form_tooltips.playable')}
                      </Typography>
                    </CustomTooltip>
                  </div>

                  <Checkbox
                    name={fields.hasGame.name}
                    onBlur={fields.hasGame.onBlur}
                    onChange={fields.hasGame.onChange}
                    inputRef={fields.hasGame.ref}
                    error={errors.hasGame?.message}
                    disabled={template.fields?.hasGame?.disabled}
                    isChecked={hasGame}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-20 flex justify-center space-x-16">
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={onBack} type="secondary">
            {t('create_contract.form_labels.back') as string}
          </Button>
        </div>
        <div className="w-[17rem]">
          <Button id="cc-step-1" action={onCompleted} disabled={isLoading || contractsLeft < 1}>
            {t('create_contract.form_labels.next') as string}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SpecificStep;
