import { useEffect } from 'react';
import Typography from '../typography/Typography';

type SectionProfileProps = {
  title: string;
  children?: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  textColor?: string;
  bgColor?: string;
  editData?: React.ReactNode;
  id?: string;
};

const colors = { text: '#ffffff', bg: '#211b2b' };
const SectionProfile = ({
  title,
  children,
  as = 'h2',
  size = 'lg',
  editData,
  textColor = colors.text,
  bgColor = colors.bg,
  id,
}: SectionProfileProps) => {
  const txc = textColor === '' ? colors.text : textColor;
  const bgc = bgColor === '' ? colors.bg : bgColor;
  const idCustom = id + 'Custom';

  const setCustomColor = () => {
    if (id === 'preview') {
      //if (id === 'bgColor' || id === 'textColor') {
      const titleElement = document.getElementById(idCustom);
      if (titleElement) {
        titleElement.style.backgroundColor = bgc;
        titleElement.style.color = txc;
      }
    }
  };

  useEffect(() => {
    setCustomColor();
  }, [txc, bgc]);

  useEffect(() => {
    setCustomColor();
  }, []);

  return (
    <div className="w-full">
      <div className="flex min-h-[6rem] w-full flex-col content-center">
        <div className="flex w-full flex-row justify-between">
          <Typography
            as={as}
            size={size}
            weight="medium"
            className="whitespace-nowrap "
            //${textColor}
          >
            <p>{title}</p>
          </Typography>
          {
            editData && editData
            // (
            //   <button
            //     type="button"
            //     onClick={editData}
            //     className="w-6 h-6 p-1 rounded-full primary btn place-self-end"
            //   >
            //     <Icon icon={IconPencil} size="sm" color="white" className="" />
            //   </button>
            // )
          }
        </div>
        {children && children}
      </div>
      <hr className="border-primary-400" />
    </div>
  );
};

export default SectionProfile;
