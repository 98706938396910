import { Routes } from '../../imports/types';
import Layout from './pages/Layout';

type NotarizationsProps = {
  route: Routes;
};

const Notarizations = ({ route }: NotarizationsProps) => <Layout route={route} />;

export default Notarizations;
