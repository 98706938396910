import { IconClose } from '../../icons';
import Button from '../button/Button';
import Typography from '../typography/Typography';

type TagProps = {
  title: string;
  className?: string;
  onClose?: (item: string | number) => void;
  id?: string | number;
  disabled?: boolean;
};

const Tag = ({ title, onClose, className, id = title, disabled }: TagProps) => (
  <div
    className={`flex h-9 items-center justify-between rounded-[6px] border border-primary-500 bg-white px-3 ${className}`}
  >
    <Typography as="p" size="xs" className="truncate text-primary-500">
      {title}
    </Typography>
    {onClose && (
      <Button
        disabled={disabled}
        type="ghost"
        icon={IconClose}
        iconStroke="primary-500"
        iconSize="sm"
        action={() => onClose(id)}
      />
    )}
  </div>
);

export default Tag;
