import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { useModalProps, useModalState } from 'react-simple-modal-provider';
import { Button } from '..';
import { useLoadingStatusContext } from '../../context';
import { removeNotarizationFromFolder } from '../../modules/notarizations/api';
import { updateContract } from '../../modules/tokenCreator/api';
import ModalLayout from '../layouts/ModalLayout';
import Typography from '../typography/Typography';

type DeleteContractModalProps = {
  children: ReactNode;
};

const ModalBody = ({ setOpen }: any) => {
  const { contract } = useModalProps('DeleteContractModal');
  const { t } = useTranslation(['tokenCreator']);
  const { dispatch: loadingStatusDispatch } = useLoadingStatusContext();

  const handleDeleteContract = async () => {
    loadingStatusDispatch({
      type: 'SET_PENDING',
      payload: {
        message: t('collection.delete_contract.actions.pending'),
      },
    });
    if (contract.folderId) {
      await removeNotarizationFromFolder(contract.folderId, contract.id)
        .then(() => {
          loadingStatusDispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('acollection.delete_contract.actions.success'),
            },
          });
        })
        .catch(() => {
          loadingStatusDispatch({
            type: 'SET_ERROR',
            payload: {
              message: t('collection.delete_contract.actions.failed'),
            },
          });
        });
    }

    await updateContract(contract.id, {
      costs: {
        deployCost: 0,
        mints: 0,
        transfers: 0,
        whitelistCost: 0,
      },
      userId: 'UNKNOWN',
      workspace_id: 'UNKNOWN',
      visibility: { isDisabled: true },
    })
      .then(() => {
        loadingStatusDispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('collection.delete_contract.actions.success'),
          },
        });
      })
      .catch(() => {
        loadingStatusDispatch({
          type: 'SET_ERROR',
          payload: {
            title: t('collection.delete_contract.actions.failed'),
          },
        });
      });
  };

  return (
    <ModalLayout
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex size-full flex-col gap-4 px-[20px] pt-[50px]">
        <div data-cy="deleteContract" className="mt-7">
          <Typography weight="medium" size="body-medium-30" color="primary-500" className="pb-7">
            {t('collection.delete_contract.title')}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('collection.delete_contract.delete_contract', { name: contract.name })}
          </Typography>
          <Typography size="body-regular-18" color="grey-600">
            {t('collection.delete_contract.disclaimer')}
          </Typography>
        </div>
        <div className="mt-[20px] flex flex-row justify-end">
          <Button id="deleteContract" type="warning" action={() => handleDeleteContract()}>
            {t('collection.delete_contract.submit_data')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

const DeleteContractModal = ({ children }: DeleteContractModalProps) => {
  const [isOpen, setOpen] = useModalState();
  return (
    <Modal id="DeleteContractModal" consumer={children} isOpen={isOpen} setOpen={setOpen}>
      <ModalBody setOpen={setOpen} />
    </Modal>
  );
};

export default DeleteContractModal;
