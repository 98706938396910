import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  CustomTooltip,
  Field,
  Input,
  Select,
  Typography,
} from '../../../../../components';
import { IconClose } from '../../../../../icons';
import { CustomFieldsProps } from '../../../imports/types';
import { useAppSelector } from '../../../../../redux/hooks';
import i18n from '../../../../../i18n';

const TextCustomField = ({
  errors,
  category,
  index,
  values,
  fieldLabel,
  resetField,
  fieldValue,
  setValue,
  options,
  fieldType,
  fieldPrivate,
}: CustomFieldsProps) => {
  const { t } = useTranslation(['tokenCreator']);
  const { nfts } = values;
  const template = useAppSelector((state) => state.template);
  const language = i18n.language;
  // const attributeType = nfts?.[category]?.attributes?.[index]?.type;
  const attributeCustomType = nfts?.[category]?.attributes?.[index]?.customType;
  const templateField = template.customFields && template.customFields.find(
    (field: any) => field.value === attributeCustomType
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex gap-2 felx-row ">
        <Field
          error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
          label={t('create_contract.form_labels.attribute')}
          className="!w-1/2"
        >
          <Select
            options={options}
            error={errors.nfts?.[category]?.attributes?.[index]?.type?.message}
            name={fieldType.name}
            // onBlur={fieldType.onBlur}
            // onChange={fieldType.onChange}
            onChange={(change) => {
              setValue(`nfts[${category}].attributes[${index}].customType`, change.target.value);
              const customFieldFound = template.customFields.find(
                (field: any) => field.value === change.target.value
              );

              // if (customFieldFound.type !== nfts?.[category]?.attributes?.[index]?.type) {

              setValue(`nfts[${category}].attributes[${index}].type`, customFieldFound?.type);
              // }

              if (customFieldFound?.fields.trait_type?.value) {
                setValue(
                  `nfts[${category}].attributes[${index}].trait_type`,
                  customFieldFound?.fields.trait_type?.value
                );
              } else {
                setValue(`nfts[${category}].attributes[${index}].trait_type`, '');
              }
            }}
            inputRef={nfts?.[category]?.attributes?.[index]?.type.ref}
            defaultValue={
              options.find(
                (option) => option.value === nfts?.[category]?.attributes?.[index]?.customType
              )?.value
            }
          />
        </Field>

        {!templateField?.fields.private?.hidden && (
          <div className="flex items-end justify-between w-1/2 mb-2">
            <div className="flex items-center">
              <Typography>{t('create_contract.form_labels.private_field') as string}</Typography>
              <CustomTooltip>
                <Typography size="xs">
                  {t('create_contract.form_tooltips.private_field') as string}
                </Typography>
              </CustomTooltip>
            </div>

            <Checkbox
              name={fieldPrivate.name}
              id={fieldPrivate.name}
              onChange={fieldPrivate.onChange}
              inputRef={fieldPrivate.ref}
              onBlur={fieldPrivate.onBlur}
              isChecked={values.nfts?.[category]?.attributes?.[index]?.private}
            />
          </div>
        )}
      </div>
      {/* SECOND ROW */}
      <div className="flex flex-col">
        <div className="flex gap-2">
          {!templateField?.fields.trait_type?.hidden && (
            <Field
              error={errors.nfts?.[category]?.attributes?.[index]?.trait_type?.message}
              label={templateField?.fields.trait_type?.label?.[language]}
              className="!w-1/2"
            >
              <Input
                type="text"
                placeholder={t('create_contract.form_placeholders.custom_field_label')}
                name={fieldLabel.name}
                onBlur={fieldLabel.onBlur}
                onChange={fieldLabel.onChange}
                inputRef={fieldLabel.ref}
                error={errors.nfts?.[category]?.attributes?.[index]?.trait_type?.message}
                elementRight={
                  <Button
                    type="ghost"
                    icon={IconClose}
                    action={() => resetField(fieldLabel.name)}
                  />
                }
                disabled={templateField?.fields.trait_type?.disabled}
              />
            </Field>
          )}
          <Field
            error={errors.nfts?.[category]?.attributes?.[index]?.value?.message}
            label={templateField?.fields.value?.label?.[language]}
            className="!w-1/2"
          >
            <Input
              type="text"
              placeholder={templateField?.fields.value?.placeholder?.[language]}
              name={fieldValue.name}
              onBlur={fieldValue.onBlur}
              onChange={fieldValue.onChange}
              // onChange={(val) => {
              //   setValue(`nfts[${category}].attributes[${index}].value`, val.target.value);
              // }}
              inputRef={fieldValue.ref}
              elementRight={
                <Button type="ghost" icon={IconClose} action={() => resetField(fieldValue.name)} />
              }
              disabled={nfts?.[category]?.attributes?.[index]?.file?.[0]}
            />
          </Field>
        </div>
      </div>
    </div>
  );
};

export default TextCustomField;
