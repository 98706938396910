import { SVGProps } from 'react';

const IconBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={192}
    height={192}
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1653_38757)">
      <path
        d="M72 173H120"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3389 66.2505C42.329 58.841 43.7215 51.5027 46.4362 44.6581C49.1509 37.8136 53.1343 31.5982 58.1568 26.3698C63.1793 21.1414 69.1418 17.0033 75.7008 14.194C82.2597 11.3847 89.2856 9.95964 96.3737 10.0009C125.969 10.2352 149.661 35.9382 149.661 66.9536V72.5004C149.661 100.469 155.266 116.719 160.199 125.625C160.723 126.574 160.999 127.649 161 128.745C161.001 129.84 160.726 130.916 160.204 131.866C159.682 132.815 158.93 133.604 158.024 134.154C157.118 134.705 156.09 134.996 155.042 135H36.9579C35.9102 134.996 34.8818 134.705 33.9758 134.154C33.0698 133.604 32.318 132.815 31.7958 131.866C31.2735 130.916 30.9991 129.84 31 128.745C31.0009 127.649 31.2772 126.574 31.801 125.625C36.7337 116.719 42.3389 100.469 42.3389 72.5004V66.2505Z"
        stroke="#4F4F4F"
        strokeWidth={12}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_38757">
        <rect width={192} height={192} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconBell;
