import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NON_SCROLLABLE_PATH } from '../imports/constants';

/**
 * Hook that manages the scroll behavior of a component based on the current location pathname or the render of new components.
 * @param scrollBehavior - Optional function that defines the scroll behavior.
 * If not provided, the default behavior is to scroll to the top of the page.
 * @param ref - Optional ref to scroll into view. Not yet used but useful if in future we need to focus on specific element after event.
 *
 * @example scroll to top on every route change or component render useScrollBehavior(() => {
 *   window.scrollTo({ top: 0, behavior: 'smooth' });
 * }, myRef);
 */
const useScrollBehavior = (scrollBehavior?: () => void, ref?: React.RefObject<HTMLElement>) => {
  const { pathname } = useLocation();

  const memoizedScrollBehavior = useCallback(() => {
    if (!NON_SCROLLABLE_PATH.some((path) => pathname.includes(path))) {
      if (scrollBehavior) {
        scrollBehavior();
      } else {
        window.scrollTo({ top: 0, behavior: 'auto' });
      }
    }
  }, [pathname, scrollBehavior]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'auto' });
    } else {
      memoizedScrollBehavior();
    }
  }, [pathname, memoizedScrollBehavior, ref]);
};

export default useScrollBehavior;
